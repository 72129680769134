export { Meta } from './meta'
export { Shared } from './shared'
export {
  HomePage,
  getFacultiesData,
  getStoriesData,
  getMissionData
} from './home-page'
export { JournalsPage, getSeoData as getJournalsSeoData } from './journals-page'
// export { getSeoData } from './journal-page'
export {
  ProgramPage,
  getHeroData,
  getWhatWillYouLearnData,
  getGraduateProfessionsData,
  getLeastProgramContentsData,
  getCostData,
  getReviewsData,
  getTeachersData,
  getForWhomData,
  getStudyIsData,
  getExamFreeEnrollmentData,
  getSeoData as getProgramSeoData
} from './program-page'
export {
  ProgramsPage,
  getProgramsData,
  getProgramsDataFaculties,
  getSeoData as getProgramsSeoData,
  getBannerData,
  getProgramsQuestions,
  getProgramsVideo
} from './programs-page'
export { ContactPage } from './contact-page'
export { LeadForm } from './shared/widgets/LeadForm'
