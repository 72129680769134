import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import Color from 'color'
import { TIconHamburgerProps } from './types'

const IconHamburger = ({ colorVariant, ...props }: TIconHamburgerProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    ...props?.styles
  } as const

  return (
    <IconContainer styles={styles} {...props}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M3 12H21'
          stroke={
            (colorVariant?.color &&
              Color(colorVariant?.color).rgb().string()) ||
            'var(--beta)'
          }
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 6H21'
          stroke={
            (colorVariant?.color &&
              Color(colorVariant?.color).rgb().string()) ||
            'var(--beta)'
          }
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 18H21'
          stroke={
            (colorVariant?.color &&
              Color(colorVariant?.color).rgb().string()) ||
            'var(--beta)'
          }
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconContainer>
  )
}

export default IconHamburger
