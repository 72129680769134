import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetCurrentJournalDataProps, TResJournal } from './types'

export const getCurrentJournal = async ({
  context
}: TGetCurrentJournalDataProps) => {
  const { currentJournalSlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  const query = qs.stringify({
    fields: ['slug'],
    filters: {
      slug: {
        $eq: currentJournalSlug
      }
    },
    locale: context.locale
  })

  const res = await fetcher<TResJournal>(
    `${PATHS.back.root.index}${PATHS.back.api.journals.index}?${query}`
  )

  return res?.data?.some(({ slug }) => Boolean(slug))
}

export default getCurrentJournal
