import { StyledJSX } from 'ui'
import { media, multiMedia, toUnit, useMedia } from 'fractals'
import { TPProps } from './types'
import { VARIANTS_KEYS } from './constants'

const P = ({ variant, ...props }: TPProps) => {
  const { isTablet, isLaptop, isDesktop, isMobile } = useMedia()

  const stylesVariants = {
    // use this for HomePage Hero
    [VARIANTS_KEYS.alpha]: {
      fontSize: toUnit(
        (isDesktop && 24) || ((isLaptop || isTablet) && 20) || 18
      ),
      fontWeight: 400,
      lineHeight: '110%'
    },
    [VARIANTS_KEYS.alphaBurger]: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      color: 'var(--alpha)'
    },
    [VARIANTS_KEYS.alphaCommonBurger]: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      color: 'var(--alpha)'
    },
    // use this for HomePage Faculty
    [VARIANTS_KEYS.beta]: {
      ...multiMedia({
        desktop: { fontSize: '16px' },
        laptop: { fontSize: '14px' },
        tablet: { fontSize: '12px' },
        phone: { fontSize: '12px' }
      }),
      fontWeight: 400,
      lineHeight: '120%'
    },
    // use this for HomePage Mission
    [VARIANTS_KEYS.gamma]: {
      fontSize: toUnit(
        (isDesktop && 24) || (isLaptop && 18) || (isTablet && 20) || 16
      ),
      fontWeight: 400,
      lineHeight: '120%'
    },
    // use this for HomePage Testimonials
    [VARIANTS_KEYS.delta]: {
      fontSize: toUnit((isLaptop && 16) || (isTablet && 20) || 18),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.deltaBurger]: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      color: 'var(--alpha)'
    },
    [VARIANTS_KEYS.epsilon]: {
      fontSize: toUnit((isDesktop && 18) || 16),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.zeta]: {
      fontSize: toUnit((isDesktop && 20) || (isTablet && 14) || 16),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.zetaAsH2]: {
      ...media('marginBottom', toUnit(24), {
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.eta]: {
      fontSize: toUnit((isDesktop && 14) || 12),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.theta]: {
      fontSize: toUnit(
        (isDesktop && 16) || ((isLaptop || isTablet) && 12) || 14
      ),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.iota]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 20) || 16),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.kappa]: {
      marginTop: toUnit((isDesktop && 24) || 16),
      ...multiMedia({
        fontSize: '16px',
        tablet: {
          fontSize: '14px'
        }
      }),
      fontWeight: 400,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.kappa2]: {
      marginTop: toUnit((isDesktop && 24) || 16),
      ...multiMedia({
        fontSize: '16px',
        tablet: {
          fontSize: '14px'
        },
        phone: {
          fontSize: '14px'
        }
      }),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.lambda]: {
      marginTop: 0,
      fontSize: toUnit(16),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.mu]: {
      marginTop: 0,
      fontSize: toUnit(
        (isDesktop && 80) || (isLaptop && 64) || (isTablet && 56) || 40
      ),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.nu]: {
      marginTop: 0,
      fontSize: toUnit((isDesktop && 36) || (isLaptop && 48) || 32),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.xi]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 14) || 12),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--phi)'
    },
    [VARIANTS_KEYS.omicron]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 16) || 14),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--omega)'
    },
    [VARIANTS_KEYS.omicronWhite]: {
      fontSize: toUnit((isMobile && 14) || 16),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--alpha)'
    },
    [VARIANTS_KEYS.pi]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 18) || 16),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--alpha)'
    },
    [VARIANTS_KEYS.rho]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 20) || 16),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.sigma]: {
      fontSize: toUnit((isDesktop && 18) || 16),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.tau]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 14) || 12),
      fontWeight: 400,
      lineHeight: '120%',
      marginBottom: toUnit(8)
    },
    [VARIANTS_KEYS.upsilon]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 24) || 20),
      fontWeight: 500,
      lineHeight: '120%'
      // position: 'absolute'
    },
    [VARIANTS_KEYS.phi]: {
      marginTop: toUnit((isDesktop && 32) || 16),
      fontSize: toUnit(
        ((isDesktop || isLaptop) && 40) || (isTablet && 36) || 30
      ),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.hi]: {
      fontSize: toUnit(((isDesktop || isLaptop || isTablet) && 14) || 12),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--beta)'
    },
    [VARIANTS_KEYS.betaA]: {
      ...media('fontSize', '14px', {
        laptop: '12px'
      }),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.alphaA]: {
      ...media('fontSize', '12px', {
        phone: '14px'
      }),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--alpha)'
    },
    [VARIANTS_KEYS.xiBadge]: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%',
      color: '#8B8B8B'
    },
    [VARIANTS_KEYS.omicronBadge]: {
      ...multiMedia({
        desktop: {
          fontSize: '16px',
          marginTop: '8px'
        },
        laptop: {
          fontSize: '14px',
          marginTop: '4px'
        },
        tablet: {
          fontSize: '14px',
          marginTop: '4px'
        },
        phone: {
          fontSize: '14px',
          marginTop: '4px'
        }
      }),
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--omega)'
    },
    [VARIANTS_KEYS.alphaUnderheading]: {
      ...multiMedia({
        desktop: {
          fontSize: '24px'
        },
        laptop: {
          fontSize: '20px'
        },
        tablet: {
          fontSize: '20px',
          color: 'var(--alpha)'
        },
        phone: {
          fontSize: '18px',
          color: 'var(--alpha)'
        }
      }),
      fontWeight: 400,
      lineHeight: '110%'
    },
    [VARIANTS_KEYS.thanks]: {
      color: '#1143a7',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.whoSeeLecturesOmega]: {
      ...multiMedia({
        fontSize: '14px',
        tablet: { fontSize: '16px' }
      }),
      color: 'var(--omega)',
      display: 'inline',
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.whoSeeLecturesBeta]: {
      ...multiMedia({
        fontSize: '14px',
        tablet: { fontSize: '16px' }
      }),
      color: 'var(--beta)',
      display: 'inline',
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.whyUsName]: {
      ...multiMedia({
        desktop: { fontSize: '20px' },
        laptop: { fontSize: '18px' },
        tablet: { fontSize: '18px' },
        phone: { fontSize: '20px' }
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.jobVideo]: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--beta)',
      marginTop: '8px'
    },
    [VARIANTS_KEYS.jobVideoPrograms]: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%',
      color: 'var(--upsilon)',
      marginTop: '8px'
    },
    [VARIANTS_KEYS.nameVideo]: {
      ...multiMedia({
        fontSize: '16px',
        desktop: { fontSize: '18px' }
      }),
      fontWeight: 500,
      lineHeight: '120%',
      color: '#FFF'
    },
    [VARIANTS_KEYS.nameVideoPrograms]: {
      ...multiMedia({
        fontSize: '16px',
        desktop: { fontSize: '18px' }
      }),
      fontWeight: 500,
      lineHeight: '120%',
      color: 'var(--omega)'
    },
    [VARIANTS_KEYS.catalogCourses]: {
      fontWeight: 400,
      ...multiMedia({
        desktop: { fontSize: '14px' },
        laptop: { fontSize: '12px' },
        phone: { fontSize: '14px' }
      }),
      width: 'max-content',
      textAlign: 'center',
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.footerSubscriber]: {
      fontWeight: 500,
      fontSize: '20px',
      color: 'var(--beta)',
      lineHeight: '120%',
      ...multiMedia({
        desktop: { margin: '23px 0 32px 0' },
        laptop: { margin: '56px 0 32px 0' },
        tablet: { margin: '56px 0 24px 0' },
        phone: { margin: '40px 0 32px 0' }
      })
    }
  } as const

  const styles = {
    marginTop: 0,
    marginBottom: 0,
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.P {...props} styles={styles} />
}

export default P
