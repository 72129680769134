import { LOCALES } from 'fractals'

export const LABEL = {
  [LOCALES.en]: 'About',
  [LOCALES.ru]: 'Об институте',
  [LOCALES.uz]: 'Institut haqida',
  [LOCALES.kk]: 'Институт туралы'
} as const

export const TITLE = {
  [LOCALES.en]: 'Our mission',
  [LOCALES.ru]: 'Наша миссия',
  [LOCALES.uz]: 'Bizning vazifamiz',
  [LOCALES.kk]: 'Біздің миссиямыз'
}

export const MISSION = {
  [LOCALES.en]:
    'Creation of an accessible modern system of higher and additional online education. Distance learning at our institute makes it possible to obtain a sought-after profession from home and work in leading domestic and international organizations.',
  [LOCALES.ru]:
    'Создание доступной современной системы высшего и дополнительного онлайн-образования. Дистанционное обучение в нашем институте даёт возможность получить востребованную профессию из дома, работать в передовых отечественных и международных организациях.',
  [LOCALES.uz]:
    'Oliy va qoʻshimcha onlayn taʼlimning qulay zamonaviy tizimini yaratish. Institutimizdagi masofaviy ta’lim uydan turib talab qilinadigan kasbni egallash hamda yetakchi mahalliy va xalqaro tashkilotlarda ishlash imkonini beradi.',
  [LOCALES.kk]:
    'Жоғары және қосымша онлайн білім берудің қолжетімді заманауи жүйесін құру. Біздің институтта қашықтан оқыту қажетті мамандықты үйден алуға және жетекші отандық және халықаралық ұйымдарда жұмыс істеуге мүмкіндік береді.'
}

export const WATCHING_LIVE = {
  [LOCALES.en]: 'Watching live **now**',
  [LOCALES.ru]: 'Смотрят видеолекции **сейчас**',
  [LOCALES.uz]: 'Video ma’ruzalarni tomosha qiling **hozir**',
  [LOCALES.kk]: 'Бейне дәрістерді **қазір** қараңыз'
}

export const IMG_ALT = {
  [LOCALES.en]: 'People studying',
  [LOCALES.ru]: 'Люди учатся',
  [LOCALES.uz]: 'O’quvchilar o’qiyapti',
  [LOCALES.kk]: 'Оқушылар оқыяптар'
}
