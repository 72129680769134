import React from 'react'
import { toUnit } from 'fractals'
import { IconContainer } from '@/fractals/components'
import { TBooksProps } from './types'
import { XLINKHREF } from './constants'

export const Books = ({ position, size, ...props }: TBooksProps) => {
  const styles = {
    '& svg':
      (position === 'upRight' &&
        size === 'sm' && {
          width: toUnit(200),
          borderRadius: '0 16px 0 0'
        }) ||
      (size === 'sm' && {
        width: toUnit(200)
      }) ||
      undefined, // * default direction is up
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      {position === 'right' ? (
        <svg
          width='176'
          height='157'
          viewBox='0 0 176 157'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'>
          <rect
            x='-26'
            y='-119'
            width='319'
            height='276'
            fill='url(#pattern8)'
          />
          <defs>
            <pattern
              id='pattern8'
              patternContentUnits='objectBoundingBox'
              width='1'
              height='1'>
              <use
                xlinkHref='#image0_5156_1494'
                transform='matrix(0.00125 0 0 0.00144475 0 -0.00060462)'
              />
            </pattern>
            <image
              id='image0_5156_1494'
              width='800'
              height='693'
              xlinkHref={XLINKHREF}
            />
          </defs>
        </svg>
      ) : (
        <svg
          width='400'
          height='73'
          viewBox='0 0 400 73'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'>
          <rect y='-170' width='400' height='346.5' fill='url(#pattern7)' />
          <defs>
            <pattern
              id='pattern7'
              patternContentUnits='objectBoundingBox'
              width='1'
              height='1'>
              <use
                xlinkHref='#image0_5325_818'
                transform='scale(0.00125 0.001443)'
              />
            </pattern>
            <image
              id='image0_5325_818'
              width='800'
              height='693'
              xlinkHref={XLINKHREF}
            />
          </defs>
        </svg>
      )}
    </IconContainer>
  )
}

export default Books
