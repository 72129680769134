import * as React from 'react'
import { StyledJSX } from 'ui'
import { TAProps } from './types'

const A = ({ /* variant, */ ...props }: TAProps) => {
  // const stylesVariants = {
  //   [VARIANTS_KEYS.alpha]: {}
  // } as const

  const styles = {
    // ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.A {...props} styles={styles} />
}

export default A
