import qs from 'qs'
import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import { TGetWhyUsDataProps, TResPrograms } from './types'

export const getWhyUsData = async ({ context }: TGetWhyUsDataProps) => {
  try {
    const { currentProgramSlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    const queryHome = qs.stringify({
      fields: ['name'],
      populate: {
        whyUs: {
          fields: ['name', 'description'],
          populate: {
            image: {
              fields: ['url', 'width', 'height', 'alternativeText']
            }
          }
        }
      },
      locale: context.locale
    })

    const queryProgram = qs.stringify({
      fields: ['name'],
      populate: {
        whyUs: {
          fields: ['name', 'description'],
          populate: {
            image: {
              fields: ['url', 'width', 'height', 'alternativeText']
            }
          }
        }
      },
      filters: {
        slug: {
          $eq: currentProgramSlug
        }
      },
      locale: context.locale
    })

    if (!currentProgramSlug) {
      const res = await fetcher<TResPrograms>(
        `${PATHS.back.root.index}${PATHS.back.api.whyUses.index}?${queryHome}`
      )

      return (
        res?.data?.map(item => ({
          name: item.name,
          whyUs: item.whyUs?.map(item2 => item2) || null
        }))?.[0] || null
      )
    }

    const programResult = await fetcher<TResPrograms>(
      `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${queryProgram}`
    )

    if (
      programResult?.data?.[0]?.whyUs?.length &&
      programResult?.data?.[0]?.whyUs
    ) {
      return (
        programResult?.data?.map(item => ({
          name: item.name,
          whyUs: item.whyUs?.map(item2 => item2) || null
        }))?.[0] || null
      )
    }

    const homeResult = await fetcher<TResPrograms>(
      `${PATHS.back.root.index}${PATHS.back.api.whyUses.index}?${queryHome}`
    )

    return (
      homeResult?.data?.map(item => ({
        name: item.name,
        whyUs: item.whyUs?.map(item2 => item2) || null
      }))?.[0] || null
    )
  } catch (e) {
    console.log('%cgetWhyUsData.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default getWhyUsData
