import { DefaultSeoProps } from 'next-seo'
// import truncate from 'truncate'
// import { routesFront, company, mituinstitute } from '@/config/index'
import { ENV, PATHS, TRANSLATIONS } from 'fractals'

const seoParams = {
  title:
    // `${company.name} | ${company.tagline}`,
    'Онлайн образование от MITM.institute | Обучение современным профессиям',
  desc:
    //  truncate(pros.join('. '), 120)
    'Самый инновационный ВУЗ страны - MITM.institute предлагает дистанционные онлайн-курсы по востребованным направлениям и специальностям. Удобный формат обучения со скидкой 30% Государственная аккредитация! Актуальные подходы к образованию вместе с экспертами-практиками'
}

const seo: DefaultSeoProps = {
  openGraph: {
    type: 'website',
    url: PATHS.front.root.index,
    title: seoParams.title,
    description: seoParams.desc,
    locale: 'ru',
    site_name: TRANSLATIONS.companyName.ru,
    images: [
      {
        url: `${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`,
        width: 512,
        height: 512,
        alt: TRANSLATIONS.companyName.ru,
        type: 'image/png'
      }
    ]
  },
  // twitter: {
  //   handle: '@handle',
  //   site: '@site',
  //   cardType: 'summary_large_image',
  // },
  defaultTitle: seoParams.title,
  description: seoParams.desc,
  canonical: '/',
  dangerouslySetAllPagesToNoIndex: ENV.verselEnv !== 'production',
  dangerouslySetAllPagesToNoFollow: ENV.verselEnv !== 'production'
  // noindex: true,
  // nofollow: true
}

export default seo
