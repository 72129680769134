import { useCallback, useEffect, useRef, useState } from 'react'

type ResizeObserverHook = () => [
  (ref: HTMLElement | null) => void,
  ResizeObserverEntry | null
]

const useResizeObserver: ResizeObserverHook = () => {
  const [entry, setEntry] = useState<ResizeObserverEntry | null>(null)
  const [targetRef, setTargetRef] = useState<Element | null>(null)

  const resizeObserver = useRef<ResizeObserver | null>(null)

  const unobserve = useCallback(() => {
    if (resizeObserver.current && targetRef) {
      resizeObserver.current.unobserve(targetRef)
    }
  }, [targetRef])

  const observe = useCallback(() => {
    if (targetRef) {
      resizeObserver.current = new ResizeObserver(
        (entryElement: ResizeObserverEntry[]) => {
          if (entryElement[0]) {
            setEntry(entryElement[0])
          }
        }
      )
      resizeObserver.current.observe(targetRef)
    }
  }, [targetRef])

  useEffect(() => {
    observe()
    return () => unobserve()
  }, [unobserve, observe])

  return [setTargetRef, entry]
}

export default useResizeObserver
