import { /* CSSProperties , */ RefObject, useContext, useMemo } from 'react'
import { ModalStylesCtx } from '../../../../../contexts'

type TypeUseDesktopAnimationConfigProps = {
  wrapperEntry: ResizeObserverEntry | null
  buttonBounding?: DOMRect | null
  modalRef: RefObject<HTMLElement | null>
}

const useDesktopAnimationConfig = ({
  wrapperEntry,
  modalRef,
  buttonBounding
}: TypeUseDesktopAnimationConfigProps) => {
  const {
    desktopStyles: {
      outerPaddingLeft,
      outerPaddingRight,
      outerPaddingTop,
      outerPaddingBottom,
      maxWidth
    }
  } = useContext(ModalStylesCtx)

  const scrollHeight = modalRef.current?.offsetHeight as number

  const wrapperWidth = wrapperEntry?.borderBoxSize[0]?.inlineSize
    ? wrapperEntry.borderBoxSize[0].inlineSize
    : 0

  const wrapperHeight = wrapperEntry?.borderBoxSize[0]?.blockSize
    ? wrapperEntry.borderBoxSize[0].blockSize
    : 0

  const totalWidthWithPadding =
    (maxWidth || 500) + outerPaddingLeft + outerPaddingRight

  const totalHeightWithPadding =
    (scrollHeight || 600) + outerPaddingTop + outerPaddingBottom

  const isOverflowX = totalWidthWithPadding >= wrapperWidth
  const isOverflowY = totalHeightWithPadding >= wrapperHeight

  const width = isOverflowX
    ? wrapperWidth - (outerPaddingLeft + outerPaddingRight)
    : maxWidth || 500

  const height = isOverflowY
    ? wrapperHeight - (outerPaddingTop + outerPaddingBottom)
    : scrollHeight || 600

  const posTop = isOverflowY ? 0 : (wrapperHeight - height) / 2

  const posLeft = isOverflowX
    ? outerPaddingLeft || 0
    : (wrapperWidth - width) / 2

  const transformShown = 'translate3d(0, 0, 0) scale(1)'

  // temporary using assertion here. To replace
  const transformHidden = `translate3d(${
    isOverflowX
      ? (buttonBounding?.left as number) -
          width / 2 +
          (buttonBounding?.width as number) / 2 -
          outerPaddingLeft || 0
      : (buttonBounding?.left as number) -
        width / 2 -
        posLeft +
        (buttonBounding?.width as number) / 2
  }px, ${
    isOverflowY
      ? (buttonBounding?.top as number) -
        scrollHeight / 2 +
        (buttonBounding?.height as number) / 2
      : (buttonBounding?.top as number) +
        outerPaddingTop -
        posTop -
        height / 2 -
        (buttonBounding?.height as number) / 2
  }px, 0) scale(${(buttonBounding?.width as number) / width}, ${
    (buttonBounding?.height as number) / height
  })`

  const memoizedConfig = useMemo(
    () => ({
      transformHidden,
      transformShown,
      maxWidth: width,
      maxHeight: height,
      posTop,
      posLeft,
      configReady:
        !/NaN/g.test(transformHidden) &&
        !/NaN/g.test(transformShown) &&
        scrollHeight &&
        buttonBounding &&
        Object.values(buttonBounding).every(
          (value?: number) =>
            typeof value === 'number' && wrapperWidth && wrapperHeight
        )
    }),
    [
      transformHidden,
      transformShown,
      posTop,
      posLeft,
      height,
      width,
      scrollHeight,
      buttonBounding,
      wrapperWidth,
      wrapperHeight
    ]
  )

  return memoizedConfig
}

export default useDesktopAnimationConfig
