import { LOCALES } from 'fractals'
import cardCheck from './images/card-check.svg'
import layers from './images/layers.svg'
import star from './images/star.svg'
import order from './images/order.svg'

// TODO add translations
export const HEADING = {
  [LOCALES.en]: 'С нами ваши возможности больше, чем вы думали',
  [LOCALES.ru]: 'С нами ваши возможности больше, чем вы думали',
  [LOCALES.uz]: 'С нами ваши возможности больше, чем вы думали',
  [LOCALES.kk]: 'С нами ваши возможности больше, чем вы думали'
} as const

export const CARDS = {
  [LOCALES.en]: [
    {
      header: 'Рассрочка на оплату обучения',
      points: [
        'Помесячная оплата',
        'Отсутствие переплат',
        'Прозрачные условия рассрочки',
        'Доверие более 6000 студентов'
      ],
      img: { ...cardCheck, alt: 'cardCheck' }
    },
    {
      header: 'Перевод из другого вуза',
      points: [
        'Обратитесь в приёмную комиссию',
        'Предоставьте пакет документов',
        'Наши сотрудники готовы ответить на все выши вопросы и оказать содействие'
      ],
      img: { ...layers, alt: 'layers' }
    },
    {
      header: 'Трудоустройство и карьера',
      points: [
        'Помогут составить резюме',
        'Подберут подходящие вакансии',
        'Подготовят к собеседованию'
      ],
      img: { ...order, alt: 'order' }
    },
    {
      header: 'Специальные условия и привилегии',
      points: [
        'Людям с ограниченными возможностями, студентам из многодетных семей и льготным категориям',
        'Студентам, оплатившим два или более семестра одновременно',
        'Победителям олимпиад, золотым медалистам, выпускникам колледжей с красным дипломом'
      ],
      img: { ...star, alt: 'star' }
    }
  ],
  [LOCALES.ru]: [
    {
      header: 'Рассрочка на оплату обучения',
      points: [
        'Помесячная оплата',
        'Отсутствие переплат',
        'Прозрачные условия рассрочки',
        'Доверие более 6000 студентов'
      ],
      img: { ...cardCheck, alt: 'cardCheck' }
    },
    {
      header: 'Перевод из другого вуза',
      points: [
        'Обратитесь в приёмную комиссию',
        'Предоставьте пакет документов',
        'Наши сотрудники готовы ответить на все выши вопросы и оказать содействие'
      ],
      img: { ...layers, alt: 'layers' }
    },
    {
      header: 'Трудоустройство и карьера',
      points: [
        'Помогут составить резюме',
        'Подберут подходящие вакансии',
        'Подготовят к собеседованию'
      ],
      img: { ...order, alt: 'order' }
    },
    {
      header: 'Специальные условия и привилегии',
      points: [
        'Людям с ограниченными возможностями, студентам из многодетных семей и льготным категориям',
        'Студентам, оплатившим два или более семестра одновременно',
        'Победителям олимпиад, золотым медалистам, выпускникам колледжей с красным дипломом'
      ],
      img: { ...star, alt: 'star' }
    }
  ],
  [LOCALES.uz]: [
    {
      header: 'Рассрочка на оплату обучения',
      points: [
        'Помесячная оплата',
        'Отсутствие переплат',
        'Прозрачные условия рассрочки',
        'Доверие более 6000 студентов'
      ],
      img: { ...cardCheck, alt: 'cardCheck' }
    },
    {
      header: 'Перевод из другого вуза',
      points: [
        'Обратитесь в приёмную комиссию',
        'Предоставьте пакет документов',
        'Наши сотрудники готовы ответить на все выши вопросы и оказать содействие'
      ],
      img: { ...layers, alt: 'layers' }
    },
    {
      header: 'Трудоустройство и карьера',
      points: [
        'Помогут составить резюме',
        'Подберут подходящие вакансии',
        'Подготовят к собеседованию'
      ],
      img: { ...order, alt: 'order' }
    },
    {
      header: 'Специальные условия и привилегии',
      points: [
        'Людям с ограниченными возможностями, студентам из многодетных семей и льготным категориям',
        'Студентам, оплатившим два или более семестра одновременно',
        'Победителям олимпиад, золотым медалистам, выпускникам колледжей с красным дипломом'
      ],
      img: { ...star, alt: 'star' }
    }
  ],
  [LOCALES.kk]: [
    {
      header: 'Рассрочка на оплату обучения',
      points: [
        'Помесячная оплата',
        'Отсутствие переплат',
        'Прозрачные условия рассрочки',
        'Доверие более 6000 студентов'
      ],
      img: { ...cardCheck, alt: 'cardCheck' }
    },
    {
      header: 'Перевод из другого вуза',
      points: [
        'Обратитесь в приёмную комиссию',
        'Предоставьте пакет документов',
        'Наши сотрудники готовы ответить на все выши вопросы и оказать содействие'
      ],
      img: { ...layers, alt: 'layers' }
    },
    {
      header: 'Трудоустройство и карьера',
      points: [
        'Помогут составить резюме',
        'Подберут подходящие вакансии',
        'Подготовят к собеседованию'
      ],
      img: { ...order, alt: 'order' }
    },
    {
      header: 'Специальные условия и привилегии',
      points: [
        'Людям с ограниченными возможностями, студентам из многодетных семей и льготным категориям',
        'Студентам, оплатившим два или более семестра одновременно',
        'Победителям олимпиад, золотым медалистам, выпускникам колледжей с красным дипломом'
      ],
      img: { ...star, alt: 'star' }
    }
  ]
} as const
