import { Image, TCloudinaryImageData } from 'fractals'
import { StaticImageData } from 'next/image'

type TProps = Omit<TCloudinaryImageData<'least'>, 'url'> & {
  url: string | StaticImageData
  fill: boolean
  // width: string
  // height: string
}

const BackgroundImage = ({
  height,
  width,
  url,
  alternativeText,
  fill
}: TProps) => (
  <Image
    colorVariant='transparent'
    src={url}
    alt={alternativeText || 'REPLACE THIS WITH CONST'}
    fill={fill}
    // width={width}
    // height={height}
    sizes='(max-width: 768px) 50vw, 10vw'
    containerProps={{
      styles: {
        '& img': {
          position: 'relative',
          zIndex: -1
        }
      }
    }}
  />
)

export default BackgroundImage
