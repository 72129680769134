/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { ENV } from '../constants'

export const FB_PIXEL_ID = ENV.fbPixelId

export const pageview = () => {
  window?.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window?.fbq('track', name, options)
}
