import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { useTheme } from 'styled-components'
import Color from 'color'
import { TIconScreenProps } from './types'

const IconScreen = ({ colorsVariant, ...props }: TIconScreenProps) => {
  const { colors } = useTheme()

  const styles = {
    width: toUnit(144),
    height: toUnit(112),
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='144'
        height='112'
        viewBox='0 0 144 112'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 0C3.58172 0 0 3.58172 0 8V72C0 76.4183 3.58173 80 8 80H38.6106L31 112H74L72.0974 80H136C140.418 80 144 76.4183 144 72V8C144 3.58172 140.418 0 136 0H8Z'
          fill={`${Color(colors[colorsVariant]).rgb().string()}`}
        />
      </svg>
    </IconContainer>
  )
}

export default IconScreen
