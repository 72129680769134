import { useContext, useEffect, useRef, useState } from 'react'
import { ModalShowCtx, ModalStylesCtx } from '../../../contexts'
import {
  useAria,
  useCloseButtons,
  useKeyboardEscape,
  usePrefersReducedMotion
} from '../../../hooks'
import { getKeyframes, parseDuration } from '../../../utils'
import { ModalBackgroundOverlayDesktop } from '../../atoms'
import { TypeModalDesktopProps, useDesktopAnimationConfig } from './fractals'

const ModalDesktop = ({
  shown,
  setShown,
  buttonInfo,
  wrapperResizeEntry,
  children
}: TypeModalDesktopProps) => {
  const [isHiding, setIsHiding] = useState(false)
  const modalDesktopWrapperRef = useRef<HTMLDivElement | null>(null)
  const modalRef = useRef<HTMLDivElement | null>(null)

  const { modalId, isPoppingHistory } = useContext(ModalShowCtx)
  const {
    desktopStyles: { animationDuration, animationEaseFunction }
  } = useContext(ModalStylesCtx)

  useKeyboardEscape(() => setIsHiding(true))

  const reducedMotion = usePrefersReducedMotion()

  const {
    transformHidden,
    transformShown,
    configReady,
    posLeft,
    posTop,
    maxWidth
  } = useDesktopAnimationConfig({
    wrapperEntry: wrapperResizeEntry,
    modalRef,
    buttonBounding: buttonInfo?.bounding
  })

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current?.focus()
    }

    if (isHiding) {
      buttonInfo?.target?.focus()
    }
  }, [isHiding, shown, buttonInfo, configReady])

  useEffect(() => {
    if (isPoppingHistory) {
      setIsHiding(true)
    }
  }, [isPoppingHistory])

  useCloseButtons({ modalRef, close: () => setIsHiding(true) })

  const ariaAttributes = useAria()

  return (
    <div
      ref={modalDesktopWrapperRef}
      style={{
        maxHeight: '100vh',
        overflowY: 'auto',
        height: '100%'
      }}>
      <ModalBackgroundOverlayDesktop
        onClick={() => setIsHiding(true)}
        show={shown && !isHiding}
        duration={parseDuration(
          reducedMotion ? 1 : animationDuration || 300,
          2
        )}
      />
      <style>
        {`
		${getKeyframes({
      name: 'hide',
      keyframes: {
        _0: {
          opacity: 1,
          transform: transformShown
        },
        _75: {
          opacity: 0.9
        },
        _100: {
          opacity: 0,
          transform: transformHidden
        }
      }
    })}

		${getKeyframes({
      name: 'show',
      keyframes: {
        _0: {
          opacity: 0,
          transform: transformHidden
        },
        _25: {
          opacity: 0.9
        },
        _100: {
          opacity: 1,
          transform: transformShown
        }
      }
    })}
				[data-modal="true"] {
						will-change: transform, opacity;
						position: relative;
						box-sizing: border-box;
						width: 100%;
						top: ${posTop}px;
						left: ${posLeft}px;
						opacity: 0;
						visibility: hidden;
						animation-fill-mode: forwards;
						animation-timing-function: ${
              animationEaseFunction || 'cubic-bezier(0.33, 1, 0.68, 1)'
            };
						animation-duration: ${
              reducedMotion ? '1ms' : parseDuration(animationDuration || 200)
            };
					}
					
					[data-modal-show="shown"] {
						visibility: visible;
						animation-name: show;
					}

					[data-modal-show="hidden"] {
						visibility: visible;
						animation-name: hide;
					}
					`}
      </style>
      <div
        role='dialog'
        aria-modal
        aria-describedby={ariaAttributes['aria-describedby']}
        aria-labelledby={ariaAttributes['aria-labelledby']}
        id={modalId}
        data-modal
        data-modal-show={
          shown && configReady ? `${!isHiding ? 'shown' : 'hidden'}` : ''
        }
        ref={modalRef}
        tabIndex={-1}
        style={{
          outline: 'none',
          maxWidth
        }}
        onAnimationEnd={() => {
          if (isHiding) {
            document.documentElement.style.overflowY = 'auto'
            setTimeout(() => {
              setIsHiding(false)
              setShown(false)
            }, 0)
          }
        }}>
        <div>{children}</div>
      </div>
    </div>
  )
}

ModalDesktop.displayName = 'ModalDesktop'

export default ModalDesktop
