import {
  Container,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  IconContainer
} from './widgets'

export const Layout = {
  Container,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  IconContainer
}

export default Layout
