import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconYoutubeFooterProps } from './types'

const IconYoutubeFooter = ({ ...props }: TIconYoutubeFooterProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      // transform:
      //   (direction === 'upRight' && 'rotate(45deg)') ||
      //   (direction === 'right' && 'rotate(90deg)') ||
      //   (direction === 'downRight' && 'rotate(135deg)') ||
      //   (direction === 'down' && 'rotate(180deg)') ||
      //   (direction === 'downLeft' && 'rotate(225deg)') ||
      //   (direction === 'left' && 'rotate(270deg)') ||
      //   (direction === 'upLeft' && 'rotate(315deg)') ||
      // * default direction is up
    },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M23.5057 6.22941C23.2289 5.20141 22.4184 4.39087 21.3904 4.1141C19.5123 3.6001 11.9999 3.6001 11.9999 3.6001C11.9999 3.6001 4.48762 3.6001 2.60954 4.09433C1.60131 4.3711 0.771002 5.20141 0.494232 6.22941C0 8.10749 0 12.002 0 12.002C0 12.002 0 15.9164 0.494232 17.7747C0.771002 18.8027 1.58154 19.6132 2.60954 19.89C4.50739 20.404 11.9999 20.404 11.9999 20.404C11.9999 20.404 19.5123 20.404 21.3904 19.9097C22.4184 19.633 23.2289 18.8224 23.5057 17.7944C23.9999 15.9164 23.9999 12.0218 23.9999 12.0218C23.9999 12.0218 24.0197 8.10749 23.5057 6.22941Z'
          fill='white'
        />
        <path
          d='M9.60742 15.5998L15.8545 12.0018L9.60742 8.40381V15.5998Z'
          fill='black'
        />
      </svg>
    </IconContainer>
  )
}

export default IconYoutubeFooter
