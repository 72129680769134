import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  Image,
  toUnit,
  InfoTag,
  Div,
  useMedia,
  ENV,
  multiMedia
} from 'fractals'
import { insertWhiteSpacesAfterPrepositions, Layout, slugify } from 'ui'
import {
  LABEL,
  TITLE,
  MISSION,
  WATCHING_LIVE,
  IMG_ALT,
  IconScreenStand,
  TMissionProps,
  styles
} from './fractals'

const Mission = ({ mission }: TMissionProps) => {
  const [label, title, missionTranslated, watchingLive, alt] = useTranslations(
    LABEL,
    TITLE,
    MISSION,
    WATCHING_LIVE,
    IMG_ALT
  )

  const { isTablet, isLaptop, isDesktop } = useMedia()

  return (
    <Section
      id={slugify(title)}
      styles={{
        ...multiMedia({
          phone: { paddingBottom: '176px' }
        })
      }}
      spacingVariant='beta'
      colorsVariant='alpha'>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <Layout.Grid
          styles={{
            gridTemplateColumns:
              ((isDesktop || isLaptop) && '4fr 3fr') || undefined,
            gap: toUnit(((isDesktop || isLaptop) && 32) || 40)
          }}>
          <Layout.GridItem
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start'
            }}>
            <H2 variant='beta' styles={{ order: 2 }}>
              {title}
            </H2>
            <InfoTag colorVariant='alpha' styles={{ order: 1 }}>
              {label}
            </InfoTag>
            <P variant='gamma' styles={{ order: 3 }}>
              {insertWhiteSpacesAfterPrepositions(missionTranslated)}
            </P>
          </Layout.GridItem>
          <Layout.GridItem>
            <Div
              styles={{
                position: 'relative',
                width: '100%',
                ...multiMedia({
                  padding: '16px 32px',
                  borderRadius: '24px',
                  desktop: {
                    height: '324px',
                    padding: '20px 56px 20.36px 102.72px',
                    borderRadius: '32px'
                  },
                  laptop: {
                    padding: '16px 32px 16px 56px',
                    height: '257px'
                  },
                  tablet: { height: '288px' },
                  phone: { height: '200px' }
                }),
                backgroundColor: 'var(--gamma)'
              }}>
              <Image
                src={mission?.[0]?.main?.url || '/'}
                fill
                alt={alt}
                colorVariant='gamma'
                borderRadiusVariant='alpha'
                containerProps={{
                  styles: {
                    position: 'relative',
                    zIndex: 2,
                    maxWidth: '100%',
                    width: '100%',
                    height: '100%',
                    '& img': {
                      objectFit: 'cover',
                      ...multiMedia({
                        laptop: { width: '296px', height: '257px' },
                        tablet: { width: '585px', height: '288px' },
                        phone: { width: '279px', height: '200px' }
                      })
                    },
                    marginLeft: 'auto'
                  }
                }}
              />

              <Div styles={styles.container}>
                <Div
                  styles={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                  {mission?.[0]?.whoSeeLectures
                    ?.map(({ url }, idx) => (
                      <Div
                        key={`WhoSeeLectures-DivImage__${idx + 1}`}
                        styles={{
                          position: 'relative',
                          width: '48px',
                          height: '48px',
                          ':not(:first-child)': {
                            marginLeft: '-16px'
                          }
                        }}>
                        <Image
                          src={url || '/'}
                          alt={`WhoSeeLectures__${idx + 1}` || ''}
                          fill
                          containerProps={{
                            styles: {
                              borderRadius: '8px',
                              '& img': {
                                objectFit: 'cover'
                              }
                            }
                          }}
                          colorVariant='transparent'
                        />
                      </Div>
                    ))
                    .slice(0, 4)}
                </Div>
                <Div>
                  <P variant='whoSeeLecturesOmega'>
                    Смотрят <br /> видеолекции{' '}
                  </P>
                  <P variant='whoSeeLecturesBeta'>сейчас</P>
                </Div>
              </Div>

              <IconScreenStand
                styles={{
                  position: 'absolute',
                  right:
                    (isDesktop && '45%') ||
                    (isLaptop && '10%') ||
                    (isTablet && '50%') ||
                    '18%',
                  bottom: toUnit(
                    (isDesktop && -80) ||
                      (isLaptop && -56) ||
                      (isTablet && -90) ||
                      -64
                  ), // ! this value should be in sync with spacing variant `beta` in project level Section component from fractals
                  zIndex: 1
                }}
              />
            </Div>
          </Layout.GridItem>
        </Layout.Grid>
      </Container>
    </Section>
  )
}

export default Mission
