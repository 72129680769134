export {
  IDS,
  H1,
  FOR_WHOM_HEADING,
  LEAST_PROGRAM_CONTENTS_HEADING,
  FULL_PROGRAM_CONTENTS_HEADING,
  COST_HEADING,
  OUR_PROGRAMS,
  COST_FORMAT_HEADING_DISTANCE,
  COST_FORMAT_HEADING_MIXED,
  COST_FORMAT_SUBHEADING_MIXED
} from './constants'
// TODO: fix import/no-cycle
// eslint-disable-next-line import/no-cycle
export type { TProgramPageProps } from './types'
export { Discount, DiscountPsihologiyaIsHigherEducation } from './components'
export { getSeoData } from './api'
