import { LOCALES } from 'fractals'

export const DISCOUNT_TEXT = {
  [LOCALES.en]: 'Discount',
  [LOCALES.ru]: 'Скидка',
  [LOCALES.uz]: 'Chegirma',
  [LOCALES.kk]: 'Жеңілдік'
} as const

export const UNTIL = {
  [LOCALES.en]: 'until',
  [LOCALES.ru]: 'до',
  [LOCALES.uz]: 'gacha',
  [LOCALES.kk]: 'дейін'
} as const
