import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  Image,
  InfoTag,
  Div,
  useAt,
  useMedia,
  multiMedia,
  Span
} from 'fractals'
import { Layout, slugify, StyledJSX } from 'ui'
import { v4 as uuidv4 } from 'uuid'
import Color from 'color'
import {
  TStudyStepsProps,
  LABEL,
  TITLE,
  STEP_TITLE_1,
  STEP_DESCRIPTION_1,
  STEP_TITLE_2,
  STEP_DESCRIPTION_2,
  STEP_TITLE_3,
  STEP_DESCRIPTION_3,
  STEP_TITLE_4,
  STEP_DESCRIPTION_4,
  STEP_TITLE_5,
  STEP_DESCRIPTION_5,
  STEP_TITLE_6,
  STEP_DESCRIPTION_6,
  ADDITIONAL_INFO,
  girl,
  IconScreen
} from './fractals'

const StudySteps = ({ colorVariant, studySteps }: TStudyStepsProps) => {
  const [
    label,
    title,
    stepTitle1,
    stepDescription1,
    stepTitle2,
    stepDescription2,
    stepTitle3,
    stepDescription3,
    stepTitle4,
    stepDescription4,
    stepTitle5,
    stepDescription5,
    stepTitle6,
    stepDescription6,
    additionalInfo
  ] = useTranslations(
    LABEL,
    TITLE,
    STEP_TITLE_1,
    STEP_DESCRIPTION_1,
    STEP_TITLE_2,
    STEP_DESCRIPTION_2,
    STEP_TITLE_3,
    STEP_DESCRIPTION_3,
    STEP_TITLE_4,
    STEP_DESCRIPTION_4,
    STEP_TITLE_5,
    STEP_DESCRIPTION_5,
    STEP_TITLE_6,
    STEP_DESCRIPTION_6,
    ADDITIONAL_INFO
  )
  const { isLaptop, isDesktop, isTablet, isMobile } = useMedia()
  const { isCourse } = useAt()
  const [isLoaded, setIsLoaded] = useState(false)

  const steps = (studySteps &&
    studySteps?.length !== 0 &&
    studySteps.map(step => ({
      id: uuidv4(),
      ...step
    }))) || [
    {
      id: uuidv4(),
      name: stepTitle1,
      description: stepDescription1
    },
    {
      id: uuidv4(),
      name: stepTitle2,
      description: stepDescription2
    },
    {
      id: uuidv4(),
      name: stepTitle3,
      description: stepDescription3
    },
    {
      id: uuidv4(),
      name: stepTitle4,
      description: stepDescription4
    },
    {
      id: uuidv4(),
      name: stepTitle5,
      description: stepDescription5
    },
    {
      id: uuidv4(),
      name: stepTitle6,
      description: stepDescription6
    }
  ]

  // const [visibleSteps, setVisibleSteps] = useState(steps.slice())
  // const [animate, setAnimate] = useState(false)

  const [visibleCardIndex, setVisibleCardIndex] = useState(0)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleCardIndex(prevIndex => (prevIndex + 1) % steps.length)
    }, 3000)

    return () => clearInterval(interval)
  }, [steps.length])

  const visibleCard = steps[visibleCardIndex]

  // variant for three steps
  const slicedSteps = [
    steps[visibleCardIndex % steps.length],
    steps[(visibleCardIndex + 1) % steps.length],
    steps[(visibleCardIndex + 2) % steps.length]
  ]

  return (
    <Section
      id={slugify(title)}
      spacingVariant='beta'
      colorsVariant='gamma'
      styles={{
        '*': { boxSizing: 'border-box' }
      }}>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <Layout.Flex
          cols={((isDesktop || isLaptop) && 2) || 1}
          styles={{
            flexDirection: 'column'
          }}>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start'
            }}>
            <H2 variant='beta' styles={{ order: 2 }}>
              {title}
            </H2>
            <InfoTag colorVariant='omega' styles={{ order: 1 }}>
              {label}
            </InfoTag>
          </Layout.FlexItem>
          <Layout.Flex
            cols={isTablet || isMobile ? 1 : 2}
            gap={toUnit((isTablet && 64) || (isMobile && 40) || 32)}
            proportion={(isDesktop && '1:2') || undefined}
            styles={{
              '& > div': {
                ...multiMedia({
                  desktop: { minHeight: '100px' },
                  laptop: { minHeight: '600px' },
                  tablet: { minHeight: '600px' },
                  phone: { minHeight: '600px' }
                })
              }
            }}>
            <Div
              className={`${isLoaded ? '' : 'before-load-overflow-visible'}`}
              styles={{
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                '& > div': {
                  zIndex: 10
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1,
                  backgroundColor:
                    (colorVariant?.color &&
                      Color(colorVariant?.color).rgb().string()) ||
                    'var(--beta)',
                  width: '2px',
                  height: '100%'
                }
              }}>
              <AnimatePresence>
                <motion.div
                  className={`${isLoaded ? '' : 'before-load-opacity-1'}`}
                  key={slicedSteps[0]?.id}
                  initial={{ y: 0, opacity: 0 }}
                  animate={{ y: -300, opacity: 1, pathLength: 3 }}
                  exit={{ y: -600, opacity: 0 }}
                  transition={{
                    duration: 1,
                    when: 'beforeChildren',
                    staggerChildren: 0.3,
                    type: 'spring',
                    ease: [0.17, 0.67, 0.83, 0.67]
                  }}>
                  <Layout.FlexItem
                    className={`${
                      isLoaded ? '' : 'before-load-position-static'
                    }`}
                    key={`Stem_item__${slicedSteps[0]?.id}`}
                    styles={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      ...multiMedia({
                        desktop: { padding: '32px' },
                        laptop: { padding: '32px' },
                        tablet: { padding: '24px' },
                        phone: { padding: '24px' }
                      }),
                      maxWidth: '100%',
                      borderRadius: '16px',
                      backgroundColor:
                        (colorVariant?.color &&
                          Color(colorVariant?.color).rgb().string()) ||
                        'var(--beta)',
                      color: 'white'
                    }}>
                    <StyledJSX.P
                      styles={{
                        marginTop: 0,
                        marginBottom: '16px',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '120%'
                      }}>
                      {slicedSteps[0]?.name ?? ''}
                    </StyledJSX.P>
                    <P
                      variant='beta'
                      styles={{
                        ...multiMedia({
                          fontSize: '14px',
                          desktop: { fontSize: '16px' }
                        })
                      }}>
                      {slicedSteps[0]?.description ?? ''}
                    </P>
                  </Layout.FlexItem>

                  <Layout.FlexItem
                    className={`${
                      isLoaded ? '' : 'before-load-position-static'
                    }`}
                    key={`Stem_item__${slicedSteps[1]?.id}`}
                    styles={{
                      position: 'absolute',
                      top: '200px',
                      left: 0,
                      right: 0,
                      ...multiMedia({
                        desktop: { padding: '32px' },
                        laptop: { padding: '32px' },
                        tablet: { padding: '24px' },
                        phone: { padding: '24px' }
                      }),
                      maxWidth: '100%',
                      borderRadius: '16px',
                      backgroundColor: 'rgb(230,230,230)',
                      color: 'black'
                    }}>
                    <StyledJSX.H5
                      styles={{
                        marginTop: 0,
                        marginBottom: '16px',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '120%'
                      }}>
                      {slicedSteps[1]?.name ?? ''}
                    </StyledJSX.H5>
                    <P
                      variant='beta'
                      styles={{
                        ...multiMedia({
                          fontSize: '14px',
                          desktop: { fontSize: '16px' }
                        })
                      }}>
                      {slicedSteps[1]?.description ?? ''}
                    </P>
                  </Layout.FlexItem>

                  <Layout.FlexItem
                    className={`${
                      isLoaded ? '' : 'before-load-position-static'
                    }`}
                    key={`Stem_item__${slicedSteps[2]?.id}`}
                    styles={{
                      position: 'absolute',
                      top: '400px',
                      left: '9%',
                      right: '9%',
                      ...multiMedia({
                        desktop: { padding: '24px' },
                        laptop: { padding: '24px' },
                        tablet: { padding: '16px' },
                        phone: { padding: '16px' }
                      }),
                      maxWidth: '82%',
                      borderRadius: '16px',
                      backgroundColor: '#E2E0E0',
                      color: '#4F4F4F'
                    }}>
                    <StyledJSX.H5
                      styles={{
                        marginTop: 0,
                        marginBottom: '16px',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '120%'
                      }}>
                      {slicedSteps[2]?.name ?? ''}
                    </StyledJSX.H5>
                    <P
                      variant='beta'
                      styles={{
                        color: '#8B8B8B',
                        fontSize: '13px'
                      }}>
                      {slicedSteps[2]?.description ?? ''}
                    </P>
                  </Layout.FlexItem>
                </motion.div>
              </AnimatePresence>
            </Div>

            <Image
              onLoad={() => setIsLoaded(true)}
              colorVariant='transparent'
              borderRadiusVariant='alpha'
              src={girl}
              alt='girl pic'
              fill={isDesktop || isLaptop}
              sizes='(max-width: 768px) 75vw, 75vw'
              quality={75}
              containerProps={{
                styles: {
                  position: 'relative',
                  ...multiMedia({
                    desktop: { minHeight: '500px' },
                    laptop: { minHeight: '506px' },
                    tablet: { minHeight: '464px' },
                    phone: { minHeight: '248px' }
                  }),
                  '& img': {
                    objectFit: 'cover'
                  }
                }
              }}
            />

            {/* TODO: add to journal link here */}
          </Layout.Flex>
        </Layout.Flex>
        {isCourse && (
          <Div
            styles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection:
                ((isDesktop || isLaptop || isTablet) && 'row') || 'column',
              gap: toUnit((isDesktop && 32) || 16),
              marginTop: toUnit(57),
              paddingTop: toUnit(32),
              paddingRight: toUnit(32),
              paddingBottom: toUnit(32),
              paddingLeft: toUnit(32),
              borderRadius: toUnit(16),
              backgroundColor: 'var(--alpha)'
            }}>
            <IconScreen
              colorsVariant='zeta'
              styles={{
                order: ((isDesktop || isLaptop || isTablet) && 1) || 2,
                minWidth: toUnit(144)
              }}
            />
            <P
              variant='gamma'
              styles={{
                order: ((isDesktop || isLaptop || isTablet) && 2) || 1
              }}>
              {additionalInfo}
            </P>
          </Div>
        )}
      </Container>
    </Section>
  )
}

export default StudySteps
