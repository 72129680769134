import { toUnit } from 'fractals'
import { VARIANT_KEYS } from './constants'

export const variants = {
  [VARIANT_KEYS.default]: {
    authorBadge: (position: 'right' | 'left' | null | undefined) => ({
      container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        width: position === 'left' ? '75%' : '',
        alignItems: 'center',
        alignSelf: position === 'right' ? 'end' : '',
        marginRight: position === 'right' ? toUnit(16) : ''
      } as const
    }),
    imageContainer: {
      position: 'relative',
      minWidth: '48px',
      width: '48px',
      height: '48px',
      borderRadius: '50%'
    } as const,
    author: {
      display: 'flex',
      flexDirection: 'column'
    } as const
  },
  [VARIANT_KEYS.homeVideo]: {
    authorBadge: (position: 'right' | 'left' | null | undefined) => ({
      container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        width: position === 'left' ? '75%' : '',
        alignItems: 'center',
        alignSelf: position === 'right' ? 'end' : '',
        marginRight: position === 'right' ? toUnit(16) : ''
      } as const
    }),
    imageContainer: {
      position: 'relative',
      minWidth: '48px',
      width: '48px',
      height: '48px',
      borderRadius: '50%'
    } as const,
    author: {
      display: 'flex',
      flexDirection: 'column-reverse'
    } as const
  },
  [VARIANT_KEYS.programVideo]: {
    authorBadge: (position: 'right' | 'left' | null | undefined) => ({
      container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        width: position === 'left' ? '75%' : '',
        alignItems: 'center',
        alignSelf: position === 'right' ? 'end' : '',
        marginRight: position === 'right' ? toUnit(16) : ''
      } as const
    }),
    imageContainer: {
      position: 'relative',
      minWidth: '48px',
      width: '48px',
      height: '48px',
      borderRadius: '50%'
    } as const,
    author: {
      display: 'flex',
      flexDirection: 'column-reverse'
    } as const
  }
} as const
