import * as React from 'react'
import { TButtonProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Button = React.forwardRef<HTMLButtonElement, TButtonProps>(
  ({ ...props }, ref) => {
    return (
      <StyledElement
        as={ROOT_ELEMENT}
        {...props}
        type={props.type || 'button'}
        ref={ref}
        styles={{
          display: 'inline-block',
          overflow: 'visible',
          maxWidth: '100%',
          height: 'auto',
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          borderStyle: 'none',
          paddingLeft: 0,
          fontFamily: 'inherit',
          fontSize: '100%',
          lineHeight: 1.15,
          textAlign: 'center',
          textTransform: 'none',
          appearance: 'none',
          cursor: 'pointer',
          WebkitTapHighlightColor: 'transparent',
          backgroundColor: 'transparent',
          ...props.styles
        }}
      />
    )
  }
)

export default Button
