import { sortBasedOnKey } from 'fractals'
import { fetchPrograms } from './fetchPrograms'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchPrograms>>
}) => {
  return (
    programsData?.map(item => {
      const { diplomas } = item
      const categoryDiplomas = item.category.diplomas

      const items =
        (diplomas && diplomas.length !== 0 && diplomas) ||
        (categoryDiplomas &&
          categoryDiplomas.length !== 0 &&
          categoryDiplomas) ||
        null

      const diplomasNormalized =
        items?.map(diploma => ({
          name: diploma?.name || null,
          rank: diploma.rank || diploma.rank === 0 ? diploma.rank : null,
          imageDefault:
            (diploma?.imageDefault && {
              url: diploma?.imageDefault.url || null,
              width: diploma?.imageDefault?.width || null,
              height: diploma?.imageDefault?.height || null,
              alternativeText: diploma?.imageDefault?.alternativeText || null
            }) ||
            null,
          imageTemplate:
            (diploma?.imageTemplate && {
              url: diploma?.imageTemplate?.url || null,
              width: diploma?.imageTemplate?.width || null,
              height: diploma?.imageTemplate?.height || null,
              alternativeText: diploma?.imageTemplate?.alternativeText || null
            }) ||
            null
        })) || null

      if (diplomasNormalized && diplomasNormalized.length !== 0)
        return diplomasNormalized

      return null
    })?.[0] || null
  )
}

export default normalizeProgramsData
