import { fetchBanner, normalizeBannerData } from './utils'

export const getBannerData = async () => {
  const bannerData = await fetchBanner()

  if (!bannerData) return null

  const facultiesDataNormalized = normalizeBannerData(bannerData)

  return facultiesDataNormalized
}

export default getBannerData
