import {
  useTranslations,
  Section,
  Container,
  H1,
  P,
  useMedia,
  multiMedia,
  Div
} from 'fractals'
import { Layout, spacesToNonBrakingSpaces, mdToJsx, slugify } from 'ui'
import { Shared } from 'modules'
import {
  THeroProps,
  UNDERHEADING,
  PRIMARY_BTN,
  SECONDARY_BTN,
  Stories
} from './fractals'

const Hero = ({ h1, stories }: THeroProps) => {
  const [heading, underheading, primaryBtn, secondaryBtn] = useTranslations(
    h1,
    UNDERHEADING,
    PRIMARY_BTN,
    SECONDARY_BTN
  )

  const { isTablet, isLaptop, isDesktop, isMobile } = useMedia()

  return (
    <Section
      id={slugify(heading)}
      spacingVariant='alpha'
      styles={{
        paddingTop: 0
      }}>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <Div
          styles={{
            display: 'flex',
            ...multiMedia({
              desktop: {
                flexWrap: 'wrap'
              },
              laptop: {
                flexWrap: 'wrap'
              },
              tablet: {
                flexWrap: 'wrap-reverse'
              },
              phone: {
                flexWrap: 'wrap-reverse'
              }
            })
          }}>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              boxSizing: 'border-box',
              ...multiMedia({
                desktop: { flexBasis: 'calc(100% / 2)', minWidth: 0 },
                laptop: { flexBasis: 'calc(100% / 2)', minWidth: 0 },
                tablet: {
                  marginTop: '-29px',
                  width: '640px',
                  height: '425px',
                  padding: '32px 24px',
                  backgroundColor: '#3A52EE',
                  borderRadius: '16px',
                  zIndex: 2,
                  minWidth: 'fit-content'
                },
                phone: {
                  marginTop: '-42px',
                  width: '343px',
                  height: '389px',
                  padding: '24px',
                  backgroundColor: '#3A52EE',
                  borderRadius: '16px',
                  zIndex: 2,
                  minWidth: 'fit-content'
                }
              })
            }}>
            <H1
              variant='alphaMainScreen'
              styles={{
                '& strong': {
                  fontWeight: 'inherit',
                  color: 'var(--beta)',
                  ...multiMedia({
                    tablet: {
                      color: 'var(--alpha)'
                    },
                    phone: {
                      color: 'var(--alpha)'
                    }
                  })
                }
              }}>
              {mdToJsx(heading, { replace: ['p'] })}
            </H1>
            <P variant='alphaUnderheading'>{underheading}</P>
            <Layout.Flex
              cols={isDesktop || isLaptop || isTablet ? 'auto' : 1}
              styles={{
                marginTop: 'auto',
                ...multiMedia({
                  desktop: { gap: '24px' },
                  laptop: { gap: '24px' },
                  tablet: { gap: '24px' },
                  phone: { gap: '32px' }
                }),
                '& > *': {
                  display: 'flex',
                  ...multiMedia({
                    width: 'calc((100% / 2) - 12px)',
                    phone: {
                      width: '100%'
                    }
                  }),
                  '& > button': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1
                  }
                }
              }}>
              <Layout.FlexItem>
                <Shared.LeadPopup
                  buttonProps={{
                    colorsVariant: isTablet || isMobile ? 'alpha' : 'beta',
                    children: spacesToNonBrakingSpaces(primaryBtn)
                  }}
                />
              </Layout.FlexItem>
              <Layout.FlexItem>
                <Shared.LeadPopup
                  buttonProps={{
                    colorsVariant:
                      isTablet || isMobile ? 'alphaReverse' : 'betaReverse',
                    children: spacesToNonBrakingSpaces(secondaryBtn)
                  }}
                />
              </Layout.FlexItem>
            </Layout.Flex>
          </Layout.FlexItem>
          <Layout.FlexItem
            styles={{
              ...multiMedia({
                desktop: { flexBasis: 'calc(100% / 2)', minWidth: 0 },
                laptop: { flexBasis: 'calc(100% / 2)', minWidth: 0 },
                tablet: {
                  width: '100%',
                  minWidth: 0
                },
                phone: {
                  width: '100%',
                  minWidth: 0
                }
              })
            }}>
            {/* <Images /> */}
            <Stories urls={stories?.map(({ url }) => url)} />
          </Layout.FlexItem>
        </Div>
      </Container>
    </Section>
  )
}

export default Hero
