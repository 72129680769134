import { sortBasedOnKey } from 'fractals'
import { fetchCategories } from './fetchCategories'

export const normalizeCategoriesData = ({
  categoriesData
}: {
  categoriesData: Awaited<ReturnType<typeof fetchCategories>>
}) => {
  // const categoriesDataNormalizedSorted = sortBasedOnKey(categoriesData, 'rank')
  // if (categoriesData && categoriesData.length) {
  return { categories: categoriesData }
  // }
  // return null
}

export default normalizeCategoriesData
