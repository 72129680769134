import { BASE64PIXELS as BASE64PIXELS_BASE } from 'ui'

export const BASE64PIXELS = {
  ...BASE64PIXELS_BASE,
  beta: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO0Cnr3HwAEwwJ7SC0hQwAAAABJRU5ErkJggg==',
  gamma:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8n+n6HwAGSgKOkhpaAwAAAABJRU5ErkJggg==',
  delta:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8vN3pPwAHfgLtqKQn7AAAAABJRU5ErkJggg==',
  epsilon:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP0XtP5HwAFSwKBFuaHPAAAAABJRU5ErkJggg==',
  eta: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOsjXvyHwAF3wLAEcH1lQAAAABJRU5ErkJggg=',
  zeta: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8HeL5HwAGgwKZNSxbqQAAAABJRU5ErkJggg=='
} as const
