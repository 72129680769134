import qs from 'qs'
import { PATHS, fetcher } from 'fractals'
import { TGetVideoDataProps, TResVideos } from './types'

export const getVideoData = async ({ context }: TGetVideoDataProps) => {
  try {
    const query = qs.stringify({
      fields: ['name'],
      populate: {
        reviews: {
          fields: ['title'],
          populate: {
            authors: {
              fields: ['name', 'job', 'richtext'],
              populate: {
                image: {
                  fields: ['url', 'width', 'height', 'alternativeText']
                }
              }
            }
          }
        },
        media: {
          fields: ['title'],
          populate: {
            video: {
              fields: ['url'],
              populate: {
                light: {
                  fields: ['url', 'width', 'height', 'alternativeText']
                }
              }
            }
          }
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResVideos>(
      `${PATHS.back.root.index}${PATHS.back.api.videos.index}?${query}`
    )

    // console.log(
    //   '%cgetVideoData.ts line:34 res?.data',
    //   'color: #007acc;',
    //   res?.data
    // )

    return res?.data || null
  } catch (e) {
    console.log('%cgetVideoData.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default getVideoData
