import * as React from 'react'
import { StyledJSX } from 'ui'
import { TFlexItemProps } from './types'
import { ROOT_ELEMENT } from './constants'

const FlexItem = React.forwardRef<HTMLDivElement, TFlexItemProps>(
  ({ ...props }, ref) => {
    return (
      <StyledJSX.Element
        ref={ref}
        {...props}
        as={ROOT_ELEMENT}
        styles={{ ...props.styles }}
      />
    )
  }
)

export default FlexItem
