import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, media, multiMedia } from 'fractals'
import Color from 'color'
import { TButtonProps } from './types'
import {
  BORDER_RADIUS_VARIANTS_KEYS,
  COLORS_VARIANT_KEYS,
  SIZE_VARIANT_KEYS
  // NOT_WORKING_YET_MESSAGE
} from './constants'

// TODO: refactor isA here and move it onto Button component in UI

// TODO: add animation on button that it randomly does transform: scale(1.02) quickly, then pulses a bit back and forth then get bigger then resizes back to origin, whe whole thing takes about 2-2.5sec, infinitely
const Button = React.forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      facultyColor,
      borderRadiusVariant,
      colorsVariant,
      sizeVariant,
      // isA,
      ...props
    },
    ref
  ) => {
    // const [notWorkingYetMessage] = useTranslations(NOT_WORKING_YET_MESSAGE)

    const borderRadiusVariants = {
      [BORDER_RADIUS_VARIANTS_KEYS.alpha]: {
        borderRadius: toUnit(8)
      },
      [BORDER_RADIUS_VARIANTS_KEYS.beta]: {
        borderRadius: toUnit(51)
      },
      [BORDER_RADIUS_VARIANTS_KEYS.circle]: {
        borderRadius: '50%'
      }
    } as const

    // TODO: generalize transform to not repeat it
    const transform = 'scale(1.014)'
    const colorsVariants = {
      [COLORS_VARIANT_KEYS.omega]: {
        backgroundColor: 'var(--omega)',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--beta)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--beta)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.omegaReverse]: {
        borderColor: 'var(--omega)',
        color: 'var(--omega)',
        '@media (hover: hover)': {
          '&:hover': {
            borderColor: 'var(--beta)',
            backgroundColor: 'var(--beta)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          borderColor: 'var(--beta)',
          backgroundColor: 'var(--beta)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.omegaReverseFaculty]: {
        borderWidth: '1.5px',
        borderColor:
          (facultyColor && Color(facultyColor).rgb().string()) || 'var(--beta)',
        color: 'var(--omega)',
        '@media (hover: hover)': {
          '&:hover': {
            borderColor:
              (facultyColor && Color(facultyColor).rgb().string()) ||
              'var(--beta)',
            backgroundColor:
              (facultyColor && Color(facultyColor).rgb().string()) ||
              'var(--beta)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          borderColor:
            (facultyColor && Color(facultyColor).rgb().string()) ||
            'var(--beta)',
          backgroundColor:
            (facultyColor && Color(facultyColor).rgb().string()) ||
            'var(--beta)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.omegaReverseGrey]: {
        borderColor: 'var(--phi)',
        color: 'var(--omega)',
        '@media (hover: hover)': {
          '&:hover': {
            borderColor: 'var(--beta)',
            backgroundColor: 'var(--beta)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          borderColor: 'var(--beta)',
          backgroundColor: 'var(--beta)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.alpha]: {
        backgroundColor: 'var(--alpha)',
        color: 'var(--omega)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--omega)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--omega)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.alphaAlt]: {
        backgroundColor: 'var(--alpha)',
        color: 'var(--omega)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--beta)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--beta)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.alphaReverse]: {
        borderColor: 'var(--alpha)',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--alpha)',
            color: 'var(--omega)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--alpha)',
          color: 'var(--omega)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.betaReverse]: {
        borderColor: 'var(--beta)',
        color: 'var(--beta)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--beta)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--beta)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.beta]: {
        backgroundColor: 'var(--beta)',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--omega)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--omega)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.delta]: {
        backgroundColor: 'var(--eta)',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--omega)',
            color: 'var(--alpha)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--omega)',
          color: 'var(--alpha)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.betaCost]: {
        backgroundColor: 'var(--beta)',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: 'var(--alpha)',
            color: 'var(--omega)',
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: 'var(--alpha)',
          color: 'var(--omega)',
          transform
        }
      },
      [COLORS_VARIANT_KEYS.transparent]: {
        backgroundColor: 'transparent',
        color: 'var(--omega)',
        '@media (hover: hover)': {
          '&:hover': {
            transform
          }
        },
        '&:focus-visible': {
          transform
        }
      },
      [COLORS_VARIANT_KEYS.alphaTransparent]: {
        backgroundColor: 'transparent',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            transform
          }
        },
        '&:focus-visible': {
          transform
        }
      },
      [COLORS_VARIANT_KEYS.ask]: {
        backgroundColor: '#27c500',
        color: 'var(--alpha)',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: '#27c500',
            color: 'var(--alpha)',
            opacity: 0.8,
            transform
          }
        },
        '&:focus-visible': {
          backgroundColor: '#27c500',
          color: 'var(--alpha)',
          transform
        }
      }
    } as const

    const sizeVariants = {
      [SIZE_VARIANT_KEYS['3xs']]: {
        fontSize: toUnit(10),
        paddingTop: toUnit(4),
        paddingRight: toUnit(8),
        paddingBottom: toUnit(4),
        paddingLeft: toUnit(8)
      },
      [SIZE_VARIANT_KEYS.xs]: {
        fontSize: toUnit(12),
        paddingTop: toUnit(14),
        paddingRight: toUnit(14),
        paddingBottom: toUnit(14),
        paddingLeft: toUnit(14)
      },
      [SIZE_VARIANT_KEYS.sm]: {
        fontSize: toUnit(14),
        paddingTop: toUnit(12),
        paddingRight: toUnit(24),
        paddingBottom: toUnit(12),
        paddingLeft: toUnit(24)
      },
      [SIZE_VARIANT_KEYS.md]: {
        padding: '16px',
        ...multiMedia({
          fontSize: '16px',
          desktop: {
            fontSize: '18px'
          }
        })
        // fontSize: toUnit(16),
        // ...media('fontSize', toUnit(16), {
        //   desktop: toUnit(18)
        // }),
        // paddingTop: toUnit(16),
        // ...media('paddingRight', toUnit(16), {
        //   desktop: toUnit(32)
        // }),
        // paddingBottom: toUnit(16),
        // ...media('paddingLeft', toUnit(16), {
        //   desktop: toUnit(32)
        // })
      },
      [SIZE_VARIANT_KEYS.medium]: {
        fontSize: toUnit(16),
        padding: `${toUnit(14.4)} ${toUnit(16)}`
      },
      [SIZE_VARIANT_KEYS.lg]: {
        fontSize: toUnit(18),
        paddingTop: toUnit(60),
        paddingRight: toUnit(60),
        paddingBottom: toUnit(60),
        paddingLeft: toUnit(60)
      },
      [SIZE_VARIANT_KEYS.default]: {
        fontSize: toUnit(14),
        fontWeight: 500,
        padding: `${toUnit(12.8)} ${toUnit(8)}`
      }
    } as const

    const styles = {
      borderWidth: toUnit(1),
      borderStyle: 'solid',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      fontWeight: 400,
      lineHeight: '120%',
      transition:
        'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
      ':disabled': {
        ...((props.styles?.[':disabled'] && props.styles[':disabled']) || {}),
        opacity: '0.5',
        cursor: 'not-allowed',
        transform: 'none !important',
        backgroundColor: 'inherit !important',
        color: 'inherit !important'
      },
      ...borderRadiusVariants[borderRadiusVariant || 'alpha'],
      ...colorsVariants[colorsVariant || 'beta'],
      ...sizeVariants[sizeVariant || 'md'],
      // '&:focus-visible-visible': {
      //   transform: 'scale(1.02)'
      // },
      '&:active': {
        transform: 'scale(0.98)'
      },
      ...props.styles
    } as const

    // if (isA)
    //   return (
    //     <A
    //       {...props}
    //       styles={styles}
    //       // onClick={() => props.onClick || alert(notWorkingYetMessage)}
    //     />
    //   )

    return (
      <StyledJSX.Button
        {...props}
        ref={ref}
        styles={styles}
        // onClick={() => props.onClick || alert(notWorkingYetMessage)}
      />
    )
  }
)

export default Button
