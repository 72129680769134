import { LOCALES } from 'fractals'

export const DEFAULT_CATEGORY_LABEL = {
  [LOCALES.en]: 'Course',
  [LOCALES.ru]: 'Курс',
  [LOCALES.uz]: 'Kurs',
  [LOCALES.kk]: 'Курс'
} as const

export const HOW_TO_REDUCE = {
  [LOCALES.ru]: 'Как сократить?',
  [LOCALES.en]: 'How to reduce?',
  [LOCALES.uz]: 'Qanday qisaladigan?',
  [LOCALES.kk]: 'Неге қысады?'
} as const

export const IMG_DEFAULT_ALT = {
  [LOCALES.en]: 'Person working',
  [LOCALES.ru]: 'Человек работает',
  [LOCALES.uz]: 'Ishlaydigan odam',
  [LOCALES.kk]: 'Жұмыс істеуші'
} as const

export const CLOSEST_ENROLLMENT = {
  [LOCALES.en]: 'Closest enrollment',
  [LOCALES.ru]: 'Ближайшее зачисление',
  [LOCALES.uz]: 'Eng yaqin yozilish',
  [LOCALES.kk]: 'Ең жақын жазылу'
} as const

export const STUDY_DURATIION = {
  [LOCALES.en]: 'Study duration',
  [LOCALES.ru]: 'Срок обучения',
  [LOCALES.uz]: 'O`qish davomiyligi',
  [LOCALES.kk]: 'Оқыту мерзімі'
} as const

export const STUDY_FORMAT = {
  [LOCALES.en]: 'Study format',
  [LOCALES.ru]: 'Формат обучения',
  [LOCALES.uz]: 'O`qish formati',
  [LOCALES.kk]: 'Оқыту пішімі'
} as const

export const STUDY_FORMAT_VALUE = {
  [LOCALES.en]: 'Remote',
  [LOCALES.ru]: 'Дистанционный',
  [LOCALES.uz]: 'Uzoqda',
  [LOCALES.kk]: 'Ұзақта'
} as const

export const GRADUATION_DOCUMENTS = {
  [LOCALES.en]: 'Graduation documents',
  [LOCALES.ru]: 'Документ по окончании обучения',
  [LOCALES.uz]: 'O`qish tugatish hujjatlari',
  [LOCALES.kk]: 'Оқыту аяқталуы үшін құжаттар'
} as const

export const EDUCATION_LEVEL = {
  [LOCALES.en]: 'Education level',
  [LOCALES.ru]: 'Уровень образования',
  [LOCALES.uz]: 'Ma`lumot darajasi',
  [LOCALES.kk]: 'Білім деңгейі'
} as const

export const HOURS_AMOUNT = {
  [LOCALES.en]: 'Hours amount',
  [LOCALES.ru]: 'Количество часов',
  [LOCALES.uz]: 'Soatlar soni',
  [LOCALES.kk]: 'Сағат саны'
} as const

export const HOURS = {
  [LOCALES.en]: 'hours',
  [LOCALES.ru]: 'час',
  [LOCALES.uz]: 'soat',
  [LOCALES.kk]: 'сағат'
} as const

export const PRIMARY_BTN = {
  [LOCALES.en]: 'Sign up for training',
  [LOCALES.ru]: 'Записаться на обучение',
  [LOCALES.uz]: 'Treningga yoziling',
  [LOCALES.kk]: 'Тренингке жазылыңыз'
} as const

export const SECONDARY_BTN = {
  [LOCALES.en]: 'Ask a question',
  [LOCALES.ru]: 'Задать вопрос',
  [LOCALES.uz]: 'Savol berish',
  [LOCALES.kk]: 'Сұрақ беру'
} as const

export const GRADUATION_DOCUMENTS_DEFAULT = {
  [LOCALES.en]: 'Diploma of state sample',
  [LOCALES.ru]: 'Диплом государственного образца',
  [LOCALES.uz]: 'Davlat nusxasi diplomasi',
  [LOCALES.kk]: 'Диплом құрамы'
} as const
