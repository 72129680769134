'use client'

import { cloneElement, useContext, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { TypeModalWrapperProps, useAppRootElement } from './fractals'
import { ModalShowCtx, ModalStylesCtx } from '../../../contexts'
import { wrapper, suppressHydrationWarningWrapper } from './ModalWrapper.styles'
import { ModalDesktop, ModalMobile } from '../../molecules'
import { useResizeObserver } from '../../../hooks'
import useButtonInfo from './fractals/hooks/useButtonInfo'

const ModalWrapper = ({
  showButton,
  defaultShowButtonText = 'Показать',
  children
}: TypeModalWrapperProps) => {
  const rootMounted = useAppRootElement()
  const { shown, setShown, modalId } = useContext(ModalShowCtx)
  const [setWrapperRef, entry] = useResizeObserver()
  const [setButtonRef, setWrapperEntry, buttonInfo] = useButtonInfo()
  const {
    options: { breakpointSize, noSwipeModal }
  } = useContext(ModalStylesCtx)

  useEffect(() => {
    if (entry && setWrapperEntry) {
      setWrapperEntry(entry)
    }
  }, [entry, setWrapperEntry])

  const inlineWidth = entry?.borderBoxSize[0]?.inlineSize

  return (
    <>
      {cloneElement(
        showButton ?? <button type='button'>Показать</button>,
        {
          onClick: () => setShown(true),
          type: showButton ? showButton.props.type : 'button',
          ref: setButtonRef,
          'data-modal-id': modalId,
          'aria-controls': modalId,
          'aria-haspopup': 'dialog'
        },
        showButton ? showButton.props.children : defaultShowButtonText
      )}
      <div style={suppressHydrationWarningWrapper} suppressHydrationWarning>
        {/* Only for suppressing hydration warning in pages directory. Doesn't affects app functionality */}
        {shown &&
          rootMounted &&
          createPortal(
            <div style={wrapper} ref={setWrapperRef}>
              {(inlineWidth &&
                breakpointSize &&
                inlineWidth >= breakpointSize) ||
              noSwipeModal ? (
                <ModalDesktop
                  shown
                  setShown={setShown}
                  buttonInfo={buttonInfo}
                  wrapperResizeEntry={entry}>
                  {children}
                </ModalDesktop>
              ) : (
                <ModalMobile
                  setShown={setShown}
                  shown
                  showButtonRef={buttonInfo.target}>
                  {children}
                </ModalMobile>
              )}
            </div>,
            document.querySelector('[data-modal-root-element]') as HTMLElement
          )}
      </div>
    </>
  )
}

export default ModalWrapper
