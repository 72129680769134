import { useState, useEffect } from 'react'
import { THEME } from 'fractals'
import { useRouter } from 'next/router'
import { useWindowWidth } from '../useWindowWidth'
import { useWindowHeight } from '../useWindowHeight'

// TODO: figure out a better way to handle this without layout shifts
export const useMedia = () => {
  const windowWidth = useWindowWidth()
  const windowHeight = useWindowHeight()
  const { brakepoints } = THEME
  const { asPath } = useRouter()

  const viewport =
    asPath
      ?.split('?')?.[1]
      ?.split('&')
      ?.find(pair => pair.includes('viewport'))
      ?.split('=')?.[1] || 'phone'

  const [isMobile, setIsMobile] = useState(
    () =>
      viewport === 'phone' ||
      (windowWidth && windowWidth <= brakepoints.md) ||
      false
  )
  const [isTablet, setIsTablet] = useState(
    () =>
      viewport === 'tablet' ||
      (windowWidth &&
        windowWidth > brakepoints.md &&
        windowWidth <= brakepoints.xl) ||
      false
  )

  const [isLaptop, setIsLaptop] = useState(
    () =>
      viewport === 'laptop' ||
      (windowWidth &&
        windowWidth > brakepoints.xl &&
        windowWidth <= brakepoints.x3) ||
      false
  )
  const [isDesktop, setIsDesktop] = useState(
    () =>
      viewport === 'desktop' ||
      (windowWidth && windowWidth > brakepoints.x3) ||
      false
  )

  useEffect(() => {
    if (windowWidth) {
      setIsMobile(windowWidth <= brakepoints.md)
      setIsTablet(windowWidth > brakepoints.md && windowWidth <= brakepoints.xl)
      setIsLaptop(windowWidth > brakepoints.xl && windowWidth <= brakepoints.x3)
      setIsDesktop(windowWidth > brakepoints.x3)
    }
  }, [windowWidth, brakepoints /* , isMobile, isTablet, isLaptop, isDesktop */])

  return { isMobile, isTablet, isLaptop, isDesktop }
}

export default useMedia
