import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { useTheme } from 'styled-components'
import Color from 'color'
import { COLOR_VARIANT_KEYS } from './constants'
import { TIconPlusProps } from './types'

const IconPlus = ({ variant, colorVariant, ...props }: TIconPlusProps) => {
  const { colors } = useTheme()

  const colorVariants = {
    [COLOR_VARIANT_KEYS.alpha]: colors.alpha,
    [COLOR_VARIANT_KEYS.omega]: colors.omega,
    [COLOR_VARIANT_KEYS.beta]: colors.beta,
    [COLOR_VARIANT_KEYS.phi]: colors.phi
  }

  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      transition: 'transform 200ms ease-in',
      transform:
        (variant === 'cross' && 'rotate(-45deg)') ||
        (variant === 'crossMini' && 'rotate(-45deg) scale(0.6)') ||
        undefined // * default is plus
    },
    ...props?.styles
  } as const

  const color =
    (typeof colorVariant === 'string' &&
      colorVariants?.[colorVariant].rgb().string()) ||
    (typeof colorVariant === 'object' &&
      colorVariant?.color &&
      Color(colorVariant.color).rgb().string()) ||
    'var(--beta)'

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12 5V19'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5 12H19'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconContainer>
  )
}

export default IconPlus
