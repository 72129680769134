import * as React from 'react'
import { IconContainer } from 'fractals'
import { TFormCircleProps } from './types'

export const RightTop = ({ ...props }: TFormCircleProps) => {
  const styles = {
    // position: 'absolute',
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='72'
        height='89'
        viewBox='0 0 72 89'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M68.4416 60.2247C60.133 82.472 35.3624 93.7716 13.115 85.4629L-0.000180358 80.5648L30.0881 1.67655e-06L43.2033 4.8981C65.4507 13.2067 76.7502 37.9773 68.4416 60.2247Z'
          fill='#7D5EE4'
        />
      </svg>
    </IconContainer>
  )
}

export default RightTop
