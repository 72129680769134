import { FC } from 'react'
import styled from 'styled-components'

const StyledSvg = styled.svg`
  width: 25px;
  height: 24px;
`

const ChatIcon: FC = () => (
  <StyledSvg viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 12.015C2.5 6.74712 6.71 2 12.52 2C18.2 2 22.5 6.65699 22.5 11.985C22.5 18.1642 17.46 22 12.5 22C10.86 22 9.04 21.5593 7.58 20.698C7.07 20.3876 6.64 20.1572 6.09 20.3375L4.07 20.9384C3.56 21.0986 3.1 20.698 3.25 20.1572L3.92 17.9139C4.03 17.6034 4.01 17.2729 3.85 17.0125C2.99 15.4301 2.5 13.6975 2.5 12.015ZM11.2 12.015C11.2 12.7261 11.77 13.2969 12.48 13.307C13.19 13.307 13.76 12.7261 13.76 12.025C13.76 11.314 13.19 10.7431 12.48 10.7431C11.78 10.7331 11.2 11.314 11.2 12.015ZM15.81 12.025C15.81 12.7261 16.38 13.307 17.09 13.307C17.8 13.307 18.37 12.7261 18.37 12.025C18.37 11.314 17.8 10.7431 17.09 10.7431C16.38 10.7431 15.81 11.314 15.81 12.025ZM7.87 13.307C7.17 13.307 6.59 12.7261 6.59 12.025C6.59 11.314 7.16 10.7431 7.87 10.7431C8.58 10.7431 9.15 11.314 9.15 12.025C9.15 12.7261 8.58 13.2969 7.87 13.307Z'
      fill='#3A52EE'
    />
  </StyledSvg>
)

export default ChatIcon
