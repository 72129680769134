import * as React from 'react'
import { useState } from 'react'
import { motion, useMotionValue, useSpring } from 'framer-motion'
import { Div } from 'fractals'
import { distance } from '@popmotion/popcorn'

const grid = [
  [0, 1],
  [2, 3],
  [4, 5]
]
const size = 6
const gap = 2

const Square = ({
  color,
  active,
  setActive,
  colIndex,
  rowIndex,
  x,
  y
}: any) => {
  const isDragging = colIndex === active.col && rowIndex === active.row
  //   const diagonalIndex = (360 / 6) * (colIndex + rowIndex)
  const d = distance(
    { x: active.col, y: active.row },
    { x: colIndex, y: rowIndex }
  )
  const springConfig = {
    stiffness: Math.max(700 - d * 120, 0),
    damping: 20 + d * 5
  }
  const dx = useSpring(x, springConfig)
  const dy = useSpring(y, springConfig)

  return (
    <motion.div
      drag
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      dragTransition={{ bounceStiffness: 500, bounceDamping: 20 }}
      dragElastic={0.5}
      onDragStart={() => setActive({ row: rowIndex, col: colIndex })}
      style={{
        background: color,
        width: size,
        height: size,
        top: rowIndex * (size + gap),
        left: colIndex * (size + gap),
        position: 'absolute',
        borderRadius: '50%',
        x: isDragging ? x : dx,
        y: isDragging ? y : dy,
        zIndex: isDragging ? 1 : 0
      }}
    />
  )
}

export const SquareLogo = () => {
  const [active, setActive] = useState({ row: 0, col: 0 })
  const x = useMotionValue(0)
  const y = useMotionValue(0)
  const theme = {
    delta: 'rgb(243,185,68)',
    eta: 'rgb(125,94,228)',
    theta: 'rgb(184,204,51)',
    zeta: 'rgb(251,87,75)',
    gamma: 'rgb(223,104,68)',
    epsilon: 'rgb(75,170,135)'
  }

  const themeArray = Object.values(theme)

  return (
    <Div
      style={{
        margin: 0,
        padding: 0,
        width: '40px',
        height: '60px',
        display: 'flex',
        overflow: 'hidden',
        textAlign: 'center',
        placeItems: 'center',
        perspective: '1000px',
        placeContent: 'center',
        fontFamily: 'sans-serif'
      }}
      //   className='squareLogo'
    >
      <motion.div
        // animate={{ "--base-hue": 360 } as any}
        initial={{ '--base-hue': 0 } as any}
        transition={{ duration: 10, loop: Infinity, ease: 'linear' }}
        style={{ width: '100%', height: '100%' }}>
        <motion.div
          style={{
            display: 'flex',
            width: (size + gap) * 4 - gap,
            height: (size + gap) * 4 - gap,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'relative',
            perspective: 500
          }}>
          {grid.map((row, rowIndex) =>
            row.map((_item, colIndex) => {
              const index = rowIndex * grid[0]!.length + colIndex
              const color = themeArray[index % themeArray.length]
              return (
                <Square
                  color={color}
                  x={x}
                  y={y}
                  active={active}
                  setActive={setActive}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                  key={`${rowIndex + colIndex + 1}`}
                />
              )
            })
          )}
        </motion.div>
      </motion.div>
    </Div>
  )
}

export default SquareLogo
