import { media, multiMedia, toUnit } from 'fractals'
import { STYLE_VARIANT_KEYS } from '../../molecules/JournalsCardFull/constants'

export const journalsCardsContainer = {
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridGap: '20px',
  overflow: 'hidden',
  ...multiMedia({
    width: '1216px',
    phone: {
      padding: 0,
      width: '100%'
    },
    tablet: {
      padding: `0 ${toUnit(64)}`,
      width: '640px'
    },
    laptop: {
      padding: `0 ${toUnit(64)}`,
      width: '800px'
    },
    desktop: {
      padding: `0 ${toUnit(64)}`,
      width: '1216px'
    }
  }),
  justifyContent: 'center'
} as const

export const journalsCardsDiv = (idx: number) => ({
  variants: {
    [STYLE_VARIANT_KEYS.default]: {
      justifyContent: 'space-evenly',
      alignItems: 'center',
      display: 'flex',
      ...multiMedia({
        flexDirection: 'column-reverse',
        gap: '17px',
        desktop: {
          flexDirection: idx === 0 ? 'row-reverse' : 'column-reverse',
          height: idx === 0 ? '100%' : '',
          gap: idx === 0 ? '32px' : '17px'
        }
      })
    } as const,
    [STYLE_VARIANT_KEYS.atHome]: {
      display: 'flex',
      gap: '17px',
      ...multiMedia({
        desktop: {
          width: (idx !== 0 && 'min-content') || '',
          flexDirection: (idx === 0 && 'row-reverse') || 'column-reverse',
          justifyContent: (idx === 0 && 'space-between') || '',
          marginLeft: ((idx === 3 || idx === 2) && 'auto') || '',
          marginRight: (idx === 2 && 'auto') || ''
        },
        laptop: {
          width: 'min-content',
          flexDirection: 'column-reverse',
          margin: idx % 2 === 0 ? '0 auto 0 0' : '0 0 0 auto'
        },
        tablet: {
          width: 'min-content',
          flexDirection: 'column-reverse',
          margin: idx % 2 === 0 ? '0 auto 0 0' : '0 0 0 auto'
        },
        phone: {
          width: 'auto',
          flexDirection: 'column-reverse'
        }
      }),
      '& > div:first-child': {
        height: 'auto',
        '& > div:last-child': {
          marginTop: 'auto'
        }
      }
    } as const
  }
})

export const journalsCardsSection = (idx: number) => {
  return {
    transition: 'color 0.3s ease-in-out',
    ':hover': {
      color: 'var(--beta)',
      transition: 'color 0.3s ease'
    },
    ...multiMedia({
      padding: '1rem 0',
      phone: {
        backgroundColor: idx === 0 ? 'var(--beta)' : '',
        padding: idx === 0 ? '56px 0' : '1rem 0'
      }
    }),
    ':nth-child(2)': {
      ...media('paddingTop', '1rem', {
        phone: '56px'
      })
    }
  }
}

export const journalsCardsDivWithButton = {
  display: 'flex',
  justifyContent: 'center',
  margin: toUnit(32)
} as const

export const journalsCardsImageDiv = (idx: number | null) =>
  ({
    variants: {
      [STYLE_VARIANT_KEYS.default]: {
        position: 'relative'
      } as const,
      [STYLE_VARIANT_KEYS.atHome]: {
        position: 'relative',
        ...multiMedia({
          desktop: {
            width: (idx === 0 && '592px') || '384px',
            height: (idx === 0 && '295px') || '192px'
          },
          laptop: { width: '384px', height: '192px' },
          tablet: { width: '304px', height: '192px' },
          phone: { width: 'auto', height: '171px' }
        })
      } as const
    } as const
  } as const)
