export const VARIANTS_KEYS = {
  alpha: 'alpha',
  alphaBurger: 'alphaBurger',
  alphaCommonBurger: 'alphaCommonBurger',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta',
  deltaBurger: 'deltaBurger',
  epsilon: 'epsilon',
  zeta: 'zeta',
  eta: 'eta',
  theta: 'theta',
  iota: 'iota',
  kappa: 'kappa',
  kappa2: 'kappa2',
  lambda: 'lambda',
  mu: 'mu',
  nu: 'nu',
  xi: 'xi',
  omicron: 'omicron',
  omicronWhite: 'omicronWhite',
  pi: 'pi',
  rho: 'rho',
  sigma: 'sigma',
  tau: 'tau',
  upsilon: 'upsilon',
  phi: 'phi',
  hi: 'hi',
  betaA: 'betaA',
  zetaAsH2: 'zetaAsH2',
  alphaA: 'alphaA',
  xiBadge: 'xiBadge',
  omicronBadge: 'omicronBadge',
  alphaUnderheading: 'alphaUnderheading',
  thanks: 'thanks',
  whoSeeLecturesOmega: 'whoSeeLecturesOmega',
  whoSeeLecturesBeta: 'whoSeeLecturesBeta',
  whyUsName: 'whyUsName',
  jobVideo: 'jobVideo',
  jobVideoPrograms: 'jobVideoPrograms',
  nameVideoPrograms: 'nameVideoPrograms',
  nameVideo: 'nameVideo',
  catalogCourses: 'catalogCourses',
  footerSubscriber: 'footerSubscriber'
} as const
