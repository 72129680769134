import * as React from 'react'
import { IconContainer, media, toUnit } from 'fractals'
import { TIconQuoteProps } from './types'

const IconQuote = ({ ...props }: TIconQuoteProps) => {
  const styles = {
    width: toUnit(16),
    height: toUnit(32),
    marginLeft: '0.3rem',
    // position: 'absolute',
    // '& svg': {
    // transform: 'translate(-20px, -45px)'
    // ...media('transform', 'translate(-65px, -75px)', {
    //   phone: 'translate(-20px, -45px)'
    // })
    // (direction === 'upRight' && 'rotate(45deg)') ||
    // (direction === 'right' && 'rotate(90deg)') ||
    // (direction === 'downRight' && 'rotate(135deg)') ||
    // (direction === 'down' && 'rotate(180deg)') ||
    // (direction === 'downLeft' && 'rotate(225deg)') ||
    // (direction === 'left' && 'rotate(270deg)') ||
    // (direction === 'upLeft' && 'rotate(315deg)') ||
    // undefined // * default direction is up
    // },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='48'
        height='37'
        viewBox='0 0 48 37'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.7 21V36.4H0.300001V21L14.3 -6.67572e-06H21.3L15.7 21ZM42.3 21V36.4H26.9V21L40.9 -6.67572e-06H47.9L42.3 21Z'
          fill='#3A52EE'
        />
      </svg>
    </IconContainer>
  )
}

export default IconQuote
