import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetVideosDataProps } from '../types'
import { TResCategories } from '../../getProgramsQuestions/types'
import {
  authorsQuery,
  videoQuery,
  reviewsPopulateVariants,
  videoPopulateVariants
} from '../constants'

export const fetchCategoryVideos = async ({ context }: TGetVideosDataProps) => {
  try {
    const { currentCategorySlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    const query = qs.stringify({
      fields: ['name'],
      populate: {
        authors: authorsQuery,
        video: videoQuery,
        ...reviewsPopulateVariants[currentCategorySlug ?? ''],
        ...videoPopulateVariants[currentCategorySlug ?? '']
      },
      filters: {
        slug: {
          $eq: currentCategorySlug
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResCategories>(
      `${PATHS.back.root.index}${PATHS.back.api.categories.index}?${query}`
    )

    return res?.data?.at(0) || null
  } catch (e) {
    console.log(e)
    return null
  }
}

export default fetchCategoryVideos
