import * as React from 'react'
import { StyledJSX } from 'ui'
import Color from 'color'
import { toUnit } from 'fractals'
import { TInputProps } from './types'
import { TYPE_KEYS } from './constants'

// TODO: more some of the logic to UI
const Option = ({
  // colorsVariant,
  placeholder,
  // type,
  // colorVariant,
  ...props
}: TInputProps) => {
  const types = {
    [TYPE_KEYS.name]: 'text',
    [TYPE_KEYS.phoneNumber]: 'tel',
    [TYPE_KEYS.email]: 'email',
    [TYPE_KEYS.promocode]: 'promocode'
  }

  // const colorsVariants = {
  //   // [COLORS_VARIANT_KEYS.omega]: {
  //   //   backgroundColor: 'var(--omega)',
  //   //   color: 'var(--alpha)',
  //   //   '@media (hover: hover)': {
  //   //     '&:hover': {
  //   //       backgroundColor: 'var(--alpha)',
  //   //       transform
  //   //     }
  //   //   }
  //   // }
  // } as const

  const styles = {
    ...props.styles,
    outline: 'none',
    borderWidth: toUnit(1),
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: 'border-color 200ms ease-in, color 200ms ease-in',
    color: 'var(--omega)'
  } as const

  return (
    <StyledJSX.Option
      {...props}
      // placeholder={placeholders[placeholder]}
      // type={types[type]}
      styles={{
        ...styles
      }}
    />
  )
}

export default Option
