import qs from 'qs'
import { PATHS, fetcher } from 'fractals'
import { TGetStoriesDataProps, TResStories } from './types'

export const getStoriesData = async ({ context }: TGetStoriesDataProps) => {
  try {
    const query = qs.stringify(
      {
        fields: ['name' /* , 'slug' */],
        populate: {
          photo: {
            fields: ['url' /* , 'width', 'height', 'alternativeText' */]
          }
        },
        locale: context.locale
      },
      {
        encodeValuesOnly: true
      }
    )

    const stories = await fetcher<TResStories>(
      `${PATHS.back.root.index}${PATHS.back.api.stories.index}?${query}`
    )

    const storiesNormalized =
      stories?.data?.map(
        ({ photo }) =>
          ({
            url: photo.url || null
          } || null)
      ) || null

    return storiesNormalized
  } catch (e) {
    console.log('%cgetStoriesData.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default getStoriesData
