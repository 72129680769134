import * as React from 'react'
import { Div, IconContainer, toUnit } from 'fractals'
import { TIconUnionFolderProps } from './types'

const IconUnionFolder = ({ ...props }: TIconUnionFolderProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(30),
    '& svg': {
      // transform:
      //   (direction === 'upRight' && 'rotate(45deg)') ||
      //   (direction === 'right' && 'rotate(90deg)') ||
      //   (direction === 'downRight' && 'rotate(135deg)') ||
      //   (direction === 'down' && 'rotate(180deg)') ||
      //   (direction === 'downLeft' && 'rotate(225deg)') ||
      //   (direction === 'left' && 'rotate(270deg)') ||
      //   (direction === 'upLeft' && 'rotate(315deg)') ||
      // * default direction is up
    },
    ...props?.styles
  } as const

  return (
    // <Div
    //   styles={{
    //     borderRadius: '8px',
    //     backgroundColor: '#4D64F6',
    //     width: '40px',
    //     minWidth: '40px',
    //     height: '40px',
    //     minHeight: '40px',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center'
    //   }}>
    <IconContainer {...props} styles={styles}>
      <svg
        width='16'
        height='20'
        viewBox='0 0 16 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.237 5.177L10.823 0.762939C10.492 0.431939 10.053 0.25 9.586 0.25H5C2.381 0.25 0.25 2.381 0.25 5V15C0.25 17.619 2.381 19.75 5 19.75H11C13.619 19.75 15.75 17.619 15.75 15V6.41394C15.75 5.94694 15.568 5.507 15.237 5.177ZM10.75 2.81006L13.189 5.24902H12.5C11.535 5.24902 10.75 4.46402 10.75 3.49902V2.81006ZM11 18.25H5C3.208 18.25 1.75 16.792 1.75 15V5C1.75 3.208 3.208 1.75 5 1.75H9.25V3.5C9.25 5.292 10.708 6.75 12.5 6.75H14.25V15C14.25 16.792 12.792 18.25 11 18.25Z'
          fill='white'
        />
      </svg>
    </IconContainer>
    // </Div>
  )
}

export default IconUnionFolder
