import { marked } from 'marked'
import parse from 'html-react-parser'

export const mdToJsx = (
  md: string,
  { replace }: { replace?: Array<'p' | 'br' | ''> } = { replace: ['p'] }
) => {
  if (!md) {
    /* eslint-disable-next-line no-console */
    console.warn('mdToJsx: md is undefined')
    return null
  }

  // TODO: refactor this to be more generic and extensible
  if (replace?.includes('p') && replace?.includes('br'))
    return parse(
      marked(md)
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/<br \/>/g, '')
    )

  if (replace?.includes('p'))
    return parse(marked(md).replace(/<p>/g, '').replace(/<\/p>/g, ''))

  if (replace?.includes('br')) return parse(marked(md).replace(/<br \/>/g, ''))

  return parse(marked(md))
}
