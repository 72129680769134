import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetFAQDataProps, TResPrograms, TResQuestions } from '../types'

export const fetchQuestions = async ({ context }: TGetFAQDataProps) => {
  try {
    const query = qs.stringify({
      fields: ['name', 'answer', 'rank'],
      locale: context.locale
    })

    const res = await fetcher<TResQuestions>(
      `${PATHS.back.root.index}${PATHS.back.api.questions.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cfetchQuestions.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default fetchQuestions
