import { GetStaticPropsContext } from 'next'
import { fetchFacultyVideos } from './fetchFacultyVideos'
import { fetchCategoryVideos } from './fetchCategoryVideos'
import { Shared } from '@/modules/shared'
import { parseGeneralSlug } from '@/fractals/utils'
import { TAuthor, TVideo } from '../types'

type TNormalizeVideoDataProps = {
  videoData: Awaited<
    ReturnType<typeof fetchFacultyVideos | typeof fetchCategoryVideos>
  >
  generalVideoData: Awaited<ReturnType<typeof Shared.getVideoData>>
  context: GetStaticPropsContext
}

export const normalizeVideoData = ({
  videoData,
  generalVideoData,
  context
}: TNormalizeVideoDataProps) => {
  const { isBachelor, isMaster, isCourse } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  if (!generalVideoData) return null
  if (!videoData?.authors?.length && !videoData?.video?.length)
    return generalVideoData

  const programsVideoObjIndex = generalVideoData.findIndex(
    ({ name }) => name === 'programs'
  )

  if (programsVideoObjIndex < 0) return null

  const originGeneralVideoData = generalVideoData

  const programsVideoObj = originGeneralVideoData.splice(
    programsVideoObjIndex,
    1
  )[0]

  let normalizedAuthors: TAuthor[] | null = null

  if (videoData.authors) {
    normalizedAuthors = videoData.authors
  }

  if (
    'authorsBakalavriat' in videoData &&
    videoData.authorsBakalavriat &&
    isBachelor
  ) {
    normalizedAuthors = videoData.authorsBakalavriat
  }

  if (
    'authorsMagistratura' in videoData &&
    videoData.authorsMagistratura &&
    isMaster
  ) {
    normalizedAuthors = videoData.authorsMagistratura
  }

  if ('authorsKursy' in videoData && videoData.authorsKursy && isCourse) {
    normalizedAuthors = videoData.authorsKursy
  }

  if (programsVideoObj?.reviews?.authors && normalizedAuthors) {
    programsVideoObj.reviews.authors = normalizedAuthors
  }

  let normalizedVideo: TVideo[] | null = null

  if (videoData.authors) {
    normalizedVideo = videoData.video
  }

  if (
    'authorsBakalavriat' in videoData &&
    videoData.authorsBakalavriat &&
    isBachelor
  ) {
    normalizedVideo = videoData.videoBakalavriat
  }

  if (
    'authorsMagistratura' in videoData &&
    videoData.authorsMagistratura &&
    isMaster
  ) {
    normalizedVideo = videoData.videoMagistratura
  }

  if ('authorsKursy' in videoData && videoData.authorsKursy && isCourse) {
    normalizedVideo = videoData.videoKursy
  }

  if (programsVideoObj?.media?.video && normalizedVideo) {
    programsVideoObj.media.video = normalizedVideo
  }

  const result = [programsVideoObj, ...originGeneralVideoData]

  return result
}

export default normalizeVideoData
