export type {
  TObjKey,
  TColor,
  TTimestamp,
  FunctionPropTypes,
  TGenericPropAttributes,
  TTuple,
  TFlatten,
  TValueOf,
  TStyleProp,
  TStyledElementStyledProps,
  TStyledElementProps,
  // TVariantsKeys,
  TVariantProp,
  // TVariant,
  // RecordDefaultVariantsTypes
  ArrayElementType
} from './types'
export { KEYS, BASE64PIXELS, NON_BRAKING_SPACE } from './constants'
export {
  mdToJsx,
  insertWhiteSpacesAfterPrepositions,
  toUnit,
  fetcher,
  isArray,
  removeDuplicatesByKey,
  spacesToNonBrakingSpaces,
  calculateRuCase,
  repeatJSX,
  calculateClosestEnrollment,
  calculateStudyDuration,
  insertLineBrakeBetweenWords,
  getInputId,
  toNumberWithSpaces,
  slugify,
  getImageHeight
} from './utils'
