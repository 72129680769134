const ArrowDown = () => (
  <svg
    width='10'
    height='6'
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 0.818848L5 4.81885L9 0.818848'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowDown
