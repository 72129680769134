import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetJournalsDataProps, TResAllJournals } from './types'

export const getAllJournals = async ({ context }: TGetJournalsDataProps) => {
  try {
    const query = qs.stringify({
      fields: ['slug'],
      populate: {
        faculty: {
          fields: ['name', 'slug', 'color']
        },
        tag: {
          fields: ['name', 'slug', 'color']
        }
      },

      locale: context.locale
    })

    const res = await fetcher<TResAllJournals>(
      `${PATHS.back.root.index}${PATHS.back.api.journals.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cfetchJournals.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default getAllJournals
