import * as React from 'react'
import { StyledJSX } from 'ui'
import { TContainerProps } from './types'
import { ROOT_ELEMENT } from './constants'

const Container = ({ ...props }: TContainerProps) => {
  return (
    <StyledJSX.Element
      as={ROOT_ELEMENT}
      {...props}
      styles={{ ...props.styles }}
    />
  )
}

export default Container
