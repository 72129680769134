import * as React from 'react'
import { Div, IconContainer, Span } from 'fractals'
import { TEllipseProps } from './types'

export const Ellipse = ({ position, ...props }: TEllipseProps) => {
  const styles = {
    // <svg width="140" height="343" viewBox="0 0 140 343" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <circle cx="187" cy="156" r="187" fill="#4D64F7"/>
    // </svg>

    svg:
      (position === 'upRightDesktop' && {
        width: '311px',
        height: '206px',
        viewBox: '0 0 311 206',
        borderRadius: '0 16px 0 0',
        circle: {
          cx: '208',
          cy: '-2',
          r: '208'
        }
      }) ||
      (position === 'upRightTablet' && {
        width: '140px',
        height: '343px',
        viewBox: '0 0 140 343',
        borderRadius: '0 16px 0 0',
        circle: {
          cx: '187',
          cy: '156',
          r: '187'
        }
      }) ||
      (position === 'upRightMobile' && {
        width: '140px',
        height: '374px',
        viewBox: '0 0 140 374',
        borderRadius: '0 16px 0 0',
        circle: {
          cx: '187',
          cy: '187',
          r: '187'
        }
      }) ||
      (position === 'center' && {
        width: '160px',
        height: '73px',
        viewBox: '0 0 160 73',
        circle: {
          cx: '80',
          cy: '37',
          r: '80',
          transform: 'rotate(-180 80 37)'
        }
      }) ||
      (position === 'downLeft' && {
        width: '104px',
        height: '88px',
        borderRadius: '0 0 0 16px',
        viewBox: '0 0 104 88',
        circle: {
          cx: '24',
          cy: '80',
          r: '80'
        }
      }) ||
      (position === 'middle' && {
        width: '416px',
        height: '416px',
        viewBox: '0 0 416 416',
        circle: {
          cx: '208',
          cy: '208',
          r: '208'
        }
      }) ||
      (position === 'upRight' && {
        width: '240px',
        height: '300px',
        viewBox: '0 0 300 250',
        circle: {
          cx: '190',
          cy: '115',
          r: '180'
        }
      }) ||
      (position === 'right' && {
        width: '115px',
        height: '90px',
        viewBox: '0 0 115 90',
        circle: {
          cx: '77',
          cy: '32',
          r: '77',
          transform: 'rotate(-180 77 32)'
        }
      }) ||
      (position === 'left' && {
        width: '116',
        height: '96px',
        viewBox: '0 0 116 96',
        circle: {
          cx: '39',
          cy: '74',
          r: '77'
        }
      }) ||
      (position === 'down' && {
        width: '416px',
        height: '299px',
        viewBox: '0 0 416 299',
        circle: {
          cx: '208',
          cy: '208',
          r: '208'
        }
      }) ||
      undefined, // * default direction is up
    ...props?.styles
  } as const
  return (
    <IconContainer {...props}>
      <Span
        styles={{
          '& svg': {
            borderRadius: styles.svg?.borderRadius
          }
        }}>
        <svg
          width={styles.svg?.width}
          height={styles.svg?.height}
          viewBox={styles.svg?.viewBox}
          // borderRadius={styles.svg?.borderRadius}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <circle
            cx={styles.svg?.circle.cx}
            cy={styles.svg?.circle.cy}
            r={styles.svg?.circle.r}
            transform={styles.svg?.circle.transform}
            fill='#4D64F7'
          />
        </svg>
      </Span>
    </IconContainer>
  )
}

export default Ellipse
