import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetSeoDataProps, TResTags, TResFaculties } from '../types'

export const fetchJournalsSeoData = async ({ context }: TGetSeoDataProps) => {
  const { currentJournalsFacultySlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  const queryTags = qs.stringify({
    fields: ['name', 'slug'],
    populate: {
      seo: {
        fields: [
          'metaTitle',
          'metaRobots',
          'isSEOFriendly',
          'metaDescription',
          'canonicalURL',
          'keywords'
        ]
      }
    },
    filters: {
      slug: {
        $eq: currentJournalsFacultySlug
      }
    },
    locale: context.locale
  })

  const resTags = await fetcher<TResTags>(
    `${PATHS.back.root.index}${PATHS.back.api.tags.index}?${queryTags}`
  )

  // console.log(
  //   '!!!!!!!!!!',
  //   resCategories?.data?.map(el => el)
  // )

  const queryFaculties = qs.stringify({
    fields: ['name', 'label', 'slug', 'rank'],
    populate: {
      seo: {
        fields: [
          'metaTitle',
          'metaRobots',
          'isSEOFriendly',
          'metaDescription',
          'canonicalURL',
          'keywords'
        ]
      }
    },
    filters: {
      slug: {
        $eq: currentJournalsFacultySlug
      }
    },
    locale: context.locale
  })

  const resFaculties = await fetcher<TResFaculties>(
    `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${queryFaculties}`
  )

  return {
    tags: resTags?.data || null,
    faculties: resFaculties?.data || null
  }
}

export default fetchJournalsSeoData
