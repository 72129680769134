import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconArrowProps } from './types'

const IconArrow = ({ direction, ...props }: TIconArrowProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      transform:
        (direction === 'upRight' && 'rotate(45deg)') ||
        (direction === 'right' && 'rotate(90deg)') ||
        (direction === 'downRight' && 'rotate(135deg)') ||
        (direction === 'down' && 'rotate(180deg)') ||
        (direction === 'downLeft' && 'rotate(225deg)') ||
        (direction === 'left' && 'rotate(270deg)') ||
        (direction === 'upLeft' && 'rotate(315deg)') ||
        undefined // * default direction is up
    },

    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      {direction !== 'firefly' ? (
        <svg
          width='10'
          height='12'
          viewBox='0 0 10 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8.70824 4.50001L4.95824 0.750015M4.95824 0.750015L1.20824 4.50001M4.95824 0.750015L4.95824 11.25'
            stroke='black'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : (
        <svg
          width='15'
          height='14'
          viewBox='0 0 15 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.5303 4.80317L14.0607 5.3335L13.5303 5.86383L9.53033 9.86383C9.23743 10.1567 8.76256 10.1567 8.46967 9.86383C8.17677 9.57093 8.17677 9.09606 8.46967 8.80317L11.1893 6.0835H4.66667C3.05584 6.0835 1.75 7.38933 1.75 9.00016C1.75 10.611 3.05584 11.9168 4.66667 11.9168H5C5.41421 11.9168 5.75 12.2526 5.75 12.6668C5.75 13.081 5.41421 13.4168 5 13.4168H4.66667C2.22741 13.4168 0.25 11.4394 0.25 9.00016C0.25 6.5609 2.22741 4.5835 4.66667 4.5835L11.1893 4.5835L8.46967 1.86383C8.17677 1.57093 8.17677 1.09606 8.46967 0.803165C8.76256 0.510273 9.23744 0.510273 9.53033 0.803165L13.5303 4.80317Z'
            fill='#3A52EE'
          />
        </svg>
      )}
    </IconContainer>
  )
}

export default IconArrow
