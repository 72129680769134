import { toUnit } from 'fractals'

export const appliedStyles = {
  '&::after': {
    // boxSizing: 'border-box',
    content: '""',
    display: 'block',
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    backgroundColor: 'var(--beta)'
  }
}
export const appliedStylesSorting = {
  '&::after': {
    // boxSizing: 'border-box',
    color: 'var(--beta)',
    content: '""',
    display: 'block',
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    backgroundColor: 'var(--beta)'
  }
}

export const regularStylesChecker = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: '1:1',
  minWidth: toUnit(20),
  width: '100%',
  maxWidth: toUnit(20),
  minHeight: toUnit(20),
  height: '100%',
  maxHeight: toUnit(20),
  borderWidth: toUnit(1),
  borderStyle: 'solid',
  transition: 'border-color 150ms ease-in-out',
  borderRadius: '50%'
}

export const regularStylesCheckerSorting = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: '1:1',
  width: toUnit(16),
  height: toUnit(16),
  borderWidth: toUnit(1),
  borderStyle: 'solid',
  transition: 'border-color 150ms ease-in-out',
  borderRadius: '50%'
}
