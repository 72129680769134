import { LOCALES } from 'fractals'
import { FilterState } from './types'

export const HEADING = {
  [LOCALES.en]: 'Faculties',
  [LOCALES.ru]: 'Направления',
  [LOCALES.uz]: "Yo'nalishlar",
  [LOCALES.kk]: 'Бағдарламалар'
} as const

export const ALL_FACULTIES = {
  [LOCALES.en]: 'All faculties',
  [LOCALES.ru]: 'Все направления',
  [LOCALES.uz]: "Barcha yo'nalishlar",
  [LOCALES.kk]: 'Барлық бағдарламалар'
} as const

export const IS_TURNED_ON = {
  [LOCALES.en]: 'On',
  [LOCALES.ru]: 'Включено',
  [LOCALES.uz]: 'Yoqilgan',
  [LOCALES.kk]: 'Ашық'
} as const

export const IS_TURNED_OFF = {
  [LOCALES.en]: 'off',
  [LOCALES.ru]: 'Выключено',
  [LOCALES.uz]: 'Yopiq',
  [LOCALES.kk]: 'Жабық'
} as const

export const BY_ALPHABET = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'по алфавиту',
  [LOCALES.uz]: '',
  [LOCALES.kk]: '',
  href: 'alphabet'
} as const

export const BY_PRICE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'по цене',
  [LOCALES.uz]: '',
  [LOCALES.kk]: '',
  href: 'price'
} as const

export const BY_DURATION = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'по продолжительности',
  [LOCALES.uz]: '',
  [LOCALES.kk]: '',
  href: 'duration'
} as const

export const BY_POPULARITY = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'по популярности',
  [LOCALES.uz]: '',
  [LOCALES.kk]: '',
  href: 'popularity'
} as const

export const SORTING_BY = [
  { ...BY_ALPHABET },
  { ...BY_PRICE },
  { ...BY_DURATION }
  // { ...BY_POPULARITY }
]

export const DEFAULT_ITEMS_TO_SHOW = {
  desktop: 8,
  laptop: 8,
  tablet: 6,
  mobile: 3
}

export const initialQueryState: FilterState = {
  withEmployment: false,
  level: [],
  diplomas: [],
  tags: [],
  duration: {
    min: 0,
    max: 0
  },
  price: {
    min: 0,
    max: 0
  }
}
