import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetFooterDataProps, TResFaculties } from '../types'

export const fetchFaculties = async ({ context }: TGetFooterDataProps) => {
  try {
    const query = qs.stringify({
      fields: ['name', 'slug', 'rank'],
      sort: ['rank:asc'],
      locale: context.locale
    })

    const res = await fetcher<TResFaculties>(
      `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cfetchFaculties.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default fetchFaculties
