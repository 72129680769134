import * as React from 'react'
import { IconContainer } from 'fractals'
import styled, { keyframes } from 'styled-components'

import { TSpinnerProps } from './types'

export const Spinner = ({ colorsVariant, ...props }: TSpinnerProps) => {
  const styles = {
    ...props?.styles
  } as const

  const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

  const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

  const Svg = styled.svg`
    animation: ${rotate} 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 50px;
    height: 50px;

    .path {
      stroke: cornflowerblue;
      stroke-linecap: round;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  `

  return (
    <IconContainer {...props} styles={styles}>
      <Svg viewBox='0 0 50 50'>
        <circle
          className='path'
          cx='25'
          cy='25'
          r='20'
          fill='none'
          strokeWidth='5'
        />
      </Svg>
    </IconContainer>
  )
}

export default Spinner
