import { useRouter } from 'next/router'
import Color from 'color'
import {
  toUnit,
  Link,
  Div,
  useTranslations,
  IconArrow,
  useCalculateStudyDuration,
  PATHS,
  LOCALES,
  multiMedia
} from 'fractals'
import { calculateRuCase, Layout, spacesToNonBrakingSpaces } from 'ui'
import { TProgramCardProps } from './types'
import { LEARN_MORE, HOUR } from './constants'
import { StyledCard } from './styled'
import { Circle } from '@/modules/home-page/widgets/Faculties/fractals/components/atoms'
import {
  StyledH3,
  StyledSpan,
  StyledSpanHoverBlack,
  StyledFlexItem
} from '../../atoms'

import { CURRENCY_SYMBOL } from '@/modules/program-page/widgets/Cost/fractals/components/molecules/PriceCard/utils'

export const ProgramCard = ({
  name,
  slug,
  facultyLabel,
  categoryLabel,
  categorySlug,
  months,
  hours,
  years,
  accentColor,
  price
}: TProgramCardProps) => {
  const { locale } = useRouter()
  const [learnMore, hour] = useTranslations(LEARN_MORE, HOUR)
  // const { isCourse, isHigherEducation } = useAt()
  const studyDuration = useCalculateStudyDuration({ months, years })

  return (
    <Link
      href={`/${slug || 'program'}--${
        categorySlug ||
        PATHS.front.general.programs[
          (locale as keyof typeof LOCALES) || 'ru'
        ].substring(1)
      }`}
      variant='alpha'>
      <StyledCard accentColor={accentColor || '#FB5449'}>
        {(facultyLabel || categoryLabel) && (
          <Layout.Flex
            cols='auto'
            gap={toUnit(16)}
            styles={{
              alignItems: 'center'
            }}>
            {facultyLabel && (
              <StyledFlexItem
                styles={{
                  borderRadius: toUnit(8),
                  fontSize: toUnit(12),
                  fontWeight: 400,
                  lineHeight: '120%',
                  backgroundColor:
                    (accentColor && Color(accentColor).rgb().string()) ||
                    'var(--beta)',
                  color: 'var(--alpha)'
                }}>
                <StyledSpanHoverBlack
                  styles={{
                    display: 'inline-block',
                    paddingTop: toUnit(8),
                    paddingRight: toUnit(16),
                    paddingBottom: toUnit(8),
                    paddingLeft: toUnit(16)
                  }}>
                  {facultyLabel}
                </StyledSpanHoverBlack>
              </StyledFlexItem>
            )}
            {categoryLabel && (
              <Layout.FlexItem
                styles={{
                  fontSize: toUnit(12),
                  fontWeight: 400,
                  lineHeight: '120%'
                }}>
                <StyledSpan>{categoryLabel}</StyledSpan>
              </Layout.FlexItem>
            )}
          </Layout.Flex>
        )}
        <Div>
          <StyledH3 variant='epsilon'>{name}</StyledH3>
        </Div>
        {/* TODO: improve these styles to add a gap in between an icon and text */}
        <div>
          <Div
            styles={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: toUnit(18),
              marginBottom: toUnit(24),
              ...multiMedia({
                tablet: {
                  marginBottom: toUnit(16)
                }
              })
            }}>
            {(studyDuration || hours) && (
              <Div
                styles={{
                  fontSize: toUnit(12),
                  fontWeight: 400,
                  lineHeight: '120%',
                  color: 'var(--upsilon)'
                }}>
                <StyledSpan>
                  {studyDuration && studyDuration}
                  {studyDuration && hours && ` / `}
                  {hours && `${hours} ${calculateRuCase(hour, hours, locale)}`}
                </StyledSpan>
              </Div>
            )}
            {price && (
              <Div>
                <StyledSpan
                  styles={{
                    fontSize: toUnit(16),
                    fontWeight: 500,
                    lineHeight: toUnit(19.2),
                    color: 'var(--beta)'
                  }}>{`${price} ${
                  CURRENCY_SYMBOL[locale as keyof typeof LOCALES]
                }`}</StyledSpan>
              </Div>
            )}
          </Div>
          <Layout.Flex
            cols={2}
            gap={toUnit(16)}
            styles={{
              maxWidth: 'max-content',
              alignItems: 'center'
            }}>
            <StyledSpan
              styles={{
                display: 'inline-block',
                fontSize: toUnit(14),
                fontWeight: 500,
                lineHeight: '120%',
                textDecoration: 'underline'
              }}>
              {spacesToNonBrakingSpaces(learnMore)}
            </StyledSpan>
            <IconArrow direction='upRight' />
          </Layout.Flex>
        </div>

        <Circle />
      </StyledCard>
    </Link>
  )
}
export default ProgramCard
