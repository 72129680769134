import { TGetGraduateProfessionsDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getGraduateProfessionsData = async ({
  context
}: TGetGraduateProfessionsDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getGraduateProfessionsData
