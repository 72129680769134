import React from 'react'
import { media } from 'fractals'
import { Div, P } from '@/fractals/components'

export const ListItem = ({
  content,
  counter
}: {
  content: string
  counter: number
}) => {
  return (
    <Div
      styles={{
        ...media('marginTop', '24px', {
          laptop: '32px',
          desktop: '32px'
        }),
        display: 'flex',
        gap: '16px'
      }}>
      <>
        <Div
          styles={{
            backgroundColor: 'var(--beta)',
            minWidth: '24px',
            width: '24px',
            height: '24px',
            minHeight: '24px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'var(--alpha)',
            fontSize: '14px',
            fontWeight: 500
          }}>
          {counter}
        </Div>
        <P
          styles={{ fontSize: '24px', fontWeight: 500 }}
          variant='nu'
          dangerouslySetInnerHTML={{
            __html: content
          }}>
          {null}
        </P>
      </>
    </Div>
  )
}

export default ListItem
