import { TGetReviewsDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getReviewsData = async ({ context }: TGetReviewsDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getReviewsData
