import React from 'react'
import { Image, multiMedia } from 'fractals'
import { Div } from '@/fractals/components'

export const ImageItem = ({ content }: { content: string }) => {
  return (
    <Div
      styles={{
        position: 'relative',
        ...multiMedia({
          desktop: { width: '800px', height: '400px' },
          laptop: { width: '592px', height: '320px' },
          tablet: { width: '640px', height: '320px' },
          phone: { width: '343px', height: '171px' }
        })
      }}>
      <Image
        fill
        sizes='(max-width: 768px) 75vw, 75vw'
        quality={75}
        src={content || '/'}
        alt={content?.match(/\/([^/]+)$/)?.[1] || ''}
        containerProps={{
          styles: {
            borderRadius: '16px',
            '& img': {
              objectFit: 'cover'
            }
          }
        }}
        colorVariant='transparent'
      />
    </Div>
  )
}

export default ImageItem
