export const VARIANTS_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta',
  delta2: 'delta2',
  epsilon: 'epsilon',
  zeta: 'zeta',
  zetaA: 'zetaA',
  journalH3: 'journalH3',
  thanks: 'thanks',
  fullProgramsContent: 'fullProgramsContent',
  theta: 'theta'
} as const
