import { styled } from '@mui/system'
import Button, { ButtonProps } from '@mui/material/Button'

export const TagButton = styled((props: ButtonProps & { active: boolean }) => (
  <Button {...props} />
))(({ active }) => ({
  boxShadow: 'none',
  fontSize: 14,
  padding: '8px 16px',
  border: 'none',
  borderRadius: '55px',
  backgroundColor: active ? '#3A52EE' : '#F2F2F2',
  color: active ? '#fff' : '#000',
  width: 'fit-content',
  textTransform: 'none',
  fontWeight: '400',
  fontFamily: [
    '__golosTextVF_099e87',
    '__golosTextVF_Fallback_099e87',
    'sans-serif'
  ].join(','),
  '&:hover': {
    backgroundColor: '#000',
    borderColor: '#000',
    boxShadow: 'none',
    color: '#fff'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#3A52EE',
    color: '#fff'
  }
}))
