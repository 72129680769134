// eslint-disable-next-line import/no-cycle
export { Header } from './Header'
export { Hero, getHeroData } from './Hero'
export { ForWhom, getForWhomData } from './ForWhom'
export {
  ExamFreeEnrollment,
  getExamFreeEnrollmentData
} from './ExamFreeEnrollment'
export {
  LeastProgramContents,
  getLeastProgramContentsData
} from './LeastProgramContents'
export { WhatWillYouLearn, getWhatWillYouLearnData } from './WhatWillYouLearn'
export { AboutTraining } from './AboutTraining'
export { LeastDocuments } from './LeastDocuments'
export {
  GraduateProfessions,
  getGraduateProfessionsData
} from './GraduateProfessions'
export { StudyIs, getStudyIsData } from './StudyIs'
export { Cost, getCostData } from './Cost'
export { CorporateLead } from './CorporateLead'
export { Reviews, getReviewsData } from './Reviews'
export { Teachers, getTeachersData } from './Teachers'
