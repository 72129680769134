import React, { useRef, useEffect } from 'react'

type ClickOutsideProps = {
  onClickOutside: () => void
}

export const withClickOutside = <P extends object>(
  Component: React.ComponentType<P>
) =>
  function (props: P & ClickOutsideProps) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          // eslint-disable-next-line react/destructuring-assignment
          props.onClickOutside()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [props])

    return (
      <div ref={ref}>
        <Component {...(props as P)} />
      </div>
    )
  }
