export {
  LABEL,
  TITLE,
  FORM_NAME,
  FORM_HEADING,
  QUESTION_1,
  ANSWER_1,
  QUESTION_2,
  ANSWER_2,
  QUESTION_3,
  ANSWER_3,
  QUESTION_4,
  ANSWER_4
} from './constants'
export type { TFAQProps } from './types'
export { getFAQData } from './api'
