import * as React from 'react'
import { TUlProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Ul = ({ ...props }: TUlProps) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        ...props.styles
      }}
    />
  )
}

export default Ul
