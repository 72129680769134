// TODO: refactor & generalize & improve

function formatWord(word: string, count: number): string {
  let formattedWord = word

  if (count % 100 > 10 && count % 100 < 20) {
    formattedWord = `${word}ов`
  } else {
    switch (count % 10) {
      case 2:
      case 3:
      case 4:
        formattedWord = `${word}а`
        break
      default:
        formattedWord = `${word}ов`
        break
    }
  }
  return formattedWord
}

export const calculateRuCase = <T extends string | undefined, N extends number>(
  str: T,
  num: N,
  locale: string | undefined
) => {
  if (!str) return str

  if (locale !== 'ru') return str

  if (str.toLocaleLowerCase() === 'январь') return 'января'
  if (str.toLocaleLowerCase() === 'февраль') return 'февраля'
  if (str.toLocaleLowerCase() === 'март') return 'марта'
  if (str.toLocaleLowerCase() === 'апрель') return 'апреля'
  if (str.toLocaleLowerCase() === 'май') return 'мая'
  if (str.toLocaleLowerCase() === 'июнь') return 'июня'
  if (str.toLocaleLowerCase() === 'июль') return 'июля'
  if (str.toLocaleLowerCase() === 'август') return 'августа'
  if (str.toLocaleLowerCase() === 'сентябрь') return 'сентября'
  if (str.toLocaleLowerCase() === 'октябрь') return 'октября'
  if (str.toLocaleLowerCase() === 'ноябрь') return 'ноября'
  if (str.toLocaleLowerCase() === 'декабрь') return 'декабря'

  const numArr = num.toString().split('')

  const numLastDigit =
    numArr.length > 1 && str !== 'курс'
      ? Number(numArr[numArr.length - 1])
      : num

  if (numLastDigit === 1 || numLastDigit === 21 || numLastDigit === 31)
    return str

  if (
    (numLastDigit > 1 && numLastDigit < 5) ||
    (numLastDigit > 21 && numLastDigit < 25)
  )
    return `${str}а`

  if (
    (numLastDigit >= 25 && numLastDigit <= 30) ||
    (num >= 5 && num <= 20) ||
    (numLastDigit >= 5 && numLastDigit <= 9) ||
    numLastDigit === 0
  )
    return (
      (str.toLowerCase() === 'месяц' && `${str}ев`) ||
      (str.toLowerCase() === 'год' && 'лет') ||
      `${str}ов`
    )

  if (str.toLowerCase() === 'курс') {
    return formatWord(str, num)
  }

  return str
}

export default calculateRuCase
