export const VARIANTS_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta',
  epsilon: 'epsilon',
  phi: 'phi',
  zeta: 'zeta',
  eta: 'eta',
  theta: 'theta',
  jota: 'jota',
  zetaA: 'zetaA',
  journalMain: 'journalMain',
  journalMainBelow: 'journalMainBelow',
  alphaA: 'alphaA',
  whyUsTitle: 'whyUsTitle',
  reviewTitle: 'reviewTitle',
  mediaTitle: 'mediaTitle',
  journalTitle: 'journalTitle',
  fullContentTitle: 'fullContentTitle',
  communitySupportTitle: 'communitySupportTitle',
  howTrainingWorksTitle: 'howTrainingWorksTitle',
  studyIsForPsychologyTitle: 'studyIsForPsychologyTitle'
} as const
