import { fetchProgramsList } from './fetchProgramsList'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchProgramsList>>
}) => {
  return { programsSearched: programsData }
}

export default normalizeProgramsData
