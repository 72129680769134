import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { Div, multiMedia, Image } from 'fractals'
import { TStoriesProps } from './types'

export const Stories = ({ urls }: TStoriesProps) => {
  const [active, setActive] = useState<number>(0)

  const handleBeforeChange = (oldNum: number, newNum: number) => {
    setActive(newNum)
  }

  const buttonStyle = (index: number) => {
    return {
      ...multiMedia({
        desktop: {
          minWidth: '78px',
          minHeight: '5px',
          width: '78px',
          height: '5px'
        },
        laptop: {
          minWidth: '51.148px',
          minHeight: '3.309px',
          width: '51.148px',
          height: '3.309px'
        },
        tablet: {
          minWidth: '68px',
          minHeight: '5px',
          width: '68px',
          height: '5px'
        },
        phone: {
          minWidth: '53px',
          minHeight: '4px',
          width: '53px',
          height: '4px'
        }
      }),
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        transition: 'background-color 0.3s ease'
      },
      borderRadius: '15px',
      transition: 'background-color 0.3s ease',
      backgroundColor: active === index ? '#FFF' : 'rgba(255, 255, 255, 0.38)',
      cursor: 'pointer'
    }
  }

  const sliderRef = useRef<Slider>(null)

  const goTo = (slideNumber: number, event: any): void => {
    if (event.type === 'click') {
      setActive(slideNumber)
      sliderRef?.current?.slickGoTo(slideNumber)
    }
  }

  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    className: 'stories-slide',
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    infinite: true,
    swipe: false,
    draggable: false,
    touchMove: false,
    swipeToSlide: false,
    beforeChange: (oldNum: number, newNum: number) =>
      handleBeforeChange(oldNum, newNum)
  }

  const five = new Array(5).fill('')

  return (
    <Slider ref={sliderRef} {...settings}>
      {urls?.map((url, idx) => (
        <Div
          key={`SimilarArticle-linkedJournal__${idx + 1}`}
          styles={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Div
            styles={{
              position: 'relative',
              ...multiMedia({
                desktop: {
                  width: '488px',
                  height: '408px'
                },
                laptop: {
                  width: '320px',
                  height: '305px'
                },
                tablet: {
                  width: '100%',
                  height: '442px'
                },
                phone: {
                  width: '100%',
                  height: '343px'
                }
              }),
              borderRadius: '10px',
              overflow: 'hidden'
            }}
            className='image-container'>
            <Image
              colorVariant='transparent'
              src={url ?? '/'}
              alt={`story__${idx + 1}`}
              fill
              sizes='(max-width: 768px) 50vw, 75vw'
              quality={70}
              priority
              containerProps={{
                styles: {
                  '& img': {
                    objectFit: 'cover'
                  }
                }
              }}
            />
          </Div>
          <Div
            styles={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              position: 'absolute',
              zIndex: 5,
              ...multiMedia({
                desktop: {
                  gap: '8px',
                  marginBottom: '32px'
                },
                laptop: {
                  gap: '5.25px',
                  marginBottom: '21.18px'
                },
                tablet: {
                  gap: '7px',
                  marginTop: 'auto',
                  marginBottom: '53px'
                },
                phone: {
                  gap: '8px',
                  marginTop: 'auto',
                  marginBottom: '66px'
                }
              })
            }}>
            {five.map((_el, index) => (
              <Div
                key={`five_of--${index + 1}`}
                styles={{
                  height: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <Div
                  // onChange={e => sliderRef?.current?.slickGoTo(e.target)}
                  onClick={event => goTo(index, event)}
                  styles={{
                    ...buttonStyle(index)
                  }}
                />
              </Div>
            ))}
          </Div>
        </Div>
      ))}
    </Slider>
  )
}

export default Stories
