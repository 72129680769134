import { P, toUnit } from 'fractals'
import { TPhoneTitleProps } from '../../../types'

const PhoneTitle = ({ children }: TPhoneTitleProps) => (
  <P variant='eta' styles={{ fontSize: toUnit(10), color: '#8B8B8B' }}>
    {children}
  </P>
)

export default PhoneTitle
