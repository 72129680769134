import { StyledLink } from './styled'

type TProps = {
  children: React.ReactElement
  href: string
}

const Link = ({ children, href = '/' }: TProps) => (
  <StyledLink href={href}>{children}</StyledLink>
)

export default Link
