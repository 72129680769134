import { TGetHeaderDataProps } from './types'
import {
  fetchCategories,
  fetchFaculties,
  normalizeCategoriesData,
  normalizeFacultiesData
} from './utils'

export const getHeaderData = async ({ context }: TGetHeaderDataProps) => {
  try {
    const categoriesData = await fetchCategories({ context })

    const categoriesDataNormalized = normalizeCategoriesData({ categoriesData })

    const facultiesData = await fetchFaculties({ context })

    const facultiesDataNormalized = normalizeFacultiesData({ facultiesData })

    return {
      ...categoriesDataNormalized,
      ...facultiesDataNormalized
    }
  } catch (e) {
    console.log('%cgetHeaderData.ts line:13 e', 'color: #007acc;', e)
  }
  return null
}

export default getHeaderData
