export {
  PriceCard,
  WhatIsIncluded,
  PriceCardPsihologiyaIsHigherEducation,
  WhatIsIncludedPsihologiyaIsHigherEducation
} from './molecules'
export {
  Testimonials,
  TestimonialsPsihologiyaIsHigherEducation
} from './organisms'
