import { LOCALES } from 'fractals'

export const LEARN_MORE = {
  [LOCALES.ru]: 'Подробнее',
  [LOCALES.en]: 'Learn more',
  [LOCALES.uz]: 'Batafsil',
  [LOCALES.kk]: 'Толығырақ'
} as const

export const HOUR = {
  [LOCALES.en]: 'hour',
  [LOCALES.ru]: 'час',
  [LOCALES.uz]: 'soat',
  [LOCALES.kk]: 'сағат'
} as const
