import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const useHistoryNavigation = ({
  shown,
  setShown,
  modalId
}: {
  shown: boolean
  setShown: Dispatch<SetStateAction<boolean>>
  modalId: string
}) => {
  const [poppingHistoryState, setPoppingHistoryState] = useState(false)

  useEffect(() => {
    if (window && window.location.hash.replace(/^#/, '') === modalId) {
      setShown(true)
    }
  }, [modalId, setShown])

  useEffect(() => {
    if (window) {
      if (shown) {
        window.history.pushState('', document.title, `#${modalId}`)
      } else {
        window.history.pushState(null, document.title, window.location.pathname)
        setPoppingHistoryState(false)
      }
    }
  }, [shown, modalId])

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash.replace(/^#/, '') === modalId) {
        setShown(true)
      }
    }
    if (window) {
      window.addEventListener('hashchange', handleHashChange)
      return () => window.removeEventListener('hashchange', handleHashChange)
    }
    return () => {}
  }, [modalId, setShown])

  useEffect(() => {
    const handleExitOnPopState = () => {
      if (shown) {
        setPoppingHistoryState(true)
      }
    }
    if (window) {
      window.addEventListener('popstate', handleExitOnPopState)

      return () => window.removeEventListener('popstate', handleExitOnPopState)
    }
    return () => {}
  }, [shown])

  return poppingHistoryState
}

export default useHistoryNavigation
