import { Layout, StyledJSX, fetcher } from 'ui'
import {
  toUnit,
  useTranslations,
  Span,
  useAt,
  Link,
  PATHS,
  LOCALES,
  useMedia,
  Button,
  Div,
  IconSearch,
  multiMedia,
  H2
} from 'fractals'
import { useRouter } from 'next/router'
import {
  useMemo,
  useReducer,
  useState,
  MouseEventHandler,
  useEffect
} from 'react'
import useSWRImmutable from 'swr/immutable'
import { FormControlLabel, Box, Grid, Input } from '@mui/material'
import Popup from 'reactjs-popup'
// eslint-disable-next-line import/no-cycle
import { HEADING as PROGRAMS_HEADING } from '../../../../index'
import { SortingType, TProgramCardsProps } from './types'
import {
  HEADING,
  IS_TURNED_OFF,
  IS_TURNED_ON,
  ALL_FACULTIES,
  DEFAULT_ITEMS_TO_SHOW,
  initialQueryState
} from './constants'
import { appliedStyles, regularStylesChecker } from './styles'
import useWindowWidth from '@/fractals/hooks/useWindowWidth'
import {
  compareFunction,
  findCommonElements,
  getDurationInMonth,
  getLimits,
  reducer
} from './utils'
import { TDiploma } from '../../../api/getProgramsData/types'
import { TTag } from '@/modules/journal-page/widgets/Journal/fractals/api/getJournalData/types'
import {
  FiltersButton,
  SortButton,
  ProgramCard,
  FiltersButtonMobile,
  CategorySelect
} from '../../molecules'
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
  CustomCheckbox,
  CustomSlider,
  CustomSwitch,
  TagButton,
  TagButtonMobile
} from '../../atoms'

export const ProgramCards = ({
  categories,
  faculties,
  programs,
  params
}: TProgramCardsProps) => {
  const { isLaptop, isDesktop, isMobile, isTablet } = useMedia()
  const windowWidth = useWindowWidth()
  const [heading, allFaculties, isTurnedOff, isTurnedOn, programsHeading] =
    useTranslations(
      HEADING,
      ALL_FACULTIES,
      IS_TURNED_OFF,
      IS_TURNED_ON,
      PROGRAMS_HEADING
    )

  const [searchRequest, setSearchRequest] = useState('')

  const [sortingBy, setSortingBy] = useState<SortingType>({
    type: null,
    direction: 'desc'
  })

  const [isSortHidden, setIsSortHidden] = useState<boolean>(true)
  const toggleIsSortHidden = () => setIsSortHidden(prev => !prev)
  const hideSorting = () => setIsSortHidden(true)

  const getSortingBy = (value: SortingType): void => {
    setSortingBy(value)
    toggleIsSortHidden()
  }

  // const { data: allTagsData } = useSWRImmutable<{ data: TTag[] }>(
  //   `${PATHS.back.root.index}${PATHS.back.api.tags.index}`,
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   fetcher
  // )

  const { data: allDiplomasData } = useSWRImmutable<{ data: TDiploma[] }>(
    `${PATHS.back.root.index}${PATHS.back.api.diplomas.index}`,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fetcher
  )

  const { minPrice, maxPrice, minDuration, maxDuration } = getLimits(programs)

  const [filters, dispatchFilters] = useReducer(reducer, {
    ...initialQueryState,
    price: {
      min: 0,
      max: maxPrice
    },
    duration: {
      min: minDuration,
      max: maxDuration
    }
  })

  const { locale } = useRouter()

  const { currentCategorySlug, currentFacultySlug, currentSearchSlugs } =
    useAt()

  const CURRENT_ITEMS_TO_SHOW =
    DEFAULT_ITEMS_TO_SHOW[isMobile ? 'mobile' : 'desktop']

  const [itemsToShow, setItemsToShow] = useState<number>(CURRENT_ITEMS_TO_SHOW)

  useEffect(() => {
    setItemsToShow(CURRENT_ITEMS_TO_SHOW)
  }, [CURRENT_ITEMS_TO_SHOW])

  const preparedPrograms = useMemo(
    () =>
      programs
        ?.filter(program =>
          currentFacultySlug
            ? currentFacultySlug === program?.faculty?.slug
            : program
        )
        ?.filter(program =>
          currentCategorySlug
            ? program?.category?.slug === currentCategorySlug
            : program
        )
        ?.filter(program =>
          Array.isArray(currentSearchSlugs) && currentSearchSlugs.length
            ? currentSearchSlugs.find(
                currentSearchSlug => currentSearchSlug === program.slug
              )
            : program
        )
        ?.filter(program =>
          params ? params?.find(param => param === program.slug) : program
        )
        ?.filter(
          ({ level }) =>
            filters.level.length === 0 ||
            (level && filters.level.includes(level))
        )
        ?.filter(({ withEmployment }) =>
          filters.withEmployment
            ? withEmployment === filters.withEmployment
            : true
        )
        ?.filter(
          ({ diplomas }) =>
            filters.diplomas.length === 0 ||
            findCommonElements(diplomas, filters.diplomas).length
        )
        ?.filter(
          ({ tags }) =>
            filters.tags.length === 0 ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            findCommonElements(tags, filters.tags).length
        )
        ?.filter(program => {
          const duration = getDurationInMonth(program)
          const { min, max } = filters.duration

          return duration >= min && duration <= max
        })
        ?.filter(({ cost }) => {
          const { price } = cost
          const { min, max } = filters.price

          return Number(price) >= min && Number(price) <= max
        })
        ?.filter(program =>
          searchRequest
            ? program.name?.toLowerCase().includes(searchRequest.toLowerCase())
            : true
        )
        ?.sort(compareFunction(sortingBy.type)) ?? [],
    [
      programs,
      currentCategorySlug,
      currentFacultySlug,
      currentSearchSlugs,
      params,
      filters,
      sortingBy,
      searchRequest
    ]
  )

  const PreparedFaculties = () =>
    useMemo(
      () => (
        <Layout.Flex cols='auto' gap={toUnit(isDesktop ? 24 : 16)}>
          {[
            {
              name: allFaculties,
              slug:
                (currentCategorySlug && `/${currentCategorySlug}`) ||
                PATHS.front.general.programs[locale as keyof typeof LOCALES],
              rank: 0,
              programs: faculties?.reduce(
                (acc, cur) => acc + Number(cur.programs),
                0
              )
            },
            ...(faculties || [])
          ]
            ?.filter(faculty => faculty?.slug)
            ?.map((faculty, idx) => {
              const isApplied =
                currentFacultySlug === faculty.slug ||
                (idx === 0 && !currentFacultySlug)

              return (
                <Layout.FlexItem
                  key={`ProgramCards__${faculty?.name || 'Faculty'}--${
                    idx + 1
                  }`}
                  styles={{
                    display: 'flex',
                    flexGrow: 1,
                    flexBasis: '100%'
                  }}>
                  <Link
                    variant='epsilon'
                    styles={{
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingLeft: 0,
                      paddingRight: 0,
                      gap: toUnit(16),
                      ...((isApplied && {
                        transition: 'none',
                        ':hover': {
                          transform: 'none',
                          cursor: 'default'
                        }
                      }) ||
                        {})
                    }}
                    href={
                      idx === 0
                        ? (currentCategorySlug && `/${currentCategorySlug}`) ||
                          PATHS.front.general.programs[
                            (locale as keyof typeof LOCALES) || 'ru'
                          ]
                        : `${
                            (currentCategorySlug &&
                              `/${currentCategorySlug}`) ||
                            PATHS.front.general.programs[
                              (locale as keyof typeof LOCALES) || 'ru'
                            ]
                          }--${
                            PATHS.front.general.faculty[
                              (locale as keyof typeof LOCALES) || 'ru'
                            ]
                          }--${faculty.slug}`
                    }
                    scroll={false}>
                    <Span
                      styles={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'left',
                        gap: toUnit(16)
                      }}>
                      <Span
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        styles={{
                          ...regularStylesChecker,
                          borderColor:
                            (isApplied && 'var(--beta)') || 'var(--phi)',
                          ...((isApplied && appliedStyles) || {})
                        }}
                        aria-label={(isApplied && isTurnedOn) || isTurnedOff}
                      />
                      {faculty.name}
                    </Span>
                    <Span
                      styles={{
                        fontSize: toUnit(14),
                        fontWeight: 400,
                        lineHeight: '120%',
                        color: 'var(--upsilon)'
                      }}>
                      {faculty.programs}
                    </Span>
                  </Link>
                </Layout.FlexItem>
              )
            }) || null}
        </Layout.Flex>
      ),
      []
    )

  const showMore = () => {
    const maxItemsToShow = preparedPrograms?.length ?? 0

    const newItemsToShow = itemsToShow + CURRENT_ITEMS_TO_SHOW

    if (maxItemsToShow - newItemsToShow >= 0) {
      return setItemsToShow(newItemsToShow)
    }

    return setItemsToShow(maxItemsToShow)
  }

  const [expandedSearch, setExpandedSearch] = useState<boolean>(false)
  const [expandedCategories, setExpandedCategories] = useState<boolean>(true)

  const handleSearchClick = () => {
    setExpandedCategories(false)
    setExpandedSearch(true)
  }

  const handleCategoriesClick = () => {
    setExpandedSearch(false)
    setExpandedCategories(true)
  }

  return (
    <Layout.Flex
      cols={(isDesktop && 2) || 1}
      proportion={(isDesktop && '5:11') || undefined}
      gap={toUnit(32)}
      styles={{
        justifyContent: isDesktop ? 'space-between' : 'center'
      }}>
      {isDesktop && (
        <Layout.FlexItem>
          <span
            style={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '120%',
              display: 'block',
              marginBlockStart: '1em',
              marginBlockEnd: '1em',
              marginInlineStart: '0px',
              marginInlineEnd: '0px'
            }}>
            {heading}
          </span>
          <PreparedFaculties />
          <FormControlLabel
            label='С трудоустройством'
            labelPlacement='start'
            control={
              <CustomSwitch
                color='primary'
                checked={filters.withEmployment}
                onChange={() =>
                  dispatchFilters({
                    type: 'setWithEmployment'
                  })
                }
              />
            }
            style={{
              marginTop: '48px',
              marginLeft: 0
            }}
            sx={{
              '& .MuiTypography-root': {
                fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                fontWeight: 500,
                lineHeight: '120%'
              }
            }}
          />
          <div>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '120%',
                display: 'block',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px'
              }}>
              Выдаваемый документ
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              {allDiplomasData?.data?.map(
                diploma =>
                  diploma.name && (
                    <FormControlLabel
                      label={diploma.name}
                      labelPlacement='end'
                      control={
                        <CustomCheckbox
                          checked={filters.diplomas?.includes(diploma.name)}
                          onChange={() =>
                            dispatchFilters({
                              type: 'setDiplomas',
                              payload: diploma.name ?? ''
                            })
                          }
                        />
                      }
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                          lineHeight: '120%'
                        }
                      }}
                    />
                  )
              )}
            </div>
          </div>
          <div>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '120%',
                display: 'block',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px'
              }}>
              Длительность
            </span>
            {/* //TODO сделать склонение слова "месяца(ев)" */}
            <span>{`От ${filters.duration.min} до ${filters.duration.max} месяцев`}</span>
            <CustomSlider
              defaultValue={maxDuration}
              // getAriaValueText={filters.duration.max}
              valueLabelDisplay='auto'
              min={minDuration}
              max={maxDuration}
              onChange={(_, newValue) =>
                typeof newValue === 'number' &&
                dispatchFilters({
                  type: 'setMaxDuration',
                  payload: newValue
                })
              }
            />
          </div>
          <div>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '120%',
                display: 'block',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px'
              }}>
              Уровень
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <FormControlLabel
                labelPlacement='end'
                label='Новичкам'
                control={
                  <CustomCheckbox
                    checked={filters.level?.includes('Новичкам')}
                    onChange={() =>
                      dispatchFilters({
                        type: 'setLevel',
                        payload: 'Новичкам'
                      })
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                        lineHeight: '120%'
                      }
                    }}
                  />
                }
              />
              <FormControlLabel
                label='Специалистам'
                labelPlacement='end'
                control={
                  <CustomCheckbox
                    checked={filters.level?.includes('Специалистам')}
                    onChange={() =>
                      dispatchFilters({
                        type: 'setLevel',
                        payload: 'Специалистам'
                      })
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                        lineHeight: '120%'
                      }
                    }}
                  />
                }
              />
            </div>
          </div>
          {/* <div>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '120%',
                display: 'block',
                marginBlockStart: '1em',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px'
              }}>
              Тэги
            </span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px'
              }}>
              {allTagsData?.data?.map(
                tag =>
                  !!tag.name && (
                    <TagButton
                      active={filters.tags.includes(tag.name)}
                      onClick={() =>
                        tag.name &&
                        dispatchFilters({ type: 'setTags', payload: tag.name })
                      }>
                      {tag.name}
                    </TagButton>
                  )
              )}
            </div>
          </div> */}
        </Layout.FlexItem>
      )}

      <Layout.FlexItem
        styles={{
          ...multiMedia({
            desktop: {
              maxWidth: '800px'
            },
            laptop: {
              maxWidth: '800px'
            },
            tablet: {
              maxWidth: '640px'
            }
          })
        }}>
        {isDesktop && <H2 variant='theta'>{programsHeading}</H2>}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isTablet ? 'column' : 'row',
            gap: isTablet ? '32px' : 0
          }}>
          <Div
            styles={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              ...multiMedia({
                desktop: {
                  maxWidth: '384px'
                },
                laptop: {
                  maxWidth: '384px'
                },
                phone: {
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: '5px'
                }
              })
            }}>
            {isMobile && (
              <CategorySelect
                categories={categories}
                onClick={handleCategoriesClick}
                isExpanded={expandedCategories}
              />
            )}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
              id='SearchForm__id'
              onClick={handleSearchClick}
              style={{
                position: 'relative',
                background: '#F2F2F2',
                borderRadius: toUnit(8),
                display: 'flex',
                transition: 'all 0.3s ease',
                width: isMobile || isTablet ? '100%' : 'unset',
                maxWidth: isMobile && !expandedSearch ? '51px' : 'unset'
              }}>
              <IconSearch
                colorVariant={isMobile ? 'beta' : 'omega'}
                styles={{
                  zIndex: 10,
                  boxSizing: 'border-box',
                  padding: '15.5px',
                  flexShrink: 0
                }}
              />
              <StyledJSX.Input
                type='text'
                placeholder='Поиск'
                value={searchRequest}
                onChange={e => setSearchRequest(e.target.value)}
                styles={{
                  zIndex: 5,
                  height: toUnit(48),
                  minWidth: '18rem',
                  background: '#F2F2F2',
                  borderRadius: toUnit(8),
                  outline: 'none',
                  boxSizing: 'border-box',
                  width: '100%',
                  ...multiMedia({
                    desktop: {
                      width: '384px'
                    },
                    laptop: {
                      width: '384px'
                    },
                    tablet: {
                      width: '100%'
                    },
                    phone: {
                      minWidth: 'unset',
                      display: expandedSearch ? 'inline-block' : 'none'
                    }
                  })
                }}
              />
            </div>
            {isDesktop && (
              <SortButton
                isHidden={isSortHidden}
                toggleIsHidden={toggleIsSortHidden}
                getSortingBy={getSortingBy}
                sortedBy={sortingBy}
                onClickOutside={hideSorting}
              />
            )}
            {isMobile && (
              <Popup
                modal
                nested
                closeOnDocumentClick
                closeOnEscape
                lockScroll
                className='burger-popup'
                overlayStyle={{ backgroundColor: '#fff' }}
                contentStyle={{
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'start'
                }}
                trigger={<FiltersButtonMobile />}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {(close: MouseEventHandler) => (
                  <Div
                    styles={{
                      width: '100%',
                      backgroundColor: '#fff',
                      padding: '64px 16px'
                    }}>
                    <Div
                      styles={{
                        backgroundColor: '#fff',
                        margin: '0 auto',
                        width: '100%',
                        height: '100%',
                        ...multiMedia({
                          maxWidth: '800px',
                          tablet: {
                            maxWidth: '640px'
                          }
                        })
                      }}
                      onClick={e => e.stopPropagation()}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <span
                          style={{
                            fontSize: '24px',
                            fontWeight: 500,
                            lineHeight: '120%',
                            display: 'block',
                            marginBlockStart: '0px',
                            marginBlockEnd: '0px',
                            marginInlineStart: '0px',
                            marginInlineEnd: '0px'
                          }}>
                          Фильтры
                        </span>
                        {/* //TODO: extract component */}
                        <svg
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={close}
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M20.5301 4.53033C20.823 4.23744 20.823 3.76256 20.5301 3.46967C20.2372 3.17678 19.7623 3.17678 19.4694 3.46967L11.9999 10.9392L4.53033 3.46969C4.23744 3.17679 3.76256 3.17679 3.46967 3.46969C3.17678 3.76258 3.17678 4.23745 3.46967 4.53035L10.9392 11.9999L3.46967 19.4694C3.17678 19.7623 3.17678 20.2372 3.46967 20.5301C3.76256 20.823 4.23744 20.823 4.53033 20.5301L11.9999 13.0605L19.4694 20.5301C19.7623 20.823 20.2372 20.823 20.5301 20.5301C20.823 20.2372 20.823 19.7623 20.5301 19.4694L13.0605 11.9999L20.5301 4.53033Z'
                            fill='#18191A'
                          />
                        </svg>
                      </div>

                      <CustomAccordion>
                        <CustomAccordionSummary isMobile={isMobile}>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em'
                            }}>
                            Уровень образования
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            <FormControlLabel
                              labelPlacement='end'
                              label='Новичкам'
                              control={
                                <CustomCheckbox
                                  isMobile={isMobile}
                                  checked={filters.level?.includes('Новичкам')}
                                  onChange={() =>
                                    dispatchFilters({
                                      type: 'setLevel',
                                      payload: 'Новичкам'
                                    })
                                  }
                                  sx={{
                                    // TODO исправить размер шрифта
                                    '&.MuiTypography-root': {
                                      fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                      lineHeight: '100% !important',
                                      fontSize: '14px !important'
                                    },
                                    '& .MuiSvgIcon-root': {
                                      width: isMobile ? '16px' : '24px',
                                      height: isMobile ? '16px' : '24px'
                                    }
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              label='Специалистам'
                              labelPlacement='end'
                              control={
                                <CustomCheckbox
                                  isMobile={isMobile}
                                  checked={filters.level?.includes(
                                    'Специалистам'
                                  )}
                                  onChange={() =>
                                    dispatchFilters({
                                      type: 'setLevel',
                                      payload: 'Специалистам'
                                    })
                                  }
                                  sx={{
                                    // TODO исправить размер шрифта
                                    lineHeight: '100%',
                                    fontSize: '14px',
                                    '&.MuiTypography-root': {
                                      fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                      lineHeight: '100% !important',
                                      fontSize: '14px !important'
                                    },
                                    '& .MuiSvgIcon-root': {
                                      width: isMobile ? '16px' : '24px',
                                      height: isMobile ? '16px' : '24px'
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                        </CustomAccordionDetails>
                      </CustomAccordion>

                      <Box
                        sx={{
                          width: '100%',
                          maxWidth: '384px'
                        }}>
                        <span
                          style={{
                            fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '120%',
                            display: 'block',
                            marginBlockStart: '1em',
                            marginBlockEnd: '1em'
                          }}>
                          Стоимость
                        </span>
                        <Grid container direction='column'>
                          <Grid
                            item
                            container
                            justifyContent='space-between'
                            alignItems='center'>
                            <Grid
                              item
                              justifyContent='space-around'
                              alignItems='center'
                              xs={5}>
                              <FormControlLabel
                                labelPlacement='start'
                                label='от'
                                control={
                                  <Input
                                    value={filters.price.min}
                                    inputProps={{
                                      step: 1000,
                                      min: minPrice,
                                      max: maxPrice,
                                      type: 'number'
                                    }}
                                    onChange={e =>
                                      dispatchFilters({
                                        type: 'setMinPrice',
                                        payload: +e.target.value
                                      })
                                    }
                                    onBlur={() => {
                                      if (filters.price.min < 0) {
                                        dispatchFilters({
                                          type: 'setMinPrice',
                                          payload: 0
                                        })
                                      } else if (filters.price.min > maxPrice) {
                                        dispatchFilters({
                                          type: 'setMinPrice',
                                          payload: maxPrice
                                        })
                                      }
                                    }}
                                    sx={{
                                      marginLeft: '8px',
                                      width: '100%',
                                      '& .MuiInputBase-input-MuiInput-input': {
                                        marginLeft: '8px',
                                        width: '100%'
                                      }
                                    }}
                                  />
                                }
                                sx={{
                                  width: '100%',
                                  '& .MuiTypography-root': {
                                    fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                    lineHeight: '100%',
                                    fontSize: '14px'
                                  },
                                  '&.MuiFormControlLabel-root': {
                                    marginLeft: 0
                                  },
                                  '& .MuiInputBase-input-MuiInput-input': {
                                    marginLeft: '8px',
                                    width: '100%'
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs='auto'>
                              <span>—</span>
                            </Grid>
                            <Grid item xs={5}>
                              <FormControlLabel
                                labelPlacement='start'
                                label='до'
                                control={
                                  <Input
                                    value={filters.price.max}
                                    inputProps={{
                                      step: 1000,
                                      min: minPrice,
                                      max: maxPrice,
                                      type: 'number'
                                    }}
                                    onChange={e =>
                                      dispatchFilters({
                                        type: 'setMaxPrice',
                                        payload: +e.target.value
                                      })
                                    }
                                    onBlur={() => {
                                      if (filters.price.max < 0) {
                                        dispatchFilters({
                                          type: 'setMaxPrice',
                                          payload: 0
                                        })
                                      } else if (filters.price.max > maxPrice) {
                                        dispatchFilters({
                                          type: 'setMaxPrice',
                                          payload: maxPrice
                                        })
                                      }
                                    }}
                                    sx={{
                                      marginLeft: '8px',
                                      width: '100%',
                                      '& .MuiInputBase-input-MuiInput-input': {
                                        marginLeft: '8px',
                                        width: '100%'
                                      }
                                    }}
                                  />
                                }
                                sx={{
                                  width: '100%',
                                  '& .MuiTypography-root': {
                                    fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                    lineHeight: '100%',
                                    fontSize: '14px'
                                  },
                                  '&.MuiFormControlLabel-root': {
                                    marginLeft: 0
                                  },
                                  '& .MuiInputBase-input-MuiInput-input': {
                                    marginLeft: '8px',
                                    width: '100%'
                                  }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      <CustomAccordion>
                        <CustomAccordionSummary isMobile={isMobile}>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em'
                            }}>
                            {heading}
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <PreparedFaculties />
                        </CustomAccordionDetails>
                      </CustomAccordion>

                      <CustomAccordion>
                        <CustomAccordionSummary isMobile={isMobile}>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em'
                            }}>
                            Выдаваемый документ
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            {allDiplomasData?.data?.map(
                              diploma =>
                                diploma.name && (
                                  <FormControlLabel
                                    label={diploma.name}
                                    labelPlacement='end'
                                    control={
                                      <CustomCheckbox
                                        isMobile={isMobile}
                                        checked={filters.diplomas?.includes(
                                          diploma.name
                                        )}
                                        onChange={() =>
                                          dispatchFilters({
                                            type: 'setDiplomas',
                                            payload: diploma.name ?? ''
                                          })
                                        }
                                      />
                                    }
                                    sx={{
                                      '& .MuiTypography-root': {
                                        fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                        lineHeight: '100%',
                                        fontSize: '14px'
                                      },
                                      '& .MuiSvgIcon-root': {
                                        width: isMobile ? '16px' : '24px',
                                        height: isMobile ? '16px' : '24px'
                                      }
                                    }}
                                  />
                                )
                            )}
                          </div>
                        </CustomAccordionDetails>
                      </CustomAccordion>
                      <CustomAccordion>
                        <CustomAccordionSummary isMobile={isMobile}>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em'
                            }}>
                            Длительность
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between'
                            }}>
                            {/* //TODO сделать склонение слова "месяца(ев)" */}
                            <span>{`От ${filters.duration.min} до ${filters.duration.max} месяцев`}</span>
                            <CustomSlider
                              defaultValue={maxDuration}
                              valueLabelDisplay='auto'
                              min={minDuration}
                              max={maxDuration}
                              onChange={(_, newValue) =>
                                typeof newValue === 'number' &&
                                dispatchFilters({
                                  type: 'setMaxDuration',
                                  payload: newValue
                                })
                              }
                            />
                          </div>
                        </CustomAccordionDetails>
                      </CustomAccordion>

                      <FormControlLabel
                        label='С трудоустройством'
                        labelPlacement='start'
                        control={
                          <CustomSwitch
                            color='primary'
                            checked={filters.withEmployment}
                            onChange={() =>
                              dispatchFilters({
                                type: 'setWithEmployment'
                              })
                            }
                          />
                        }
                        style={{
                          marginTop: '16px',
                          marginLeft: 0
                        }}
                        sx={{
                          '& .MuiTypography-root': {
                            fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '120%'
                          }
                        }}
                      />

                      {/* <div
                        style={{
                          marginTop: '32px'
                        }}>
                        <span
                          style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            display: 'block',
                            marginBlockStart: '1em',
                            marginBlockEnd: '1em'
                          }}>
                          Тэги
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px'
                          }}>
                          {allTagsData?.data?.map(
                            tag =>
                              !!tag.name && (
                                <TagButton
                                  active={filters.tags.includes(tag.name)}
                                  onClick={() =>
                                    tag.name &&
                                    dispatchFilters({
                                      type: 'setTags',
                                      payload: tag.name
                                    })
                                  }>
                                  {tag.name}
                                </TagButton>
                              )
                          )}
                        </div>
                      </div> */}

                      <Div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '32px 16px 0px'
                        }}>
                        <Button
                          onClick={close}
                          colorsVariant='beta'
                          sizeVariant='medium'
                          styles={{
                            width: '100%'
                          }}>
                          Применить
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                )}
              </Popup>
            )}
          </Div>
          {(isLaptop || isTablet) && (
            <Div
              styles={{
                width: '100%',
                maxWidth: '384px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                ...multiMedia({
                  laptop: {
                    gap: '16px'
                  },
                  tablet: {
                    gap: '40px'
                  }
                })
              }}>
              <SortButton
                isHidden={isSortHidden}
                toggleIsHidden={toggleIsSortHidden}
                getSortingBy={getSortingBy}
                sortedBy={sortingBy}
                onClickOutside={hideSorting}
              />
              <Popup
                modal
                nested
                closeOnDocumentClick
                closeOnEscape
                lockScroll
                className='burger-popup'
                overlayStyle={{ backgroundColor: '#fff' }}
                contentStyle={{
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'start'
                }}
                trigger={<FiltersButton />}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {(close: MouseEventHandler) => (
                  <Div
                    styles={{
                      width: '100%',
                      backgroundColor: '#fff',
                      padding: '64px 0'
                    }}>
                    <Div
                      styles={{
                        backgroundColor: '#fff',
                        margin: '0 auto',
                        width: '100%',
                        height: '100%',
                        ...multiMedia({
                          maxWidth: '800px',
                          tablet: {
                            maxWidth: '640px'
                          }
                        })
                      }}
                      onClick={e => e.stopPropagation()}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <span
                          style={{
                            fontSize: '24px',
                            fontWeight: 500,
                            lineHeight: '120%',
                            display: 'block',
                            marginBlockStart: '1em',
                            marginBlockEnd: '1em',
                            marginInlineStart: '0px',
                            marginInlineEnd: '0px'
                          }}>
                          Фильтры
                        </span>
                        {/* //TODO: extract component */}
                        <svg
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={close}
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M20.5301 4.53033C20.823 4.23744 20.823 3.76256 20.5301 3.46967C20.2372 3.17678 19.7623 3.17678 19.4694 3.46967L11.9999 10.9392L4.53033 3.46969C4.23744 3.17679 3.76256 3.17679 3.46967 3.46969C3.17678 3.76258 3.17678 4.23745 3.46967 4.53035L10.9392 11.9999L3.46967 19.4694C3.17678 19.7623 3.17678 20.2372 3.46967 20.5301C3.76256 20.823 4.23744 20.823 4.53033 20.5301L11.9999 13.0605L19.4694 20.5301C19.7623 20.823 20.2372 20.823 20.5301 20.5301C20.823 20.2372 20.823 19.7623 20.5301 19.4694L13.0605 11.9999L20.5301 4.53033Z'
                            fill='#18191A'
                          />
                        </svg>
                        {/* <IconPlus
                          colorVariant='omega'
                          variant='cross'
                          onClick={close}
                          styles={{ cursor: 'pointer' }}
                        /> */}
                      </div>
                      <CustomAccordion>
                        <CustomAccordionSummary>
                          <span
                            style={{
                              fontSize: '24px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em',
                              marginInlineStart: '0px',
                              marginInlineEnd: '0px'
                            }}>
                            Уровень образования
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            <FormControlLabel
                              labelPlacement='end'
                              label='Новичкам'
                              control={
                                <CustomCheckbox
                                  checked={filters.level?.includes('Новичкам')}
                                  onChange={() =>
                                    dispatchFilters({
                                      type: 'setLevel',
                                      payload: 'Новичкам'
                                    })
                                  }
                                  sx={{
                                    '& .MuiTypography-root': {
                                      fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                      lineHeight: '120%'
                                    }
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              label='Специалистам'
                              labelPlacement='end'
                              control={
                                <CustomCheckbox
                                  checked={filters.level?.includes(
                                    'Специалистам'
                                  )}
                                  onChange={() =>
                                    dispatchFilters({
                                      type: 'setLevel',
                                      payload: 'Специалистам'
                                    })
                                  }
                                  sx={{
                                    '& .MuiTypography-root': {
                                      fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                      lineHeight: '120%'
                                    }
                                  }}
                                />
                              }
                            />
                          </div>
                        </CustomAccordionDetails>
                      </CustomAccordion>

                      <div
                        style={{
                          display: 'flex',
                          gap: '32px'
                        }}>
                        <Box
                          sx={{
                            width: '100%',
                            maxWidth: '384px'
                          }}>
                          <span
                            style={{
                              fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                              fontSize: '24px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em',
                              marginInlineStart: '0px',
                              marginInlineEnd: '0px'
                            }}>
                            Стоимость
                          </span>
                          <Grid container direction='column'>
                            <Grid
                              item
                              container
                              justifyContent='space-between'
                              alignItems='center'>
                              <Grid
                                item
                                justifyContent='space-around'
                                alignItems='center'
                                xs={5}>
                                <FormControlLabel
                                  labelPlacement='start'
                                  label='от'
                                  control={
                                    <Input
                                      value={filters.price.min}
                                      inputProps={{
                                        step: 1000,
                                        min: minPrice,
                                        max: maxPrice,
                                        type: 'number'
                                      }}
                                      onChange={e =>
                                        dispatchFilters({
                                          type: 'setMinPrice',
                                          payload: +e.target.value
                                        })
                                      }
                                      onBlur={() => {
                                        if (filters.price.min < 0) {
                                          dispatchFilters({
                                            type: 'setMinPrice',
                                            payload: 0
                                          })
                                        } else if (
                                          filters.price.min > maxPrice
                                        ) {
                                          dispatchFilters({
                                            type: 'setMinPrice',
                                            payload: maxPrice
                                          })
                                        }
                                      }}
                                      sx={{
                                        marginLeft: '8px',
                                        width: '100%',
                                        '& .MuiInputBase-input-MuiInput-input':
                                          {
                                            marginLeft: '8px',
                                            width: '100%'
                                          }
                                      }}
                                    />
                                  }
                                  sx={{
                                    width: '100%',
                                    '& .MuiTypography-root': {
                                      fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                      lineHeight: '120%'
                                    },
                                    '&.MuiFormControlLabel-root': {
                                      marginLeft: 0
                                    },
                                    '& .MuiInputBase-input-MuiInput-input': {
                                      marginLeft: '8px',
                                      width: '100%'
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs='auto'>
                                <span>—</span>
                              </Grid>
                              <Grid item xs={5}>
                                <FormControlLabel
                                  labelPlacement='start'
                                  label='до'
                                  control={
                                    <Input
                                      value={filters.price.max}
                                      inputProps={{
                                        step: 1000,
                                        min: minPrice,
                                        max: maxPrice,
                                        type: 'number'
                                      }}
                                      onChange={e =>
                                        dispatchFilters({
                                          type: 'setMaxPrice',
                                          payload: +e.target.value
                                        })
                                      }
                                      onBlur={() => {
                                        if (filters.price.max < 0) {
                                          dispatchFilters({
                                            type: 'setMaxPrice',
                                            payload: 0
                                          })
                                        } else if (
                                          filters.price.max > maxPrice
                                        ) {
                                          dispatchFilters({
                                            type: 'setMaxPrice',
                                            payload: maxPrice
                                          })
                                        }
                                      }}
                                      sx={{
                                        marginLeft: '8px',
                                        width: '100%',
                                        '& .MuiInputBase-input-MuiInput-input':
                                          {
                                            marginLeft: '8px',
                                            width: '100%'
                                          }
                                      }}
                                    />
                                  }
                                  sx={{
                                    width: '100%',
                                    '& .MuiTypography-root': {
                                      fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                      lineHeight: '120%'
                                    },
                                    '&.MuiFormControlLabel-root': {
                                      marginLeft: 0
                                    },
                                    '& .MuiInputBase-input-MuiInput-input': {
                                      marginLeft: '8px',
                                      width: '100%'
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item sx={{ padding: '0px, 8px' }}>
                              <CustomSlider
                                value={[filters.price.min, filters.price.max]}
                                step={1000}
                                min={0}
                                max={maxPrice}
                                onChange={(_, newValue) =>
                                  dispatchFilters({
                                    type: 'setPrice',
                                    payload: newValue as [number, number]
                                  })
                                }
                                valueLabelDisplay='auto'
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }}>
                          <span
                            style={{
                              fontSize: '24px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em',
                              marginInlineStart: '0px',
                              marginInlineEnd: '0px'
                            }}>
                            Длительность
                          </span>
                          {/* //TODO сделать склонение слова "месяца(ев)" */}
                          <span>{`От ${filters.duration.min} до ${filters.duration.max} месяцев`}</span>
                          <CustomSlider
                            defaultValue={maxDuration}
                            // getAriaValueText={filters.duration.max}
                            valueLabelDisplay='auto'
                            min={minDuration}
                            max={maxDuration}
                            onChange={(_, newValue) =>
                              typeof newValue === 'number' &&
                              dispatchFilters({
                                type: 'setMaxDuration',
                                payload: newValue
                              })
                            }
                          />
                        </div>
                      </div>

                      <CustomAccordion>
                        <CustomAccordionSummary>
                          <span
                            style={{
                              fontSize: '24px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em',
                              marginInlineStart: '0px',
                              marginInlineEnd: '0px'
                            }}>
                            {heading}
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <PreparedFaculties />
                        </CustomAccordionDetails>
                      </CustomAccordion>

                      <CustomAccordion>
                        <CustomAccordionSummary>
                          <span
                            style={{
                              fontSize: '24px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              display: 'block',
                              marginBlockStart: '1em',
                              marginBlockEnd: '1em',
                              marginInlineStart: '0px',
                              marginInlineEnd: '0px'
                            }}>
                            Выдаваемый документ
                          </span>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            {allDiplomasData?.data?.map(
                              diploma =>
                                diploma.name && (
                                  <FormControlLabel
                                    label={diploma.name}
                                    labelPlacement='end'
                                    control={
                                      <CustomCheckbox
                                        checked={filters.diplomas?.includes(
                                          diploma.name
                                        )}
                                        onChange={() =>
                                          dispatchFilters({
                                            type: 'setDiplomas',
                                            payload: diploma.name ?? ''
                                          })
                                        }
                                      />
                                    }
                                    sx={{
                                      '& .MuiTypography-root': {
                                        fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                                        lineHeight: '120%'
                                      }
                                    }}
                                  />
                                )
                            )}
                          </div>
                        </CustomAccordionDetails>
                      </CustomAccordion>

                      <FormControlLabel
                        label='С трудоустройством'
                        labelPlacement='start'
                        control={
                          <CustomSwitch
                            color='primary'
                            checked={filters.withEmployment}
                            onChange={() =>
                              dispatchFilters({
                                type: 'setWithEmployment'
                              })
                            }
                          />
                        }
                        style={{
                          marginTop: '48px',
                          marginLeft: 0
                        }}
                        sx={{
                          '& .MuiTypography-root': {
                            fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                            fontWeight: 500,
                            lineHeight: '120%'
                          }
                        }}
                      />
                      <Div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '40px'
                        }}>
                        <Button
                          onClick={close}
                          colorsVariant='omegaReverse'
                          sizeVariant='medium'>
                          Применить
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                )}
              </Popup>
            </Div>
          )}
          {isDesktop && (
            <Box
              sx={{
                width: '100%',
                maxWidth: '384px'
              }}>
              <span
                style={{
                  fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                  lineHeight: '120%',
                  fontWeight: '500'
                }}>
                Стоимость
              </span>
              <Grid container direction='column'>
                <Grid
                  item
                  container
                  justifyContent='space-between'
                  alignItems='center'>
                  <Grid
                    item
                    justifyContent='space-around'
                    alignItems='center'
                    xs={5}>
                    <FormControlLabel
                      labelPlacement='start'
                      label='от'
                      control={
                        <Input
                          value={filters.price.min}
                          inputProps={{
                            step: 1000,
                            min: minPrice,
                            max: maxPrice,
                            type: 'number'
                          }}
                          onChange={e =>
                            dispatchFilters({
                              type: 'setMinPrice',
                              payload: +e.target.value
                            })
                          }
                          onBlur={() => {
                            if (filters.price.min < 0) {
                              dispatchFilters({
                                type: 'setMinPrice',
                                payload: 0
                              })
                            } else if (filters.price.min > maxPrice) {
                              dispatchFilters({
                                type: 'setMinPrice',
                                payload: maxPrice
                              })
                            }
                          }}
                          sx={{
                            marginLeft: '8px',
                            width: '100%',
                            '& .MuiInputBase-input-MuiInput-input': {
                              marginLeft: '8px',
                              width: '100%'
                            }
                          }}
                        />
                      }
                      sx={{
                        width: '100%',
                        '& .MuiTypography-root': {
                          fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                          lineHeight: '120%'
                        },
                        '&.MuiFormControlLabel-root': {
                          marginLeft: 0
                        },
                        '& .MuiInputBase-input-MuiInput-input': {
                          marginLeft: '8px',
                          width: '100%'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs='auto'>
                    <span>—</span>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      labelPlacement='start'
                      label='до'
                      control={
                        <Input
                          value={filters.price.max}
                          inputProps={{
                            step: 1000,
                            min: minPrice,
                            max: maxPrice,
                            type: 'number'
                          }}
                          onChange={e =>
                            dispatchFilters({
                              type: 'setMaxPrice',
                              payload: +e.target.value
                            })
                          }
                          onBlur={() => {
                            if (filters.price.max < 0) {
                              dispatchFilters({
                                type: 'setMaxPrice',
                                payload: 0
                              })
                            } else if (filters.price.max > maxPrice) {
                              dispatchFilters({
                                type: 'setMaxPrice',
                                payload: maxPrice
                              })
                            }
                          }}
                          sx={{
                            marginLeft: '8px',
                            width: '100%',
                            '& .MuiInputBase-input-MuiInput-input': {
                              marginLeft: '8px',
                              width: '100%'
                            }
                          }}
                        />
                      }
                      sx={{
                        width: '100%',
                        '& .MuiTypography-root': {
                          fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
                          lineHeight: '120%'
                        },
                        '&.MuiFormControlLabel-root': {
                          marginLeft: 0
                        },
                        '& .MuiInputBase-input-MuiInput-input': {
                          marginLeft: '8px',
                          width: '100%'
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sx={{ padding: '0px, 8px' }}>
                  <CustomSlider
                    value={[filters.price.min, filters.price.max]}
                    step={1000}
                    min={0}
                    max={maxPrice}
                    onChange={(_, newValue) =>
                      dispatchFilters({
                        type: 'setPrice',
                        payload: newValue as [number, number]
                      })
                    }
                    valueLabelDisplay='auto'
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
        {/* {(isLaptop || isTablet) && (
          <div
            style={{
              marginTop: isLaptop ? '64px' : '40px'
            }}>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '120%',
                display: 'block',
                marginBlockStart: '1em',
                marginBlockEnd: '1em'
              }}>
              Тэги
            </span>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px'
              }}>
              {allTagsData?.data?.map(
                tag =>
                  !!tag.name && (
                    <TagButton
                      active={filters.tags.includes(tag.name)}
                      onClick={() =>
                        tag.name &&
                        dispatchFilters({ type: 'setTags', payload: tag.name })
                      }>
                      {tag.name}
                    </TagButton>
                  )
              )}
            </div>
          </div>
        )} */}
        {isMobile && (
          <>
            {/* <Div
              styles={{
                marginTop: '16px',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                overflowY: 'hidden',
                width: '100%',
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}>
              <div
                style={{
                  display: 'inline-flex',
                  gap: '16px'
                }}>
                {allTagsData?.data?.map(
                  tag =>
                    !!tag.name && (
                      <TagButtonMobile
                        active={filters.tags.includes(tag.name)}
                        accentColor={tag.color ?? undefined}
                        onClick={() =>
                          tag.name &&
                          dispatchFilters({
                            type: 'setTags',
                            payload: tag.name
                          })
                        }>
                        {tag.name}
                      </TagButtonMobile>
                    )
                )}
              </div>
            </Div> */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '16px'
              }}>
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '14.4px'
                }}>{`Найдено: ${preparedPrograms.length} курсов`}</span>
              <SortButton
                isHidden={isSortHidden}
                toggleIsHidden={toggleIsSortHidden}
                getSortingBy={getSortingBy}
                sortedBy={sortingBy}
                onClickOutside={hideSorting}
              />
            </div>
          </>
        )}
        {!!preparedPrograms?.length && (
          <>
            <Div
              styles={{
                gap: toUnit(((isDesktop || isLaptop) && 32) || 24),
                display: 'flex',
                flexDirection: (windowWidth ?? 0) > 680 ? 'row' : 'column',
                flexWrap: (windowWidth ?? 0) > 680 ? 'wrap' : 'nowrap',
                justifyContent: 'space-between',
                alignItems: (windowWidth ?? 0) > 680 ? 'center' : 'stretch',
                ...multiMedia({
                  desktop: {
                    marginTop: '80px'
                  },
                  laptop: {
                    marginTop: '40px'
                  },
                  tablet: {
                    marginTop: '40px'
                  },
                  phone: {
                    marginTop: '16px'
                  }
                })
              }}>
              <div style={{ display: 'none' }}>
                {preparedPrograms.map((program, i) => (
                  <Link
                    key={`krowler-${i + 1}`}
                    href={`/${program?.slug || 'program'}--${
                      program?.category?.slugSingular ||
                      program?.category?.slug ||
                      '' ||
                      PATHS.front.general.programs[
                        (locale as keyof typeof LOCALES) || 'ru'
                      ].substring(1)
                    }`}
                    variant='alpha'>
                    {program?.name}
                  </Link>
                ))}
              </div>
              {preparedPrograms.slice(0, itemsToShow)?.map((program, idx) => (
                <ProgramCard
                  key={`Programs__${program?.name || 'Program'}--${idx + 1}`}
                  name={program?.name}
                  slug={program?.slug}
                  facultyLabel={
                    program?.faculty?.label || program?.faculty?.name || ''
                  }
                  categoryLabel={
                    program?.category?.label || program?.category?.name || ''
                  }
                  categorySlug={
                    program?.category?.slugSingular ||
                    program?.category?.slug ||
                    ''
                  }
                  months={program?.cost?.studyDurationMonths}
                  years={program?.cost?.studyDurationYears}
                  hours={program?.cost?.studyHours}
                  accentColor={program?.faculty?.color ?? ''}
                  price={program?.cost?.price}
                />
              ))}
            </Div>
            {itemsToShow < preparedPrograms?.length && (
              <Div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // TODO add adaptive
                  marginTop: '56px'
                }}>
                <Button
                  onClick={showMore}
                  colorsVariant='omegaReverse'
                  sizeVariant='medium'
                  styles={{
                    boxSizing: 'border-box',
                    borderWidth: '1px',
                    ...multiMedia({
                      desktop: {
                        minWidth: '280px'
                      },
                      laptop: {
                        minWidth: '280px'
                      },
                      tablet: {
                        minWidth: '304px'
                      },
                      phone: {
                        minWidth: '100%'
                      }
                    })
                  }}>
                  Показать ещё
                </Button>
              </Div>
            )}
          </>
        )}
      </Layout.FlexItem>
    </Layout.Flex>
  )
}

export default ProgramCards
