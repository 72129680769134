import React from 'react'
import { useRouter } from 'next/router'
import { PATHS, media, multiMedia, toUnit } from 'fractals'
import { Button, Span } from '@/fractals/components'
import { TJournalsFilterButtons } from './types'

export const JournalsFilterButtons = ({
  allJournals,
  tags,
  faculties,
  atHome
}: TJournalsFilterButtons) => {
  const allArticles = 'Все статьи'
  const router = useRouter()

  const filteredTagsByJournals = tags?.filter(tag => {
    return allJournals?.some(journal => {
      return journal?.tag?.slug === tag?.slug
    })
  })

  const filteredFacultiesByJournals = faculties?.filter(faculty => {
    return allJournals?.some(journal => {
      return journal?.faculty?.slug === faculty?.slug
    })
  })

  return (
    <Span
      styles={{
        ...(atHome && {
          ...multiMedia({
            marginBottom: '32px',
            desktop: { marginBottom: '40px' },
            phone: { marginBottom: '40px' }
          })
        }),
        rowGap: toUnit(16),
        columnGap: toUnit(16),
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: '90%',
        ...media('flexWrap', 'wrap', {
          tablet: 'nowrap',
          phone: 'nowrap'
        }),
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        '&::-webkit-scrollbar': {
          display: 'none'
        },

        '& > div': {
          flex: '0 0 auto',
          scrollSnapAlign: 'start',
          padding: '10px'
        },
        '& > button': {
          minWidth: 'max-content'
        }
      }}>
      <Button
        styles={{
          borderWidth: '1px',
          height: '',
          padding: '8px 16px'
        }}
        onClick={() =>
          router.push(`${PATHS.front.root.index}${PATHS.front.journal.ru}`)
        }
        colorsVariant='omegaReverseFaculty'
        sizeVariant='sm'>
        {allArticles}
      </Button>
      {tags &&
        filteredTagsByJournals?.map((tag, idx) => (
          <Button
            key={`ButtonsContainer__${idx + 1}`}
            facultyColor={tag.color}
            colorsVariant='omegaReverseFaculty'
            sizeVariant='sm'
            onClick={() =>
              router.push(
                `${PATHS.front.root.index}${PATHS.front.journal.ru}--${tag.slug}`
              )
            }
            styles={{
              borderWidth: '1px',
              height: '',
              padding: '8px 16px'
            }}>
            {tag.name}
          </Button>
        ))}
      {faculties &&
        filteredFacultiesByJournals?.map((faculty, idx) => (
          <Button
            key={`ButtonsContainer__${idx + 1}`}
            facultyColor={faculty.color}
            colorsVariant='omegaReverseFaculty'
            sizeVariant='sm'
            onClick={() =>
              router.push(
                `${PATHS.front.root.index}${PATHS.front.journal.ru}--${faculty.slug}`
              )
            }
            styles={{
              borderWidth: '1px',
              height: '',
              padding: '8px 16px'
            }}>
            {faculty.name}
          </Button>
        ))}
    </Span>
  )
}

export default JournalsFilterButtons
