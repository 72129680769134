export {
  TITLE,
  DESCRIPTION_1,
  DESCRIPTION_2,
  DESCRIPTION_3,
  DESCRIPTION_4,
  DESCRIPTION_5,
  DESCRIPTION_6
} from './constants'
export type { TStudyIsProps } from './types'
export { image1, image2, image3, image4 } from './images'
export { getStudyIsData } from './api'
