import * as React from 'react'
import { toUnit, IconContainer } from 'fractals'
import Color from 'color'
import { TArrowProps } from './types'

export const Arrow = ({ colorVariant, ...props }: TArrowProps) => {
  const styles = {
    width: toUnit(10),
    height: toUnit(10),
    margin: '0 0.5rem 0 0.5rem',
    ...props?.styles
  } as const

  return (
    <IconContainer styles={styles} {...props}>
      <svg
        width='7'
        height='10'
        viewBox='0 0 7 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1.81934 9L5.81934 5L1.81934 1'
          stroke={props?.styles?.stroke ?? '#8B8B8B'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconContainer>
  )
}

export default Arrow
