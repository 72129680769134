import React, { Fragment, useState } from 'react'
import Link from 'next/link'
import { toUnit, Image, media, useMedia, multiMedia } from 'fractals'
import {
  Button,
  Container,
  Div,
  IconPlus,
  P,
  Span
} from '@/fractals/components'
import { TRelatedCoursesMobileProps } from './types'

const Popup = React.lazy(() => import('reactjs-popup'))

export const RelatedCoursesMobile = ({
  programs
}: TRelatedCoursesMobileProps) => {
  const [isRelatedCourses, toggleRelatedCourses] = useState(true)
  const [open, setOpen] = useState(false)
  const { isMobile } = useMedia()

  const learnMore = 'Узнать больше'
  const relatedCourses = 'Курсы по теме'

  return (
    <Div
      styles={{
        ...multiMedia({
          desktop: { display: 'none' },
          laptop: { display: 'block' },
          tablet: { display: 'block' },
          phone: { display: 'block' }
        }),
        backgroundColor: 'var(--omega)'
      }}>
      {isRelatedCourses && (
        <Container
          styles={{
            ...media('maxWidth', null, {
              laptop: '800px',
              tablet: '640px',
              phone: '343px'
            })
          }}>
          <Div
            styles={{
              width: '-webkit-fill-available',
              height: '3.6rem',
              position: 'sticky',
              backgroundColor: 'var(--omega)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <P
              styles={{
                fontSize: '16px'
              }}
              variant='pi'>
              Курсы по теме
            </P>
            <Button
              onClick={() => setOpen(true)}
              styles={{
                position: 'relative',
                zIndex: 2,
                marginLeft: 'auto',
                marginRight: '2rem',
                width: '124px',
                height: '38px',
                boxSizing: 'border-box',
                fontWeight: 500,
                fontSize: '12px',
                padding: 0
              }}
              colorsVariant='beta'>
              Узнать больше
            </Button>
            <Popup
              modal
              nested
              closeOnDocumentClick
              closeOnEscape
              lockScroll
              open={open}
              onClose={() => setOpen(false)}>
              {/* @ts-expect-error TODO: remove this line and fix the error */}
              {(close: MouseEventHandler) => (
                <Div
                  styles={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '24px',
                    gap: '24px',
                    boxSizing: 'border-box',
                    ...media('maxWidth', null, {
                      laptop: '800px',
                      tablet: '640px',
                      phone: '343px'
                    }),
                    maxHeight: '33rem',
                    backgroundColor: 'var(--omega)',
                    borderRadius: '16px',
                    color: 'var(--alpha)'
                  }}>
                  <Div
                    styles={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '-webkit-fill-available',
                      '& span': {
                        marginLeft: 'auto',
                        backgroundColor: 'var(--alpha)',
                        borderRadius: '50%',
                        ':hover': {
                          cursor: 'pointer'
                        }
                      }
                    }}>
                    <Div
                      styles={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 16px',
                        marginBottom: toUnit(16),
                        ...multiMedia({
                          desktop: { width: '118px', height: '30px' },
                          laptop: { width: '118px', height: '30px' },
                          tablet: { width: '118px', height: '30px' },
                          phone: { width: '133px', height: '33px' }
                        }),
                        background: '#3A52EE',
                        borderRadius: '8px'
                      }}>
                      <P
                        styles={{
                          ...media('fontSize', '12px', {
                            phone: '14px'
                          })
                        }}
                        variant='pi'>
                        {relatedCourses}
                      </P>
                    </Div>
                    <IconPlus
                      styles={{
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                      onClick={close}
                      colorVariant='omega'
                      variant='crossMini'
                    />
                  </Div>

                  {programs &&
                    programs?.map(
                      ({ image, name, slug }, idx) =>
                        idx < 3 && (
                          <Fragment key={`RelatedCourses-Div__${idx + 1}`}>
                            <Div
                              styles={{
                                color: 'var(--alpha)',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '14px',
                                width: '100%',
                                flexWrap: 'wrap'
                              }}>
                              <Div
                                styles={{
                                  minWidth: '40px',
                                  height: '40px',
                                  position: 'relative',
                                  flexBasis: 'auto'
                                }}>
                                <Image
                                  colorVariant='transparent'
                                  src={image?.url || '/'}
                                  fill
                                  alt=''
                                  containerProps={{
                                    styles: {
                                      borderRadius: '8px',
                                      '& img': {
                                        objectFit: 'cover'
                                      }
                                    }
                                  }}
                                />
                              </Div>
                              <Div
                                styles={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: toUnit(18),
                                  width: '75%',
                                  justifyContent: 'space-between'
                                }}>
                                <Span
                                  styles={{
                                    ...media('width', null, {
                                      tablet: 'max-content',
                                      laptop: 'max-content'
                                    })
                                  }}>
                                  {name}
                                </Span>
                                <Link href={`/${slug}--kurs`}>
                                  <Span
                                    styles={{
                                      color: 'var(--alpha)',
                                      textDecoration: 'underline',
                                      ':hover': {
                                        textDecorationColor: 'var(--omega)'
                                      },
                                      textDecorationColor: 'var(--alpha)'
                                    }}>{`${learnMore}`}</Span>
                                </Link>
                              </Div>
                            </Div>
                          </Fragment>
                        )
                    )}
                  <Span
                    styles={{
                      fontSize: '14px',
                      marginTop: toUnit(16)
                    }}>
                    <P
                      variant='xi'
                      styles={{
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '120%',
                        ...multiMedia({
                          phone: { width: '300px' }
                        }),
                        letterSpacing: '0.4px',
                        color: '#8B8B8B'
                      }}>
                      <Span
                        styles={{
                          color: 'var(--alpha)'
                        }}>
                        *{' '}
                      </Span>
                      В этом месяце мы подготовили для вас{' '}
                      <Span
                        styles={{
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '120%',
                          color: 'var(--beta)'
                        }}>
                        скидку 30%
                      </Span>{' '}
                      на все курсы! Спешите записаться на обучение, ведь
                      количество мест ограничено
                    </P>
                  </Span>
                </Div>
              )}
            </Popup>
            <IconPlus
              onClick={prev => toggleRelatedCourses(!prev)}
              styles={{
                backgroundColor: 'var(--alpha)',
                borderRadius: '50%',
                position: 'relative',
                zIndex: 2,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              colorVariant='omega'
              variant='crossMini'
            />
          </Div>
        </Container>
      )}
    </Div>
  )
}

export default RelatedCoursesMobile
