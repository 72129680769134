import {
  useTranslations,
  P,
  toUnit,
  Image,
  InfoTag,
  Div,
  Ul,
  Li,
  Span,
  useCalculateClosestEnrollment,
  useCalculateStudyDuration,
  useAt,
  useMedia
} from 'fractals'
import { StaticImageData } from 'next/image'
import { useRouter } from 'next/router'
import Color from 'color'
import { Shared } from 'modules'
import {
  SBER_OFFER,
  TINKOFF_OFFER,
  REMOTE,
  STATE_DIPLOMA,
  BTN_VALUE,
  SBER_LOGO_ALT,
  TINKOFF_LOGO_ALT,
  DEFAULT_CATEGORY_LABEL,
  CLOSEST_ENROLLMENT_TEXT,
  STUDY_DURATION
} from './constants'
import { TWhatIsIncludedProps } from './types'
import sberLogo from './sber-logo.svg'
import tinkoffLogo from './tinkoff-logo.svg'

export const WhatIsIncludedPsihologiyaIsHigherEducation = ({
  cost,
  categoryLabel,
  colorVariant,
  closestEnrollment,
  studyDurationMonths,
  studyDurationYears,
  hasPopup = true
}: TWhatIsIncludedProps) => {
  const [
    sberOffer,
    tinkoffOffer,
    remote,
    stateDiploma,
    btnValue,
    sberLogoAlt,
    tinkoffLogoAlt,
    defaultCategoryLabel,
    closestEnrollmentText,
    studyDuration
  ] = useTranslations(
    SBER_OFFER,
    TINKOFF_OFFER,
    REMOTE,
    STATE_DIPLOMA,
    BTN_VALUE,
    SBER_LOGO_ALT,
    TINKOFF_LOGO_ALT,
    DEFAULT_CATEGORY_LABEL,
    CLOSEST_ENROLLMENT_TEXT,
    STUDY_DURATION
  )

  const { isHigherEducation } = useAt()

  const { isTablet, isLaptop, isDesktop } = useMedia()

  const { locale } = useRouter()

  const calculatedClosestEnrollment = useCalculateClosestEnrollment({
    timestamp: closestEnrollment
  })
  const calculatedStudyDuration = useCalculateStudyDuration({
    years: studyDurationYears,
    months: studyDurationMonths
  })

  const whatsIncluded = [
    ...((locale !== 'en' &&
      isHigherEducation &&
      ([
        {
          name: sberOffer,
          img: {
            src: sberLogo as StaticImageData,
            alt: sberLogoAlt
          }
        }
      ] as const)) ||
      []),
    ...((locale !== 'en' &&
      ([
        {
          name: tinkoffOffer,
          img: {
            src: tinkoffLogo as StaticImageData,
            alt: tinkoffLogoAlt
          }
        }
      ] as const)) ||
      []),
    {
      name: `${studyDuration} — ${calculatedStudyDuration}`,
      img: null
    },
    {
      name: remote,
      img: null
    },
    {
      name: `${closestEnrollmentText} ${calculatedClosestEnrollment}`,
      img: null
    },
    {
      name: stateDiploma,
      img: null
    }
  ] as const

  const gap = toUnit(((isDesktop || isLaptop) && 56) || 40)

  return (
    <Div
      styles={{
        display: 'flex',
        alignItems: 'flex-start',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: isDesktop ? 'start' : 'space-between'
      }}>
      <InfoTag
        colorVariant='alpha'
        styles={{
          marginBottom: `calc(${gap} / 1.75)`
        }}>
        {categoryLabel || defaultCategoryLabel}
      </InfoTag>

      <Ul variant='delta'>
        {whatsIncluded.map((item, idx) => (
          <Li
            variantAlphaVariant={(item?.img && 'point:mt0') || undefined}
            variant='alpha'
            colorVariant={
              (colorVariant?.color &&
                Color(colorVariant.color).rgb().string()) ||
              undefined
            }
            key={`Cost__whatsIncluded__${item.name}-${idx + 1}`}
            styles={{
              ...((item?.img && {
                alignItems: 'center'
              }) ||
                {})
            }}>
            <Div
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexGrow: 1,
                gap: toUnit(12)
              }}>
              <Span
                styles={{
                  fontSize: toUnit((isDesktop && 20) || 16),
                  fontWeight: 500,
                  lineHeight: '120%'
                }}>
                {item.name}
              </Span>
              {item?.img && <Image colorVariant='transparent' {...item?.img} />}
            </Div>
          </Li>
        ))}
      </Ul>
      {hasPopup && (
        <Shared.LeadPopup
          cost={cost}
          buttonProps={{
            children: btnValue,
            colorsVariant: 'betaCost',
            styles: {
              marginTop: gap,
              marginLeft: ((isLaptop || isTablet) && 'auto') || undefined,
              marginRight: ((isLaptop || isTablet) && 'auto') || undefined
            }
          }}
        />
      )}
    </Div>
  )
}

export default WhatIsIncludedPsihologiyaIsHigherEducation
