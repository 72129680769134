export const FiltersIcon = ({ color = '#18191A' }: { color: string }) => (
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 6.75065C12.5188 6.75065 13.75 7.98187 13.75 9.50065C13.75 10.7594 12.9043 11.8207 11.75 12.1471L11.75 14.1673C11.75 14.5815 11.4142 14.9173 11 14.9173C10.5858 14.9173 10.25 14.5815 10.25 14.1673L10.25 12.1471C9.09575 11.8207 8.25 10.7594 8.25 9.50065C8.25 7.98187 9.48122 6.75065 11 6.75065ZM9.75 9.50065C9.75 10.191 10.3096 10.7507 11 10.7507C11.6904 10.7507 12.25 10.191 12.25 9.50065C12.25 8.8103 11.6904 8.25065 11 8.25065C10.3096 8.25065 9.75 8.81029 9.75 9.50065ZM11.75 0.833984C11.75 0.419771 11.4142 0.0839843 11 0.0839843C10.5858 0.0839842 10.25 0.419771 10.25 0.833984L10.25 4.83398C10.25 5.2482 10.5858 5.58398 11 5.58398C11.4142 5.58398 11.75 5.2482 11.75 4.83398L11.75 0.833984ZM2.25 0.833984L2.25 2.1875C1.09575 2.51396 0.25 3.57521 0.25 4.83398C0.25 6.35277 1.48122 7.58398 3 7.58398C4.51878 7.58398 5.75 6.35277 5.75 4.83398C5.75 3.57521 4.90425 2.51396 3.75 2.1875L3.75 0.833984C3.75 0.41977 3.41421 0.0839839 3 0.0839839C2.58579 0.0839839 2.25 0.41977 2.25 0.833984ZM1.75 4.83398C1.75 4.14363 2.30964 3.58398 3 3.58398C3.69036 3.58398 4.25 4.14363 4.25 4.83398C4.25 5.52434 3.69036 6.08398 3 6.08398C2.30964 6.08398 1.75 5.52434 1.75 4.83398ZM3.75 9.50065C3.75 9.08644 3.41421 8.75065 3 8.75065C2.58579 8.75065 2.25 9.08644 2.25 9.50065L2.25 14.1673C2.25 14.5815 2.58579 14.9173 3 14.9173C3.41421 14.9173 3.75 14.5815 3.75 14.1673L3.75 9.50065Z'
      fill={color}
    />
  </svg>
)
