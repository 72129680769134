import { LOCALES } from 'fractals'

export const FOR_1_TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Выпускники 11 класса',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Есть возможность поступить без ЕГЭ по специальной программе',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_2_TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Для тех, кто хочет перевестись из другого ВУЗа',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_2 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Перевод из других вузов осуществляется без потери курса',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_3_TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'У кого уже есть образование',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_3 = {
  [LOCALES.en]: '',
  [LOCALES.ru]:
    'По окончании колледжа вы можете поступить на сокращённую форму обучения продолжительностью 3.5 года без сдачи ЕГЭ',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_4_TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Для тех, у кого есть высшее образование',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const FOR_4 = {
  [LOCALES.en]: '',
  [LOCALES.ru]:
    'При наличии диплома о высшем образовании есть возможность получить второе высшее образование по ускоренной программе продолжительностью 3.5 года и без сдачи ЕГЭ',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
