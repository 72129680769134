import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import Color from 'color'
import { TIconClockProps } from './types'

const IconClock = ({ colorVariant, ...props }: TIconClockProps) => {
  const styles = {
    // width: toUnit(16),
    // height: toUnit(16),
    ...props?.styles
  } as const

  return (
    <IconContainer styles={styles} {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.80144 9.29156L7.8014 9.29152L6.59933 7.78962V5.66667C6.59933 5.33544 6.33056 5.06667 5.99933 5.06667C5.6681 5.06667 5.39933 5.33544 5.39933 5.66667V8C5.39933 8.13709 5.44664 8.26897 5.53113 8.37499L5.53125 8.37513L6.86458 10.0418C6.9831 10.1899 7.15772 10.2667 7.33333 10.2667C7.46481 10.2667 7.59693 10.2233 7.70773 10.1348L7.70776 10.1348C7.967 9.92752 8.00851 9.55061 7.80144 9.29156ZM10.2426 2.57529L10.3133 2.646L10.2426 2.57529L9.31868 3.49925C8.54075 2.92394 7.61169 2.54269 6.6 2.43467V1.6H7.33333C7.66456 1.6 7.93333 1.33123 7.93333 1C7.93333 0.668771 7.66456 0.4 7.33333 0.4H4.66667C4.33544 0.4 4.06667 0.668771 4.06667 1C4.06667 1.33123 4.33544 1.6 4.66667 1.6H5.4V2.43472C2.59383 2.73557 0.4 5.11477 0.4 8C0.4 11.0879 2.9121 13.6 6 13.6C9.0879 13.6 11.6 11.0879 11.6 8C11.6 6.58672 11.0692 5.29788 10.2029 4.31252L11.0914 3.42406C11.3258 3.18968 11.3258 2.80967 11.0914 2.57529C10.857 2.3409 10.477 2.3409 10.2426 2.57529ZM6 12.4C3.57389 12.4 1.6 10.4261 1.6 8C1.6 5.57389 3.57389 3.6 6 3.6C8.42611 3.6 10.4 5.57389 10.4 8C10.4 10.4261 8.42611 12.4 6 12.4Z'
          fill={styles?.fill || '#8B8B8B'}
          stroke={styles?.stroke || '#8B8B8B'}
          strokeWidth='0.2'
        />
      </svg>
    </IconContainer>
  )
}

export default IconClock
