import * as Flag from 'country-flag-icons/react/3x2'
import { FC, MouseEvent } from 'react'
import { Div } from 'fractals'

function getFlagComponent(thisCountryCode: string) {
  return (Flag as Record<string, any> & { value?: string })[thisCountryCode]
}

interface IFLagItemProps {
  code: string
  onClick: (e: MouseEvent<HTMLElement>) => void
}

export const FlagItem: FC<IFLagItemProps> = ({ code, onClick }) => {
  const FlagComponent = getFlagComponent(code)
  if (!FlagComponent) return null
  return (
    <Div styles={{ width: '25.5px', height: '17px' }}>
      <FlagComponent onClick={onClick} data-value={code}>
        {(Flag as Record<string, any>)[`${code}`]}
      </FlagComponent>
    </Div>
  )
}
