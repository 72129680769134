export type { THeroProps } from './types'
export {
  DEFAULT_CATEGORY_LABEL,
  HOW_TO_REDUCE,
  IMG_DEFAULT_ALT,
  CLOSEST_ENROLLMENT,
  STUDY_DURATIION,
  STUDY_FORMAT,
  STUDY_FORMAT_VALUE,
  GRADUATION_DOCUMENTS,
  EDUCATION_LEVEL,
  HOURS_AMOUNT,
  HOURS,
  PRIMARY_BTN,
  SECONDARY_BTN,
  GRADUATION_DOCUMENTS_DEFAULT
} from './constants'
export { getHeroData } from './api'
