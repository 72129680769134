import { useRouter } from 'next/router'
// import { TLocaleKeys, TTranslations } from 'fractals'
import { TTuple, ArrayElementType } from 'ui'
// TODO: remove the line and fix the error
// eslint-disable-next-line
import { TLocaleKeys } from '../..'
import { checkIfCorrectLocaleKey } from './checkIfCorrectLocaleKey'
import { TUseTranslationsProps } from './types'

// TODO: improve useTranslations types

export const useTranslations = <T extends TUseTranslationsProps>(
  ...translations: T
) => {
  if (!translations) throw new Error('Translations are not provided!')

  const { locale } = useRouter()

  const correctLocaleKey = checkIfCorrectLocaleKey(locale)

  const output = translations.map(
    (transaltion, idx) =>
      transaltion[correctLocaleKey] as ArrayElementType<
        T[typeof idx extends infer Idx ? Idx : never]
      >[TLocaleKeys]
  )

  // return output

  return output as TTuple<
    ArrayElementType<T>[TLocaleKeys],
    T['length']
  > /* Tuple<T[number][TLocaleKeys], T['length']> */ // TODO: improve this type so that it's not just string but constant type
}

// import { useRouter } from 'next/router'
// import { TLocaleKeys, TTranslations } from 'fractals'
// import { checkIfCorrectLocaleKey } from './checkIfCorrectLocaleKey'
// import { TUseTranslationsProps } from './types'

// // T extends infer Value extends T
// //   ? readonly Value[number][TLocaleKeys][] & { length: T['length'] }
// //   : undefined

// type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift'

// type FixedLengthArray<T, L extends number, TObj = [T, ...Array<T>]> = Pick<
//   TObj,
//   Exclude<keyof TObj, ArrayLengthMutationKeys>
// > & {
//   readonly length: L
//   [I: number]: T
//   [Symbol.iterator]: () => IterableIterator<T>
// }

// export const useTranslations = <T extends TUseTranslationsProps>(
//   ...translations: T
// ): T extends infer Value extends T
//   ? FixedLengthArray<string, Value['length']>
//   : never => {
//   if (!translations) throw new Error('Translations are not provided!')

//   const { locale } = useRouter()

//   const correctLocaleKey = checkIfCorrectLocaleKey(locale)

//   const output = translations.map(transaltion => transaltion[correctLocaleKey])

//   return output
//   // return output as [typeof translations[number][TLocaleKeys]]
// }

// // const test = [1, 2]

// // const requireTwoSameLengthArrays = <T extends readonly [] | readonly any[]>(
// //   t: T,
// //   u: { [K in keyof T]: any }
// // ): void => {}

// // requireTwoSameLengthArrays([1, 2], [1, 3])

// // type TTest<T extends string[]> = T extends infer Value extends string[]
// //   ? Value[number]
// //   : void

// // type TTest2 = TTest<['123', '1234', '12345']>

// // type ArrayOfFixedLength<T, N extends number> = readonly T[] & {
// //   length: N
// // }

// // const a1: ArrayOfFixedLength<number, 2> = [1] as const
// // const a2: ArrayOfFixedLength<number, 2> = [1, 2] as const

// // const test2 = test[0]

// // const test3 = [123, 1234] as const

// // const test4 = test3[0]

// // const myFunc = <T extends string[]>(
// //   ...arr: T
// // ): T extends string[] ? readonly number[] & { length: T['length'] } : void => {
// //   const output = arr.map(item => item.length)
// //   return output
// // }

// // const test10 = myFunc('123', '1234')

// // console.log(test10)

// // const test11 = test10[0]
// // const test12 = test10[1]
// // const test13 = test10[2]
