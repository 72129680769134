import { useState, useEffect /* , ElementRef, RefObject */ } from 'react'

// ! Does not work yet
// TODO: figure out better types
export const useIntersection = <T, M extends string>(
  element: T,
  rootMargin: M // 10rem || -10.2rem
) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    // @ts-expect-error fix the type and remove this
    const curElement = element?.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        const fireOnlyOnce = entry?.isIntersecting
        if (entry && fireOnlyOnce) {
          setState(entry.isIntersecting)
          observer.unobserve(curElement)
        }
      },
      { rootMargin }
    )

    if (curElement) observer?.observe(curElement)

    return () => observer?.unobserve(curElement)
  }, [element, rootMargin])

  return isVisible
}

export default useIntersection
