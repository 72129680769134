import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TCategory, TGetQuestionsDataProps, TResCategories } from '../types'
import { TResFaculties, TResFacultyData } from '../../getFacultiesData/types'

const fields = ['name', 'answer', 'rank']

export const fetchQuestions = async ({ context }: TGetQuestionsDataProps) => {
  try {
    const { currentCategorySlug, currentFacultySlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    const populateVariants: { [key: string]: any } = {
      bakalavriat: {
        questionsBakalavriat: {
          fields
        }
      },
      magistratura: {
        questionsMagistratura: {
          fields
        }
      },
      kursy: {
        questionsKursy: {
          fields
        }
      }
    }

    const query = qs.stringify({
      fields: ['name'],
      populate: {
        questions: {
          fields
        },
        ...populateVariants[currentCategorySlug ?? '']
      },
      filters: {
        slug: {
          $eq: currentFacultySlug
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResFaculties>(
      `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${query}`
    )

    return res?.data?.at(0) || null
  } catch (e) {
    console.log('%cfetchQuestions.ts line:9 e', 'color: #007acc;', e)
    return null
  }
}

export default fetchQuestions
