import { Div, toUnit, useMedia } from 'fractals'
import { repeatJSX } from 'ui'

export const Rounds = () => {
  const { isDesktop, isLaptop, isTablet } = useMedia()
  const getRoundStls = (item: 'top' | 'bottom') =>
    ({
      width: toUnit(234),
      height: toUnit(234),
      backgroundColor: item === 'top' ? '#F07A56' : '#3A52EE',
      borderRadius: '50%',
      position: 'absolute',
      top: `${item === 'top' ? '-' : ''}49%`,
      right:
        (isDesktop && '-18%') ||
        (isTablet && '-50%') ||
        (isLaptop && '-40%') ||
        '-52%'
    } as const)
  return (
    <>
      {repeatJSX(2, (_v, idx) => (
        <Div styles={getRoundStls(idx === 0 ? 'top' : 'bottom')} />
      ))}
    </>
  )
}
