import { useEffect, useState, FC } from 'react'
import { Div } from 'fractals'
import { IconScroll } from '@/fractals/components'
import { SmoothScrollLink } from '@/modules/journal-page/widgets/Journal/fractals/components/atoms'
import { IDS } from '@/modules/home-page/fractals'

type Props = {
  // eslint-disable-next-line
  targetId?: string
}

export const Scroll: FC<Props> = ({ targetId = IDS.homePage }) => {
  const [opacity, setOpacity] = useState(0)

  const handleScroll = () => {
    const { scrollHeight } = document.documentElement
    const scrollPosition = window.scrollY + window.innerHeight
    const scrollPercent = (scrollPosition / scrollHeight) * 100

    if (scrollPercent >= 10 && scrollPercent <= 20) {
      setOpacity((scrollPercent - 10) / 10)
    } else if (scrollPercent > 20) {
      setOpacity(1)
    } else {
      setOpacity(0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <SmoothScrollLink targetId={targetId}>
      <Div
        style={{
          position: 'fixed',
          right: '25px',
          bottom: '95px',
          zIndex: 998,
          opacity,
          transition: 'opacity 0.1s ease',
          cursor: 'pointer'
        }}>
        <IconScroll />
      </Div>
    </SmoothScrollLink>
  )
}

export default Scroll
