import { TGetHeaderDataProps } from '../getHeaderData/types'
import { fetchProgramImages, normalizeProgramImagesData } from './utils'

export const getProgramImagesData = async ({
  context
}: TGetHeaderDataProps) => {
  try {
    const programImagesData = await fetchProgramImages({ context })

    const programImagesDataNormalized = normalizeProgramImagesData({
      programImagesData
    })

    return programImagesDataNormalized
  } catch (e) {
    console.log('%cgetProgramImagesData.ts line:10 e', 'color: #007acc;', e)
  }
  return null
}

export default getProgramImagesData
