import * as React from 'react'
import { StyledJSX } from 'ui'
import { TGridItemProps } from './types'
import { ROOT_ELEMENT } from './constants'

const GridItem = ({ ...props }: TGridItemProps) => {
  return (
    <StyledJSX.Element
      {...props}
      as={ROOT_ELEMENT}
      styles={{ ...props.styles }}
    />
  )
}

export default GridItem
