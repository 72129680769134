import { LOCALES } from 'fractals'

export const SEO_TITLE = {
  [LOCALES.en]: 'Online education programs from MITM online institute',
  [LOCALES.ru]: 'Программы дистанционного обучения от онлайн-института MITM',
  [LOCALES.uz]: 'MITM online institutidan online ta’lim dasturlari',
  [LOCALES.kk]: 'MITM online institutidan online ta’lim dasturlari'
} as const

export const SEO_DESCRIPTION = {
  [LOCALES.ru]:
    'Дистанционные программы обучения по востребованным направлениям и специальностям. Удобный формат со скидкой 30% Государственная аккредитация! Актуальные подходы к образованию вместе с экспертами-практиками',
  [LOCALES.en]:
    'Online education programs from MITM online institute. Convenient format with a 30% discount State accreditation! Up-to-date approaches to education together with experts-practitioners',
  [LOCALES.uz]:
    'MITM online institutidan online ta’lim dasturlari. Qulay format 30% chegirma bilan Davlat akreditatsiyasi! Ta’limda so‘nggi yo‘nalishlar ekspertlar-praktikalar bilan birga',
  [LOCALES.kk]:
    'MITM online institutidan online ta’lim dasturlari. Qulay format 30% chegirma bilan Davlat akreditatsiyasi! Ta’limda so‘nggi yo‘nalishlar ekspertlar-praktikalar bilan birga'
} as const
