import * as React from 'react'
import { StyledJSX } from 'ui'
import { multiMedia, toUnit, useMedia } from 'fractals'
import Color from 'color'
import { TSectionProps } from './types'
import { SPACING_VARIANT_KEYS, BG_VARIANT_KEYS } from './constants'

const Section = ({
  spacingVariant,
  colorsVariant,
  colorVariant,
  ...props
}: TSectionProps) => {
  const { isTablet, isLaptop, isDesktop, isMobile } = useMedia()

  const stylesSpacingVariants = {
    // use this for Hero HomePage and Faculties
    [SPACING_VARIANT_KEYS.alpha]: {
      paddingTop: toUnit((isDesktop && 80) || (isTablet && 40) || 64),
      paddingBottom: toUnit(
        (isDesktop && 80) || ((isLaptop || isTablet) && 64) || 56
      ),
      ...multiMedia({
        desktop: { paddingBottom: '80px' },
        laptop: { paddingBottom: '64px' },
        tablet: { paddingBottom: '64px' },
        phone: { paddingBottom: '56px' }
      })
    },
    // use this for Our Mission
    [SPACING_VARIANT_KEYS.beta]: {
      paddingTop: toUnit((isDesktop && 80) || 64),
      paddingBottom: toUnit(
        (isDesktop && 80) || (isLaptop && 56) || (isTablet && 90) || 64
      )
    },
    // use this for Testimonials
    [SPACING_VARIANT_KEYS.gamma]: {
      paddingTop: toUnit(((isDesktop || isLaptop) && 56) || 40),
      paddingBottom: toUnit(((isDesktop || isLaptop) && 56) || 40)
    },
    [SPACING_VARIANT_KEYS.delta]: {
      paddingTop: toUnit(
        (isDesktop && 80) || ((isLaptop || isTablet) && 64) || 40
      ),
      paddingBottom: toUnit(
        (isDesktop && 80) || ((isLaptop || isTablet) && 64) || 56
      )
    },
    [SPACING_VARIANT_KEYS.epsilon]: {
      paddingTop: toUnit(32),
      paddingBottom: toUnit(32)
    },
    [SPACING_VARIANT_KEYS.zeta]: {
      paddingBottom: toUnit(64)
    },
    [SPACING_VARIANT_KEYS.eta]: {
      paddingTop: toUnit((isDesktop && 48) || (isTablet && 32) || 16),
      paddingBottom: toUnit(
        (isDesktop && 48) || ((isLaptop || isTablet) && 32) || 16
      )
    },
    [SPACING_VARIANT_KEYS.theta]: {
      ...multiMedia({
        desktop: {
          margin: `${toUnit(64)} ${toUnit(0)}`,
          padding: `0 ${toUnit(112)}`
        },
        laptop: {
          margin: `${toUnit(64)} ${toUnit(0)}`,
          padding: `0 ${toUnit(112)}`
        },
        tablet: {
          margin: `${toUnit(64)} ${toUnit(0)}`,
          padding: `0 ${toUnit(64)}`
        },
        phone: {
          margin: `${toUnit(56)} ${toUnit(0)}`,
          padding: `0 ${toUnit(16)}`
        }
      })
    },
    [SPACING_VARIANT_KEYS.iota]: {
      ...multiMedia({
        desktop: {
          margin: `${toUnit(64)} ${toUnit(0)} ${toUnit(80)}`
        },
        laptop: {
          margin: `${toUnit(84)} ${toUnit(0)} ${toUnit(64)}`
        },
        tablet: {
          margin: `${toUnit(40)} ${toUnit(0)} ${toUnit(64)}`
        },
        phone: {
          margin: `${toUnit(32)} ${toUnit(0)}`
        }
      })
    }
  } as const

  const stylesColorsVariants = {
    [BG_VARIANT_KEYS.alpha]: {
      backgroundColor: 'var(--beta)',
      color: 'var(--alpha)'
    },
    [BG_VARIANT_KEYS.beta]: {
      backgroundColor: 'var(--omega)',
      color: 'var(--alpha)'
    },
    [BG_VARIANT_KEYS.gamma]: {
      backgroundColor: 'var(--chi)',
      color: 'var(--omega)'
    },
    [BG_VARIANT_KEYS.delta]: {
      backgroundColor: 'var(--alpha)',
      color: 'var(--omega)'
    }
  } as const

  const styles = {
    // DEFAULT STYLES WOULD GO HERE
    ...stylesSpacingVariants[spacingVariant],
    ...((colorsVariant && stylesColorsVariants[colorsVariant]) || {}),
    ...(colorVariant?.color && {
      backgroundColor: Color(colorVariant.color).rgb().string(),
      color: 'var(--alpha)'
    }),
    ...props.styles
  } as const

  return <StyledJSX.Section {...props} styles={styles} />
}

export default Section
