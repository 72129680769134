import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  Image,
  useMedia
} from 'fractals'
import { Layout, slugify, spacesToNonBrakingSpaces } from 'ui'
import { Shared } from 'modules'
import {
  TCorporateLeadProps,
  TITLE,
  DESCRIPTION,
  images,
  BUTTON,
  ellipses,
  createRenderImgsArr
} from './fractals'

const CorporateLead = ({ cost }: TCorporateLeadProps) => {
  const [title, description, button] = useTranslations(
    TITLE,
    DESCRIPTION,
    BUTTON
  )

  const { isTablet, isLaptop, isDesktop, isMobile } = useMedia()

  const renderImgs = createRenderImgsArr(images)

  const renderEllipses = createRenderImgsArr(ellipses)

  return (
    <Section
      id={slugify(title)}
      spacingVariant='beta'
      styles={{
        '*': { boxSizing: 'border-box' }
      }}>
      <Container>
        <Layout.Flex
          cols={!isMobile ? 2 : 1}
          gap={toUnit(isMobile ? 40 : 32)}
          proportion={(isLaptop && '5:3') || (isTablet && '2:1') || '1:1'}
          styles={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            '@media only screen and (min-width: 482px) and (max-width: 510px)':
              {
                flexDirection: 'column'
              }
          }}>
          <Layout.FlexItem>
            <H2
              variant='beta'
              styles={{
                marginBottom: toUnit(isDesktop ? 32 : 16)
              }}>
              {title}
            </H2>
            <P
              variant='beta'
              styles={{
                fontSize: toUnit(isDesktop ? 24 : 20),
                marginBottom: toUnit(isDesktop || isLaptop ? 56 : 40)
              }}>
              {description}
            </P>
            <Shared.LeadPopup
              cost={cost}
              buttonProps={{
                colorsVariant: 'omega',
                children: spacesToNonBrakingSpaces(button),
                styles: {
                  fontSize: toUnit(18),
                  maxWidth: isMobile
                    ? '100%'
                    : toUnit((isDesktop && 280) || (isLaptop && 208) || 304),
                  width: '100%'
                }
              }}
            />
          </Layout.FlexItem>
          <Layout.Grid>
            <Layout.Grid
              styles={{
                gridArea: '1 / 1',
                grid: `repeat(${isMobile || isTablet ? 3 : 2}, 1fr) / ${
                  isMobile || isTablet ? 'auto-flow' : 'repeat(3, 1fr)'
                }`,
                gap: toUnit(!isDesktop ? 16 : 32),
                transform: isMobile || isTablet ? 'rotateX(180deg)' : 'none'
              }}>
              {renderEllipses.map(({ id, alt, src }) => (
                <Image
                  key={id}
                  src={src}
                  alt={alt}
                  colorVariant='transparent'
                />
              ))}
            </Layout.Grid>
            <Layout.Grid
              styles={{
                gridArea: '1 / 1',
                alignSelf: 'center',
                justifySelf: 'end',
                height:
                  ((isDesktop || isLaptop) && '120%') ||
                  (isTablet && '60%') ||
                  '63%',
                width: isDesktop || isLaptop ? '82%' : '100%',
                columnGap: toUnit(
                  (isDesktop && 32) ||
                    (isLaptop && 15.4) ||
                    (isTablet && 16) ||
                    24
                ),
                grid:
                  ((isDesktop || isLaptop) && '11fr 10fr / 8fr 9fr') ||
                  '11fr 10fr / 5fr 4fr'
              }}>
              {renderImgs.map(({ id, alt, src }, idx) => (
                <Image
                  key={id}
                  colorVariant='transparent'
                  borderRadiusVariant={isDesktop ? 'alpha' : 'beta'}
                  alt={alt}
                  src={src}
                  quality={100}
                  fill
                  containerProps={{
                    styles: {
                      position: 'relative',
                      width: toUnit(
                        (isDesktop &&
                          (([0, 1].includes(idx) && 104) ||
                            ([2, 3].includes(idx) && 176) ||
                            136)) ||
                          (isLaptop &&
                            (([0, 1].includes(idx) && 49.19) ||
                              ([2, 3].includes(idx) && 83.24) ||
                              64.32)) ||
                          (isTablet &&
                            (([0, 1].includes(idx) && 41) ||
                              ([2, 3].includes(idx) && 69) ||
                              53)) ||
                          ([0, 1].includes(idx) && 73) ||
                          ([2, 3].includes(idx) && 123) ||
                          96
                      ),
                      aspectRatio: '1 / 1',
                      gridArea: ([0, 1].includes(idx) && `1 / 1`) || 'auto',
                      alignSelf:
                        ([0, 4].includes(idx) && 'start') ||
                        (idx === 2 && 'center') ||
                        'end',
                      justifySelf:
                        isMobile || isTablet
                          ? (idx === 3 && 'center') ||
                            ([0, 4].includes(idx) && 'start') ||
                            'end'
                          : ([1, 2, 3].includes(idx) && 'end') || 'start',
                      '& img': {
                        objectFit: 'contain'
                      }
                    }
                  }}
                />
              ))}
            </Layout.Grid>
          </Layout.Grid>
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default CorporateLead
