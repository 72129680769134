import { useAt, Section, Container, H2, toUnit, useMedia } from 'fractals'
import { Layout, slugify } from 'ui'
import {
  TCostProps,
  TestimonialsPsihologiyaIsHigherEducation,
  WhatIsIncludedPsihologiyaIsHigherEducation,
  PriceCardPsihologiyaIsHigherEducation
} from './fractals'

const CostPsihologiyaIsHigherEducation = ({
  heading,
  cost,
  colorVariant,
  costFormatHeadingDistance,
  costFormatHeadingMixed,
  costFormatSubHeadingMixed
}: TCostProps) => {
  const { isMobile, isTablet, isLaptop, isDesktop } = useMedia()

  return (
    <Section id={slugify(heading)} spacingVariant='beta' colorsVariant='beta'>
      <Container>
        <H2 variant='beta'>{heading}</H2>
        <Layout.Flex
          cols={(isMobile && 1) || ((isTablet || isLaptop) && 2) || 3}
          proportion={isTablet || isLaptop ? '1:1:2' : undefined}
          gap={toUnit(((isDesktop || isLaptop) && 32) || 16)}>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexGrow: 1
            }}>
            <PriceCardPsihologiyaIsHigherEducation
              colorVariant={{ color: '#FFFFFF' }}
              price={cost?.price}
              discount={cost?.discount}
              discountUntil={cost?.discountUntil || cost?.closestEnrollment}
              costFormatHeading={costFormatHeadingDistance}
            />
          </Layout.FlexItem>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexGrow: 1,
              marginBottom: toUnit((isMobile && 14) || 0)
            }}>
            <PriceCardPsihologiyaIsHigherEducation
              colorVariant={{ color: '#7D5EE4' }}
              price={cost?.price}
              discount={cost?.discount}
              discountUntil={cost?.discountUntil || cost?.closestEnrollment}
              costFormatHeading={costFormatHeadingMixed}
              costFormatSubHeading={costFormatSubHeadingMixed}
              additionalPrice={cost?.additionalСost?.data?.cost.price}
              additionalDiscount={cost?.additionalСost?.data?.cost.discount}
            />
          </Layout.FlexItem>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexGrow: 1,
              marginTop: toUnit(((isLaptop || isTablet) && 20) || 0)
            }}>
            <WhatIsIncludedPsihologiyaIsHigherEducation
              cost={cost?.price ? cost.price : 0}
              colorVariant={colorVariant}
              categoryLabel={cost?.categoryLabel}
              closestEnrollment={cost?.closestEnrollment}
              studyDurationMonths={cost?.studyDurationMonths}
              studyDurationYears={cost?.studyDurationYears}
              hasPopup={false}
            />
          </Layout.FlexItem>
        </Layout.Flex>
        <TestimonialsPsihologiyaIsHigherEducation />
      </Container>
    </Section>
  )
}

export default CostPsihologiyaIsHigherEducation
