import * as React from 'react'
import { Image } from 'fractals'
import telegram from './telegram.png'

const IconTelegram = () => {
  return (
    <Image
      id='telegram__icon'
      colorVariant='transparent'
      src={telegram.src || '/'}
      width={telegram.width}
      height={telegram.height}
      alt={telegram.blurDataURL || `telegram__icon`}
      containerProps={{
        styles: {
          '& img': {
            position: 'relative',
            objectFit: 'cover'
          }
        }
      }}
    />
  )
}

export default IconTelegram
