// TODO: ! figure out ENV

const DEV = process.env.NODE_ENV === 'development'
const TEST = process.env.NODE_ENV === 'test'
const PROD = process.env.NODE_ENV === 'production'

export const ENV = {
  dev: DEV,
  test: TEST,
  prod: PROD,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  googleTagManagerIdV4: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_V4,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  yandexMetrikaCounterId: process.env.YANDEX_METRIKA_COUNTER_ID,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  previewSecret: process.env.PREVIEW_SECRET,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpFrom: process.env.SMTP_FROM,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpTo: (PROD && process.env.SMTP_TO_PROD) || process.env.SMTP_TO_DEV,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpHost: process.env.SMTP_HOST,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpPort: process.env.SMTP_PORT,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpLogin: process.env.SMTP_LOGIN,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpDataLogin: process.env.SMTP_DATA_LOGIN,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpPassword: process.env.SMTP_PASSWORD,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpDataPassword: process.env.SMTP_DATA_PASSWORD,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  smtpDataFrom: process.env.SMTP_DATA_FROM,
  // TODO: ! remove the line and fix the error
  // eslint-disable-next-line
  yandexFeedGenerate: process.env.YANDEX_FEED_GENERATE,
  // eslint-disable-next-line
  fbPixelId: process.env.FACEBOOK_PIXEL_ID,
  // eslint-disable-next-line
  ymCounter: process.env.YM_COUNTER,
  // eslint-disable-next-line
  ymGoalId: process.env.NEXT_PUBLIC_YM_GOAL_ID,
  // eslint-disable-next-line
  verselEnv: process.env.NEXT_PUBLIC_VERCEL_ENV
} as const
