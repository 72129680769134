import { THEME } from 'fractals'

export const VARIANTS = {
  plus: 'plus',
  cross: 'cross',
  crossMini: 'crossMini'
} as const

export const COLOR_VARIANT_KEYS = {
  alpha: 'alpha',
  omega: 'omega',
  beta: 'beta',
  phi: 'phi'
} as const
