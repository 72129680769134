import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetMissionDataProps, TResMission } from './types'

export const getMissionData = async ({ context }: TGetMissionDataProps) => {
  try {
    const query = qs.stringify({
      fields: ['name' /* , 'slug', 'rank' */],
      populate: {
        whoSeeLectures: {
          fields: ['url' /* , 'width', 'height', 'alternativeText' */]
        },
        main: {
          fields: ['url' /* , 'width', 'height', 'alternativeText' */]
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResMission>(
      `${PATHS.back.root.index}${PATHS.back.api.missions.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cgetMissionData.tsx line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default getMissionData
