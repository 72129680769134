// TODO: remove this file when we have a better way to handle circular dependencies
// eslint-disable-next-line import/no-cycle
export { default as ProgramsPage } from './ProgramsPage'
export {
  getProgramsData,
  getProgramsDataFaculties,
  getBannerData,
  getProgramsQuestions,
  getProgramsVideo
} from './widgets'
export { getSeoData } from './fractals'
