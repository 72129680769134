import { multiMedia } from 'fractals'

export const whoSeeLectures = {
  container: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    zIndex: 3,
    borderRadius: '16px',
    boxSizing: 'border-box',
    padding: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...multiMedia({
      transform: 'translateY(16px)',
      gap: '8px',
      left: 'auto',
      right: '81px',
      laptop: { left: '24px', right: '24px' },
      tablet: {
        gap: '16px',
        right: '112px',
        transform: 'translateY(38px)',
        minWidth: '416px'
      },
      phone: {
        gap: '20px',
        right: 0,
        left: 0,
        minWidth: '343px',
        marginBottom: '-7rem'
      }
    })
  }
} as const
