import { StyledJSX } from 'ui'
import { FiltersIcon } from '../../atoms'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const FiltersButtonMobile = props => (
  <StyledJSX.Button
    styles={{
      width: '51px',
      height: '51px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '8px',
      background: '#F0F0F0',
      flexShrink: 0
    }}
    {...props}>
    <FiltersIcon color='#3A52EE' />
  </StyledJSX.Button>
)
