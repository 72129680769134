import styled from 'styled-components'

import { toUnit } from 'fractals'
import {
  StyledCirclesWrapper,
  StyledButtonCources,
  StyledPathButtonCources
} from '../../atoms'

type TStyledCardProps = {
  accentColor?: string
}

export const StyledCard = styled.div.attrs(
  ({ accentColor, ...props }: TStyledCardProps) => ({
    accentColor: accentColor || '#fb5449',
    ...props
  })
)`
  position: relative;
  width: ${toUnit(384)};
  min-height: ${toUnit(267)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: ${toUnit(32)};
  padding-bottom: ${toUnit(32)};
  padding-left: ${toUnit(32)};
  padding-right: ${toUnit(32)};
  border-radius: ${toUnit(16)};
  overflow: hidden;
  transition-duration: 200ms;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    ${({ accentColor }) => `${accentColor}00`} 36.33%,
    ${({ accentColor }) => `${accentColor}99`} 67.2%
  );

  ${StyledCirclesWrapper} {
    display: none;
  }

  @media (max-width: 480px) {
    width: auto;
    min-height: ${toUnit(211)};
    padding-top: ${toUnit(24)};
    padding-bottom: ${toUnit(24)};
    padding-left: ${toUnit(24)};
    padding-right: ${toUnit(24)};
  }

  @media (min-width: 481px) and (max-width: 768px) {
    width: ${toUnit(304)};
    min-height: ${toUnit(211)};
    padding-top: ${toUnit(24)};
    padding-bottom: ${toUnit(24)};
    padding-left: ${toUnit(24)};
    padding-right: ${toUnit(24)};
  }

  &:hover {
    background: linear-gradient(
        to right bottom,
        transparent 50%,
        ${({ accentColor }) => accentColor} 50%
      )
      left top;
    background-size: 200% 200%;
    background-position: 100% 100%;

    ${StyledCirclesWrapper} {
      display: flex;
    }

    ${StyledButtonCources} {
      background-color: white;
    }

    ${StyledPathButtonCources} {
      stroke: black;
    }
  }
`
