import { P, toUnit, Div, Span, useAt, useMedia } from 'fractals'
import { useRouter } from 'next/router'
import {
  insertWhiteSpacesAfterPrepositions,
  Layout,
  toNumberWithSpaces,
  spacesToNonBrakingSpaces,
  NON_BRAKING_SPACE
} from 'ui'
import Color from 'color'
import { TPriceCardProps } from './types'
import { calculatePrice } from './utils'
import { Discount } from '@/modules/program-page/fractals'

export const PriceCard = ({
  price,
  discount,
  colorVariant,
  discountUntil
}: TPriceCardProps) => {
  const { locale } = useRouter()

  const { isTablet, isLaptop, isDesktop } = useMedia()

  const { isCourse, isCollege, isHigherEducation, isMba } = useAt()

  const calculatedPrice = calculatePrice({
    price,
    discount,
    locale,
    semestersNumber: isMba ? 1 : 2
  })

  const paymentOptions = [
    ...(((isHigherEducation || isCollege) && [
      calculatedPrice.oneSemisterPayment
    ]) ||
      []),
    calculatedPrice.yearInstallmentPlan
  ] as const

  return (
    <Div
      styles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (isCourse && 'space-between') || undefined,
        flexGrow: 1,
        paddingTop: toUnit(((isDesktop || isLaptop) && 32) || 24),
        paddingRight: toUnit(((isDesktop || isLaptop) && 32) || 24),
        paddingBottom: toUnit(((isDesktop || isLaptop) && 32) || 24),
        paddingLeft: toUnit(((isDesktop || isLaptop) && 32) || 24),
        borderRadius: toUnit(32),
        backgroundColor: 'var(--tau)'
      }}>
      {isCourse && (
        <Discount
          colorVariant={colorVariant}
          discount={discount}
          discountUntil={discountUntil}
        />
      )}
      {paymentOptions.map((paymentOption, idx) => (
        <div
          key={`paymentOptions__paymentOption--${idx + 1}`}
          style={{
            position: 'relative',
            display: (isCourse && 'flex') || undefined,
            flexDirection: (isCourse && 'column') || undefined,
            ...((idx !== 0 && {
              marginTop: toUnit(32),
              paddingTop: toUnit(32)
            }) ||
              {})
          }}
          itemProp='offers'
          itemScope
          itemType='https://schema.org/Offer'>
          <meta itemProp='price' content={String(paymentOption.price)} />
          <meta itemProp='priceCurrency' content='RUB' />
          <link itemProp='availability' href='http://schema.org/InStock' />
          <Layout.Flex
            cols={((isDesktop || isLaptop || isTablet) && 2) || 1}
            proportion={
              ((isDesktop || isLaptop || isTablet) && '3:5') || undefined
            }
            styles={{
              alignItems: 'center',
              order: (isCourse && 2) || undefined
            }}
            gap={toUnit(16)}>
            <Layout.FlexItem
              styles={{
                order: ((isDesktop || isLaptop || isTablet) && 2) || 1
              }}>
              <P
                variant='nu'
                styles={{
                  textDecoration: 'line-through',
                  textAlign:
                    ((isDesktop || isLaptop || isTablet) && 'right') ||
                    undefined,
                  color:
                    (colorVariant?.color &&
                      Color(colorVariant.color).rgb().string()) ||
                    'var(--beta)'
                }}>
                {spacesToNonBrakingSpaces(
                  `${toNumberWithSpaces(paymentOption.regularPrice)} ${
                    paymentOption.currencySymbol
                  }`
                )}
              </P>
            </Layout.FlexItem>
            <Layout.FlexItem
              styles={{
                order: ((isDesktop || isLaptop || isTablet) && 1) || 2
              }}>
              <P variant='mu'>
                {spacesToNonBrakingSpaces(
                  toNumberWithSpaces(paymentOption.price)
                )}
                {NON_BRAKING_SPACE}
                <Span
                  styles={{
                    display: 'inline-block',
                    fontSize: toUnit(((isDesktop || isLaptop) && 48) || 32)
                  }}>
                  {paymentOption.currencySymbol}
                </Span>
              </P>
            </Layout.FlexItem>
          </Layout.Flex>
          <P
            variant='alpha'
            styles={{
              marginTop: toUnit((!isCourse && 16) || 0),
              marginBottom: toUnit((isCourse && 16) || 0),
              maxWidth: (isDesktop && '23ch') || undefined,
              order: (isCourse && 1) || undefined
            }}>
            {insertWhiteSpacesAfterPrepositions(paymentOption.name)}
          </P>
        </div>
      ))}
    </Div>
  )
}

export default PriceCard
