import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: 'Study with MITM is',
  [LOCALES.ru]:
    'Обучение в «Московском институте технологий и управления» — это',
  [LOCALES.uz]: 'MITM bilan o’qish',
  [LOCALES.kk]: '«Мәскеу технология және менеджмент институты»-да оқу'
}

export const DESCRIPTION_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Дистанционное обучение. Можете обучаться из любой точки мира',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
export const DESCRIPTION_2 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Возможность вернуть 13% от стоимости обучения',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
export const DESCRIPTION_3 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Перевод из других ВУЗов без потери курса',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
export const DESCRIPTION_4 = {
  [LOCALES.en]: '',
  [LOCALES.ru]:
    'Образовательные программы, адаптированные под требования работодателей',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
export const DESCRIPTION_5 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Поддержка куратора 24/7',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
export const DESCRIPTION_6 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Программа трудоустройства после окончания обучения',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
