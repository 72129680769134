import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, media } from 'fractals'
import { TInfoTagProps } from './types'
// import { VARIANTS_KEYS } from './constants'

const InfoTag = ({ colorVariant, ...props }: TInfoTagProps) => {
  // const stylesVariants = {
  //   [VARIANTS_KEYS.alpha]: {}
  // } as const

  const styles = {
    display: 'inline-block',
    marginBottom: toUnit(16),
    paddingTop: toUnit(8),
    paddingRight: toUnit(16),
    paddingBottom: toUnit(8),
    paddingLeft: toUnit(16),
    borderRadius: toUnit(55),
    borderWidth: toUnit(1),
    borderStyle: 'solid',
    borderColor: `var(--${colorVariant})`,
    ...media('fontSize', toUnit(12), {
      desktop: toUnit(14)
    }),
    fontWeight: 500,
    lineHeight: '120%',
    color: `var(--${colorVariant})`,
    // ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Span {...props} styles={styles} />
}

export default InfoTag
