import { createContext, PropsWithChildren, useMemo } from 'react'
import { defaultStyleConfig } from '../../constants'
import {
  TypeDesktopStylesConfig,
  TypeMobileStylesConfig,
  TypeModalOptions
} from '../../types'

type TypeModalStylesCtx = {
  desktopStyles: TypeDesktopStylesConfig
  mobileStyles: TypeMobileStylesConfig
  options: Partial<TypeModalOptions>
}

type TypeModalStylesProvider = PropsWithChildren<
  Partial<
    Omit<TypeModalStylesCtx, 'desktopStyles' | 'mobileStyles'> & {
      desktopStyles: Partial<TypeDesktopStylesConfig>
      mobileStyles: Partial<TypeMobileStylesConfig>
    }
  >
>

const ModalStylesCtx = createContext<TypeModalStylesCtx>(defaultStyleConfig)

const ModalStylesProvider = ({
  children,
  desktopStyles,
  mobileStyles,
  options
}: TypeModalStylesProvider) => {
  const styles = useMemo(
    () => ({
      desktopStyles: {
        ...defaultStyleConfig.desktopStyles,
        ...(desktopStyles || {})
      },
      mobileStyles: {
        ...defaultStyleConfig.mobileStyles,
        ...(mobileStyles || {})
      },
      options: {
        ...defaultStyleConfig.options,
        ...(options || {})
      }
    }),
    [desktopStyles, mobileStyles, options]
  )
  return (
    <ModalStylesCtx.Provider value={styles}>{children}</ModalStylesCtx.Provider>
  )
}

export { ModalStylesCtx, ModalStylesProvider }
