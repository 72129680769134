import { id } from 'ui/keys-props'
import { getVideoData } from './api'

export enum VideoVariants {
  home = 'home',
  programs = 'programs'
}

export type TVideoProps = {
  [id]: string
  videos: Awaited<Promise<ReturnType<typeof getVideoData>>>
  variant?: VideoVariants
}
