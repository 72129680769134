import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TCategory, TGetQuestionsDataProps, TResCategories } from '../types'
import { TResFaculties, TResFacultyData } from '../../getFacultiesData/types'

const fields = ['name', 'answer', 'rank']

export const fetchCategoryQuestions = async ({
  context
}: TGetQuestionsDataProps) => {
  try {
    const { currentCategorySlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    const query = qs.stringify({
      fields: ['name'],
      populate: {
        questions: {
          fields
        }
      },
      filters: {
        slug: {
          $eq: currentCategorySlug
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResCategories>(
      `${PATHS.back.root.index}${PATHS.back.api.categories.index}?${query}`
    )

    return res?.data?.at(0) || null
  } catch (e) {
    console.log('%cfetchQuestions.ts line:9 e', 'color: #007acc;', e)
    return null
  }
}

export default fetchCategoryQuestions
