import { sortBasedOnKey } from 'fractals'
import { fetchCategories } from './fetchCategories'

export const normalizeCategoriesData = ({
  categoriesData
}: {
  categoriesData: Awaited<ReturnType<typeof fetchCategories>>
}) => {
  return { categories: categoriesData }
}

export default normalizeCategoriesData
