import { LOCALES } from 'fractals'

export const IDS = {
  aboutTraining: 'about-training',
  programPage: 'program-page',
  formLeastProgramContents: 'form-least-program-contents',
  formFAQ: 'form-faq'
} as const

export const H1 = {
  [LOCALES.en]: 'Program',
  [LOCALES.ru]: 'Программа',
  [LOCALES.uz]: 'Dastur',
  [LOCALES.kk]: 'Бағдарлама'
} as const

export const FOR_WHOM_HEADING = {
  [LOCALES.en]: 'Whom the program is for?',
  [LOCALES.ru]: 'Для кого эта программа?',
  [LOCALES.uz]: 'Bu dastur kim uchun?',
  [LOCALES.kk]: 'Бұл бағдарлама қандай адамға?'
} as const

export const LEAST_PROGRAM_CONTENTS_HEADING = {
  [LOCALES.en]: 'Short contents',
  [LOCALES.ru]: 'Краткая программа курса',
  [LOCALES.uz]: 'Qisqa tarkib',
  [LOCALES.kk]: 'Қысқа мазмұны'
} as const

export const FULL_PROGRAM_CONTENTS_HEADING = {
  [LOCALES.en]: 'Training program',
  [LOCALES.ru]: 'Программа обучения',
  [LOCALES.uz]: 'Trening dasturi',
  [LOCALES.kk]: 'Оқыту бағдарламасы'
} as const

export const COST_HEADING = {
  [LOCALES.en]: 'Cost of education',
  [LOCALES.ru]: 'Стоимость обучения',
  [LOCALES.uz]: 'Ta’lim narxi',
  [LOCALES.kk]: 'Оқу құны'
} as const

export const COST_FORMAT_HEADING_DISTANCE = {
  [LOCALES.en]: 'Remote',
  [LOCALES.uz]: 'Masofadan',
  [LOCALES.kk]: 'Қашықтан',
  [LOCALES.ru]: 'Дистанционный'
}

export const COST_FORMAT_HEADING_MIXED = {
  [LOCALES.en]: 'Mixed format',
  [LOCALES.uz]: 'Aralash format',
  [LOCALES.kk]: 'Аралас формат',
  [LOCALES.ru]: 'Cмешанный формат'
}

export const COST_FORMAT_SUBHEADING_MIXED = {
  [LOCALES.en]: '(weekend group)',
  [LOCALES.uz]: '(hafta oxiri guruhi)',
  [LOCALES.kk]: '(демалыс күндері тобы)',
  [LOCALES.ru]: '(группа выходного дня)'
}

export const OUR_PROGRAMS = [
  'graficheskij-dizajn-1',
  'vychislitelnye-mashiny-kompleksy-sistemy-i-seti',
  'geoinformaczionnye-tehnologii-i-3-d-modelirovanie',
  'inzhiniring-i-razrabotka-programmnogo-obespecheniya',
  'intellektualnye-informaczionnye-sistemy-i-tehnologii',
  'informaczionnaya-bezopasnost',
  'informaczionno-kommunikaczionnye-tehnologii-i-analitika-dannyh',
  'informaczionnoe-obespechenie-avtomatizirovannyh-sistem',
  'informaczionnoe-obespechenie-biznes-proczessov',
  'kompyuternaya-grafika',
  'prikladnaya-informatika-v-ekonomike',
  'prikladnaya-informatika-i-matematika',
  'razrabotka-i-dizajn-veb-prilozhenij',
  'razrabotka-kompyuternyh-igr',
  'razrabotka-multimedijnyh-prilozhenij',
  'czifrovaya-transformacziya-informaczionnyh-sistem',
  'bim-tehnologii',
  'internet-marketing-1',
  'marketing-1',
  'marketing-v-kommercheskoj-deyatelnosti',
  'marketing-i-upravlenie-brendami',
  'antikrizisnyj-menedzhment',
  'buhgalterskij-uchet-i-finansovyj-menedzhment-1',
  'kadrovyj-menedzhment-1',
  'menedzhment-v-zdravoohranenii',
  'menedzhment-v-mashinostroenii',
  'menedzhment-v-neftegazovom-komplekse',
  'menedzhment-v-obrazovanii',
  'menedzhment-v-smi-i-reklame',
  'menedzhment-v-stroitelstve-1',
  'menedzhment-v-turizme',
  'menedzhment-v-energetike',
  'menedzhment-gostinichnyh-i-restorannyh-predpriyatij',
  'organizaczionnyj-menedzhment',
  'predprinimatelstvo',
  'proizvodstvennyj-menedzhment',
  'sportivnyj-menedzhment-1',
  'strategicheskij-menedzhment',
  'upravlenie-bankovskoj-i-informaczionnoj-bezopasnostyu',
  'upravlenie-vneshneekonomicheskoj-deyatelnostyu',
  'upravlenie-gosudarstvennym-i-municzipalnym-sektorom',
  'upravlenie-zh-kh',
  'upravlenie-malym-biznesom',
  'upravlenie-na-transporte',
  'upravlenie-personalom',
  'upravlenie-prodazhami',
  'upravlenie-proektami-1',
  'upravlenie-tehnologicheskimi-innovacziyami',
  'upravlenie-chelovecheskimi-resursami',
  'finansovyj-menedzhment',
  'klinicheskaya-psihologiya-1',
  'klinicheskie-osnovy-psihologicheskogo-zdorovya-cheloveka',
  'korporativnaya-psihologiya-1',
  'korrekczionnaya-psihologiya-s-osnovami-logopedii',
  'organizaczionnaya-psihologiya',
  'psihoanaliz',
  'psiholog-pedagog-1',
  'psihologicheskoe-konsultirovanie-1',
  'psihologiya-1',
  'psihologiya-semejnyh-otnoshenij',
  'psihologiya-upravleniya',
  'soczialnaya-psihologiya',
  'yuridicheskaya-psihologiya',
  'buhgalterskij-uchet-analiz-i-audit',
  'vnutrennij-audit',
  'dengi-banki-finansovye-rynki-1',
  'kommercziya',
  'korporativnye-finansy',
  'mezhdunarodnye-finansy',
  'mirovaya-ekonomika',
  'nalogi-i-nalogooblozhenie-1',
  'oczenka-biznesa-i-riskov',
  'regionalnaya-ekonomika',
  'strahovanie',
  'upravlencheskij-i-finansovyj-uchet',
  'uchet-i-finansovyj-analiz',
  'finansovyj-kontrol-i-ekonomicheskij-analiz',
  'finansy-i-kredit',
  'czifrovaya-ekonomika',
  'ekonomika-v-soczialnoj-sfere',
  'ekonomika-i-logistika-predpriyatiya',
  'ekonomika-i-statistika',
  'ekonomika-i-upravlenie-v-uchrezhdeniyah-zdravoohraneniya',
  'ekonomika-i-upravlenie-na-predpriyatii',
  'ekonomika-i-ekonomicheskaya-bezopasnost-predpriyatiya',
  'ekonomika-predpriyatij-i-organizaczij',
  'proektnyj-menedzhment',
  'graficheskij-dizajner',
  'dizajner-interera',
  'landshaftnyj-dizajn-i-sadovo-parkovoe-stroitelstvo',
  'direktor-po-marketingu',
  'internet-marketing',
  'antikrizisnoe-upravlenie-1',
  'assistent-rukovoditelya',
  'biznes-analitika',
  'gosudarstvennoe-i-municzipalnoe-upravlenie',
  'dokumentovedenie-i-arhivovedenie',
  'logisticheskij-menedzhment',
  'menedzhment-v-neftegazovom-komplekse-1',
  'menedzhment-v-organizaczii',
  'menedzhment-organizaczii-v-zdravoohranenii',
  'menedzher-it-proektov',
  'upravlenie-gostinichnymi-i-restorannymi-predpriyatiyami',
  'upravlenie-kachestvom',
  'upravlenie-personalom-2',
  'upravlenie-proektami-3',
  'finansovyj-menedzhment-1',
  'hr-menedzher-s-nulya',
  'product-menedzher',
  'menedzher-prodazham',
  'rukovoditel-otdela-prodazh',
  'art-terapiya',
  'geshtalt-terapiya',
  'detskaya-nejropsihologiya',
  'detskaya-psihologiya',
  'zoopsiholog',
  'klinicheskaya-psihologiya-2',
  'kognitivno-povedencheskaya-psihoterapiya',
  'konfliktologiya',
  'kouching-individualnoe-psihologicheskoe-konsultirovanie',
  'kouching-psihologicheskoe-biznes-konsultirovanie',
  'krizisnyj-psiholog-1',
  'logoped-defektolog',
  'nejropsihologiya',
  'pedagog-psiholog-1',
  'prakticheskaya-psihologiya-s-dop-kvalifikacziej-pedagog-psiholog',
  'prakticheskaya-psihologiya-s-dop-kvalifikacziej-psiholog-psihoterapevt',
  'prakticheskaya-psihologiya',
  'proforientaczionnaya-rabota-v-obrazovatelnom-uchrezhdenii',
  'psihoanaliz-i-psihologicheskaya-psihoterapiya',
  'psiholog-konsultant',
  'nutricziolog',
  'psihologiya',
  'psihosomatika-i-telesno-orientirovannaya-terapiya',
  'seksologiya-v-psihologicheskom-konsultirovanii',
  'semejnaya-psihologiya',
  'soczialnaya-psihologiya-1',
  'suggestivnaya-psihologiya-gipnoz-v-psihologicheskom-konsultirovanii',
  'proektirovanie-zdanij-i-sooruzhenij',
  'advokatura',
  'arbitrazhnoe-antikrizisnoe-upravlenie',
  'grazhdanskoe-pravo',
  'dogovornoe-pravo',
  'mediacziya',
  'mediczinskoe-pravo',
  'patentovedenie',
  'pravovedenie',
  'pravovoe-soprovozhdenie-soczialnogo-obespecheniya-grazhdan-rf',
  'rieltor',
  'sdelki-s-nedvizhimostyu',
  'trudovoe-pravo',
  'ugolovnoe-pravo',
  'yuridicheskie-aspekty-upravleniya-v-sfere-zh-kh-1',
  'yurisprudencziya'
]
