import { LOCALES } from 'fractals'

export const LABEL = {
  [LOCALES.en]: 'Frequently asked questions',
  [LOCALES.ru]: 'Часто задаваемые вопросы',
  [LOCALES.uz]: 'Tezkor savollar',
  [LOCALES.kk]: 'Ең көп сұрақталған сұрақтар'
} as const

export const TITLE = {
  [LOCALES.en]: 'We are often asked',
  [LOCALES.ru]: 'Нас часто спрашивают',
  [LOCALES.uz]: 'Biz tez-tez so‘ray',
  [LOCALES.kk]: 'Біз қазірдің кезеңінде көп сұрақталады'
} as const

export const FORM_NAME = {
  [LOCALES.en]: 'Have questions, need consultation',
  [LOCALES.ru]: 'Есть вопрсы, требуется консультация',
  [LOCALES.uz]: 'Savollar bor, konsultatsiya kerak',
  [LOCALES.kk]: 'Сұрақтар бар, көмек көрсету керек'
} as const

export const FORM_HEADING = {
  [LOCALES.en]:
    'Have questions? Get a consultation from the admissions committee',
  [LOCALES.ru]: 'Остались вопросы? Получите консультацию приемной комиссии',
  [LOCALES.uz]: 'Savollar bormi? Qabul komissiyasi konsultatsiyasini oling',
  [LOCALES.kk]: 'Сұрақтар бар ма? Кабул комиссиясы көмек көрсетіледі'
} as const

export const QUESTION_1 = {
  [LOCALES.en]: 'Do I need to come to the university?',
  [LOCALES.ru]: 'Необходимо ли приезжать в ВУЗ?',
  [LOCALES.uz]: 'Universitetga kelish kerakmi?',
  [LOCALES.kk]: 'Университетке келу керек пе?'
} as const

export const ANSWER_1 = {
  [LOCALES.en]:
    'Personal presence is not required for training. All work of students from the submission of documents for admission to the preparation and defense of the diploma is carried out remotely',
  [LOCALES.ru]:
    'Для обучения личного присутствия не требуется. Вся работа студентов от подачи документов для поступления до подготовки и защиты диплома проводится дистанционно',
  [LOCALES.uz]:
    "Ta'lim uchun shaxsiy tashrif kerak emas. Talabalarning barcha ishi qabul qilish uchun hujjatlarni taqdim qilishdan diplomani tayyorlash va himoya qilishgacha masofadan amalga oshiriladi",
  [LOCALES.kk]:
    'Білім алу үшін шахси кіру керек емес. Оқушылардың барлығын қабылдау үшін құжаттарды жіберуден дипломды жасауға дейін және оны қорғауға дейін қашықтырылған жұмыс аяқталады'
} as const

export const QUESTION_2 = {
  [LOCALES.en]: 'Can foreign citizens apply?',
  [LOCALES.ru]: 'Могут ли поступить иностранные граждане?',
  [LOCALES.uz]: 'Xorijiy fuqarolar qabul qilinadi?',
  [LOCALES.kk]: 'Хорижий фуқаролар қабыл қилинады?'
} as const

export const ANSWER_2 = {
  [LOCALES.en]:
    'Yes, we accept foreign citizens in a general manner. You will need to provide certified documents about your education',
  [LOCALES.ru]:
    'Да, принимаем иностранных граждан в общем порядке. Нужно будет предоставить заверенные документы о вашем образовании',
  [LOCALES.uz]:
    "Ha, chet el fuqarolarini umumiy tartib asosida qabul qilamiz. Ta'lim haqida tasdiqlangan hujjatlarni taqdim etishingiz kerak",
  [LOCALES.kk]:
    'Иә, шетел азаматтарын жалпы тәртіп бойынша қабылдаймыз. Сізге біліміңіз туралы расталған құжаттарды ұсыну қажет'
} as const

export const QUESTION_3 = {
  [LOCALES.en]: 'Can I pay for training from another country?',
  [LOCALES.ru]: 'Можно ли оплатить обучение из другой страны?',
  [LOCALES.uz]: "Boshqa davlatdan ta'lim to'lash mumkinmi?",
  [LOCALES.kk]: 'Басқа елден білім алу мүмкін пе?'
} as const

export const ANSWER_3 = {
  [LOCALES.en]:
    'Yes, you can pay for our course from anywhere in the world. Installment payment is available only in Russia, but for other regions we will try to find a comfortable way to pay',
  [LOCALES.ru]:
    'Да, вы можете оплатить наш курс из любой точки мира. Рассрочка доступна только в России, но и для других регионов мы постараемся подобрать комфортный способ оплаты',
  [LOCALES.uz]:
    "Ha, siz dunyodagi har qanday joydan bizning kursimizni to'lashingiz mumkin. Oylik to'lov faqat Rossiyada mavjud, ammo boshqa hududlar uchun biz komfortli to'lov usulini topishga harakat qilamiz",
  [LOCALES.kk]:
    'Иә, сіз дүниедегі әрқайсыы жерден біздің курсты төлемдей аласыз. Айлық төлем тек Ресейде қол жеткізіледі, бірақ басқа аймақтар үшін біз қанағаттандырушы төлем әдісін табуға қызмет етеміз'
} as const

export const QUESTION_4 = {
  [LOCALES.en]: 'Will I have a mentor and technical support?',
  [LOCALES.ru]: 'Будет ли у меня наставник и техническая поддержка?',
  [LOCALES.uz]: "Menda murabbiy va texnik yordam bo'ladimi?",
  [LOCALES.kk]: 'Менің тәлімгерім және техникалық қолдауым болады ма?'
} as const

export const ANSWER_4 = {
  [LOCALES.en]:
    'During the entire training, you will be supported by a curator. He will help with any questions during the training',
  [LOCALES.ru]:
    'На протяжении всего обучения вас будет поддерживать куратор. Он поможет с любыми вопросами в процессе обучения',
  [LOCALES.uz]:
    "Ta'lim davomida sizni kursator tomonidan qo'llab-quvvatlanasiz. U ta'lim davomida har qanday savollarga javob beradi",
  [LOCALES.kk]:
    'Тағылым кезеңінде сізді курсатор таңдаушы туралы жақындау қылады. Ол тағылым кезеңінде әрбір сұраққа жауап береді'
} as const
