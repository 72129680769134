import { useEffect, useState } from 'react'
import { useRouter } from 'next/router' // Подключаем useRouter

// Функция для получения значения куки по имени
const getCookieValue = (name: string): string | undefined => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`))
  return matches && matches[1] ? decodeURIComponent(matches[1]) : undefined
}

// Основная функция-хук для проверки условий
export const useEdpartnersLead = () => {
  const [isEdpartnersLead, setIsEdpartnersLead] = useState(false)
  const router = useRouter() // Подключаем router для работы с query

  useEffect(() => {
    // Получаем значение utm_source из query
    const utmSourceFromUrl = router.query.utm_source as string

    // Получение значений куки
    const utmSourceFromCookie = getCookieValue('utm_source')

    // Проверка условий
    if (
      utmSourceFromUrl === 'edpartners' ||
      utmSourceFromCookie === 'edpartners'
    ) {
      setIsEdpartnersLead(true)
    } else {
      setIsEdpartnersLead(false)
    }
  }, [router.query]) // Отслеживаем изменения query

  return isEdpartnersLead
}

export default useEdpartnersLead
