import React from 'react'
import { CSSObject } from 'styled-components'
import { Button, IconChat } from '@/fractals/components'

type TAskButtonProps = {
  onClick: () => void
  // eslint-disable-next-line react/require-default-props
} & { styles?: CSSObject }

export const AskButton = React.forwardRef<HTMLButtonElement, TAskButtonProps>(
  ({
    onClick,
    styles = {
      bottom: '25px'
    }
  }) => {
    return (
      <Button
        onClick={onClick}
        aria-label='ask-button'
        colorsVariant='ask'
        styles={{
          ...styles,
          position: 'fixed',
          right: '25px',
          backgroundColor: '#27c500',
          borderRadius: '50%',
          zIndex: 10,
          width: '58px',
          height: '58px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color .3s ease-out',
          color: '#fff',
          cursor: 'pointer',
          border: 0,
          boxSizing: 'border-box',
          padding: 0
        }}>
        <IconChat
          styles={{
            width: '100%'
          }}
          colorsVariant='alpha'
        />
      </Button>
    )
  }
)

export default AskButton
