import { ReactNode, cloneElement } from 'react'

export const repeatJSX = (
  count: number,
  item:
    | JSX.Element
    | JSX.Element[]
    | ((v: unknown, k: number) => JSX.Element | JSX.Element[])
): ReactNode[] =>
  Array.from({ length: count }, (_, index) =>
    typeof item === 'function'
      ? cloneElement(item(undefined, index) as JSX.Element, {
          key: index
        })
      : cloneElement(item as JSX.Element, { key: index })
  )
