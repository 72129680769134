import { LOCALES } from 'fractals'

export const LABEL = {
  [LOCALES.en]:
    'From the moment you enroll in the course until you receive your diploma',
  [LOCALES.ru]: 'С момента поступления на курс до получения диплома',
  [LOCALES.uz]:
    "Kursga kirganingizdan boshlab diplomni qo'lga kiritguningizcha",
  [LOCALES.kk]: 'Курсқа жазылған сәттен бастап дипломды алғанға дейін'
} as const

export const TITLE = {
  [LOCALES.en]: 'Training order',
  [LOCALES.ru]: 'Порядок обучения',
  [LOCALES.uz]: 'Trening tartibi',
  [LOCALES.kk]: 'Жаттығу тәртібі'
} as const

export const STEP_TITLE_1 = {
  [LOCALES.en]: 'Admission remotely',
  [LOCALES.ru]: 'Поступление дистанционно',
  [LOCALES.uz]: 'Masofadan qabul qilish',
  [LOCALES.kk]: 'Қашықтықтан қабылдау'
} as const

export const STEP_DESCRIPTION_1 = {
  [LOCALES.en]:
    'Distance learning in higher education courses and programs involves submitting documents remotely. If you wish, you can come to the office.',
  [LOCALES.ru]:
    'Дистанционная форма обучения на курсах и программах высшего образования предусматривает подачу документов удаленно. При желании можно приехать в офис.',
  [LOCALES.uz]:
    'Oliy taʼlim kurslari va dasturlarida masofaviy taʼlim hujjatlarni masofadan turib topshirishni oʻz ichiga oladi. Agar xohlasangiz, ofisga kelishingiz mumkin.',
  [LOCALES.kk]:
    'Жоғары оқу орындарының курстары мен бағдарламаларында қашықтықтан оқыту құжаттарды қашықтан тапсыруды қамтиды. Қаласаңыз, кеңсеге келуге болады.'
} as const

export const STEP_TITLE_2 = {
  [LOCALES.en]: 'Online classes',
  [LOCALES.ru]: 'Занятия в онлайн-формате',
  [LOCALES.uz]: 'Onlayn darslar',
  [LOCALES.kk]: 'Онлайн сабақтар'
} as const

export const STEP_DESCRIPTION_2 = {
  [LOCALES.en]:
    'Webinars are held on the university platform. If students were unable to attend lectures, they watch recordings of classes, which are saved in their personal account until the end of their studies.',
  [LOCALES.ru]:
    'Вебинары проходят на платформе университета. Если студенты не смогли присутствовать на лекциях, они смотрят записи занятий, которые сохраняются в личном кабинете до конца обучения.',
  [LOCALES.uz]:
    "Vebinarlar universitet platformasida o'tkaziladi. Agar talabalar ma'ruzalarda qatnasha olmasalar, ular o'qish tugaguniga qadar shaxsiy kabinetida saqlanadigan dars yozuvlarini tomosha qiladilar.",
  [LOCALES.kk]:
    'Вебинарлар университет платформасында өткізіледі. Студенттер дәрістерге қатыса алмаған жағдайда, олар оқуының соңына дейін жеке кабинетінде сақталатын сабақтардың жазбаларын көреді.'
} as const

export const STEP_TITLE_3 = {
  [LOCALES.en]: 'Practice',
  [LOCALES.ru]: 'Практика',
  [LOCALES.uz]: 'Amaliyot',
  [LOCALES.kk]: 'Жаттығу'
} as const

export const STEP_DESCRIPTION_3 = {
  [LOCALES.en]:
    'Students complete practical assignments and submit their projects for testing via the distance learning system.',
  [LOCALES.ru]:
    'Студенты выполняют практические задания и отправляют свои проекты на проверку через систему дистанционного обучения.',
  [LOCALES.uz]:
    'Talabalar amaliy topshiriqlarni bajarib, o‘z loyihalarini masofaviy ta’lim tizimi orqali sinovdan o‘tkazish uchun taqdim etadilar.',
  [LOCALES.kk]:
    'Қашықтықтан оқыту жүйесі арқылы студенттер практикалық тапсырмаларды орындап, жобаларын тестілеуге жібереді.'
} as const

export const STEP_TITLE_4 = {
  [LOCALES.en]: 'Exams',
  [LOCALES.ru]: 'Сдача экзаменов',
  [LOCALES.uz]: 'Imtihonlar',
  [LOCALES.kk]: 'Емтихандар'
} as const

export const STEP_DESCRIPTION_4 = {
  [LOCALES.en]:
    'There is no need to come to the institute. Students take exams online.',
  [LOCALES.ru]:
    'Приезжать в институт нет необходимости. Студенты сдают экзамены в режиме онлайн.',
  [LOCALES.uz]:
    'Institutga kelish shart emas. Talabalar onlayn imtihon topshirishadi.',
  [LOCALES.kk]:
    'Институтқа келудің қажеті жоқ. Студенттер емтихандарды онлайн тапсырады.'
} as const

export const STEP_TITLE_5 = {
  [LOCALES.en]: 'Graduate work',
  [LOCALES.ru]: 'Дипломная работа',
  [LOCALES.uz]: 'Diplom ishi',
  [LOCALES.kk]: 'Дипломдық жұмыс'
} as const

export const STEP_DESCRIPTION_5 = {
  [LOCALES.en]:
    'At the end of their studies, students write and defend their final thesis in consultation with their supervisor.',
  [LOCALES.ru]:
    'В конце обучения студенты пишут и защищают выпускную работу при консультации научного руководителя.',
  [LOCALES.uz]:
    'O‘qish oxirida talabalar ilmiy rahbar bilan maslahatlashgan holda bitiruv malakaviy ishini yozadilar va himoya qiladilar.',
  [LOCALES.kk]:
    'Студенттер оқудың соңында ғылыми жетекшімен кеңесіп, дипломдық жұмысты жазып, қорғайды.'
} as const

export const STEP_TITLE_6 = {
  [LOCALES.en]: 'Diploma defense',
  [LOCALES.ru]: 'Защита диплома',
  [LOCALES.uz]: 'Diplomni himoya qilish',
  [LOCALES.kk]: 'Дипломдық жұмысты қорғау'
} as const

export const STEP_DESCRIPTION_6 = {
  [LOCALES.en]:
    'The final qualifying work is defended in the Institute. If desired, you can pass it remotely',
  [LOCALES.ru]:
    'Итоговая аттестация проходит на базе университета МИТМ. При желании можно пройти удаленно.',
  [LOCALES.uz]:
    'Tugat ilmiy rahbarining ko’rsatmasi bilan diplom ishini yozadi',
  [LOCALES.kk]:
    'Дипломдық жұмысты қорғау Институтта жүргізіледі. Келесі желісе, сіз оны қашықтыра аласыз'
} as const

export const ADDITIONAL_INFO = {
  [LOCALES.ru]:
    'Вы можете самостоятельно распределять время обучения под ваш образ жизни и учиться с комфортом. В ходе программы также предусмотрены учебные блоки в записи, которые будет легко встроить в привычный ритм дня',
  [LOCALES.en]:
    'You can independently distribute the time of study to your lifestyle and study comfortably. During the program, there are also recorded study blocks that will be easy to integrate into the usual rhythm of the day',
  [LOCALES.uz]:
    'Siz o’z hayot tarziga mos vaqtni o’rganish va qulay o’rganish imkoniyatiga ega bo’lasiz. Darslar davomida, kunduzgi odatiy rezhimni o’zlashtirish uchun juda qulay bo’lishi mumkin',
  [LOCALES.kk]:
    'Сіз оқу уақытын жеке жағдайға қолдануға болады және қызықты оқуға болады. Бұл бағдарламада оқу блогын күндік ритміңізге қосуға қатысады'
} as const
