import { LOCALES } from 'fractals'

export const ABOUT = {
  [LOCALES.en]: 'About',
  [LOCALES.ru]: 'О профессии',
  [LOCALES.uz]: 'Mutaxassislik haqida',
  [LOCALES.kk]: 'Мутахассислик туралы'
} as const

export const PROGRAM = {
  [LOCALES.en]: 'Program',
  [LOCALES.ru]: 'Программа',
  [LOCALES.uz]: 'Dastur',
  [LOCALES.kk]: 'Бағдарлама'
} as const

export const COST = {
  [LOCALES.en]: 'Cost',
  [LOCALES.ru]: 'Стоимость',
  [LOCALES.uz]: 'Narx',
  [LOCALES.kk]: 'Құны'
} as const

export const DIPLOMA = {
  [LOCALES.en]: 'Diploma',
  [LOCALES.ru]: 'Диплом',
  [LOCALES.uz]: 'Diplom',
  [LOCALES.kk]: 'Диплом'
} as const
