import React from 'react'
import { Div, IconHamburger, Span } from '@/fractals/components'

export const BurgerMenu = ({ text }: { text: string }) => {
  return (
    <Div
      styles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}>
      <IconHamburger colorVariant={{ color: 'black' }} />
      {text && (
        <Span
          styles={{
            marginLeft: '8px',
            marginRight: '18px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '120%'
          }}>
          {text}
        </Span>
      )}
    </Div>
  )
}

export default BurgerMenu
