import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import {
  TGetHeaderDataProps,
  TResProgramImages
} from '../../getHeaderData/types'

export const fetchProgramImages = async ({ context }: TGetHeaderDataProps) => {
  try {
    const query = qs.stringify(
      {
        fields: ['name', 'slug'],
        populate: {
          faculty: {
            fields: ['name', 'slug'],
            populate: {
              image: {
                fields: ['url', 'width', 'height', 'alternativeText']
              }
            }
          }
        },
        locale: context.locale
      },
      {
        encodeValuesOnly: true // prettify URL
      }
    )

    const res = await fetcher<TResProgramImages>(
      `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cfetchProgramImages.ts line:12 e', 'color: #007acc;', e)
  }
  return null
}

export default fetchProgramImages
