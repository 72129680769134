import { TGetProgramsDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getProgramsData = async ({ context }: TGetProgramsDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getProgramsData
