import {
  Section,
  Container,
  H2,
  Div,
  IconPlay,
  PATHS,
  useMedia,
  Image
} from 'fractals'
import ReactPlayer from 'react-player/lazy'
import { Fragment, useMemo } from 'react'
import { TVideoProps } from './fractals'
import { AuthorBadge } from '@/modules/journal-page/widgets/Journal/fractals/components/molecules'
import { variants as styleVariants } from './styles'
import { VideoVariants } from './fractals/types'

const Video = ({ id, videos, variant = VideoVariants.home }: TVideoProps) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()
  const isDefaultVariant = variant === VideoVariants.home

  const onePageVideos = useMemo(
    () => videos?.find(({ name }) => name === variant),
    [videos, variant]
  )

  const styles = styleVariants[variant]

  const size = {
    width:
      (isDesktop && '280px') ||
      (isLaptop && '176px') ||
      (isTablet && '190px') ||
      '229px',
    height:
      (isDesktop && '440px') ||
      (isLaptop && '276px') ||
      (isTablet && '298px') ||
      '360px'
  }

  return (
    <Section
      id={id}
      colorsVariant={isDefaultVariant ? 'beta' : 'delta'}
      spacingVariant='beta'>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        {onePageVideos?.reviews?.title && (
          <H2 variant={isDefaultVariant ? 'reviewTitle' : 'journalTitle'}>
            {onePageVideos?.reviews?.title}
          </H2>
        )}

        {onePageVideos?.reviews?.authors?.length && (
          <Div styles={styles.author.reviews}>
            {onePageVideos?.reviews?.authors?.map((author, index, array) => (
              <Div key={`ReviewAuthor__Div-${index + 1}`}>
                <Div styles={styles.author.line} />
                <AuthorBadge
                  author={author}
                  variant={isDefaultVariant ? 'homeVideo' : 'programVideo'}
                />
                <Div
                  styles={styles.author.review}
                  dangerouslySetInnerHTML={{
                    __html: author.richtext ?? ''
                  }}
                />
                {array.length - 1 === index && (
                  <Div styles={styles.author.line} />
                )}
              </Div>
            ))}
          </Div>
        )}

        {onePageVideos?.media?.title && (
          <H2 variant='mediaTitle'>{onePageVideos?.media?.title}</H2>
        )}
        {onePageVideos?.media?.video.length && (
          <Div styles={styles.media.scroller}>
            {onePageVideos?.media?.video.length && (
              <Div styles={styles.media.videos}>
                {onePageVideos?.media?.video?.map(({ url, light }, idx) => (
                  <Fragment key={`MediaVideo__Div-${idx + 1}`}>
                    <ReactPlayer
                      width={size.width}
                      height={size.height}
                      playIcon={
                        <IconPlay
                          styles={{
                            width: '',
                            position: 'absolute',
                            marginLeft: '24px',
                            marginBottom: '24px'
                          }}
                          styleVariant='ytp'
                          colorsVariant='zeta'
                        />
                      }
                      controls
                      light={
                        <Div
                          styles={{
                            position: 'relative',
                            width: size.width,
                            height: size.height
                          }}>
                          <Image
                            colorVariant='transparent'
                            borderRadiusVariant='alpha'
                            fill
                            containerProps={{
                              styles: {
                                '& img': {
                                  objectFit: 'cover'
                                }
                              }
                            }}
                            src={light?.url || '/'}
                            alt={light?.alternativeText || 'Thumbnail'}
                          />
                        </Div>
                      }
                      url={url || '/'}
                      config={{
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            color: 'blue',
                            modestbranding: 1,
                            iv_load_policy: 3,
                            fs: 0,
                            enablejsapi: 1,
                            origin: PATHS.front.root.index,
                            controls: 0,
                            disablekb: 1,
                            rel: 0,
                            cc_load_policty: 0,
                            autohide: 0
                          }
                        }
                      }}
                    />
                  </Fragment>
                ))}
              </Div>
            )}
          </Div>
        )}
      </Container>
    </Section>
  )
}

export default Video
