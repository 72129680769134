import { TTimestamp } from 'ui'

const setLastDayOfMonth = (currentDate: Date) => {
  currentDate.setMonth(currentDate.getMonth() + 1)
  currentDate.setDate(0)
}

const setNextDay = (currentDate: Date, currentDay: number) =>
  currentDay <= 20
    ? currentDate.setDate(currentDay <= 10 ? 10 : 20)
    : setLastDayOfMonth(currentDate)

export const calculateClosestEnrollment = ({
  timestamp,
  months,
  locale
}: {
  timestamp?: TTimestamp | null
  months: readonly string[]
  locale: string | undefined
}) => {
  const isDateTimestamp = timestamp && Date.parse(timestamp) > Date.now()

  const date = isDateTimestamp ? new Date(timestamp) : new Date()

  if (!isDateTimestamp) setNextDay(date, date.getDate())

  return !locale || ['ru', 'en'].includes(locale)
    ? date.toLocaleString(locale, {
        day: 'numeric',
        month: 'long'
      })
    : `${date.getDate()} ${months[date.getMonth()]}`
}

export default calculateClosestEnrollment
