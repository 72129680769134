import {
  useTranslations,
  Header as HeaderContainer,
  LOCALES,
  toUnit,
  Ul,
  Li,
  Div,
  P,
  PATHS,
  Link,
  TRANSLATIONS,
  useMedia,
  media,
  IconPlus,
  Form,
  Image,
  Button,
  IconSearch,
  multiMedia,
  IconEntry
} from 'fractals'
import { Layout, NextWrappers, StyledJSX } from 'ui'
import { useRouter } from 'next/router'
import {
  MouseEventHandler,
  Reducer,
  useEffect,
  useReducer,
  useState
} from 'react'
import Popup from 'reactjs-popup'
import {
  JOURNAL,
  CONTACTS,
  PHONE_TITLE,
  PHONE_LABEL_MSCW,
  LMS_MITM_EXTERNAL_LINK,
  THeaderProps,
  PhoneWithTitle,
  LocaleWithGlobe,
  ArrowDown,
  SEARCH,
  LOG_IN,
  Dictionary,
  BurgerMenu
} from './fractals'
import {
  ROUTES_ABOUT_JOURNAL_CONTACTS,
  SEARCH_COURSE,
  VARIANT_KEYS
} from './fractals/constants'
// eslint-disable-next-line import/no-cycle
import { BreadcrumbsReact } from '../BreadcrumbsReact'
import { VARIANTS_KEYS } from '@/fractals/components/atoms/Header/constants'

const Header = ({
  categories,
  nopb,
  images,
  show,
  withBreadcrumbs,
  variant = 'default',
  closeBurger,
  containerVariant = VARIANTS_KEYS.alpha
}: THeaderProps) => {
  const commonPages = useTranslations(/* ABOUT, */ JOURNAL, CONTACTS)
  const [phoneTitle, phoneLabelMscw, search, searchCourse, logIn] =
    useTranslations(
      PHONE_TITLE,
      PHONE_LABEL_MSCW,
      SEARCH,
      SEARCH_COURSE,
      LOG_IN
    )

  const [phoneNumberAlpha, phoneNumberBeta] = useTranslations(
    TRANSLATIONS.phoneNumberAlpha,
    TRANSLATIONS.phoneNumberBeta
  )

  const [searchRequest, setSearchRequest] = useState('')
  const [searchSlugs, setSearchSlugs] = useState('')
  const [searchSlugCount, setSearchSlugCount] = useState(0)
  const [showBurger, setShowBurger] = useState(false)

  const getSearchQuery = async (value: string): Promise<string> => {
    setSearchRequest(() => value)

    setSearchSlugs('')
    setSearchSlugCount(
      [
        categories?.categories
          ?.map(category =>
            category.programs?.filter(program =>
              program?.name?.toLowerCase()?.includes(
                searchRequest
                  .toLowerCase()
                  .split('')
                  .map(word => (Dictionary[word] ? Dictionary[word] : word))
                  .join('')
              )
            )
          )
          .map(el =>
            el?.map(({ slug }) => setSearchSlugs(prev => `${prev}--${slug}`))
          )
      ].flat(2).length
    )

    return `${PATHS.front.general.programs.ru}?query=${searchSlugs.replace(
      '--',
      ''
    )}`
  }

  const [isLocalesHidden, toggleIsLocalesHidden] = useReducer<
    Reducer<boolean, void>
  >(prev => !prev, true)

  const [isSearchingHidden, toggleIsSearchingHidden] = useReducer<
    Reducer<boolean, void>
  >(prev => !prev, false)

  const { isDesktop, isMobile } = useMedia()

  const { locale, route } = useRouter()

  const transform = 'scale(1.014)'

  const searchColor = '#3A52EE'

  useEffect(() => {
    document
      .querySelector('search_input')
      ?.addEventListener('Enter', async e => {
        e.stopPropagation()
        const result = await getSearchQuery(searchSlugs)
      })
  })

  const styleVariants = {
    [VARIANT_KEYS.default]: {
      icons: {
        ...multiMedia({
          desktop: { marginRight: '8px' },
          laptop: { marginRight: '7.75px' },
          phone: { marginRight: '16px' }
        })
      },
      numbers: {
        container: {
          gap: '36px',
          ...multiMedia({
            display: 'flex',
            phone: { display: 'none' }
          })
        }
      },
      login: {
        link: {
          textAlign: 'center',
          ...multiMedia({
            desktop: { /* marginLeft: '86px', */ width: '104px' },
            laptop: { /* marginLeft: '31px', */ width: '73px' },
            tablet: { /* marginLeft: '41px', */ width: '80px' },
            phone: { display: 'none' }
          })
        }
      }
    }
  } as const

  return (
    <HeaderContainer
      variant={containerVariant}
      colorVariant={show ? 'alpha' : 'omega'}
      styles={{
        '*': { boxSizing: 'border-box' },
        ...((nopb && {
          paddingBottom: 0
        }) ||
          {})
      }}>
      <Div
        styles={{
          ...multiMedia({
            width: 'auto',
            phone: {
              width: '100%'
            }
          }),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Popup
          modal
          nested
          closeOnDocumentClick
          closeOnEscape
          lockScroll
          className='burger-popup'
          onClose={() => setShowBurger(prev => !prev)}
          onOpen={() => setShowBurger(prev => !prev)}
          trigger={
            <Div
              styles={{
                ...multiMedia({
                  display: 'none',
                  phone: { display: (show && 'none') || 'block' }
                })
              }}>
              <BurgerMenu text='Меню' />
            </Div>
          }>
          {/* @ts-expect-error TODO: remove this line and fix the error */}
          {(close: MouseEventHandler) => (
            <Header
              closeBurger={close}
              show={showBurger}
              categories={categories}
              images={images}
            />
          )}
        </Popup>
        <Div
          styles={{
            position: 'relative',
            display: 'flex',
            margin: 'auto',
            ...multiMedia({
              desktop: { display: 'flex' },
              laptop: { display: 'flex' },
              tablet: { display: 'null' },
              phone: { display: 'flex', width: '100%' }
            })
          }}>
          <Popup
            className={show ? 'burger-styled-popup' : 'styled-popup'}
            onOpen={() => {
              getSearchQuery('')
              toggleIsSearchingHidden()
            }}
            onClose={toggleIsSearchingHidden}
            trigger={
              <StyledJSX.Button
                styles={{
                  transition:
                    'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
                  backgroundColor: isSearchingHidden
                    ? `${show ? 'var(--alpha)' : 'var(--beta)'}`
                    : `${show ? 'var(--beta)' : 'var(--alpha)'}`,
                  // backgroundColor: (isSearchingHidden && (show && 'var(--beta)') || 'var(--alpha)') || show && 'var(--alpha)' || 'var(--beta)',
                  '@media (hover: hover)': {
                    '&:hover': {
                      backgroundColor: !isSearchingHidden
                        ? `${show ? 'var(--omega)' : 'var(--beta)'}`
                        : `${show ? 'var(--omega)' : 'var(--alpha)'}`,
                      color: isSearchingHidden
                        ? `${show ? 'var(--alpha)' : 'var(--beta)'}`
                        : `${show ? 'var(--alpha)' : 'var(--alpha)'}`,
                      transform
                    },
                    '&:hover > span > svg > *': {
                      transition:
                        'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
                      stroke: isSearchingHidden
                        ? `${show ? 'var(--alpha)' : 'var(--beta)'}`
                        : `${show ? 'var(--alpha)' : 'var(--alpha)'}`
                    }
                  },
                  '&:focus-visible': {
                    backgroundColor: !isSearchingHidden
                      ? 'var(--beta)'
                      : 'var(--alpha)',
                    color: isSearchingHidden ? 'var(--beta)' : 'var(--alpha)',
                    transform
                  },
                  border: show
                    ? '1px solid var(--alpha)'
                    : '1px solid var(--beta)',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ...multiMedia({
                    desktop: {
                      width: '280px',
                      height: '48px',
                      fontSize: '14px'
                    },
                    laptop: {
                      width: '176px',
                      height: '38px',
                      fontSize: '12px'
                    },
                    tablet: { display: 'none' },
                    phone: {
                      width: '-webkit-fill-available',
                      height: '40px',
                      fontSize: '14px'
                    }
                  }),
                  color: !isSearchingHidden
                    ? `${show ? 'var(--alpha)' : 'var(--beta)'}`
                    : `${show ? 'var(--beta)' : 'var(--alpha)'}`,
                  cursor: 'pointer'
                }}>
                {isSearchingHidden ? (
                  <IconPlus
                    styles={styleVariants[variant].icons}
                    colorVariant={
                      ((!isSearchingHidden || show) && 'beta') || 'alpha'
                    }
                    variant='cross'
                  />
                ) : (
                  <IconSearch
                    colorVariant={show ? 'alpha' : 'beta'}
                    styles={styleVariants[variant].icons}
                  />
                )}
                <P variant='catalogCourses'>Каталог курсов</P>
              </StyledJSX.Button>
            }
            modal
            nested
            closeOnDocumentClick
            closeOnEscape
            lockScroll>
            {/* @ts-expect-error TODO: remove this line and fix the error */}
            {(close: MouseEventHandler) => (
              <Div
                tabIndex={-1}
                styles={{
                  zIndex: 999,
                  display: 'flex',
                  pointerEvents: 'auto',
                  inset: '0px'
                }}>
                <Div
                  styles={{
                    display: 'flex',
                    flexDirection: 'column',
                    pointerEvents: 'auto'
                  }}>
                  <Form
                    id='SearchForm__id'
                    formName='SearchForm'
                    method='GET'
                    action={`${PATHS.front.general.programs.ru}`}
                    onSubmit={event => {
                      event.preventDefault()
                      if (searchSlugs) {
                        window.location.href = `${
                          PATHS.front.general.programs.ru
                        }?query=${searchSlugs.replace('--', '')}`
                      }
                    }}>
                    <Div
                      id='searchTable'
                      styles={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        backgroundColor: show ? 'var(--beta)' : 'white',
                        justifyContent: 'center',
                        ...media('borderRadius', '0 0 10px 10px', {
                          phone: '10px'
                        }),
                        width: '-webkit-fill-available',
                        margin: `0 auto`,
                        textAlign: 'center'
                      }}>
                      <Div
                        styles={{
                          display: 'flex',
                          position: 'relative',
                          ...multiMedia({
                            desktop: {
                              margin: `0 ${toUnit(32)} 0 -23rem`,
                              maxWidth: '31rem',
                              width: '31rem'
                            },
                            laptop: {
                              margin: `0 ${toUnit(32)} 0 -1rem`,
                              maxWidth: '31rem',
                              width: '31rem'
                            },
                            tablet: {
                              margin: `0 ${toUnit(16)} 0 0`,
                              maxWidth: '22rem',
                              width: '23rem'
                            },
                            phone: {
                              margin: '1rem',
                              maxWidth: '-webkit-fill-available',
                              width: '-webkit-fill-available'
                            }
                          }),
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <IconSearch
                          styles={{
                            zIndex: 10,
                            position: 'absolute',
                            left: 0,
                            marginLeft: toUnit(16)
                          }}
                        />
                        <StyledJSX.Input
                          className='search_input'
                          type='text'
                          placeholder={searchCourse}
                          value={searchRequest}
                          onChange={e => getSearchQuery(e.target.value)}
                          styles={{
                            zIndex: 5,
                            height: toUnit(48),
                            width: '100%',
                            paddingLeft: toUnit(48),
                            background: '#F2F2F2',
                            borderRadius: toUnit(8),
                            outline: 'none'
                          }}
                        />
                        {searchRequest && (
                          <IconPlus
                            onClick={() => setSearchRequest('')}
                            styles={{
                              zIndex: 10,
                              cursor: 'pointer',
                              position: 'absolute',
                              right: 0,
                              marginRight: toUnit(16)
                            }}
                            colorVariant='omega'
                            variant='cross'
                          />
                        )}
                      </Div>
                      <Link
                        variant='alpha'
                        styles={{
                          ...multiMedia({
                            phone: { display: 'none' }
                          })
                        }}
                        onClick={() => getSearchQuery(searchRequest)}
                        href={
                          searchSlugs &&
                          `${
                            PATHS.front.general.programs.ru
                          }?query=${searchSlugs.replace('--', '')}`
                        }
                        target='_top'>
                        <Button
                          borderRadiusVariant='alpha'
                          colorsVariant='alpha'
                          sizeVariant='medium'
                          styles={{
                            borderColor: 'var(--omega)',
                            marginRight: 'auto',
                            ...media('width', '18rem', {
                              laptop: '14rem',
                              tablet: '14rem'
                            }),
                            height: '1rem'
                          }}>
                          Найти программу
                        </Button>
                      </Link>
                      <Div
                        styles={{
                          minWidth: '-webkit-fill-available',
                          ...media('width', '78rem', {
                            laptop: '52rem',
                            tablet: '42rem',
                            phone: 'inherit'
                          }),
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <P
                          variant={show ? 'deltaBurger' : 'delta'}
                          styles={{
                            ...media('paddingLeft', toUnit(32), {
                              phone: toUnit(16)
                            }),
                            textAlign: 'start',
                            minInlineSize: '-moz-available',
                            fontWeight: 600,
                            width: 'inherit',
                            margin: '1rem 0 1rem 0'
                          }}>
                          {!searchRequest
                            ? 'Направления курсов'
                            : 'Результаты поиска'}
                        </P>
                      </Div>
                      <Div
                        styles={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          minHeight: show ? 'inherit' : '',
                          marginBottom: show ? '10rem' : '',
                          ...multiMedia({
                            padding: '',
                            desktop: { maxWidth: '78rem' },
                            laptop: { maxWidth: '51rem' },
                            tablet: { maxWidth: '41rem' },
                            phone: {
                              maxWidth: '-webkit-fill-available',
                              padding: '0 0.5rem'
                            }
                          })
                        }}>
                        {!searchRequest && (
                          <NextWrappers.Link
                            href={`${PATHS.front.general.programs.ru}`}>
                            <StyledJSX.Button
                              onClick={close}
                              styles={{
                                transition:
                                  'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
                                backgroundColor: 'var(--alpha)',
                                color: 'var(--omega)',
                                '@media (hover: hover)': {
                                  '&:hover': {
                                    backgroundColor: 'var(--beta)',
                                    color: 'var(--alpha)',
                                    transform
                                  }
                                },
                                '&:focus-visible': {
                                  backgroundColor: 'var(--beta)',
                                  color: 'var(--alpha)',
                                  transform
                                },
                                minWidth: 'fit-content',
                                width: show ? '' : 'fit-content',
                                height: show ? '' : toUnit(40),
                                margin: `${toUnit(8)} ${toUnit(8)} ${toUnit(
                                  8
                                )} ${toUnit(8)}`,
                                border: '2px solid #f2f2f2',
                                borderRadius: toUnit(24),
                                textAlign: 'center',
                                ...media('fontSize', '12px', {
                                  desktop: '14px',
                                  phone: '14px'
                                }),
                                padding: show ? '8px 16px' : '',
                                cursor: 'pointer'
                              }}>
                              <P
                                variant='betaA'
                                styles={{
                                  margin: '0 1rem 0 1rem'
                                }}>
                                Все направления
                              </P>
                            </StyledJSX.Button>
                          </NextWrappers.Link>
                        )}
                        {!searchRequest
                          ? categories?.faculties?.map((faculty, idx) => (
                              <NextWrappers.Link
                                key={`Header__${faculty.name}--${idx + 1}`}
                                href={`${PATHS.front.general.programs.ru}--fakultet--${faculty.slug}`}>
                                <StyledJSX.Button
                                  onClick={close}
                                  styles={{
                                    transition:
                                      'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
                                    '@media (hover: hover)': {
                                      '&:hover': {
                                        backgroundColor: 'var(--beta)',
                                        color: 'var(--alpha)',
                                        transform
                                      }
                                    },
                                    '&:focus-visible': {
                                      backgroundColor: 'var(--beta)',
                                      color: 'var(--alpha)',
                                      transform
                                    },
                                    backgroundColor: 'var(--alpha)',
                                    color: 'var(--omega)',
                                    minWidth: 'fit-content',
                                    width: show ? '' : 'fit-content',
                                    height: show ? '' : toUnit(40),
                                    margin: toUnit(8),
                                    padding: show ? '8px 16px' : '',
                                    border: '2px solid #f2f2f2',
                                    borderRadius: toUnit(24),
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    ...media('fontSize', toUnit(16), {
                                      desktop: toUnit(18)
                                    }),
                                    cursor: 'pointer'
                                  }}>
                                  <P
                                    variant='betaA'
                                    styles={{
                                      margin: '0 1rem 0 1rem',
                                      minWidth: 'max-content'
                                    }}>
                                    {!(
                                      faculty.name === 'Прикладная информатика'
                                    )
                                      ? faculty.name
                                      : 'Информатика'}
                                  </P>
                                </StyledJSX.Button>
                              </NextWrappers.Link>
                            ))
                          : categories?.categories
                              ?.map(category =>
                                category.programs?.filter(program =>
                                  program?.name?.toLowerCase()?.includes(
                                    searchRequest
                                      .toLowerCase()
                                      .split('')
                                      .map(word =>
                                        Dictionary[word]
                                          ? Dictionary[word]
                                          : word
                                      )
                                      .join('')
                                  )
                                )
                              )
                              .slice()
                              .flat()
                              .splice(0, isDesktop ? 6 : 4)
                              .map((program, idx, array) => (
                                <NextWrappers.Link
                                  key={`Header__${program?.name}--${idx + 1}`}
                                  styles={{
                                    display: 'flex',
                                    '&:nth-child(3)': {
                                      borderBottom:
                                        (isDesktop || isMobile) &&
                                        array.length > 3
                                          ? '3px solid #f2f2f2'
                                          : ''
                                    },
                                    '&:nth-child(2)': {
                                      borderBottom:
                                        array.length > 3 ||
                                        (array.length > 2 &&
                                          !isDesktop &&
                                          !isMobile)
                                          ? '3px solid #f2f2f2'
                                          : ''
                                    },
                                    '&:nth-child(1)': {
                                      borderBottom:
                                        array.length > 3 ||
                                        (array.length > 2 &&
                                          !isDesktop &&
                                          !isMobile)
                                          ? '3px solid #f2f2f2'
                                          : ''
                                    },
                                    ...media('width', '30%', {
                                      laptop: '45%',
                                      tablet: '44%',
                                      phone: '90%'
                                    }),
                                    margin: `0 ${toUnit(16)}`
                                  }}
                                  href={`${program?.slug}--${
                                    categories?.categories?.filter(categori =>
                                      categori.programs?.find(
                                        el => el.slug === program?.slug
                                      )
                                    )?.[0]?.slug
                                  }`.replace('kursy', 'kurs')}>
                                  <StyledJSX.Button
                                    className='styled-button'
                                    styles={{
                                      ':nth-last-child(2)': {
                                        borderBottom: '2px solid #f2f2f2'
                                      },
                                      width: '100%',
                                      height: toUnit(100),
                                      display: 'flex',
                                      marginTop: '1rem',
                                      gap: toUnit(9.5),
                                      ...media('fontSize', toUnit(16), {
                                        desktop: toUnit(18)
                                      }),
                                      cursor: 'pointer'
                                    }}>
                                    {[
                                      images?.find(
                                        image =>
                                          image?.programSlug === program?.slug
                                      )
                                    ]?.map(image => (
                                      <Image
                                        src={image?.image.url || '/'}
                                        alt={image?.image.alternativeText || ''}
                                        width='100'
                                        height='100'
                                        containerProps={{
                                          styles: {
                                            borderRadius: '8px',
                                            position: 'absolute',
                                            gridRow: 'span 2',
                                            '& img': {
                                              objectFit: 'cover',
                                              position: 'relative'
                                            }
                                          }
                                        }}
                                        colorVariant='transparent'
                                      />
                                    ))}
                                    <P
                                      variant='beta'
                                      styles={{
                                        ...multiMedia({
                                          desktop: {
                                            fontSize: '14px',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                          },
                                          laptop: {
                                            fontSize: '14px',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                          },
                                          tablet: {
                                            fontSize: '14px',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                          },
                                          phone: {
                                            fontSize: '20px',
                                            flexDirection: 'column',
                                            justifyContent: ''
                                          }
                                        }),
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        inlineSize: 'inherit',
                                        gap: '1rem',
                                        textAlign: 'start'
                                      }}>
                                      <P
                                        variant='alpha'
                                        styles={{
                                          ...multiMedia({
                                            fontSize: '12px',
                                            phone: { fontSize: '14px' }
                                          }),
                                          color: show
                                            ? 'var(--alpha)'
                                            : 'var(--beta)',
                                          marginLeft: 'auto'
                                        }}>
                                        {`${
                                          categories?.categories?.find(
                                            categori =>
                                              categori.programs?.find(
                                                el => el?.slug === program?.slug
                                              )
                                          )?.name
                                        }`}
                                      </P>
                                      <P
                                        variant='gamma'
                                        styles={{
                                          flexBasis: '-moz-available',
                                          textAlign: 'start',
                                          fontSize: '18px',
                                          marginLeft: '7rem',
                                          marginRight: 'auto',
                                          color: show
                                            ? 'var(--alpha)'
                                            : 'var(--omega)'
                                        }}>
                                        {`${
                                          program?.name !== null &&
                                          program?.name !== undefined &&
                                          program?.name?.length > 35
                                            ? program?.name
                                                ?.slice(0, 35)
                                                .concat('...')
                                            : program?.name
                                        }`}
                                      </P>
                                    </P>
                                  </StyledJSX.Button>
                                </NextWrappers.Link>
                              ))}
                        <Link
                          variant={show ? 'delta' : 'zeta'}
                          onClick={() => getSearchQuery(searchRequest)}
                          styles={{
                            flexBasis: '100%',
                            textAlign: 'start',
                            padding: '0 0 2rem 2rem',
                            overflowWrap: 'break-word',
                            width: 'inherit'
                          }}
                          href={
                            searchSlugs &&
                            `${
                              PATHS.front.general.programs.ru
                            }?query=${searchSlugs.replace('--', '')}`
                          }
                          target='_top'>
                          {searchRequest &&
                            `Все результаты по запросу "${searchRequest}"${
                              searchSlugCount ? ` (${searchSlugCount})` : ''
                            }`}
                        </Link>
                      </Div>
                    </Div>
                  </Form>
                </Div>
              </Div>
            )}
          </Popup>
        </Div>
      </Div>
      <Div styles={styleVariants[variant].numbers.container}>
        <PhoneWithTitle
          phoneNumbers={[
            { phoneNumber: phoneNumberAlpha, label: phoneTitle },
            { phoneNumber: phoneNumberBeta, label: phoneLabelMscw }
          ]}
        />
      </Div>
      <Div
        styles={{
          position: 'relative',
          ...multiMedia({
            phone: {
              display: 'flex',
              gap: '24px',
              position: 'absolute',
              top: 0,
              right: 0
            }
          })
        }}>
        {!show ? (
          <>
            <StyledJSX.Button
              onClick={toggleIsLocalesHidden}
              styles={{
                display: 'flex',
                alignItems: 'center',
                gap: toUnit(9.5),
                textTransform: 'capitalize',
                ...media('fontSize', toUnit(12), {
                  desktop: toUnit(14)
                }),
                cursor: 'pointer'
              }}>
              <LocaleWithGlobe locale={locale} fillGlobe='#3A52EE' />
              <ArrowDown />
            </StyledJSX.Button>
            <Div
              styles={{
                zIndex: 100,
                position: 'absolute',
                display: isLocalesHidden ? 'none' : 'flex',
                flexDirection: 'column',
                top: '110%',
                left: '-13%',
                width: 'max-content',
                background: '#F2F2F2',
                borderRadius: '16px',
                padding: toUnit(16),
                gap: toUnit(8)
              }}>
              {Object.values(LOCALES)
                .filter(loc => loc !== locale)
                .sort(loc => (loc === 'ru' ? -1 : 0))
                .map((loc, idx) => (
                  <NextWrappers.Link
                    key={`HeaderLocales__${loc}--${idx + 1}`}
                    onClick={toggleIsLocalesHidden}
                    href={PATHS.front.home.index}
                    locale={loc}
                    styles={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: toUnit(9.5),
                      textTransform: 'capitalize',
                      ...media('fontSize', toUnit(12), {
                        desktop: toUnit(14)
                      })
                    }}>
                    <LocaleWithGlobe locale={loc} fillGlobe='black' />
                  </NextWrappers.Link>
                ))}
            </Div>
          </>
        ) : (
          <IconPlus
            colorVariant='alpha'
            variant='cross'
            onClick={closeBurger}
          />
        )}
      </Div>
      <Layout.Flex
        cols='auto'
        nowrap
        styles={{
          ...multiMedia({
            flexBasis: '100%',
            desktop: { flexBasis: '81%' },
            phone: { display: (show && 'flex') || 'none' }
          }),
          justifyContent: show ? 'start' : 'space-between',
          marginLeft: 'auto',
          marginTop: show ? '16px' : ''
        }}>
        <Ul
          variant='beta'
          styles={{
            flexDirection: show ? 'column' : 'row',
            flexWrap: 'nowrap',
            ...media('gap', toUnit(19), {
              laptop: toUnit(27),
              desktop: toUnit(42),
              phone: '16px'
            })
          }}>
          {categories?.categories?.map((category, idx) => (
            <Li
              onClick={closeBurger}
              variant='gamma'
              key={`Header__${category.name}--${idx + 1}`}>
              <NextWrappers.Link href={category.slug ?? ''}>
                <P
                  variant={show ? 'alphaBurger' : 'beta'}
                  styles={{
                    fontWeight: 500,
                    width: 'max-content'
                  }}>
                  {category.name}
                </P>
              </NextWrappers.Link>
            </Li>
          ))}
        </Ul>
        <Ul
          variant='beta'
          styles={{
            flexWrap: 'nowrap',
            flexDirection: show ? 'column' : 'row',
            marginLeft: show ? '52px' : '',
            ...media('gap', toUnit(19), {
              laptop: toUnit(47),
              desktop: toUnit(62),
              phone: '20px'
            })
          }}>
          {commonPages?.map((page, idx) => (
            <Li variant='gamma' key={`Header__${page}--${idx + 1}`}>
              <NextWrappers.Link
                href={`${ROUTES_ABOUT_JOURNAL_CONTACTS?.[idx]?.route}`}>
                <P
                  variant={show ? 'alphaCommonBurger' : 'beta'}
                  styles={{
                    fontWeight: 500,
                    color: !show ? '#3A52EE' : 'var(--alpha)',
                    width: 'max-content'
                  }}>
                  {page}
                </P>
              </NextWrappers.Link>
            </Li>
          ))}
        </Ul>
      </Layout.Flex>
      {show && (
        <Div
          styles={{
            color: 'var(--alpha)',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '40px',
            '& > div > div > a > p': {
              ':first-child': {
                fontSize: '10px',
                marginTop: '16px',
                marginBottom: '8px'
              },
              ':last-child': {
                fontSize: '14px'
              },
              color: 'var(--alpha)',
              fontWeight: 500
            }
          }}>
          <Div
            styles={{
              display: 'flex',
              width: '100%',
              gap: '52px'
            }}>
            <PhoneWithTitle
              phoneNumbers={[
                { phoneNumber: phoneNumberAlpha, label: phoneTitle },
                { phoneNumber: phoneNumberBeta, label: phoneLabelMscw }
              ]}
            />
          </Div>
        </Div>
      )}
      {!show && withBreadcrumbs && (
        <BreadcrumbsReact bread={null} styles={{ marginBottom: '-3rem' }} />
      )}
    </HeaderContainer>
  )
}

export default Header
