import * as React from 'react'
import { Layout, StyledJSX } from 'ui'
import { toUnit, media, multiMedia } from 'fractals'
import { Container } from '../Container'
import { Logo } from '../Logo'
import { THeaderProps } from './types'
import { VARIANTS_KEYS } from './constants'

const Header = ({
  variant,
  children,
  colorVariant,
  containerProps: { styles: containerStyles, ...containerProps } = {
    styles: {}
  },
  ...props
}: THeaderProps) => {
  const stylesVariants = {
    [VARIANTS_KEYS.alpha]: {
      ...media('paddingTop', toUnit(24), {
        tablet: toUnit(30),
        laptop: toUnit(37),
        desktop: toUnit(32)
      }),
      ...media('paddingBottom', '64px', {
        tablet: '40px',
        desktop: '80px',
        phone: '16px'
      })
    },
    [VARIANTS_KEYS.beta]: {
      ...media('paddingTop', toUnit(24), {
        tablet: toUnit(30),
        laptop: toUnit(37),
        desktop: toUnit(32)
      }),
      ...media('paddingBottom', toUnit(24), {
        tablet: toUnit(30),
        laptop: toUnit(37),
        desktop: toUnit(32)
      })
    }
  } as const

  const styles = {
    // DEFAULT STYLES WOULD GO HERE
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return (
    <StyledJSX.Header {...props} styles={styles}>
      <Container>
        <Layout.Flex
          cols='auto'
          styles={{
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            ...multiMedia({
              desktop: { gap: '32px' },
              laptop: { gap: '29px' },
              tablet: { gap: '26px' },
              phone: { gap: '15px' }
            }),
            ...containerStyles
          }}
          {...containerProps}>
          <Layout.FlexItem>
            <Logo colorVariant={colorVariant || 'omega'} id='Header' />
          </Layout.FlexItem>
          {children}
        </Layout.Flex>
      </Container>
    </StyledJSX.Header>
  )
}

export default Header
