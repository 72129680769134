import { LOCALES } from 'fractals'

export const IMG_LICENCE_NUMBER = '№1207700217977'

export const PROGRAMS = {
  [LOCALES.en]: 'Programs',
  [LOCALES.ru]: 'Программы',
  [LOCALES.uz]: 'Dasturlar',
  [LOCALES.kk]: 'Дастурлар'
} as const

export const FACULTIES = {
  [LOCALES.en]: 'Faculties',
  [LOCALES.ru]: 'Направления',
  [LOCALES.uz]: 'Fakultetlar',
  [LOCALES.kk]: 'Факультеттер'
} as const

export const ABOUT = {
  [LOCALES.en]: 'About',
  [LOCALES.ru]: 'Об институте',
  [LOCALES.uz]: 'Institut haqida',
  [LOCALES.kk]: 'Институт туралы'
} as const

export const POLICY = {
  [LOCALES.en]: 'Privacy Policy',
  [LOCALES.ru]: 'Политика конфиденциальности',
  [LOCALES.uz]: 'Maxfiylik siyosati',
  [LOCALES.kk]: 'Құпиялылық саясаты'
} as const

export const TERMS = {
  [LOCALES.en]: 'Terms of Use',
  [LOCALES.ru]: 'Пользовательское соглашение',
  [LOCALES.uz]: 'Foydalanish shartlari',
  [LOCALES.kk]: 'Пайдалану шарттары'
} as const

export const FORM_TITLE = {
  [LOCALES.en]: 'Subscribe to updates',
  [LOCALES.ru]: 'Подписаться на обновления',
  [LOCALES.uz]: 'Yangilanishlarga obuna bo‘ling',
  [LOCALES.kk]: 'Жаңартуларға жазылыңыз'
} as const

export const CONTACT = {
  [LOCALES.en]: 'Contact',
  [LOCALES.ru]: 'Контакты',
  [LOCALES.uz]: 'Aloqa',
  [LOCALES.kk]: 'Байланыс'
} as const

export const IMG_LICENCE_ALT = {
  [LOCALES.en]: 'State license',
  [LOCALES.ru]: 'Государственная лицензия',
  [LOCALES.uz]: 'Davlat litsenziya',
  [LOCALES.kk]: 'Дербес дипломы'
} as const
