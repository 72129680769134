import {
  Main,
  Section,
  Container,
  useTranslations,
  H1,
  TRANSLATIONS,
  toUnit,
  Span,
  H3,
  Image,
  Div,
  LOCALES,
  PATHS,
  useMedia
} from 'fractals'
import { Layout, slugify } from 'ui'
import { Shared } from 'modules'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import {
  HEADING,
  IDS,
  CONTACTS,
  TContactPageProps,
  ContactButtons,
  ALMATY,
  MOSCOW,
  TASHKENT,
  IMG_MOSCOW_MAP_ALT,
  CAMPUS,
  ADDRESS_LABEL,
  EMAIL_LABEL,
  PHONE_NUMBER_LABEL,
  IMG_PEOPLE_STUDYING_ALT,
  FORM_TITLE,
  FORM_NAME,
  SEO_TITLE,
  SEO_DESCRIPTION
} from './fractals'
import imgMoscowMap from './moscow-map.jpg'
import peopleStudying from './people-studying.jpg'
import { BreadcrumbsReact } from '../shared/widgets'

const ContactPage = ({ categories, footer, images }: TContactPageProps) => {
  const [
    heading,
    moscow,
    almaty,
    tashkent,
    addressLabel,
    phoneNumberLabel,
    emailLabel,
    imgMoscowMapAlt,
    campus,
    imgPeopleAreStudyingAlt,
    formTitle,
    formName,
    addressMoscow,
    addressTashkent,
    addressAlmaty,
    // phoneNumberAlpha,
    // phoneNumberBeta,
    phoneNumberMoscowAlpha,
    phoneNumberMoscowBeta,
    phoneNumberTashkentAlpha,
    phoneNumberAlmatyAlpha,
    phoneNumberAlmatyBeta,
    phoneNumberAlmatyGamma,
    email,
    emailTashkent,
    emailAlmaty,
    companyName,
    seoTitle,
    seoDescription
  ] = useTranslations(
    HEADING,
    MOSCOW,
    ALMATY,
    TASHKENT,
    ADDRESS_LABEL,
    PHONE_NUMBER_LABEL,
    EMAIL_LABEL,
    IMG_MOSCOW_MAP_ALT,
    CAMPUS,
    IMG_PEOPLE_STUDYING_ALT,
    FORM_TITLE,
    FORM_NAME,
    TRANSLATIONS.addressMoscow,
    TRANSLATIONS.addressTashkent,
    TRANSLATIONS.addressAlmaty,
    // TRANSLATIONS.phoneNumberAlpha,
    // TRANSLATIONS.phoneNumberBeta,
    TRANSLATIONS.phoneNumberMoscowAlpha,
    TRANSLATIONS.phoneNumberMoscowBeta,
    TRANSLATIONS.phoneNumberTashkentAlpha,
    TRANSLATIONS.phoneNumberAlmatyAlpha,
    TRANSLATIONS.phoneNumberAlmatyBeta,
    TRANSLATIONS.phoneNumberAlmatyGamma,
    TRANSLATIONS.email,
    TRANSLATIONS.emailTashkent,
    TRANSLATIONS.emailAlmaty,
    TRANSLATIONS.companyName,
    SEO_TITLE,
    SEO_DESCRIPTION
  )

  const { isDesktop, isLaptop, isTablet, isMobile } = useMedia()
  const { locale, asPath } = useRouter()

  const [activeContact, setActiveContact] = useState<
    'moscow' | 'almaty' | 'tashkent'
  >('moscow')

  const items = [
    {
      type: 'moscow',
      city: moscow,
      address: addressMoscow,
      phoneNumberAlpha: phoneNumberMoscowAlpha,
      phoneNumberBeta: phoneNumberMoscowBeta,
      phoneNumberGamma: null,
      email
    },
    {
      type: 'almaty',
      city: almaty,
      address: addressAlmaty,
      phoneNumberAlpha: phoneNumberAlmatyAlpha,
      phoneNumberBeta: phoneNumberAlmatyBeta,
      phoneNumberGamma: phoneNumberAlmatyGamma,
      email: emailAlmaty
    },
    {
      type: 'tashkent',
      city: tashkent,
      address: addressTashkent,
      phoneNumberAlpha: phoneNumberTashkentAlpha,
      phoneNumberBeta: null,
      phoneNumberGamma: null,
      email: emailTashkent
    }
  ].filter(item => item.type === activeContact)

  const seoParams = {
    title: seoTitle,
    desc: seoDescription,
    canonical: `${PATHS.front.root.index}${asPath}`
  }

  return (
    <>
      <NextSeo
        title={seoParams.title}
        description={seoParams.desc}
        canonical={seoParams.canonical}
        openGraph={{
          url: seoParams.canonical,
          title: seoParams.title,
          description: seoParams.desc,
          images: [
            {
              url: `${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`,
              width: 512,
              height: 512,
              alt: companyName,
              type: 'image/png'
            }
          ],
          site_name: companyName
        }}
      />
      <Shared.PageContainer id={IDS.contactPage}>
        <Shared.Header images={images} categories={categories} />
        {!isMobile && (
          <Container styles={{ marginBottom: '2rem' }}>
            <BreadcrumbsReact bread={null} />
          </Container>
        )}
        <Main>
          <Section
            spacingVariant='beta'
            id={slugify(heading)}
            styles={{
              paddingTop: 0
            }}>
            <Container>
              <H1 variant='delta'>{heading}</H1>
              <ContactButtons
                contacts={['moscow', 'almaty', 'tashkent']}
                activeContact={activeContact}
                setActiveContact={setActiveContact}
              />
            </Container>
            <Container>
              <Div
                styles={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: toUnit(80)
                }}>
                {items.map((item, idx) => (
                  <Layout.Flex
                    key={`ContactPageitem__${item.city}--${idx + 1}`}
                    cols={
                      ((isDesktop || isLaptop) && 4) || (isTablet && 2) || 1
                    }
                    gap={toUnit(32)}
                    styles={{
                      justifyContent: 'space-between'
                    }}>
                    <Layout.FlexItem>
                      <H3 variant='alpha'>{item.city}</H3>
                    </Layout.FlexItem>
                    <Layout.FlexItem
                      styles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: toUnit(16)
                      }}>
                      <Span
                        styles={{
                          fontSize: toUnit(14),
                          fontWeight: 500,
                          lineHeight: '120%',
                          color: 'var(--upsilon)'
                        }}>
                        {addressLabel}
                      </Span>
                      {item.address}
                    </Layout.FlexItem>
                    <Layout.FlexItem
                      styles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: toUnit(16)
                      }}>
                      <Span
                        styles={{
                          fontSize: toUnit(14),
                          fontWeight: 500,
                          lineHeight: '120%',
                          color: 'var(--upsilon)'
                        }}>
                        {phoneNumberLabel}
                      </Span>
                      <Span
                        styles={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                        <Span>{item.phoneNumberAlpha}</Span>
                        {item.phoneNumberBeta && (
                          <Span> / {item.phoneNumberBeta}</Span>
                        )}
                        {item.phoneNumberGamma && (
                          <Span> / {item.phoneNumberGamma}</Span>
                        )}
                      </Span>
                    </Layout.FlexItem>
                    <Layout.FlexItem
                      styles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: toUnit(16)
                      }}>
                      <Span
                        styles={{
                          fontSize: toUnit(14),
                          fontWeight: 500,
                          lineHeight: '120%',
                          color: 'var(--upsilon)'
                        }}>
                        {emailLabel}
                      </Span>
                      {item.email}
                    </Layout.FlexItem>
                  </Layout.Flex>
                ))}
                {activeContact === 'moscow' && (
                  <Div
                    styles={{
                      position: 'relative',
                      '&::before': {
                        content: `"${campus}"`,
                        position: 'absolute',
                        top: '20%',
                        left: '43%',
                        aspectRatio: '1 / 1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: (isDesktop && '6%') || '8%',
                        width: '100%',
                        maxWidth: (isDesktop && '6%') || '8%',
                        // minHeight: '10%',
                        // height: '100%',
                        // maxHeight: '10%',
                        borderRadius: '50%',
                        fontSize: toUnit((isDesktop && 14) || 12),
                        fontWeight: 500,
                        lineHeight: '120%',
                        backgroundColor: 'var(--beta)',
                        color: 'var(--alpha)'
                      }
                    }}>
                    <Image
                      colorVariant='transparent'
                      borderRadiusVariant='alpha'
                      src={imgMoscowMap}
                      alt={imgMoscowMapAlt}
                    />
                  </Div>
                )}
                <Layout.Flex
                  cols={(isDesktop && 2) || 1}
                  proportion={(isDesktop && '4:3') || undefined}
                  gap={toUnit(33)}>
                  <Layout.FlexItem
                    styles={{
                      position: 'relative'
                    }}>
                    <Image
                      colorVariant='transparent'
                      borderRadiusVariant='alpha'
                      src={peopleStudying}
                      alt={imgPeopleAreStudyingAlt}
                      fill={isDesktop}
                      containerProps={{
                        styles: {
                          '& img': {
                            objectFit: (isDesktop && 'cover') || undefined
                          }
                        }
                      }}
                    />
                  </Layout.FlexItem>
                  <Layout.FlexItem>
                    <Shared.LeadForm
                      // cost={cost}
                      id={IDS.contactForm}
                      formName={formName}
                      name
                      phoneNumber
                      email
                      heading={formTitle}
                      styleVariant='alpha'
                      colorsVariant='alpha'
                    />
                  </Layout.FlexItem>
                </Layout.Flex>
              </Div>
            </Container>
          </Section>
          {/* <Shared.AskModal /> */}
        </Main>
        <Shared.Footer footer={footer} />
      </Shared.PageContainer>
    </>
  )
}

export default ContactPage
