import { sortBasedOnKey } from 'fractals'
import { fetchDiplomas } from './fetchDiplomas'

export const normalizeDiplomasData = ({
  diplomasData
}: {
  diplomasData: Awaited<ReturnType<typeof fetchDiplomas>>
}) => {
  const diplomasDataNormalized =
    diplomasData?.map(item => ({
      name: item?.name || null,
      rank: item.rank || item.rank === 0 ? item.rank : null,
      imageDefault:
        (item?.imageDefault && {
          url: item?.imageDefault.url || null,
          width: item?.imageDefault.width || null,
          height: item?.imageDefault.height || null,
          alternativeText: item?.imageDefault.alternativeText || null
        }) ||
        null,
      imageTemplate:
        (item?.imageTemplate && {
          url: item?.imageTemplate.url || null,
          width: item?.imageTemplate.width || null,
          height: item?.imageTemplate.height || null,
          alternativeText: item?.imageTemplate.alternativeText || null
        }) ||
        null
    })) || null

  if (diplomasDataNormalized && diplomasDataNormalized.length !== 0)
    return diplomasDataNormalized

  return null
}

export default normalizeDiplomasData
