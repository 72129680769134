import * as React from 'react'
import { StyledJSX } from 'ui'
import Color from 'color'
import { toUnit, useMedia, useTranslations } from 'fractals'
import { TInputProps } from './types'
import {
  PLACEHOLDER_KEYS,
  TYPE_KEYS,
  YOUR_NAME_PLACEHOLDER,
  YOUR_PHONE_NUMBER_PLACEHOLDER,
  YOUR_EMAIL_PLACEHOLDER,
  YOUR_PROMOCODE_PLACEHOLDER
} from './constants'

// TODO: more some of the logic to UI
const Input = React.forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      // colorsVariant,
      placeholder,
      type,
      colorVariant,
      ...props
    },
    ref
  ) => {
    const [
      yourNamePlaceholder,
      yourPhoneNumberPlaceholder,
      yourEmailPlaceHolder,
      yourPromocodePlaceHolder
    ] = useTranslations(
      YOUR_NAME_PLACEHOLDER,
      YOUR_PHONE_NUMBER_PLACEHOLDER,
      YOUR_EMAIL_PLACEHOLDER,
      YOUR_PROMOCODE_PLACEHOLDER
    )

    const { isTablet } = useMedia()

    const placeholders = {
      [PLACEHOLDER_KEYS.name]: yourNamePlaceholder,
      [PLACEHOLDER_KEYS.phoneNumber]: yourPhoneNumberPlaceholder,
      [PLACEHOLDER_KEYS.email]: yourEmailPlaceHolder,
      [PLACEHOLDER_KEYS.promocode]: yourPromocodePlaceHolder
    }

    const types = {
      [TYPE_KEYS.name]: 'text',
      [TYPE_KEYS.phoneNumber]: 'tel',
      [TYPE_KEYS.email]: 'email',
      [TYPE_KEYS.promocode]: 'text'
    }

    // const colorsVariants = {
    //   // [COLORS_VARIANT_KEYS.omega]: {
    //   //   backgroundColor: 'var(--omega)',
    //   //   color: 'var(--alpha)',
    //   //   '@media (hover: hover)': {
    //   //     '&:hover': {
    //   //       backgroundColor: 'var(--alpha)',
    //   //       transform
    //   //     }
    //   //   }
    //   // }
    // } as const

    const styles = {
      ...props.styles,
      outline: 'none',
      // borderWidth: toUnit(1),
      borderStyle: 'solid',
      borderColor: 'transparent',
      transition: 'border-color 200ms ease-in, color 200ms ease-in',
      color: 'var(--omega)',
      '&::placeholder': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        ...(typeof props?.styles?.['&::placeholder'] === 'object' &&
          props?.styles?.['&::placeholder'])
      },
      '&:focus': {
        borderColor:
          (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
          'var(--beta)',
        color:
          (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
          'var(--beta)',
        ...(typeof props?.styles?.['&:focus'] === 'object' &&
          props?.styles?.['&:focus']),
        '&::placeholder': {
          color:
            (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
            'inherit',
          ...(typeof props?.styles?.['&::placeholder'] === 'object' &&
            props?.styles?.['&::placeholder'])
        }
      },
      '&:active': {
        borderColor:
          (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
          'var(--beta)',
        color:
          (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
          'var(--beta)',
        ...(typeof props?.styles?.['&:active'] === 'object' &&
          props?.styles?.['&:active']),
        '&::placeholder': {
          color:
            (colorVariant?.color && Color(colorVariant.color).rgb().string()) ||
            'inherit',
          ...(typeof props?.styles?.['&::placeholder'] === 'object' &&
            props?.styles?.['&::placeholder'])
        }
      }
    } as const

    return (
      <StyledJSX.Input
        ref={ref}
        {...props}
        placeholder={placeholders[placeholder]}
        type={types[type]}
        styles={{
          ...styles,
          paddingLeft: type === 'phoneNumber' ? '77.5px' : toUnit(16)
        }}
      />
    )
  }
)

export default Input
