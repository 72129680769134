/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Container,
  Div,
  H1,
  H2,
  P,
  Section,
  media,
  multiMedia,
  toUnit,
  useAt,
  useMedia
} from 'fractals'
import { FC } from 'react'
import Image from 'next/image'
import { BannerCircle, TBannerProps } from './fractals'
import { CategoryInfo } from '../../fractals/molecules'
import FadeAndHideText from '@/fractals/components/atoms/FadeAndHideText'
import {
  getBannerColor,
  getBannerHeader,
  getBannerImage,
  getDescription
} from './fractals/utils'

const Banner: FC<TBannerProps> = ({ data, category, defaultData }) => {
  const { isLaptop, isDesktop, isMobile } = useMedia()

  const useAtResult = useAt()

  const header = getBannerHeader({
    data,
    category,
    defaultData,
    useAtResult
  })

  const description = getDescription({
    data,
    category,
    defaultData,
    useAtResult
  })

  const color = getBannerColor({
    data,
    category,
    defaultData,
    useAtResult
  })

  const image = getBannerImage({
    data,
    category,
    defaultData,
    useAtResult
  })

  return (
    <Section spacingVariant='iota' id='Banner' styles={{}}>
      <Container>
        <Div
          styles={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {(isDesktop || isLaptop) && <BannerCircle color={color} />}

          <Div
            styles={{
              backgroundColor: color,
              padding: toUnit(32),
              color: 'var(--alpha)',
              borderRadius: toUnit(16),
              width: '100%'
            }}>
            <Div
              styles={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
                marginBottom: toUnit(10),
                ...multiMedia({
                  desktop: { marginBottom: toUnit(10) },
                  laptop: { marginBottom: toUnit(24) },
                  tablet: { marginBottom: toUnit(16) },
                  phone: { marginBottom: toUnit(16) }
                })
              }}>
              <H1 variant='phi'>{header}</H1>
              {category && (
                <CategoryInfo
                  content={category.description}
                  label={category.name}
                  color='var(--alpha)'
                  hoverColor='#8D9BF5'
                  // eslint-disable-next-line
                  // @ts-ignore
                  styles={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    right: '24px',
                    top: '50%',
                    marginTop: '-8px',
                    marginRight: '-8px'
                  }}
                />
              )}
            </Div>
            {isMobile ? (
              <FadeAndHideText maxHeight={50} bgColor={color}>
                <P variant='omicronWhite'>{description}</P>
              </FadeAndHideText>
            ) : (
              <P variant='omicronWhite'>{description}</P>
            )}
          </Div>
          {(isDesktop || isLaptop) && image?.url && (
            <Div
              styles={{
                marginLeft: '32px',
                ...media('marginLeft', '32px', {
                  laptop: '10px'
                })
              }}>
              <Image
                src={image.url}
                width={246}
                height={222}
                alt={image.alternativeText ?? ''}
              />
            </Div>
          )}
        </Div>
      </Container>
    </Section>
  )
}

export default Banner
