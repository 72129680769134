export const phonePrefixToCountryCode: {
  [prefix: string]: { code: string; ru: string; native: string }
} = {
  '+1-664': { code: 'MS', ru: 'Монтсеррат', native: 'Montserrat' },
  '+212': { code: 'MA', ru: 'Мороко', native: 'Morocco' },
  '+258': { code: 'MZ', ru: 'Мозамбик', native: 'Mozambique' },
  '+95': { code: 'MM', ru: 'Мьянма', native: 'Myanmar' },
  '+264': { code: 'NA', ru: 'Намибия', native: 'Namibia' },
  '+674': { code: 'NR', ru: 'Науру', native: 'Nauru' },
  '+977': { code: 'NP', ru: 'Непал', native: 'Nepal' },
  '+31': { code: 'NL', ru: 'Нидерланды', native: 'Netherlands' },
  '+687': { code: 'NC', ru: 'Новая каледония', native: 'New Caledonia' },
  '+64': { code: 'NZ', ru: 'Новая зеландия', native: 'New Zealand' },
  '+505': { code: 'NI', ru: 'Никарагуа', native: 'Nicaragua' },
  '+227': { code: 'NE', ru: 'Нигер', native: 'Niger' },
  '+234': { code: 'NG', ru: 'Нигерия', native: 'Nigeria' },
  '+683': { code: 'NU', ru: 'Ниуэ', native: 'Niue' },
  '+672': { code: 'NF', ru: 'Норфолкские острова', native: 'Norfolk Island' },
  '+850': { code: 'KP', ru: 'Северная Корея', native: 'North Korea' },
  '+967': { code: 'YE', ru: 'Северный Йемен', native: 'North Yemen' },
  '+670': {
    code: 'MP',
    ru: 'Северно Марианские острова',
    native: 'Northern Mariana Islands'
  },
  '+47': { code: 'NO', ru: 'Норвегия', native: 'Norway' },
  '+968': { code: 'OM', ru: 'Оман', native: 'Oman' },
  '+92': { code: 'PK', ru: 'Пакистан', native: 'Pakistan' },
  '+507': { code: 'PA', ru: 'Панама', native: 'Panama' },
  '+675': { code: 'PG', ru: 'Папуа Новая Гвинея', native: 'Papua New Guinea' },
  '+595': { code: 'PY', ru: 'Парагвай', native: 'Paraguay' },
  '+51': { code: 'PE', ru: 'Перу', native: 'Peru' },
  '+63': { code: 'PH', ru: 'Филипины', native: 'Philippines' },
  '+48': { code: 'PL', ru: 'Польша', native: 'Poland' },
  '+351': { code: 'PT', ru: 'Португалия', native: 'Portugal' },
  '+1-787': { code: 'PR', ru: 'Пуэрто Рико', native: 'Puerto Rico' },
  '+974': { code: 'QA', ru: 'Катар', native: 'Qatar' },
  '+378': {
    code: 'SM',
    ru: 'Республика Сан Марино',
    native: 'Republic of San Marino'
  },
  '+262': { code: 'RE', ru: 'Реоньон', native: 'Reunion' },
  '+40': { code: 'RO', ru: 'Румыния', native: 'Romania' },
  '+7': { code: 'RU', ru: 'Россия', native: 'Russia' },
  '+250': { code: 'RW', ru: 'Республика Руанда', native: 'Rwandese Republic' },
  '+247': {
    code: 'SH',
    ru: 'Остров Святой Елены',
    native: 'Saint Helena and Ascension Island'
  },
  '+508': {
    code: 'PM',
    ru: 'Сен-Пьер и Микелон',
    native: 'Saint Pierre et Miquelon'
  },
  '+239': {
    code: 'ST',
    ru: 'Сан-Томе и Принсипи',
    native: 'Sao Tome e Principe'
  },
  '+966': { code: 'SA', ru: 'Саудовская Аравия', native: 'Saudi Arabia' },
  '+221': { code: 'SN', ru: 'Сенегал', native: 'Senegal' },
  '+248': { code: 'SC', ru: 'Сейшельские Острова', native: 'Seychelles' },
  '+232': { code: 'SL', ru: 'Сьерра Леоне', native: 'Sierra Leone' },
  '+65': { code: 'SG', ru: 'Сингапур', native: 'Singapore' },
  '+421': { code: 'SK', ru: 'Словакия', native: 'Slovakia' },
  '+386': { code: 'SI', ru: 'Словения', native: 'Slovenia' },
  '+677': { code: 'SB', ru: 'Соломоновы острова', native: 'Solomon Islands' },
  '+252': { code: 'SO', ru: 'Сомали', native: 'Somalia' },
  '+27': { code: 'ZA', ru: 'ЮАР', native: 'South Africa' },
  '+82': { code: 'KR', ru: 'Южная Корея', native: 'South Korea' },
  '+969': { code: 'YE', ru: 'Южный Йемен', native: 'South Yemen' },
  '+34': { code: 'ES', ru: 'Испания', native: 'Spain' },
  '+94': { code: 'LK', ru: 'Шри Ланка', native: 'Sri Lanka' },
  '+1-869': {
    code: 'KN',
    ru: 'Сент-Китс и Невис',
    native: 'St.Kitts and Nevis'
  },
  '+1-758': { code: 'LC', ru: 'Сент-Люсия', native: 'St.Lucia' },
  '+1-784': {
    code: 'VC',
    ru: 'Сент-Винсент и Гренадины',
    native: 'St.Vincent and the Grenadines'
  },
  '+249': { code: 'SD', ru: 'Судан', native: 'Sudan' },
  '+597': { code: 'SR', ru: 'Суринам', native: 'Suriname' },
  '+268': { code: 'SZ', ru: 'Свазиленд', native: 'Swaziland' },
  '+46': { code: 'SE', ru: 'Швеция', native: 'Sweden' },
  '+41': { code: 'CH', ru: 'Швейцария', native: 'Switzerland' },
  '+963': { code: 'SY', ru: 'Сирия', native: 'Syria' },
  '+992': { code: 'TJ', ru: 'Таджикистан', native: 'Ta(d)jikistan' },
  '+886': { code: 'TW', ru: 'Тайвань', native: 'Taiwan' },
  '+255': { code: 'TZ', ru: 'Танзания', native: 'Tanzania' },
  '+66': { code: 'TH', ru: 'Тайланд', native: 'Thailand' },
  '+228': { code: 'TG', ru: 'Того', native: 'Togolese Republic' },
  '+690': { code: 'TK', ru: 'Токелау', native: 'Tokelau' },
  '+676': { code: 'TO', ru: 'Тонго', native: 'Tonga' },
  '+1-868': {
    code: 'TT',
    ru: 'Тринидад и Тобаго',
    native: 'Trinidad and Tobago'
  },
  '+90': { code: 'TR', ru: 'Турция', native: 'Turkey' },
  '+993': { code: 'TM', ru: 'Туркменистан', native: 'Turkmenistan' },
  '+1-649': {
    code: 'TC',
    ru: 'Теркс и Кайкос',
    native: 'Turks & Caicos Islands'
  },
  '+688': { code: 'TV', ru: 'Тувалу', native: 'Tuvalu' },
  '+1-340': {
    code: 'VI',
    ru: 'Виргинские острова США',
    native: 'US Virgin Islands'
  },
  '+256': { code: 'UG', ru: 'Уганда', native: 'Uganda' },
  '+380': { code: 'UA', ru: 'Украина', native: 'Ukraine' },
  '+971': { code: 'AE', ru: 'О.А.Э.', native: 'United Arab Emirates' },
  '+44': { code: 'GB', ru: 'Великобритания', native: 'United Kingdom' },
  '+598': { code: 'UY', ru: 'Уругвай', native: 'Uruguay' },
  '+1': { code: 'US', ru: 'США', native: 'USA' },
  '+998': { code: 'UZ', ru: 'Узбекистан', native: 'Uzbekistan' },
  '+678': { code: 'VU', ru: 'Вануату', native: 'Vanuatu' },
  '+39': { code: 'VA', ru: 'Ватикан', native: 'Vatican City' },
  '+58': { code: 'VE', ru: 'Венесуэла', native: 'Venezuela' },
  '+84': { code: 'VN', ru: 'Вьетнам', native: 'Vietnam' },
  '+681': {
    code: 'WF',
    ru: 'Уоллис и Футуна',
    native: 'Wallis and Futuna Islands'
  },
  '+685': { code: 'WS', ru: 'Западное Самоа', native: 'Western Samoa' },
  '+381': { code: 'RS', ru: 'Сербия', native: 'Yugoslavia' },
  '+243': { code: 'CD', ru: 'Заир', native: 'Zaire' },
  '+260': { code: 'ZM', ru: 'Замбия', native: 'Zambia' },
  '+263': { code: 'ZW', ru: 'Зимбабве', native: 'Zimbabwe' },

  '+93': { code: 'AF', ru: 'Афганистан', native: 'Afghanistan' },
  '+355': { code: 'AL', ru: 'Албания', native: 'Albania' },
  '+21': { code: 'DZ', ru: 'Алжир', native: 'Algeria' },
  '+684': { code: 'AS', ru: 'Американское Самоа', native: 'American Samoa' },
  '+376': { code: 'AD', ru: 'Андорра', native: 'Andorra' },
  '+244': { code: 'AO', ru: 'Ангола', native: 'Angola' },
  '+1-264': { code: 'AI', ru: 'Ангилья', native: 'Anguilla' },
  '+1-268': {
    code: 'AG',
    ru: 'Антигуа и Барбуда',
    native: 'Antigua and Barbuda'
  },
  '+374': { code: 'AM', ru: 'Армения', native: 'Armenia' },
  '+54': { code: 'AR', ru: 'Аргентина', native: 'Argentina' },
  '+61': { code: 'AU', ru: 'Австралия', native: 'Australia' },
  '+43': { code: 'AT', ru: 'Австрия', native: 'Austria' },
  '+994': { code: 'AZ', ru: 'Азербайджан', native: 'Azerbaijan' },
  '+1-242': { code: 'BS', ru: 'Багамы', native: 'Bahamas' },
  '+973': { code: 'BH', ru: 'Бахрейн', native: 'Bahrain' },
  '+880': { code: 'BD', ru: 'Бангладеш', native: 'Bangladesh' },
  '+1-246': { code: 'BB', ru: 'Барбадос', native: 'Barbados' },
  '+375': { code: 'BY', ru: 'Белоруссия', native: 'Belarus' },
  '+32': { code: 'BE', ru: 'Бельгия', native: 'Belgium' },
  '+501': { code: 'BZ', ru: 'Белиз', native: 'Belize' },
  '+229': { code: 'BJ', ru: 'Бенин', native: 'Benin' },
  '+1-441': { code: 'BM', ru: 'Бермудские острова', native: 'Bermuda' },
  '+591': { code: 'BO', ru: 'Боливия', native: 'Bolivia' },
  '+387': {
    code: 'BA',
    ru: 'Босния и Герцеговина',
    native: 'Bosnia and Herzegovina'
  },
  '+267': { code: 'BW', ru: 'Ботсвана', native: 'Botswana' },
  '+55': { code: 'BR', ru: 'Бразилия', native: 'Brazil' },
  '+1-284': {
    code: 'VG',
    ru: 'Британские Виргинские острова',
    native: 'British Virgin Islands'
  },
  '+673': { code: 'BN', ru: 'Бруней Даруэсалаам', native: 'Brunei Darusalaam' },
  '+359': { code: 'BG', ru: 'Болгария', native: 'Bulgaria' },
  '+226': { code: 'BF', ru: 'Буркина Фасо', native: 'Burkina Faso' },
  '+257': { code: 'BI', ru: 'Бурунди', native: 'Burundi' },
  '+855': { code: 'KH', ru: 'Камбоджа', native: 'Cambodia' },
  '+237': { code: 'CM', ru: 'Камерун', native: 'Cameroon' },
  '+238': { code: 'CV', ru: 'Капе Верде', native: 'Cape Verde' },
  '+1-345': { code: 'KY', ru: 'Каймановы острова', native: 'Cayman Islands' },
  '+236': {
    code: 'CF',
    ru: 'Центрально африканская республика',
    native: 'Central African Republic'
  },
  '+235': { code: 'TD', ru: 'Чад', native: 'Chad' },
  '+56': { code: 'CL', ru: 'Чили', native: 'Chile' },
  '+86': { code: 'CN', ru: 'Китай', native: 'China' }
}

export function getCountryCodeByPhonePrefix(phoneNumber: string): {
  code: string
  ru: string
  native: string
} {
  const prefix = Object.keys(phonePrefixToCountryCode).find(key =>
    phoneNumber.startsWith(key)
  )

  if (prefix) {
    return phonePrefixToCountryCode[prefix] ?? { code: '', ru: '', native: '' }
  }

  return { code: '', ru: '', native: '' }
}

export function getPhonePrefixByCountryCode(countryCode: string): string {
  console.log('countryCode', countryCode)
  return (
    Object.keys(phonePrefixToCountryCode).find(
      key => phonePrefixToCountryCode[key]?.code === countryCode
    ) || ''
  )
}
