import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetCostDataProps, TResPrograms } from '../types'
import fetchPsychologyWeekendGroup from './fetchPsychologyWeekendGroup'

export const fetchPrograms = async ({ context }: TGetCostDataProps) => {
  const { currentProgramSlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  if (!currentProgramSlug) return null

  const query = qs.stringify({
    fields: ['name'],
    populate: {
      category: {
        fields: ['name', 'label', 'slug']
      },
      faculty: {
        fields: ['slug']
      },
      cost: {
        fields: [
          'price',
          'discount',
          'studyDurationMonths',
          'studyDurationYears',
          'discountUntil',
          'closestEnrollment'
        ]
      }
    },
    filters: {
      slug: {
        $eq: currentProgramSlug
      }
    },
    locale: context.locale
  })

  const res = await fetcher<TResPrograms>(
    `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
  )

  // Получаем цены для Психология группа выходного дня
  let additionalСost
  if (res) {
    additionalСost = await fetchPsychologyWeekendGroup(res)
  }

  if (res?.data && res.data[0] && res.data[0]?.cost && additionalСost) {
    res.data[0].cost.additionalСost = additionalСost
  }

  return res?.data || null
}

export default fetchPrograms
