import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  Div,
  Ul,
  Li,
  useMedia
} from 'fractals'
import { Layout, slugify } from 'ui'
import { Shared } from 'modules'
import { IDS } from 'modules/program-page'
import {
  TLeastProgramContentsProps,
  DESCRIPTION,
  FORM_NAME,
  DEFAULT_HEADING
} from './fractals'

const LeastProgramContents = ({
  cost,
  program,
  heading,
  colorVariant,
  leastProgramContents
}: TLeastProgramContentsProps) => {
  const [description, formName, defaultHeading] = useTranslations(
    DESCRIPTION,
    FORM_NAME,
    DEFAULT_HEADING
  )

  const { isLaptop, isDesktop } = useMedia()

  return (
    <Section
      id={slugify(heading)}
      spacingVariant='beta'
      colorsVariant='alpha'
      colorVariant={colorVariant}>
      <Container>
        <H2
          variant='beta'
          styles={{
            maxWidth: ((isDesktop || isLaptop) && '15ch') || undefined
          }}>
          {heading}
        </H2>
        <Layout.Flex
          cols={(isDesktop && 2) || 1}
          proportion={(isDesktop && '3:2') || undefined}
          gap={toUnit((isDesktop && 32) || (isLaptop && 58) || 40)}
          styles={{
            '& > *': {
              display: 'flex'
            }
          }}>
          {leastProgramContents && leastProgramContents.length !== 0 && (
            <Layout.FlexItem>
              <Div
                styles={{
                  paddingTop: toUnit(((isDesktop || isLaptop) && 32) || 24),
                  paddingRight: toUnit(((isDesktop || isLaptop) && 32) || 24),
                  paddingBottom: toUnit(((isDesktop || isLaptop) && 32) || 24),
                  paddingLeft: toUnit(((isDesktop || isLaptop) && 32) || 24),
                  borderRadius: toUnit(16),
                  backgroundColor: 'var(--alpha)',
                  color: 'var(--omega)'
                }}>
                <P variant='iota'>{description}</P>
                <Ul variant='card'>
                  {leastProgramContents.map((item, idx) => (
                    // <Div styles={{ wordWrap: 'break-word' }}>
                    <Li
                      styles={{ wordBreak: 'break-word' }}
                      variant='gamma'
                      key={`LeastProgramCOntents__${item}--${idx + 1}`}>
                      {item}
                    </Li>
                    // </Div>
                  ))}
                </Ul>
              </Div>
            </Layout.FlexItem>
          )}
          <Layout.FlexItem>
            <Shared.LeadForm
              cost={cost}
              id={IDS.formLeastProgramContents}
              formName={formName}
              program={program}
              name
              phoneNumber
              email
              styleVariant='alpha'
              heading={defaultHeading}
              colorVariant={colorVariant}
              colorsVariant='alpha'
            />
          </Layout.FlexItem>
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default LeastProgramContents
