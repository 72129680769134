import { CSSProperties, CSSObject } from 'styled-components'
import { THEME } from 'fractals'

type MediaStyle<T extends keyof CSSProperties> = {
  [P in T]: CSSProperties[P]
}

type MediaStyles<T extends keyof CSSProperties> = {
  [P in keyof CSSProperties]?: CSSProperties[P]
} & {
  phone?: Partial<MediaStyle<T>>
  tablet?: Partial<MediaStyle<T>>
  laptop?: Partial<MediaStyle<T>>
  desktop?: Partial<MediaStyle<T>>
}

export const multiMedia = <T extends keyof CSSProperties>(
  styles: MediaStyles<T>
) => {
  const { media: mediaTheme } = THEME

  const mediaStyles: CSSObject = {}

  if (styles.phone) {
    mediaStyles[mediaTheme.phone] = styles.phone
  }
  if (styles.tablet) {
    mediaStyles[mediaTheme.tablet] = styles.tablet
  }
  if (styles.laptop) {
    mediaStyles[mediaTheme.laptop] = styles.laptop
  }
  if (styles.desktop) {
    mediaStyles[mediaTheme.desktop] = styles.desktop
  }

  return {
    ...styles,
    ...mediaStyles
  }
}

export default multiMedia
