import { LOCALES } from 'fractals'

export const TITLE_LICENSE = {
  [LOCALES.en]: 'License',
  [LOCALES.ru]: 'Лицензия',
  [LOCALES.uz]: 'Litsenziya',
  [LOCALES.kk]: 'Лицензия'
} as const

export const TITLE_DIPLOMAS = {
  [LOCALES.en]: 'Documents upon graduation',
  [LOCALES.ru]: 'Документы по окончании обучения',
  [LOCALES.uz]: 'Taʼlimni tugatishidan soʻnggi hujjatlar',
  [LOCALES.kk]: 'Білім алуға аяқталғаннан кейінгі құжаттар'
}

export const DIPLOMA_BACHELOR = {
  [LOCALES.en]: 'Bachelor diploma',
  [LOCALES.ru]: 'Диплом бакалавра',
  [LOCALES.uz]: 'Bakalavr diplomasi',
  [LOCALES.kk]: 'Бакалавр дипломы'
} as const

export const DIPLOMA_MASTER = {
  [LOCALES.en]: 'Master diploma',
  [LOCALES.ru]: 'Диплом магистра',
  [LOCALES.uz]: 'Magistr diplomasi',
  [LOCALES.kk]: 'Магистр дипломы'
} as const

export const DIPLOMA_RETRAINING = {
  [LOCALES.en]: 'Diploma on professional retraining',
  [LOCALES.ru]: 'Диплом о профессиональной переподготовке',
  [LOCALES.uz]: 'Mutaxassislik qayta oʻrgatish diplomasi',
  [LOCALES.kk]: 'Мутахассислик қайта оқыту дипломы'
} as const

export const DIPLOMA_NAME = {
  [LOCALES.en]: 'Diploma',
  [LOCALES.ru]: 'Diploma',
  [LOCALES.uz]: 'Diploma',
  [LOCALES.kk]: 'Diploma'
} as const

export const DIPLOMA_SUPPLEMENT = {
  [LOCALES.en]: 'Supplement',
  [LOCALES.ru]: 'Supplement',
  [LOCALES.uz]: 'Supplement',
  [LOCALES.kk]: 'Supplement'
} as const

export const LICENCE_ALT = {
  [LOCALES.en]: 'License on conducting educational activities',
  [LOCALES.ru]: 'Лицензия на ведение образовательной деятельности',
  [LOCALES.uz]: 'Litsenziya taʼlim faoliyatini amalga oshirish uchun',
  [LOCALES.kk]: 'Лицензия оқу қызметін жүргізу үшін'
} as const

export const CHECK_LICENCE = {
  [LOCALES.en]:
    'Check the license on the website of the Federal Service for Supervision in the Field of Education and Science',
  [LOCALES.ru]:
    'Проверьте лицензию на сайте Федеральной службы по надзору в сфере образования и науки',
  [LOCALES.uz]:
    'Litsenziyaning Federal xizmati taʼlim va ilmiy sahada nazoratda saytida tekshiring',
  [LOCALES.kk]:
    'Лицензияның Федералдық қызметі оқу және ғылыми сағатта назоратта сайтында тексеріңіз'
} as const

export const CHECK_LICENCE_URL =
  'https://islod.obrnadzor.gov.ru/rlic/details/e6d90a44-2d1c-3f1e-38cd-23b05043cdb7/'

export const GRADUATION_DOCUMENTS_INFO = {
  [LOCALES.en]:
    'After successful completion of graduation tests, students receive a document confirming the level of their competencies. Depending on your previous education, you will be issued a diploma on professional retraining, a certificate of professional development or a certificate of completion of training',
  [LOCALES.ru]:
    'После успешного прохождения выпускных испытаний студенты получают документ, подтверждающий уровень их компетенций.В зависимости от предыдущего образования вам будет выдан диплом о профессиональной переподготовке, удостоверение о повышении квалификации или свидетельство о прохождении обучения',
  [LOCALES.uz]:
    'Tugatish sinovlarini muvaffaqiyatli tugatgandan soʻng talabalar kompetensiyalarining darajasi haqida tasdiqlash hujjati oladi. Oldingi taʼlimingiz asosida sizga mutaxassislik qayta oʻrgatish diplomasi, mutaxassislikni oshirish sertifikati yoki taʼlimni tugatish sertifikati beriladi',
  [LOCALES.kk]:
    'Тамаша сынақтарын мүмкіндікті орындауға сәтті болғаннан кейін студенттердің компетенцияларының деңгейін бекітетін құжаттары алынады. Алдыңғы біліміңізге қарай сізге мутахассислик қайта оқыту дипломы, мутахассисликті жоғарту сертификаты немесе оқытуды аяқтау сертификаты беріледі'
} as const
