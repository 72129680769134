import { FC } from 'react'
import { TOptionProps } from './types'
import { StyledElement } from '../StyledElement'
import { ROOT_ELEMENT } from './constants'

export const Option: FC<TOptionProps> = ({ ...props }) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{
        // display: 'inline-block',
        // fontFamily: 'inherit',
        // fontSize: '100%',
        // lineHeight: 1.15,
        // overflow: 'visible',
        // minWidth: 'initial',
        // width: 'initial',
        // maxWidth: 'initial',
        // minHeight: 'initial',
        // height: 'initial',
        // maxHeight: 'initial',
        // paddingTop: 0,
        // paddingRight: 0,
        // paddingBottom: 0,
        // paddingLeft: 0,
        // borderWidth: 0,
        // borderStyle: 'none',
        // borderColor: 'transparent',
        // appearance: 'none',
        // cursor: 'text',
        // WebkitTapHighlightColor: 'transparent',
        ...props.styles
      }}
    />
  )
}

export default Option
