import * as React from 'react'
import { TDivProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Div = React.forwardRef<HTMLDivElement, TDivProps>(({ ...props }, ref) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      ref={ref}
      {...props}
      styles={{ /* DEFAULT_STYLES */ ...props.styles }}
    />
  )
})

export default Div
