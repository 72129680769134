export {
  IMG_LICENCE_NUMBER,
  PROGRAMS,
  FACULTIES,
  ABOUT,
  CONTACT,
  FORM_TITLE,
  POLICY,
  TERMS,
  IMG_LICENCE_ALT
} from './constants'
export { getFooterData } from './api'
export type { TFooterProps } from './types'
export { IconGlobe, Subscriber } from './components'
