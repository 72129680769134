import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetVideosDataProps } from '../types'
import { TResFaculties } from '../../getFacultiesData/types'
import {
  authorsQuery,
  videoQuery,
  reviewsPopulateVariants,
  videoPopulateVariants
} from '../constants'

export const fetchFacultyVideos = async ({ context }: TGetVideosDataProps) => {
  try {
    const { currentCategorySlug, currentFacultySlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    const query = qs.stringify({
      fields: ['name'],
      populate: {
        authors: authorsQuery,
        video: videoQuery,
        ...reviewsPopulateVariants[currentCategorySlug ?? ''],
        ...videoPopulateVariants[currentCategorySlug ?? '']
      },
      filters: {
        slug: {
          $eq: currentFacultySlug
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResFaculties>(
      `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${query}`
    )

    return res?.data?.at(0) || null
  } catch (e) {
    console.log(e)
    return null
  }
}

export default fetchFacultyVideos
