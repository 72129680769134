import * as React from 'react'
import { TLabelProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Label = ({ ...props }: TLabelProps) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{
        display: 'inline-block',
        fontFamily: 'inherit',
        // display: 'block',
        // overflow: 'hidden',
        // minWidth: '100%',
        // maxWidth: '100%',
        // height: 'auto',
        // paddingTop: 0,
        // paddingRight: 0,
        // paddingBottom: 0,
        // borderStyle: 'none',
        // paddingLeft: 0,
        // fontFamily: 'inherit',
        // fontSize: '100%',
        // lineHeight: 1.15,
        // textAlign: 'center',
        // textTransform: 'none',
        // appearance: 'none',
        // WebkitTapHighlightColor: 'transparent',
        // backgroundColor: 'transparent',
        ...props.styles
      }}
    />
  )
}

export default Label
