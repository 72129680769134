import { Accordion, AccordionProps } from '@mui/material'
import { styled } from '@mui/system'

export const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  '&::before': {
    display: 'none'
  }
}))
