import { STYLED_PROPS } from './styledProps'

export const KEYS = {
  styledProps: STYLED_PROPS,
  props: {
    id: 'id',
    className: 'className',
    children: 'children',
    containerProps: 'containerProps',
    href: 'href',
    passHref: 'passHref',
    legacyBehavior: 'legacyBehavior',
    isOn: 'isOn',
    h1: 'h1',
    as: 'as',
    styles: 'styles',
    variant: 'variant',
    colorVariant: 'colorVariant',
    costFormatHeading: 'costFormatHeading',
    colorsVariant: 'colorsVariant',
    costFormatHeadingDistance: 'costFormatHeadingDistance',
    costFormatHeadingMixed: 'costFormatHeadingMixed',
    costFormatSubHeading: 'costFormatSubHeading',
    costFormatSubHeadingMixed: 'costFormatSubHeadingMixed',
    bgVariant: 'bgVariant',
    spacingVariant: 'spacingVariant',
    borderRadiusVariant: 'borderRadiusVariant',
    direction: 'direction',
    position: 'position',
    sizeVariant: 'sizeVariant',
    accentColor: 'accentColor',
    formName: 'formName',
    value: 'value',
    placeholder: 'placeholder',
    htmlFor: 'htmlFor',
    type: 'type',
    name: 'name',
    phoneNumber: 'phoneNumber',
    email: 'email',
    promocode: 'promocode',
    childrenVariant: 'childrenVariant',
    styleVariant: 'styleVariant',
    buttonValue: 'buttonValue',
    title: 'title',
    heading: 'heading',
    withImage: 'withImage',
    withJournalImage: 'withJournalImage',
    isSandwich: 'isSandwich',
    isLabel: 'isLabel',
    isPopup: 'isPopup',
    isPopupJournal: 'isPopupJournal',
    isJournal: 'isJournal',
    onSuccess: 'onSuccess',
    selected: 'selected',
    toggleSelect: 'toggleSelect'
  },
  locales: {
    en: 'en',
    ru: 'ru',
    uz: 'uz',
    kk: 'kk'
  },
  selectors: {
    delimiters: {
      id: '#',
      className: '.'
    },
    // ids: {
    //   header: 'header',
    //   main: 'main',
    //   footer: 'footer',
    //   googleTagManager: 'google-tag-manager'
    // },
    classNames: {}
  }
} as const
