/* eslint-disable import/extensions */
import React, { createContext, useContext, useMemo, useState } from 'react'
import { type YandexEcommerce } from '@/fractals/hooks/useYandexEcommerce'

interface YandexEcommerceDataContextProps {
  YandexEcommerce: YandexEcommerce | null
  setYandexEcommerce: (YandexEcommerce: YandexEcommerce | null) => void
}

const YandexEcommerceDataContext = createContext<
  YandexEcommerceDataContextProps | undefined
>(undefined)

export const useYandexEcommerceData = (): YandexEcommerceDataContextProps => {
  const context = useContext(YandexEcommerceDataContext)
  if (!context) {
    throw new Error(
      'useYandexEcommerceData must be used within a YandexEcommerceDataProvider'
    )
  }
  return context
}

interface YandexEcommerceDataProviderProps {
  children: React.ReactNode
}

export const YandexEcommerceDataProvider: React.FC<
  YandexEcommerceDataProviderProps
> = ({ children }) => {
  const [YandexEcommerce, setYandexEcommerce] =
    useState<YandexEcommerce | null>(null)

  const value = useMemo(
    () => ({ YandexEcommerce, setYandexEcommerce }),
    [YandexEcommerce]
  )

  return (
    <YandexEcommerceDataContext.Provider value={value}>
      {children}
    </YandexEcommerceDataContext.Provider>
  )
}
