/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React, {
  // createElement,
  useContext,
  useEffect,
  // useMemo,
  useState
} from 'react'
import { ModalStylesCtx } from '../../../contexts'
import { TypeModalBackgrounfOverlayMobileProps } from './fractals/types'

const ModalBackgroundOverlayMobile = ({
  show,
  duration = '300ms',
  modalIsSwiping = false,
  opacityRatio = 1,
  ...props
}: TypeModalBackgrounfOverlayMobileProps) => {
  const {
    options: {
      withBackgroundBlurOverlay,
      backgroundOverlayBlur,
      backgroundOverlayColor
    }
  } = useContext(ModalStylesCtx)

  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    setOpacity(prev => {
      if (modalIsSwiping) {
        return opacityRatio
      }

      if (opacityRatio === 1 && !show) {
        return prev
      }

      return 1
    })
  }, [modalIsSwiping, opacityRatio, show])

  return (
    <>
      <style>
        {`
          [data-background-overlay] {
            will-change: opacity${
              withBackgroundBlurOverlay ? ' backdrop-filter' : ''
            };
            position: absolute;
            background-color: ${backgroundOverlayColor};
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        `}
      </style>
      <div
        title='Нажмите чтобы закрыть'
        data-background-overlay
        style={{
          opacity: show ? opacity : 0,
          // TODO: remove the eslint-disable line and fix the error
          backdropFilter: withBackgroundBlurOverlay
            ? `blur(${parseFloat(backgroundOverlayBlur!) * opacity})` // eslint-disable-line @typescript-eslint/no-non-null-assertion
            : undefined,
          transition: !modalIsSwiping
            ? `opacity${
                withBackgroundBlurOverlay ? ' backdrop-filter' : ''
              } ${duration} ease-in-out`
            : undefined
        }}
        {...props}
      />
    </>
  )
}

ModalBackgroundOverlayMobile.displayName = 'ModalBackgroundOverlayMobile'

export default ModalBackgroundOverlayMobile
