export const Dictionary: any = {
  a: 'ф',
  b: 'и',
  c: 'с',
  d: 'в',
  e: 'у',
  f: 'а',
  g: 'п',
  h: 'р',
  i: 'ш',
  j: 'о',
  k: 'л',
  l: 'д',
  m: 'ь',
  n: 'т',
  o: 'щ',
  p: 'з',
  q: 'й',
  r: 'к',
  s: 'ы',
  t: 'е',
  u: 'г',
  v: 'м',
  w: 'ц',
  x: 'ч',
  y: 'н',
  z: 'я',
  ',': 'б',
  '.': 'ю',
  '/': '.',
  "'": 'э',
  ';': 'ж',
  '[': 'х',
  ']': 'ъ'
}
