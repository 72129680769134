import qs from 'qs'
import { PATHS, fetcher } from 'fractals'
import { TGetFacultiesDataProps, TResFaculties } from './types'

export const getFacultiesData = async ({
  context,
  amount = 6,
  facultiesToShow
}: TGetFacultiesDataProps) => {
  try {
    const queryFaculties = qs.stringify(
      {
        ...((facultiesToShow && { facultiesToShow }) || {
          facultiesToShow: { default: amount }
        }),
        fields: ['name', 'slug', 'color'],
        populate: {
          image: {
            fields: ['url']
          },
          categories: {
            fields: ['name', 'slug', 'description']
          },
          programs: {
            fields: ['name', 'slug'],
            populate: {
              category: {
                fields: ['slug']
              }
            }
          }
        },
        locale: context.locale
      },
      {
        encodeValuesOnly: true
      }
    )

    const faculties = await fetcher<TResFaculties>(
      `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${queryFaculties}`
    )

    return faculties?.data || null
  } catch (e) {
    console.log('%cgetFacultiesData.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default getFacultiesData
