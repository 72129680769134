type TypeKeyframesProps = {
  name: string
  keyframes: Record<string, React.CSSProperties | string>
}

const toCss = (cssObject: React.CSSProperties | string) =>
  typeof cssObject === 'string'
    ? cssObject
    : Object.entries(cssObject).reduce((accumulator, rule) => {
        const cssKey = rule[0].replace(/[A-Z]/g, v => `-${v.toLowerCase()}`)
        // eslint-disable-next-line quotes
        const cssValue = rule[1]?.toString().replace("'", '')
        return `${accumulator}${cssKey}:${cssValue};`
      }, '')

export const getKeyframes = ({ name, keyframes }: TypeKeyframesProps) => {
  const cssRules =
    keyframes &&
    Object.entries(keyframes).map(([key, value]) => {
      if (/_\d{1,3}/.test(key)) {
        const numberKey = (key as string).match(/\d{1,3}/)

        return `${numberKey}% { ${toCss(value)} }`
      }
      return `${key} { ${toCss(value)} }`
    })

  const css = `@keyframes ${name} {
    ${cssRules?.join('\n')}
  }`

  return css
}

export default getKeyframes
