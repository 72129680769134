import React from 'react'
import {
  H1,
  IconClock,
  P,
  media,
  multiMedia,
  toUnit,
  Image,
  useMedia,
  PATHS
} from 'fractals'
import { Div } from 'ui/modules/styled-jsx/widgets'
import { useRouter } from 'next/router'
import { TJournalCardMainSectionProps } from './types'
import { CurrentTag } from '@/modules/journals-page/widgets/Journals/fractals/components/molecules'
import { AuthorBadge } from '../AuthorBadge'
import { Social } from '../Social'

export const JournalCardMainSection = ({
  name,
  description,
  createdDate,
  time,
  faculty,
  tag,
  author,
  slug,
  category,
  image
}: TJournalCardMainSectionProps) => {
  const { isDesktop, isLaptop, isTablet, isMobile } = useMedia()

  const router = useRouter()
  const { asPath } = router

  return (
    <Div
      styles={{
        display: 'flex',
        flexDirection: 'column',
        height: '40%',
        marginTop: toUnit(16)
      }}>
      <Div
        styles={{
          display: 'flex',
          flexDirection: 'row',
          gap: toUnit(32),
          alignItems: 'center',
          ...multiMedia({
            desktop: {
              marginBottom: '56px'
            },
            laptop: {
              marginBottom: '56px'
            },
            tablet: {
              marginBottom: '48px'
            },
            phone: {
              marginBottom: '40px'
            }
          })
        }}>
        <link
          itemProp='mainEntityOfPage'
          href={`${PATHS.front.root.index}${asPath}`}
        />

        <link itemProp='image' href={image?.url as string} />

        <meta itemProp='headline name' content={name as string} />

        {description && <meta itemProp='description' content={description} />}

        <meta itemProp='author' content={author?.name as string} />
        <meta
          itemProp='datePublished'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dateTime={createdDate as string}
          content={createdDate as string}
        />
        {/* {faculty.data?.attributes.name && (
          <Layout.FlexItem
            styles={{
              borderRadius: toUnit(8),
              fontSize: toUnit(12),
              fontWeight: 400,
              lineHeight: '120%',
              width: 'fit-content',
              backgroundColor:
                (faculty.data?.attributes.color &&
                  Color(faculty.data?.attributes.color).rgb().string()) ||
                'var(--beta)',
              color: 'var(--alpha)'
            }}>
            <Span
              styles={{
                display: 'inline-block',
                paddingTop: toUnit(8),
                paddingRight: toUnit(16),
                paddingBottom: toUnit(8),
                paddingLeft: toUnit(16)
              }}>
              {faculty.data?.attributes.name}
            </Span>
          </Layout.FlexItem>
        )} */}
        {(faculty?.name || tag?.name) && (
          <CurrentTag
            faculty={
              faculty?.tagImage && {
                tagImage: faculty?.tagImage
              }
            }
            tag={
              tag?.tagImage && {
                tagImage: tag?.tagImage
              }
            }
          />
        )}
        <Div
          styles={{
            width: '100%',
            height: '50%',
            display: 'flex',
            gap: '0.5rem'
          }}>
          {createdDate && (
            <P
              variant='xi'
              styles={{
                color: 'var(--omega)',
                marginRight: '1rem'
              }}>
              {createdDate?.slice(0, 10)?.split('-')?.reverse()?.join('.')}
            </P>
          )}
          <IconClock
            styles={{
              '& svg > path': {
                stroke: 'black',
                fill: 'black'
              }
            }}
          />
          {time && (
            <P
              styles={{
                color: 'var(--omega)'
              }}
              variant='xi'>
              {String(`${time?.split(':')[1]} мин`)}
            </P>
          )}
        </Div>
      </Div>
      <H1 id='journal-header' variant='alphaA'>
        {name}
      </H1>
      <Div
        styles={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          ...multiMedia({
            desktop: {
              marginBottom: '56px'
            },
            laptop: {
              marginBottom: '32px'
            },
            tablet: {
              marginBottom: '48px'
            },
            phone: {
              marginBottom: '24px'
            }
          })
        }}>
        {author && <AuthorBadge author={author} />}

        {!isMobile && (
          <Social
            journalSlug={slug && slug}
            facultySlug={faculty?.slug ? faculty?.slug : ''}
            tagSlug={tag?.slug ? tag?.slug : ''}
            categorySlug={category?.slug ? category?.slug : ''}
          />
        )}
      </Div>
      {isMobile && (
        <Social
          isLeftSide
          journalSlug={slug && slug}
          facultySlug={faculty?.slug ? faculty?.slug : ''}
          tagSlug={tag?.slug ? tag?.slug : ''}
          categorySlug={category?.slug ? category?.slug : ''}
        />
      )}
      <Div
        styles={{
          height: isDesktop
            ? '400px'
            : `${isTablet || isLaptop ? '320px' : '171px'}`,
          ...media('width', '800px', {
            laptop: '592px',
            tablet: '640px',
            phone: '343px'
          }),
          minWidth: '-webkit-fill-available',
          position: 'relative'
        }}>
        <Image
          fill
          sizes='(max-width: 768px) 50vw, 75vw'
          quality={50}
          src={image?.url || '/'}
          alt={image?.alternativeText || ''}
          containerProps={{
            styles: {
              borderRadius: '16px',
              '& img': {
                objectFit: 'cover'
              }
            }
          }}
          colorVariant='transparent'
        />
      </Div>
    </Div>
  )
}

export default JournalCardMainSection
