import { Section, Container, H2, Button, PATHS } from 'fractals'
import { Fragment } from 'react'
import { useRouter } from 'next/router'
import { TJournalProps } from './fractals'
import {
  JournalsCardFull,
  JournalsFilterButtons
} from '@/modules/journals-page/widgets/Journals/fractals/components/molecules'
import { styles } from '../styles'

const Journal = ({
  id,
  journals,
  allJournals,
  facultiesFromJournals,
  tags
}: TJournalProps) => {
  const title = 'Журнал'
  const router = useRouter()

  return (
    <Section id={id} spacingVariant='beta'>
      <Container
        styles={{
          display: 'flex',
          flexWrap: 'wrap',
          boxSizing: 'border-box'
        }}>
        <H2
          styles={{
            flexBasis: '100%'
          }}
          variant='journalTitle'>
          {title}
        </H2>
        <JournalsFilterButtons
          atHome
          allJournals={allJournals}
          faculties={facultiesFromJournals}
          tags={tags}
        />
        {journals?.length &&
          journals?.map(
            (
              {
                name,
                description,
                slug,
                createdDate,
                time,
                image,
                faculty,
                category,
                tag
              },
              idx
            ) => (
              <Fragment key={`JournalsCardFull__Fragment-${idx + 1}`}>
                <JournalsCardFull
                  variant='atHome'
                  idx={idx}
                  name={name}
                  description={description}
                  slug={slug}
                  createdDate={createdDate}
                  time={time}
                  faculty={faculty}
                  category={category}
                  tag={tag}
                  image={image}
                />
              </Fragment>
            )
          )}
        <Button
          onClick={() => router.push(`${PATHS.front.journal.ru}`)}
          styles={styles.button}
          colorsVariant='omegaReverse'>
          Все направления
        </Button>
      </Container>
    </Section>
  )
}

export default Journal
