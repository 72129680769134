import { TGetDiplomasDataProps } from './types'
import {
  fetchPrograms,
  normalizeProgramsData,
  fetchDiplomas,
  normalizeDiplomasData
} from './utils'

export const getDiplomasData = async ({ context }: TGetDiplomasDataProps) => {
  try {
    const programsData = await fetchPrograms({ context })

    const programsDataNormalized = normalizeProgramsData({ programsData })

    const diplomasData = await fetchDiplomas({ context })

    const diplomasDataNormalized = normalizeDiplomasData({
      diplomasData
    })

    return programsDataNormalized || diplomasDataNormalized
  } catch (e) {
    console.log('%cgetDiplomasData.ts line:13 e', 'color: #007acc;', e)
  }
  return null
}

export default getDiplomasData
