import * as React from 'react'
import { NextWrappers } from 'ui'
import { BASE64PIXELS, toUnit } from 'fractals'
import { TImageProps } from './types'

const Image = ({
  colorVariant,
  bgVariant,
  borderRadiusVariant,
  ...props
}: TImageProps) => {
  const borderRadiusVariants = {
    circle: '50%',
    alpha: toUnit(16),
    beta: toUnit(8)
  } as const

  const styles = {
    borderRadius:
      (borderRadiusVariant && borderRadiusVariants[borderRadiusVariant]) ||
      undefined,
    aspectRatio: (borderRadiusVariant === 'circle' && '1 / 1') || undefined,
    backgroundColor: (bgVariant && `var(--${bgVariant})`) || undefined,
    ...props.containerProps?.styles
  } as const

  return (
    <NextWrappers.Image
      {...props}
      blurDataURL={BASE64PIXELS[colorVariant]}
      containerProps={{ ...props.containerProps, styles }}
    />
  )
}

export default Image
