import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetSearchProgramsDataProps, TResPrograms } from '../types'

export const fetchProgramsList = async ({
  context
}: TGetSearchProgramsDataProps) => {
  const query = qs.stringify({
    populate: {
      categories: {
        fields: ['name', 'slug', 'rank', 'label']
      }
    },
    sort: ['rank:asc'],
    locale: context.locale
  })

  const res = await fetcher<TResPrograms>(
    `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
  )

  return res?.data || null
}

export default fetchProgramsList
