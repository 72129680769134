import { TGetHeroDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getHeroData = async ({ context }: TGetHeroDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getHeroData
