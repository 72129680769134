/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React, { /* createElement, */ useContext /* , useMemo */ } from 'react'
import { ModalStylesCtx } from '../../../contexts'
import { getKeyframes } from '../../../utils'
import { TypeModalBackgrounfOverlayDesktopProps } from './fractals'

const ModalBackgroundOverlayDesktop = ({
  show,
  duration = '300ms',
  ...props
}: TypeModalBackgrounfOverlayDesktopProps) => {
  const {
    options: {
      withBackgroundBlurOverlay,
      backgroundOverlayBlur,
      backgroundOverlayColor
    }
  } = useContext(ModalStylesCtx)

  return (
    <>
      <style>
        {getKeyframes({
          name: 'showOverlay',
          keyframes: {
            _0: {
              opacity: 0,
              ...(withBackgroundBlurOverlay
                ? {
                    backdropFilter: `blur(0px)`
                  }
                : {})
            },
            _50: {
              opacity: 0.8
            },
            _100: {
              opacity: 1,
              ...(withBackgroundBlurOverlay
                ? {
                    backdropFilter: `blur(${backgroundOverlayBlur})`
                  }
                : {})
            }
          }
        })}
        {getKeyframes({
          name: 'hideOverlay',
          keyframes: {
            _0: {
              opacity: 1,
              ...(withBackgroundBlurOverlay
                ? {
                    backdropFilter: `blur(${backgroundOverlayBlur})`
                  }
                : {})
            },
            _50: {
              opacity: 0.8
            },
            _100: {
              opacity: 0,
              ...(withBackgroundBlurOverlay
                ? {
                    backdropFilter: 'blur(0)'
                  }
                : {})
            }
          }
        })}
      </style>
      <div
        title='Нажмите чтобы закрыть'
        style={{
          animationName: show ? 'showOverlay' : 'hideOverlay',
          animationDuration: duration,
          animationFillMode: 'forwards',
          position: 'absolute',
          backgroundColor: backgroundOverlayColor,
          cursor: 'pointer',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
        {...props}
      />
    </>
  )
}

ModalBackgroundOverlayDesktop.displayName = 'ModalBackgroundOverlayDesktop'

export default ModalBackgroundOverlayDesktop
