import { TGetFacultiesDataProps } from './types'
import { fetchFaculties, normalizeFacultiesData } from './utils'

export const getFacultiesData = async ({ context }: TGetFacultiesDataProps) => {
  const facultiesData = await fetchFaculties({ context })

  const facultiesDataNormalized = normalizeFacultiesData({
    facultiesData,
    generalSlug: context?.params?.general?.toString() || null
  })

  return facultiesDataNormalized
}

export default getFacultiesData
