import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, media, multiMedia } from 'fractals'
import { TH3Props } from './types'
import { VARIANTS_KEYS } from './constants'

const H3 = ({ variant, ...props }: TH3Props) => {
  const stylesVariants = {
    // use this for HomePage Testimonials
    [VARIANTS_KEYS.alpha]: {
      marginBottom: toUnit(16),
      ...media('fontSize', toUnit(40), {
        laptop: toUnit(32),
        desktop: toUnit(48)
      }),
      fontWeight: 500,
      lineHeight: '92.5%'
    },
    [VARIANTS_KEYS.beta]: {
      ...media('marginBottom', toUnit(16), {
        tablet: toUnit(24),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      ...media('fontSize', toUnit(16), {
        tablet: toUnit(20),
        laptop: toUnit(20),
        desktop: toUnit(20)
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.gamma]: {
      ...media('marginBottom', toUnit(24), {
        tablet: toUnit(32),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      fontSize: toUnit(20),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.delta]: {
      ...media('marginBottom', 0, {
        desktop: toUnit(24)
      }),
      fontSize: toUnit(20),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.delta2]: {
      marginBottom: '0',
      ...media('fontSize', toUnit(20), {
        desktop: toUnit(24)
      }),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.epsilon]: {
      marginBottom: 0,
      fontSize: toUnit(20),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.zeta]: {
      marginBottom: 0,
      fontSize: toUnit(36),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.zetaA]: {
      marginBottom: 0,
      ...media('fontSize', '24px', {
        desktop: '36px'
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.journalH3]: {
      marginBottom: 0,
      ...media('fontSize', '20px', {
        desktop: '32px',
        phone: '18px'
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.thanks]: {
      fontSize: '22px',
      fontWeight: 400,
      lineHeight: '120%',
      color: '#1143a7'
    },
    [VARIANTS_KEYS.fullProgramsContent]: {
      fontWeight: 400,
      lineHeight: '100%',
      fontSize: '32px',
      marginBottom: '32px',
      ...multiMedia({
        phone: { fontSize: '24px', marginBottom: '16px' },
        tablet: { fontSize: '24px', marginBottom: '16px' }
      })
    },
    [VARIANTS_KEYS.theta]: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '120%'
    }
  } as const

  const styles = {
    marginTop: 0,
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.H3 {...props} styles={styles} />
}

export default H3
