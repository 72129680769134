import {
  useTranslations,
  Section,
  Container,
  H2,
  toUnit,
  Ul,
  Li,
  P,
  useMedia
} from 'fractals'
import {
  insertWhiteSpacesAfterPrepositions,
  Layout,
  mdToJsx,
  slugify
} from 'ui'
import Color from 'color'
import {
  TWhyUsProps,
  TITLE,
  TESTIMONIAL_1,
  TESTIMONIAL_2,
  TESTIMONIAL_3,
  TESTIMONIAL_4,
  TESTIMONIAL_5,
  TESTIMONIAL_6,
  TESTIMONIAL_7,
  TESTIMONIAL_8,
  ImageWithDecorations
} from './fractals'

const WhyUs = ({ whyUs, colorsVariant, colorVariant }: TWhyUsProps) => {
  const [title] = useTranslations(TITLE)

  const testimonialsDefault = useTranslations(
    TESTIMONIAL_1,
    TESTIMONIAL_2,
    TESTIMONIAL_3,
    TESTIMONIAL_4,
    TESTIMONIAL_5,
    TESTIMONIAL_6,
    TESTIMONIAL_7,
    TESTIMONIAL_8
  )

  const testimonials =
    (whyUs?.whyUs && whyUs?.whyUs?.length !== 0 && whyUs.whyUs) ||
    testimonialsDefault

  const { isTablet, isLaptop, isDesktop } = useMedia()

  return (
    <Section
      id={slugify(title)}
      spacingVariant='delta'
      colorsVariant={colorsVariant || 'beta'}
      styles={{
        overflowX: 'hidden'
      }}>
      <Container>
        <Layout.Flex
          proportion={(isDesktop && '2:1') || (isLaptop && '3:1') || undefined}
          cols={((isDesktop || isLaptop) && 2) || 1}
          gap={toUnit(
            (isDesktop && 64) ||
              (isLaptop && 58) ||
              (isTablet && undefined) ||
              40
          )}>
          <Layout.FlexItem>
            <H2
              variant='gamma'
              styles={{ maxWidth: (isDesktop && '25ch') || undefined }}>
              {title}
            </H2>
            <Ul variant='alpha'>
              {testimonials?.map((testimonial, idx) => (
                <Li
                  variant='alpha'
                  colorVariant={
                    (colorVariant?.color &&
                      Color(colorVariant?.color).rgb().string()) ||
                    undefined
                  }
                  key={`WhyUs__testimonials__testimonial--${idx + 1}`}>
                  <P
                    variant='delta'
                    styles={{
                      maxWidth: (isLaptop && '38ch') || undefined
                    }}>
                    {testimonial &&
                      mdToJsx(insertWhiteSpacesAfterPrepositions(testimonial), {
                        replace: (isLaptop && ['br', 'p']) || ['p']
                      })}
                  </P>
                </Li>
              ))}
            </Ul>
          </Layout.FlexItem>
          {!isTablet && (
            <Layout.FlexItem
              styles={{
                display: (isLaptop && 'flex') || undefined,
                alignItems: (isLaptop && 'flex-end') || undefined
              }}>
              <ImageWithDecorations />
            </Layout.FlexItem>
          )}
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default WhyUs
