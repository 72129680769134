import styled, { keyframes } from 'styled-components'

export const StyledCirclesWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 150%;
  height: 150%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
`

type TStyledCircleProps = {
  left?: boolean
  right?: boolean
}
const animatedStyledCircle = ({ left, right }: TStyledCircleProps) => keyframes`
  from {
    left: ${left && '-50%'};
    right: ${right && '-50%'};
  }
  to {
    left: ${left && '0'};
    right: ${right && '0'};
  }
`
export const StyledCircle = styled.div<TStyledCircleProps>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.15;
  border-radius: 50%;
  animation: ${({ left, right }) =>
      animatedStyledCircle({
        left,
        right
      })}
    200ms linear forwards;
`
