import { LOCALES } from 'fractals'

export const IDS = {
  contactPage: 'contact-page',
  contactForm: 'contact-form'
} as const

export const HEADING = {
  [LOCALES.en]: 'Contact Information',
  [LOCALES.ru]: 'Контактная информация',
  [LOCALES.uz]: "Aloqa ma'lumotlari",
  [LOCALES.kk]: 'Байланыс мәліметтері'
} as const

export const CONTACTS = ['moscow', 'almaty', 'tashkent'] as const

export const MOSCOW = {
  [LOCALES.en]: 'Moscow',
  [LOCALES.ru]: 'Москва',
  [LOCALES.uz]: 'Moskva',
  [LOCALES.kk]: 'Москва'
} as const

export const ALMATY = {
  [LOCALES.en]: 'Almaty',
  [LOCALES.ru]: 'Алматы',
  [LOCALES.uz]: 'Almati',
  [LOCALES.kk]: 'Алматы'
} as const

export const TASHKENT = {
  [LOCALES.en]: 'Tashkent',
  [LOCALES.ru]: 'Ташкент',
  [LOCALES.uz]: 'Toshkent',
  [LOCALES.kk]: 'Ташкент'
} as const

export const ADDRESS_LABEL = {
  [LOCALES.en]: 'Address',
  [LOCALES.ru]: 'Адрес',
  [LOCALES.uz]: 'Manzil',
  [LOCALES.kk]: 'Мекенжай'
} as const

export const PHONE_NUMBER_LABEL = {
  [LOCALES.en]: 'Phone number',
  [LOCALES.ru]: 'Контактный телефон',
  [LOCALES.uz]: 'Telefon raqami',
  [LOCALES.kk]: 'Телефон нөмірі'
} as const

export const EMAIL_LABEL = {
  [LOCALES.en]: 'Email',
  [LOCALES.ru]: 'Почтовый адрес',
  [LOCALES.uz]: 'Elektron pochta',
  [LOCALES.kk]: 'Электрон пошта'
} as const

export const IMG_MOSCOW_MAP_ALT = {
  [LOCALES.en]: 'We are on the map of Moscow',
  [LOCALES.ru]: 'Мы на карте Москвы',
  [LOCALES.uz]: 'Moskvadagi xaritada biz',
  [LOCALES.kk]: 'Мы на карте Москвы'
} as const

export const CAMPUS = {
  [LOCALES.en]: 'Campus',
  [LOCALES.ru]: 'Кампус',
  [LOCALES.uz]: 'Kampus',
  [LOCALES.kk]: 'Кампус'
} as const

export const IMG_PEOPLE_STUDYING_ALT = {
  [LOCALES.en]: 'People are studying',
  [LOCALES.ru]: 'Люди обучаются',
  [LOCALES.uz]: 'Oʻquvchilar oʻqiyapti',
  [LOCALES.kk]: 'Люди обучаются'
} as const

export const FORM_TITLE = {
  [LOCALES.en]:
    'Do you have any questions? Get admission committee consultation',
  [LOCALES.ru]: 'Остались вопросы? Получите консультацию приемной комиссии',
  [LOCALES.uz]:
    'Sizda savollar bormi? Qabul komissiyasi konsultatsiyasini oling',
  [LOCALES.kk]: 'Остались вопросы? Получите консультацию приемной комиссии'
} as const

export const FORM_NAME = {
  [LOCALES.en]: 'Do you have any questions. Need consultation',
  [LOCALES.ru]: 'Остались вопросы. Требуется консультация',
  [LOCALES.uz]: 'Sizda savollar bormi. Konsultatsiya talab qilinadi',
  [LOCALES.kk]: 'Остались вопросы. Требуется консультация'
} as const

export const SEO_TITLE = {
  [LOCALES.en]: 'Contacts of Moscow Institute of Technology and Management',
  [LOCALES.ru]: 'Контакты Московского Института Технологий и Управления',
  [LOCALES.uz]: 'Moskva Texnologiya va Boshqaruv Instituti Aloqalaridan',
  [LOCALES.kk]: 'Контакты Московского Института Технологий и Управления'
} as const

export const SEO_DESCRIPTION = {
  [LOCALES.en]:
    'Contacts of MITM.institute: address, route map, phone numbers, email and working hours of the institute',
  [LOCALES.ru]:
    'Контакты MITM.institute: адрес, схема проезда, телефоны для связи, элекронная почта и время работы института',
  [LOCALES.uz]:
    'MITM.institute Aloqalar: manzil, yoʻnalish xaritasi, telefon raqamlari, elektron pochta va institut ish vaqti',
  [LOCALES.kk]:
    'Контакты MITM.institute: адрес, схема проезда, телефоны для связи, элекронная почта и время работы института'
} as const
