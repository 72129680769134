import { IconContainer } from 'fractals'
import { TGlobeProps } from '../../../types'

const Globe = ({ fill, ...props }: TGlobeProps) => (
  <IconContainer {...props}>
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.526367 10.25H6.50802C6.56965 7.37922 6.983 4.76938 7.63683 2.80788C7.89021 2.04774 8.18667 1.36369 8.52648 0.793013C4.15586 1.8484 0.851857 5.63803 0.526367 10.25Z'
        fill={fill}
      />
      <path
        d='M0.526367 11.75C0.851857 16.362 4.15586 20.1516 8.52648 21.207C8.18667 20.6363 7.89021 19.9523 7.63683 19.1921C6.983 17.2306 6.56965 14.6208 6.50802 11.75H0.526367Z'
        fill={fill}
      />
      <path
        d='M13.4735 21.207C17.8441 20.1516 21.1481 16.362 21.4736 11.75H15.492C15.4303 14.6208 15.017 17.2306 14.3632 19.1921C14.1098 19.9523 13.8133 20.6363 13.4735 21.207Z'
        fill={fill}
      />
      <path
        d='M21.4736 10.25C21.1481 5.63803 17.8441 1.8484 13.4735 0.793013C13.8133 1.36369 14.1098 2.04774 14.3632 2.80788C15.017 4.76938 15.4303 7.37922 15.492 10.25H21.4736Z'
        fill={fill}
      />
      <path
        d='M11 0.5C10.8105 0.5 10.5107 0.61451 10.1294 1.09831C9.75477 1.57366 9.38461 2.30797 9.05986 3.28222C8.4665 5.06227 8.06995 7.50534 8.00838 10.25H13.9916C13.93 7.50534 13.5335 5.06227 12.9401 3.28222C12.6154 2.30797 12.2452 1.57366 11.8706 1.09831C11.4893 0.61451 11.1895 0.5 11 0.5Z'
        fill={fill}
      />
      <path
        d='M12.9401 18.7178C13.5335 16.9377 13.93 14.4947 13.9916 11.75H8.00838C8.06995 14.4947 8.4665 16.9377 9.05986 18.7178C9.38461 19.692 9.75477 20.4263 10.1294 20.9017C10.5107 21.3855 10.8105 21.5 11 21.5C11.1895 21.5 11.4893 21.3855 11.8706 20.9017C12.2452 20.4263 12.6154 19.692 12.9401 18.7178Z'
        fill={fill}
      />
    </svg>
  </IconContainer>
)

export default Globe
