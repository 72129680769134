import { Div, toUnit, useMedia } from 'fractals'
import Color from 'color'
import { useTheme } from 'styled-components'
import { TLineProps } from '../../types'

export const Line = ({ colorVariant, last }: TLineProps) => {
  const { isMobile } = useMedia()
  const { colors } = useTheme()
  return (
    <Div
      styles={{
        height: toUnit(1),
        width: '100%',
        backgroundColor:
          (colorVariant?.color && Color(colorVariant?.color).rgb().string()) ||
          colors.beta.rgb().string(),
        marginBottom: toUnit(last ? 0 : (isMobile && 16) || 24)
      }}
    />
  )
}
