import styled from 'styled-components'

export const TagButtonMobile = styled.button<{
  active: boolean
  accentColor?: string
}>`
  cursor: pointer;
  box-sizing: border-box;
  border: ${({ accentColor }) =>
    accentColor ? `1px solid ${accentColor}` : '1px solid #DF6945'};
  font-size: 12px;
  line-height: 14.4px;
  background-color: ${({ active, accentColor }) =>
    active ? accentColor : '#fff '};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border-radius: 8px;
  box-shadow: none;
  height: 30px;
  padding: 8px 16px;
  width: fit-content;
  min-width: fit-content;
  font-family: '__golosTextVF_099e87', '__golosTextVF_Fallback_099e87',
    sans-serif;
  &:hover {
    background-color: #000;
    border-color: #000;
    box-shadow: none;
    color: #fff;
  }
  /* &:active {
    box-shadow: none;
    background-color: #000;
    color: #fff;
  } */
`
