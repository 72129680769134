import createSlugify from 'slugify'
import { FunctionPropTypes } from '../types'

export const slugify = (props: FunctionPropTypes<typeof createSlugify>) =>
  createSlugify(props, {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    strict: true,
    trim: true
  })

export default slugify
