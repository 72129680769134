import { LOCALES } from '../../../constants'

export const CHILDREN_VARIANT_KEYS = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  promocode: 'promocode'
} as const

export const YOUR_NAME = {
  [LOCALES.en]: 'Your name *',
  [LOCALES.ru]: 'Ваше имя *',
  [LOCALES.uz]: 'Sizning ismingiz *',
  [LOCALES.kk]: 'Сіздің атыңыз *'
} as const

export const YOUR_PHONE_NUMBER = {
  [LOCALES.en]: 'Your phone number',
  [LOCALES.ru]: 'Ваш номер телефона',
  [LOCALES.uz]: 'Sizning telefon raqamingiz',
  [LOCALES.kk]: 'Сіздің телефон нөміріңіз'
}

export const YOUR_EMAIL = {
  [LOCALES.en]: 'Your Email *',
  [LOCALES.ru]: 'Ваш Email *',
  [LOCALES.uz]: 'Sizning emailingiz *',
  [LOCALES.kk]: 'Сіздің emailіңіз *'
}

export const YOUR_PROMOCODE = {
  [LOCALES.en]: 'Your Promocode',
  [LOCALES.ru]: 'Ваш промокод',
  [LOCALES.uz]: 'Sizning promocodeingiz',
  [LOCALES.kk]: 'Сіздің promocodeіңіз'
}
