import React from 'react'
import { Div, IconArrow, IconCheck } from '@/fractals/components'
import { IconTick } from '@/fractals/components/atoms/IconTick'
import { SmoothScrollLink } from '../../atoms'

export const UpButton = () => {
  return (
    <SmoothScrollLink targetId='journal-header'>
      <Div
        styles={{
          backgroundColor: 'black',
          borderRadius: '50%',
          minWidth: '48px',
          minHeight: '48px',
          width: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '1rem',
          marginLeft: 'auto',
          marginBottom: '2rem',
          cursor: 'pointer'
        }}>
        <IconTick
          styles={{
            '& > svg > path': {
              stroke: 'var(--alpha)'
            }
          }}
          direction='down'
        />
      </Div>
    </SmoothScrollLink>
  )
}

export default UpButton
