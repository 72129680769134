import {
  useTranslations,
  Section,
  Container,
  H3,
  P,
  toUnit,
  useMedia,
  Span,
  multiMedia
} from 'fractals'
import { Layout, insertWhiteSpacesAfterPrepositions, slugify } from 'ui'
import { IDS } from 'modules/home-page/fractals'
import { Fragment } from 'react'
import {
  TTestimonialsProps,
  TESTIMONIAL_TITLE_YEARS,
  TESTIMONIAL_DESC_YEARS,
  TESTIMONIAL_TITLE_STUDENTS,
  TESTIMONIAL_DESC_STUDENTS,
  TESTIMONIAL_TITLE_YEARS_ONLINE,
  TESTIMONIAL_DESC_YEARS_ONLINE,
  TESTIMONIAL_TITLE_GRADUATES,
  TESTIMONIAL_DESC_GRADUATES
} from './fractals'

const Testimonials = () => {
  const [
    titleYears,
    descYears,
    titleStudents,
    descStudents,
    titleYearsOnline,
    descYearsOnline,
    titleGraduates,
    descGraduates
  ] = useTranslations(
    TESTIMONIAL_TITLE_YEARS,
    TESTIMONIAL_DESC_YEARS,
    TESTIMONIAL_TITLE_STUDENTS,
    TESTIMONIAL_DESC_STUDENTS,
    TESTIMONIAL_TITLE_YEARS_ONLINE,
    TESTIMONIAL_DESC_YEARS_ONLINE,
    TESTIMONIAL_TITLE_GRADUATES,
    TESTIMONIAL_DESC_GRADUATES
  )

  const { isTablet, isLaptop, isDesktop } = useMedia()

  const testimonials = [
    {
      title: titleYears,
      desc: descYears
    },
    {
      title: titleStudents,
      desc: descStudents
    },
    {
      title: titleYearsOnline,
      desc: descYearsOnline
    },
    {
      title: titleGraduates,
      desc: descGraduates
    }
  ]

  return (
    <Section
      id={slugify(
        testimonials.reduce(
          (acc, cur) => cur && `${acc} ${cur.title} ${cur.desc}`,
          ''
        )
      )}
      spacingVariant='gamma'
      colorsVariant='beta'>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <Layout.Flex
          cols={(isDesktop && 4) || ((isLaptop || isTablet) && 2) || 1}
          gap={toUnit(((isDesktop || isLaptop || isTablet) && 32) || 24)}>
          {testimonials.map((testimonial, idx) => (
            <Layout.FlexItem
              key={`${IDS.testimonials}-Testimonials__Testimonial--${idx + 1}`}>
              <H3
                styles={{
                  ...multiMedia({
                    desktop: {
                      transform:
                        (testimonial.title.includes('>') &&
                          'translateX(-30px)') ||
                        ''
                    },
                    laptop: {
                      transform:
                        (testimonial.title.includes('>') &&
                          'translateX(-36px)') ||
                        ''
                    }
                  })
                }}
                variant='alpha'>
                {testimonial.title}
              </H3>
              <P
                variant='delta'
                styles={{
                  color: 'var(--psi)'
                }}>
                {insertWhiteSpacesAfterPrepositions(testimonial.desc)}
              </P>
            </Layout.FlexItem>
          ))}
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default Testimonials
