import {
  Section,
  Container,
  P,
  toUnit,
  Image,
  H2,
  useTranslations,
  Button,
  useMedia
} from 'fractals'
import { Layout, slugify } from 'ui'
import { useTheme } from 'styled-components'
import { useState } from 'react'
import Color from 'color'
import {
  NAME_1,
  DESCRIPTION_1,
  TITLE,
  LABEL,
  BUTTON,
  TTeachersProps
} from './fractals'

const Teachers = ({ teachers, colorVariant }: TTeachersProps) => {
  const [title, label, button] = useTranslations(TITLE, LABEL, BUTTON)

  const { isDesktop, isLaptop, isTablet, isMobile } = useMedia()
  const { colors } = useTheme()

  const [shownTeachers, setShownTeachers] = useState(
    (isMobile && 3) || (isTablet && 6) || 8
  )

  if (!teachers || teachers?.length === 0) return null

  return (
    <Section
      id={slugify(title)}
      spacingVariant='delta'
      colorsVariant='delta'
      styles={{
        ...(isMobile && { paddingTop: toUnit(56) }),
        '*': { boxSizing: 'border-box' }
      }}>
      <Container>
        <H2
          variant='beta'
          styles={{
            ...(!isDesktop && { marginBottom: toUnit(16) })
          }}>
          {title}
        </H2>
        <P
          variant='gamma'
          styles={{
            ...(!isDesktop && { fontSize: toUnit(20) }),
            ...(!isMobile && {
              maxWidth: toUnit(isDesktop ? 696 : 488),
              width: '100%'
            }),
            marginBottom: toUnit((isMobile && 40) || (isTablet && 32) || 56)
          }}>
          {label}
        </P>
        <Layout.Flex
          cols={isMobile ? 1 : (isTablet && 3) || 4}
          gap={toUnit(isMobile ? 40 : 32)}>
          {teachers?.map?.((teacher, idx) => (
            <Layout.FlexItem
              key={`Teachers__renderTeachers__renderTeacher--${idx + 1}`}
              styles={{
                display: idx + 1 > shownTeachers ? 'none' : 'inline-flex',
                flexDirection: 'column'
              }}>
              {teacher?.image?.url && (
                <Image
                  quality={100}
                  colorVariant='transparent'
                  src={teacher?.image?.url}
                  alt={teacher.image?.alternativeText || teacher.name || ''}
                  width={
                    isMobile ? undefined : teacher.image?.width ?? undefined
                  }
                  height={
                    isMobile ? undefined : teacher.image?.height ?? undefined
                  }
                  borderRadiusVariant='alpha'
                  fill={isMobile}
                  containerProps={{
                    styles: {
                      ...(isMobile && { height: toUnit(452) }),
                      position: 'relative',
                      marginBottom: toUnit(isDesktop || isLaptop ? 32 : 16),
                      '& img': { objectFit: 'cover' }
                    }
                  }}
                />
              )}
              <P
                variant='epsilon'
                styles={{
                  fontSize: toUnit(isDesktop ? 24 : 20),
                  marginBottom: toUnit(16)
                }}>
                {teacher.name}
              </P>
              <P
                variant='beta'
                styles={{
                  ...(!isDesktop && { fontSize: toUnit(isMobile ? 16 : 14) }),
                  color: '#4F4F4F'
                }}
                dangerouslySetInnerHTML={{
                  __html: teacher.description || ''
                }}>
                {null}
              </P>
            </Layout.FlexItem>
          ))}
        </Layout.Flex>
        {teachers.length > shownTeachers && (
          <Button
            colorsVariant='transparent'
            onClick={() => setShownTeachers(teachers.length)}
            styles={{
              marginTop: toUnit(40),
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: isMobile ? '100%' : '40%',
              borderColor:
                (colorVariant?.color &&
                  Color(colorVariant?.color).rgb().string()) ||
                colors.beta.rgb().string(),
              color:
                (colorVariant?.color &&
                  Color(colorVariant?.color).rgb().string()) ||
                colors.beta.rgb().string()
            }}>
            {button}
          </Button>
        )}
      </Container>
    </Section>
  )
}

export default Teachers
