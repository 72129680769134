import { NextRouter } from 'next/router'

type TypeHandleLocaleProps = {
  readonly router: NextRouter
}

export const handleLocale = ({ router }: TypeHandleLocaleProps) => {
  const urlParamLocale = router.query.locale

  if (urlParamLocale) {
    sessionStorage.setItem('locale', urlParamLocale.toString())
  }
}

export default handleLocale
