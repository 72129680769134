import * as React from 'react'
import { StyledJSX } from 'ui'
import { useRouter } from 'next/router'
import { LOCALES, toUnit, media, multiMedia } from 'fractals'
import { TH1Props } from './types'
import { VARIANTS_KEYS } from './constants'

const H1 = ({ variant, ...props }: TH1Props) => {
  const { locale } = useRouter()

  // TODO: figure out word wrap
  const stylesVariants = {
    // use this for home page
    [VARIANTS_KEYS.alpha]: {
      ...media('marginBottom', toUnit(24), {
        tablet: toUnit(32),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      ...media('fontSize', toUnit((locale === LOCALES.kk && 30) || 38), {
        tablet: toUnit((locale === LOCALES.kk && 32) || 54),
        laptop: toUnit((locale === LOCALES.kk && 38) || 42),
        desktop: toUnit((locale === LOCALES.kk && 64) || 72)
      }),
      fontWeight: 500,
      lineHeight: '100%'
    },
    // use this for program page
    [VARIANTS_KEYS.beta]: {
      ...media('marginBottom', toUnit(24), {
        tablet: toUnit(32),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      ...media('fontSize', toUnit(40), {
        tablet: toUnit(48),
        laptop: toUnit(48),
        desktop: toUnit(80)
      }),
      fontWeight: 400,
      lineHeight: '94.5%'
    },
    // use this for programs & contact page
    [VARIANTS_KEYS.gamma]: {
      ...media('marginBottom', toUnit(38), {
        tablet: toUnit(32),
        laptop: toUnit(32),
        desktop: toUnit(56)
      }),
      ...media('fontSize', toUnit(40), {
        tablet: toUnit(48),
        laptop: toUnit(48),
        desktop: toUnit(64)
      }),
      fontWeight: 400,
      ...media('lineHeight', '100%', {
        tablet: '120%',
        laptop: '120%',
        desktop: '120%'
      })
    },
    [VARIANTS_KEYS.epsilon]: {
      ...media('marginBottom', toUnit(32), {
        tablet: toUnit(40),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      ...media('fontSize', toUnit(32), {
        tablet: toUnit(40),
        laptop: toUnit(48),
        desktop: toUnit(48)
      }),
      fontWeight: 400,
      ...media('lineHeight', toUnit(32), {
        tablet: toUnit(40),
        laptop: toUnit(48),
        desktop: toUnit(48)
      }),
      textAlign: 'center'
    },
    [VARIANTS_KEYS.delta]: {
      ...media('marginBottom', toUnit(38), {
        tablet: toUnit(32),
        laptop: toUnit(32),
        desktop: toUnit(56)
      }),
      ...media('fontSize', toUnit(40), {
        tablet: toUnit(48),
        laptop: toUnit(48),
        desktop: toUnit(64)
      }),
      fontWeight: 500,
      ...media('lineHeight', '100%', {
        tablet: '120%',
        laptop: '120%',
        desktop: '120%'
      })
    },
    [VARIANTS_KEYS.zetaA]: {
      marginBottom: 0,
      ...media('fontSize', '24px', {
        desktop: '36px'
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.alphaA]: {
      marginBottom: 0,
      ...multiMedia({
        desktop: {
          marginBottom: '32px',
          fontSize: '48px'
        },
        laptop: {
          marginBottom: '56px',
          fontSize: '36px'
        },
        tablet: {
          marginBottom: '24px',
          fontSize: '36px'
        },
        phone: {
          marginBottom: '24px',
          fontSize: '32px'
        }
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.betaA]: {
      marginBottom: 0,
      ...media('fontSize', '64px', {
        desktop: '48px',
        phone: '40px'
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.alphaMainScreen]: {
      ...multiMedia({
        desktop: {
          fontSize: '80px',
          marginBottom: '32px',
          wordBreak: 'initial'
        },
        laptop: {
          fontSize: '48px',
          marginBottom: '32px'
        },
        tablet: {
          fontSize: '72px',
          marginBottom: '32px',
          color: 'var(--alpha)'
        },
        phone: {
          fontSize: '37px',
          marginBottom: '24px',
          color: 'var(--alpha)'
        }
      }),
      fontWeight: 500,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.eta]: {
      fontSize: '64px',
      fontWeight: 500,
      lineHeight: '120%',
      ...multiMedia({
        tablet: {
          fontSize: '48px'
        },
        phone: {
          fontSize: '40px',
          lineHeight: '100%'
        }
      })
    },
    [VARIANTS_KEYS.phi]: {
      ...multiMedia({
        desktop: { fontSize: '48px' },
        laptop: { fontSize: '40px' },
        tablet: { fontSize: '32px' },
        phone: { fontSize: '24px' }
      }),
      fontWeight: 400,
      lineHeight: '100%',
      marginBottom: '0'
    }
  } as const

  const styles = {
    marginTop: 0,
    wordBreak: 'break-word',
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.H1 {...props} styles={styles} />
}

export default H1
