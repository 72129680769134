import { useTranslations, toUnit, Image, Div, useMedia } from 'fractals'
import { IMG_ALT } from './constants'
import img from './why-us.jpg'

const ImageWithDecorations = () => {
  const [alt] = useTranslations(IMG_ALT)

  const { isMobile, isTablet, isLaptop, isDesktop } = useMedia()

  const decorations = [
    {
      transform: `translate(15%, -48%) rotate(23deg)`,
      minHeight: '100%',
      backgroundColor: 'var(--zeta)'
    },
    {
      transform: 'translate(50%, -50%) rotate(-57deg)',
      minHeight: '112%',
      backgroundColor: 'var(--delta)'
    },
    {
      transform: 'translate(50%, -50%)',
      minHeight: '112%',
      backgroundColor: 'var(--eta)'
    }
  ]

  return (
    <Image
      colorVariant='beta'
      src={img}
      alt={alt}
      borderRadiusVariant='alpha'
      containerProps={{
        styles: {
          position: 'relative',
          transform:
            (isLaptop && 'translate(-50%, -50%) scale(1.75)') ||
            (isMobile && 'scale(0.81)') ||
            undefined,
          '& img': {
            position: 'relative',
            zIndex: decorations.length + 1
          }
        },
        children: decorations.map((decoration, idx) => (
          <Div
            key={`ImageWithDecorations__decoration--${idx + 1}`}
            styles={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              minWidth: '50%',
              borderRadius: toUnit(32),
              zIndex: idx + 1,
              ...decoration
            }}
          />
        ))
      }}
    />
  )
}

export default ImageWithDecorations
