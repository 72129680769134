import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetProgramsDataProps, TResPrograms } from '../types'

export const fetchPrograms = async ({ context }: TGetProgramsDataProps) => {
  const query = qs.stringify({
    sort: {
      cost: {
        price: 'asc'
      }
    },
    fields: ['name', 'slug', 'rank', 'withEmployment', 'level'],
    populate: {
      category: {
        fields: [
          'name',
          'slug',
          'rank',
          'notUniqueSlug',
          'slugSingular',
          'notUniqueSlugSingular',
          'label'
        ]
      },
      faculty: {
        fields: ['name', 'slug', 'rank', 'label', 'color']
      },
      cost: {
        fields: [
          'price',
          'studyHours',
          'studyDurationMonths',
          'studyDurationYears'
        ]
      },
      tags: { fields: ['name', 'slug', 'color'] },
      diplomas: {
        fields: ['name', 'slug', 'label']
      }
    },
    locale: context.locale
  })

  const res = await fetcher<TResPrograms>(
    `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
  )

  return res?.data || null
}

export default fetchPrograms
