import { LOCALES } from 'fractals'

export const ABOUT = {
  [LOCALES.en]: 'About',
  [LOCALES.ru]: 'Об институте',
  [LOCALES.uz]: 'Haqida',
  [LOCALES.kk]: 'Жайлы',
  route: '/about'
} as const

export const JOURNAL = {
  [LOCALES.en]: 'Magazine',
  [LOCALES.ru]: 'Журнал',
  [LOCALES.uz]: 'Jurnallar',
  [LOCALES.kk]: 'Журналдар',
  route: '/journal'
} as const

export const CONTACTS = {
  [LOCALES.en]: 'Contacts',
  [LOCALES.ru]: 'Контакты',
  [LOCALES.uz]: 'Aloqa',
  [LOCALES.kk]: 'Байланыс',
  route: '/contact'
} as const

export const ROUTES_ABOUT_JOURNAL_CONTACTS = [
  // { ...ABOUT },
  { ...JOURNAL },
  { ...CONTACTS }
]

export const PHONE_TITLE = {
  // [LOCALES.en]: '',
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Бесплатно по России',
  // [LOCALES.uz]: 'Rossiyada bepul',
  [LOCALES.uz]: '',
  // [LOCALES.kk]: 'Ресейде бесплаттік'
  [LOCALES.kk]: ''
} as const

export const PHONE_LABEL_MSCW = {
  // [LOCALES.en]: '',
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Бесплатно по Москве',
  // [LOCALES.uz]: 'Rossiyada bepul',
  [LOCALES.uz]: '',
  // [LOCALES.kk]: 'Ресейде бесплаттік'
  [LOCALES.kk]: ''
} as const

export const SEARCH = {
  [LOCALES.en]: 'Search',
  [LOCALES.ru]: 'Поиск',
  [LOCALES.uz]: 'Qidiruv',
  [LOCALES.kk]: 'Іздеу'
} as const

export const SEARCH_COURSE = {
  [LOCALES.en]: 'What course do you need?',
  [LOCALES.ru]: 'Какой курс вам нужен?',
  [LOCALES.uz]: 'Sizga qanday kurs kerak?',
  [LOCALES.kk]: 'Сізге қандай курс керек?'
}

export const LOG_IN = {
  [LOCALES.en]: 'Log in',
  [LOCALES.ru]: 'Войти',
  [LOCALES.uz]: 'Kirish',
  [LOCALES.kk]: 'Кіру'
} as const

export const VARIANT_KEYS = {
  default: 'default'
} as const

export const LMS_MITM_EXTERNAL_LINK = 'https://lms.mitu.msk.ru'

export const SEARCH_SVG = `<svg
width='24'
height='24'
viewBox='0 0 24 24'
fill='none'
xmlns='http://www.w3.org/2000/svg'>
<path
  d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
  stroke='black'
  strokeWidth='1.5'
  strokeLinecap='round'
  strokeLinejoin='round'
/>
<path
  d='M20.9999 20.9999L16.6499 16.6499'
  stroke='black'
  strokeWidth='1.5'
  strokeLinecap='round'
  strokeLinejoin='round'
/>
</svg>`
