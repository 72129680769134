import * as React from 'react'
import { IconContainer } from 'fractals'
import { TFormCircleProps } from './types'

export const LeftTop = ({ ...props }: TFormCircleProps) => {
  const styles = {
    // position: 'absolute',
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='10' cy='10' r='10' fill='#5EC49F' />
      </svg>
    </IconContainer>
  )
}

export default LeftTop
