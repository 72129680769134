// import { keyframes, useTheme, css } from 'styled-components'
import { Layout } from 'ui'
import { IDS } from 'modules/home-page/fractals'
import { Image, toUnit, useMedia } from 'fractals'
import defaultImage from './people-studying.jpg'

// TODO: add glowing animation effect
export const Images = () => {
  const { isTablet, isLaptop, isDesktop } = useMedia()

  // TODO: solve react list keys problem
  const imgs = [
    {
      src: '/',
      alt: '1',
      colorVariant: 'gamma',
      bgVariant: 'gamma'
    },
    {
      src: defaultImage,
      alt: '2',
      colorVariant: 'beta',
      bgVariant: 'beta'
    },
    {
      src: '/',
      alt: '3',
      colorVariant: 'delta',
      bgVariant: 'delta'
    },
    {
      src: '/',
      alt: '4',
      colorVariant: 'epsilon',
      bgVariant: 'epsilon'
    },
    {
      src: '/',
      alt: '5',
      colorVariant: 'zeta',
      bgVariant: 'zeta'
    },
    {
      src: '/',
      alt: '6',
      colorVariant: 'eta',
      bgVariant: 'eta'
    }
  ] as const

  return (
    <Layout.Flex
      cols={((isDesktop || isLaptop || isTablet) && 3) || 2}
      gap={toUnit(((isDesktop || isTablet) && 32) || (isLaptop && 24) || 16)}>
      {imgs.map((props, idx) => (
        <Layout.FlexItem key={`${IDS.hero}-Images__Image--${idx + 1}`}>
          <Image
            width={240}
            height={240}
            {...props}
            borderRadiusVariant='circle'
            containerProps={{
              // TODO: generalize the hover
              styles: {
                overflow: 'hidden',
                transition:
                  'transform 175ms ease-in-out,  backgroundColor 300ms ease-in-out',
                // boxShadow: `0 0 60px 30px #fff, 0 0 100px 20px #f0f, 0 0 100px 90px var(--${props.bgVariant})`,
                '& > *': {
                  transition:
                    'transform 175ms ease-in-out, opacity 175ms ease-in-out',
                  opacity: 0,
                  transform: 'translate(100%, 100%)'
                },
                '@media (hover: hover)': {
                  '&:hover': {
                    transform: 'scale(1.02)',
                    '& > *': {
                      opacity: 1,
                      transform: 'translate(0, 0)'
                    }
                  }
                }
              }
            }}
          />
        </Layout.FlexItem>
      ))}
    </Layout.Flex>
  )
}

export default Images
