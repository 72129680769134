export const insertLineBrakeBetweenWords = (str: string) => {
  if (!str) return str

  return str
    .split(' ')
    .map(item => `${item}<br />`)
    .join('')
}

export default insertLineBrakeBetweenWords
