import { LOCALES, PATHS } from '@/fractals/constants'
import { SharedBreadParams } from './types'

export const getClearPath = (path: string) =>
  path
    .replace('/', '')
    .replace('#dlya-kogo-eta-programma', '')
    .replace('#kratkaya-programma-kursa', '')
    .replace('#stoimost-obucheniya', '')
    .replace('kurs', 'kursy')
    .replace('kursyy', 'kursy')
    .split('?')[0] as string

const transformPath = (path: string): string => {
  if (!path) return '' // Handle the case where path is undefined or an empty string
  return (
    path
      .replace('#dlya-kogo-eta-programma', '')
      .replace('#kratkaya-programma-kursa', '')
      .replace('#stoimost-obucheniya', '')
      .replace('kurs', 'kursy')
      .replace('kursyy', 'kursy')
      .split('?')[0] ?? ''
  ) // Ensure the result is a string
}

const getTransformedPath = (
  paths: string[],
  index: number,
  reversedPaths: boolean
): string => {
  let path = paths.slice(0, index + 1).join('--')

  if (!reversedPaths) {
    path = path.split('--').reverse().join('--')
  } else {
    path = path.replace('/', '')
  }

  return transformPath(path)
}

export const getHref = (
  paths: string[],
  path: string,
  index: number,
  reversedPaths: boolean,
  locale: string | undefined,
  asPath: string,
  { journalPaths, bread }: SharedBreadParams
) => {
  let href = getTransformedPath(paths, index, reversedPaths)

  // form the correct reference to the program taking into account its section and direction
  if (bread?.slug && !journalPaths && path === bread.slug) {
    href = `${getTransformedPath(paths, 0, reversedPaths)}--${
      PATHS.front.general.faculty[(locale as keyof typeof LOCALES) || 'ru']
    }--${path}`
  }

  // form the correct link to the journal section
  if (journalPaths && index === 1) {
    href = getTransformedPath(paths, index, true)
  }

  // clear the href for the last crumb
  if (index === paths.length - 1) {
    href = asPath
  }

  return href
}

export const removeQueryParams = (path: string) => path.split('?')[0] as string

export const getPaths = (
  curPath: string,
  { journalPaths, fakultetPaths, bread }: SharedBreadParams
) => {
  const paths = removeQueryParams(curPath).split('--')

  // reverse the path on the detailed page of the program
  if (!journalPaths && !fakultetPaths) {
    paths.reverse()
  }

  // insert the program section in the path, if it came from the program page and is not on the log page
  if (bread?.slug && !journalPaths) {
    paths.splice(paths.length - 1, 0, bread.slug)
  }

  // program page and not log, then remove the second subpath fakultet
  if (fakultetPaths && !journalPaths) {
    paths.splice(1, 1)
  }

  return paths
}
