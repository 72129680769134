import * as React from 'react'
import { Layout } from 'ui'
import { TIconContainerProps } from './types'

const IconContainer = ({ ...props }: TIconContainerProps) => {
  const styles = {
    ...props.styles
  } as const

  return <Layout.IconContainer {...props} styles={styles} />
}

export default IconContainer
