/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import Image from 'next/image'
import React, { useEffect, useState, useCallback } from 'react'
import Link from 'next/link'
import styles from './HalloweenBanner.module.css'
import pumpkinImg from './assets/pumpkin.png'
import pumpkinBackgroundImg from './assets/pumpkinBackground.png'
import pumpkinDesktopImg from './assets/pumpkinDesktop.png'
import pumpkinDesktopBackgroundImg from './assets/pumpkinDesktopBackground.png'

// Интерфейс для состояния timeLeft
interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const HalloweenBanner = () => {
  const targetDate = new Date(2024, 10, 2) // Ноябрь (месяцы в JS начинаются с 0)

  // Указываем начальное значение и тип для состояния timeLeft
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [visible, setVisible] = useState(true)

  const calculateTimeLeft = useCallback(() => {
    const now = new Date()
    const difference = targetDate.getTime() - now.getTime()

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((difference / 1000 / 60) % 60)
      const seconds = Math.floor((difference / 1000) % 60)

      return {
        days,
        hours,
        minutes,
        seconds
      }
    }

    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }, [targetDate])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [calculateTimeLeft])

  if (!visible) {
    return null
  }

  return (
    <div className={styles.halloweenBannerWrapper}>
      <Image
        alt='pumpkinBackground'
        src={pumpkinBackgroundImg.src}
        width={pumpkinBackgroundImg.width}
        height={pumpkinBackgroundImg.height}
        className={styles.pumpkinBackground}
      />
      <div className={styles.mainText}>
        Учиться не страшно! <br className={styles.mainTextDelimeter} />
        Скидки до 30%
        <div className={styles.subText}>
          Успейте забронировать место со скидкой!
        </div>
        <Image
          alt='pumpkin'
          src={pumpkinImg.src}
          width={pumpkinImg.width}
          height={pumpkinImg.height}
          className={styles.pumpkin}
        />
      </div>
      <div className={styles.pumpkinDesktopContainer}>
        <Image
          alt='pumpkinDesktop'
          src={pumpkinDesktopImg.src}
          width={pumpkinDesktopImg.width}
          height={pumpkinDesktopImg.height}
          className={styles.pumpkinDesktop}
        />
        <Image
          alt='pumpkinDesktopBackground'
          src={pumpkinDesktopBackgroundImg.src}
          width={pumpkinDesktopBackgroundImg.width}
          height={pumpkinDesktopBackgroundImg.height}
          className={styles.pumpkinDesktopBackground}
        />
      </div>
      <div className={styles.rightSideContainer}>
        <div className={styles.timer}>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.days).padStart(2, '0')} :
            </div>
            <div className={styles.timerText}>дней</div>
          </span>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.hours).padStart(2, '0')} :
            </div>
            <div className={styles.timerText}>часов</div>
          </span>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.minutes).padStart(2, '0')} :
            </div>
            <div className={styles.timerText}>минут</div>
          </span>
          <span className={styles.timerNuberContainer}>
            <div className={styles.timerNumber}>
              {String(timeLeft.seconds).padStart(2, '0')}
            </div>
            <div className={styles.timerText}>секунд</div>
          </span>
        </div>
        <Link
          href='/programmy'
          className={styles.getDiscountButton}
          aria-label='Get discount'>
          Получить скидку
        </Link>
        <button
          className={styles.closeButton}
          onClick={() => setVisible(false)}
          aria-label='Закрыть баннер'>
          ✖
        </button>
      </div>
    </div>
  )
}

export default HalloweenBanner
