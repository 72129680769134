import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, useMedia } from 'fractals'
import { TLiProps } from './types'
import { VARIANTS_KEYS } from './constants'

const Li = ({
  variant,
  variantAlphaVariant,
  colorVariant,
  ...props
}: TLiProps) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()

  const stylesVariants = {
    [VARIANTS_KEYS.alpha]: {
      display: 'flex',
      alignItems: 'flex-start',
      '&::before': {
        content: '"\\2022"',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: toUnit(8),
        maxWidth: toUnit(8),
        minHeight: toUnit(8),
        maxHeight: toUnit(8),
        aspectRatio: '1 / 1',
        ...((variantAlphaVariant !== 'point:mt0' && {
          marginTop: toUnit(8)
        }) ||
          {}),
        marginRight: toUnit(16),
        borderRadius: '50%',
        fontSize: 'inherit',
        backgroundColor: colorVariant || 'var(--beta)',
        color: colorVariant || 'var(--beta)'
      }
    },
    [VARIANTS_KEYS.beta]: {
      display: 'flex'
    },
    [VARIANTS_KEYS.gamma]: {
      display: 'flex',
      fontSize: toUnit(
        (isDesktop && 24) || ((isLaptop || isTablet) && 20) || 18
      ),
      fontWeight: 500,
      lineHeight: '120%'
    }
  } as const

  const styles = {
    marginTop: 0,
    marginBottom: 0,
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Li {...props} styles={styles} />
}

export default Li
