import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: 'Watch video about the life of the institute',
  [LOCALES.ru]: 'Посмотри видео о жизни института',
  [LOCALES.uz]: 'Institutning hayoti haqida video ko‘ring',
  [LOCALES.kk]: 'Институттың жүйесі туралы видео көру'
} as const

export const VARIANT_KEYS = {
  home: 'home',
  programs: 'programs'
} as const
