import * as React from 'react'
import { StyledJSX } from 'ui'
import { multiMedia } from 'fractals'
import { TSpanProps } from './types'
import { VARIANTS_KEYS } from './constants'

const Span = ({ variant = 'default', ...props }: TSpanProps) => {
  const stylesVariants = {
    [VARIANTS_KEYS.alpha]: {
      color: '#000',
      ...multiMedia({
        fontSize: '72px',
        laptop: { fontSize: '48px' },
        tablet: { fontSize: '40px' },
        phone: { fontSize: '32px' }
      }),
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '94.5%',
      textAlign: 'center'
    },
    [VARIANTS_KEYS.beta]: {
      color: 'var(--alpha)',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      textDecoration: 'underline'
    },
    [VARIANTS_KEYS.default]: {}
  } as const

  const styles = {
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Span {...props} styles={styles} />
}

export default Span
