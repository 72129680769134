export const TYPE_KEYS = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  promocode: 'promocode'
} as const

export const PLACEHOLDER_KEYS = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  promocode: 'promocode'
} as const
