import * as React from 'react'
import { IconContainer } from 'fractals'
import { TFormCircleProps } from './types'

export const LeftBottom = ({ ...props }: TFormCircleProps) => {
  const styles = {
    // position: 'absolute',
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='125'
        height='149'
        viewBox='0 0 125 149'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M3.37616 96.0155C-6.77271 58.1395 15.7046 19.2075 53.5807 9.05865L87.3882 -2.07578e-05L124.14 137.161L90.333 146.22C52.457 156.369 13.525 133.892 3.37616 96.0155Z'
          fill='#FFC450'
        />
      </svg>
    </IconContainer>
  )
}

export default LeftBottom
