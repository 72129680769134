import { TGetSeoDataProps } from './types'
import { fetchProgramsSeoData, normalizeProgramsSeoData } from './utils'

export const getSeoData = async ({ context }: TGetSeoDataProps) => {
  const seoData = await fetchProgramsSeoData({ context })

  const seoDataNormalized = normalizeProgramsSeoData({
    seoData
  })

  return seoDataNormalized
}

export default getSeoData
