import { H4, H5 } from 'ui/modules/styled-jsx/widgets'
import { media } from 'fractals'
import { H1, H2, H3 } from '@/fractals/components'

type HeaderConfig = {
  Component: React.ComponentType<any>
  variant?: 'alpha' | 'beta' | 'gamma' | 'delta' | 'zetaA' | 'journalH3'
  keyPrefix: string
  styles?: React.CSSProperties
}

const journalH4H5 = {
  marginBottom: 0,
  marginTop: 0,
  ...media('fontSize', '20px', {
    desktop: '32px',
    phone: '18px'
  }),
  fontWeight: 500,
  lineHeight: '120%'
}

const headersConfig: HeaderConfig[] = [
  { Component: H1, variant: 'zetaA', keyPrefix: 'JournalContent-H1' },
  { Component: H2, variant: 'zetaA', keyPrefix: 'JournalContent-H2' },
  { Component: H3, variant: 'journalH3', keyPrefix: 'JournalContent-H3' },
  {
    Component: H4,
    keyPrefix: 'JournalContent-H4',
    styles: { ...journalH4H5 }
  },
  {
    Component: H5,
    keyPrefix: 'JournalContent-H5',
    styles: { ...journalH4H5 }
  }
]

const headerNumber = (name: string) => name?.split('#')?.[0]

export const HeaderComponent = ({
  content,
  idx
}: {
  content: { name: string | null }
  idx: number
}) => {
  return (
    <>
      {headersConfig.map((header, index) => {
        if (headerNumber(content.name ?? '') === `${index + 1}`) {
          return (
            <header.Component
              id={`journal-content__${idx + 1}`}
              variant={header.variant}
              key={`${header.keyPrefix}__${idx + 1}`}
              styles={header.styles}>
              {content.name?.replace(/.#/, '') ?? ''}
            </header.Component>
          )
        }
        return null
      })}
    </>
  )
}
