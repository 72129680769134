import { OpportunityCardVariant } from './components/molecules/OpportunityCard/types'

export const getOpportunityCardVariant = (index: number, cols: number) => {
  if (index === 0) return OpportunityCardVariant.light
  // if (cols === 2) {
  if (index % 4 === 1 || index % 4 === 2) {
    return OpportunityCardVariant.blue
  }
  return OpportunityCardVariant.light
  // }

  // return index % 2 === 0
  //   ? OpportunityCardVariant.light
  //   : OpportunityCardVariant.blue
}
