import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  Image,
  InfoTag,
  Ul,
  Li,
  useMedia
} from 'fractals'
import {
  Layout,
  mdToJsx,
  insertWhiteSpacesAfterPrepositions,
  slugify
} from 'ui'
import Color from 'color'
import {
  TWhatWillYouLearnProps,
  TITLE,
  ITEM_1,
  ITEM_2,
  ITEM_3,
  ITEM_4,
  ITEM_5,
  ITEM_6
} from './fractals'

const WhatWillYouLearn = ({
  colorVariant,
  whatWillYouLearn
}: TWhatWillYouLearnProps) => {
  const [title] = useTranslations(TITLE)
  const itemsDefault = useTranslations(
    ITEM_1,
    ITEM_2,
    ITEM_3,
    ITEM_4,
    ITEM_5,
    ITEM_6
  )

  const { isTablet, isLaptop, isDesktop } = useMedia()

  const items =
    (whatWillYouLearn && whatWillYouLearn?.length !== 0 && whatWillYouLearn) ||
    itemsDefault
  // TODO: improve UI
  return (
    <Section id={slugify(title)} spacingVariant='beta'>
      <Container>
        <H2 variant='beta'>{title}</H2>
        <Ul variant='beta'>
          {items?.map((item, idx) => (
            <Li
              styles={{
                minWidth: isDesktop || isLaptop ? '40%' : '0%',
                wordBreak: 'break-word'
              }}
              variant='alpha'
              colorVariant={
                (colorVariant?.color &&
                  Color(colorVariant?.color).rgb().string()) ||
                undefined
              }
              key={`WhatWillYouLearn__items__item--${idx + 1}`}>
              <P variant='delta'>
                {item &&
                  mdToJsx(insertWhiteSpacesAfterPrepositions(item), {
                    replace: ['p']
                  })}
              </P>
            </Li>
          ))}
        </Ul>
      </Container>
    </Section>
  )
}

export default WhatWillYouLearn
