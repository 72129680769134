import React, { MouseEventHandler, useState } from 'react'
import Popup from 'reactjs-popup'
import { getCookies } from 'cookies-next'
import { TbBrandTelegram, TbBrandWhatsapp, TbPhoneCall } from 'react-icons/tb'
import { SiViber } from 'react-icons/si'
import { TfiEmail } from 'react-icons/tfi'
import { Label } from 'ui/modules/styled-jsx/widgets'
import axios from 'axios'
import {
  Button,
  Div,
  Form,
  H3,
  IconCheck,
  IconPlus,
  IconTick,
  P,
  Spinner
} from '@/fractals/components'
import { AskButton, CallButtons, TextButtons } from './features'
import { TAskModalProps, TStack, TWrap } from './types'
import { askModal } from './styles'
import { PATHS } from '@/fractals/constants'
import { margetingLead } from '../LeadForm/LeadForm'

export const AskModal = ({ styles, ...props }: TAskModalProps) => {
  const [contentStack, setContentStack] = useState<TStack[]>(['main'])
  const [question, setQuestion] = useState('')
  const [value, setValue] = useState('')
  const [alert, setAlert] = useState('')

  const cookies = getCookies()

  const currentContent = contentStack[contentStack.length - 1]

  const previousContent =
    contentStack.length > 1 ? contentStack[contentStack.length - 2] : ''

  const callOptions = new Set(['telegram', 'whatsapp', 'viber'])
  const textOptions = new Set(['vk', 'phone', 'email'])

  const navigateTo = (content: (typeof contentStack)[0]) => {
    setAlert('')
    setContentStack(prevStack => [...prevStack, content])
  }

  const navigateBack = () => {
    setAlert('')
    setContentStack(prevStack => {
      if (prevStack.length > 1) {
        return prevStack.slice(0, -1)
      }
      return prevStack
    })
  }

  const [open, setOpen] = useState(false)

  const wrap: TWrap = {
    main: [
      {
        contact: 'telegram',
        backgroundColor: '#00a3ff',
        Icon: TbBrandTelegram
      },
      {
        contact: 'whatsapp',
        backgroundColor: '#1bc100',
        Icon: TbBrandWhatsapp
      },
      { contact: 'viber', backgroundColor: '#9300c7', Icon: SiViber },
      // { contact: 'vk', backgroundColor: '#368cdc', Icon: '' },
      { contact: 'phone', backgroundColor: '#0050e3', Icon: TbPhoneCall },
      { contact: 'email', backgroundColor: '#f60', Icon: TfiEmail }
    ]
  }

  const capitalizeFirstLetter = (word: string) => {
    return `${word?.[0]?.toUpperCase()}${word.slice(1)}`
  }

  type TSubmitProps = {
    question: string
    contactMethod: string
    contactWay: string
  }

  const handleSubmit = async ({ ...values }: TSubmitProps) => {
    if (values.contactWay === 'Email' || values.contactMethod === '') {
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.contactMethod)) {
        setAlert('Введите корректный адрес почты')
        return
      }

      try {
        if (values.contactMethod && values.question) {
          setContentStack(['loading'])
          const response = await axios.post(`${PATHS.front.api.lead.index}`, {
            ...values
          })
          if (response.status === 200) {
            setContentStack(['thanks'])
          }
        } else setAlert('Заполните поля')
      } catch (e) {
        console.error('Error message: ', e)
      }
      return
    }

    if (
      values.contactMethod &&
      values.question &&
      values.contactMethod.match(/^\+\d{1,3}\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$/)
    ) {
      try {
        setContentStack(['loading'])
        const response = await axios.post(`${PATHS.front.api.lead.index}`, {
          ...values
        })
        if (response.status === 200) {
          setContentStack(['thanks'])
        }
      } catch (e) {
        console.error('Error message: ', e)
      }
    } else {
      setAlert('Введите корректный номер телефона')
    }
  }

  return (
    <Popup
      open={open}
      trigger={<AskButton styles={styles} onClick={() => setOpen(true)} />}
      modal
      nested
      closeOnDocumentClick
      closeOnEscape
      lockScroll
      className='ask-popup'
      position='bottom right'
      {...props}>
      {/* @ts-expect-error TODO: remove this line and fix the error */}
      {(close: MouseEventHandler) => (
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit({
              question,
              contactMethod: value,
              contactWay: capitalizeFirstLetter(
                (currentContent === 'call' &&
                  `${previousContent} / Позвонить`) ||
                  (currentContent === 'text' &&
                    `${previousContent} / Написать`) ||
                  currentContent ||
                  ''
              )
            })
            margetingLead({
              event: 'lead',
              program: null,
              googleClientId: cookies.gclUid || null
            })
          }}
          formName='askModal-popup'
          styles={askModal.form}>
          <IconPlus
            colorVariant='phi'
            variant='cross'
            styles={askModal.icon}
            onClick={close}
          />
          {(currentContent === 'main' && (
            <>
              <textarea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setQuestion(e.target.value)
                }
                style={askModal.textarea}
                placeholder='Напишите вопрос...'
                value={question}
              />
              <Div styles={askModal.wrapper}>
                <Label styles={askModal.wrapper.label}>Куда ответить:</Label>
                {wrap?.main.map(
                  (current, idx) =>
                    current.contact && (
                      <Button
                        key={`AskmodalWrap-button__${idx + 1}`}
                        styles={{ backgroundColor: current.backgroundColor }}
                        onClick={() => navigateTo(current.contact ?? 'main')}>
                        <current.Icon />
                      </Button>
                    )
                )}
              </Div>
            </>
          )) ||
            ((currentContent === 'call' || currentContent === 'text') && (
              <>
                <Div
                  styles={{
                    ...askModal.backWrap
                  }}>
                  <IconTick
                    sizeVariant='xl'
                    onClick={navigateBack}
                    styles={{
                      ...askModal.backIcon
                    }}
                    direction='leftAnotherPath'
                  />
                  <Label>
                    {`${capitalizeFirstLetter(previousContent ?? '')} / ${
                      (currentContent === 'call' && 'Позвонить') ||
                      (currentContent === 'text' && 'Написать')
                    }`}
                  </Label>
                </Div>
                <TextButtons
                  setAlert={setAlert}
                  setValue={setValue}
                  value={value}
                  alert={alert}
                  currentContent={currentContent}
                />
              </>
            )) ||
            ((callOptions.has(currentContent ?? '') ||
              textOptions.has(currentContent ?? '')) && (
              <>
                <Div styles={{ ...askModal.backWrap }}>
                  <IconTick
                    sizeVariant='xl'
                    onClick={navigateBack}
                    styles={{
                      ...askModal.backIcon
                    }}
                    direction='leftAnotherPath'
                  />
                  <Label>{capitalizeFirstLetter(currentContent ?? '')}</Label>
                </Div>
                {callOptions.has(currentContent ?? '') && (
                  <CallButtons navigateTo={navigateTo} />
                )}
                {textOptions.has(currentContent ?? '') && (
                  <TextButtons
                    setAlert={setAlert}
                    setValue={setValue}
                    value={value}
                    alert={alert}
                    currentContent={currentContent}
                  />
                )}
              </>
            )) ||
            (currentContent === 'thanks' && (
              <Div styles={askModal.thanks}>
                <Div styles={askModal.thanksIcon}>
                  <IconCheck colorsVariant='alpha' />
                </Div>
                <H3 variant='thanks'>Спасибо за заявку!</H3>
                <P variant='thanks'>
                  Менеджер свяжется с Вами в ближайшее время
                </P>
              </Div>
            )) ||
            (currentContent === 'loading' && (
              <Div
                styles={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  marginTop: '1rem'
                }}>
                <Spinner />
              </Div>
            ))}
        </Form>
      )}
    </Popup>
  )
}

export default AskModal
