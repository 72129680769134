import * as React from 'react'
import { TSectionProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Section = ({ ...props }: TSectionProps) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{ /* DEFAULT_STYLES */ ...props.styles }}
    />
  )
}

export default Section
