import {
  useTranslations,
  Section,
  Container,
  H2,
  toUnit,
  Image,
  Link,
  IconArrow,
  Span,
  P,
  useAt,
  imgLicence,
  useMedia
} from 'fractals'
import { insertWhiteSpacesAfterPrepositions, Layout, slugify } from 'ui'
import {
  TDiplomasProps,
  TITLE_LICENSE,
  TITLE_DIPLOMAS,
  DIPLOMA_BACHELOR,
  DIPLOMA_MASTER,
  DIPLOMA_RETRAINING,
  DIPLOMA_NAME,
  DIPLOMA_SUPPLEMENT,
  LICENCE_ALT,
  CHECK_LICENCE,
  CHECK_LICENCE_URL,
  GRADUATION_DOCUMENTS_INFO
} from './fractals'
import imgBachelor from './diploma-bachelor.jpg'
import imgMaster from './diploma-master.jpg'
import imgDiplomaProfessionalRetraining from './diploma-professional-retraining.jpg'
import imgDiploma from './diploma-en.jpg'
import imgSupplement from './supplement.jpg'

// TODO: ! add popups with diplomas
const Diplomas = ({ diplomas }: TDiplomasProps) => {
  const [
    titleLicense,
    titleDiplomas,
    diplomaBachelor,
    diplomaMaster,
    diplomaRetraining,
    diplomaName,
    diplomaSupplement,
    licenceAlt,
    checkLicence,
    graduationDocumentsInfo
  ] = useTranslations(
    TITLE_LICENSE,
    TITLE_DIPLOMAS,
    DIPLOMA_BACHELOR,
    DIPLOMA_MASTER,
    DIPLOMA_RETRAINING,
    DIPLOMA_NAME,
    DIPLOMA_SUPPLEMENT,
    LICENCE_ALT,
    CHECK_LICENCE,
    GRADUATION_DOCUMENTS_INFO
  )

  const { isLaptop, isDesktop } = useMedia()
  const { isCourse } = useAt()

  const diplomasWithDefault = (diplomas &&
    diplomas?.length !== 0 &&
    diplomas) || [
    {
      name: diplomaBachelor,
      imageDefault: {
        url: imgBachelor,
        width: null,
        height: null,
        alternativeText: diplomaBachelor
      }
    },
    {
      name: diplomaMaster,
      imageDefault: {
        url: imgMaster,
        width: null,
        height: null,
        alternativeText: diplomaMaster
      }
    },
    {
      name: diplomaRetraining,
      imageDefault: {
        url: imgDiplomaProfessionalRetraining,
        width: null,
        height: null,
        alternativeText: diplomaRetraining
      }
    },
    {
      name: diplomaName,
      imageDefault: {
        url: imgDiploma,
        width: null,
        height: null,
        alternativeText: diplomaName
      }
    },
    {
      name: diplomaSupplement,
      imageDefault: {
        url: imgSupplement,
        width: null,
        height: null,
        alternativeText: diplomaSupplement
      }
    }
  ]

  return (
    <Section
      id={slugify(`${titleLicense} ${titleDiplomas}`)}
      spacingVariant='delta'
      colorsVariant='gamma'>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <Layout.Flex
          cols={((isDesktop || isLaptop) && 2) || 1}
          proportion={(isDesktop && '6:7') || (isLaptop && '5:6') || undefined}
          gap={toUnit((isDesktop && 136) || (isLaptop && 32) || 56)}>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <H2
              variant='gamma'
              styles={{
                marginBottom: toUnit(
                  (isDesktop && 100) || (isLaptop && 135) || undefined
                )
              }}>
              {titleLicense}
            </H2>
            <Image
              colorVariant='transparent'
              borderRadiusVariant='beta'
              src={imgLicence}
              alt={licenceAlt}
              title={licenceAlt}
            />
            <Link
              variant='beta'
              href={CHECK_LICENCE_URL}
              target='_blank'
              styles={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: toUnit(18),
                marginTop: toUnit((isDesktop && 32) || 25),
                textDecoration: 'underline'
              }}>
              <Span
                styles={{
                  maxWidth:
                    (isDesktop && '44ch') || (isLaptop && '34ch') || undefined
                }}>
                {insertWhiteSpacesAfterPrepositions(checkLicence)}
              </Span>
              <IconArrow direction='upRight' />
            </Link>
          </Layout.FlexItem>
          <Layout.FlexItem>
            <H2 variant='gamma' styles={{ marginBottom: toUnit(56) }}>
              {insertWhiteSpacesAfterPrepositions(titleDiplomas)}
            </H2>
            <Layout.Flex cols={2} gap={toUnit(32)}>
              {diplomasWithDefault?.map(
                (diploma, idx) =>
                  diploma.name &&
                  diploma.imageDefault?.url && (
                    <Layout.FlexItem
                      key={`Diplomas__${diploma?.name || 'diploma'}--${
                        idx + 1
                      }`}>
                      <Image
                        colorVariant='transparent'
                        borderRadiusVariant='beta'
                        src={diploma.imageDefault?.url}
                        width={diploma.imageDefault?.width || undefined}
                        height={diploma.imageDefault?.height || undefined}
                        alt={
                          diploma.imageDefault?.alternativeText || diploma.name
                        }
                        title={diploma.name}
                      />
                    </Layout.FlexItem>
                  )
              ) || null}
              {isCourse && (
                <Layout.FlexItem
                  styles={{
                    marginTop: toUnit(56),
                    paddingTop: toUnit(32),
                    paddingRight: toUnit(32),
                    paddingBottom: toUnit(32),
                    paddingLeft: toUnit(32),
                    borderRadius: toUnit(32),
                    flexBasis: '100%',
                    backgroundColor: 'var(--beta)',
                    color: 'var(--alpha)'
                  }}>
                  <P
                    variant='lambda'
                    styles={{
                      color: 'var(--alpha)'
                    }}>
                    {graduationDocumentsInfo}
                  </P>
                </Layout.FlexItem>
              )}
            </Layout.Flex>
          </Layout.FlexItem>
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default Diplomas
