const MONTHS_IN_YEAR = 12

export const calculateStudyDuration = ({
  years,
  months,
  year,
  month
}: {
  years?: number | null
  months?: number | null
  year?: string
  month?: string
}) => {
  // idk what doing this
  // const monthsInYears = years && years * MONTHS_IN_YEAR
  // if (months === 0 && typeof years === 'number' && years > 0)
  //   return `${monthsInYears}${
  //     month && ` ${monthsInYears === 24 ? 'месяца' : month}`
  //   }`

  return `${years || ''}${(years && year && ` ${year}`) || ''}${
    (months && ` ${months}`) || ''
  }${(months && month && ` ${month}`) || ''}`
}

export default calculateStudyDuration
