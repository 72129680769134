import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, media } from 'fractals'
import { TContainerProps } from './types'
import {
  // VARIANTS_KEYS,
  DESKTOP_MAX_WIDTH,
  LAPTOP_MAX_WIDTH,
  TABLET_MAX_WIDTH
} from './constants'

const Container = ({
  // variant = VARIANTS_KEYS.alpha,
  ...props
}: TContainerProps) => {
  // const stylesVariants = {
  //   [VARIANTS_KEYS.alpha]: {}
  // } as const

  const styles = {
    marginRight: 'auto',
    marginLeft: 'auto',
    minWidth: '280px',
    // boxSizing: 'border-box',
    ...media('maxWidth', null, {
      tablet: `${TABLET_MAX_WIDTH + 32}px`,
      laptop: `${LAPTOP_MAX_WIDTH + 32}px`,
      desktop: `${DESKTOP_MAX_WIDTH + 32}px`
    }),
    paddingLeft: '16px',
    paddingRight: '16px',
    // ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Div {...props} styles={styles} />
}

export default Container
