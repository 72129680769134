import { NextWrappers } from 'ui'
import logo from 'public/favicon.svg'
import { ENV } from 'fractals'
import { TIsUnderDevelopmentProps } from './types'

const IsUnderDevelopment = ({ isOn = ENV.prod }: TIsUnderDevelopmentProps) => {
  if (!isOn) return null

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh'
      }}>
      <NextWrappers.Image src={logo} alt='MITM' priority />
      <h1>
        Сайт в разработке... <br /> <br /> Скоро будет готов!
      </h1>
    </div>
  )
}

export default IsUnderDevelopment
