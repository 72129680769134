import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconLinkProps } from './types'

const IconLink = ({ ...props }: TIconLinkProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      // transform:
      //   (direction === 'upRight' && 'rotate(45deg)') ||
      //   (direction === 'right' && 'rotate(90deg)') ||
      //   (direction === 'downRight' && 'rotate(135deg)') ||
      //   (direction === 'down' && 'rotate(180deg)') ||
      //   (direction === 'downLeft' && 'rotate(225deg)') ||
      //   (direction === 'left' && 'rotate(270deg)') ||
      //   (direction === 'upLeft' && 'rotate(315deg)') ||
      // * default direction is up
    },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='15'
        height='15'
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_4625_1182)'>
          <path
            d='M6.25 8.12498C6.51841 8.48381 6.86085 8.78072 7.25409 8.99557C7.64734 9.21041 8.08219 9.33818 8.52916 9.37019C8.97612 9.4022 9.42474 9.33771 9.84459 9.18109C10.2644 9.02448 10.6457 8.7794 10.9625 8.46248L12.8375 6.58748C13.4067 5.9981 13.7217 5.20871 13.7146 4.38935C13.7075 3.56998 13.3788 2.7862 12.7994 2.2068C12.22 1.6274 11.4362 1.29874 10.6169 1.29162C9.79751 1.2845 9.00813 1.59948 8.41875 2.16873L7.34375 3.23748'
            stroke='#262626'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M8.74988 6.87502C8.48147 6.51619 8.13903 6.21928 7.74579 6.00443C7.35254 5.78958 6.91769 5.66182 6.47072 5.62981C6.02376 5.5978 5.57514 5.66229 5.15529 5.81891C4.73544 5.97552 4.35418 6.2206 4.03738 6.53752L2.16238 8.41252C1.59314 9.0019 1.27815 9.79128 1.28527 10.6106C1.29239 11.43 1.62105 12.2138 2.20045 12.7932C2.77985 13.3726 3.56364 13.7013 4.383 13.7084C5.20237 13.7155 5.99175 13.4005 6.58113 12.8313L7.64988 11.7625'
            stroke='#262626'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_4625_1182'>
            <rect width='15' height='15' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  )
}

export default IconLink
