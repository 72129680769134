import { useContext, useEffect, useRef, useState } from 'react'
import { ModalBackgroundOverlayMobile } from '../../atoms'
import { TypeModalMobileProps, useSwipeEffect } from './fractals'
import { parseDuration } from '../../../utils'
import { ModalShowCtx, ModalStylesCtx } from '../../../contexts'
import {
  useAria,
  useCloseButtons,
  useKeyboardEscape,
  usePrefersReducedMotion
} from '../../../hooks'

const ModalMobile = ({ shown, setShown, children }: TypeModalMobileProps) => {
  const { modalId, isPoppingHistory } = useContext(ModalShowCtx)
  const [mounted, setMounted] = useState(false)
  const [isHiding, setIsHiding] = useState(false)
  const modalRef = useRef<HTMLDivElement | null>(null)

  const {
    mobileStyles: {
      maxHeight,
      animationDuration,
      backgroundColor,
      animationEaseFunction,
      boxShadow,
      swipeToggleTraitColor,
      swipeToggleHeight,
      offsetThreshold,
      borderRadius
    }
  } = useContext(ModalStylesCtx)

  const reducedMotion = usePrefersReducedMotion()

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (shown) {
      setTimeout(() => {
        setMounted(true)
        modalRef.current?.focus()
        document.documentElement.style.overflowY = 'hidden'
      }, 0)
    }
    return () => clearTimeout(timeoutId)
  }, [shown, modalRef])

  const modalHeight = modalRef.current?.offsetHeight || 0

  const close = () => {
    setIsHiding(true)
    setMounted(false)
  }

  useCloseButtons({ modalRef, close })

  useEffect(() => {
    if (isPoppingHistory) {
      close()
    }
  }, [isPoppingHistory])

  useKeyboardEscape(() => close())

  const ariaAttributes = useAria()

  const {
    touchScreenHandlers,
    isSwiping,
    currentSwipeOffset,
    mouseScreenHandlers
  } = useSwipeEffect({ setMounted, offsetThreshold })
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0 }}>
      <ModalBackgroundOverlayMobile
        aria-controls={modalId}
        duration={
          reducedMotion ? '1ms' : parseDuration(animationDuration || 200, 1.3)
        }
        onClick={close}
        modalIsSwiping={isSwiping}
        opacityRatio={
          reducedMotion
            ? 1
            : (modalHeight - currentSwipeOffset.offset) / modalHeight
        }
        show={!isHiding && mounted}
      />
      <div style={{ height: '100%' }}>
        <style>
          {` 
          [data-modal="true"] {
            background-color: #efefef;
						border-top-left-radius: ${borderRadius};
						border-top-right-radius: ${borderRadius};
            outline: none;
            overflow: hidden;
						max-height: ${maxHeight}px;
						position: absolute;
						bottom: -${modalHeight}px;
						height: 100%;
						left: 0;
						right: 0;
						transform: translate3d(0, -${modalHeight}px, 0);
						will-change: transform;
            box-shadow: ${boxShadow};
            backgroundColor: ${backgroundColor}
					}
					`}
        </style>
        <div
          id={modalId}
          role='dialog'
          aria-modal='true'
          aria-describedby={ariaAttributes['aria-describedby']}
          aria-labelledby={ariaAttributes['aria-labelledby']}
          data-modal={shown}
          tabIndex={-1}
          ref={modalRef}
          style={{
            transition: !isSwiping
              ? `transform ${
                  reducedMotion
                    ? '1ms'
                    : parseDuration(animationDuration || 200)
                } ${animationEaseFunction || 'cubic-bezier(0.33, 1, 0.68, 1)'}`
              : undefined,
            transform: mounted
              ? `translate3d(0, -${
                  modalHeight - currentSwipeOffset.offset
                }px, 0)`
              : 'translate3d(0, 0, 0)'
          }}
          onTransitionEnd={() => {
            if (!mounted) {
              setTimeout(() => {
                setIsHiding(false)
                setShown(false)
                document.documentElement.style.overflowY = 'auto'
              }, 0)
            }
          }}>
          <div
            aria-controls={modalId}
            style={{
              height: swipeToggleHeight,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              backgroundColor
            }}
            {...touchScreenHandlers}
            {...mouseScreenHandlers}>
            <div
              style={{
                height: '3px',
                width: '50px',
                borderRadius: '100vw',
                backgroundColor: swipeToggleTraitColor
              }}
            />
          </div>
          <div
            style={{
              flex: '0 0 auto',
              maxHeight: modalHeight - swipeToggleHeight,
              overflowY: 'auto'
            }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

ModalMobile.displayName = 'ModalMobile'

export default ModalMobile
