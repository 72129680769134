import { LOCALES } from 'fractals'

// TODO: refactor this
export const CURRENCY_SYMBOL = {
  [LOCALES.en]: '$',
  [LOCALES.ru]: '₽',
  [LOCALES.uz]: 'so’m',
  [LOCALES.kk]: 'тг'
}

export const CURRENCY_RATES = {
  [LOCALES.en]: 0.014,
  [LOCALES.ru]: 1,
  [LOCALES.uz]: 1.23,
  [LOCALES.kk]: 6.62
}

export const ONE_SEMISTER_PAYMENT = {
  [LOCALES.en]: 'One semester payment',
  [LOCALES.ru]: 'Оплата за один семестр',
  [LOCALES.uz]: 'Bir o‘quv yili to‘lovi',
  [LOCALES.kk]: 'Бір оқу жылы төлемі'
} as const

export const FULL_COURSE_PAYMENT = {
  [LOCALES.en]: 'Cost of the program for the entire course',
  [LOCALES.ru]: 'Стоимость программы за весь курс',
  [LOCALES.uz]: 'Butun kurs uchun dasturning narxi',
  [LOCALES.kk]: 'Бүкіл курсқа арналған бағдарламаның құны'
} as const

export const YEAR_INSTALLMENT_PLAN = {
  [LOCALES.en]: '12-month installment plan',
  [LOCALES.ru]: 'Беспроцентная рассрочка на 12 месяцев',
  [LOCALES.uz]: '12 oylik to‘lovlash rejaligi',
  [LOCALES.kk]: '12 айлық төлем режимі'
} as const

export const PER_MONTH = {
  [LOCALES.en]: '/month',
  [LOCALES.ru]: '/мес',
  [LOCALES.uz]: '/oy',
  [LOCALES.kk]: '/ай'
} as const

export const DEFAULT_DISCOUNT = 30

export const DEFAULT_PRICE = 96000

export const calculatePrice = ({
  price = DEFAULT_PRICE,
  discount = DEFAULT_DISCOUNT,
  locale,
  semestersNumber = 2
}: {
  price: number | null | undefined
  discount: number | null | undefined
  // locale: keyof typeof LOCALES
  locale: string | undefined
  semestersNumber?: number
}) => {
  // TODO: figure out a better way to handle types here
  // * we want to check if the locale that we pass in is a valid locale
  const currentLocale =
    (locale &&
      LOCALES[locale as keyof typeof LOCALES] &&
      LOCALES[locale as keyof typeof LOCALES]) ||
    'ru'

  const currencySymbol = CURRENCY_SYMBOL[currentLocale]

  const rounds = (locale === 'en' && 10) || 100

  const priceRated =
    ((price && Number(price)) || DEFAULT_PRICE) * CURRENCY_RATES[currentLocale]

  const regularPrice =
    Math.round(
      Math.ceil(
        (priceRated /
          (100 - ((discount && Number(discount)) || DEFAULT_DISCOUNT))) *
          100
      ) / rounds
    ) * rounds

  const regularPriceSemester =
    Math.round(Math.ceil(regularPrice / semestersNumber) / rounds) * rounds

  const discountedPriceSemester =
    Math.round(Math.ceil(priceRated / semestersNumber) / rounds) * rounds

  const regularPriceOneMonth =
    Math.round(Math.ceil(regularPrice / 12) / (rounds / 10)) * (rounds / 10)

  const discountedPriceOneMonth =
    Math.round(Math.ceil(priceRated / 12) / (rounds / 10)) * (rounds / 10)

  return {
    oneSemisterPayment: {
      name:
        semestersNumber === 1
          ? FULL_COURSE_PAYMENT[currentLocale]
          : ONE_SEMISTER_PAYMENT[currentLocale],
      price: discountedPriceSemester,
      regularPrice: regularPriceSemester,
      currencySymbol
    },
    yearInstallmentPlan: {
      name: YEAR_INSTALLMENT_PLAN[currentLocale],
      price: discountedPriceOneMonth,
      regularPrice: regularPriceOneMonth,
      currencySymbol: `${currencySymbol}${PER_MONTH[currentLocale]}`
    }
  }
}
