import {
  PHONE_NUMBER,
  Div,
  H3,
  P,
  useTranslations,
  toUnit,
  useAt,
  useMedia
} from 'fractals'
import { insertWhiteSpacesAfterPrepositions, Layout, mdToJsx } from 'ui'
import {
  TESTIMONIAL_TITLE_1,
  TESTIMONIAL_TITLE_1_ALT,
  TESTIMONIAL_DESCRIPTION_1,
  TESTIMONIAL_DESCRIPTION_1_ALT,
  TESTIMONIAL_TITLE_2,
  TESTIMONIAL_DESCRIPTION_2,
  TESTIMONIAL_TITLE_3,
  TESTIMONIAL_DESCRIPTION_3
} from './constants'

export const TestimonialsPsihologiyaIsHigherEducation = () => {
  const { isLaptop, isDesktop } = useMedia()

  const [
    testimonialTitle1,
    testimonialTitle1Alt,
    testimonialDescription1,
    testimonialDescription1Alt,
    testimonialTitle2,
    testimonialDescription2,
    testimonialTitle3,
    testimonialDescription3,
    phoneNumber
  ] = useTranslations(
    TESTIMONIAL_TITLE_1,
    TESTIMONIAL_TITLE_1_ALT,
    TESTIMONIAL_DESCRIPTION_1,
    TESTIMONIAL_DESCRIPTION_1_ALT,
    TESTIMONIAL_TITLE_2,
    TESTIMONIAL_DESCRIPTION_2,
    TESTIMONIAL_TITLE_3,
    TESTIMONIAL_DESCRIPTION_3,
    PHONE_NUMBER
  )

  const { isCourse } = useAt()

  const testimonials = [
    {
      name: (isCourse && testimonialTitle1Alt) || testimonialTitle1,
      description:
        (isCourse && testimonialDescription1Alt) || testimonialDescription1,
      nameLiesInTwoLines: true
    },
    {
      name: testimonialTitle2,
      description: testimonialDescription2,
      nameLiesInTwoLines: false
    },
    // TODO: add here anchor functionality
    {
      name: testimonialTitle3,
      description: `${testimonialDescription3} <br /> <a href="tel:${phoneNumber}">${phoneNumber}</a>`,
      nameLiesInTwoLines: false
    }
  ] as const

  return (
    <Layout.Flex
      cols={(isDesktop && 3) || (isLaptop && 2) || 1}
      gap={toUnit(((isDesktop || isLaptop) && 32) || 40)}
      styles={{
        marginTop: toUnit((isDesktop && 56) || (isLaptop && 72) || 40)
      }}>
      {testimonials.map((testimonial, idx) => (
        <Layout.FlexItem
          key={`Cost__testimonials__${testimonial.name}-${idx + 1}`}
          styles={{
            display: 'flex'
          }}>
          <Div
            styles={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'space-between',
              flexGrow: 1,
              gap: toUnit(
                ((isDesktop || isLaptop) &&
                  !testimonial.nameLiesInTwoLines &&
                  40) ||
                  16
              ), // * there needs to be an equal gap of the longest title for each card, this is temp fix since first title goes two lines across
              paddingTop: toUnit(((isDesktop || isLaptop) && 32) || 24),
              paddingRight: toUnit(((isDesktop || isLaptop) && 32) || 24),
              paddingBottom: toUnit(((isDesktop || isLaptop) && 32) || 24),
              paddingLeft: toUnit(((isDesktop || isLaptop) && 32) || 24),
              borderRadius: toUnit(16),
              backgroundColor: '#222324'
            }}>
            <H3 variant='epsilon'>
              {mdToJsx(insertWhiteSpacesAfterPrepositions(testimonial.name))}
            </H3>
            <P
              variant='lambda'
              styles={{
                color: 'var(--upsilon)',
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit'
                }
              }}>
              {mdToJsx(
                insertWhiteSpacesAfterPrepositions(testimonial.description)
              )}
            </P>
          </Div>
        </Layout.FlexItem>
      ))}
    </Layout.Flex>
  )
}

export default TestimonialsPsihologiyaIsHigherEducation
