import { calculateRuCase, calculateStudyDuration, FunctionPropTypes } from 'ui'
import { useRouter } from 'next/router'
import { LOCALES, useTranslations, YEAR, MONTH } from 'fractals'

export const YEARS = 0
export const MONTHS = 0

// TODO: refactor this
export const useCalculateStudyDuration = (
  props: Omit<
    FunctionPropTypes<typeof calculateStudyDuration>,
    'month' | 'year'
  >
) => {
  const [year, month] = useTranslations(YEAR, MONTH)
  const { locale } = useRouter()
  const years = Number((props.years === 0 && '0') || props.years || YEARS)
  const months = Number((props.months === 0 && '0') || props.months || MONTHS)
  const yearCased =
    ((years || years === 0) && calculateRuCase(year, years, locale)) || ''
  const monthCased =
    ((months || months === 0) && calculateRuCase(month, months, locale)) || ''

  return calculateStudyDuration({
    years,
    months,
    year: yearCased,
    month: monthCased
  })
}

export default useCalculateStudyDuration
