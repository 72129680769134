import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Преподаватели',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const LABEL = {
  [LOCALES.en]: '',
  [LOCALES.ru]:
    'Преподают ведущие российские и зарубежные эксперты с опытом от 7 до 25 лет',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const NAME_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Иванов Иван',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const DESCRIPTION_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Инженер по тестированию в компании Яндекс',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const BUTTON = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Посмотреть всех',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const
