import * as React from 'react'
import { TAProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

// TODO: figure out Ref
const A = React.forwardRef<HTMLAnchorElement, TAProps>(({ ...props }, ref) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      ref={ref}
      {...props}
      {...(props.target === '_blank' && {
        rel: `${(props.rel && `${props.rel} `) || ''}noreferrer noopener`
      })}
      styles={{
        textDecoration: 'none',
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
        backgroundColor: 'transparent',
        color: 'inherit',
        ...props.styles
      }}
    />
  )
})

export default A
