import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, media, multiMedia } from 'fractals'
import zIndex from '@mui/material/styles/zIndex'
import { TH2Props } from './types'
import { VARIANTS_KEYS } from './constants'

const H2 = ({ variant, ...props }: TH2Props) => {
  const stylesVariants = {
    // use this for HomePage Faculty and HomePage Diplomas
    [VARIANTS_KEYS.alpha]: {
      ...multiMedia({
        desktop: { marginBottom: toUnit(48), fontSize: '48px' },
        laptop: { marginBottom: toUnit(24), fontSize: '32px' },
        tablet: { marginBottom: toUnit(24), fontSize: '32px' },
        phone: { marginBottom: toUnit(24), fontSize: '32px' }
      }),
      fontWeight: 400,
      lineHeight: '100%'
    },
    // use this for HomePage Mission, FAQ, and Journal
    [VARIANTS_KEYS.beta]: {
      ...multiMedia({
        marginBottom: toUnit(24),
        fontSize: toUnit(40),
        tablet: { fontSize: toUnit(48), marginBottom: toUnit(32) },
        laptop: { fontSize: toUnit(48), marginBottom: toUnit(32) },
        desktop: { fontSize: toUnit(64), marginBottom: toUnit(56) }
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    // use this for HomePage WhyUs
    [VARIANTS_KEYS.gamma]: {
      ...media('marginBottom', toUnit(24), {
        tablet: toUnit(32),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      ...media('fontSize', toUnit(32), {
        tablet: toUnit(40),
        laptop: toUnit(40),
        desktop: toUnit(48)
      }),
      fontWeight: 400,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.delta]: {
      ...media('marginBottom', toUnit(32), {
        tablet: toUnit(16),
        laptop: toUnit(40),
        desktop: toUnit(40)
      }),
      ...media('fontSize', toUnit(18), {
        tablet: toUnit(20),
        laptop: toUnit(20),
        desktop: toUnit(24)
      }),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.epsilon]: {
      ...media('marginBottom', toUnit(32 - 16), {
        laptop: toUnit(56),
        desktop: toUnit(56)
      }),
      ...media('fontSize', toUnit(32), {
        desktop: toUnit(48)
      }),
      fontWeight: 500,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.zeta]: {
      ...media('marginBottom', toUnit(24), {
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.eta]: {
      // marginBottom: toUnit(16),
      ...media('fontSize', toUnit(48), {
        tablet: toUnit(40),
        phone: toUnit(27)
      }),
      fontWeight: 400,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.theta]: {
      fontSize: '64px',
      fontWeight: 500,
      lineHeight: '120%',
      ...multiMedia({
        tablet: {
          fontSize: '48px'
        },
        phone: {
          fontSize: '40px',
          lineHeight: '100%'
        }
      })
    },
    [VARIANTS_KEYS.jota]: {
      ...media('marginBottom', toUnit(32), {
        tablet: toUnit(40),
        laptop: toUnit(32),
        desktop: toUnit(32)
      }),
      fontWeight: 400,
      ...multiMedia({
        phone: { fontSize: '32px', lineHeight: '32px' },
        tablet: { fontSize: '40px', lineHeight: '40px' },
        laptop: { fontSize: '48px', lineHeight: '48px' },
        desktop: { fontSize: '48px', lineHeight: '48px' }
      }),
      textAlign: 'center'
    },
    [VARIANTS_KEYS.phi]: {
      ...multiMedia({
        desktop: { fontSize: '48px' },
        laptop: { fontSize: '40px' },
        tablet: { fontSize: '32px' },
        phone: { fontSize: '24px' }
      }),
      fontWeight: 400,
      lineHeight: '120%',
      marginBottom: '0'
    },
    [VARIANTS_KEYS.journalMain]: {
      fontSize: '36px',
      fontWeight: 500,
      marginBottom: '16px',
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.journalMainBelow]: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.zetaA]: {
      marginBottom: 0,
      ...media('fontSize', '24px', {
        desktop: '36px'
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.alphaA]: {
      marginBottom: 0,
      ...media('fontSize', '36px', {
        desktop: '46px',
        phone: '32px'
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.whyUsTitle]: {
      ...multiMedia({
        desktop: { fontSize: '48px', marginBottom: '56px', width: '60%' },
        laptop: { fontSize: '40px', marginBottom: '32px', width: '60%' },
        tablet: { fontSize: '40px', marginBottom: '39px' },
        phone: { fontSize: '32px', marginBottom: '40px' }
      }),
      fontWeight: 400,
      lineHeight: '100%'
    },
    [VARIANTS_KEYS.reviewTitle]: {
      fontSize: '48px',
      ...multiMedia({
        marginBottom: '32px',
        textAlign: 'center',
        desktop: { fontSize: '64px', marginBottom: '56px' },
        phone: { fontSize: '40px', marginBottom: '40px', textAlign: 'start' }
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.mediaTitle]: {
      fontSize: '48px',
      ...multiMedia({
        textAlign: 'center',
        desktop: { fontSize: '64px', marginBottom: '56px' },
        laptop: { marginBottom: '48px' },
        tablet: { marginBottom: '40px' },
        phone: { fontSize: '40px', marginBottom: '32px', textAlign: 'start' }
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.journalTitle]: {
      fontSize: '48px',
      marginBottom: '32px',
      ...multiMedia({
        desktop: { fontSize: '64px', marginBottom: '56px' },
        phone: { fontSize: '40px', marginBottom: '40px' }
      }),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.fullContentTitle]: {
      fontSize: '64px',
      lineHeight: '60.48px',
      marginBottom: '32px',
      ...multiMedia({
        phone: { fontSize: '40px', lineHeight: '37.8px', marginBottom: '24px' }
      }),
      fontWeight: 400
    },
    [VARIANTS_KEYS.communitySupportTitle]: {
      fontSize: '48px',
      marginBottom: '0',
      ...multiMedia({
        laptop: { gridRow: '1 / 2', gridColumn: '1 / 3' },
        tablet: { fontSize: '40px', paddingInlineEnd: '4px' },
        phone: { fontSize: '40px', paddingInlineEnd: '18px' }
      }),
      fontWeight: 400,
      lineHeight: '100%',
      color: 'var(--alpha)',
      gridArea: 'title',
      zIndex: 1
    },
    [VARIANTS_KEYS.howTrainingWorksTitle]: {
      marginBottom: '0',
      fontSize: '64px',
      fontWeight: 500,
      lineHeight: '120%',
      color: 'var(--omega)',
      textAlign: 'center',
      ...multiMedia({
        desktop: { justifySelf: 'start' },
        laptop: { fontSize: '48px', lineHeight: '90%' },
        tablet: { fontSize: '48px', lineHeight: '90%' },
        phone: { fontSize: '40px', lineHeight: '90%' }
      })
    },
    [VARIANTS_KEYS.studyIsForPsychologyTitle]: {
      marginBottom: 0,
      fontSize: toUnit(64),
      lineHeight: '90%',
      fontWeight: 500,
      textAlign: 'center',
      ...multiMedia({
        phone: { fontSize: toUnit(40) },
        tablet: { fontSize: toUnit(40) },
        laptop: { fontSize: toUnit(48) }
      })
    }
  } as const

  const styles = {
    marginTop: 0,
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.H2 {...props} styles={styles} />
}

export default H2
