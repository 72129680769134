// components/SmoothScrollLink.tsx
import React from 'react'
import { StyledElement } from 'ui/modules/styled-jsx/widgets'

type SmoothScrollLinkProps = {
  targetId: string
  children: React.ReactNode
  //   className?: string
}

export const SmoothScrollLink: React.FC<SmoothScrollLinkProps> = ({
  targetId,
  children
  //   className
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    const targetElement = document.getElementById(targetId)

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <a href={`#${targetId}`} onClick={handleClick}>
      {children}
    </a>
  )
}

export default SmoothScrollLink
