import { useEffect, useState } from 'react'

const useAppRootElement = () => {
  const [bodyElement, setBodyElement] = useState<HTMLElement | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setBodyElement(document?.querySelector('body'))
  }, [])

  useEffect(() => {
    if (bodyElement) {
      let root = Array.from(bodyElement.children).find(
        element =>
          Object.keys(element).some(key => /^__reactContainer/.test(key)),
        null
      )

      if (root) {
        root?.setAttribute('data-modal-root-element', 'true')
        setMounted(true)
      } else {
        root = bodyElement.firstElementChild as Element
        root?.setAttribute('data-modal-root-element', 'true')
        setMounted(true)
      }
    }
  }, [bodyElement])

  return mounted
}

export default useAppRootElement
