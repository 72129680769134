import { LOCALES, TLocaleKeys } from 'fractals'

export const checkIfCorrectLocaleKey = (key: string | null | undefined) => {
  const isCorrectLocaleKey = Object.values(LOCALES).some(
    locale => locale === key
  )

  if (!isCorrectLocaleKey) throw new Error(`Incorrect locale key: ${key}`)

  return key as TLocaleKeys // TODO: remove this type assertion and figure out TS inferrence
}
