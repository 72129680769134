import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetSeoDataProps, TResCategories, TResFaculties } from '../types'

export const fetchProgramsSeoData = async ({ context }: TGetSeoDataProps) => {
  const { currentCategorySlug, currentFacultySlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  const queryCategories = qs.stringify({
    fields: [
      'name',
      'label',
      'slug',
      'notUniqueSlug',
      'slugSingular',
      'notUniqueSlugSingular',
      'rank'
    ],
    populate: {
      seo: {
        fields: [
          'metaTitle',
          'metaRobots',
          'isSEOFriendly',
          'metaDescription',
          'canonicalURL',
          // 'metaImage',
          'keywords'
        ]
      }
    },
    filters: {
      slug: {
        $eq: currentCategorySlug
      }
    },
    locale: context.locale
  })

  const resCategories = await fetcher<TResCategories>(
    `${PATHS.back.root.index}${PATHS.back.api.categories.index}?${queryCategories}`
  )

  const queryFaculties = qs.stringify({
    fields: ['name', 'label', 'slug', 'rank'],
    populate: {
      seo: {
        fields: [
          'metaTitle',
          'metaRobots',
          'isSEOFriendly',
          'metaDescription',
          'canonicalURL',
          // 'metaImage',
          'keywords'
        ]
      }
    },
    filters: {
      slug: {
        $eq: currentFacultySlug
      }
    },
    locale: context.locale
  })

  const resFaculties = await fetcher<TResFaculties>(
    `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${queryFaculties}`
  )

  return {
    categories: resCategories?.data || null,
    faculties: resFaculties?.data || null
  }
}

export default fetchProgramsSeoData
