// eslint-disable-next-line import/no-cycle
export { Programs } from './Programs'
export {
  getProgramsData,
  getProgramsDataFaculties,
  getBannerData,
  getProgramsQuestions,
  getProgramsVideo
} from './fractals'
export { HEADING } from './fractals/index'
