import { setCookie } from 'cookies-next'
import { NextRouter } from 'next/router'

type TypeHandleUtmsProps = {
  readonly route: NextRouter
}

export const handleCookiesExpiration = ({ route }: TypeHandleUtmsProps) => {
  const utms = route.asPath
    .split('?')?.[1]
    ?.split('&')
    ?.map(el => ({ [el.split('=')[0] as string]: el.split('=')[1] }))

  const cookieExpiration = new Date()
  cookieExpiration.setDate(cookieExpiration.getDate() + 90)

  if (utms) {
    utms.forEach(utm => {
      if (utm) {
        Object.keys(utm).forEach(key => {
          const value = utm[key]
          if (value) {
            setCookie(key, value, {
              expires: cookieExpiration,
              path: '/'
            })
          }
        })
      }
    })
  }
}

export default handleCookiesExpiration
