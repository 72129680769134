import { toUnit as createToUnit } from 'ui'
import { THEME } from 'fractals'
import { TToUnitOptions } from './types'
import { DECIMAL_POINTS, UNIT } from './constants'

export const toUnit = (
  value: number | undefined,
  { unit = UNIT }: TToUnitOptions = {
    unit: UNIT
  }
) => {
  if (value === 0) return value
  if (!value) return undefined

  return createToUnit({
    value,
    unit,
    rootFontSize: THEME.rootFontSize,
    decimalPoints: DECIMAL_POINTS
  })
}
