import React from 'react'
import { Div, Span } from '@/fractals/components'
import { media, multiMedia } from '@/fractals/utils'
import { TPopupThanksSpan } from './types'

export const PopupThanksSpan = ({ onForward }: TPopupThanksSpan) => {
  return (
    <Div
      styles={{
        position: 'absolute',
        display: 'flex',
        color: '#FFFFFF',
        zIndex: 6,
        flexDirection: 'column',
        top: '50%',
        ...multiMedia({
          desktop: {
            left: '32px'
          },
          laptop: {
            left: '32px'
          },
          tablet: {
            left: '24px'
          },
          phone: {
            left: '24px'
          }
        }),
        gap: '16px'
      }}>
      <Span
        styles={{
          ...media('fontSize', '24px', {
            phone: '18px'
          })
        }}>
        Спасибо! Файлы отправлены Вам на почту
      </Span>
      <Span
        onClick={prev => onForward(!prev)}
        styles={{
          fontSize: '14px',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}>
        Вернуться назад
      </Span>
    </Div>
  )
}

export default PopupThanksSpan
