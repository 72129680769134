import { TGetCostDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getCostData = async ({ context }: TGetCostDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getCostData
