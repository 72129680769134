const Category = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.04 2H8.42C9.83 2 10.96 3.15 10.96 4.561V7.97C10.96 9.39 9.83 10.53 8.42 10.53H5.04C3.64 10.53 2.5 9.39 2.5 7.97V4.561C2.5 3.15 3.64 2 5.04 2ZM5.04 13.4697H8.42C9.83 13.4697 10.96 14.6107 10.96 16.0307V19.4397C10.96 20.8497 9.83 21.9997 8.42 21.9997H5.04C3.64 21.9997 2.5 20.8497 2.5 19.4397V16.0307C2.5 14.6107 3.64 13.4697 5.04 13.4697ZM19.9601 2H16.5801C15.1701 2 14.0401 3.15 14.0401 4.561V7.97C14.0401 9.39 15.1701 10.53 16.5801 10.53H19.9601C21.3601 10.53 22.5001 9.39 22.5001 7.97V4.561C22.5001 3.15 21.3601 2 19.9601 2ZM16.5801 13.4697H19.9601C21.3601 13.4697 22.5001 14.6107 22.5001 16.0307V19.4397C22.5001 20.8497 21.3601 21.9997 19.9601 21.9997H16.5801C15.1701 21.9997 14.0401 20.8497 14.0401 19.4397V16.0307C14.0401 14.6107 15.1701 13.4697 16.5801 13.4697Z'
      fill='#3A52EE'
    />
  </svg>
)

export default Category
