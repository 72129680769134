import { useEffect, useReducer } from 'react'
import { useRouter } from 'next/router'
import { useAt } from '../useAt'
// eslint-disable-next-line import/no-cycle
import {
  Action,
  State,
  UsePaginationOptions,
  UsePaginationResult
} from './types'

export function usePagination<TData>({
  initialAmount = 11,
  incrementAmount = 5,
  fetchDataFunc,
  locale = 'ru',
  initialData
}: UsePaginationOptions<TData>): UsePaginationResult<TData> {
  const { query } = useRouter()
  const { currentJournalsByFilterSlug } = useAt()
  const [stateData, dispatch] = useReducer(
    (state: State<TData>, action: Action<TData>): State<TData> => {
      switch (action.type) {
        case 'FETCH_START':
          return { ...state, status: 'loading' }
        case 'FETCH_SUCCESS':
          return { ...state, data: action.payload, status: null }
        case 'FETCH_ERROR':
          console.error(action.error)
          return { ...state, status: null }
        case 'SHOW_MORE':
          return { ...state, amount: state.amount + incrementAmount }
        default:
          return state
      }
    },
    {
      amount: initialAmount,
      data: initialData,
      status: null as 'loading' | null
    }
  )

  useEffect(() => {
    const abortController = new AbortController()

    dispatch({ type: 'FETCH_START' })
    fetchDataFunc({
      context: { locale },
      amount: stateData.amount,
      filterBy: currentJournalsByFilterSlug
    }).then(
      data => {
        if (!abortController.signal.aborted) {
          dispatch({ type: 'FETCH_SUCCESS', payload: data })
        }
      },
      error => {
        if (!abortController.signal.aborted) {
          dispatch({ type: 'FETCH_ERROR', error })
        }
      }
    )

    return () => {
      abortController.abort()
    }
  }, [
    stateData.amount,
    fetchDataFunc,
    locale,
    initialData,
    query,
    currentJournalsByFilterSlug
  ])

  const showMore = () => {
    dispatch({ type: 'SHOW_MORE' })
  }

  return { ...{ stateData }, showMore }
}

export default usePagination
