import { FC } from 'react'
import { TStyledBannerCircleProps } from './types'
import { multiMedia } from '@/fractals/utils'
import { Div } from '@/fractals/components'

const BannerCircle: FC<TStyledBannerCircleProps> = ({ color }) => (
  <Div
    styles={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: color,
      borderRadius: '50%',
      maxWidth: '192px',
      maxHeight: '192px',
      top: '-41px',
      left: '-43px',
      zIndex: -1,
      ...multiMedia({
        laptop: {
          top: '-52px',
          left: '-59px',
          maxWidth: '148px',
          maxHeight: '148px'
        }
      })
    }}
  />
)

export default BannerCircle
