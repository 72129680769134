import React from 'react'
import { Image } from 'fractals'
import { TCurrentTagProps } from './types'
import { Div } from '@/fractals/components'

export const CurrentTag = ({ faculty, tag, styles }: TCurrentTagProps) => {
  return (
    <Div
      styles={{
        ...styles,
        position: 'relative',
        width: (faculty?.tagImage?.width || tag?.tagImage?.width) ?? '138px',
        minWidth: (faculty?.tagImage?.width || tag?.tagImage?.width) ?? '138px',
        height: (faculty?.tagImage?.height || tag?.tagImage?.height) ?? '30px'
      }}>
      <Image
        src={faculty?.tagImage?.url || tag?.tagImage?.url || '/'}
        alt={
          faculty?.tagImage?.alternativeText ||
          tag?.tagImage?.alternativeText ||
          ''
        }
        fill
        containerProps={{
          styles: {
            borderRadius: '8px',
            '& img': {
              objectFit: 'cover'
            }
          }
        }}
        colorVariant='transparent'
      />
    </Div>
  )
}

export default CurrentTag
