import { NextRouter } from 'next/router'

export type TypeHandleUtmsProps = {
  readonly router: NextRouter
}

export const handleUtms = ({ router }: TypeHandleUtmsProps) => {
  const utms = JSON.parse(sessionStorage.getItem('utms') || '{}')
  let utmsAreEmpty = false

  Object.keys(utms).forEach(key => {
    // TODO: fix this eslint-disable-next-line
    // eslint-disable-next-line
    if (utms.hasOwnProperty(key)) {
      utmsAreEmpty = true
    }
  })

  // for (const key in utms) {
  //   if (utms.hasOwnProperty(key)) {
  //     utmsAreEmpty = true
  //     break
  //   }
  // }

  if (!utmsAreEmpty) {
    const urlUtmsArr = router.asPath.split('?')[1]

    if (urlUtmsArr) {
      urlUtmsArr.split('&').forEach(utm => {
        // TODO: fix this eslint-disable-next-line
        // eslint-disable-next-line
        utms[utm.split('=')[0] as string] = utm.split('=')[1]
      })
    }

    sessionStorage.setItem('utms', JSON.stringify(utms))
  }
}

export default handleUtms
