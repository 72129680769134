import React from 'react'
import { media } from 'fractals'
import { P } from '@/fractals/components'

export const ListDescriptionItem = ({ content }: { content: string }) => {
  return (
    <P
      styles={{
        marginLeft: '2.5rem',
        fontSize: '18px',
        ...media('marginTop', '16px', {
          laptop: '16px',
          desktop: '8px'
        })
      }}
      variant='alpha'
      dangerouslySetInnerHTML={{ __html: content }}>
      {null}
    </P>
  )
}

export default ListDescriptionItem
