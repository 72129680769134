import { parseGeneralSlug } from 'fractals'
import { TGetFAQDataProps } from './types'
import {
  fetchPrograms,
  normalizeProgramsData,
  fetchQuestions,
  normalizeQuestionsData
} from './utils'

export const getFAQData = async ({ context }: TGetFAQDataProps) => {
  try {
    const { currentProgramSlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    if (currentProgramSlug) {
      const programsData = await fetchPrograms({ context })

      const programsDataNormalized = normalizeProgramsData({ programsData })

      return programsDataNormalized || null
    }

    const questionsData = await fetchQuestions({ context })

    const questionsDataNormalized = normalizeQuestionsData({ questionsData })

    return questionsDataNormalized
  } catch (e) {
    console.log('%cgetFAQData.ts line:14 e', 'color: #007acc;', e)
  }
  return null
}

export default getFAQData
