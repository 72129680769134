import { sortBasedOnKey } from 'fractals'
import { fetchQuestions } from './fetchQuestions'

export const normalizeQuestionsData = ({
  questionsData
}: {
  questionsData: Awaited<ReturnType<typeof fetchQuestions>>
}) => {
  const questions =
    questionsData?.map(item => ({
      name: item.name || null,
      answer: item.answer || null,
      rank: item.rank || item.rank === 0 ? item.rank : null
    })) || null

  if (!questions) return null

  const output = sortBasedOnKey(questions, 'rank')
  return output
}

export default normalizeQuestionsData
