export {
  FOR_1_TITLE,
  FOR_1,
  FOR_2_TITLE,
  FOR_2,
  FOR_3_TITLE,
  FOR_3,
  FOR_4_TITLE,
  FOR_4
} from './constants'
export type { TForWhomProps } from './types'
export { Vector, Rounds } from './components'
export { graduate, peopleLaughing } from './images'
export { getForWhomData } from './api'
