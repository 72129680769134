import { TObjKey } from 'ui'

export const sortBasedOnKey = <
  T extends Array<Record<TObjKey, unknown>> | undefined
>(
  arr: T,
  key: T extends Array<Record<TObjKey, unknown>> ? keyof T[number] : never
) =>
  (arr &&
    [...arr].sort(
      (a, b) =>
        (((a?.[key] !== 0 && !a?.[key]) || (b?.[key] !== 0 && !b?.[key])) &&
          -1) ||
        Number(a[key]) - Number(b[key])
    )) ||
  arr

export default sortBasedOnKey
