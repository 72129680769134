import { useEffect, useState } from 'react'
import { Link, useEdpartnersLead } from 'fractals'
import { Layout } from 'ui'
// TODO: fix this import
// eslint-disable-next-line import/no-cycle
import { PhoneTitle, PhoneNumber } from '../..'
import { TPhoneWithTitleProps } from '../../../types'

const PhoneWithTitle = ({ phoneNumbers }: TPhoneWithTitleProps) => {
  const isEdpartnersLead = useEdpartnersLead()

  return (
    <>
      {phoneNumbers.map((phoneNumber, idx) => (
        <Layout.Flex
          key={`PhoneWithTitle__${phoneNumber.phoneNumber}--${idx + 1}`}
          cols='auto'
          hidden={isEdpartnersLead}>
          <Link
            styles={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}
            variant='alpha'
            href={`tel:${phoneNumber.phoneNumber}`}>
            <>
              {phoneNumber?.label && (
                <PhoneTitle>{phoneNumber.label}</PhoneTitle>
              )}
              <PhoneNumber>{phoneNumber.phoneNumber}</PhoneNumber>
            </>
          </Link>
        </Layout.Flex>
      ))}
    </>
  )
}

export default PhoneWithTitle
