import { sortBasedOnKey } from 'fractals'
import { GetStaticPropsContext } from 'next'
import { fetchQuestions } from './fetchQuestions'
import { TQuestion } from '../../getFacultiesData/types'
import { fetchCategoryQuestions } from './fetchCategoryQuestions'
import { parseGeneralSlug } from '@/fractals/utils'

const normalize = (question: TQuestion) => ({
  name: question.name || null,
  answer: question.answer || null,
  rank: question.rank || question.rank === 0 ? question.rank : null
})

export const normalizeQuestions = ({
  questionsData,
  context
}: {
  questionsData: Awaited<
    ReturnType<typeof fetchQuestions | typeof fetchCategoryQuestions>
  >
  context: GetStaticPropsContext
}) => {
  const { isBachelor, isMaster, isCourse } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  if (!questionsData) return null

  let normalizedQuestions: TQuestion[] | null = null

  if (questionsData.questions) {
    normalizedQuestions = questionsData.questions.map(normalize)
  }

  if (
    'questionsBakalavriat' in questionsData &&
    questionsData.questionsBakalavriat &&
    isBachelor
  ) {
    normalizedQuestions = questionsData.questionsBakalavriat.map(normalize)
  }

  if (
    'questionsMagistratura' in questionsData &&
    questionsData.questionsMagistratura &&
    isMaster
  ) {
    normalizedQuestions = questionsData.questionsMagistratura.map(normalize)
  }

  if (
    'questionsKursy' in questionsData &&
    questionsData.questionsKursy &&
    isCourse
  ) {
    normalizedQuestions = questionsData.questionsKursy.map(normalize)
  }

  if (!normalizedQuestions) return null

  const output = sortBasedOnKey(normalizedQuestions, 'rank')
  return output
}

export default normalizeQuestions
