import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconTickProps } from './types'

const IconTick = ({ direction, sizeVariant, ...props }: TIconTickProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      ...((sizeVariant === 'xl' && {
        width: '16px',
        height: '16px'
      }) ||
        (sizeVariant === 'medium' && { width: '16px', height: '16px' }) ||
        undefined),
      transform:
        (direction === 'upRight' && 'rotate(45deg)') ||
        (direction === 'right' && 'rotate(90deg)') ||
        (direction === 'downRight' && 'rotate(135deg)') ||
        (direction === 'down' && 'rotate(180deg)') ||
        (direction === 'downLeft' && 'rotate(225deg)') ||
        (direction === 'left' && 'rotate(270deg)') ||
        (direction === 'leftAnotherPath' && 'rotate(0deg)') ||
        (direction === 'upLeft' && 'rotate(315deg)') ||
        undefined,
      transition: 'transform 0.3s'
    },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      {/* <svg
        width='11'
        height='7'
        viewBox='0 0 11 7'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13 7L7 0.999999L1 7'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg> */}

      <svg
        width={(sizeVariant === 'xl' && '1em') || '17'}
        height={(sizeVariant === 'xl' && '1em') || '17'}
        viewBox={(sizeVariant === 'xl' && '0 0 1024 1024') || '0 0 17 17'}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        {(sizeVariant === 'xl' && (
          <path
            stroke='black'
            strokeWidth='50'
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M752.145 0c8.685 0 17.572 3.434 24.237 10.099 13.33 13.33 13.33 35.143 0 48.473L320.126 515.03l449.591 449.591c13.33 13.33 13.33 35.144 0 48.474-13.33 13.33-35.142 13.33-48.472 0L247.418 539.268c-13.33-13.33-13.33-35.144 0-48.474L727.91 10.1C734.575 3.435 743.46.002 752.146.002z'
          />
        )) || (
          <path
            d='M4.5 6.31909L8.5 10.3191L12.5 6.31909'
            stroke='black'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        )}
      </svg>
    </IconContainer>
  )
}

export default IconTick
