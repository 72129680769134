import { media } from 'fractals'

export const journalsPageHeaderStyles = {
  'div > div': {
    ...media('gap', '24px', {
      tablet: '32px',
      laptop: '32px',
      desktop: '56px'
    })
  },
  '& > div': {
    ...media('width', '1216px', {
      laptop: '800px',
      tablet: '640px',
      phone: '343px'
    }),
    padding: 0
  }
}
