import { sortBasedOnKey } from 'fractals'
import { fetchPrograms } from './fetchPrograms'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchPrograms>>
}) => {
  const questions =
    programsData?.[0]?.questions?.data?.map(item => ({
      name: item.name || null,
      answer: item.answer || null,
      rank: item.rank || item.rank === 0 ? item.rank : null
    })) || null

  if (!questions) return null

  const output = sortBasedOnKey(questions, 'rank')
  return output
}

export default normalizeProgramsData
