export const VARIANTS_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta',
  epsilon: 'epsilon',
  zetaA: 'zetaA',
  alphaA: 'alphaA',
  betaA: 'betaA',
  alphaMainScreen: 'alphaMainScreen',
  eta: 'eta',
  phi: 'phi'
} as const
