import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconEntryProps } from './types'

const IconEntry = ({ ...props }: TIconEntryProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='27'
        viewBox='0 0 24 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='button / entry'>
          <path
            id='Union'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9 23.4451C9.41421 23.4451 9.75 23.7809 9.75 24.1951C9.75 24.6093 9.41421 24.9451 9 24.9451H8.4H8.367C7.27485 24.9451 6.40936 24.9451 5.71173 24.8881C4.99834 24.8299 4.39472 24.7082 3.84354 24.4274C2.94978 23.972 2.22312 23.2454 1.76772 22.3516C1.48688 21.8004 1.36527 21.1968 1.30699 20.4834C1.24999 19.7858 1.24999 18.9203 1.25 17.8281V17.8281V17.7951V9.81772V9.78473V9.78469C1.25 8.69256 1.24999 7.82707 1.30699 7.12946C1.36527 6.41607 1.48688 5.81244 1.76772 5.26127C2.22312 4.3675 2.94978 3.64084 3.84355 3.18544C4.39472 2.90461 4.99835 2.783 5.71173 2.72471C6.40937 2.66772 7.2749 2.66772 8.36708 2.66772H8.4L9 2.66772C9.41421 2.66772 9.75 3.00351 9.75 3.41772C9.75 3.83194 9.41421 4.16772 9 4.16772H8.4C7.26752 4.16772 6.46327 4.16831 5.83388 4.21973C5.21325 4.27044 4.829 4.36682 4.52453 4.52195C3.913 4.83354 3.41582 5.33073 3.10423 5.94226C2.94909 6.24673 2.85271 6.63098 2.80201 7.25161C2.75058 7.88099 2.75 8.68524 2.75 9.81772L2.75 17.7951C2.75 18.9276 2.75058 19.7319 2.80201 20.3612C2.85271 20.9819 2.94909 21.3661 3.10423 21.6706C3.41582 22.2821 3.913 22.7793 4.52453 23.0909C4.829 23.246 5.21325 23.3424 5.83388 23.3931C6.46326 23.4445 7.26752 23.4451 8.4 23.4451H9ZM15.5062 20.9213C15.8118 20.6418 15.8331 20.1674 15.5535 19.8617L10.7026 14.5565H23.0001C23.4143 14.5565 23.7501 14.2207 23.7501 13.8065C23.7501 13.3923 23.4143 13.0565 23.0001 13.0565H10.7017L15.5535 7.75035C15.8331 7.44466 15.8118 6.97026 15.5062 6.69075C15.2005 6.41123 14.7261 6.43245 14.4466 6.73813L8.44656 13.2999L7.98378 13.806L8.43128 14.2954C8.4412 14.307 8.45147 14.3182 8.46206 14.3291L14.4466 20.8739C14.7261 21.1796 15.2005 21.2008 15.5062 20.9213Z'
            fill='#18191A'
          />
        </g>
      </svg>
    </IconContainer>
  )
}

export default IconEntry
