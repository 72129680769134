import { Section, Container, toUnit, Link, Span, IconArrow } from 'fractals'
import { slugify } from 'ui'
import { TBreadcrumbsProps } from './types'

export const Breadcrumbs = ({ pages }: TBreadcrumbsProps) => {
  return (
    <Section
      id={slugify(pages.map(page => page.title).join(' '))}
      spacingVariant='epsilon'
      styles={{
        overflowX: 'hidden'
      }}>
      <Container>
        {pages
          .filter(page => page.href && page.title)
          .map((page, idx, arr) => (
            <Span key={`Breadcrumbs__${page.title}--${idx + 1}`}>
              <Link
                variant='alpha'
                href={page.href || '#'}
                styles={{
                  fontSize: toUnit(14),
                  fontWeight: 500,
                  lineHeight: '120%',
                  color: 'var(--upsilon)'
                }}>
                {page.title}
              </Link>
              {idx !== arr.length - 1 && <IconArrow direction='right' />}
            </Span>
          ))}
      </Container>
    </Section>
  )
}

export default Breadcrumbs
