export const VARIANTS_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta',
  epsilon: 'epsilon',
  omega: 'omega',
  omegaBurger: 'omegaBurger',
  zeta: 'zeta'
} as const
