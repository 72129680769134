import { useEffect } from 'react'
import { Shared } from 'modules'
import styled from 'styled-components'
import Link from 'next/link'
import Calling from './icons/Calling'
import Category from './icons/Category'
import Chat from './icons/Chat'

const MenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 76px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 24px 24px 0 0; /* Adjusted border-radius for softer corners */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Adjusted for smoother shadow */
  z-index: 1000; /* Ensure the menu stays on top */

  @media (min-width: 769px) {
    display: none; /* Hide on screens wider than 768px */
  }
`

const MenuItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--beta);
  text-decoration: none;
  font-size: 14px;

  svg {
    margin-bottom: 4px;
    color: var(--beta);
  }
`

const MenuItemButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--beta);
  text-decoration: none;
  font-size: 14px;

  svg {
    margin-bottom: 4px;
    color: var(--beta);
  }
`

const MenuBottomMobile = () => {
  useEffect(() => {
    const adjustChatButton = (chatButton: HTMLElement) => {
      const styles = {
        bottom: '160px',
        right: '31px',
        position: 'fixed'
      }
      Object.assign(chatButton.style, styles)
    }

    const intervalId = setInterval(() => {
      const widget = document.querySelector('fstrk-widget')
      if (widget) {
        const { shadowRoot } = widget
        if (!shadowRoot) return

        const chatButton = shadowRoot.querySelector(
          '.fstrk-Indicator-root'
        ) as HTMLElement | null
        if (chatButton) {
          adjustChatButton(chatButton)
          clearInterval(intervalId) // Виджет найден, можно остановить выполнение
        }
      }
    }, 500) // Проверяем каждые 500 мс

    return () => clearInterval(intervalId)
  }, [])

  return (
    <MenuContainer>
      <Shared.LeadPopup
        buttonProps={{
          colorsVariant: 'alpha',
          styles: { padding: 0 },
          children: (
            <MenuItemButton>
              <Chat />
              Помощь
            </MenuItemButton>
          )
        }}
      />
      <MenuItem href='/programmy'>
        <Category />
        Программы
      </MenuItem>
      <MenuItem href='/contact'>
        <Calling />
        Контакты
      </MenuItem>
    </MenuContainer>
  )
}

export default MenuBottomMobile
