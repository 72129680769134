import { LOCALES } from 'fractals'

export const SEO_TITLE = {
  [LOCALES.en]: 'Magazine | Moscow Institute of Technology and Management',
  [LOCALES.ru]: 'Журнал | Московский Институт Технологий и Управления',
  [LOCALES.uz]: `Jurnal | Moskva texnologiya va menejment instituti`,
  [LOCALES.kk]: 'Jwrnal | Mäskew texnologïya jäne menedjment ïnstïtwtı'
} as const

export const SEO_DESCRIPTION = {
  [LOCALES.ru]:
    'Journal MITM.institute ➜ Articles about design, psychology, economics, applied computer science, management, advanced training, education and professional growth.',
  [LOCALES.en]:
    'Blog article of the online institute MITM. Read the latest publications on current topics: psychology, design, marketing, construction, management, economics, education and much more.',
  [LOCALES.uz]: `MITM.institute jurnali ➜ Dizayn, psixologiya, iqtisod, amaliy informatika, menejment, malaka oshirish, ta'lim va kasbiy o'sish haqidagi maqolalar.`,
  [LOCALES.kk]:
    'MITM.institute jwrnalı ➜ Dïzayn, psïxologïya, ékonomïka, qoldanbalı ïnformatïka, menedjment, biliktilikti arttırw, bilim berw jäne käsibï ösw twralı maqalalar.'
} as const
