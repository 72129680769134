import { FC } from 'react'
import { Div, Span } from '@/fractals/components'
import { CategoryInfoProps } from './fractals'
import { CategoryTooltip } from '../../atoms'
import { useMedia } from '@/fractals/hooks'

const CategoryInfo: FC<CategoryInfoProps> = ({
  content,
  label,
  color,
  hoverColor,
  ...props
}) => {
  const { isMobile } = useMedia()
  return (
    <Div {...props}>
      {label && !isMobile && (
        <Span variant='beta' styles={{ marginRight: '8px' }}>
          {label}
        </Span>
      )}

      <CategoryTooltip
        content={content}
        color={color}
        hoverColor={hoverColor}
      />
    </Div>
  )
}

export default CategoryInfo
