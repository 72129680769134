import * as React from 'react'
import { StyledJSX } from 'ui'
import { TMainProps } from './types'

const Main = ({
  /* variant = VARIANTS_KEYS.alpha, */ ...props
}: TMainProps) => {
  // const stylesVariants = {
  //   [VARIANTS_KEYS.alpha]: {}
  // } as const

  const styles = {
    // DEFAULT STYLES WOULD GO HERE
    // ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Main {...props} styles={styles} />
}

export default Main
