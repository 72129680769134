import * as React from 'react'
import { Image } from 'fractals'
import vkontakte from './vkontakte.png'

const IconVK = () => {
  return (
    <Image
      id='vkontakte__icon'
      colorVariant='transparent'
      src={vkontakte.src || '/'}
      width={vkontakte.width}
      height={vkontakte.height}
      alt={vkontakte.blurDataURL || `vkontakte__icon`}
      containerProps={{
        styles: {
          '& img': {
            position: 'relative',
            objectFit: 'cover'
          }
        }
      }}
    />
  )
}

export default IconVK
