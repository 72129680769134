import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconBelowHeaderDecorationProps } from './types'

const IconBelowHeaderDecoration = ({
  colorsVariant,
  ...props
}: TIconBelowHeaderDecorationProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        viewBox='0 0 1440 160'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_810_2932)'>
          <rect width='1440' height='160' fill='#4BAC89' />
          <g clipPath='url(#clip1_810_2932)'>
            <path
              d='M739.227 80.3437C739.227 48.1191 765.007 22.1518 796.999 22.1518V-0.687012H642.941V161.687H796.999V138.536C765.007 138.536 739.227 112.568 739.227 80.3437Z'
              fill='#F1AE2B'
            />
            <path
              d='M739.228 80.3431C739.228 112.568 765.007 138.535 796.999 138.535V22.1511C765.007 22.1511 739.228 48.1185 739.228 80.3431Z'
              fill='#FB5449'
            />
            <path
              d='M481.742 161.687H401.918C401.918 117.26 437.637 81.2816 481.742 81.2816V161.687Z'
              fill='#F1AE2B'
            />
            <path
              d='M322.405 161.687V81.2816C366.51 81.2816 402.23 117.26 402.23 161.687H322.405Z'
              fill='#FB5449'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M643.563 161.687H563.739V-0.68689H643.563C687.669 -0.68689 723.388 35.292 723.388 79.7181V81.2824C723.388 125.708 687.669 161.687 643.563 161.687Z'
              fill='#3A52EE'
            />
            <path
              d='M362.783 80.6567C385.083 80.6567 403.161 62.4473 403.161 39.9849C403.161 17.5225 385.083 -0.68689 362.783 -0.68689C340.483 -0.68689 322.405 17.5225 322.405 39.9849C322.405 62.4473 340.483 80.6567 362.783 80.6567Z'
              fill='#ACBF2F'
            />
            <path
              d='M443.224 80.6567C465.524 80.6567 483.602 62.4473 483.602 39.9849C483.602 17.5225 465.524 -0.68689 443.224 -0.68689C420.924 -0.68689 402.846 17.5225 402.846 39.9849C402.846 62.4473 420.924 80.6567 443.224 80.6567Z'
              fill='#7D5EE4'
            />
            <path
              d='M523.671 80.6567C545.971 80.6567 564.049 62.4473 564.049 39.9849C564.049 17.5225 545.971 -0.68689 523.671 -0.68689C501.371 -0.68689 483.293 17.5225 483.293 39.9849C483.293 62.4473 501.371 80.6567 523.671 80.6567Z'
              fill='#DF6945'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M161.512 -0.68689C205.928 -0.68689 241.957 35.6049 241.957 80.3438C241.957 125.396 205.928 161.687 161.512 161.687V-0.68689Z'
              fill='#DF6945'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M322.404 161.688C277.989 161.688 241.959 125.396 241.959 80.6571C241.959 35.9181 277.989 -0.373657 322.404 -0.373657V161.688V161.688Z'
              fill='#FB5449'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M562.495 162.001H481.739V80.9699L562.495 162.001Z'
              fill='#F1AE2B'
            />
            <path d='M161.512 -1H0V161.687H161.512V-1Z' fill='#3A52EE' />
            <path
              d='M102.871 134.099C132.349 121.8 146.348 87.7585 134.137 58.0657C121.926 28.3729 88.1309 14.2728 58.6526 26.5722C29.1742 38.8716 15.176 72.913 27.3865 102.606C39.5971 132.299 73.3926 146.399 102.871 134.099Z'
              fill='#7D5EE4'
            />
          </g>
          <g clipPath='url(#clip2_810_2932)'>
            <path
              d='M1095 0V80C1139.2 80 1175 44.2023 1175 0H1095Z'
              fill='#3A52EE'
            />
            <path
              d='M1095 160H1175C1175 115.798 1139.2 80 1095 80V160Z'
              fill='#3A52EE'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1016.93 160H1095V0H1016.93C972.768 0 937 35.8674 937 80.1559C937 124.444 972.768 160 1016.93 160Z'
              fill='#3A52EE'
            />
            <path d='M780.57 0H936.863V160H780.57V0Z' fill='#7D5EE4' />
            <path
              d='M1413.03 160V0L1573.03 0V160L1413.03 160Z'
              fill='#7D5EE4'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1252.85 0V160L1332.85 80L1252.85 0Z'
              fill='#7D5EE4'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1252.85 0C1208.68 0 1172.85 35.8301 1172.85 80C1172.85 124.17 1208.68 160 1252.85 160V0V0Z'
              fill='#7D5EE4'
            />
            <path
              d='M1403.24 81.0004C1403.24 60.6143 1419.61 44.2437 1440 44.2437V117.757C1419.92 117.757 1403.24 101.387 1403.24 81.0004Z'
              fill='#F1AE2B'
            />
            <path
              d='M965.011 92.7914C958.46 65.0486 975.64 37.2481 1003.38 30.6973C1031.13 24.1464 1058.93 41.3259 1065.48 69.0687C1072.03 96.8115 1054.85 124.612 1027.11 131.163C999.362 137.714 971.562 120.534 965.011 92.7914Z'
              fill='#ACBF2F'
            />
            <path
              d='M858.716 121.082C836.539 121.082 818.561 103.104 818.561 80.9273C818.561 58.7506 836.539 40.7728 858.716 40.7728C880.892 40.7728 898.87 58.7506 898.87 80.9273C898.87 103.104 880.892 121.082 858.716 121.082Z'
              fill='#FB5449'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_810_2932'>
            <rect width='1440' height='160' fill='white' />
          </clipPath>
          <clipPath id='clip1_810_2932'>
            <rect
              width='797'
              height='163'
              fill='white'
              transform='translate(0 -1)'
            />
          </clipPath>
          <clipPath id='clip2_810_2932'>
            <rect
              width='792.587'
              height='160'
              fill='white'
              transform='matrix(-1 0 0 1 1573.16 0)'
            />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  )
}

export default IconBelowHeaderDecoration
