import { useRouter } from 'next/router'
import { parseGeneralSlug } from 'fractals'

// * so far does not handle languages other than en, ru, uz, kk
export const useAt = () => {
  const { query } = useRouter()
  const queryGeneral = query?.general?.toString() || null
  const parsedQueryGeneral = parseGeneralSlug(queryGeneral)

  return parsedQueryGeneral
}

export default useAt
