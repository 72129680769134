import { v4 as uuidv4 } from 'uuid'
import { StaticImageData } from 'next/image'

export const createRenderImgsArr = (
  obj: Record<string, StaticImageData | Record<'default', StaticImageData>>
) =>
  Object.entries(obj).map(([alt, src]) => ({
    id: uuidv4(),
    alt,
    src
  }))
