export const ROOT_ELEMENT = 'h4'

export const VARIANTS_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  gamma: 'gamma',
  delta: 'delta',
  epsilon: 'epsilon',
  zeta: 'zeta',
  zetaA: 'zetaA',
  journalH3: 'journalH3'
} as const
