import { sortBasedOnKey, parseGeneralSlug } from 'fractals'
import { fetchBreadcrumbs } from './fetchBreadcrumbs'

export const normalizeBreadcrumbsData = ({
  breadcrumbsData,
  journalSlug
}: {
  breadcrumbsData: Awaited<ReturnType<typeof fetchBreadcrumbs>>
  journalSlug: string | null | undefined
}) => {
  const { currentJournalSlug } = parseGeneralSlug(journalSlug)

  const breadcrumbsDataNormalized = breadcrumbsData?.find(({ name, slug }) =>
    slug === currentJournalSlug
      ? {
          name: name || null,
          slug: slug || null
        }
      : null
  )

  // ?.filter(journal =>
  //   currentJournalsByFacultySlug
  //     ? journal.faculty.slug === currentJournalsByFacultySlug
  //     : journal
  // )

  // console.log(
  //   '!!!!!!!!!!!!!!!!',
  //   journalDataNormalized?.attributes.linkedJournal
  // )

  if (breadcrumbsDataNormalized) return breadcrumbsDataNormalized

  return null
}

export default normalizeBreadcrumbsData
