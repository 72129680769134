import { LOCALES } from 'fractals'

export const HEADING_DEFAULT = {
  [LOCALES.en]:
    'Leave an application and our consultants of the admission committee will tell you all the conditions of accelerated learning',
  [LOCALES.ru]:
    'Оставьте заявку и наши консультанты приемной комиссии расскажут все условия ускоренного обучения',
  [LOCALES.uz]:
    'Aplikatsiyani qoldiring va bizning qabul komissiyasi konsullari tez oʻqish shartlarini aytadi',
  [LOCALES.kk]:
    'Қабылдау комиссиясының консультанттары тез оқыту шарттарын көрсетеді'
} as const
