import * as React from 'react'
import { TInputProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Input = React.forwardRef<HTMLInputElement, TInputProps>(
  ({ ...props }, ref) => {
    return (
      <StyledElement
        as={ROOT_ELEMENT}
        {...props}
        ref={ref}
        styles={{
          display: 'inline-block',
          fontFamily: 'inherit',
          fontSize: '100%',
          lineHeight: 1.15,
          overflow: 'visible',
          minWidth: 'initial',
          width: 'initial',
          maxWidth: 'initial',
          minHeight: 'initial',
          height: 'initial',
          maxHeight: 'initial',
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderWidth: 0,
          borderStyle: 'none',
          borderColor: 'transparent',
          appearance: 'none',
          cursor: 'text',
          WebkitTapHighlightColor: 'transparent',
          ...props.styles
        }}
      />
    )
  }
)

export default Input
