import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetStudyIsDataProps, TResPrograms } from '../types'

export const fetchPrograms = async ({ context }: TGetStudyIsDataProps) => {
  const { currentProgramSlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  if (!currentProgramSlug) return null

  const query = qs.stringify({
    fields: ['name'],
    populate: {
      studyIs: {
        fields: ['name']
      }
    },
    filters: {
      slug: {
        $eq: currentProgramSlug
      }
    },
    locale: context.locale
  })

  const res = await fetcher<TResPrograms>(
    `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
  )

  return res?.data || null
}

export default fetchPrograms
