import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  Image,
  useAt,
  useCalculateClosestEnrollment,
  useCalculateStudyDuration,
  useMedia
} from 'fractals'
import {
  Layout,
  mdToJsx,
  insertWhiteSpacesAfterPrepositions,
  slugify
} from 'ui'
import { Shared } from 'modules'
import {
  TITLE,
  TITLE_LEARN_FASTER,
  DESCRIPTION,
  DESCRIPTION_LEARN_FASTER,
  BTN,
  IMAGE_ALT,
  IMAGE_ALT_ALT,
  DYNAMIC_STUDY_DURATION,
  TExamFreeEnrollmentProps
} from './fractals'
import image from './person-studying.jpg'
import imageAlt from './people-having-fun.jpg'

const ExamFreeEnrollment = ({
  cost,
  examFreeEnrollment
}: TExamFreeEnrollmentProps) => {
  const [
    title,
    titleLearnFaster,
    description,
    descriptionLearnFaster,
    btn,
    alt,
    altAlt
  ] = useTranslations(
    TITLE,
    TITLE_LEARN_FASTER,
    DESCRIPTION,
    DESCRIPTION_LEARN_FASTER,
    BTN,
    IMAGE_ALT,
    IMAGE_ALT_ALT
  )

  const { isCourse } = useAt()

  const { isMobile, isTablet, isLaptop, isDesktop } = useMedia()

  const calculatedStudyDuration = useCalculateStudyDuration({
    months:
      (examFreeEnrollment?.studyDurationMonths &&
        Math.floor(examFreeEnrollment.studyDurationMonths / 2)) ||
      0,
    years:
      (examFreeEnrollment?.studyDurationYears &&
        Math.floor(examFreeEnrollment.studyDurationYears / 2)) ||
      0
  })

  // TODO: improve Tablet UI, make it the way it's in figma
  return (
    <Section id={slugify(title)} spacingVariant='beta'>
      <Container
        styles={{
          position: 'relative'
        }}>
        <Layout.Flex
          cols={1}
          styles={{
            marginRight: toUnit(
              (isDesktop && 200) || (isLaptop && 114) || undefined
            ),
            paddingTop: toUnit(
              ((isLaptop || isDesktop) && 56) || (isTablet && 40) || 24
            ),
            paddingRight: toUnit(
              (isDesktop && 208) || (isLaptop && 160) || (isTablet && 40) || 24
            ),
            paddingBottom: toUnit(
              ((isLaptop || isDesktop) && 56) || (isTablet && 40) || 24
            ),
            paddingLeft: toUnit(
              ((isDesktop || isLaptop) && 56) || (isTablet && 40) || 24
            ),
            borderRadius: toUnit(16),
            backgroundColor: 'var(--beta)',
            color: 'var(--alpha)'
          }}>
          <Layout.FlexItem
            styles={{
              minWidth: '100%',
              display: (isMobile && 'flex') || undefined,
              flexDirection: (isMobile && 'column') || undefined
            }}>
            <H2 variant='gamma'>{(isCourse && titleLearnFaster) || title}</H2>
            <P
              variant='alpha'
              styles={{
                marginBottom: toUnit(((isDesktop || isLaptop) && 56) || 40)
              }}>
              {mdToJsx(
                insertWhiteSpacesAfterPrepositions(
                  (isCourse &&
                    descriptionLearnFaster.replace(
                      DYNAMIC_STUDY_DURATION,
                      calculatedStudyDuration
                    )) ||
                    description
                )
              )}
            </P>

            <Shared.LeadPopup
              cost={cost}
              buttonProps={{
                colorsVariant: 'alpha',
                children: btn,
                styles: {
                  minWidth: toUnit(
                    ((isDesktop || isLaptop) && 208) ||
                      (isTablet && 304) ||
                      undefined
                  )
                }
              }}
            />

            {(isTablet || isMobile) && (
              <Image
                src={(isCourse && imageAlt) || image}
                colorVariant='gamma'
                borderRadiusVariant='alpha'
                alt={(isCourse && altAlt) || alt}
                containerProps={{
                  styles: {
                    display: 'inline-block',
                    marginTop: toUnit(40)
                  }
                }}
              />
            )}
          </Layout.FlexItem>
        </Layout.Flex>
        {(isDesktop || isLaptop) && (
          <Image
            src={(isCourse && imageAlt) || image}
            colorVariant='gamma'
            borderRadiusVariant='alpha'
            alt={(altAlt && altAlt) || alt}
            containerProps={{
              styles: {
                position: ((isDesktop || isLaptop) && 'absolute') || undefined,
                top: ((isDesktop || isLaptop) && '50%') || undefined,
                maxWidth: toUnit((isLaptop && 248) || 384),
                right: toUnit(((isDesktop || isLaptop) && 16) || undefined),
                transform:
                  ((isDesktop || isLaptop) && 'translate(0, -50%)') || undefined
              }
            }}
          />
        )}
      </Container>
    </Section>
  )
}

export default ExamFreeEnrollment
