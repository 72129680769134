import * as React from 'react'
import { IconContainer } from 'fractals'
import { useTheme } from 'styled-components'
import { TIconScreenStandProps } from './types'

const IconScreenStand = ({ ...props }: TIconScreenStandProps) => {
  const { colors } = useTheme()

  const styles = {
    ...props.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='233'
        height='87'
        viewBox='0 0 233 87'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M41.2389 0H222.69L233 87H0L41.2389 0Z'
          fill={colors.gamma.rgb().string()}
        />
      </svg>
    </IconContainer>
  )
}

export default IconScreenStand
