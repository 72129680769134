import { fetchPrograms } from './fetchPrograms'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchPrograms>>
}) => {
  return programsData?.map(item => item.name || null) || null
  // return (
  //   programsData?.map(
  //     item =>
  //       item.attributes.leastProgramContents?.map(item2 => item2.name) || null
  //   )?.[0] || null
  // )
}

export default normalizeProgramsData
