import * as React from 'react'
import { StyledJSX } from 'ui'
import { TDivProps } from './types'

const Div = React.forwardRef<HTMLDivElement, TDivProps>(
  ({ /* variant, */ ...props }, ref) => {
    // const stylesVariants = {
    //   [VARIANTS_KEYS.alpha]: {}
    // } as const

    const styles = {
      // ...stylesVariants[variant],
      ...props.styles
    } as const

    return <StyledJSX.Div ref={ref} {...props} styles={styles} />
  }
)

export default Div
