import { LOCALES } from 'fractals'

export const TESTIMONIAL_TITLE_YEARS = {
  [LOCALES.en]: '> 11 years',
  [LOCALES.ru]: '> 11 лет',
  [LOCALES.uz]: '> 11 yil',
  [LOCALES.kk]: '> 11 жыл'
} as const

export const TESTIMONIAL_DESC_YEARS = {
  [LOCALES.en]: 'Educational practitioners',
  [LOCALES.ru]: 'Практики в сфере образования',
  [LOCALES.uz]: 'Pedagogik amaliyotchilar',
  [LOCALES.kk]: 'Педагогикалық практиктер'
}

export const TESTIMONIAL_TITLE_STUDENTS = {
  [LOCALES.en]: '8500',
  [LOCALES.ru]: '8500',
  [LOCALES.uz]: '8500',
  [LOCALES.kk]: '8500'
} as const

export const TESTIMONIAL_DESC_STUDENTS = {
  [LOCALES.en]: 'Students are learning right now',
  [LOCALES.ru]: 'Студентов обучаются прямо сейчас',
  [LOCALES.uz]: "O'qituvchilar hozirda o'qiyapti",
  [LOCALES.kk]: 'Оқушылар қазір оқыяптар'
}

export const TESTIMONIAL_TITLE_YEARS_ONLINE = {
  [LOCALES.en]: '7 years',
  [LOCALES.ru]: '7 лет',
  [LOCALES.uz]: '7 yil',
  [LOCALES.kk]: '7 жыл'
} as const

export const TESTIMONIAL_DESC_YEARS_ONLINE = {
  [LOCALES.en]: 'Specialized in online education',
  [LOCALES.ru]: 'Специализируемся на дистанционном образовании',
  [LOCALES.uz]: "Onlayn ta'limda maxsuslashtirilgan",
  [LOCALES.kk]: 'Онлайн оқу туралы маңызды'
}

export const TESTIMONIAL_TITLE_GRADUATES = {
  [LOCALES.en]: '> 10 000',
  [LOCALES.ru]: '> 10 000',
  [LOCALES.uz]: '> 10 000',
  [LOCALES.kk]: '> 10 000'
} as const

export const TESTIMONIAL_DESC_GRADUATES = {
  [LOCALES.en]: 'Graduates are working right now',
  [LOCALES.ru]: 'Студентов уже обучили',
  [LOCALES.uz]: "O'qituvchilar hozirda ishlamoqda",
  [LOCALES.kk]: 'Оқушылар қазір жұмыс істейді'
}
