import { NextWrappers } from 'ui'
import { toUnit, useMedia, multiMedia } from 'fractals'
import { TLinkProps } from './types'
import { VARIANTS_KEYS } from './constants'

const Link = ({ variant, ...props }: TLinkProps) => {
  const { isDesktop } = useMedia()

  const sharedStyles = {
    paddingTop: toUnit(16),
    paddingRight: toUnit(24),
    paddingBottom: toUnit(16),
    paddingLeft: toUnit(24),
    borderWidth: toUnit(1),
    borderStyle: 'solid',
    borderColor: 'var(--phi)',
    borderRadius: toUnit(8),
    fontSize: toUnit((isDesktop && 18) || 16),
    fontWeight: 400,
    lineHeight: '120%',
    textAlign: 'center',
    color: 'var(--omega)',
    cursor: 'pointer',
    transition:
      'color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out, transform 200ms ease-in-out',
    '@media (hover: hover)': {
      '&:hover': {
        color: 'var(--beta)',
        borderColor: 'var(--beta)',
        transform: 'scale(1.02)'
      }
    },
    '&:focus-visible': {
      color: 'var(--beta)',
      borderColor: 'var(--beta)',
      transform: 'scale(1.02)'
    }
  } as const

  const stylesVariants = {
    [VARIANTS_KEYS.alpha]: {},
    [VARIANTS_KEYS.beta]: {
      fontSize: toUnit((isDesktop && 18) || 16),
      fontWeight: 500,
      lineHeight: '120%'
    },
    [VARIANTS_KEYS.gamma]: {
      ...sharedStyles
    },
    [VARIANTS_KEYS.delta]: {
      ...sharedStyles,
      backgroundColor: 'var(--beta)',
      borderColor: 'var(--beta)',
      color: 'var(--alpha)',
      // cursor: 'not-allowed',
      '@media (hover: hover)': {
        '&:hover': {
          color: 'var(--alpha)',
          borderColor: 'var(--beta)'
        }
      },
      '&:focus-visible': {
        color: 'var(--alpha)',
        borderColor: 'var(--omega)'
      }
    },
    [VARIANTS_KEYS.epsilon]: {
      transition: 'transform 150ms ease-in',
      '@media (hover: hover)': {
        '&:hover': {
          transform: 'scale(1.014)'
        }
      },
      '&:focus-visible': {
        transform: 'scale(1.014)'
      }
    },
    [VARIANTS_KEYS.zeta]: {
      transition: 'transform 150ms ease-in',
      color: 'var(--beta)',
      '@media (hover: hover)': {
        '&:hover': {
          transform: 'scale(1.014)'
        }
      },
      '&:focus-visible': {
        transform: 'scale(1.014)'
      }
    },
    // TODO: this is pulled from the Button, but it should be generalized
    [VARIANTS_KEYS.omega]: {
      borderWidth: toUnit(1),
      borderStyle: 'solid',
      borderColor: 'transparent',
      ...multiMedia({
        desktop: { fontSize: '14px', width: '104px' },
        laptop: { fontSize: '12px', width: '73px' },
        tablet: { fontSize: '12px', width: '80px' },
        phone: { fontSize: '14px' }
      }),
      fontWeight: 400,
      lineHeight: '120%',
      padding: '12px 8px',
      borderRadius: '8px',
      transition:
        'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
      ':disabled': {
        ...((props.styles?.[':disabled'] && props.styles[':disabled']) || {}),
        opacity: '0.5',
        cursor: 'not-allowed',
        transform: 'none !important',
        backgroundColor: 'inherit !important',
        color: 'inherit !important'
      },
      backgroundColor: 'var(--omega)',
      color: 'var(--alpha)',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'var(--beta)',
          transform: 'scale(1.014)'
        }
      },
      '&:focus-visible': {
        backgroundColor: 'var(--beta)',
        transform: 'scale(1.014)'
      },
      '&:active': {
        transform: 'scale(0.98)'
      }
    },
    [VARIANTS_KEYS.omegaBurger]: {
      borderWidth: toUnit(1),
      borderStyle: 'solid',
      borderColor: 'transparent',
      ...multiMedia({
        desktop: { fontSize: '14px', width: '104px' },
        laptop: { fontSize: '12px', width: '73px' },
        tablet: { fontSize: '12px', width: '80px' },
        phone: { fontSize: '14px' }
      }),
      fontWeight: 500,
      lineHeight: '120%',
      padding: '12px 8px',
      boxSizing: 'border-box',
      width: '104px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      transition:
        'transform 150ms ease-in, background-color 220ms ease-in-out, color 220ms ease-in-out, border-color 220ms ease-in-out, opacity 220ms ease-in-out',
      ':disabled': {
        ...((props.styles?.[':disabled'] && props.styles[':disabled']) || {}),
        opacity: '0.5',
        cursor: 'not-allowed',
        transform: 'none !important',
        backgroundColor: 'inherit !important',
        color: 'inherit !important'
      },
      backgroundColor: 'var(--omega)',
      color: 'var(--alpha)',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'var(--beta)',
          transform: 'scale(1.014)'
        }
      },
      '&:focus-visible': {
        backgroundColor: 'var(--beta)',
        transform: 'scale(1.014)'
      },
      '&:active': {
        transform: 'scale(0.98)'
      }
    }
  } as const

  const styles = {
    marginTop: 0,
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <NextWrappers.Link {...props} styles={styles} />
}

export default Link
