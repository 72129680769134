import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: 'Why choose «Moscow Institute of Technology and Management»',
  [LOCALES.ru]: 'Почему выбирают «Московский институт технологий и управления»',
  [LOCALES.uz]:
    '«Moscow Institute of Technology and Management» ni nima sababli tanlaymiz',
  [LOCALES.kk]:
    '«Moscow Institute of Technology and Management» ның негізгі себебі'
} as const

export const TESTIMONIAL_1 = {
  [LOCALES.en]: 'Installment from the institute without overpayment',
  [LOCALES.ru]: 'Рассрочка от института без переплат',
  [LOCALES.uz]: 'Institutdan to‘lovni qo‘shimcha to‘lashsiz',
  [LOCALES.kk]: 'Институттан төлемді қосымша төлемсіз'
}

export const TESTIMONIAL_3 = {
  [LOCALES.ru]: 'Дипломы котируются по всему миру',
  [LOCALES.en]: 'Diplomas are quoted all over the world',
  [LOCALES.uz]: 'Diplomalar dunyodagi hamma joyda narxlantiriladi',
  [LOCALES.kk]: 'Дипломалар барлық жерде негізгі болып табылады'
}

export const TESTIMONIAL_5 = {
  [LOCALES.en]: 'Monthly enrollment in any program',
  [LOCALES.ru]: 'Ежемесячное зачисление на любую программу',
  [LOCALES.uz]: 'Har bir dasturga oylik oʻqish',
  [LOCALES.kk]: 'Әрбір бағдарламага айлық тіркелу'
}

export const TESTIMONIAL_7 = {
  [LOCALES.en]: 'Easy to combine with work',
  [LOCALES.ru]: 'Легко совмещать с работой',
  [LOCALES.uz]: 'Ish bilan birga oʻzaro bogʻlash',
  [LOCALES.kk]: 'Жұмыс белгілі болып табылады'
}

export const TESTIMONIAL_2 = {
  [LOCALES.en]: 'Acceptance of foreign citizens',
  [LOCALES.ru]: 'Прием иностранных граждан',
  [LOCALES.uz]: 'Xalqaro fuqarolar qabul qilinadi',
  [LOCALES.kk]: 'Халқаро қазақстандықтар қабылданады'
}

export const TESTIMONIAL_4 = {
  [LOCALES.en]: 'Prices are lower than competitors',
  [LOCALES.ru]: 'Цены ниже, чем у конкурентов',
  [LOCALES.uz]: 'Narxlari boshqa institutlardan past',
  [LOCALES.kk]: 'Негізгі болып табылады'
}

export const TESTIMONIAL_6 = {
  [LOCALES.en]: 'Strong faculty and practicing teachers',
  [LOCALES.ru]: 'Сильный профессорский состав и практикующие преподаватели',
  [LOCALES.uz]: 'Katta oʻqituvchilar va amaliy oʻqituvchilar',
  [LOCALES.kk]: 'Қатар оқытушылар мен амалды оқытушылар'
}

export const TESTIMONIAL_8 = {
  [LOCALES.en]: 'You can choose a convenient time and place for classes',
  [LOCALES.ru]: 'Можно выбрать удобное время <br /> и место для занятий',
  [LOCALES.uz]: 'Darslar uchun uyga kelish vaqti va joyini tanlay olasiz',
  [LOCALES.kk]: 'Сабақтар үшін уйға келу уақыты мен жерін таңдауға болады'
}
