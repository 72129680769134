import { multiMedia } from 'fractals'
import { VARIANT_KEYS } from './fractals/constants'

export const variants = {
  [VARIANT_KEYS.home]: {
    author: {
      reviews: {
        ...multiMedia({
          marginTop: '56px',
          desktop: { marginTop: '64px' },
          phone: { marginTop: '48px' }
        })
      } as const,
      review: {
        '& > *': {
          color: 'var(--alpha)',
          fontWeight: 400,
          lineHeight: '120%',
          ...multiMedia({
            margin: '16px 0 24px 0',
            fontSize: '14px',
            desktop: { fontSize: '18px', margin: '18px 0 24px 0' },
            laptop: { fontSize: '16px' },
            phone: { margin: '16px 0' }
          })
        } as const
      },
      line: {
        width: '100%',
        border: '1px solid #3A52EE',
        ...multiMedia({
          marginBottom: '24px',
          phone: { marginBottom: '16px' }
        })
      } as const
    },
    media: {
      scroller: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        '::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      } as const,
      videos: {
        display: 'flex',
        flexDirection: 'row',
        ...multiMedia({
          desktop: { gap: '32px' },
          laptop: { gap: '31.65px' },
          tablet: { gap: '34.43px' },
          phone: { gap: '19.33px' }
        }),
        justifyContent: 'space-between',
        width: 'fit-content'
      } as const
    }
  },
  [VARIANT_KEYS.programs]: {
    author: {
      reviews: {
        ...multiMedia({
          marginTop: '56px',
          desktop: { marginTop: '64px' },
          phone: { marginTop: '48px' }
        })
      } as const,
      review: {
        '& > *': {
          color: 'var(--omega)',
          fontWeight: 400,
          lineHeight: '120%',
          ...multiMedia({
            margin: '16px 0 24px 0',
            fontSize: '14px',
            desktop: { fontSize: '18px', margin: '18px 0 24px 0' },
            laptop: { fontSize: '16px' },
            phone: { margin: '16px 0' }
          })
        } as const
      },
      line: {
        width: '100%',
        border: '1px solid var(--phi)',
        ...multiMedia({
          marginBottom: '24px',
          phone: { marginBottom: '16px' }
        })
      } as const
    },
    media: {
      scroller: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        '::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      } as const,
      videos: {
        display: 'flex',
        flexDirection: 'row',
        ...multiMedia({
          desktop: { gap: '32px' },
          laptop: { gap: '31.65px' },
          tablet: { gap: '34.43px' },
          phone: { gap: '19.33px' }
        }),
        justifyContent: 'space-between',
        width: 'fit-content'
      } as const
    }
  }
} as const
