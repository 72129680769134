import { multiMedia } from 'fractals'

export const askModal = {
  backWrap: {
    color: 'gray',
    ...multiMedia({
      width: '100vw',
      laptop: { width: '100%' },
      desktop: { width: '100%' }
    }),
    padding: '20px',
    display: 'flex',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    position: 'relative',
    alignItems: 'center',
    marginBottom: '35px',
    justifyContent: 'center',
    backgroundColor: '#f7f7f7'
  },

  form: {
    right: 0,
    bottom: 0,
    ...multiMedia({
      width: '375px',
      tablet: { width: '100%' },
      phone: { width: '100%' }
    }),
    display: 'flex',
    position: 'fixed',
    marginLeft: 'auto',
    alignItems: 'center',
    padding: '0 35px 27px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    backgroundColor: '#fff',
    justifyContent: 'center'
  },
  icon: {
    width: '22px',
    height: '22px',
    position: 'absolute',
    top: '-28px',
    right: '5px',
    cursor: 'pointer'
  },
  textarea: {
    width: '100%',
    height: '70px',
    padding: '14px',
    marginTop: '27px',
    fontSize: '16px',
    border: '1px solid #636363',
    caretColor: '#ff3535',
    resize: 'none',
    borderRadius: 0
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    width: '100%',
    marginTop: '10px',
    justifyContent: 'center',
    label: {
      width: '100%',
      fontSize: '16px',
      fontWeight: 500,
      marginBottom: '5px'
    },
    '& button': {
      height: '32px',
      flexGrow: 1,
      color: '#fff',
      width: '30%',
      borderWidth: 0,
      padding: 0,
      borderRadius: 0
    }
  },
  defaultButton: {
    color: '#fff',
    width: '100%',
    display: 'flex',
    padding: '12px 70px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ff3535',
    textTransform: 'uppercase',
    borderWidth: 0,
    marginTop: '10px',
    borderRadius: 0
  },
  inputContainer: {
    position: 'relative',
    width: '100%'
  },
  input: {
    width: '100%',
    border: 'none',
    fontSize: '16px',
    borderRadius: 0,
    padding: '10px 3px',
    borderBottom: '1px solid #000',
    '&:focus': {
      outline: 'auto',
      outlineColor: 'red'
    }
  },
  inputPlaceholder: {
    left: 0,
    top: '-5px',
    color: '#000',
    fontSize: '16px',
    position: 'absolute',
    pointerEvents: 'none',
    transition: 'all .3s ease-out',
    focuse: {
      top: '-15px',
      fontSize: '13px',
      color: '#ff3535'
    }
  },
  backIcon: {
    top: '20px',
    left: '25px',
    color: 'gray',
    width: '16px',
    opacity: '.65',
    height: '16px',
    cursor: 'pointer',
    strokeWidth: 50,
    position: 'absolute'
  },
  thanks: {
    display: 'flex',
    paddingTop: '10%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  thanksIcon: {
    width: '68px',
    height: '68px',
    display: 'flex',
    margin: '11px 0',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1143a7'
  }
} as const
