import { StyledJSX } from 'ui'
import { DispatchWithoutAction } from 'react'
import { multiMedia } from 'fractals'
import { Div, Span } from '@/fractals/components'
import { ArrowDown } from '@/modules/shared/widgets/Header/fractals'
import { SORTING_BY } from '../../organisms/ProgramCards/constants'
import { toUnit, withClickOutside } from '@/fractals/utils'
import { type SortingType } from '../../organisms/ProgramCards/types'
import {
  appliedStylesSorting,
  regularStylesCheckerSorting
} from '../../organisms/ProgramCards/styles'

export const SortButton = withClickOutside(
  ({
    isHidden,
    toggleIsHidden,
    getSortingBy,
    sortedBy
  }: {
    isHidden: boolean
    toggleIsHidden: DispatchWithoutAction
    getSortingBy: (value: SortingType) => void
    sortedBy: SortingType
  }) => (
    <Div
      styles={{
        position: 'relative'
      }}>
      <StyledJSX.Button
        onClick={toggleIsHidden}
        styles={{
          display: 'flex',
          alignItems: 'center',
          gap: toUnit(9.5),
          color: isHidden ? '#000' : '#3A52EE',
          '& path': {
            stroke: isHidden ? '#000' : '#3A52EE'
          },
          cursor: 'pointer',
          ...multiMedia({
            phone: {
              fontSize: '12px',
              lineHeight: '14.4px'
            }
          })
        }}>
        Сортировать
        <ArrowDown />
      </StyledJSX.Button>
      <Div
        styles={{
          position: 'absolute',
          display: isHidden ? 'none' : 'flex',
          flexDirection: 'column',
          top: '110%',
          width: 'max-content',
          background: '#F2F2F2',
          borderRadius: '16px',
          padding: toUnit(16),
          gap: toUnit(8),
          zIndex: 2000,
          ...multiMedia({
            phone: {
              right: 0
            }
          })
        }}>
        {SORTING_BY.map((sorted, idx) => (
          <Span
            onClick={() =>
              getSortingBy({ type: sorted.href, direction: 'desc' })
            }
            key={`ProgramsSort__${sorted.ru}--${idx + 1}`}
            styles={{
              display: 'flex',
              gap: '0.5rem',
              cursor: 'pointer',
              minWidth: '-moz-available',
              transition: 'transform 150ms ease-in',
              '@media (hover: hover)': {
                '&:hover': {
                  transform: 'scale(1.014)'
                }
              },
              '&:focus-visible': {
                transform: 'scale(1.014)'
              }
            }}>
            <Span
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              styles={{
                ...regularStylesCheckerSorting,
                borderColor:
                  (sortedBy.type === sorted.href && 'var(--beta)') ||
                  'var(--phi)',
                ...((sortedBy.type === sorted.href && appliedStylesSorting) ||
                  {})
              }}
            />
            <Span>{sorted.ru}</Span>
          </Span>
        ))}
      </Div>
    </Div>
  )
)
