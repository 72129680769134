import { Layout } from 'ui'
import { Link, PATHS, toUnit, useMedia, useTranslations } from 'fractals'
import { TContactButtonsProps } from './types'
// TODO: ! remove the line and fix the error
// eslint-disable-next-line
import { ALMATY, MOSCOW, TASHKENT } from '../..'

export const ContactButtons = ({
  contacts,
  activeContact,
  setActiveContact
}: TContactButtonsProps) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()
  const [moscow, almaty, tashkent] = useTranslations(MOSCOW, ALMATY, TASHKENT)

  // if (!contacts || contacts?.length === 0) return null

  return (
    <Layout.Flex
      cols='auto'
      gap={toUnit(((isTablet || isLaptop || isDesktop) && 32) || 16)}
      styles={{
        marginBottom: toUnit(((isLaptop || isDesktop) && 85) || 32)
      }}>
      {contacts.map((contact, idx) => {
        const isApplied = contact === activeContact

        return (
          <Layout.FlexItem
            key={`ContactButtons__${contact}--${idx + 1}`}
            styles={{
              display: 'flex',
              flexGrow: 1
            }}>
            <Link
              variant={(isApplied && 'delta') || 'gamma'}
              href={PATHS.front.contact.index}
              styles={{
                flexGrow: 1
              }}
              shallow
              onClick={() => setActiveContact(contact)}>
              {contact === 'moscow' && moscow}
              {contact === 'almaty' && almaty}
              {contact === 'tashkent' && tashkent}
            </Link>
          </Layout.FlexItem>
        )
      })}
      {/* <H3 variant='alpha'>{heading}</H3> */}
    </Layout.Flex>
  )
}

export default ContactButtons
