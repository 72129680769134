import { Layout } from 'ui'
import { useTranslations, Div, toUnit, Span, useMedia } from 'fractals'
import { TCardProps } from './types'

const Card = ({ card, children }: TCardProps) => {
  const [title] = useTranslations(card.title)
  const { isTablet, isLaptop, isDesktop } = useMedia()

  return (
    <Layout.GridItem
      styles={{
        padding: ((isDesktop || isLaptop) && toUnit(32)) || toUnit(24),
        borderRadius: (isDesktop && toUnit(32)) || toUnit(16),
        backgroundColor: 'var(--alpha)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: (isDesktop && toUnit(32)) || toUnit(16)
      }}>
      <Div
        styles={{
          flexBasis: (isDesktop && toUnit(176)) || toUnit(80)
        }}>
        {children}
      </Div>
      <Span
        styles={{
          color: 'var(--omega)',
          fontWeight: '400',
          fontSize:
            (isDesktop && toUnit(20)) || (isTablet && toUnit(16)) || toUnit(14),
          lineHeight: '120%'
        }}>
        {title}
      </Span>
    </Layout.GridItem>
  )
}

export default Card
