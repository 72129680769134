import { CSSProperties, CSSObject } from 'styled-components'
import { THEME } from 'fractals'

export const media = <T extends keyof CSSProperties>(
  key: T,
  style: CSSObject[T] | null,
  {
    phone,
    tablet,
    laptop,
    desktop
  }: {
    phone?: CSSObject[T]
    tablet?: CSSObject[T]
    laptop?: CSSObject[T]
    desktop?: CSSObject[T]
  }
) => {
  const { media: mediaTheme } = THEME

  return {
    ...((key && {
      [key]: style
    }) ||
      {}),
    ...((phone && {
      [mediaTheme.phone]: {
        [key]: phone
      }
    }) ||
      {}),
    ...((tablet && {
      [mediaTheme.tablet]: {
        [key]: tablet
      }
    }) ||
      {}),
    ...((laptop && {
      [mediaTheme.laptop]: {
        [key]: laptop
      }
    }) ||
      {}),
    ...((desktop && {
      [mediaTheme.desktop]: {
        [key]: desktop
      }
    }) ||
      {})
  }
}

export default media
