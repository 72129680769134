import { PATHS, parseGeneralSlug, fetcher } from 'fractals'
import qs from 'qs'
import { TGetSeoDataProps, TResPrograms } from '../types'

export const fetchProgramsSeoData = async ({ context }: TGetSeoDataProps) => {
  const { currentProgramSlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  const query = qs.stringify({
    fields: ['name', 'slug', 'notUniqueSlug', 'rank'],
    populate: {
      seo: {
        fields: [
          'metaTitle',
          'metaRobots',
          'isSEOFriendly',
          'metaDescription',
          'canonicalURL',
          // 'metaImage',
          'keywords'
        ]
      }
    },
    filters: {
      slug: {
        $eq: currentProgramSlug
      }
    },
    locale: context.locale
  })

  const res = await fetcher<TResPrograms>(
    `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
  )

  return res?.data || null
}

export default fetchProgramsSeoData
