import * as React from 'react'
import { StyledJSX } from 'ui'
import Color from 'color'
import { Div, toUnit } from 'fractals'
import { TSelectProps } from './types'
import { TYPE_KEYS } from './constants'
import { IconTick } from '../IconTick'

// TODO: more some of the logic to UI
const Select = ({
  placeholder,
  value,
  isOpen,
  onOpen,
  ...props
}: TSelectProps) => {
  const styles = {
    ...props.styles,
    outline: 'none',
    borderColor: 'transparent',
    transition: 'border-color 200ms ease-in, color 200ms ease-in',
    cursor: 'pointer'
  } as const

  return (
    <Div
      onClick={onOpen}
      styles={{
        ...styles
      }}>
      <StyledJSX.Select {...props} />
      <IconTick
        styles={{
          marginLeft: '10px',
          width: '16px',
          height: '16px',
          pointerEvents: 'auto'
        }}
        direction={isOpen ? 'down' : 'up'}
      />
    </Div>
  )
}

export default Select
