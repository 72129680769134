import { LOCALES } from 'fractals'

export const DYNAMIC_STUDY_DURATION = '%STUDY_DURATION%'

export const TITLE = {
  [LOCALES.en]: 'How to enroll without the exams?',
  [LOCALES.ru]: 'Как поступить без ЕГЭ?',
  [LOCALES.uz]: 'Imtihonlarsiz qabul qilinish qanday?',
  [LOCALES.kk]: 'ЕГЭсыз тіркеу қандай?'
} as const

export const TITLE_LEARN_FASTER = {
  [LOCALES.en]: 'Learn faster',
  [LOCALES.ru]: 'Учитесь быстрее',
  [LOCALES.uz]: 'Tezroq o‘rganing',
  [LOCALES.kk]: 'Тез оқыңыз'
} as const

export const DESCRIPTION = {
  [LOCALES.en]:
    'Submit an application and find out how to get a higher education without the exams',
  [LOCALES.ru]:
    'Оставь заявку и узнай подробности, как получить высшее образование без ЕГЭ по специальной программе',
  [LOCALES.uz]:
    'Ariza yuboring va imtihonlarsiz o‘rta ta’limni qabul qilish haqida ma’lumot oling',
  [LOCALES.kk]:
    'Арзыңызды жіберіңіз және ЕГЭсыз орта мектепке тіркелу үшін қандай жолдар бар деп біліңіз'
} as const

export const DESCRIPTION_LEARN_FASTER = {
  [LOCALES.ru]: `Программу  можно пройти интенсивно — всего за ${DYNAMIC_STUDY_DURATION}. Вы получите те же знания и навыки, что и в стандартной программе, но в два раза быстрее. Стоимость обучения остается прежней`,
  [LOCALES.en]: `The program can be completed intensively - in just ${DYNAMIC_STUDY_DURATION}. You will get the same knowledge and skills as in the standard program, but twice as fast. The cost of training remains the same`,
  [LOCALES.uz]: `Dasturni tezroq o‘rganish imkoniyati bor - faqat ${DYNAMIC_STUDY_DURATION}. Siz standart dasturda olingan bilim va qobiliyatlar olingan bo‘ladi, lekin ikki marta tezroq. O‘qitish narxi o‘zgarmaydi`,
  [LOCALES.kk]: `Программаны жылдам орындауға болады - тек ${DYNAMIC_STUDY_DURATION}. Сіз стандартты бағдарламада алған білім және құралдарды аласыз, бірақ екі рет тез оқыңыз. Оқу құны өзгермейді`
} as const

export const BTN = {
  [LOCALES.en]: 'Submit',
  [LOCALES.ru]: 'Узнать подробнее',
  [LOCALES.uz]: 'Ariza yuborish',
  [LOCALES.kk]: 'Арзыңызды жіберу'
} as const

export const IMAGE_ALT = {
  [LOCALES.en]: 'Person studying',
  [LOCALES.ru]: 'Человек учится',
  [LOCALES.uz]: 'O‘quvchi',
  [LOCALES.kk]: 'Оқушы'
} as const

export const IMAGE_ALT_ALT = {
  [LOCALES.en]: 'People laughing',
  [LOCALES.ru]: 'Людя смеются',
  [LOCALES.uz]: 'Odamlar qo‘yilgan',
  [LOCALES.kk]: 'Адамдар қойылған'
} as const
