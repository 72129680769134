import { Layout, slugify } from 'ui'
import {
  Section,
  Container,
  H2,
  useTranslations,
  Div,
  toUnit,
  Image,
  IconCircle,
  IconPlay,
  useMedia
} from 'fractals'
import { TITLE, CARDS, Card } from './fractals'
import photographPicture from './fractals/images/photograph.jpg'
import workHumanPicture from './fractals/images/workHuman.jpg'

const AboutTraining = () => {
  const { isMobile, isTablet, isLaptop, isDesktop } = useMedia()
  const [title] = useTranslations(TITLE)

  return (
    <Section id={slugify(title)} spacingVariant='alpha'>
      <Container>
        <Div
          styles={{
            padding:
              (isDesktop && toUnit(80)) ||
              (isLaptop && toUnit(64)) ||
              (isTablet && toUnit(32)) ||
              toUnit(24),
            borderRadius: (isDesktop && toUnit(32)) || toUnit(16),
            backgroundColor: 'var(--omega)'
          }}>
          <H2
            variant='beta'
            styles={{
              color: 'var(--alpha)'
            }}>
            {title}
          </H2>
          <Layout.Grid
            styles={{
              gridTemplateColumns: (isMobile && '1fr') || 'repeat(2, 1fr)',
              gridGap: (isMobile && toUnit(40)) || toUnit(32),
              gridAutoRows: '1fr'
            }}>
            {CARDS[0] && (
              <Card card={CARDS[0]}>
                <Image
                  src={photographPicture}
                  alt='Фотограф'
                  colorVariant='beta'
                  borderRadiusVariant='alpha'
                  width={(isDesktop && 176) || 80}
                  height={(isDesktop && 176) || 80}
                />
              </Card>
            )}
            {CARDS[1] && (
              <Card card={CARDS[1]}>
                <Layout.Grid
                  styles={{
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridGap: (isDesktop && toUnit(8)) || toUnit(4)
                  }}>
                  {/* The eslint rule is disabled to use index, because there is no unique value other than it. */}
                  {[...Array(4)].map((item, index, arr) => (
                    <IconCircle
                      colorsVariant={
                        arr.length - 1 !== index ? 'epsilon' : 'eta'
                      }
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${item}_${index}`}
                    />
                  ))}
                </Layout.Grid>
              </Card>
            )}
            {CARDS[2] && (
              <Card card={CARDS[2]}>
                <Layout.Flex cols={7}>
                  {/* The eslint rule is disabled to use index, because there is no unique value other than it. */}
                  {[...Array(7)].map((item, index, arr) => (
                    <IconPlay
                      colorsVariant={arr.length - 1 !== index ? 'iota' : 'zeta'}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${item}_${index}`}
                    />
                  ))}
                </Layout.Flex>
              </Card>
            )}
            {CARDS[3] && (
              <Card card={CARDS[3]}>
                <Image
                  src={workHumanPicture}
                  alt='Работа за компьютером'
                  colorVariant='beta'
                  borderRadiusVariant='alpha'
                  width={(isDesktop && 176) || 80}
                  height={(isDesktop && 176) || 80}
                />
              </Card>
            )}
          </Layout.Grid>
        </Div>
      </Container>
    </Section>
  )
}

export default AboutTraining
