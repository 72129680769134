'use client'

import {
  ModalShowProvider,
  ModalStylesProvider,
  ModalWrapper,
  TypeModalProps
} from './fractals'

/**
 * A modal with two variations. For desktop as a popup that begins the animation from the showing button
 * position and dimensions. For mobile as a sliding window from the bottom of the page.
 * @component `Modal`
 * @param {string} props.id - Modal id. `Required`.
 * @param {ReactElement} props.showButton - A button that will toggle the modal show. `Defaults` to the browser's default button.
 * @example An example to how use the Modal
 * ```jsx padding
 * // add data-modal-close to the button which you want to be a closing button
 * // data-modal-label and data-modal-description sets the aria-labelledby and aria-describedby on the modal to the tagged elements respectively
 * // these dataset attributes are optional, but it's highly recommended to use them in order to improve accessibility of the modal with screen readers
 * <Modal showButton={<CustomButton>Open modal</CustomButton>}>
 *   <div>
 *    <h3 data-modal-label>Modal heading</h3>
 *    <p data-modal-description>Modal description</p>
 *    This is a modal
 *    <button data-modal-close>Close modal</button>
 *  </div>
 * </Modal>
 * ```
 *
 * @param {object} props.config - This object sets modal configuration. `Optional`.
 * @description The modal configuration includes:
 * @param {object} config.desktopStyles - Sets the styles for desktop popup modal.
 * @param {object} config.mobileStyles - Sets the styles for mobile sliding modal.
 * @param {object} config.options - Sets additional configs.
 * @description
 * `desktopStyles` includes this parameters:
 *  - `outerPaddingTop` Type `Number` - Sets Modal's outer top padding in px. Defaults to `20`.
 *  - `outerPaddingLeft` Type `Number` - Sets Modal's outer left padding in px. Defaults to `20`.
 *  - `outerPaddingRight` Type `Number` - Sets Modal's outer right padding in px. Defaults to `20`.
 *  - `outerPaddingBottom` Type `Number` - Sets Modal's outer bottom padding in px. Defaults to `20`.
 *  - `maxWidth:` Type `Number`  - Sets Modal's max-width in px. Defaults to `600`.
 *  - `borderRadius:` Type `String` - Sets Modal's border-Radius. Defaults to `0px`.
 *  - `boxShadow:` Type `String` - Sets Modal's box-shadow. Defaults to `none`.
 *  - `backgroundColor:` Type `String` - Sets Modal's background-color. Defaults to `rgba(255, 255, 255, 0)`.
 *	- `animationDuration:` Type `Number` - Sets Modal's animation duration in ms. Defaults to `200`.
 *  - `animationEaseFunction:` Type `String` - Sets Modal's animation ease function. Defaults to `cubic-bezier(0.33, 1, 0.68, 1)`.
 *
 * `mobileStyles` includes this parameters:
 *  - `maxHeight:` Type `Number`  - Sets Modal's max-height in px. Defaults to `500`.
 *  - `borderRadius:` Type `String` - Sets Modal's top border-Radius. Defaults to `16px`.
 *  - `boxShadow:` Type `String` - Sets Modal's box-shadow. Defaults to `none`.
 *  - `backgroundColor:` Type `String` - Sets Modal's background-color. Defaults to `#eee`.
 *	- `animationDuration:` Type `Number` - Sets Modal's animation duration in ms. Defaults to 200.
 *  - `animationEaseFunction:` Type String - Sets Modal's animation ease function. Defaults to `cubic-bezier(0.33, 1, 0.68, 1)`.
 *  - `swipeToggleHeight:` Type `Number` - Sets Modal's swipe toggle height in px. Defaults to `45`.
 *  - `swipeToggleTraitColor`: Type `String` - Sets Modal's Swipe toggle trait color. Defaults to `#d1d1d1`.
 *  - `offsetThreshold`: Type `Number` - Sets Modal's offset threshold to toggle close in px. Defaults to `120`.
 *
 * `options` includes this parameters:
 *  - `showButtonDefultText`: Type `String` - Sets the default browser's button text.
 *  - `breakpointSize`: Type `Number` - Sets the breakpoint size which will toggle between Modal desktop and Modal mobile in px. Defaults to 640.
 *  - `noSwipeModal`: Type `Boolean` - Turns off the sliding modal and sets the popup modal as default to all screen sizes. Defaults to `false`.
 *  - `backgroundOverlayColor`: Type `String` - Sets the background overlay color. Defaults to `rgba(0,0,0,0.7)`.
 *  - `withBackgroundBlurOverlay`: Type `Boolean` - activates the backdrop blur on the overlay. Defaults to `false`.
 *  - `backgroundOverlayBlur`: Type `String` - Sets the background overaly blur value in px. Defaults to 2.
 *
 * **Warning**: Activating the Background blur will affect the performance of the modal.
 */

const Modal = ({ id, showButton, config, children }: TypeModalProps) => {
  return (
    <ModalStylesProvider
      desktopStyles={config?.desktopStyles}
      mobileStyles={config?.mobileStyles}
      options={config?.options}>
      <ModalShowProvider modalId={id}>
        <ModalWrapper
          showButton={showButton}
          defaultShowButtonText={config?.options?.defaultShowButtonText}>
          {children}
        </ModalWrapper>
      </ModalShowProvider>
    </ModalStylesProvider>
  )
}

export default Modal
