export {
  TITLE,
  ITEM_1,
  ITEM_2,
  ITEM_3,
  ITEM_4,
  ITEM_5,
  ITEM_6
} from './constants'
export type { TWhatWillYouLearnProps } from './types'
export { getWhatWillYouLearnData } from './api'
