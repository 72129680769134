export {
  LABEL,
  TITLE,
  STEP_TITLE_1,
  STEP_DESCRIPTION_1,
  STEP_TITLE_2,
  STEP_DESCRIPTION_2,
  STEP_TITLE_3,
  STEP_DESCRIPTION_3,
  STEP_TITLE_4,
  STEP_DESCRIPTION_4,
  STEP_TITLE_5,
  STEP_DESCRIPTION_5,
  STEP_TITLE_6,
  STEP_DESCRIPTION_6,
  ADDITIONAL_INFO
} from './constants'
export * as girl from './girl.jpg'
// export * as comp from './comp.gif'
export type { TStudyStepsProps } from './types'
export { getStudyStepsData } from './api'
export { IconScreen } from './components'
