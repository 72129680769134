import { useState, useEffect } from 'react'

export const useWindowHeight = () => {
  const [height, setHeight] = useState<number | undefined>()

  const handleResize = () => {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    // setInterval(() => {
    //   handleResize()
    // }, 2000)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return height
}

export default useWindowHeight
