import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  Ul,
  Li,
  toUnit,
  useMedia
} from 'fractals'
import { mdToJsx, insertWhiteSpacesAfterPrepositions, slugify } from 'ui'
import { TGraduateProfessionsProps, TITLE } from './fractals'

const GraduateProfessions = ({
  graduateProfessions
}: TGraduateProfessionsProps) => {
  const [title] = useTranslations(TITLE)

  const { isTablet, isLaptop, isDesktop } = useMedia()

  if (!graduateProfessions || graduateProfessions.length === 0) return null

  return (
    <Section id={slugify(title)} spacingVariant='beta' colorsVariant='gamma'>
      <Container>
        <H2
          variant='beta'
          styles={{
            textAlign: 'center'
          }}>
          {title}
        </H2>
        <Ul variant='gamma'>
          {graduateProfessions?.map((profession, idx) => (
            <Li
              variant='beta'
              key={`WhyUs__testimonials__testimonial--${idx + 1}`}
              styles={{
                backgroundColor: 'var(--alpha)',
                padding: toUnit(
                  ((isDesktop || isLaptop || isTablet) && 24) || 16
                )
              }}>
              <P
                // styles={{
                //   wordBreak: 'break-word'
                // }}
                variant='alpha'>
                {profession &&
                  mdToJsx(insertWhiteSpacesAfterPrepositions(profession), {
                    replace: (isLaptop && ['br', 'p']) || ['p']
                  })}
              </P>
            </Li>
          ))}
        </Ul>
      </Container>
    </Section>
  )
}

export default GraduateProfessions
