import { TGetForWhomDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getForWhomData = async ({ context }: TGetForWhomDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getForWhomData
