import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { media, multiMedia, toUnit, useMedia } from 'fractals'
import { Div, H2, IconPlus, IconUnionFolder, P } from '@/fractals/components'
import { Ellipse } from '../Ellipse'
import { Books } from '../Books'
import { TDownloadFreeMaterialsProps } from './types'
import { LeadForm } from '@/modules/shared/widgets'
import { BooksPopup } from '../BooksPopup'
import { PopupThanksSpan } from '../PopupThanksSpan'

type CallbackType = (data: boolean | undefined) => void

export const DownloadFreeMaterials = ({
  pdf,
  programs,
  category
}: TDownloadFreeMaterialsProps) => {
  const { isMobile, isDesktop } = useMedia()
  const [isDownloadFree, setIsDownloadFree] = useState(true)
  const [popupThanks, setPopupThanks] = useState<boolean | undefined>(false)

  const handleSuccess: CallbackType = data => {
    setPopupThanks(data)
  }

  const topic =
    'Получите подборку файлов от Московского Института Технологий и Управления'
  return (
    <Div>
      {isDownloadFree && (
        <Div
          styles={{
            position: 'relative',
            display: 'flex',
            top: '2rem',
            width: '12rem',
            height: '12rem',
            backgroundColor: 'var(--beta)',
            borderRadius: '16px',
            padding: toUnit(16),
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: '32px',
            cursor: 'pointer'
          }}>
          <Popup
            modal
            nested
            closeOnDocumentClick
            closeOnEscape
            lockScroll
            trigger={
              <Div
                styles={{
                  zIndex: 4
                }}>
                <Ellipse
                  styles={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0
                  }}
                  position='downLeft'
                />
                <P
                  styles={{
                    position: 'absolute',
                    zIndex: 2,
                    maxWidth: '75%',
                    fontSize: '16px',
                    bottom: '1rem'
                  }}
                  variant='pi'>
                  Скачать полезные материалы
                </P>
                <IconPlus
                  onClick={prev => setIsDownloadFree(!prev)}
                  styles={{
                    backgroundColor: 'var(--alpha)',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    position: 'absolute',
                    zIndex: 2,
                    left: 'auto',
                    right: '2rem',
                    top: '2rem',

                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  colorVariant='omega'
                  variant='crossMini'
                />
                <Books
                  styles={{
                    position: 'absolute',
                    right: 0,
                    top: 0
                  }}
                  size='sm'
                  position='right'
                />
              </Div>
            }>
            {/* @ts-expect-error TODO: remove this line and fix the error */}
            {(close: MouseEventHandler) => (
              <>
                <Div
                  onClick={close}
                  styles={{
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    borderRadius: '50%',
                    backgroundColor: 'var(--alpha)',
                    zIndex: 4,

                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}>
                  <IconPlus colorVariant='omega' variant='crossMini' />
                </Div>
                <Div
                  styles={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    backgroundColor: '#3A52EE',
                    borderRadius: '16px',
                    minHeight: 'auto',
                    maxHeight: 'max-content',
                    ...multiMedia({
                      desktop: {
                        maxWidth: '910px',
                        padding: '64px'
                      },
                      laptop: {
                        maxWidth: '800px',
                        padding: '32px'
                      },
                      tablet: {
                        maxWidth: '',
                        padding: '32px'
                      },
                      phone: {
                        maxWidth: '',
                        padding: '32px'
                      }
                    })
                  }}>
                  <Div
                    styles={{
                      visibility: popupThanks ? 'hidden' : 'visible',
                      zIndex: 3,
                      color: 'white',
                      width: '-webkit-fill-available',
                      height: '100%',
                      '& > p:first-child': {
                        ...media('marginBottom', '43px', {
                          laptop: '35px',
                          tablet: '27px',
                          phone: '27px'
                        })
                      }
                    }}>
                    <P
                      styles={{
                        ...media('fontSize', '24px', {
                          phone: '20px'
                        }),
                        fontWeight: 500,
                        lineHeight: '120%'
                      }}
                      variant='eta'>
                      {topic}
                    </P>
                    <Div
                      styles={{
                        display: 'flex',
                        gap: '19px',
                        flexDirection: 'column'
                      }}>
                      {pdf &&
                        pdf?.map(
                          ({ name }, idx) =>
                            idx < 2 &&
                            name && (
                              <Div
                                styles={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: toUnit(6)
                                }}
                                key={`ProgramUnion-Div__${idx + 1}`}>
                                <IconUnionFolder />
                                <P
                                  styles={{
                                    fontSize: '14px'
                                  }}
                                  variant='beta'>
                                  {name || ''}
                                </P>
                              </Div>
                            )
                        )}
                    </Div>
                  </Div>
                  <LeadForm
                    styles={{
                      zIndex: 3,
                      width: '100%',
                      visibility: popupThanks ? 'hidden' : 'visible'
                    }}
                    name
                    phoneNumber
                    email
                    pdfData={pdf?.map(({ name, url }) => ({
                      name,
                      url
                    }))}
                    isJournal
                    isPopupJournal
                    isSandwich
                    onSuccess={handleSuccess}
                    styleVariant='beta'
                    formName=''
                    colorsVariant='gamma'
                    id='DownloadFreeMaterials'
                  />

                  <>
                    <Div
                      styles={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: 'fit-content',
                        zIndex: 1,
                        height: '100%',
                        minHeight: 'inherit',
                        top: 0,
                        right: 0
                      }}>
                      <Ellipse
                        position='upRightDesktop'
                        styles={{
                          maxWidth: 'fit-content',
                          marginLeft: 'auto',
                          borderRadius: '16px'
                        }}
                      />
                      <Ellipse
                        position='middle'
                        styles={{
                          maxWidth: 'fit-content',
                          margin: '-6rem 10rem auto auto',
                          ...media('width', null, {
                            phone: '15rem'
                          })
                        }}
                      />
                    </Div>
                    <Div
                      styles={{
                        position: 'absolute',
                        zIndex: 2,
                        ...media('bottom', 0, {
                          phone: toUnit(300)
                        }),
                        right: 0
                      }}>
                      <BooksPopup position='rightDesktop' />
                    </Div>
                  </>
                </Div>

                {popupThanks && <PopupThanksSpan onForward={handleSuccess} />}
              </>
            )}
          </Popup>
        </Div>
      )}
    </Div>
  )
}

export default DownloadFreeMaterials
