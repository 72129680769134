import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { useTheme } from 'styled-components'
import Color from 'color'
import { TIconGlobeProps } from './types'

export const IconGlobe = ({ colorsVariant, ...props }: TIconGlobeProps) => {
  const { colors } = useTheme()

  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1.52637 11.25H7.50802C7.56965 8.37922 7.983 5.76938 8.63683 3.80788C8.89021 3.04774 9.18667 2.36369 9.52648 1.79301C5.15586 2.8484 1.85186 6.63803 1.52637 11.25Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
        <path
          d='M1.52637 12.75C1.85186 17.362 5.15586 21.1516 9.52648 22.207C9.18667 21.6363 8.89021 20.9523 8.63683 20.1921C7.983 18.2306 7.56965 15.6208 7.50802 12.75H1.52637Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
        <path
          d='M14.4735 22.207C18.8441 21.1516 22.1481 17.362 22.4736 12.75H16.492C16.4303 15.6208 16.017 18.2306 15.3632 20.1921C15.1098 20.9523 14.8133 21.6363 14.4735 22.207Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
        <path
          d='M22.4736 11.25C22.1481 6.63803 18.8441 2.8484 14.4735 1.79301C14.8133 2.36369 15.1098 3.04774 15.3632 3.80788C16.017 5.76938 16.4303 8.37922 16.492 11.25H22.4736Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
        <path
          d='M12 1.5C11.8105 1.5 11.5107 1.61451 11.1294 2.09831C10.7548 2.57366 10.3846 3.30797 10.0599 4.28222C9.4665 6.06227 9.06995 8.50534 9.00838 11.25H14.9916C14.93 8.50534 14.5335 6.06227 13.9401 4.28222C13.6154 3.30797 13.2452 2.57366 12.8706 2.09831C12.4893 1.61451 12.1895 1.5 12 1.5Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
        <path
          d='M13.9401 19.7178C14.5335 17.9377 14.93 15.4947 14.9916 12.75H9.00838C9.06995 15.4947 9.4665 17.9377 10.0599 19.7178C10.3846 20.692 10.7548 21.4263 11.1294 21.9017C11.5107 22.3855 11.8105 22.5 12 22.5C12.1895 22.5 12.4893 22.3855 12.8706 21.9017C13.2452 21.4263 13.6154 20.692 13.9401 19.7178Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
      </svg>
    </IconContainer>
  )
}

export default IconGlobe
