import { TStyledProps } from './types'

// TODO: figure out a better way to do this instead of manually typing out all the styled props

// * I've used a JS function I've written to generate this object where KEYS.styledProps comes from styledPropsPulledFromCSSDefenitionsTypes.ts file

// const temp = Object.keys(KEYS.styledProps).reduce(
//   (acc, cur) => ({ ...acc, [cur]: cur }),
//   {}
// console.log(temp)

export const STYLED_PROPS: TStyledProps = {
  accentColor: 'accentColor',
  alignContent: 'alignContent',
  alignItems: 'alignItems',
  alignSelf: 'alignSelf',
  alignmentBaseline: 'alignmentBaseline',
  all: 'all',
  animation: 'animation',
  // TODO fix types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // animationComposition: 'animationComposition',
  animationDelay: 'animationDelay',
  animationDirection: 'animationDirection',
  animationDuration: 'animationDuration',
  animationFillMode: 'animationFillMode',
  animationIterationCount: 'animationIterationCount',
  animationName: 'animationName',
  animationPlayState: 'animationPlayState',
  animationTimingFunction: 'animationTimingFunction',
  appearance: 'appearance',
  aspectRatio: 'aspectRatio',
  backdropFilter: 'backdropFilter',
  backfaceVisibility: 'backfaceVisibility',
  background: 'background',
  backgroundAttachment: 'backgroundAttachment',
  backgroundBlendMode: 'backgroundBlendMode',
  backgroundClip: 'backgroundClip',
  backgroundColor: 'backgroundColor',
  backgroundImage: 'backgroundImage',
  backgroundOrigin: 'backgroundOrigin',
  backgroundPosition: 'backgroundPosition',
  backgroundPositionX: 'backgroundPositionX',
  backgroundPositionY: 'backgroundPositionY',
  backgroundRepeat: 'backgroundRepeat',
  backgroundSize: 'backgroundSize',
  baselineShift: 'baselineShift',
  blockSize: 'blockSize',
  border: 'border',
  borderBlock: 'borderBlock',
  borderBlockColor: 'borderBlockColor',
  borderBlockEnd: 'borderBlockEnd',
  borderBlockEndColor: 'borderBlockEndColor',
  borderBlockEndStyle: 'borderBlockEndStyle',
  borderBlockEndWidth: 'borderBlockEndWidth',
  borderBlockStart: 'borderBlockStart',
  borderBlockStartColor: 'borderBlockStartColor',
  borderBlockStartStyle: 'borderBlockStartStyle',
  borderBlockStartWidth: 'borderBlockStartWidth',
  borderBlockStyle: 'borderBlockStyle',
  borderBlockWidth: 'borderBlockWidth',
  borderBottom: 'borderBottom',
  borderBottomColor: 'borderBottomColor',
  borderBottomLeftRadius: 'borderBottomLeftRadius',
  borderBottomRightRadius: 'borderBottomRightRadius',
  borderBottomStyle: 'borderBottomStyle',
  borderBottomWidth: 'borderBottomWidth',
  borderCollapse: 'borderCollapse',
  borderColor: 'borderColor',
  borderEndEndRadius: 'borderEndEndRadius',
  borderEndStartRadius: 'borderEndStartRadius',
  borderImage: 'borderImage',
  borderImageOutset: 'borderImageOutset',
  borderImageRepeat: 'borderImageRepeat',
  borderImageSlice: 'borderImageSlice',
  borderImageSource: 'borderImageSource',
  borderImageWidth: 'borderImageWidth',
  borderInline: 'borderInline',
  borderInlineColor: 'borderInlineColor',
  borderInlineEnd: 'borderInlineEnd',
  borderInlineEndColor: 'borderInlineEndColor',
  borderInlineEndStyle: 'borderInlineEndStyle',
  borderInlineEndWidth: 'borderInlineEndWidth',
  borderInlineStart: 'borderInlineStart',
  borderInlineStartColor: 'borderInlineStartColor',
  borderInlineStartStyle: 'borderInlineStartStyle',
  borderInlineStartWidth: 'borderInlineStartWidth',
  borderInlineStyle: 'borderInlineStyle',
  borderInlineWidth: 'borderInlineWidth',
  borderLeft: 'borderLeft',
  borderLeftColor: 'borderLeftColor',
  borderLeftStyle: 'borderLeftStyle',
  borderLeftWidth: 'borderLeftWidth',
  borderRadius: 'borderRadius',
  borderRight: 'borderRight',
  borderRightColor: 'borderRightColor',
  borderRightStyle: 'borderRightStyle',
  borderRightWidth: 'borderRightWidth',
  borderSpacing: 'borderSpacing',
  borderStartEndRadius: 'borderStartEndRadius',
  borderStartStartRadius: 'borderStartStartRadius',
  borderStyle: 'borderStyle',
  borderTop: 'borderTop',
  borderTopColor: 'borderTopColor',
  borderTopLeftRadius: 'borderTopLeftRadius',
  borderTopRightRadius: 'borderTopRightRadius',
  borderTopStyle: 'borderTopStyle',
  borderTopWidth: 'borderTopWidth',
  borderWidth: 'borderWidth',
  bottom: 'bottom',
  boxShadow: 'boxShadow',
  boxSizing: 'boxSizing',
  breakAfter: 'breakAfter',
  breakBefore: 'breakBefore',
  breakInside: 'breakInside',
  captionSide: 'captionSide',
  caretColor: 'caretColor',
  clear: 'clear',
  clipPath: 'clipPath',
  clipRule: 'clipRule',
  color: 'color',
  colorInterpolation: 'colorInterpolation',
  colorInterpolationFilters: 'colorInterpolationFilters',
  colorScheme: 'colorScheme',
  columnCount: 'columnCount',
  columnFill: 'columnFill',
  columnGap: 'columnGap',
  columnRule: 'columnRule',
  columnRuleColor: 'columnRuleColor',
  columnRuleStyle: 'columnRuleStyle',
  columnRuleWidth: 'columnRuleWidth',
  columnSpan: 'columnSpan',
  columnWidth: 'columnWidth',
  columns: 'columns',
  contain: 'contain',
  container: 'container',
  // containIntrinsicBlockSize: 'containIntrinsicBlockSize',
  // containIntrinsicHeight: 'containIntrinsicHeight',
  // containIntrinsicInlineSize: 'containIntrinsicInlineSize',
  // containIntrinsicSize: 'containIntrinsicSize',
  // containIntrinsicWidth: 'containIntrinsicWidth',
  containerName: 'containerName',
  containerType: 'containerType',
  content: 'content',
  counterIncrement: 'counterIncrement',
  counterReset: 'counterReset',
  counterSet: 'counterSet',
  cssFloat: 'cssFloat',
  cssText: 'cssText',
  cursor: 'cursor',
  direction: 'direction',
  display: 'display',
  dominantBaseline: 'dominantBaseline',
  emptyCells: 'emptyCells',
  fill: 'fill',
  fillOpacity: 'fillOpacity',
  fillRule: 'fillRule',
  filter: 'filter',
  flex: 'flex',
  flexBasis: 'flexBasis',
  flexDirection: 'flexDirection',
  flexFlow: 'flexFlow',
  flexGrow: 'flexGrow',
  flexShrink: 'flexShrink',
  flexWrap: 'flexWrap',
  float: 'float',
  floodColor: 'floodColor',
  floodOpacity: 'floodOpacity',
  font: 'font',
  fontFamily: 'fontFamily',
  fontFeatureSettings: 'fontFeatureSettings',
  fontKerning: 'fontKerning',
  fontOpticalSizing: 'fontOpticalSizing',
  fontPalette: 'fontPalette',
  fontSize: 'fontSize',
  fontSizeAdjust: 'fontSizeAdjust',
  fontStretch: 'fontStretch',
  fontStyle: 'fontStyle',
  // fontSynthesisSmallCaps: 'fontSynthesisSmallCaps',
  // fontSynthesisStyle: 'fontSynthesisStyle',
  // fontSynthesisWeight: 'fontSynthesisWeight',
  fontSynthesis: 'fontSynthesis',
  fontVariant: 'fontVariant',
  fontVariantAlternates: 'fontVariantAlternates',
  fontVariantCaps: 'fontVariantCaps',
  fontVariantEastAsian: 'fontVariantEastAsian',
  fontVariantLigatures: 'fontVariantLigatures',
  fontVariantNumeric: 'fontVariantNumeric',
  fontVariantPosition: 'fontVariantPosition',
  fontVariationSettings: 'fontVariationSettings',
  fontWeight: 'fontWeight',
  // forcedColorAdjust: 'forcedColorAdjust',
  gap: 'gap',
  grid: 'grid',
  gridArea: 'gridArea',
  gridAutoColumns: 'gridAutoColumns',
  gridAutoFlow: 'gridAutoFlow',
  gridAutoRows: 'gridAutoRows',
  gridColumn: 'gridColumn',
  gridColumnEnd: 'gridColumnEnd',
  gridColumnStart: 'gridColumnStart',
  gridRow: 'gridRow',
  gridRowEnd: 'gridRowEnd',
  gridRowStart: 'gridRowStart',
  gridTemplate: 'gridTemplate',
  gridTemplateAreas: 'gridTemplateAreas',
  gridTemplateColumns: 'gridTemplateColumns',
  gridTemplateRows: 'gridTemplateRows',
  height: 'height',
  hyphenateCharacter: 'hyphenateCharacter',
  hyphens: 'hyphens',
  imageRendering: 'imageRendering',
  inlineSize: 'inlineSize',
  inset: 'inset',
  insetBlock: 'insetBlock',
  insetBlockEnd: 'insetBlockEnd',
  insetBlockStart: 'insetBlockStart',
  insetInline: 'insetInline',
  insetInlineEnd: 'insetInlineEnd',
  insetInlineStart: 'insetInlineStart',
  isolation: 'isolation',
  justifyContent: 'justifyContent',
  justifyItems: 'justifyItems',
  justifySelf: 'justifySelf',
  left: 'left',
  length: 'length',
  letterSpacing: 'letterSpacing',
  lightingColor: 'lightingColor',
  lineBreak: 'lineBreak',
  lineHeight: 'lineHeight',
  listStyle: 'listStyle',
  listStyleImage: 'listStyleImage',
  listStylePosition: 'listStylePosition',
  listStyleType: 'listStyleType',
  margin: 'margin',
  marginBlock: 'marginBlock',
  marginBlockEnd: 'marginBlockEnd',
  marginBlockStart: 'marginBlockStart',
  marginBottom: 'marginBottom',
  marginInline: 'marginInline',
  marginInlineEnd: 'marginInlineEnd',
  marginInlineStart: 'marginInlineStart',
  marginLeft: 'marginLeft',
  marginRight: 'marginRight',
  marginTop: 'marginTop',
  marker: 'marker',
  markerEnd: 'markerEnd',
  markerMid: 'markerMid',
  markerStart: 'markerStart',
  mask: 'mask',
  maskClip: 'maskClip',
  maskComposite: 'maskComposite',
  maskImage: 'maskImage',
  maskMode: 'maskMode',
  maskOrigin: 'maskOrigin',
  maskPosition: 'maskPosition',
  maskRepeat: 'maskRepeat',
  maskSize: 'maskSize',
  maskType: 'maskType',
  // mathStyle: 'mathStyle',
  maxBlockSize: 'maxBlockSize',
  maxHeight: 'maxHeight',
  maxInlineSize: 'maxInlineSize',
  maxWidth: 'maxWidth',
  minBlockSize: 'minBlockSize',
  minHeight: 'minHeight',
  minInlineSize: 'minInlineSize',
  minWidth: 'minWidth',
  mixBlendMode: 'mixBlendMode',
  objectFit: 'objectFit',
  objectPosition: 'objectPosition',
  offset: 'offset',
  offsetDistance: 'offsetDistance',
  offsetPath: 'offsetPath',
  offsetRotate: 'offsetRotate',
  opacity: 'opacity',
  order: 'order',
  orphans: 'orphans',
  outline: 'outline',
  outlineColor: 'outlineColor',
  outlineOffset: 'outlineOffset',
  outlineStyle: 'outlineStyle',
  outlineWidth: 'outlineWidth',
  overflow: 'overflow',
  overflowAnchor: 'overflowAnchor',
  overflowClipMargin: 'overflowClipMargin',
  overflowWrap: 'overflowWrap',
  overflowX: 'overflowX',
  overflowY: 'overflowY',
  overscrollBehavior: 'overscrollBehavior',
  overscrollBehaviorBlock: 'overscrollBehaviorBlock',
  overscrollBehaviorInline: 'overscrollBehaviorInline',
  overscrollBehaviorX: 'overscrollBehaviorX',
  overscrollBehaviorY: 'overscrollBehaviorY',
  padding: 'padding',
  paddingBlock: 'paddingBlock',
  paddingBlockEnd: 'paddingBlockEnd',
  paddingBlockStart: 'paddingBlockStart',
  paddingBottom: 'paddingBottom',
  paddingInline: 'paddingInline',
  paddingInlineEnd: 'paddingInlineEnd',
  paddingInlineStart: 'paddingInlineStart',
  paddingLeft: 'paddingLeft',
  paddingRight: 'paddingRight',
  paddingTop: 'paddingTop',
  // page: 'page',
  pageBreakAfter: 'pageBreakAfter',
  pageBreakBefore: 'pageBreakBefore',
  pageBreakInside: 'pageBreakInside',
  paintOrder: 'paintOrder',
  parentRule: 'parentRule',
  perspective: 'perspective',
  perspectiveOrigin: 'perspectiveOrigin',
  placeContent: 'placeContent',
  placeItems: 'placeItems',
  placeSelf: 'placeSelf',
  pointerEvents: 'pointerEvents',
  position: 'position',
  printColorAdjust: 'printColorAdjust',
  quotes: 'quotes',
  resize: 'resize',
  right: 'right',
  rotate: 'rotate',
  rowGap: 'rowGap',
  rubyPosition: 'rubyPosition',
  scale: 'scale',
  scrollBehavior: 'scrollBehavior',
  scrollMargin: 'scrollMargin',
  scrollMarginBlock: 'scrollMarginBlock',
  scrollMarginBlockEnd: 'scrollMarginBlockEnd',
  scrollMarginBlockStart: 'scrollMarginBlockStart',
  scrollMarginBottom: 'scrollMarginBottom',
  scrollMarginInline: 'scrollMarginInline',
  scrollMarginInlineEnd: 'scrollMarginInlineEnd',
  scrollMarginInlineStart: 'scrollMarginInlineStart',
  scrollMarginLeft: 'scrollMarginLeft',
  scrollMarginRight: 'scrollMarginRight',
  scrollMarginTop: 'scrollMarginTop',
  scrollPadding: 'scrollPadding',
  scrollPaddingBlock: 'scrollPaddingBlock',
  scrollPaddingBlockEnd: 'scrollPaddingBlockEnd',
  scrollPaddingBlockStart: 'scrollPaddingBlockStart',
  scrollPaddingBottom: 'scrollPaddingBottom',
  scrollPaddingInline: 'scrollPaddingInline',
  scrollPaddingInlineEnd: 'scrollPaddingInlineEnd',
  scrollPaddingInlineStart: 'scrollPaddingInlineStart',
  scrollPaddingLeft: 'scrollPaddingLeft',
  scrollPaddingRight: 'scrollPaddingRight',
  scrollPaddingTop: 'scrollPaddingTop',
  scrollSnapAlign: 'scrollSnapAlign',
  scrollSnapStop: 'scrollSnapStop',
  scrollSnapType: 'scrollSnapType',
  scrollbarGutter: 'scrollbarGutter',
  shapeImageThreshold: 'shapeImageThreshold',
  shapeMargin: 'shapeMargin',
  shapeOutside: 'shapeOutside',
  shapeRendering: 'shapeRendering',
  stopColor: 'stopColor',
  stopOpacity: 'stopOpacity',
  stroke: 'stroke',
  strokeDasharray: 'strokeDasharray',
  strokeDashoffset: 'strokeDashoffset',
  strokeLinecap: 'strokeLinecap',
  strokeLinejoin: 'strokeLinejoin',
  strokeMiterlimit: 'strokeMiterlimit',
  strokeOpacity: 'strokeOpacity',
  strokeWidth: 'strokeWidth',
  tabSize: 'tabSize',
  tableLayout: 'tableLayout',
  textAlign: 'textAlign',
  textAlignLast: 'textAlignLast',
  textAnchor: 'textAnchor',
  textCombineUpright: 'textCombineUpright',
  textDecoration: 'textDecoration',
  textDecorationColor: 'textDecorationColor',
  textDecorationLine: 'textDecorationLine',
  textDecorationSkipInk: 'textDecorationSkipInk',
  textDecorationStyle: 'textDecorationStyle',
  textDecorationThickness: 'textDecorationThickness',
  textEmphasis: 'textEmphasis',
  textEmphasisColor: 'textEmphasisColor',
  textEmphasisPosition: 'textEmphasisPosition',
  textEmphasisStyle: 'textEmphasisStyle',
  textIndent: 'textIndent',
  textOrientation: 'textOrientation',
  textOverflow: 'textOverflow',
  textRendering: 'textRendering',
  textShadow: 'textShadow',
  textTransform: 'textTransform',
  textUnderlineOffset: 'textUnderlineOffset',
  textUnderlinePosition: 'textUnderlinePosition',
  top: 'top',
  touchAction: 'touchAction',
  transform: 'transform',
  transformBox: 'transformBox',
  transformOrigin: 'transformOrigin',
  transformStyle: 'transformStyle',
  transition: 'transition',
  transitionDelay: 'transitionDelay',
  transitionDuration: 'transitionDuration',
  transitionProperty: 'transitionProperty',
  transitionTimingFunction: 'transitionTimingFunction',
  translate: 'translate',
  unicodeBidi: 'unicodeBidi',
  userSelect: 'userSelect',
  verticalAlign: 'verticalAlign',
  visibility: 'visibility',
  webkitLineClamp: 'webkitLineClamp',
  webkitMaskComposite: 'webkitMaskComposite',
  webkitTextFillColor: 'webkitTextFillColor',
  webkitTextStroke: 'webkitTextStroke',
  webkitTextStrokeColor: 'webkitTextStrokeColor',
  webkitTextStrokeWidth: 'webkitTextStrokeWidth',
  whiteSpace: 'whiteSpace',
  widows: 'widows',
  width: 'width',
  willChange: 'willChange',
  wordBreak: 'wordBreak',
  wordSpacing: 'wordSpacing',
  writingMode: 'writingMode',
  zIndex: 'zIndex',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  animationComposition: 'animationComposition',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baselineSource: 'baselineSource',
  containIntrinsicBlockSize: 'containIntrinsicBlockSize',
  containIntrinsicHeight: 'containIntrinsicHeight',
  containIntrinsicInlineSize: 'containIntrinsicInlineSize',
  containIntrinsicSize: 'containIntrinsicSize',
  containIntrinsicWidth: 'containIntrinsicWidth',
  cx: 'cx',
  cy: 'cy',
  d: 'd',
  fontSynthesisSmallCaps: 'fontSynthesisSmallCaps',
  fontSynthesisStyle: 'fontSynthesisStyle',
  fontSynthesisWeight: 'fontSynthesisWeight',
  forcedColorAdjust: 'forcedColorAdjust',
  mathDepth: 'mathDepth',
  mathStyle: 'mathStyle',
  offsetAnchor: 'offsetAnchor',
  offsetPosition: 'offsetPosition',
  page: 'page',
  r: 'r',
  rx: 'rx',
  ry: 'ry',
  scrollbarColor: 'scrollbarColor',
  scrollbarWidth: 'scrollbarWidth',
  textWrap: 'textWrap',
  vectorEffect: 'vectorEffect',
  x: 'x',
  y: 'y'
}
