import * as React from 'react'
import { TPProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const P = ({ ...props }: TPProps) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{ /* DEFAULT_STYLES */ ...props.styles }}
    />
  )
}

export default P
