import * as React from 'react'
import NextLink from 'next/link'
import { StyledJSX } from 'ui'
import { TLinkProps } from './types'
import { PASS_HREF, LEGACY_BEHAVIOR } from './constants'

const Link = ({
  children,
  href,
  as,
  passHref = PASS_HREF,
  legacyBehavior = LEGACY_BEHAVIOR,
  prefetch,
  replace,
  scroll,
  shallow,
  locale,
  ...props
}: TLinkProps) => {
  return (
    <NextLink
      href={href}
      as={as}
      passHref={passHref}
      legacyBehavior={legacyBehavior}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}>
      <StyledJSX.A {...props}>{children}</StyledJSX.A>
    </NextLink>
  )
}

export default Link
