import React from 'react'
import { Layout } from 'ui'
import Color from 'color'
import { Span } from 'ui/modules/styled-jsx/widgets'
import { TJournalsCardProps } from './types'
import { media, multiMedia, toUnit } from '@/fractals/utils'
import { Div, H2, H3, IconClock, P } from '@/fractals/components'
import { useMedia } from '@/fractals/hooks'

export const JournalsCard = ({
  id,
  name,
  description,
  slug,
  time,
  createdDate,
  faculty,
  atHome
}: TJournalsCardProps) => {
  const { isMobile, isLaptop, isTablet, isDesktop } = useMedia()
  return (
    <Div
      styles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: !isDesktop || id !== 0 ? '17px' : '',
        ...multiMedia({
          desktop: {
            width: id === 0 ? '592px' : '100%'
          },
          laptop: {
            width: '100%'
          },
          tablet: {
            width: '100%'
          },
          phone: {
            width: atHome ? 'auto' : '343px'
          }
        }),
        height: id === 0 && isDesktop ? '100%' : ''
      }}>
      {id === 0 && isDesktop ? (
        <>
          <Div>
            <H2 variant='journalMain'>{name}</H2>
          </Div>
          {isDesktop && id === 0 && (
            <Span
              styles={{
                color: '#8B8B8B'
              }}>
              {description?.length !== undefined && description?.length > 1
                ? description
                : 'Узнайте что-то новое для себя в самой свежей статье от MITM институт'}
            </Span>
          )}
          <Layout.FlexItem
            styles={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 'auto'
            }}>
            <Layout.FlexItem
              styles={{
                color: '#8B8B8B',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <P variant='xiBadge'>
                {createdDate && createdDate?.split('-').reverse().join('.')}
              </P>
              <IconClock
                styles={{
                  margin: '0 4px 0 32px'
                }}
              />
              <P
                styles={{
                  color: 'var(--upsilon)'
                }}
                variant='xiBadge'>
                {`${time?.split(':')[1]} мин`}
              </P>
            </Layout.FlexItem>
          </Layout.FlexItem>
        </>
      ) : (
        <>
          <Div
            styles={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 'fit-content',
              gap: toUnit(16)
            }}>
            <Layout.FlexItem
              styles={{
                ...media('color', '#8B8B8B', {
                  phone: id === 0 ? '#FFFFFF' : '#8B8B8B'
                }),
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                // gap: '1rem',
                alignItems: 'center'
              }}>
              <P
                styles={{
                  ...media('color', '#8B8B8B', {
                    phone: id === 0 && !atHome ? '#FFFFFF' : '#8B8B8B'
                  })
                }}
                variant='xiBadge'>
                {createdDate && createdDate?.split('-').reverse().join('.')}
              </P>
              <IconClock
                styles={{
                  '& svg > path': {
                    stroke:
                      isMobile && id === 0 && !atHome ? 'white' : '#8B8B8B',
                    fill: isMobile && id === 0 && !atHome ? 'white' : '#8B8B8B'
                  },
                  margin: '0 4px 0 32px'
                }}
              />
              <P
                styles={{
                  ...media('color', 'var(--upsilon)', {
                    phone: id === 0 && !atHome ? '#FFFFFF' : 'var(--upsilon)'
                  })
                }}
                variant='xiBadge'>
                {`${time?.split(':')[1]} мин`}
              </P>
            </Layout.FlexItem>
          </Div>
          <Div>
            <H2
              styles={{
                transition: 'color 0.3s ease-in-out',
                ':hover': {
                  ...media('color', 'var(--beta)', {
                    phone: id === 0 && !atHome ? 'var(--omega)' : 'var(--beta)'
                  }),
                  // color: 'var(--beta)',
                  transition: 'color 0.3s ease'
                },
                ...media('color', 'var(--omega)', {
                  phone: id === 0 && !atHome ? '#FFFFFF' : 'var(--omega)'
                })
              }}
              variant={
                id !== null &&
                id % 5 === 0 &&
                !isMobile &&
                !isDesktop &&
                !atHome
                  ? 'journalMain'
                  : 'journalMainBelow'
              }>
              {name}
            </H2>
          </Div>
          {((id === 0 && !atHome) ||
            (id !== null &&
              id % 5 === 0 &&
              (isTablet || isLaptop) &&
              !atHome)) && (
            <Span
              styles={{
                ...media('color', '#8B8B8B', {
                  phone: id === 0 ? '#FFFFFF' : '#8B8B8B'
                })
              }}>
              {description?.length !== undefined && description?.length > 1
                ? description
                : 'Узнайте что-то новое для себя в самой свежей статье от MITM институт'}
            </Span>
          )}
        </>
      )}
    </Div>
  )
}

export default JournalsCard
