import React from 'react'
import { Link, Div, Image, PATHS, toUnit, multiMedia } from 'fractals'
import { CurrentTag } from '../CurrentTag'
import { JournalsCard } from '../JournalsCard'
import * as styles from '../../organisms/JournalsCards/styles'
import { TJournalsCardFullProps } from './types'
import { STYLE_VARIANT_KEYS } from './constants'

export const JournalsCardFull = ({
  idx,
  name,
  description,
  slug,
  createdDate,
  time,
  faculty,
  tag,
  image,
  category,
  variant = 'default'
}: TJournalsCardFullProps) => {
  const variants = {
    [STYLE_VARIANT_KEYS.default]: {
      divCard: 'journal-card',
      divImage: 'journalCard__image'
    },
    [STYLE_VARIANT_KEYS.atHome]: {
      divCard: 'journal-card-atHome',
      divImage: 'journalCard__image-atHome'
    }
  } as const

  return (
    <Link
      variant='alpha'
      styles={{
        ...multiMedia({
          flexBasis: '50%',
          desktop: {
            flexBasis: (idx === 0 && '100%') || '33.3%',
            marginBottom: (idx === 0 && '56px') || ''
          },
          laptop: { marginBottom: ((idx === 0 || idx === 1) && '32px') || '' },
          tablet: { marginBottom: ((idx === 0 || idx === 1) && '56px') || '' },
          phone: {
            flexBasis: '100%',
            marginBottom: (idx !== 3 && '40px') || ''
          }
        })
      }}
      href={`${PATHS.front.root.index}${PATHS.front.journal.ru}--${
        faculty.slug || tag?.slug
      }--${slug}`}>
      <Div
        className={variants[variant].divCard}
        styles={styles.journalsCardsDiv(idx ?? 0).variants[variant]}>
        <JournalsCard
          id={idx}
          name={name}
          description={description}
          slug={slug}
          createdDate={createdDate}
          time={time}
          faculty={faculty}
          atHome={variant === 'atHome'}
        />
        <Div
          className={variants[variant].divImage}
          styles={styles.journalsCardsImageDiv(idx ?? 0).variants[variant]}>
          <Image
            src={image?.url || '/'}
            alt={image?.alternativeText || ''}
            fill
            sizes='(max-width: 768px) 50vw, 75vw'
            quality={75}
            containerProps={{
              styles: {
                borderRadius: '8px',
                '& img': {
                  objectFit: 'cover'
                }
              }
            }}
            colorVariant='transparent'
          />

          <Div
            styles={{
              position: 'absolute',
              margin: toUnit(16)
            }}>
            {(faculty.name || tag?.name) && (
              <CurrentTag
                faculty={
                  faculty.tagImage && {
                    tagImage: faculty.tagImage
                  }
                }
                tag={
                  tag?.tagImage && {
                    tagImage: tag?.tagImage
                  }
                }
              />
            )}
          </Div>
        </Div>
      </Div>
    </Link>
  )
}

export default JournalsCardFull
