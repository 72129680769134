const BeforeLoadStyles = () => {
  return (
    <style>
      {`
        .before-load-opacity-1 {
          opacity: 1 !important;
        }

        .before-load-overflow-visible {
          overflow: visible !important;
        }

        .before-load-position-static {
          position: static !important;
        }

        .before-load-display-flex {
          display: flex !important;
        }
      `}
    </style>
  )
}

export default BeforeLoadStyles
