export const parseGeneralSlug = (str: string | null | undefined) => {
  const strSplit = str?.split('--') || null

  const isHomePage = strSplit === null

  const redirects: Array<Record<string, string>> = [
    { source: '/bachelor', destination: '/en/bachelor' },
    { source: '/bakalavriat-1', destination: '/kk/bakalavriat-1' },
    { source: '/bakalavr', destination: '/uz/bakalavr' },
    { source: '/magistr-de-geji', destination: '/kk/magistr-de-geji' },
    { source: '/master', destination: '/en/master' },
    { source: '/magistr-diplomi', destination: '/uz/magistr-diplomi' },
    { source: '/kurslar', destination: '/uz/kurslar' },
    { source: '/kurstar', destination: '/kk/kurstar' },
    { source: '/courses', destination: '/en/courses' },
    { source: '/programlar', destination: '/uz/programlar' },
    { source: '/programs', destination: '/en/programs' },
    { source: '/mba-1', destination: '/en/mba-1' },
    { source: '/mba-2', destination: '/kk/mba-2' },
    { source: '/mba-3', destination: '/uz/mba-3' }
  ]

  const sortKeys = ['alphabet', 'cost', 'duration', 'popularity']

  const bachelorKeys = ['bakalavriat', 'bachelor', 'bakalavriat-1', 'bakalavr']

  const masterKeys = [
    'magistratura',
    'master',
    'magistr-de-geji',
    'magistr-diplomi'
  ]

  const mbaKeys = ['mba', 'mba-1', 'mba-2', 'mba-3']

  const higherEducationKeys = [...bachelorKeys, ...masterKeys, ...mbaKeys]

  const courseKeys = ['kursy', 'courses', 'kurslar', 'kurstar', 'kurs'] as const
  const courseKeysForProgramPage = courseKeys.filter(key => key !== 'kursy')

  const collegeKeys = ['college', 'college', 'college', 'college']
  const weekendGroupKeys = [
    'gruppa-vyhodnogo-dnya',
    'gruppa-vyhodnogo-dnya',
    'gruppa-vyhodnogo-dnya',
    'gruppa-vyhodnogo-dnya'
  ]
  const programsKeys = ['programmy', 'programs', 'programlar', 'programlar']

  const facultyKeys = ['fakultet', 'faculty', 'fakultet', 'fakultetler']

  const journalKeys = ['journal', 'jurnal', 'jwrnal']

  const isProgramsPage =
    (strSplit &&
      [
        ...higherEducationKeys,
        ...courseKeys,
        ...collegeKeys,
        ...programsKeys,
        ...weekendGroupKeys
      ].some(key => key === strSplit?.[0])) ||
    false

  const isProgramsFacultyPage =
    (isProgramsPage && facultyKeys.some(key => key === strSplit?.[1])) || false

  const isProgramPage =
    (!isProgramsPage &&
      [
        ...higherEducationKeys,
        ...courseKeysForProgramPage,
        ...collegeKeys,
        ...weekendGroupKeys
      ].some(key => key === strSplit?.[strSplit.length - 1])) ||
    false

  const isJournalsPage =
    [...journalKeys].some(
      key => key === strSplit?.[0] && strSplit.length <= 2
    ) || false

  const isJournalPage = [...journalKeys].some(
    key => key === strSplit?.[0] && strSplit.length > 2
  )

  // && strSplit?.[strSplit.length - 1] === getCurrentJournal()

  const currentJournalsFacultySlug = (isJournalsPage && strSplit?.[1]) || null

  const currentCategorySlug =
    (isProgramsPage &&
      [
        ...higherEducationKeys,
        ...courseKeys,
        ...collegeKeys,
        ...weekendGroupKeys
      ].some(key => key === strSplit?.[0]) &&
      strSplit?.[0]) ||
    (isProgramPage && strSplit?.[strSplit.length - 1]) ||
    null

  const currentFacultySlug = (isProgramsFacultyPage && strSplit?.[2]) || null

  const currentProgramSlug = (isProgramPage && strSplit?.[0]) || null

  const currentSearchSlugs =
    (isProgramsPage &&
      !isProgramsFacultyPage &&
      strSplit?.length !== undefined &&
      strSplit.length >= 2 &&
      strSplit?.slice(1, strSplit.length)) ||
    null

  const currentJournalsByFilterSlug = (isJournalsPage && strSplit?.[1]) || null

  const currentJournalSlug =
    (!isJournalsPage && strSplit?.[strSplit.length - 1]) || null

  const currentSortKey =
    (isProgramsPage &&
      sortKeys.find(key => key === strSplit?.[strSplit.length - 1])) ||
    null

  const isBachelor = bachelorKeys.some(key => key === currentCategorySlug)

  const isMaster = masterKeys.some(key => key === currentCategorySlug)

  const isMba = mbaKeys.some(key => key === currentCategorySlug)

  const isCollege = collegeKeys.some(key => key === currentCategorySlug)

  const isHigherEducation = higherEducationKeys.some(
    key => key === currentCategorySlug
  )

  const isCourse = courseKeys.some(key => key === currentCategorySlug)

  const isWeekendGroup = weekendGroupKeys.some(
    key => key === currentCategorySlug
  )

  const redirect =
    (isProgramsPage && redirects.find(r => r.source === `/${strSplit?.[0]}`)) ||
    null

  return {
    isHomePage,
    isProgramsPage,
    isProgramsFacultyPage,
    isProgramPage,
    isJournalsPage,
    isJournalPage,
    isBachelor,
    isMaster,
    isMba,
    isCollege,
    isHigherEducation,
    isCourse,
    isWeekendGroup,
    currentCategorySlug,
    currentFacultySlug,
    currentProgramSlug,
    currentJournalsByFilterSlug,
    currentJournalsFacultySlug,
    currentJournalSlug,
    currentSearchSlugs,
    currentSortKey,
    redirect
  }
}

export default parseGeneralSlug
