import React from 'react'
import { P } from '@/fractals/components'

export const HeaderItem = ({ content }: { content: string }) => {
  return (
    <P
      styles={{ fontSize: '18px' }}
      variant='nu'
      dangerouslySetInnerHTML={{
        __html: content
      }}>
      {null}
    </P>
  )
}

export default HeaderItem
