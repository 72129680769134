import { LOCALES } from 'fractals'

export const TESTIMONIAL_TITLE_1 = {
  [LOCALES.en]: 'Credit for education with state support',
  [LOCALES.ru]: 'Кредит на обучение с господдержкой',
  [LOCALES.uz]: 'Davlat qo’llab-quvvatlashi bilan ta’lim uchun kredit',
  [LOCALES.kk]: 'Дербес қолданумен оқу үшін кредит'
} as const

export const TESTIMONIAL_TITLE_1_ALT = {
  [LOCALES.en]: 'Money back',
  [LOCALES.ru]: 'Возврат денег',
  [LOCALES.uz]: 'Pul qaytish',
  [LOCALES.kk]: 'Ақшаны қайтару'
} as const

export const TESTIMONIAL_DESCRIPTION_1 = {
  [LOCALES.en]:
    'Get an educational credit on favorable terms for obtaining higher education',
  [LOCALES.ru]:
    'Получите образовательный кредит на льготных условиях для получения высшего образования',
  [LOCALES.uz]:
    'Oliy ta’limni olish uchun arzon shartlarda ta’lim kreditini oling',
  [LOCALES.kk]: 'Жоғары мамандықты алу үшін арзан шарттарда оқу кредитін алыңыз'
} as const

export const TESTIMONIAL_DESCRIPTION_1_ALT = {
  [LOCALES.en]:
    'If you change your mind, we will return the full amount within the first two weeks',
  [LOCALES.ru]:
    'Если вы передумаете учиться, то мы вернем полную сумму в течение первых двух недель',
  [LOCALES.uz]:
    'Agar siz o’zgartirib qolsangiz, biz birinchi ikki haftada to’liq miqdordan qaytaramiz',
  [LOCALES.kk]:
    'Егер сіз өзгертіп қалсаңыз, біз бірінші екі апта кезінде толық мөлшерден қайтарамыз'
} as const

export const TESTIMONIAL_TITLE_2 = {
  [LOCALES.en]: 'Save 13%',
  [LOCALES.ru]: 'Сэкономьте 13%',
  [LOCALES.uz]: '13% saqlang',
  [LOCALES.kk]: '13% сақтаңыз'
} as const

export const TESTIMONIAL_DESCRIPTION_2 = {
  [LOCALES.en]:
    'Get a tax deduction. All details are with the manager when you register for the course',
  [LOCALES.ru]:
    'Получите налоговый вычет. Все подробности у менеджера при записи на курс',
  [LOCALES.uz]:
    'Soliqni olib tashlang. Barcha xususiyatlari kursga ro’yxatdan o’tishda menejer bilan',
  [LOCALES.kk]:
    'Соликті алып тастаңыз. Барлық ерекшелер курсты рәйдан оқуға мағлұмат бергенде'
} as const

export const TESTIMONIAL_TITLE_3 = {
  [LOCALES.en]: 'Any questions?',
  [LOCALES.ru]: 'Остались вопросы?',
  [LOCALES.uz]: 'Savollar qoldi?',
  [LOCALES.kk]: 'Сұрақтар қалды?'
} as const

export const TESTIMONIAL_DESCRIPTION_3 = {
  [LOCALES.en]: 'Call or write to us: ',
  [LOCALES.ru]: 'Позвоните или напишите нам: ',
  [LOCALES.uz]: 'Bizga zvonit yoki yozing: ',
  [LOCALES.kk]: 'Бізге деңгейді немесе жазыңыз: '
} as const
