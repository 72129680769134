import * as React from 'react'
import { TH3Props } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const H3 = ({ ...props }: TH3Props) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{ /* DEFAULT_STYLES */ ...props.styles }}
    />
  )
}

export default H3
