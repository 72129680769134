// TODO: remove this eslint-disable-next-line after fixing the circular dependency
// eslint-disable-next-line import/no-cycle
export { default as ProgramPage } from './ProgramPage'
export {
  getHeroData,
  getWhatWillYouLearnData,
  getGraduateProfessionsData,
  getLeastProgramContentsData,
  getCostData,
  getReviewsData,
  getTeachersData,
  getForWhomData,
  getStudyIsData,
  getExamFreeEnrollmentData
} from './widgets'
export {
  IDS,
  H1,
  FOR_WHOM_HEADING,
  LEAST_PROGRAM_CONTENTS_HEADING,
  COST_HEADING,
  getSeoData
} from './fractals'
