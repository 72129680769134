import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { useTheme } from 'styled-components'
import Color from 'color'
import { TIconCheckProps } from './types'

const IconCheck = ({ colorsVariant, ...props }: TIconCheckProps) => {
  const { colors } = useTheme()

  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20 6L9 17L4 12'
          stroke={Color(colors[colorsVariant]).rgb().string()}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconContainer>
  )
}

export default IconCheck
