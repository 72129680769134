import {
  fetchQuestions,
  fetchCategoryQuestions,
  normalizeQuestions
} from './utils'
import { TGetFAQDataProps } from '@/modules/shared/widgets/FAQ/fractals/api/getFAQData/types'

export const getProgramsQuestions = async ({ context }: TGetFAQDataProps) => {
  try {
    const questionsData =
      (await fetchQuestions({ context })) ||
      (await fetchCategoryQuestions({ context }))

    const questionsDataNormalized = normalizeQuestions({
      questionsData,
      context
    })

    return questionsDataNormalized
  } catch (e) {
    console.log('%cgetFAQData.ts line:14 e', 'color: #007acc;', e)
  }
  return null
}

export default getProgramsQuestions
