import { StyledJSX } from 'ui'
import { TOlProps } from './types'

const Ol = ({ /* variant, */ ...props }: TOlProps) => {
  // const stylesVariants = {
  //   // [VARIANTS_KEYS.alpha]: {
  //   // STYLES HERE
  //   // },
  // } as const

  const styles = {
    marginTop: 0,
    marginBottom: 0,
    // ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Ol {...props} styles={styles} />
}

export default Ol
