import React from 'react'
import { media } from 'fractals'
import { Span } from '@/fractals/components'

export const HeadItem = ({ content }: { content: string }) => {
  return (
    <Span
      styles={{
        margin: '32px 0',
        display: 'block',
        ...media('fontSize', '24px', {
          desktop: '36px'
        }),
        fontWeight: 500,
        lineHeight: '120%'
      }}
      dangerouslySetInnerHTML={{
        __html: content
      }}>
      {null}
    </Span>
  )
}

export default HeadItem
