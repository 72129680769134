import * as React from 'react'
import { IconContainer, media, toUnit, useMedia, Image } from 'fractals'
import { TFolderProps } from './types'
import folder from './folder.png'

export const Folder = ({ size, ...props }: TFolderProps) => {
  const { isTablet, isLaptop } = useMedia()
  const styles = {
    height: 'fit-content',
    '& img':
      (size === 'sm' && {
        width: toUnit(200)
      }) ||
      (size === 'lg' && {
        borderRadius: '0 0 16px 0',
        width: isTablet || isLaptop ? '390px' : '368px',
        // transform: isTablet || isLaptop ? 'scale(1.6)' : '',
        viewBox: isTablet || isLaptop ? '0 0 390 461' : '0 0 368 400'
      }) ||
      undefined, // * default direction is up
    ...props?.styles
  } as const
  return (
    <Image
      id='folder__image'
      colorVariant='transparent'
      src={folder.src || '/'}
      width={folder.width}
      height={folder.height}
      alt={folder.blurDataURL || `folder__image`}
      borderRadiusVariant='alpha'
      containerProps={{
        styles: {
          ...styles,
          '& img': {
            position: 'relative',
            objectFit: 'cover'
          }
        }
      }}
    />
  )
}

export default Folder
