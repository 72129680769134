import { sortBasedOnKey } from 'fractals'
import { fetchFaculties } from './fetchFaculties'

export const normalizeFacultiesData = ({
  facultiesData
}: {
  facultiesData: Awaited<ReturnType<typeof fetchFaculties>>
}) => {
  return { faculties: facultiesData }
}

export default normalizeFacultiesData
