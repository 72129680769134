import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: 'For admission, you will need a minimum of documents',
  [LOCALES.ru]: 'Для поступления потребуется минимум документов',
  [LOCALES.uz]: 'Qabul uchun sizga minimal hajmda hujjat kerak bo’ladi',
  [LOCALES.kk]: 'Келісімге қатысу үшін сізге минималды құжаттар керек'
}

export const PASSPORT = {
  [LOCALES.en]: 'Passport',
  [LOCALES.ru]: 'Паспорт',
  [LOCALES.uz]: 'Pasport',
  [LOCALES.kk]: 'Паспорт'
}
export const DIPLOMAS = {
  [LOCALES.en]: 'Diploma / certificate',
  [LOCALES.ru]: 'Диплом / аттестат',
  [LOCALES.uz]: 'Diplom / sertifikat',
  [LOCALES.kk]: 'Диплом / сертификат'
}

export const APPLICATION = {
  [LOCALES.en]: 'Application for admission',
  [LOCALES.ru]: 'Заявление на поступление',
  [LOCALES.uz]: 'Qabul uchun ariza',
  [LOCALES.kk]: 'Келісімге қатысу үшін арқылы сұрақ'
}
