import * as React from 'react'
import { StyledJSX } from 'ui'
import { TFormProps } from './types'

const Form = ({
  // colorsVariant,
  ...props
}: TFormProps) => {
  // const [notWorkingYetMessage] = useTranslations(NOT_WORKING_YET_MESSAGE)

  // const colorsVariants = {
  //   // [COLORS_VARIANT_KEYS.omega]: {
  //   //   backgroundColor: 'var(--omega)',
  //   //   color: 'var(--alpha)',
  //   //   '@media (hover: hover)': {
  //   //     '&:hover': {
  //   //       backgroundColor: 'var(--alpha)',
  //   //       transform
  //   //     }
  //   //   }
  //   // }
  // } as const

  const styles = {
    ...props.styles
  } as const

  return <StyledJSX.Form {...props} styles={styles} />
}

export default Form
