import { sortBasedOnKey, parseGeneralSlug } from 'fractals'
import { fetchFaculties } from './fetchFaculties'

export const normalizeFacultiesData = ({
  facultiesData,
  generalSlug
}: {
  facultiesData: Awaited<ReturnType<typeof fetchFaculties>>
  generalSlug: string | null | undefined
}) => {
  const { currentCategorySlug } = parseGeneralSlug(generalSlug)

  const facultiesDataNormalized = facultiesData
    ?.map(
      ({
        name,
        slug,
        rank,
        programs,
        color,
        description,
        descriptionBachelor,
        descriptionMagister,
        descriptionCourses,
        facultyImage
      }) => ({
        name: name || null,
        slug: slug || null,
        rank: rank || Infinity,
        color: color || null,
        description: description || null,
        descriptionBachelor: descriptionBachelor || null,
        descriptionMagister: descriptionMagister || null,
        descriptionCourses: descriptionCourses || null,
        facultyImage: facultyImage || null,
        programs:
          programs?.filter(program =>
            currentCategorySlug
              ? program?.category?.slug === currentCategorySlug
              : program
          )?.length || null
      })
    )
    .filter(faculty => faculty?.programs)

  if (facultiesDataNormalized && facultiesDataNormalized?.length !== 0)
    return facultiesDataNormalized

  return null
}

export default normalizeFacultiesData
