import { TGetTeachersDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getTeachersData = async ({ context }: TGetTeachersDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getTeachersData
