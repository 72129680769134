import {
  P,
  toUnit,
  Div,
  Span,
  useAt,
  useMedia,
  useTranslations,
  H3,
  H2
} from 'fractals'
import { useRouter } from 'next/router'
import {
  insertWhiteSpacesAfterPrepositions,
  Layout,
  toNumberWithSpaces,
  spacesToNonBrakingSpaces,
  NON_BRAKING_SPACE
} from 'ui'
import Color from 'color'
import { Flex } from 'ui/modules/layout1/widgets'
import { TPriceCardProps } from './types'
import { calculatePrice } from './utils'
import { DiscountPsihologiyaIsHigherEducation } from '@/modules/program-page/fractals'
import { Shared } from '@/modules/shared'
import { BTN_VALUE } from '../WhatIsIncluded/constants'

export const PriceCardPsihologiyaIsHigherEducation = ({
  price,
  discount,
  colorVariant,
  discountUntil,
  costFormatHeading,
  costFormatSubHeading,
  additionalPrice,
  additionalDiscount
}: TPriceCardProps) => {
  const { locale } = useRouter()

  const { isTablet, isLaptop, isDesktop, isMobile } = useMedia()

  const { isCourse, isCollege, isHigherEducation } = useAt()

  const calculatedPrice = calculatePrice({
    price,
    discount,
    locale
  })

  let additionalCalculatedPrice
  if (additionalPrice && additionalDiscount) {
    additionalCalculatedPrice = calculatePrice({
      price: additionalPrice,
      discount: additionalDiscount,
      locale
    })
  }

  const [btnValue] = useTranslations(BTN_VALUE)

  const paymentOptions = [
    ...(((isHigherEducation || isCollege) && [
      additionalCalculatedPrice?.oneSemisterPayment
        ? additionalCalculatedPrice.oneSemisterPayment
        : calculatedPrice.oneSemisterPayment
    ]) ||
      []),
    additionalCalculatedPrice?.yearInstallmentPlan
      ? additionalCalculatedPrice.yearInstallmentPlan
      : calculatedPrice.yearInstallmentPlan
  ] as const

  return (
    <Div
      styles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (isCourse && 'space-between') || undefined,
        flexGrow: 1,
        // paddingTop: toUnit(((isDesktop || isLaptop || isTablet) && 38) || 32),
        paddingTop: toUnit(33),
        paddingRight: toUnit(((isDesktop || isLaptop) && 32) || 32),
        paddingBottom: toUnit(((isDesktop || isLaptop) && 32) || 32),
        paddingLeft: toUnit(((isDesktop || isLaptop) && 32) || 32),
        borderRadius: toUnit((isDesktop && 32) || 16),
        backgroundColor: colorVariant?.color || '#FFFFFF'
      }}>
      {costFormatHeading && (
        <H3
          variant='beta'
          styles={{
            color:
              colorVariant?.color === '#FFFFFF'
                ? 'var(--omega)'
                : 'var(--alpha)',
            fontSize: `${toUnit(
              ((isDesktop || isLaptop) && 32) || 24
            )} !important`,
            marginBottom: costFormatSubHeading ? toUnit(9) : toUnit(16)
          }}>
          {costFormatHeading}
        </H3>
      )}
      {costFormatSubHeading && (
        <H3
          variant='beta'
          styles={{
            color:
              colorVariant?.color === '#FFFFFF'
                ? 'var(--omega)'
                : 'var(--alpha)',
            fontSize: `${toUnit(
              ((isMobile || isTablet) && 14) || (isLaptop && 16) || 20
            )} !important`
          }}>
          {costFormatSubHeading}
        </H3>
      )}
      <DiscountPsihologiyaIsHigherEducation
        colorVariant={colorVariant}
        discount={discount}
        discountUntil={discountUntil}
      />
      {paymentOptions.map((paymentOption, idx) => (
        <div
          key={`paymentOptions__paymentOption--${idx + 1}`}
          style={{
            position: 'relative',
            display: (isCourse && 'flex') || undefined,
            flexDirection: (isCourse && 'column') || undefined,

            marginTop: isDesktop || isLaptop || isTablet ? 'auto' : toUnit(0),
            ...((idx !== 0 && {
              marginTop: toUnit(16),
              paddingTop: toUnit(19),
              borderTop: `1px solid ${
                colorVariant?.color === '#FFFFFF'
                  ? 'var(--omega)'
                  : 'var(--alpha)'
              }`
            }) ||
              {})
          }}
          itemProp='offers'
          itemScope
          itemType='https://schema.org/Offer'>
          <meta itemProp='price' content={String(paymentOption.price)} />
          <meta itemProp='priceCurrency' content='RUB' />
          <link itemProp='availability' href='http://schema.org/InStock' />
          <Layout.Flex
            cols={1}
            styles={{
              alignItems: 'center',
              order: (isCourse && 2) || undefined
            }}
            gap={toUnit(16)}>
            <Layout.FlexItem
              styles={{
                order: ((isDesktop || isLaptop || isTablet) && 1) || 2,
                display: 'flex'
              }}>
              <P
                variant='mu'
                styles={{
                  color:
                    colorVariant?.color === '#FFFFFF'
                      ? 'var(--omega)'
                      : 'var(--alpha)',
                  fontSize: toUnit(((isDesktop || isLaptop) && 32) || 24),
                  marginRight: toUnit(((isDesktop || isLaptop) && 16) || 16)
                }}>
                {spacesToNonBrakingSpaces(
                  toNumberWithSpaces(paymentOption.price)
                )}
                {NON_BRAKING_SPACE}
                <Span
                  styles={{
                    display: 'inline-block',
                    fontSize: toUnit((isDesktop && 32) || 24)
                  }}>
                  {paymentOption.currencySymbol}
                </Span>
              </P>
              <P
                variant='nu'
                styles={{
                  textDecoration: 'line-through',
                  textAlign:
                    ((isDesktop || isLaptop || isTablet) && 'right') ||
                    undefined,
                  color:
                    colorVariant?.color === '#FFFFFF'
                      ? 'var(--eta)'
                      : 'var(--alpha)',
                  fontSize: toUnit(((isDesktop || isLaptop) && 20) || 16),
                  display: 'flex',
                  alignItems: 'end',
                  paddingBottom: toUnit(2)
                }}>
                {spacesToNonBrakingSpaces(
                  `${toNumberWithSpaces(paymentOption.regularPrice)} ${
                    paymentOption.currencySymbol
                  }`
                )}
              </P>
            </Layout.FlexItem>
          </Layout.Flex>
          <P
            variant='alpha'
            styles={{
              marginTop: toUnit((isDesktop && 10) || (isLaptop && 5) || 8),
              marginBottom: toUnit((isCourse && 16) || 0),
              maxWidth: (isDesktop && '23ch') || undefined,
              order: (isCourse && 1) || undefined,
              color:
                colorVariant?.color === '#FFFFFF'
                  ? 'var(--omega)'
                  : 'var(--alpha)',
              fontSize: toUnit(((isDesktop || isLaptop) && 16) || 14),
              fontWeight: 500
            }}>
            {insertWhiteSpacesAfterPrepositions(paymentOption.name)} <br />
            {paymentOption.name === 'Оплата за один семестр' && 'обучения'}
          </P>
        </div>
      ))}
      <Shared.LeadPopup
        cost={price}
        buttonProps={{
          children: btnValue,
          colorsVariant: colorVariant?.color === '#FFFFFF' ? 'delta' : 'alpha',
          styles: {
            marginTop: toUnit((isLaptop && 40) || 24)
          }
        }}
      />
    </Div>
  )
}

export default PriceCardPsihologiyaIsHigherEducation
