import { StyledJSX } from 'ui'
import { toUnit } from '@/fractals/utils'
import { FiltersIcon } from '../../atoms'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const FiltersButton = props => (
  <StyledJSX.Button
    styles={{
      display: 'flex',
      alignItems: 'center',
      gap: toUnit(9.5),
      color: '#000',
      cursor: 'pointer'
    }}
    {...props}>
    {` Фильтры `}
    <FiltersIcon color='#000' />
  </StyledJSX.Button>
)
