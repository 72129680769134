/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as React from 'react'
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as types from 'styled-components/cssprop'

export * from './Button'

export type {
  TObjKey,
  TColor,
  TTimestamp,
  FunctionPropTypes,
  TGenericPropAttributes,
  TTuple,
  TFlatten,
  TValueOf,
  TStyleProp,
  TStyledElementProps,
  TStyledElementStyledProps,
  // TVariantsKeys,
  TVariantProp,
  // TVariant,
  // RecordDefaultVariantsTypes
  ArrayElementType
} from './fractals'
export {
  KEYS,
  BASE64PIXELS,
  NON_BRAKING_SPACE,
  mdToJsx,
  insertWhiteSpacesAfterPrepositions,
  toUnit,
  fetcher,
  isArray,
  removeDuplicatesByKey,
  spacesToNonBrakingSpaces,
  calculateRuCase,
  repeatJSX,
  calculateClosestEnrollment,
  calculateStudyDuration,
  insertLineBrakeBetweenWords,
  getInputId,
  toNumberWithSpaces,
  slugify,
  getImageHeight
} from './fractals'
export { NextWrappers, StyledJSX, Layout, Modal } from './modules'
