import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetDiplomasDataProps, TResDiplomas } from '../types'

export const fetchDiplomas = async ({ context }: TGetDiplomasDataProps) => {
  try {
    const query = qs.stringify(
      {
        fields: ['name', 'rank'],
        populate: {
          imageDefault: {
            fields: ['url', 'width', 'height', 'alternativeText']
          },
          imageTemplate: {
            fields: ['url', 'width', 'height', 'alternativeText']
          },
          categories: {
            fields: ['slug']
          },
          programs: {
            fields: ['slug']
          }
        },
        sort: ['rank:asc'],
        locale: context.locale
      },
      {
        encodeValuesOnly: true // prettify URL
      }
    )

    const diplomas = await fetcher<TResDiplomas>(
      `${PATHS.back.root.index}${PATHS.back.api.diplomas.index}?${query}`
    )

    return diplomas?.data || null
  } catch (e) {
    console.log('%cfetchDiplomas.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default fetchDiplomas
