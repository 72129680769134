import { PATHS, THEME, TRANSLATIONS } from 'fractals'
import BeforeLoadStyles from './BeforeLoadStyles'

const Meta = () => {
  return (
    <>
      <BeforeLoadStyles />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />

      <meta name='mobile-web-app-capable' content='yes' />

      <meta
        name='msapplication-TileColor'
        content={THEME.colors.light.beta.rgb().string()}
      />
      <meta name='msapplication-tap-highlight' content='no' />
      <meta
        name='theme-color'
        content={THEME.colors.light.beta.rgb().string()}
      />

      <link rel='icon' href='/favicon.ico' />
      <link rel='shortcut icon' href='/favicon.ico' />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/assets/imgs/icons/mitm-logo-180-180.png'
      />

      {/* <link rel='manifest' href='/manifest.json' /> */}

      <meta property='og:type' content='website' />
      <meta property='og:title' content={TRANSLATIONS.companyName.ru} />
      <meta
        property='og:description'
        content='Самый инновационный ВУЗ страны - MITM.institute предлагает дистанционные онлайн-курсы по востребованным направлениям и специальностям. Удобный формат обучения со скидкой 30% Государственная аккредитация! Актуальные подходы к образованию вместе с экспертами-практиками'
      />
      <meta property='og:site_name' content={TRANSLATIONS.companyName.ru} />
      <meta property='og:url' content={PATHS.front.root.index} />
      <meta
        property='og:image'
        content={`${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`}
      />
    </>
  )
}

export default Meta
