/* eslint-disable react/no-danger */
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { useEffect, useRef, useState } from 'react'
import Router, { useRouter } from 'next/router'
import Head from 'next/head'
import Script from 'next/script'
import { ThemeProvider } from 'styled-components'
import { DefaultSeo, LogoJsonLd, OrganizationJsonLd } from 'next-seo'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { NextWrappers } from 'ui'
import {
  THEME,
  ENV,
  StyledGlobal,
  LOCALES,
  PATHS,
  useTranslations,
  TRANSLATIONS,
  handleLocale,
  handleReferer,
  handleUtms,
  useHandleUtms,
  handleCookiesExpiration,
  useAt
} from 'fractals'
import { getCookies } from 'cookies-next'
import { Shared } from 'modules'
import { YandexEcommerceDataProvider } from '@/modules/shared/contexts/YandexEcommerceContext'
import FacebookPixel from '@/modules/shared/widgets/FacebookPixel'
import SEO from '../next-seo.config'

declare global {
  interface Window {
    flocktory?: {
      reInit: () => void
    }
  }
}

export const App = ({ Component, pageProps, router }: AppProps) => {
  const [companyName, address] = useTranslations(
    TRANSLATIONS.companyName,
    TRANSLATIONS.address
  )

  const route = useRouter()

  const { isProgramPage, currentProgramSlug } = useAt()

  //
  // const [isBrowser, setIsBrowser] = useState(false)

  // useEffect(() => {
  //   setIsBrowser(prevState => !prevState)
  // }, [])

  const [currentTheme, setCurrentTheme] =
    useState<keyof (typeof THEME)['colors']>('light')

  useEffect(() => {
    handleCookiesExpiration({ route })
    handleUtms({ router })
    handleReferer()
    handleLocale({ router })

    NProgress.configure({
      showSpinner: false
    })

    const start = () => {
      NProgress.start()
    }
    const end = (url: URL) => {
      NProgress.done()
      // Flocktory integration for SSR/SPA sites
      if (typeof window?.flocktory?.reInit === 'function') {
        window.flocktory.reInit()
      }
      // pageview({ url })
    }
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)
    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  useHandleUtms()

  return (
    <>
      <Head>
        <script
          async
          id='roistat-counter'
          dangerouslySetInnerHTML={{
            __html: `(function(w, d, s, h, id) {
                w.roistatProjectId = id; w.roistatHost = h;
                var p = d.location.protocol == "https:" ? "https://" : "http://";
                var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
                var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
                })(window, document, 'script', 'cloud.roistat.com', '46751d07945ec38697681458052a865d');`
          }}
        />
        <script
          type='text/javascript'
          src='https://api.flocktory.com/v2/loader.js?site_id=5519'
          async
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w, d, s, o){
                var j = d.createElement(s); j.async = true; j.src = '//script.marquiz.ru/v2.js';j.onload = function() {
                  if (document.readyState !== 'loading') Marquiz.init(o);
                  else document.addEventListener("DOMContentLoaded", function() {
                    Marquiz.init(o);
                  });
                };
                d.head.insertBefore(j, d.head.firstElementChild);
              })(window, document, 'script', {
                  host: '//quiz.marquiz.ru',
                  region: 'eu',
                  id: '66b122b4007cc600260c3e08',
                  autoOpen: false,
                  autoOpenFreq: 'once',
                  openOnExit: false,
                  disableOnMobile: false
                }
              );
            `
          }}
        />
        <meta name='application-name' content={companyName} />
        <meta name='apple-mobile-web-app-title' content={companyName} />
      </Head>
      <ThemeProvider
        theme={{
          colors: THEME.colors[currentTheme],
          currentTheme
        }}>
        <YandexEcommerceDataProvider>
          <Shared.MenuBottomMobile />
          {/* <Shared.HalloweenBanner /> */}
          <Shared.BlackFridayBanner />
          <DefaultSeo {...SEO} />
          <LogoJsonLd
            logo={`${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`}
            url={PATHS.front.root.index}
          />
          <OrganizationJsonLd
            organizationType='EducationalOrganization'
            id={PATHS.front.root.index}
            logo={`${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`}
            legalName={companyName}
            name={companyName}
            // address={{
            //   streetAddress: `${company.address.street.typeShort} ${company.address.street.name} ${company.address.street.door}, ${company.address.street.room}`,
            //   addressLocality: company.address.city,
            //   postalCode: company.address.zip,
            //   addressCountry: company.address.countryCode
            // }}
            // contactPoints={[
            //   {
            //     telephone: company.phoneNumber.val,
            //     contactType: company.phoneNumber.contactType,
            //     areaServed: company.phoneNumber.areaServed,
            //     availableLanguage: company.languages
            //   }
            // ]}
            sameAs={[PATHS.front.root.index]}
            url={PATHS.front.root.index}
          />
          <StyledGlobal />

          {/* {ENV.dev && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0
            }}>
            <NextWrappers.Link
              href={PATHS.front.home.index}
              locale={LOCALES.ru}>
              {LOCALES.ru}
            </NextWrappers.Link>
            <br />
            <NextWrappers.Link
              href={PATHS.front.home.index}
              locale={LOCALES.en}>
              {LOCALES.en}
            </NextWrappers.Link>
            <br />
            <NextWrappers.Link
              href={PATHS.front.home.index}
              locale={LOCALES.uz}>
              {LOCALES.uz}
            </NextWrappers.Link>
            <br />
            <NextWrappers.Link
              href={PATHS.front.home.index}
              locale={LOCALES.kk}>
              {LOCALES.kk}
            </NextWrappers.Link>
          </div>
        )} */}

          <Component {...pageProps} />
        </YandexEcommerceDataProvider>
      </ThemeProvider>
      <>
        <Script
          async
          defer
          id='edpartners-pixel'
          dangerouslySetInnerHTML={{
            __html: `function sclClickPixelFn() {
            const url = new URL(document.location.href).searchParams;
            if (url.get('a')) {
                const availableParams = ['aff_click_id', 'sub_id1', 'sub_id2', 'sub_id3', 'sub_id4', 'sub_id5', 'aff_param1', 'aff_param2', 'aff_param3', 'aff_param4', 'aff_param5', 'idfa', 'gaid'];
                const t = new URL('https://edpartners.scaletrk.com/click');
                const r = t.searchParams;
                console.log(url);
                r.append('a', url.get('a'));
                r.append('o', url.get('o'));
                r.append('return', 'click_id');
                if (availableParams?.length > 0) {
                    availableParams.forEach((key) => {
                        const value = url.get(key);
                        if (value) {
                            r.append(key, value);
                        }
                    });
                }
                fetch(t.href).then((e) => e.json()).then((e) => {
                    const c = e.click_id;
                    if (c) {
                        const expiration = 864e5 * 90;
                        const o = new Date(Date.now() + expiration);
                        document.cookie = 'cl_uid=' + c + ';expires=' + o;
                        document.cookie = 'utm_source=' + url.get('utm_source') + ';expires=' + o;
                    }
                });
            }
        }

        sclClickPixelFn();`
          }}
        />
        <Script
          async
          defer
          id={ENV.googleTagManagerId}
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${ENV.googleTagManagerId}');`
          }}
        />
        <script
          async
          defer
          src={`https://www.googletagmanager.com/gtag/js?id=${ENV.googleTagManagerIdV4}`}
        />
        <Script
          async
          defer
          id='gtag'
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${ENV.googleTagManagerIdV4}');
            gtag('get', '${ENV.googleTagManagerIdV4}', 'client_id', function(clientId) {
              document.cookie = "gclUid=" + clientId;
            });`
          }}
        />
        <FacebookPixel />
        {isProgramPage && currentProgramSlug ? (
          <Script
            id='xcntmyAsync'
            type='text/javascript'
            async
            defer
            dangerouslySetInnerHTML={{
              __html: `
                var xcnt_product_id = '${currentProgramSlug}';
                (function(d){
                  var xscr = d.createElement( 'script' );
                  xscr.async = 1;
                  xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
                  var x = d.getElementById( 'xcntmyAsync' );
                  x.parentNode.insertBefore( xscr, x );
                })(document);
              `
            }}
          />
        ) : (
          <Script
            id='xcntmyAsync'
            type='text/javascript'
            async
            defer
            dangerouslySetInnerHTML={{
              __html: `
              (function(d){
                var xscr = d.createElement('script');
                xscr.async = 1;
                xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
                var x = d.getElementById('xcntmyAsync');
                x.parentNode.insertBefore(xscr, x);
              })(document);
              `
            }}
          />
        )}
      </>

      <Script
        async
        defer
        id='top-mail-ru-counter'
        dangerouslySetInnerHTML={{
          __html: `var _tmr = window._tmr || (window._tmr = []);
                    _tmr.push({id: "3374995", type: "pageView", start: (new Date()).getTime()});
                    (function (d, w, id) {
                      if (d.getElementById(id)) return;
                      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                      ts.src = "https://top-fwz1.mail.ru/js/code.js";
                      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                    })(document, window, "tmr-code");`
        }}
      />
      <Script
        async
        defer
        src='https://dashboard.fstrk.io/widget3/894c50c1-29a0-4b3b-97d9-1cefd4c8974d.js'
        id='chat-fstrk'
      />
      <noscript>
        <div>
          <img
            src='https://top-fwz1.mail.ru/counter?id=3374995;js=na'
            style={{
              position: 'absolute',
              left: '-9999px'
            }}
            alt='Top.Mail.Ru'
          />
        </div>
      </noscript>
    </>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log('%c_app.tsx line:233 metric', 'color: #007acc;', metric)
  switch (metric.name) {
    case 'FCP':
      console.log(
        '%c_app.tsx line:235 FCP value',
        'color: #007acc;',
        metric.value
      )
      break
    case 'LCP':
      console.log(
        '%c_app.tsx line:238 LCP value',
        'color: #007acc;',
        metric?.attribution?.lcpEntry
      )
      break
    case 'CLS':
      console.log(
        '%c_app.tsx line:241 CLS attributio',
        'color: #007acc;',
        metric
      )
      break
    case 'FID':
      console.log(
        '%c_app.tsx line:244 FID value',
        'color: #007acc;',
        metric.value
      )
      break
    case 'TTFB':
      // handle TTFB results
      break
    case 'INP':
      // handle INP results (note: INP is still an experimental metric)
      break
    default:
      break
  }
}

export default App
