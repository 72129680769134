import { spacesToNonBrakingSpaces } from './spacesToNonBrakingSpaces'

export const toNumberWithSpaces = (
  num: number,
  { isNonBraking }: { isNonBraking: boolean } = { isNonBraking: true }
) => {
  const numWithSpaces = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (isNonBraking) return spacesToNonBrakingSpaces(numWithSpaces)

  return numWithSpaces
}

export default toNumberWithSpaces
