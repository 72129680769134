import * as React from 'react'
import { TLiProps } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const Li = ({ ...props }: TLiProps) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{
        // DEFAULT STYLES HERE
        ...props.styles
      }}
    />
  )
}

export default Li
