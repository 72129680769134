import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TResBanner } from '../types'

export const fetchBanner = async () => {
  const query = qs.stringify({
    fields: [
      'allCategoriesDescription',
      'bachelorDescription',
      'magisterDescription',
      'coursesDescription',
      'color'
    ],
    populate: {
      allCategoriesImage: {
        fields: ['url', 'width', 'height', 'alternativeText']
      }
    }
  })

  const res = await fetcher<TResBanner>(
    `${PATHS.back.root.index}${PATHS.back.api.banner.index}?${query}`
  )

  return res?.data || null
}

export default fetchBanner
