import * as React from 'react'
import { TH6Props } from './types'
import { ROOT_ELEMENT } from './constants'
import { StyledElement } from '../StyledElement'

const H6 = ({ ...props }: TH6Props) => {
  return (
    <StyledElement
      as={ROOT_ELEMENT}
      {...props}
      styles={{ /* DEFAULT_STYLES */ ...props.styles }}
    />
  )
}

export default H6
