export const authorsQuery = {
  fields: ['name', 'job', 'richtext'],
  populate: {
    image: {
      fields: ['url', 'width', 'height', 'alternativeText']
    }
  }
}

export const videoQuery = {
  fields: ['url'],
  populate: {
    light: {
      fields: ['url', 'width', 'height', 'alternativeText']
    }
  }
}

export const reviewsPopulateVariants: { [key: string]: any } = {
  bakalavriat: {
    authorsBakalavriat: authorsQuery
  },
  magistratura: {
    authorsMagistratura: authorsQuery
  },
  kursy: {
    authorsKursy: authorsQuery
  }
}

export const videoPopulateVariants: { [key: string]: any } = {
  bakalavriat: {
    videoBakalavriat: videoQuery
  },
  magistratura: {
    videoMagistratura: videoQuery
  },
  kursy: {
    videoKursy: videoQuery
  }
}
