import * as React from 'react'
import { IconContainer, toUnit, media } from 'fractals'
import { useTheme } from 'styled-components'
import Color from 'color'
import { TIconCheckProps } from './types'
import { STYLES_VARIANT_KEYS } from './constants'

const IconPlay = ({
  styleVariant = 'default',
  colorsVariant,
  ...props
}: TIconCheckProps) => {
  const { colors } = useTheme()

  const styles = {
    ...media('width', toUnit(25), {
      desktop: toUnit(48)
    }),
    ...props?.styles
  } as const

  const variants = {
    [STYLES_VARIANT_KEYS.default]: (
      <svg viewBox='0 0 48 152' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M46.6614 79.3652C47.651 77.2309 47.651 74.7691 46.6614 72.6348L15.2578 4.9065C11.6526 -2.86877 0 -0.298675 0 8.27174V143.728C0 152.299 11.6526 154.869 15.2578 147.093L46.6614 79.3652Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
      </svg>
    ),
    [STYLES_VARIANT_KEYS.ytp]: (
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='Group 1312'>
          <circle id='Ellipse 558' cx='24' cy='24' r='24' fill='white' />
          <path
            id='Vector 146'
            d='M25.4626 16.975L30.0092 20.0062C32.0123 21.3415 33.0138 22.0092 33.3628 22.8508C33.668 23.5866 33.668 24.4134 33.3628 25.1492C33.0138 25.9908 32.0123 26.6585 30.0092 27.9938L25.4626 31.025L25.4625 31.025C23.0384 32.6411 21.8263 33.4491 20.8212 33.3891C19.9456 33.3368 19.1367 32.9039 18.6075 32.2044C18 31.4014 18 29.9446 18 27.0311V20.9689C18 18.0554 18 16.5986 18.6075 15.7956C19.1367 15.0961 19.9456 14.6632 20.8212 14.6109C21.8263 14.5509 23.0384 15.3589 25.4626 16.975Z'
            fill='#3A52EE'
          />
        </g>
      </svg>
    )
  }

  return (
    <IconContainer {...props} styles={styles}>
      {variants[styleVariant]}
    </IconContainer>
  )
}

export default IconPlay
