import { useRouter } from 'next/router'
import { calculateClosestEnrollment, FunctionPropTypes } from 'ui'
import {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
  useTranslations
} from 'fractals'

export const useCalculateClosestEnrollment = ({
  timestamp
}: Omit<
  FunctionPropTypes<typeof calculateClosestEnrollment>,
  'months' | 'locale'
>) => {
  const { locale } = useRouter()
  const months = useTranslations(
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER
  )

  return calculateClosestEnrollment({ timestamp, months, locale })
}

export default useCalculateClosestEnrollment
