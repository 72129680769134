import * as React from 'react'
import { StyledJSX } from 'ui'
import { toUnit, useMedia } from 'fractals'
import { TUlProps } from './types'
import { VARIANTS_KEYS } from './constants'

const Ul = ({ variant, ...props }: TUlProps) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()

  const gap = toUnit((isDesktop && 32) || (isLaptop && 24) || 16)
  const gapCard = ((isDesktop || isLaptop) && 24) || 16
  const gapDelta = ((isDesktop || isLaptop) && 24) || (isTablet && 18) || 16

  const stylesVariants = {
    [VARIANTS_KEYS.alpha]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap,
      '& > li': {
        flexBasis:
          (gap && (isDesktop || isTablet) && `calc((100% - ${gap} * 1) / 2)`) ||
          '100%'
      }
    },
    [VARIANTS_KEYS.beta]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap,
      '& > li': {
        flexBasis:
          (gap && isDesktop && `calc((100% - ${gap} * 2) / 3)`) ||
          (gap && isLaptop && `calc((100% - ${gap} * 1) / 2)`) ||
          '100%'
      }
    },
    [VARIANTS_KEYS.gamma]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap,
      '& > li': {
        flexBasis: '100%'
      }
    },
    [VARIANTS_KEYS.delta]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: toUnit(gapDelta),
      '& > li': {
        flexBasis:
          (gapDelta &&
            isLaptop &&
            `calc((100% - ${toUnit(gapDelta)} * 1) / 2)`) ||
          (gapDelta &&
            isTablet &&
            `calc((100% - ${toUnit(gapDelta)} * 1) / 2)`) ||
          '100%'
      }
    },
    [VARIANTS_KEYS.eta]: {
      display: 'inline-flex',
      // minWidth: isMobile ? 'auto' : '77.6rem',
      minWidth: '-webkit-fill-available'
    },
    [VARIANTS_KEYS.card]: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: toUnit(gapCard * 2),
      marginTop: toUnit((((isDesktop || isLaptop) && 32) || 24) + gapCard),
      marginBottom: toUnit(((isDesktop || isLaptop) && 32) || 24),
      '& > li': {
        position: 'relative',
        flexBasis: '100%',
        ':nth-last-child(1)': {
          '&::before': {
            content: '""',
            position: 'absolute',
            bottom: toUnit(-gapCard),
            right: 0,
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: toUnit(1),
            maxHeight: toUnit(1),
            backgroundColor: 'var(--phi)'
          }
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: toUnit(-gapCard),
          right: 0,
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: toUnit(1),
          maxHeight: toUnit(1),
          backgroundColor: 'var(--phi)'
        }
      }
    }
  } as const

  const styles = {
    marginTop: 0,
    marginBottom: 0,
    ...stylesVariants[variant],
    ...props.styles
  } as const

  return <StyledJSX.Ul {...props} styles={styles} />
}

export default Ul
