import { TGetStudyIsDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getStudyIsData = async ({ context }: TGetStudyIsDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getStudyIsData
