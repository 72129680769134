import { LOCALES, PATHS, toUnit, useAt, useTranslations } from 'fractals'
import { useRouter } from 'next/router'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { TCategoryButtonsProps } from './types'
import { ALL_PROGRAMS } from './constants'

export const CategorySelect = ({
  categories,
  onClick,
  isExpanded
}: TCategoryButtonsProps) => {
  const { currentCategorySlug, currentFacultySlug } = useAt()
  const { locale, push } = useRouter()
  const [allPrograms] = useTranslations(ALL_PROGRAMS)

  const allProgramsSlug = `${
    PATHS.front.general.programs[(locale as keyof typeof LOCALES) || 'ru']
  }`

  const initialCategory = currentCategorySlug ?? allProgramsSlug

  const [category, setCategory] = useState(initialCategory)

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value)
    push(
      `${
        event.target.value === allProgramsSlug
          ? event.target.value
          : `/${event.target.value}`
      }${
        currentFacultySlug
          ? `--${
              PATHS.front.general.faculty[
                (locale as keyof typeof LOCALES) || 'ru'
              ]
            }--${currentFacultySlug}`
          : ''
      }`
    )
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClick = () => {
    setIsOpen(true)
    onClick()
  }

  const iconComponent = (isOpened: boolean) => {
    return isOpened ? (
      <ExpandLessIcon
        style={{
          position: 'absolute',
          right: '8px',
          userSelect: 'none',
          pointerEvents: 'none'
        }}
      />
    ) : (
      <ExpandMoreIcon
        sx={{
          position: 'absolute',
          right: isExpanded ? '8px' : '13.5px',
          userSelect: 'none',
          pointerEvents: 'none',
          '& svg': {
            fill: isExpanded ? '#fff' : '#3A52EE'
          }
        }}
      />
    )
  }

  const menuProps = {
    sx: {
      '& .MuiMenu-list': {
        padding: '8px'
      },
      '& .MuiMenu-paper': {
        background: '#F0F0F0'
      },
      '& .MuiMenuItem-root.Mui-selected': {
        background: '#fff !important'
      },
      '& .MuiMenuItem-root': {
        borderRadius: '8px',
        fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`
      }
    }
  }

  if (!categories?.categories || categories?.categories?.length === 0)
    return null

  return (
    <Select
      value={category}
      onChange={handleChange}
      displayEmpty
      IconComponent={() => iconComponent(isOpen)}
      onOpen={handleClick}
      onClose={() => setIsOpen(false)}
      // TODO fix types
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      MenuProps={menuProps}
      renderValue={selected => {
        if (!isExpanded) return ''
        if (selected === allProgramsSlug) {
          return <span>Уровень образования</span>
        }

        return (
          categories?.categories &&
          categories.categories.find(({ slug }) => slug === selected)?.name
        )
      }}
      sx={{
        height: '51px',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: toUnit(8),
        bgcolor: isExpanded ? 'var(--beta)' : '#F0F0F0',
        color: isExpanded ? 'var(--alpha)' : '#3A52EE',
        border: 'none',
        position: 'relative',
        maxWidth: isExpanded ? '100%' : '51px',
        fontFamily: `'__golosTextVF_099e87', '__golosTextVF_Fallback_099e87', sans-serif`,
        '& .MuiSelect-root': {
          lineHeight: '120%'
        }
      }}>
      {[
        {
          name: allPrograms,
          slug: allProgramsSlug,
          rank: 0,
          programs: [{ faculty: { slug: '' } }]
        },
        ...categories.categories
      ].map((categoryMenuItem, idx) => {
        return (
          <MenuItem
            key={`CategorySelects__${categoryMenuItem.name}--${idx + 1}`}
            value={categoryMenuItem.slug ?? ''}
            selected={
              currentCategorySlug === categoryMenuItem?.slug ||
              !currentCategorySlug
            }
            sx={{ width: '100%' }}>
            {`${categoryMenuItem.name}`}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default CategorySelect
