import React from 'react'
import { multiMedia } from 'fractals'
import { Div } from '@/fractals/components'

export const TextItem = ({ content }: { content: string }) => {
  return (
    <Div
      styles={{
        '& b': {
          fontWeight: 500,
          ...multiMedia({
            fontSize: '20px',
            phone: { fontSize: '18px' }
          })
        },
        ...multiMedia({
          fontSize: '16px',
          desktop: { fontSize: '18px' }
        })
      }}
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  )
}

export default TextItem
