import axios from 'axios'

// TODO: add extends to T
export const fetcher = async <T>(url: string) => {
  try {
    const res = await axios.get<T>(url)
    const data = await res.data
    return data
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    return null
  }
}

export default fetcher
