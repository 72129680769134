import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconForwardProps } from './types'

const IconForward = ({ ...props }: TIconForwardProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      // transform:
      //   (direction === 'upRight' && 'rotate(45deg)') ||
      //   (direction === 'right' && 'rotate(90deg)') ||
      //   (direction === 'downRight' && 'rotate(135deg)') ||
      //   (direction === 'down' && 'rotate(180deg)') ||
      //   (direction === 'downLeft' && 'rotate(225deg)') ||
      //   (direction === 'left' && 'rotate(270deg)') ||
      //   (direction === 'upLeft' && 'rotate(315deg)') ||
      // * default direction is up
    },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15 17L20 12L15 7'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4 18V16C4 14.9391 4.42143 13.9217 5.17157 13.1716C5.92172 12.4214 6.93913 12 8 12H20'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconContainer>
  )
}

export default IconForward
