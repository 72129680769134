import { LOCALES } from '../../../constants'

export const TYPE_KEYS = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  promocode: 'promocode'
} as const

export const PLACEHOLDER_KEYS = {
  name: 'name',
  phoneNumber: 'phoneNumber',
  email: 'email',
  promocode: 'promocode'
} as const

export const YOUR_NAME_PLACEHOLDER = {
  [LOCALES.en]: 'Type in your name',
  [LOCALES.ru]: 'Введите ваше имя',
  [LOCALES.uz]: 'Sizning ismingizni kiriting',
  [LOCALES.kk]: 'Сіздің атыңызды енгізіңіз'
} as const

export const YOUR_PHONE_NUMBER_PLACEHOLDER = {
  [LOCALES.en]: '+1-999-999-9999',
  [LOCALES.ru]: '+7-999-999-9999',
  [LOCALES.uz]: '+998-999-999-9999',
  [LOCALES.kk]: '+7-999-999-9999'
} as const

export const YOUR_EMAIL_PLACEHOLDER = {
  [LOCALES.en]: 'Type in your email',
  [LOCALES.ru]: 'Введите вашу почту',
  [LOCALES.uz]: 'Sizning emailingizni kiriting',
  [LOCALES.kk]: 'Сіздің emailіңізді енгізіңіз'
} as const

export const YOUR_PROMOCODE_PLACEHOLDER = {
  [LOCALES.en]: 'Type in your promocode',
  [LOCALES.ru]: 'Введите промокод',
  [LOCALES.uz]: 'Sizning promocodeingizni kiriting',
  [LOCALES.kk]: 'Сіздің promocodeіңізді енгізіңіз'
} as const
