import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: 'About training',
  [LOCALES.ru]: 'Об обучении',
  [LOCALES.uz]: 'Trening haqida',
  [LOCALES.kk]: 'Оқыту туралы'
}

export const CARDS = [
  {
    title: {
      [LOCALES.en]: 'Only relevant content',
      [LOCALES.ru]: 'Только актуальный контент',
      [LOCALES.uz]: 'Faqat tegishli tarkib',
      [LOCALES.kk]: 'Тек ағымдағы мазмұн'
    }
  },
  {
    title: {
      [LOCALES.en]:
        'We regularly monitor changes in the market and adapt programs to new realities',
      [LOCALES.ru]:
        'Мы регулярно отслеживаем изменения на рынке и адаптируем программы под новые реалии',
      [LOCALES.uz]:
        "Biz muntazam ravishda o'zgarishlarni kuzatib boramiz bozorda va dasturlarni moslashtirish yangi haqiqatlar ostida",
      [LOCALES.kk]:
        'Біз өзгерістерді үнемі қадағалап отырамыз нарықта және бағдарламаларды бейімдеу жаңа шындықтар үшін'
    }
  },
  {
    title: {
      [LOCALES.en]:
        'We conduct internal research and, based on them, adapt programs to the requirements of the employers',
      [LOCALES.ru]:
        'Мы проводим внутренние исследования и на их основе адаптируем программы под требования работодателей',
      [LOCALES.uz]:
        'Biz ichki tadqiqotlar olib boramiz va ularning asosida biz dasturlarni moslashtiramiz ish beruvchilarning talablariga muvofiq',
      [LOCALES.kk]:
        'Біз ішкі зерттеулер жүргіземіз және олардың негізінде біз бағдарламаларды бейімдейміз жұмыс берушілердің талаптарына сәйкес'
    }
  },
  {
    title: {
      [LOCALES.en]:
        'The program is designed so that it is comfortable to take place in a remote format, without interrupting the main activity',
      [LOCALES.ru]:
        'Программа разработана так, чтобы ее было комфортно проходить в удаленном формате, без отрыва от основной деятельности ',
      [LOCALES.uz]:
        "Dastur masofaviy formatda qulay bo'lishi uchun ishlab chiqilgan, asosiy faoliyatdan ajralmasdan",
      [LOCALES.kk]:
        'Бағдарлама қашықтағы форматта ыңғайлы өтуге арналған, негізгі қызметтен қол үзбей'
    }
  }
]
