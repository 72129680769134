import {
  useTranslations,
  Header as HeaderContainer,
  Container,
  toUnit,
  Link,
  Button,
  IconHamburger,
  IconPlus,
  useMedia
} from 'fractals'
import { useState } from 'react'
import { Layout, slugify, spacesToNonBrakingSpaces } from 'ui'
import Color from 'color'
import { ABOUT, PROGRAM, COST, DIPLOMA, THeaderProps } from './fractals'
// TODO: fix this import
// eslint-disable-next-line import/no-cycle
import {
  FOR_WHOM_HEADING,
  LEAST_PROGRAM_CONTENTS_HEADING,
  COST_HEADING
} from '../..'
import { BreadcrumbsReact } from '@/modules/shared/widgets'

const Header = ({ colorVariant, bread }: THeaderProps) => {
  const [
    about,
    program,
    cost,
    diploma,
    forWhomHeading,
    leastProgramContentsHeading,
    costHeading
  ] = useTranslations(
    ABOUT,
    PROGRAM,
    COST,
    DIPLOMA,
    FOR_WHOM_HEADING,
    LEAST_PROGRAM_CONTENTS_HEADING,
    COST_HEADING
  )

  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)

  const { isTablet, isLaptop, isDesktop } = useMedia()

  const links = [
    {
      title: about,
      href: `#${slugify(forWhomHeading)}`,
      variant: 'alpha'
    },
    {
      title: program,
      href: `#${slugify(leastProgramContentsHeading)}`,
      variant: 'alpha'
    },
    {
      title: cost,
      href: `#${slugify(costHeading)}`,
      variant: 'alpha'
    }
    // {
    //   title: cost,
    //   href: `#${IDS.cost}`,
    //   variant: 'alpha'
    // }
  ] as const

  // TODO: refactor header
  return (
    <HeaderContainer
      variant='beta'
      colorVariant={(mobileMenuIsShown && 'alpha') || 'omega'}
      styles={{
        position: 'relative',
        transition: 'background-color 200ms ease-in-out',
        backgroundColor:
          (mobileMenuIsShown &&
            colorVariant?.color &&
            Color(colorVariant?.color).rgb().string()) ||
          (mobileMenuIsShown && 'var(--beta)') ||
          undefined
      }}>
      <Layout.Flex
        cols={((isDesktop || isLaptop || isTablet) && 3) || 'auto'}
        gap={toUnit(((isDesktop || isLaptop || isTablet) && 32) || 40)}
        nowrap
        styles={{
          '& > *': {
            minWidth: undefined,
            flexGrow: 1
          }
        }}>
        {/* TODO: generalize links, so far there is copy here */}
        {(isDesktop || isLaptop || isTablet) &&
          links.map(({ title, ...props }, idx) => (
            <Layout.FlexItem
              key={`ProgramPage__Header__Link--${idx + 1}`}
              styles={{
                display: 'flex'
              }}>
              <Link
                {...props}
                styles={{
                  display: 'inline-block'
                }}>
                {spacesToNonBrakingSpaces(title)}
              </Link>
            </Layout.FlexItem>
          ))}
        {!isDesktop && !isLaptop && !isTablet && (
          <Layout.FlexItem
            styles={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Button
              colorsVariant='transparent'
              onClick={() => setMobileMenuIsShown(prevState => !prevState)}>
              {mobileMenuIsShown && (
                <IconPlus variant='cross' colorVariant='alpha' />
              )}
              {!mobileMenuIsShown && (
                <IconHamburger colorVariant={colorVariant} />
              )}
            </Button>
          </Layout.FlexItem>
        )}
      </Layout.Flex>
      {/* TODO: animate menu */}
      {!isDesktop && !isLaptop && !isTablet && mobileMenuIsShown && (
        <Layout.Flex
          cols='auto'
          gap={toUnit(16)}
          styles={{
            position: 'absolute',
            zIndex: 100,
            right: 0,
            bottom: 0,
            transform: 'translateY(100%)',
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: '100vh',
            maxHeight: '100vh',
            backgroundColor:
              (colorVariant?.color &&
                Color(colorVariant.color).rgb().string()) ||
              'var(--beta)',
            color: 'var(--alpha)'
          }}>
          <Container
            styles={{
              width: '100%'
            }}>
            <Layout.Flex
              cols='auto'
              gap={toUnit(16)}
              styles={{
                flexDirection: 'column'
              }}>
              {/* TODO: generalize links, so far there is copy here */}
              {links.map(({ title, ...props }, idx) => (
                <Layout.FlexItem
                  key={`ProgramPage__Header__Link--${idx + 1}`}
                  styles={{
                    display: 'flex'
                  }}>
                  <Link
                    {...props}
                    styles={{
                      display: 'inline-block',
                      fontSize: toUnit(16),
                      fontWeight: 500,
                      lineHeight: '120%'
                    }}
                    onClick={() => setMobileMenuIsShown(false)}>
                    {spacesToNonBrakingSpaces(title)}
                  </Link>
                </Layout.FlexItem>
              ))}
            </Layout.Flex>
          </Container>
        </Layout.Flex>
      )}
      <BreadcrumbsReact
        bread={bread}
        styles={{
          marginTop: '-8px',
          color: mobileMenuIsShown ? 'var(--alpha)' : undefined
        }}
      />
    </HeaderContainer>
  )
}

export default Header
