import { fetchPrograms } from './fetchPrograms'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchPrograms>>
}) => {
  return programsData?.map(
    item =>
      item.studySteps?.map(item2 => ({
        name: item2.name,
        description: item2.description
      })) || null
  )?.[0]
}

export default normalizeProgramsData
