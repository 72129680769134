const Calling = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.9183 5.49C14.4422 5.40206 14.005 5.70586 13.9144 6.17054C13.8238 6.63522 14.1285 7.08891 14.5916 7.17984C15.9859 7.45166 17.0624 8.53092 17.3353 9.92995V9.93095C17.413 10.3337 17.7675 10.6265 18.1759 10.6265C18.2306 10.6265 18.2854 10.6215 18.3412 10.6115C18.8043 10.5186 19.109 10.0659 19.0184 9.60018C18.6111 7.51062 17.0027 5.89672 14.9183 5.49Z'
      fill='#3A52EE'
    />
    <path
      d='M14.8558 2.00793C14.6328 1.97595 14.4087 2.04191 14.2304 2.18381C14.0472 2.32771 13.9326 2.53557 13.9078 2.76841C13.855 3.23908 14.1946 3.66479 14.6646 3.71776C17.9063 4.07951 20.4259 6.60477 20.7904 9.85654C20.8392 10.2922 21.2047 10.621 21.6409 10.621C21.6738 10.621 21.7057 10.619 21.7385 10.615C21.9666 10.59 22.1698 10.4771 22.3132 10.2972C22.4556 10.1174 22.5203 9.89351 22.4944 9.66467C22.0403 5.60746 18.9002 2.45862 14.8558 2.00793Z'
      fill='#3A52EE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5317 12.9724C15.5208 16.9604 16.4258 12.3467 18.9656 14.8848C21.4143 17.3328 22.8216 17.8232 19.7192 20.9247C19.3306 21.237 16.8616 24.9943 8.1846 16.3197C-0.493478 7.644 3.26158 5.17244 3.57397 4.78395C6.68387 1.67385 7.16586 3.08938 9.61449 5.53733C12.1544 8.0765 7.54266 8.98441 11.5317 12.9724Z'
      fill='#3A52EE'
    />
  </svg>
)

export default Calling
