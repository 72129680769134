import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import Color from 'color'
import { TIconSearchProps } from './types'

const IconSearch = ({ colorVariant, ...props }: TIconSearchProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    ...props?.styles
  } as const

  return (
    <IconContainer styles={styles} {...props}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19.53 18.469L14.836 13.775C16.03 12.344 16.75 10.505 16.75 8.5C16.75 3.951 13.049 0.25 8.5 0.25C3.951 0.25 0.25 3.951 0.25 8.5C0.25 13.049 3.951 16.75 8.5 16.75C10.505 16.75 12.344 16.0301 13.775 14.8361L18.469 19.53C18.615 19.676 18.807 19.75 18.999 19.75C19.191 19.75 19.383 19.677 19.529 19.53C19.823 19.237 19.823 18.762 19.53 18.469ZM1.75 8.5C1.75 4.778 4.778 1.75 8.5 1.75C12.222 1.75 15.25 4.778 15.25 8.5C15.25 12.222 12.222 15.25 8.5 15.25C4.778 15.25 1.75 12.222 1.75 8.5Z'
          fill={
            (colorVariant === 'beta' && 'var(--beta)') ||
            (colorVariant === 'alpha' && 'var(--alpha)') ||
            'var(--omega)'
          }
        />
      </svg>
    </IconContainer>
  )
}

export default IconSearch
