import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetBreadcrumbsProps, TResBreadcrumbs } from '../types'

export const fetchBreadcrumbs = async ({ context }: TGetBreadcrumbsProps) => {
  const { currentJournalSlug } = parseGeneralSlug(
    context?.params?.journal?.toString() || null
  )

  try {
    const query = qs.stringify({
      fields: ['name', 'slug'],
      filter: {
        $eq: {
          slug: currentJournalSlug
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResBreadcrumbs>(
      `${PATHS.back.root.index}${PATHS.back.api.journals.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.error(e)
  }
  return null
}

export default fetchBreadcrumbs
