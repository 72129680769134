import { fetchProgramImages } from './fetchProgramImages'

export const normalizeProgramImagesData = ({
  programImagesData
}: {
  programImagesData: Awaited<ReturnType<typeof fetchProgramImages>>
}) => {
  return (
    programImagesData?.map(
      item =>
        ({
          image:
            {
              url: item?.faculty?.image?.url || null,
              width: item?.faculty?.image?.width || null,
              height: item?.faculty?.image?.height || null,
              alternativeText: item?.faculty?.image?.alternativeText || null
            } || null,
          programSlug: item?.slug || null
        } || null)
    ) || null
  )
}

export default normalizeProgramImagesData
