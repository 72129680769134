import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetFAQDataProps, TResPrograms } from '../types'

export const fetchPrograms = async ({ context }: TGetFAQDataProps) => {
  try {
    const { currentProgramSlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    if (!currentProgramSlug) return null

    const query = qs.stringify({
      fields: ['name'],
      populate: {
        questions: {
          fields: ['name', 'answer', 'rank']
        }
      },
      filters: {
        slug: {
          $eq: currentProgramSlug
        }
      },
      locale: context.locale
    })

    const res = await fetcher<TResPrograms>(
      `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cfetchPrograms.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default fetchPrograms
