/* eslint-disable no-param-reassign */
import {
  Dispatch,
  PointerEventHandler,
  SetStateAction,
  TouchEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'

type TypeUseSwipeEffectProps = {
  setMounted: Dispatch<SetStateAction<boolean>>
  offsetThreshold?: number
}

interface TypeUseSwipeReturn {
  isSwiping: boolean
  currentSwipeOffset: {
    initialClientY: number
    currentClientY: number
    offset: number
  }
  touchScreenHandlers: {
    [key: string]: TouchEventHandler
  }
  mouseScreenHandlers: {
    [key: string]: PointerEventHandler
  }
}

const useSwipeEffect = ({
  setMounted,
  offsetThreshold = 60
}: TypeUseSwipeEffectProps) => {
  const [currentSwipeOffset, setCurrentSwipeOffset] = useState<{
    initialClientY: number
    currentClientY: number
    offset: number
  }>({ initialClientY: 0, currentClientY: 0, offset: 0 })
  const swiping = useRef<boolean>(false)
  const onTouchEnd: TouchEventHandler = useCallback(
    e => {
      if (e.changedTouches.length === 1) {
        swiping.current = false
        if (
          currentSwipeOffset.offset &&
          currentSwipeOffset.offset >= offsetThreshold
        ) {
          setMounted(false)
        }
        setCurrentSwipeOffset({
          initialClientY: 0,
          currentClientY: 0,
          offset: 0
        })
      }
    },
    [currentSwipeOffset.offset, offsetThreshold, setMounted]
  )

  const data: TypeUseSwipeReturn = useMemo(
    () => ({
      isSwiping: swiping.current,
      currentSwipeOffset,
      touchScreenHandlers: {
        onTouchStart(e) {
          if (e.changedTouches.length === 1) {
            swiping.current = true
            setCurrentSwipeOffset(prev => {
              if (e.changedTouches[0]) {
                return {
                  initialClientY: e.changedTouches[0].clientY,
                  currentClientY: e.changedTouches[0].clientY,
                  offset: 0
                }
              }
              return prev
            })
          }
        },
        onTouchMove(e) {
          if (e.changedTouches.length === 1) {
            setCurrentSwipeOffset(prev => {
              if (e.changedTouches[0] && prev.initialClientY) {
                const offset = e.changedTouches[0].clientY - prev.initialClientY
                return {
                  ...prev,
                  currentClientY: e.changedTouches[0]?.clientY,
                  offset: offset > 0 ? offset : 0
                }
              }
              return prev
            })
          }
        },
        onTouchEnd,
        onTouchCancel: onTouchEnd
      },
      mouseScreenHandlers: {
        onPointerDown(e) {
          if (e.pointerType !== 'touch') {
            setMounted(false)
          }
        }
      }
    }),
    [currentSwipeOffset, swiping, setMounted, onTouchEnd]
  )
  return data
}

export default useSwipeEffect
