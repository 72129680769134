import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  Div,
  IconCheck,
  ENV,
  useMedia
} from 'fractals'
import { Layout, slugify } from 'ui'
import { useTheme } from 'styled-components'
import Color from 'color'
import { useInView } from 'react-intersection-observer'
import {
  TLeastDocumentsProps,
  TITLE,
  PASSPORT,
  DIPLOMAS,
  APPLICATION
} from './fractals'

const LeastDocuments = ({ colorVariant }: TLeastDocumentsProps) => {
  const [title, passport, diploma, application] = useTranslations(
    TITLE,
    PASSPORT,
    DIPLOMAS,
    APPLICATION
  )

  const { isTablet, isLaptop, isDesktop } = useMedia()
  const { colors } = useTheme()

  const documents = [passport, diploma, application]

  const { ref, inView, entry } = useInView({
    threshold: 0.9,
    triggerOnce: !ENV.dev
  })

  // TODO: figure out ref passing to StyledElement
  return (
    <div ref={ref}>
      <Section
        id={slugify(title)}
        spacingVariant='beta'
        colorsVariant='alpha'
        colorVariant={colorVariant}>
        <Container>
          <Layout.Flex
            cols={((isDesktop || isLaptop) && 2) || 1}
            proportion={((isDesktop || isLaptop) && '2:1') || undefined}
            gap={toUnit(((isDesktop || isLaptop) && 32) || 40)}
            styles={{
              alignItems: 'center'
            }}>
            <Layout.FlexItem>
              <H2
                variant='beta'
                styles={{
                  marginBottom:
                    ((isDesktop || isLaptop || isTablet) && 'initial') ||
                    undefined
                }}>
                {title}
              </H2>
            </Layout.FlexItem>
            <Layout.FlexItem>
              {documents.map((document, idx) => (
                <Div
                  key={`LeastDocument__${document}-${idx + 1}`}
                  styles={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: (idx !== 0 && toUnit(32)) || undefined
                  }}>
                  <Div
                    styles={{
                      aspectRatio: '1 / 1',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: toUnit(4),
                      fontSize: toUnit(18),
                      minWidth: toUnit(41),
                      maxWidth: toUnit(41),
                      minHeight: toUnit(41),
                      maxHeight: toUnit(41),
                      marginRight: toUnit(16),
                      backgroundColor:
                        (colorVariant?.color &&
                          Color(colorVariant?.color)
                            .lighten(0.1)
                            .rgb()
                            .string()) ||
                        colors.beta.lighten(0.1).rgb().string(),
                      color: 'var(--alpha)',
                      transition: 'transform 300ms ease-out',
                      transitionDelay: `${idx * 300 * 2}ms`,
                      transform: (inView && 'scale(1)') || 'scale(0.8)'
                    }}>
                    <IconCheck
                      colorsVariant='alpha'
                      styles={{
                        ...((idx !== 0 && {
                          transition: 'opacity 300ms ease-in-out',
                          transitionDelay: `${idx * 300 * 2}ms}`,
                          opacity: (inView && 1) || 0
                        }) ||
                          {})
                      }}
                    />
                  </Div>
                  <P variant='alpha'>{document}</P>
                </Div>
              ))}
            </Layout.FlexItem>
          </Layout.Flex>
        </Container>
      </Section>
    </div>
  )
}

export default LeastDocuments
