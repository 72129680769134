// * the types are not in sync with next.config.mjs locales
import { en, ru, uz, kk } from 'ui/keys-props'
import nextConfig from '../../next.config.mjs'

// TODO: dynamically infer a type from next.config.mjs so that we don't have to manually update it

export const LOCALES = nextConfig.i18n.locales?.reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: cur
  }),
  {}
) as {
  [en]: typeof en
  [ru]: typeof ru
  [uz]: typeof uz
  [kk]: typeof kk
}

export const LOCALES_ARR = nextConfig.i18n.locales as Array<
  typeof en | typeof ru | typeof uz | typeof kk
>

// export const LOCALES = {
//   en,
//   ru,
//   uz,
//   kk
// } as const
