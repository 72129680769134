import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: 'What will you learn?',
  [LOCALES.ru]: 'Чему вы научитесь?',
  [LOCALES.uz]: 'Nima o’rganasiz?',
  [LOCALES.kk]: 'Сіз қандай білімдерді оқып отырсыз?'
}

export const ITEM_1 = {
  [LOCALES.en]:
    'Master the basics of professional diagnosis and professional development',
  [LOCALES.ru]:
    'Владеть основами профессиональной диагностики и профессионального развития',
  [LOCALES.uz]:
    'Mutaxassislikni aniqlash va mutaxassislikni rivojlantirishning asoslarini bilish',
  [LOCALES.kk]:
    'Мамандықты анықтау және мамандықты жетістірудің негіздерін білу'
}

export const ITEM_2 = {
  [LOCALES.ru]: 'Проводить качественную психодиагностику',
  [LOCALES.en]: 'Conduct a qualitative psycho-diagnosis',
  [LOCALES.uz]: 'Sifatli psixodiagnostikani amalga oshirish',
  [LOCALES.kk]: 'Сапалы психодиагностикалық жүргізу'
}

export const ITEM_3 = {
  [LOCALES.ru]:
    'Оказывать психологическую помощь личности по разрешению внутриличностных проблем',
  [LOCALES.en]:
    'Provide psychological assistance to the personality in resolving intra-personal problems',
  [LOCALES.uz]:
    'Shaxsiyning ichki shaxsiy muammolarini hal etishda shaxsiyning psixologik yordamini berish',
  [LOCALES.kk]:
    'Шахси кіші шахси мәселелерді айтуда шахси психологиялық көмек беру'
}

export const ITEM_4 = {
  [LOCALES.ru]:
    'Понимать особенности диагностики психического развития людей разного возраста',
  [LOCALES.en]:
    'Understand the features of the diagnosis of the mental development of people of different ages',
  [LOCALES.uz]:
    'Tajribali shaxslarning psixologik rivojlanishini aniqlashning xususiyatlarini tushunish',
  [LOCALES.kk]:
    'Тәжірибелі шахслардың психологиялық жетістігін анықтаудың мүмкіндіктерін білу'
}

export const ITEM_5 = {
  [LOCALES.ru]: 'Разбираться в психологии личности',
  [LOCALES.en]: 'Understand the psychology of personality',
  [LOCALES.uz]: 'Shaxsiyning psixologiyasini tushunish',
  [LOCALES.kk]: 'Шахси психологиясын білу'
}

export const ITEM_6 = {
  [LOCALES.ru]: 'Понимать психологию труда, инженерную психологию и эргономику',
  [LOCALES.en]:
    'Understand the psychology of work, engineering psychology and ergonomics',
  [LOCALES.uz]:
    'Ish psixologiyasini, injener psixologiyasini va ergonomikani tushunish',
  [LOCALES.kk]:
    'Жұмыс психологиясын, инженерлік психологиясын және эргономикасын білу'
}
