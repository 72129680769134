const defaultConfig = {
  desktopStyles: {
    outerPaddingTop: 20,
    outerPaddingLeft: 20,
    outerPaddingRight: 20,
    outerPaddingBottom: 20,
    maxWidth: 600,
    borderRadius: '0',
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    animationDuration: 200,
    animationEaseFunction: 'cubic-bezier(0.33, 1, 0.68, 1)'
  },
  mobileStyles: {
    maxHeight: 500,
    borderRadius: '16px',
    boxShadow: 'none',
    backgroundColor: '#eee',
    animationDuration: 200,
    animationEaseFunction: 'cubic-bezier(0.33, 1, 0.68, 1)',
    swipeToggleHeight: 45,
    swipeToggleTraitColor: '#d1d1d1',
    offsetThreshold: 120
  },
  options: {
    showButtonDefultText: 'Показать',
    breakpointSize: 640,
    noSwipeModal: false,
    backgroundOverlayColor: 'rgba(0,0,0,0.7)',
    withBackgroundBlurOverlay: false,
    backgroundOverlayBlur: '2px'
  }
}

export default defaultConfig
