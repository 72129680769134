import { LOCALES } from 'fractals'

export const BTN_VALUE = {
  [LOCALES.en]: 'Submit',
  [LOCALES.ru]: 'Записаться на курс',
  [LOCALES.uz]: 'Ariza qoldiring',
  [LOCALES.kk]: 'Тапсырыс беру'
} as const

export const SBER_LOGO_ALT = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Сбербанк',
  [LOCALES.uz]: 'Sberbank',
  [LOCALES.kk]: 'Сбербанк'
} as const

export const TINKOFF_LOGO_ALT = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Тинькофф',
  [LOCALES.uz]: 'Tinkoff',
  [LOCALES.kk]: 'Тинькофф'
} as const

export const SBER_OFFER = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Образовательный кредит от Сбера',
  [LOCALES.uz]: 'Sberbank ta’lim krediti',
  [LOCALES.kk]: 'Сбербанк оқу кредиті'
} as const

export const TINKOFF_OFFER = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Рассрочка от «Тинькофф Банка»',
  [LOCALES.uz]: 'Tinkoff bank rassrochka',
  [LOCALES.kk]: '«Тинькофф Банк» рассрочка'
} as const

export const REMOTE = {
  [LOCALES.en]: 'Remote',
  [LOCALES.ru]: 'Дистанционно',
  [LOCALES.uz]: 'Uzoqdan',
  [LOCALES.kk]: 'Узқыдан'
} as const

export const STATE_DIPLOMA = {
  [LOCALES.en]: 'State diploma',
  [LOCALES.ru]: 'Государственный диплом',
  [LOCALES.uz]: 'Davlat diplomasi',
  [LOCALES.kk]: 'Дербес дипломы'
} as const

export const DEFAULT_CATEGORY_LABEL = {
  [LOCALES.en]: 'Course',
  [LOCALES.ru]: 'Курс',
  [LOCALES.uz]: 'Kurs',
  [LOCALES.kk]: 'Курс'
} as const

export const CLOSEST_ENROLLMENT_TEXT = {
  [LOCALES.en]: 'Closest enrollment',
  [LOCALES.ru]: 'Ближайшее зачисление',
  [LOCALES.uz]: 'Eng yaqin yozilish',
  [LOCALES.kk]: 'Ең жақын зачисление'
} as const

export const STUDY_DURATION = {
  [LOCALES.en]: 'Study duration',
  [LOCALES.ru]: 'Длительность обучения',
  [LOCALES.uz]: 'Ta’lim davomiyligi',
  [LOCALES.kk]: 'Оқу уақыты'
} as const
