import React, { Fragment, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Image, media, multiMedia, toUnit } from 'fractals'
import { Div } from 'ui/modules/styled-jsx/widgets'
import { Button, IconPlus, P, Span } from '@/fractals/components'
import { TRelatedCoursesProps } from './types'

export const RelatedCourses = ({
  oneArticle,
  nopb,
  category,
  programs
}: TRelatedCoursesProps) => {
  const learnMore = 'Узнать больше'
  const relatedCourses = `${category?.name ? 'Программы' : 'Курсы'} по теме`

  const router = useRouter()

  const [isRelatedCourses, toggleRelatedCourses] = useState(true)
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * 12) + 1
  )

  return (
    <>
      {!oneArticle && isRelatedCourses && (
        <Div
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            ...multiMedia({
              desktop: {
                padding: '32px',
                marginLeft: (!nopb && '32px') || '',
                width: (nopb && '90%') || '360px'
              },
              laptop: {
                padding: '32px',
                marginLeft: (!nopb && '32px') || '',
                marginRight: toUnit(16),
                width: (nopb && '90%') || '360px'
              },
              tablet: {
                padding: '32px',
                width: (nopb && '-webkit-fill-available') || '360px'
              },
              phone: {
                padding: toUnit(16),
                width: (nopb && '-webkit-fill-available') || '360px'
              }
            }),
            ...media('padding', '32px', {
              phone: toUnit(16)
            }),
            gap: '24px',
            height: nopb ? '100%' : 'fit-content',
            backgroundColor: 'var(--omega)',
            borderRadius: '16px',
            color: 'var(--alpha)'
          }}>
          {nopb ? (
            <P
              variant='alpha'
              styles={{
                display: 'initial',
                marginBottom: '8px'
              }}>
              {`Важно! Мы настоятельно рекомендуем ознакомиться с нашими ${
                category?.name ? 'программами' : 'курсами'
              },
              где тема данной статьи раскрывается более подробно:`}
            </P>
          ) : (
            <Div
              styles={{
                display: 'flex',
                flexDirection: 'row',
                width: '-webkit-fill-available',
                '& span': {
                  marginLeft: 'auto',
                  backgroundColor: 'var(--alpha)',
                  borderRadius: '50%',
                  ':hover': {
                    cursor: 'pointer'
                  }
                }
              }}>
              <Div
                styles={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px 16px',
                  background: '#3A52EE',
                  borderRadius: '8px',
                  minWidth: 'fit-content'
                }}>
                <P variant='alphaA'>{relatedCourses}</P>
              </Div>
              <IconPlus
                onClick={prev => toggleRelatedCourses(!prev)}
                colorVariant='omega'
                variant='crossMini'
              />
            </Div>
          )}
          {(programs || category) &&
            programs?.map(
              (program, idx, array) =>
                ((idx < 3 && !nopb) || (nopb && idx < 2)) && (
                  <Fragment key={`RelatedCourses-Div__${idx + 1}`}>
                    <Div
                      styles={{
                        color: 'var(--alpha)',
                        display: 'flex',
                        ...multiMedia({
                          desktop: { flexWrap: 'nowrap', flexDirection: 'row' },
                          laptop: { flexWrap: 'nowrap', flexDirection: 'row' },
                          tablet: { flexWrap: 'nowrap', flexDirection: 'row' },
                          phone: {
                            flexWrap: 'wrap',
                            flexDirection: (nopb && 'column') || undefined
                          }
                        }),
                        gap: '16px',
                        minWidth: nopb ? '-webkit-fill-available' : '',
                        minHeight: '4rem'
                      }}>
                      <Div
                        styles={{
                          minWidth: nopb ? '80px' : '48px',
                          maxWidth: nopb ? '80px' : '48px',
                          height: nopb ? '80px' : '48px',
                          maxHeight: nopb ? '80px' : '48px',
                          position: 'relative',
                          flexBasis: 'auto'
                        }}>
                        <Image
                          colorVariant='transparent'
                          sizes='(max-width: 768px) 50vw, 75vw'
                          quality={50}
                          src={
                            (nopb &&
                              category?.image?.url &&
                              category?.image?.url) ||
                            (nopb &&
                              program?.image?.url &&
                              program?.image?.url) ||
                            `/assets/imgs/groups/${
                              randomNumber + idx < 13
                                ? randomNumber + idx
                                : randomNumber - idx
                            }.png`
                          }
                          fill
                          alt=''
                          containerProps={{
                            styles: {
                              borderRadius: '8px',
                              '& img': {
                                objectFit: 'cover'
                              }
                            }
                          }}
                        />
                      </Div>
                      <Div
                        styles={{
                          display: 'flex',
                          ...multiMedia({
                            desktop: {
                              flexDirection: (nopb && 'row') || 'column'
                            },
                            laptop: {
                              flexDirection: (nopb && 'row') || 'column'
                            },
                            tablet: {
                              flexDirection: (nopb && 'row') || 'column'
                            },
                            phone: {
                              flexDirection: 'column',
                              flexBasis: 'content'
                            }
                          }),
                          gap: '16px',
                          width: '100%',
                          alignItems: 'flex-start',
                          '& > a': {
                            alignSelf: nopb ? 'center' : ''
                          }
                        }}>
                        <Div>
                          <Span
                            styles={{
                              display: 'block'
                            }}>
                            {idx === 0 && category?.name
                              ? `${category?.name}`
                              : `${program?.name}`}
                          </Span>
                          {nopb &&
                            (category?.relatedArticleDescription ||
                              program?.relatedArticleDescription) && (
                              <Span
                                styles={{
                                  display: 'block',
                                  marginTop: '8px'
                                }}>
                                {category?.name
                                  ? `${category?.relatedArticleDescription}`
                                  : `${program?.relatedArticleDescription}`}
                              </Span>
                            )}
                        </Div>
                        {nopb ? (
                          <Button
                            colorsVariant='beta'
                            sizeVariant='sm'
                            onClick={() =>
                              router.push(
                                idx === 0 && category?.slug
                                  ? `/${category?.slug}`
                                  : `/${program?.slug}--${
                                      program?.category?.slug
                                        ? program?.category?.slug
                                        : 'kurs'
                                    }`
                              )
                            }
                            styles={{
                              ...multiMedia({
                                desktop: { display: 'flex' },
                                laptop: { display: 'flex' },
                                tablet: { display: 'flex' },
                                phone: { display: 'none' }
                              }),
                              alignSelf: 'center',
                              color: 'white',
                              minWidth: '7rem',
                              marginLeft: 'auto',
                              '&:hover': {
                                borderColor: 'var(--beta)'
                              }
                            }}>
                            {`${learnMore}`}
                          </Button>
                        ) : (
                          <Link
                            href={
                              idx === 0 && category?.slug
                                ? `/${category?.slug}`
                                : `/${program?.slug}--${
                                    program?.category?.slug
                                      ? program?.category?.slug
                                      : 'kurs'
                                  }`
                            }>
                            <Span
                              styles={{
                                color: 'var(--alpha)',
                                textDecoration: 'underline',
                                ':hover': {
                                  textDecorationColor: 'var(--omega)'
                                },
                                textDecorationColor: 'var(--alpha)'
                              }}>{`${learnMore}`}</Span>
                          </Link>
                        )}
                      </Div>
                      <Div
                        styles={{
                          flexBasis: '100%',
                          ...multiMedia({
                            desktop: { display: 'none' },
                            laptop: { display: 'none' },
                            tablet: { display: 'none' },
                            phone: { display: 'inline-block' }
                          })
                        }}>
                        {nopb && (
                          <Button
                            colorsVariant='beta'
                            sizeVariant='sm'
                            onClick={() =>
                              router.push(
                                idx === 0 && category?.slug
                                  ? `/${category?.slug}`
                                  : `/${program?.slug}--${
                                      program?.category?.slug
                                        ? program?.category?.slug
                                        : 'kurs'
                                    }`
                              )
                            }
                            styles={{
                              color: 'white',
                              padding: 0,
                              minWidth: '128px',
                              height: '41px'
                            }}>
                            {`${learnMore}`}
                          </Button>
                        )}
                      </Div>
                    </Div>
                    {idx === 0 && nopb && array.length > 1 && (
                      <Div
                        styles={{
                          borderBottom: '1.5px solid #585858',
                          marginTop: toUnit(8),
                          width: '100%'
                        }}
                      />
                    )}
                  </Fragment>
                )
            )}
          <Span
            styles={{
              fontSize: '14px',
              marginTop: toUnit(16)
            }}>
            <Span
              styles={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%',
                ...multiMedia({
                  desktop: { width: (!nopb && '320px') || '' },
                  laptop: { width: (!nopb && '320px') || '' },
                  tablet: { width: (!nopb && '320px') || '' },
                  phone: { width: '320px' }
                }),
                letterSpacing: '0.4px',
                color: '#8B8B8B'
              }}>
              *В этом месяце мы подготовили для вас{' '}
              <Span
                styles={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '120%',
                  color: 'var(--beta)'
                }}>
                скидку 30%
              </Span>{' '}
              на все курсы! Спешите записаться на обучение, ведь количество мест
              ограничено
            </Span>
          </Span>
        </Div>
      )}
      {oneArticle && (
        <Div>
          {(programs || category) &&
            programs?.map((program, index, array) => (
              <Div
                key={`RelatedCourses-OneArticle__Div${index + 1}`}
                styles={{
                  minWidth: '-webkit-fill-available',
                  ...multiMedia({
                    desktop: {
                      width: '800px',
                      height: '136px',
                      alignItems: 'center',
                      flexDirection: 'row'
                    },
                    laptop: {
                      width: '800px',
                      height: '136px',
                      alignItems: 'center',
                      flexDirection: 'row'
                    },
                    tablet: {
                      width: '640px',
                      height: '136px',
                      alignItems: 'center',
                      flexDirection: 'row'
                    },
                    phone: {
                      width: '343px',
                      height: '288px',
                      alignItems: 'flex-start',
                      flexDirection: 'column'
                    }
                  }),
                  borderRadius: array.length === 1 ? '16px !important' : '',
                  backgroundColor: '#F7F7F7',
                  ':first-child': {
                    borderRadius: '16px 16px 0 0'
                  },
                  ':last-child': {
                    borderRadius: '0 0 16px 16px'
                  },
                  position: 'relative',
                  display: 'flex',
                  maxWidth: '-webkit-fill-available',
                  gap: toUnit(16),
                  padding: toUnit(32),
                  boxSizing: 'border-box',
                  '& > a': {
                    display: 'flex',
                    height: 'inherit',
                    ...multiMedia({
                      desktop: { alignItems: 'center' },
                      laptop: { alignItems: 'center' },
                      tablet: { alignItems: 'center' },
                      phone: { alignItems: 'flex-start' }
                    })
                  }
                }}>
                {(programs || category) && (
                  <>
                    <Div
                      styles={{
                        minWidth: '80px',
                        minHeight: '80px',
                        height: '80px',
                        position: 'relative'
                      }}>
                      <Image
                        colorVariant='transparent'
                        sizes='(max-width: 768px) 50vw, 75vw'
                        quality={50}
                        alt={`Program-Category__${index + 1}`}
                        src={
                          program?.image?.url
                            ? program?.image?.url
                            : `/assets/imgs/groups/${
                                Math.floor(Math.random() * 12) + 1
                              }.png`
                        }
                        fill
                        containerProps={{
                          styles: {
                            borderRadius: '16px',
                            '& img': {
                              objectFit: 'cover'
                            }
                          }
                        }}
                      />
                    </Div>
                    <Div
                      styles={{
                        minWidth: 'auto',
                        display: 'flex',
                        ...multiMedia({
                          desktop: {
                            alignItems: 'center',
                            flexDirection: 'row'
                          },
                          laptop: {
                            alignItems: 'center',
                            flexDirection: 'row'
                          },
                          tablet: {
                            alignItems: 'center',
                            flexDirection: 'row'
                          },
                          phone: {
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                          }
                        }),
                        justifyContent: 'space-between',
                        width: '100%',
                        height: '100%'
                      }}>
                      <Div
                        styles={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                        {(program?.name || category?.name) && (
                          <P variant={`${program?.name ? 'hi' : 'upsilon'}`}>
                            {`${category?.name ? `Программа` : `Курс`} по теме`}
                          </P>
                        )}
                        <P
                          variant='sigma'
                          styles={{
                            marginTop: toUnit(8)
                          }}>
                          {index === 0 && category?.name
                            ? `${category?.name}`
                            : `${program?.name}`}
                        </P>
                      </Div>
                      {(program || category) && (
                        <Button
                          colorsVariant='beta'
                          sizeVariant='default'
                          onClick={() =>
                            router.push(
                              index === 0 && category?.slug
                                ? `/${category?.slug}`
                                : `/${program?.slug}--${
                                    program?.category?.slug
                                      ? program?.category?.slug
                                      : 'kurs'
                                  }`
                            )
                          }
                          styles={{
                            width: '10rem'
                          }}>
                          Узнать больше
                        </Button>
                      )}
                    </Div>
                  </>
                )}
              </Div>
            ))}
        </Div>
      )}
    </>
  )
}
