import { TDataFaculties } from './types'

export function filterFacultiesData(faculties: TDataFaculties) {
  const filteredFaculties = faculties?.map(category => {
    return {
      ...category,
      faculties: category?.faculties?.map(faculty => {
        const facultyPrograms = faculty?.programs?.filter(
          program => program?.category?.slug === category?.category?.slug
        )
        return {
          ...faculty,
          programs: facultyPrograms,
          programCount: facultyPrograms?.length
        }
      })
    }
  })

  return filteredFaculties
}

export default filterFacultiesData
