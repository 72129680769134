import { TGetLeastProgramContentsDataProps } from './types'
import { fetchPrograms, normalizeProgramsData } from './utils'

export const getLeastProgramContentsData = async ({
  context
}: TGetLeastProgramContentsDataProps) => {
  const programsData = await fetchPrograms({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getLeastProgramContentsData
