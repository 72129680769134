export {
  IDS,
  HEADING,
  CONTACTS,
  ALMATY,
  MOSCOW,
  TASHKENT,
  ADDRESS_LABEL,
  EMAIL_LABEL,
  PHONE_NUMBER_LABEL,
  IMG_MOSCOW_MAP_ALT,
  CAMPUS,
  IMG_PEOPLE_STUDYING_ALT,
  FORM_TITLE,
  FORM_NAME,
  SEO_TITLE,
  SEO_DESCRIPTION
} from './constants'
export type { TContactPageProps } from './types'
// TODO: fix eslint error
// eslint-disable-next-line import/no-cycle
export { ContactButtons } from './components'
