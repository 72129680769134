import {
  Container,
  Div,
  H2,
  Section,
  multiMedia,
  toUnit,
  useMedia,
  useTranslations
} from 'fractals'
import { useRouter } from 'next/router'
import { Layout } from 'ui'
import { CARDS, HEADING } from './fractals/constants'
import { OpportunityCard } from './fractals/components/molecules'
import { OpportunityCardData } from './fractals/components/molecules/OpportunityCard/types'
import { getOpportunityCardVariant } from './fractals/utils'

const Opportunities = () => {
  const { isLaptop, isDesktop, isTablet, isMobile } = useMedia()

  const columns = ((isDesktop || isLaptop) && 2) || 1

  const [heading] = useTranslations(HEADING)
  const { locale = 'ru' } = useRouter()

  // TODO fix types
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const cards: OpportunityCardData[] = CARDS[locale]

  return (
    <Section spacingVariant='theta' id='Opportunities' styles={{}}>
      <Container
        styles={{
          backgroundColor: 'var(--chi)',
          borderRadius: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          ...multiMedia({
            desktop: { maxWidth: '1216px', padding: toUnit(64) },
            laptop: {
              maxWidth: '800px',
              padding: `${toUnit(64)} ${toUnit(24)}`
            },
            tablet: {
              maxWidth: '640px',
              padding: `${toUnit(64)} ${toUnit(32)}`
            },
            phone: { maxWidth: '343px', padding: `${toUnit(56)} ${toUnit(16)}` }
          })
        }}>
        <Div
          styles={{
            ...multiMedia({
              desktop: { maxWidth: `calc(528px * 2 + 2rem)` },
              laptop: { maxWidth: `calc(360px * 2 + 2rem)` },
              tablet: { maxWidth: `calc(576px * 2 + 2rem)` },
              phone: { maxWidth: `calc(311px * 2 + 2rem)` }
            })
          }}>
          <H2 variant='jota'>{heading}</H2>
        </Div>

        <Layout.Flex
          cols={columns}
          gap={toUnit(32)}
          styles={{
            justifyContent: 'center'
          }}>
          {cards.map((card, index) => (
            <OpportunityCard
              key={card.header}
              data={card}
              variant={getOpportunityCardVariant(index, columns)}
              customOrder={index === 2 && (isTablet || isMobile)}
            />
          ))}
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default Opportunities
