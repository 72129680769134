import { LOCALES } from './locales'

export const YEAR = {
  [LOCALES.en]: 'year',
  [LOCALES.ru]: 'год',
  [LOCALES.uz]: 'yil',
  [LOCALES.kk]: 'жыл'
} as const

export const MONTH = {
  [LOCALES.en]: 'month',
  [LOCALES.ru]: 'месяц',
  [LOCALES.uz]: 'oy',
  [LOCALES.kk]: 'ай'
} as const

export const JANUARY = {
  [LOCALES.en]: 'January',
  [LOCALES.ru]: 'Январь',
  [LOCALES.uz]: 'Yanvar',
  [LOCALES.kk]: 'Қаңтар'
}

export const FEBRUARY = {
  [LOCALES.en]: 'February',
  [LOCALES.ru]: 'Февраль',
  [LOCALES.uz]: 'Fevral',
  [LOCALES.kk]: 'Ақпан'
}

export const MARCH = {
  [LOCALES.en]: 'March',
  [LOCALES.ru]: 'Март',
  [LOCALES.uz]: 'Mart',
  [LOCALES.kk]: 'Наурыз'
}

export const APRIL = {
  [LOCALES.en]: 'April',
  [LOCALES.ru]: 'Апрель',
  [LOCALES.uz]: 'Aprel',
  [LOCALES.kk]: 'Сәуір'
}

export const MAY = {
  [LOCALES.en]: 'May',
  [LOCALES.ru]: 'Май',
  [LOCALES.uz]: 'May',
  [LOCALES.kk]: 'Мамыр'
}

export const JUNE = {
  [LOCALES.en]: 'June',
  [LOCALES.ru]: 'Июнь',
  [LOCALES.uz]: 'Iyun',
  [LOCALES.kk]: 'Маусым'
}

export const JULY = {
  [LOCALES.en]: 'July',
  [LOCALES.ru]: 'Июль',
  [LOCALES.uz]: 'Iyul',
  [LOCALES.kk]: 'Шілде'
}

export const AUGUST = {
  [LOCALES.en]: 'August',
  [LOCALES.ru]: 'Август',
  [LOCALES.uz]: 'Avgust',
  [LOCALES.kk]: 'Тамыз'
}

export const SEPTEMBER = {
  [LOCALES.en]: 'September',
  [LOCALES.ru]: 'Сентябрь',
  [LOCALES.uz]: 'Sentabr',
  [LOCALES.kk]: 'Қыркүйек'
}

export const OCTOBER = {
  [LOCALES.en]: 'October',
  [LOCALES.ru]: 'Октябрь',
  [LOCALES.uz]: 'Oktabr',
  [LOCALES.kk]: 'Қазан'
}

export const NOVEMBER = {
  [LOCALES.en]: 'November',
  [LOCALES.ru]: 'Ноябрь',
  [LOCALES.uz]: 'Noyabr',
  [LOCALES.kk]: 'Қараша'
}

export const DECEMBER = {
  [LOCALES.en]: 'December',
  [LOCALES.ru]: 'Декабрь',
  [LOCALES.uz]: 'Dekabr',
  [LOCALES.kk]: 'Желтоқсан'
}
