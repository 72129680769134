import { LOCALES } from 'fractals'

export const IDS = {
  homePage: 'home-page',
  hero: 'hero',
  faculties: 'faculties',
  mission: 'mission',
  testimonials: 'testimonials',
  video: 'video',
  studySteps: 'study-steps',
  whyUs: 'why-us',
  diplomas: 'diplomas',
  journal: 'journal',
  faq: 'faq'
} as const

export const H1 = {
  [LOCALES.en]: 'The most **innovative** university in the country',
  [LOCALES.ru]: 'Самый **инновационный** ВУЗ страны',
  [LOCALES.uz]: 'O‘zbekiston davlatining eng **innovatsion** universiteti',
  [LOCALES.kk]: 'Қазақстан Республикасының ең **инновациялық** университеті'
} as const

export const SEO_TITLE = {
  [LOCALES.ru]:
    'Онлайн образование от MITM.institute | Обучение современным профессиям',
  [LOCALES.en]:
    'Online education from MITM.institute | Education of modern professions',
  [LOCALES.uz]:
    'MITM.institut dan online ta’lim | Zamonaviy kasblar o’qitilishi',
  [LOCALES.kk]:
    'MITM.institut dan online ta’lim | Zamonaviy kasblar o’qitilishi'
} as const

export const SEO_DESCRIPTION = {
  [LOCALES.ru]:
    'Самый инновационный ВУЗ страны - MITM.institute предлагает дистанционные онлайн-курсы по востребованным направлениям и специальностям. Удобный формат обучения со скидкой 30% Государственная аккредитация! Актуальные подходы к образованию вместе с экспертами-практиками',
  [LOCALES.en]:
    'The most innovative university in the country - MITM.institute offers online courses in popular directions and specialties. Convenient format of training with a 30% discount State accreditation! Up-to-date approaches to education together with experts-practitioners',
  [LOCALES.uz]:
    'O‘zbekiston davlatining eng innovatsion universiteti - MITM.institut mamlakatning eng ko‘p talab qilinadigan yo‘nalishlari va kasblari bo‘yicha online kurslarni taklif etadi. 30% chegirma bilan ta’limning qulay formati Davlat akreditatsiyasi! Ta’limda so‘nggi yo‘nalishlar o‘zaro ishtirokchilar bilan',
  [LOCALES.kk]:
    'Қазақстан Республикасының ең инновациялық университеті - MITM.institut mamlakatning eng ko‘p talab qilinadigan yo‘nalishlari va kasblari bo‘yicha online kurslarni taklif etadi. 30% chegirma bilan ta’limning qulay formati Davlat akreditatsiyasi! Ta’limda so‘nggi yo‘nalishlar o‘zaro ishtirokchilar bilan'
} as const
