import * as React from 'react'
import { StyledJSX } from 'ui'
import { TIconContainerProps } from './types'
import { ROOT_ELEMENT } from './constants'

const IconContainer = ({ ...props }: TIconContainerProps) => {
  return (
    <StyledJSX.Element
      {...props}
      as={ROOT_ELEMENT}
      styles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%'
        },
        ...props.styles
      }}
    />
  )
}

export default IconContainer
