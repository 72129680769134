import { AccordionSummary, AccordionSummaryProps } from '@mui/material'
import { styled } from '@mui/system'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const CustomAccordionSummary = styled(
  (props: AccordionSummaryProps & { isMobile?: boolean }) => {
    return <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
  }
)(({ isMobile }) => ({
  padding: 0,
  maxHeight: isMobile ? '17px' : '29px'
}))
