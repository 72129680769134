'use client'

import { createContext, useMemo, useState } from 'react'
// TODO: remove the eslint-disable line and fix the error
// eslint-disable-next-line import/no-cycle
import { useHistoryNavigation } from '../../hooks'
import { TypeModalShowCtx, TypeModalShowProviderProps } from './types'

const ModalShowCtx = createContext<TypeModalShowCtx>({
  setShown: () => {},
  shown: false,
  modalId: '',
  isPoppingHistory: false
})

const ModalShowProvider = ({
  modalId,
  children
}: TypeModalShowProviderProps) => {
  const [shown, setShown] = useState(false)
  const isPoppingHistory = useHistoryNavigation({ shown, setShown, modalId })

  const showProps = useMemo(
    () => ({
      shown,
      setShown,
      modalId,
      isPoppingHistory
    }),
    [shown, setShown, modalId, isPoppingHistory]
  )

  return (
    <ModalShowCtx.Provider value={showProps}>{children}</ModalShowCtx.Provider>
  )
}

export { ModalShowCtx, ModalShowProvider }
