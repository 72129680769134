import {
  useTranslations,
  useCalculateClosestEnrollment,
  Div,
  Span,
  toUnit,
  useMedia
} from 'fractals'
import Color from 'color'
import { DISCOUNT_TEXT, UNTIL } from './constants'
import { TDiscountProps } from './types'

export const Discount = ({
  discountUntil,
  discount,
  colorVariant
}: TDiscountProps) => {
  const [discountText, until] = useTranslations(DISCOUNT_TEXT, UNTIL)

  const { isDesktop } = useMedia()

  const calculatedClosestEnrollment = useCalculateClosestEnrollment({
    timestamp: discountUntil || null
  })

  if (!discount) return null

  return (
    <Div
      styles={{
        display: 'flex',
        gap: toUnit(8),
        alignItems: 'center'
      }}>
      <Div
        styles={{
          paddingTop: toUnit(4),
          paddingRight: toUnit(8),
          paddingBottom: toUnit(4),
          paddingLeft: toUnit(8),
          borderRadius: toUnit(32),
          fontSize: toUnit((isDesktop && 20) || 16),
          fontWeight: 400,
          lineHeight: '120%',
          backgroundColor: 'var(--alpha)',
          color:
            (colorVariant?.color &&
              Color(colorVariant?.color).rgb().string()) ||
            colorVariant?.color ||
            'var(--beta)'
        }}>
        {discountText} - {discount}%
      </Div>
      <Span>
        {until} {calculatedClosestEnrollment}
      </Span>
    </Div>
  )
}
export default Discount
