import {
  useTranslations,
  Section,
  Container,
  H1,
  P,
  toUnit,
  Image,
  InfoTag,
  useCalculateClosestEnrollment,
  useCalculateStudyDuration,
  Div,
  useAt,
  useMedia
} from 'fractals'
import {
  Layout,
  mdToJsx,
  spacesToNonBrakingSpaces,
  insertLineBrakeBetweenWords,
  slugify,
  calculateRuCase
} from 'ui'
import { Shared } from 'modules'
import { useRouter } from 'next/router'
import { useTheme } from 'styled-components'
import Color from 'color'
import {
  THeroProps,
  DEFAULT_CATEGORY_LABEL,
  HOW_TO_REDUCE,
  IMG_DEFAULT_ALT,
  CLOSEST_ENROLLMENT,
  STUDY_DURATIION,
  STUDY_FORMAT,
  STUDY_FORMAT_VALUE,
  GRADUATION_DOCUMENTS,
  EDUCATION_LEVEL,
  HOURS_AMOUNT,
  HOURS,
  PRIMARY_BTN,
  SECONDARY_BTN,
  GRADUATION_DOCUMENTS_DEFAULT
} from './fractals'
import imgDefault from './hero.jpg'
// TODO: remove the next line and fix the error
// eslint-disable-next-line
import { Discount } from '../../fractals'

// TODO: brake it down for components
const Hero = ({ h1, colorVariant, hero }: THeroProps) => {
  const [
    headingDefault,
    howToReduce,
    defaultCategoryLabel,
    imgDefaultAlt,
    closestEnrollment,
    studyDuration,
    studyFormat,
    closestFormatValue,
    graduationDocuments,
    educationLevel,
    hoursAmount,
    hours,
    primaryBtn,
    secondaryBtn,
    graduationDocumentsDefault
  ] = useTranslations(
    h1,
    HOW_TO_REDUCE,
    DEFAULT_CATEGORY_LABEL,
    IMG_DEFAULT_ALT,
    CLOSEST_ENROLLMENT,
    STUDY_DURATIION,
    STUDY_FORMAT,
    STUDY_FORMAT_VALUE,
    GRADUATION_DOCUMENTS,
    EDUCATION_LEVEL,
    HOURS_AMOUNT,
    HOURS,
    PRIMARY_BTN,
    SECONDARY_BTN,
    GRADUATION_DOCUMENTS_DEFAULT
  )

  const { isTablet, isLaptop, isDesktop } = useMedia()
  const { colors } = useTheme()

  const { isCourse } = useAt()

  const { locale } = useRouter()

  const calculatedStudyDuration = useCalculateStudyDuration({
    months: hero?.cost?.studyDurationMonths,
    years: hero?.cost?.studyDurationYears
  })

  const calculatedClosestEnrollment = useCalculateClosestEnrollment({
    timestamp: hero?.cost?.closestEnrollment || null
  })

  const imageProps = {
    borderRadiusVariant: 'alpha',
    colorVariant: 'transparent',
    ...((hero?.image.url &&
      hero?.image?.height &&
      hero?.image.width && {
        src: hero.image.url,
        width: hero.image.width,
        height: hero.image.height,
        alt: hero.image.alternativeText || imgDefaultAlt
      }) || {
      src: imgDefault,
      alt: imgDefaultAlt
    })
  } as const

  const testimonials = [
    {
      label: null,
      name: closestEnrollment,
      value: calculatedClosestEnrollment
    },
    {
      label: (isCourse && howToReduce) || null,
      name: studyDuration,
      value: calculatedStudyDuration
    },
    {
      label: null,

      name: studyFormat,
      value: closestFormatValue
    },
    {
      label: null,

      name: graduationDocuments,
      value:
        hero?.diplomaLabels?.[0] ||
        hero?.category.diplomaLabels?.[0] ||
        graduationDocumentsDefault
    },
    ...((isCourse &&
      hero?.cost?.studyHours && [
        {
          label: null,
          name: hoursAmount,
          value: `${hero?.cost?.studyHours} ${calculateRuCase(
            hours,
            hero?.cost?.studyHours,
            locale
          )}`
        }
      ]) || [
      {
        label: null,
        name: educationLevel,
        value:
          hero?.category?.label || hero?.category?.name || defaultCategoryLabel
      }
    ])
  ]

  const gap = (isDesktop && 40) || 16

  const heading = hero?.name || headingDefault

  return (
    <Section
      id={slugify(heading)}
      spacingVariant='alpha'
      colorsVariant='alpha'
      colorVariant={colorVariant}>
      {hero?.name && <meta itemProp='name' content={hero.name} />}
      {hero?.description && (
        <meta itemProp='description' content={hero.description} />
      )}
      {hero?.image?.url && <link itemProp='image' href={hero.image.url} />}

      <Container>
        <Layout.Flex
          cols={((isDesktop || isLaptop) && 2) || 1}
          proportion={((isDesktop || isLaptop) && '4:3') || undefined}
          gap={toUnit(
            ((isDesktop || isLaptop) && 32) || (isTablet && 64) || 40
          )}>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'space-between'
            }}>
            <Layout.FlexItem
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                flexGrow: 1
              }}>
              <Div
                styles={{
                  order: 2
                }}>
                <H1 variant='alpha'>{heading}</H1>
                {hero?.description && (
                  <P variant='zeta' styles={{ order: 3 }}>
                    {hero?.description}
                  </P>
                )}
              </Div>
              <Div
                styles={{
                  order: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: toUnit(24),
                  marginBottom: toUnit(
                    (isDesktop && 56) || (isLaptop && 32) || 16
                  )
                }}>
                <InfoTag
                  colorVariant='alpha'
                  styles={{
                    marginBottom: 0
                  }}>
                  {hero?.category?.label ||
                    hero?.category?.name ||
                    defaultCategoryLabel}
                </InfoTag>
                {isCourse && (
                  <Discount
                    discountUntil={
                      hero?.cost?.discountUntil || hero?.cost?.closestEnrollment
                    }
                    discount={hero?.cost?.discount}
                    colorVariant={colorVariant}
                  />
                )}
              </Div>
            </Layout.FlexItem>

            {/* TODO: Layout.Flex below should be inside it's own Button component yet to create here */}
            <Layout.Flex
              cols={isDesktop || isLaptop || isTablet ? 'auto' : 1}
              gap={
                toUnit(
                  ((isDesktop || isLaptop || isTablet) && 32) || undefined
                ) || `calc(${toUnit(32)} / 1.5) ${toUnit(32)}`
              }
              styles={{
                minWidth: '100%',
                maxWidth: '100%',
                marginTop: toUnit(
                  ((isDesktop || isDesktop) && 56) || (isTablet && 56) || 40
                ),
                '& > *': {
                  display: 'flex',
                  flexGrow: 1,
                  '& > button': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1
                  }
                }
              }}>
              <Layout.FlexItem>
                <Shared.LeadPopup
                  cost={hero?.cost?.price ? hero.cost.price : 0}
                  buttonProps={{
                    colorsVariant: 'alpha',
                    children: spacesToNonBrakingSpaces(primaryBtn)
                  }}
                />
              </Layout.FlexItem>
              <Layout.FlexItem>
                {/* <Shared.LeadPopup
                  cost={hero?.cost?.price ? hero.cost.price : 0}
                  buttonProps={{
                    colorsVariant: 'alphaReverse',
                    children: spacesToNonBrakingSpaces(secondaryBtn)
                  }}
                /> */}
              </Layout.FlexItem>
            </Layout.Flex>
          </Layout.FlexItem>
          <Layout.FlexItem>
            <Image {...imageProps} />
          </Layout.FlexItem>
        </Layout.Flex>
        <Div
          styles={{
            display: 'flex',
            flexDirection: isDesktop || isLaptop ? 'row' : 'column',
            gap: toUnit(gap * 2),
            marginTop: toUnit(((isDesktop || isLaptop) && 56) || 40),
            paddingTop: toUnit((isDesktop && 32) || (isLaptop && 16) || 24),
            paddingRight: toUnit((isDesktop && 32) || (isLaptop && 16) || 24),
            paddingBottom: toUnit((isDesktop && 32) || (isLaptop && 16) || 24),
            paddingLeft: toUnit((isDesktop && 32) || (isLaptop && 16) || 24),
            borderRadius: toUnit(16),
            backgroundColor:
              (colorVariant?.color &&
                Color(colorVariant?.color).lighten(0.1).rgb().string()) ||
              colors.beta.lighten(0.1).rgb().string()
          }}>
          {testimonials.map((item, idx) => (
            <Div
              key={`Hero__testimonials__${item.name}-${idx + 1}`}
              styles={{
                position: 'relative',
                flexGrow: 1,
                paddingTop: toUnit(
                  (isCourse && (isDesktop || isLaptop) && !item.label && 28) ||
                    0
                ),
                ...((idx !== 0 && {
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: ((isDesktop || isLaptop) && '50%') || toUnit(-gap),
                    left: ((isDesktop || isLaptop) && toUnit(-gap)) || '0',
                    minWidth: ((isDesktop || isLaptop) && toUnit(1)) || '100%',
                    maxWidth: ((isDesktop || isLaptop) && toUnit(1)) || '100%',
                    minHeight: ((isDesktop || isLaptop) && '103%') || toUnit(1),
                    maxHeight: ((isDesktop || isLaptop) && '103%') || toUnit(1),
                    transform:
                      ((isDesktop || isLaptop) && 'translate(-100%, -50%)') ||
                      undefined,
                    backgroundColor: 'var(--alpha)'
                  }
                }) ||
                  {})
              }}>
              <Div
                styles={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  flexGrow: 1
                }}>
                {item.label && (
                  <Shared.LeadPopup
                    cost={hero?.cost?.price ? hero.cost.price : 0}
                    buttonProps={{
                      sizeVariant: '3xs',
                      colorsVariant: 'alpha',
                      borderRadiusVariant: 'beta',
                      styles: {
                        marginBottom: toUnit(12)
                      },
                      children: howToReduce
                    }}
                  />
                )}
                <P
                  variant='eta'
                  styles={{
                    display: 'inline-block',
                    maxWidth: 'max-content',
                    overflowWrap: 'break-word'
                  }}>
                  {(idx === 0 &&
                    isLaptop &&
                    mdToJsx(insertLineBrakeBetweenWords(item.name))) ||
                    item.name}
                </P>
                <P
                  variant='theta'
                  styles={{
                    marginTop: toUnit(16),
                    maxWidth: '25ch'
                  }}>
                  {item.value}
                </P>
              </Div>
            </Div>
          ))}
        </Div>
      </Container>
    </Section>
  )
}

export default Hero
