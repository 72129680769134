import React from 'react'
import { Link, Image } from 'fractals'

export const RatingEddu = () => {
  return (
    <Link
      target='_blank'
      variant='alpha'
      href='https://eddu.pro/reviews/mitu-review/'>
      <Image
        colorVariant='white'
        width='150'
        height='150'
        src='https://eddu.pro/eddu-counter-white-simple.svg'
        alt='Отзывы о MITM Institute'
        title='Отзывы о MITM Institute'
        containerProps={{
          styles: {
            justifyContent: 'start',
            '& img': { objectFit: 'contain' }
          }
        }}
      />
    </Link>
  )
}

export default RatingEddu
