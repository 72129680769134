import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetFooterDataProps, TResCategories } from '../types'

export const fetchCategories = async ({ context }: TGetFooterDataProps) => {
  try {
    const query = qs.stringify({
      fields: ['name', 'slug', 'rank'],
      sort: ['rank:asc'],
      locale: context.locale
    })

    const res = await fetcher<TResCategories>(
      `${PATHS.back.root.index}${PATHS.back.api.categories.index}?${query}`
    )

    return res?.data || null
  } catch (e) {
    console.log('%cfetchCategories.ts line:9 e', 'color: #007acc;', e)
  }
  return null
}

export default fetchCategories
