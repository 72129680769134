import { useAt, Section, Container, H2, toUnit, useMedia } from 'fractals'
import { Layout, slugify } from 'ui'
import { TCostProps, Testimonials, PriceCard, WhatIsIncluded } from './fractals'

const Cost = ({ heading, cost, colorVariant }: TCostProps) => {
  const { isLaptop, isDesktop } = useMedia()

  return (
    <Section id={slugify(heading)} spacingVariant='beta' colorsVariant='beta'>
      <Container>
        <H2 variant='beta'>{heading}</H2>
        <Layout.Flex
          cols={(isDesktop && 2) || 1}
          proportion={(isDesktop && '3:2') || undefined}
          gap={toUnit(((isDesktop || isLaptop) && 32) || 40)}>
          <Layout.FlexItem
            styles={{
              display: 'flex',
              flexGrow: 1
            }}>
            <PriceCard
              colorVariant={colorVariant}
              price={cost?.price}
              discount={cost?.discount}
              discountUntil={cost?.discountUntil || cost?.closestEnrollment}
            />
          </Layout.FlexItem>
          <Layout.FlexItem
            styles={{
              display: 'flex'
            }}>
            <WhatIsIncluded
              cost={cost?.price ? cost.price : 0}
              colorVariant={colorVariant}
              categoryLabel={cost?.categoryLabel}
              closestEnrollment={cost?.closestEnrollment}
              studyDurationMonths={cost?.studyDurationMonths}
              studyDurationYears={cost?.studyDurationYears}
            />
          </Layout.FlexItem>
        </Layout.Flex>
        <Testimonials />
      </Container>
    </Section>
  )
}

export default Cost
