// Получаем цены для Психология группа выходного дня
import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TResPrograms } from '../types'

type TResAdditionalСostsQuery = {
  data: {
    cost: {
      price: number | null
      discount: number | null
    }
  } | null
}
const fetchPsychologyWeekendGroup = async (res: TResPrograms) => {
  let additionalСost
  const additionalСostsQuery = qs.stringify({
    populate: {
      cost: {
        fields: ['price', 'discount']
      }
    }
  })

  if (
    res?.data &&
    res?.data[0]?.category?.slug === 'bakalavriat' &&
    res?.data[0]?.faculty?.slug === 'psihologiya'
  ) {
    try {
      additionalСost = await fetcher<TResAdditionalСostsQuery>(
        `${PATHS.back.root.index}${PATHS.back.api.programs.index}/286?${additionalСostsQuery}`
      )
    } catch (e) {
      console.log(e)
    }
  }

  return additionalСost
}

export default fetchPsychologyWeekendGroup
