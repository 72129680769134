import React from 'react'
import {
  Header,
  LOCALES,
  PATHS,
  TRANSLATIONS,
  useAt,
  useTranslations,
  Link
} from 'fractals'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import * as styles from './styles'
import { SEO_DESCRIPTION, SEO_TITLE, TJournalsPageProps } from './fractals'
import { BreadcrumbsReact, PageContainer } from '../shared/widgets'
import { Shared } from '../shared'
import { H1, Main, Spinner } from '@/fractals/components'
import { JournalsFilterButtons } from './widgets/Journals/fractals/components/molecules'

const DynamicJournals = dynamic(() => import('./widgets/Journals/Journals'), {
  loading: () => <Spinner />
})

export const JournalsPage = ({
  faculties,
  tags,
  journals,
  allJournals,
  footer,
  seoJournals
}: TJournalsPageProps) => {
  const { asPath, locale } = useRouter()
  const [companyName, defaultSeoTitle, defaultSeoDescription] = useTranslations(
    TRANSLATIONS.companyName,
    SEO_TITLE,
    SEO_DESCRIPTION
  )

  const { currentJournalsFacultySlug } = useAt()

  const toDativeCase = (word: string) => {
    const lastChar = word.charAt(word.length - 1)
    const preLastChar = word.charAt(word.length - 2)
    if (preLastChar === 'а' || preLastChar === 'я') {
      return `${word.slice(0, -2)}е`
    }
    if (preLastChar === 'и' && lastChar === 'е') {
      return `${word.slice(0, -2)}ию`
    }
    if (preLastChar === 'а' && lastChar === 'я') {
      return `${word.slice(0, -2)}ой`
    }
    if (lastChar === 'а') {
      return `${word.slice(0, -1)}е`
    }
    if (lastChar === 'й') {
      return `${word.slice(0, -1)}ю`
    }
    if (lastChar === 'о') {
      return `${word.slice(0, -1)}у`
    }
    if (lastChar === 'ь' || lastChar === 'я') {
      return `${word.slice(0, -1)}и`
    }
    return `${word}у`
  }

  const currentFacultyName = () =>
    faculties?.find(faculty => faculty.slug === currentJournalsFacultySlug)
      ?.name ||
    tags?.find(tag => tag.slug === currentJournalsFacultySlug)?.name ||
    'Факультету'

  const seoParams = {
    title:
      (currentJournalsFacultySlug &&
        (seoJournals?.faculty?.seo.metaTitle ||
          seoJournals?.tag?.seo.metaTitle)) ||
      (currentJournalsFacultySlug &&
        locale === LOCALES.ru &&
        `Статьи по ${currentFacultyName()
          .split(' ')
          .filter(name => name !== 'Про')
          .map(name => (name.length > 3 ? toDativeCase(name) : name))
          .join(' ')} | Блог MITM.institute`) ||
      defaultSeoTitle,
    desc:
      (currentJournalsFacultySlug &&
        (seoJournals?.faculty?.seo.metaDescription ||
          seoJournals?.tag?.seo.metaDescription)) ||
      (currentJournalsFacultySlug &&
        locale === LOCALES.ru &&
        `Интересные информационные статьи и публикации по теме "${currentFacultyName()}" от онлайн-института MITM. Читайте в журнале актуальные материалы, последние тренды , полезные практики и обзоры.`) ||
      defaultSeoDescription,
    canonical:
      (currentJournalsFacultySlug
        ? seoJournals?.faculty?.seo.canonicalURL
        : seoJournals?.tag?.seo.canonicalURL) ||
      `${PATHS.front.root.index}${asPath}`
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        {allJournals?.map(journal => (
          <Link
            href={`${PATHS.front.root.index}${PATHS.front.journal.ru}--${
              journal.faculty?.slug || journal.tag?.slug
            }--${journal.slug}`}
            variant='alpha'>
            {null}
          </Link>
        ))}
      </div>
      <NextSeo
        title={seoParams.title}
        description={seoParams.desc}
        canonical={seoParams.canonical}
        openGraph={{
          url: seoParams.canonical,
          title: seoParams.title,
          description: seoParams.desc,
          images: [
            {
              url: `${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`,
              width: 512,
              height: 512,
              alt: companyName,
              type: 'image/png'
            }
          ],
          site_name: companyName
        }}
      />

      <PageContainer>
        <Header
          styles={{
            ...styles.journalsPageHeaderStyles,
            '& > div > div': {
              alignItems: 'self-start'
              // flexDirection: 'column'
            }
          }}
          variant='beta'>
          <BreadcrumbsReact bread={null} />
          <H1 variant='betaA'>Журнал</H1>
          <JournalsFilterButtons
            allJournals={allJournals}
            faculties={faculties}
            tags={tags}
          />
        </Header>
        <Main>
          <DynamicJournals journals={journals} />
          {/* <Shared.AskModal /> */}
        </Main>
        <Shared.Footer footer={footer} />
      </PageContainer>
    </>
  )
}

export default JournalsPage
