const parseDuration = (duration: string | number, divisor = 1) => {
  if (typeof duration === 'string') {
    const trimmed = duration.trim()

    if (/^\d+ms$/gi.test(trimmed))
      return `${(parseFloat(trimmed) / divisor).toFixed(0)}ms`

    if (/^\d*\.*\d*s$/gi.test(trimmed))
      return `${
        parseFloat((parseFloat(trimmed) / divisor).toFixed(3)) * 1000
      }ms`

    return '0'
  }

  if (typeof duration === 'number') {
    return `${(duration / divisor).toFixed(0)}ms`
  }

  return '0'
}

export default parseDuration
