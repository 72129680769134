export type { TCostProps } from './types'
export { getCostData } from './api'
export {
  Testimonials,
  PriceCard,
  WhatIsIncluded,
  PriceCardPsihologiyaIsHigherEducation,
  WhatIsIncludedPsihologiyaIsHigherEducation,
  TestimonialsPsihologiyaIsHigherEducation
} from './components'
