import NextImage from 'next/image'
import { BASE64PIXELS, StyledJSX } from 'ui'
import { CSSObject } from 'styled-components'
import { TImageProps } from './types'
import { PLACEHOLDER } from './constants'

const Image = ({ containerProps, ...props }: TImageProps) => {
  return (
    <StyledJSX.Span
      {...containerProps}
      styles={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...containerProps?.styles,
        '& img': {
          ...((containerProps?.styles?.['& img'] as CSSObject) || {}),
          borderStyle: 'none',
          maxWidth: '100%',
          height: 'auto',
          borderRadius: containerProps?.styles?.borderRadius || undefined
        }
      }}>
      <NextImage
        blurDataURL={
          props.blurDataURL ||
          (typeof props.src === 'string' && BASE64PIXELS.transparent) ||
          undefined
        }
        {...props}
      />
      {containerProps?.children}
    </StyledJSX.Span>
  )
}

export default Image
