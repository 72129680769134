import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useResizeObserver } from '../../../../../hooks'

export type TypeButtonInfo = {
  bounding: DOMRect | null
  target: HTMLButtonElement | null
}

const useButtonInfo = (): [
  ReturnType<typeof useResizeObserver>[0],
  Dispatch<SetStateAction<ResizeObserverEntry | null>>,
  TypeButtonInfo
] => {
  const [buttonInfo, setbuttonInfo] = useState<TypeButtonInfo>({
    target: null,
    bounding: null
  })
  const [wrapperEntry, setWrapperEntry] = useState<ResizeObserverEntry | null>(
    null
  )
  const [setRef, entry] = useResizeObserver()

  useEffect(() => {
    if (entry?.target && wrapperEntry) {
      const info = {
        target: entry.target as HTMLButtonElement,
        bounding: entry.target.getBoundingClientRect()
      }

      setbuttonInfo(info)
    }
  }, [entry, wrapperEntry])

  return [setRef, setWrapperEntry, buttonInfo]
}

export default useButtonInfo
