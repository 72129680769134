import Image from 'next/image'
import Link from 'next/link'

export const RatingKurshub = () => {
  return (
    <Link
      href='https://kurshub.ru/reviews/mitm/'
      target='_blank'
      rel='noopener noreferrer'>
      <Image
        src='https://kurshub.ru/informers/mitm/light.png'
        alt='Kurshub Reviews'
        width='141'
        height='70'
      />
    </Link>
  )
}

export default RatingKurshub
