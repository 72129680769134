export {
  TESTIMONIAL_TITLE_YEARS,
  TESTIMONIAL_DESC_YEARS,
  TESTIMONIAL_TITLE_STUDENTS,
  TESTIMONIAL_DESC_STUDENTS,
  TESTIMONIAL_TITLE_YEARS_ONLINE,
  TESTIMONIAL_DESC_YEARS_ONLINE,
  TESTIMONIAL_TITLE_GRADUATES,
  TESTIMONIAL_DESC_GRADUATES
} from './constants'
export type { TTestimonialsProps } from './types'
