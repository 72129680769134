import { useRouter } from 'next/router'
import Link from 'next/link'
import { TStyledElementStyledProps } from 'ui'
import { dictionary } from './dictionary'
import { Arrow } from './atoms'
import { useMedia } from '@/fractals/hooks'
import { getClearPath, getHref, getPaths } from './utils'
import { Bread } from './types'

export const BreadcrumbsReact = ({
  bread,
  ...props
}: {
  bread: Bread | null
} & TStyledElementStyledProps) => {
  const { locale, asPath } = useRouter()
  const fakultetPaths = asPath.includes('fakultet')
  const journalPaths = asPath.includes('journal')
  const reversedPaths = fakultetPaths && journalPaths // возможно недостижимое условие
  const { isMobile } = useMedia()
  const paths = getPaths(asPath, { journalPaths, fakultetPaths, bread })

  const breadcrumbs = paths?.map((path, i) => {
    const href = getHref(paths, path, i, reversedPaths, locale, asPath, {
      journalPaths,
      fakultetPaths,
      bread
    })
    const clearPath = getClearPath(path)

    return (
      <li
        style={{ listStyle: 'none' }}
        itemProp='itemListElement'
        itemScope
        itemType='https://schema.org/ListItem'
        key={`breadcrumb-id-${i + 1}`}>
        <Link
          itemProp='item'
          style={{
            color:
              i === paths.length - 1
                ? props?.styles?.color ?? 'black'
                : props?.styles?.color ?? '#8B8B8B',
            cursor: 'pointer',
            pointerEvents: i === paths.length - 1 ? 'none' : 'auto',
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          key={`Breadcrumbs__${i + 1}`}
          href={!reversedPaths ? href : `/${href}`}>
          {paths.find(el => el.match(/[a-z]/)) ? (
            <Arrow
              styles={{
                stroke: props?.styles?.color,
                margin: '0 0.5rem 0 0.5rem'
              }}
            />
          ) : (
            ''
          )}
          <span itemProp='name'>
            {!reversedPaths
              ? `${dictionary[clearPath] ? dictionary[clearPath] : clearPath} `
              : `${
                  dictionary[
                    String(
                      clearPath.split('/')?.[clearPath.split('/').length - 1]
                    )
                  ]
                    ? dictionary[
                        String(
                          clearPath.split('/')?.[
                            clearPath.split('/').length - 1
                          ]
                        )
                      ]
                    : bread?.name
                } `}
          </span>
          <meta itemProp='position' content={`${i + 2}`} />
        </Link>
      </li>
    )
  })

  const homeBreadcrumb = (
    <li
      style={{ listStyle: 'none' }}
      itemProp='itemListElement'
      itemScope
      itemType='https://schema.org/ListItem'
      key='breadcrumb-id-home'>
      <Link
        itemProp='item'
        style={{
          color: props?.styles?.color ?? '#8B8B8B',
          cursor: 'pointer',
          textDecoration: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        key='Breadcrumbs__0'
        href='/'>
        <span itemProp='name'>{!paths.includes('/') ? ` Главная ` : ''}</span>
        <meta itemProp='position' content='1' />
      </Link>
    </li>
  )

  return (
    <ul
      style={{
        display: 'inline-flex',
        minWidth: '-webkit-fill-available',
        flexWrap: isMobile ? 'wrap' : 'nowrap',
        marginLeft: isMobile ? '0.6rem' : props?.styles?.marginLeft,
        marginRight: isMobile ? '0.6rem' : '7.3rem',
        marginTop: props?.styles?.marginTop,
        marginBottom: isMobile ? '0.5rem' : props?.styles?.marginBottom,
        flexBasis: isMobile ? '-moz-available' : ''
      }}
      itemScope
      itemType='https://schema.org/BreadcrumbList'>
      {[homeBreadcrumb, ...breadcrumbs]}
    </ul>
  )
}

export default BreadcrumbsReact
