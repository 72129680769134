import { LOCALES } from 'fractals'

export const UNDERHEADING = {
  [LOCALES.en]: 'Get an education remotely',
  [LOCALES.ru]: 'Получи образование дистанционно',
  [LOCALES.uz]: 'Ta’limni masofadan oling',
  [LOCALES.kk]: 'Білімді қашыққанда алыңыз'
} as const

export const PRIMARY_BTN = {
  [LOCALES.en]: 'Register for a course',
  [LOCALES.ru]: 'Записаться на курс',
  [LOCALES.uz]: 'Kursga yoziling',
  [LOCALES.kk]: 'Курсқа жазылыңыз'
} as const

export const SECONDARY_BTN = {
  [LOCALES.en]: 'Ask a question',
  [LOCALES.ru]: 'Задать вопрос',
  [LOCALES.uz]: 'Savol berish',
  [LOCALES.kk]: 'Сұрақ беру'
} as const
