import React from 'react'
import { Div, P } from '@/fractals/components'
import * as subjectStyles from '../../../../../molecules/Subject/SubjectStyles'

export const StripeItem = ({ content }: { content: string }) => {
  return (
    <Div
      styles={{
        ...subjectStyles.advancedBackgroundStyles,
        position: 'relative'
      }}>
      <Div
        styles={{
          borderLeft: '4px solid #3A52EE',
          borderRadius: '43px',
          position: 'absolute',
          minHeight: '100%',
          maxHeight: 'fit-content'
        }}
      />
      <P
        styles={{
          padding: '24px',
          fontSize: '20px'
        }}
        variant='sigma'
        dangerouslySetInnerHTML={{
          __html: content
        }}>
        {null}
      </P>
    </Div>
  )
}

export default StripeItem
