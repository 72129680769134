/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Layout } from 'ui'
import {
  Link,
  LOCALES,
  multiMedia,
  PATHS,
  toUnit,
  useAt,
  useMedia,
  useTranslations
} from 'fractals'
import { useRouter } from 'next/router'
import { Box } from '@mui/material'
import { TCategoryButtonsProps } from './types'
import { ALL_PROGRAMS } from './constants'
import CategoryInfo from '@/modules/programs-page/fractals/molecules/CategoryInfo'

export const CategoryButtons = ({ categories }: TCategoryButtonsProps) => {
  const { currentCategorySlug, currentFacultySlug } = useAt()
  const { isTablet, isLaptop, isDesktop } = useMedia()
  const { locale } = useRouter()
  const [allPrograms] = useTranslations(ALL_PROGRAMS)

  let isExistFaculty

  if (!categories?.categories || categories?.categories?.length === 0)
    return null

  return (
    <Box
      display='grid'
      gridTemplateColumns={`repeat(${(isDesktop && 4) || 2}, 1fr)`}
      gap={`${toUnit(((isTablet || isLaptop || isDesktop) && 32) || 16)}`}
      boxSizing='border-box'
      sx={{
        ...multiMedia({
          desktop: {
            marginBottom: toUnit(85)
          },
          laptop: {
            marginBottom: toUnit(32)
          },
          tablet: {
            marginBottom: toUnit(40)
          }
        })
      }}>
      {[
        ...[
          {
            name: allPrograms,
            slug: `${
              PATHS.front.general.programs[
                (locale as keyof typeof LOCALES) || 'ru'
              ]
            }${
              currentFacultySlug
                ? `--${
                    PATHS.front.general.faculty[
                      (locale as keyof typeof LOCALES) || 'ru'
                    ]
                  }--${currentFacultySlug}`
                : ''
            }`,
            rank: 0,
            programs: [{ faculty: { slug: '' } }]
          }
        ],
        ...categories.categories
      ]
        .filter(category => category?.slug)
        .map((category, idx) => {
          const isApplied =
            currentCategorySlug === category?.slug ||
            (!currentCategorySlug && idx === 0)

          isExistFaculty = category.programs.some(
            program => program?.faculty?.slug === currentFacultySlug
          )

          return (
            <Layout.FlexItem
              key={`CategoryButtons__${category.name}--${idx + 1}`}
              styles={{
                display: 'flex',
                position: 'relative',
                minWidth: '280px',
                boxSizing: 'border-box',
                ...multiMedia({
                  laptop: {
                    minWidth: '384px'
                  },
                  tablet: {
                    minWidth: '304px'
                  }
                })
              }}>
              <Link
                variant={(isApplied && 'delta') || 'gamma'}
                href={
                  (idx === 0 && category?.slug) ||
                  `/${category?.slug}${
                    currentFacultySlug && isExistFaculty
                      ? `--${
                          PATHS.front.general.faculty[
                            (locale as keyof typeof LOCALES) || 'ru'
                          ]
                        }--${currentFacultySlug}`
                      : ''
                  }`
                }
                styles={{
                  flexGrow: 1
                }}>
                {`${category.name}`}

                {idx !== 0 && (
                  <CategoryInfo
                    // TODO fix types
                    // @ts-ignore
                    content={category?.description}
                    color={isApplied ? 'var(--alpha)' : 'var(--phi)'}
                    hoverColor={isApplied ? '#8D9BF5' : 'var(--beta)'}
                    // @ts-ignore
                    styles={{
                      position: 'absolute',
                      right: '16px',
                      top: '50%',
                      marginTop: '-9px'
                    }}
                  />
                )}
              </Link>
            </Layout.FlexItem>
          )
        })}
    </Box>
  )
}

export default CategoryButtons
