import {
  useTranslations,
  Section,
  Container,
  H2,
  P,
  toUnit,
  InfoTag,
  Div,
  A,
  H3,
  IconPlus,
  useMedia
} from 'fractals'
import {
  insertWhiteSpacesAfterPrepositions,
  Layout,
  mdToJsx,
  slugify
} from 'ui'
import { useState } from 'react'
import { Shared } from 'modules'
import { IDS } from 'modules/program-page'
import {
  TFAQProps,
  LABEL,
  TITLE,
  FORM_NAME,
  FORM_HEADING,
  QUESTION_1,
  ANSWER_1,
  QUESTION_2,
  ANSWER_2,
  QUESTION_3,
  ANSWER_3,
  QUESTION_4,
  ANSWER_4
} from './fractals'

// TODO: refactor this into it's own component
const QuestionAndAnswer = ({
  name,
  answer,
  isOpenDefault
}: {
  name: string | null
  answer: string | null
  isOpenDefault: boolean
}) => {
  const { isTablet, isLaptop, isDesktop } = useMedia()

  const [isOpen, setIsOpen] = useState(isOpenDefault)

  if (!name || !answer) return null

  const gap = ((isDesktop || isLaptop || isTablet) && 24) || 16
  return (
    <A
      styles={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        // gap: toUnit(((isDesktop || isLaptop || isTablet) && 32) || 16),
        paddingTop: toUnit(gap),
        paddingBottom: toUnit(gap)
      }}
      onClick={() => setIsOpen(prevState => !prevState)}>
      <Div
        styles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1
        }}>
        <H3 variant='delta2'>
          {mdToJsx(insertWhiteSpacesAfterPrepositions(name))}
        </H3>
        <IconPlus colorVariant='alpha' variant={isOpen ? 'cross' : 'plus'} />
      </Div>
      <P
        variant='kappa2'
        styles={{
          display: !isOpen ? 'none' : 'block'
        }}>
        {mdToJsx(insertWhiteSpacesAfterPrepositions(answer))}
      </P>
    </A>
  )
}

const FAQ = ({ cost, questions }: TFAQProps) => {
  const [
    label,
    title,
    formName,
    formHeading,
    question1,
    answer1,
    question2,
    answer2,
    question3,
    answer3,
    question4,
    answer4
  ] = useTranslations(
    LABEL,
    TITLE,
    FORM_NAME,
    FORM_HEADING,
    QUESTION_1,
    ANSWER_1,
    QUESTION_2,
    ANSWER_2,
    QUESTION_3,
    ANSWER_3,
    QUESTION_4,
    ANSWER_4
  )

  const { isLaptop, isDesktop } = useMedia()

  const questionsWithFallback = (questions &&
    questions?.length !== 0 &&
    questions) || [
    {
      name: question1,
      answer: answer1
    },
    {
      name: question2,
      answer: answer2
    },
    {
      name: question3,
      answer: answer3
    },
    {
      name: question4,
      answer: answer4
    }
  ]

  return (
    <Section id={slugify(title)} spacingVariant='beta' colorsVariant='alpha'>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <Div
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
          }}>
          <H2 variant='beta' styles={{ order: 2 }}>
            {title}
          </H2>
          <InfoTag colorVariant='alpha' styles={{ order: 1 }}>
            {label}
          </InfoTag>
        </Div>
        <Layout.Flex
          cols={(isDesktop && 2) || 1}
          proportion={(isDesktop && '4:3') || undefined}
          gap={toUnit(((isDesktop || isLaptop) && 32) || 40)}>
          <Layout.FlexItem>
            <Layout.Flex cols={1}>
              {questionsWithFallback?.map((item, idx) => (
                <Layout.FlexItem
                  key={`FAQ__question--${idx + 1}}`}
                  styles={{
                    borderTop: '1px solid var(--alpha)',
                    '&:nth-last-child(1)': {
                      borderBottom: '1px solid var(--alpha)'
                    }
                  }}>
                  <QuestionAndAnswer
                    name={item.name}
                    answer={item.answer}
                    isOpenDefault={idx === 0}
                  />
                </Layout.FlexItem>
              ))}
            </Layout.Flex>
          </Layout.FlexItem>
          <Layout.FlexItem>
            <Shared.LeadForm
              cost={cost}
              id={IDS.formFAQ}
              formName={formName}
              name
              phoneNumber
              email
              promocode
              styleVariant='alpha'
              heading={insertWhiteSpacesAfterPrepositions(formHeading)}
              colorsVariant='beta'
            />
          </Layout.FlexItem>
        </Layout.Flex>
      </Container>
    </Section>
  )
}

export default FAQ
