import {
  StyledButtonCources,
  StyledSvgButtonCources,
  StyledPathButtonCources
} from './styled'

type TProps = {
  accentColor: string
}

const ButtonCources = ({ accentColor }: TProps) => (
  <StyledButtonCources accentColor={accentColor}>
    <StyledSvgButtonCources
      viewBox='0 0 8 14'
      xmlns='http://www.w3.org/2000/svg'>
      <StyledPathButtonCources d='M1 13L7 7L1 1' />
    </StyledSvgButtonCources>
  </StyledButtonCources>
)

export default ButtonCources
