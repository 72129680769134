import * as React from 'react'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { createSvgIcon } from '@mui/material/utils'

const CheckedIcon = createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='#3A52EE' />
    <path
      d='M17.3337 8L10.0003 15.3333L6.66699 12'
      stroke='#3A52EE'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'CheckedIcon'
)

const CheckedIconMobile = createSvgIcon(
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='15' height='15' rx='3.5' stroke='#3A52EE' />
    <path
      d='M11.5556 5.33325L6.66668 10.2221L4.44446 7.99992'
      stroke='#3A52EE'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'CheckedIconMobile'
)

const Icon = createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='23' height='23' rx='3.5' stroke='#C4C4C4' />
  </svg>,
  'Icon'
)

const IconMobile = createSvgIcon(
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='15' height='15' rx='3.5' stroke='#C4C4C4' />
  </svg>,
  'IconMobile'
)

export const CustomCheckbox = (
  // eslint-disable-next-line react/require-default-props
  props: CheckboxProps & { isMobile?: boolean }
) => {
  const { isMobile } = props
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' }
      }}
      disableRipple
      color='default'
      checkedIcon={isMobile ? <CheckedIconMobile /> : <CheckedIcon />}
      icon={isMobile ? <IconMobile /> : <Icon />}
      {...props}
    />
  )
}
