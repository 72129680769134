export const insertWhiteSpacesAfterPrepositions = (str: string) => {
  if (!str) return str

  // TODO: improve this
  // inserts white space
  return str
    .replace(' на ', ' на ')
    .replace(' На ', ' На ')
    .replace(' прямо ', ' прямо ')
    .replace(' Прямо ', ' Прямо')
    .replace(' и ', ' и ')
    .replace(' И ', ' И ')
    .replace(' у ', ' у ')
    .replace(' У ', ' У ')
    .replace(' чем ', ' чем ')
    .replace(' Чем ', ' Чем ')
    .replace(' с ', ' с ')
    .replace(' С ', ' С ')
    .replace(' без ', ' без ')
    .replace(' Без ', ' Без ')
    .replace(' по ', ' по ')
    .replace(' По ', ' По ')
    .replace(' от ', ' от ')
    .replace(' От ', ' От ')
    .replace(' для ', ' для ')
    .replace(' Для ', ' Для ')
    .replace(' в ', ' в ')
    .replace(' В ', ' В ')
    .replace(' о ', ' о ')
    .replace(' О ', ' О ')
    .replace('О ', 'О ')
}

export default insertWhiteSpacesAfterPrepositions
