// TODO: add Normalize like reset to styled-jsx
import {
  StyledElement,
  Div,
  Span,
  Header,
  Main,
  Footer,
  Section,
  Ul,
  Ol,
  Li,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  A,
  Button,
  Form,
  Input,
  Label,
  Select,
  Option
} from './widgets'

export const StyledJSX = {
  Element: StyledElement,
  Div,
  Span,
  Header,
  Main,
  Footer,
  Section,
  Ul,
  Ol,
  Li,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  A,
  Button,
  Form,
  Input,
  Label,
  Select,
  Option
}

export default StyledJSX
