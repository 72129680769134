import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconVKFooterProps } from './types'

const IconVKFooter = ({ ...props }: TIconVKFooterProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      // transform:
      //   (direction === 'upRight' && 'rotate(45deg)') ||
      //   (direction === 'right' && 'rotate(90deg)') ||
      //   (direction === 'downRight' && 'rotate(135deg)') ||
      //   (direction === 'down' && 'rotate(180deg)') ||
      //   (direction === 'downLeft' && 'rotate(225deg)') ||
      //   (direction === 'left' && 'rotate(270deg)') ||
      //   (direction === 'upLeft' && 'rotate(315deg)') ||
      // * default direction is up
    },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='15'
        viewBox='0 0 24 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.7425 14.4244H13.177C13.177 14.4244 13.6102 14.3745 13.8317 14.1254C14.0353 13.8964 14.0288 13.4668 14.0288 13.4668C14.0288 13.4668 14.0008 11.455 14.894 11.1587C15.7748 10.8667 16.9057 13.103 18.1042 13.963C19.0106 14.6136 19.6994 14.4712 19.6994 14.4712L22.9045 14.4244C22.9045 14.4244 24.5811 14.3163 23.7861 12.9385C23.721 12.8259 23.3229 11.9193 21.4029 10.0566C19.393 8.10697 19.6625 8.4224 22.0833 5.05002C23.5576 2.99624 24.1469 1.74248 23.9628 1.20552C23.7873 0.693905 22.7029 0.829051 22.7029 0.829051L19.0942 0.85237C19.0942 0.85237 18.8265 0.814301 18.6282 0.938317C18.4342 1.05959 18.3098 1.34296 18.3098 1.34296C18.3098 1.34296 17.7384 2.93212 16.9769 4.28384C15.37 7.13568 14.7274 7.28664 14.4647 7.10927C13.8536 6.6965 14.0063 5.45139 14.0063 4.56659C14.0063 1.80272 14.4074 0.650358 13.2253 0.352066C12.8331 0.253135 12.5441 0.187682 11.5409 0.176994C10.2532 0.163303 9.16355 0.181146 8.54644 0.497105C8.13588 0.707244 7.81913 1.1754 8.01216 1.20234C8.25073 1.23555 8.79076 1.35471 9.07709 1.76191C9.447 2.28792 9.43407 3.46872 9.43407 3.46872C9.43407 3.46872 9.64662 6.72221 8.93782 7.12623C8.45145 7.40341 7.78414 6.83757 6.3515 4.25019C5.6176 2.92487 5.06328 1.45973 5.06328 1.45973C5.06328 1.45973 4.95654 1.186 4.76589 1.03945C4.53466 0.86191 4.21157 0.805644 4.21157 0.805644L0.78225 0.829051C0.78225 0.829051 0.267571 0.844067 0.0784334 1.07806C-0.0898297 1.28634 0.0649971 1.7166 0.0649971 1.7166C0.0649971 1.7166 2.7496 8.28151 5.78966 11.5898C8.57746 14.6235 11.7425 14.4244 11.7425 14.4244Z'
          fill='white'
        />
      </svg>
    </IconContainer>
  )
}

export default IconVKFooter
