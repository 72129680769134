import {
  Container,
  H2,
  Image,
  P,
  Section,
  toUnit,
  useMedia,
  useTranslations
} from 'fractals'
import { CSSObject } from 'styled-components'
import { Layout, slugify } from 'ui'
import {
  graduate,
  peopleLaughing,
  Rounds,
  TForWhomProps,
  Vector,
  FOR_1_TITLE,
  FOR_1,
  FOR_2_TITLE,
  FOR_2,
  FOR_3_TITLE,
  FOR_3,
  FOR_4_TITLE,
  FOR_4
} from './fractals'

// TODO: ! Fix this section

// TODO: refactor and decompose

const ForWhom = ({ heading, forWhom }: TForWhomProps) => {
  const { isMobile, isLaptop, isDesktop } = useMedia()
  const [for1Title, for1, for2Title, for2, for3Title, for3, for4Title, for4] =
    useTranslations(
      FOR_1_TITLE,
      FOR_1,
      FOR_2_TITLE,
      FOR_2,
      FOR_3_TITLE,
      FOR_3,
      FOR_4_TITLE,
      FOR_4
    )

  const data = {
    head: heading,
    forWhom: (forWhom && forWhom?.length !== 0 && forWhom) || [
      {
        name: for1Title,
        description: for1,
        image: null
      },
      {
        name: for2Title,
        description: for2,
        image: null
      },
      {
        name: for3Title,
        description: for3,
        image: null
      },
      {
        name: for4Title,
        description: for4,
        image: null
      }
    ]
  } as const

  const wrapStls = {
    ...(isMobile && { paddingTop: toUnit(56), paddingBottom: toUnit(49) }),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '*': { boxSizing: 'border-box' }
  } as const

  const H2Stls = {
    ...(isMobile && { marginBottom: toUnit(32) })
  } as const

  const cardsStls = {
    gridTemplate: !isMobile
      ? 'repeat(2, 1fr) / repeat(2, 1fr)'
      : 'auto 1fr / none',
    rowGap: toUnit((isDesktop && 56) || (isMobile && 40) || 32),
    columnGap: !isMobile ? toUnit(32) : undefined
  } as const

  const getCardStls = (item: number) =>
    ({
      position: 'relative',
      display: 'grid',
      gridTemplate: `0fr 1fr / ${
        isDesktop && [1, 2].includes(item) ? '80%' : 'none'
      }`,
      alignItems: 'center',
      gap: toUnit(16),
      overflow: 'hidden',
      maxWidth: toUnit(item === 0 || item === 3 ? 592 : 488),
      minHeight: toUnit((isLaptop && 230) || (isMobile && 214) || 239),
      justifySelf: (isDesktop && (item < 2 ? 'start' : 'end')) || undefined,
      padding: toUnit(isDesktop || isLaptop ? 32 : 24),
      borderRadius: toUnit(16),
      backgroundColor:
        (item === 1 && '#F2F2F2') || (item !== 2 && '#3A52EE') || undefined,
      border: item === 2 ? '1px solid #C4C4C4' : undefined,
      color: item === 0 || item === 3 ? 'white' : undefined
    } as const)

  const h5Stls = {
    position: 'relative',
    fontSize: toUnit(20)
  } as const

  const pStls: CSSObject = {
    position: 'relative',
    ...(isMobile && { fontSize: toUnit(14) })
  } as const

  const vectorStls = {
    position: 'absolute',
    right: isDesktop ? '0' : '-4%',
    top: toUnit(32)
  } as const

  return (
    <Section spacingVariant='delta' id={slugify(data.head)} styles={wrapStls}>
      <Container>
        <H2 variant='beta' styles={H2Stls}>
          {data.head}
        </H2>
        <Layout.Grid styles={cardsStls}>
          {data?.forWhom?.map((item, idx) => (
            <Layout.GridItem
              styles={getCardStls(idx)}
              key={`ForWhom__${item?.name || 'item'}--${idx + 1}`}>
              {(item?.image?.url || idx === 0 || idx === 3) && (
                <Image
                  borderRadiusVariant='alpha'
                  colorVariant='beta'
                  quality={100}
                  src={
                    item?.image?.url || (idx === 3 ? graduate : peopleLaughing)
                  }
                  alt={
                    item?.image?.alternativeText || idx === 3
                      ? 'graduate'
                      : 'people-laughing'
                  }
                  fill
                  containerProps={{
                    styles: {
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      '& img': {
                        objectFit: 'cover'
                      }
                    }
                  }}
                />
              )}
              {idx === 1 && <Vector styles={vectorStls} />}
              {idx === 2 && <Rounds />}
              <P variant='epsilon' styles={h5Stls}>
                {item?.name}
              </P>
              <P variant='beta' styles={pStls}>
                {item?.description}
              </P>
            </Layout.GridItem>
          ))}
        </Layout.Grid>
      </Container>
    </Section>
  )
}

export default ForWhom
