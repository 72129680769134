import * as React from 'react'
import { Image } from 'fractals'
import whatsapp from './whatsapp.png'

const IconWhatsapp = () => {
  return (
    <Image
      id='whatsapp__icon'
      colorVariant='transparent'
      src={whatsapp.src || '/'}
      width={whatsapp.width}
      height={whatsapp.height}
      alt={whatsapp.blurDataURL || `whatsapp__icon`}
      containerProps={{
        styles: {
          '& img': {
            position: 'relative',
            objectFit: 'cover'
          }
        }
      }}
    />
  )
}

export default IconWhatsapp
