import { TGetVideoDataProps } from '@/modules/shared/widgets/Video/fractals/api/types'
import {
  fetchFacultyVideos,
  fetchCategoryVideos,
  normalizeVideoData
} from './utils'
import { parseGeneralSlug } from '@/fractals/utils'
import { Shared } from '@/modules/shared'

export const getProgramsVideo = async ({ context }: TGetVideoDataProps) => {
  try {
    const { currentCategorySlug, currentFacultySlug } = parseGeneralSlug(
      context?.params?.general?.toString() || null
    )

    let videoData = null
    if (currentFacultySlug) videoData = await fetchFacultyVideos({ context })
    if (currentCategorySlug && !currentFacultySlug)
      videoData = await fetchCategoryVideos({ context })

    const generalVideoData = await Shared.getVideoData({ context })

    const videoDataNormalized = normalizeVideoData({
      generalVideoData,
      videoData,
      context
    })

    return videoDataNormalized
  } catch (e) {
    console.log(e)
    return null
  }
}

export default getProgramsVideo
