import { calculateRuCase, StyledJSX } from 'ui'
import { useRouter } from 'next/router'
import { /* TCloudinaryImageData, */ toUnit, useMedia } from 'fractals'
import { StyledCard } from './styled'
import { BackgroundImage, ButtonCources, Circle, Link } from '../../atoms'
import defaultImage from './card.jpg'

// TODO: Подограть типы под api
type TProps = {
  accentColor: string
  nameCource: string
  countCources: number
  backgroundImage: any // tfix this
  route: string
}

// TODO: Улучшить компонент
const Card = ({
  accentColor,
  nameCource,
  countCources,
  backgroundImage,
  route = '/'
}: TProps) => {
  const { isDesktop, isLaptop } = useMedia()
  const { locale } = useRouter()

  return (
    <Link href={route}>
      <StyledCard accentColor={accentColor || '#FB5449'}>
        <BackgroundImage
          url={backgroundImage || defaultImage}
          width={backgroundImage?.width || defaultImage.width}
          height={backgroundImage?.height || defaultImage.height}
          alternativeText={backgroundImage?.alternativeText || nameCource}
          fill
        />
        {/* TODO: Заменить на компонент типографию */}
        <StyledJSX.Element
          as='h3'
          styles={{
            position: 'relative',
            margin: '0',
            fontSize: `${
              ((isDesktop || isLaptop) && toUnit(28)) || toUnit(20)
            }}`,
            fontWeight: '400',
            lineHeight: '120%',
            color: '#ffffff',
            textTransform: 'uppercase'
          }}>
          {nameCource || 'менеджмент'}
        </StyledJSX.Element>
        {(countCources !== 0 && (
          <StyledJSX.Element
            as='div'
            styles={{
              position: 'relative',
              display: 'inline-block',
              marginTop: `${toUnit(16)}`,
              paddingTop: `${toUnit(8)}`,
              paddingBottom: `${toUnit(8)}`,
              paddingLeft: `${toUnit(16)}`,
              paddingRight: `${toUnit(16)}`,
              borderRadius: `${toUnit(8)}`,
              backgroundColor: '#ffffff',
              color: '#000000',
              fontSize: `${
                ((isDesktop || isLaptop) && toUnit(12)) || toUnit(10)
              }}`,
              lineHeight: '120%'
            }}>
            {`${countCources} ${calculateRuCase('курс', countCources, locale)}`}
          </StyledJSX.Element>
        )) ||
          null}
        <Circle />
        {/* TODO: make sure there is no color glow at the right bottom corner */}
        <ButtonCources accentColor={accentColor || '#FB5449'} />
      </StyledCard>
    </Link>
  )
}

export default Card
