import * as React from 'react'
import { IconContainer, toUnit } from 'fractals'
import { TIconTelegramFooterProps } from './types'

const IconTelegramFooter = ({ ...props }: TIconTelegramFooterProps) => {
  const styles = {
    width: toUnit(24),
    height: toUnit(24),
    '& svg': {
      // transform:
      //   (direction === 'upRight' && 'rotate(45deg)') ||
      //   (direction === 'right' && 'rotate(90deg)') ||
      //   (direction === 'downRight' && 'rotate(135deg)') ||
      //   (direction === 'down' && 'rotate(180deg)') ||
      //   (direction === 'downLeft' && 'rotate(225deg)') ||
      //   (direction === 'left' && 'rotate(270deg)') ||
      //   (direction === 'upLeft' && 'rotate(315deg)') ||
      // * default direction is up
    },
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='24'
        height='20'
        viewBox='0 0 24 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.03739 8.29332C8.23887 5.59144 12.3742 3.81019 14.4433 2.94958C20.351 0.492359 21.5786 0.0655145 22.3787 0.0514198C22.5547 0.0483198 22.9481 0.0919322 23.203 0.298746C23.4182 0.473375 23.4774 0.709274 23.5058 0.874841C23.5341 1.04041 23.5694 1.41758 23.5413 1.71228C23.2212 5.07603 21.8359 13.2389 21.1312 17.0064C20.833 18.6005 20.2458 19.1351 19.6774 19.1874C18.442 19.301 17.504 18.371 16.3075 17.5866C14.4352 16.3593 13.3774 15.5953 11.5601 14.3977C9.45976 13.0136 10.8213 12.2529 12.0183 11.0097C12.3315 10.6844 17.7745 5.73353 17.8799 5.28441C17.893 5.22824 17.9053 5.01887 17.7809 4.90831C17.6565 4.79775 17.4729 4.83556 17.3404 4.86563C17.1527 4.90825 14.1616 6.88519 8.3674 10.7965C7.51841 11.3794 6.74943 11.6635 6.06044 11.6486C5.30089 11.6322 3.83981 11.2191 2.75365 10.8661C1.42144 10.433 0.362616 10.2041 0.454816 9.46861C0.502839 9.08554 1.03036 8.69378 2.03739 8.29332Z'
          fill='white'
        />
      </svg>
    </IconContainer>
  )
}

export default IconTelegramFooter
