import * as React from 'react'
import { IconContainer } from 'fractals'
import styled, { keyframes } from 'styled-components'
import { TIconScrollProps } from './types'

export const IconScroll = ({ colorVariant, ...props }: TIconScrollProps) => {
  const styles = {
    width: '58px',
    height: '58px',
    ...props?.styles
  } as const

  const StyledSvg = styled.svg`
    circle {
      transition: fill 0.3s ease-in-out;
    }

    &:hover {
      circle {
        fill: gray;
      }
    }
  `

  return (
    <IconContainer styles={styles} {...props}>
      <StyledSvg
        xmlns='http://www.w3.org/2000/svg'
        width='58'
        height='58'
        viewBox='0 0 114 114'
        fill='none'>
        <circle
          cx='56.5673'
          cy='56.5671'
          r='56'
          transform='rotate(-44.6235 56.5673 56.5671)'
          fill='black'
          fillOpacity='1'
        />
        <line
          x1='41.1921'
          y1='56.228'
          x2='57.703'
          y2='39.9326'
          stroke='white'
          strokeWidth='2'
        />
        <line
          x1='73.0597'
          y1='56.9139'
          x2='57.23'
          y2='40.8747'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M56.6661 41.4291L56.4567 73.298'
          stroke='white'
          strokeWidth='2'
        />
      </StyledSvg>
    </IconContainer>
  )
}

export default IconScroll
