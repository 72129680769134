import * as React from 'react'
import { IconContainer, toUnit, media } from 'fractals'
import { useTheme } from 'styled-components'
import Color from 'color'
import { TIconCheckProps } from './types'

// TODO: apply this component to Hero circles maybe
const IconCircle = ({ colorsVariant, ...props }: TIconCheckProps) => {
  const { colors } = useTheme()

  const styles = {
    ...media('width', toUnit(38), {
      desktop: toUnit(85)
    }),
    ...media('height', toUnit(38), {
      desktop: toUnit(85)
    }),
    ...props?.styles
  } as const

  return (
    <IconContainer {...props} styles={styles}>
      <svg
        width='85'
        height='84'
        viewBox='0 0 85 84'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M42.0109 83.8987C65.2128 83.8987 84.0217 65.1173 84.0217 41.9493C84.0217 18.7814 65.2128 0 42.0109 0C18.8089 0 0 18.7814 0 41.9493C0 65.1173 18.8089 83.8987 42.0109 83.8987Z'
          fill={Color(colors[colorsVariant]).rgb().string()}
        />
      </svg>
    </IconContainer>
  )
}

export default IconCircle
