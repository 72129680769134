import { FilterAction, FilterState, SortingType } from './types'
import { TDiploma, TProgram } from '../../../api/getProgramsData/types'
import { initialQueryState } from './constants'
import { TTag } from '@/modules/journal-page/widgets/Journal/fractals/api/getJournalData/types'

export const getDurationInMonth = (program: TProgram) => {
  const { studyDurationMonths: months, studyDurationYears: years } =
    program.cost

  return Number(months) + Number(years) * 12
}

export const getLimits = (programs?: TProgram[]) => {
  let minPrice = Infinity
  let maxPrice = -Infinity
  let minDuration = Infinity
  let maxDuration = -Infinity

  programs?.forEach(program => {
    const { price } = program.cost

    if (price !== null && price < minPrice) {
      minPrice = price
    }
    if (price !== null && price > maxPrice) {
      maxPrice = price
    }

    const durationInMonth = getDurationInMonth(program)

    if (durationInMonth < minDuration) {
      minDuration = durationInMonth
    }
    if (durationInMonth > maxDuration) {
      maxDuration = durationInMonth
    }
  })

  return {
    minPrice,
    maxPrice,
    minDuration,
    maxDuration
  }
}

export const reducer = (state: FilterState, action: FilterAction) => {
  switch (action.type) {
    case 'setWithEmployment':
      return { ...state, withEmployment: !state.withEmployment }

    case 'setLevel': {
      if (state.level?.includes(action.payload)) {
        return {
          ...state,
          level: state.level.filter(level => level !== action.payload)
        }
      }
      return { ...state, level: [...state.level, action.payload] }
    }

    case 'setDiplomas': {
      if (state.diplomas?.includes(action.payload)) {
        return {
          ...state,
          diplomas: state.diplomas.filter(diploma => diploma !== action.payload)
        }
      }
      return { ...state, diplomas: [...state.diplomas, action.payload] }
    }

    case 'setTags': {
      if (state.tags?.includes(action.payload)) {
        return {
          ...state,
          tags: state.tags.filter(tag => tag !== action.payload)
        }
      }
      return { ...state, tags: [...state.tags, action.payload] }
    }

    case 'setMaxDuration': {
      return {
        ...state,
        duration: {
          ...state.duration,
          max: action.payload
        }
      }
    }

    case 'setMinPrice': {
      return {
        ...state,
        price: {
          ...state.duration,
          min: action.payload
        }
      }
    }

    case 'setMaxPrice': {
      return {
        ...state,
        price: {
          ...state.duration,
          max: action.payload
        }
      }
    }

    case 'setPrice': {
      return {
        ...state,
        price: {
          min: action.payload[0],
          max: action.payload[1]
        }
      }
    }

    case 'resetFilters':
      return initialQueryState
    default:
      return state
  }
}

export const findCommonElements = (
  elements: TDiploma[] | TTag[],
  filter: string[]
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return elements.filter(
    (element: TDiploma | TTag) => element.name && filter.includes(element.name)
  )
}
export const compareFunction =
  (type: SortingType['type']) => (a: TProgram, b: TProgram) => {
    if (type === 'price') {
      return Number(a.cost.price) - Number(b.cost.price)
    }
    if (type === 'duration') {
      return getDurationInMonth(a) - getDurationInMonth(b)
    }
    if (type === 'alphabet' && a.name && b.name) {
      return a.name.localeCompare(b.name)
    }
    return 0
  }
