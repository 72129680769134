import { StyledCircle, StyledCirclesWrapper } from './styled'

const Circle = () => (
  <StyledCirclesWrapper>
    <StyledCircle left />
    <StyledCircle right />
  </StyledCirclesWrapper>
)

export default Circle
