import { forwardRef } from 'react'
import styled from 'styled-components'
import { TStyledElementProps, TStyledElementStyledProps } from 'ui'
import { ROOT_ELEMENT } from './constants'

// TODO: figure out how to make the best type. Now it works like this but you need to add a forwardRef to the next component which uses this and changes the element type via prop as

const StyledElement = styled(
  forwardRef<HTMLSpanElement, TStyledElementProps<typeof ROOT_ELEMENT>>(
    ({ children, styles, ...props }, ref) => (
      <ROOT_ELEMENT ref={ref} {...props}>
        {children}
      </ROOT_ELEMENT>
    )
  )
)<TStyledElementStyledProps>(({ styles }) => ({
  /* DEFAULT_STYLES */
  ...styles
}))

export default StyledElement
