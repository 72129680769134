import { TGetSearchProgramsDataProps } from './types'
import { fetchProgramsList, normalizeProgramsData } from './utils'

export const getSearchProgramsData = async ({
  context
}: TGetSearchProgramsDataProps) => {
  const programsData = await fetchProgramsList({ context })

  const programsDataNormalized = normalizeProgramsData({ programsData })

  return programsDataNormalized
}

export default getSearchProgramsData
