import {
  useTranslations,
  Section,
  Container,
  H2,
  toUnit,
  Ul,
  Li,
  P,
  useMedia,
  Div,
  Image,
  multiMedia
} from 'fractals'
import {
  insertWhiteSpacesAfterPrepositions,
  Layout,
  mdToJsx,
  slugify
} from 'ui'
import Color from 'color'
import { Fragment, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  TWhyUsProps,
  TITLE,
  TESTIMONIAL_1,
  TESTIMONIAL_2,
  TESTIMONIAL_3,
  TESTIMONIAL_4,
  TESTIMONIAL_5,
  TESTIMONIAL_6,
  TESTIMONIAL_7,
  TESTIMONIAL_8,
  ImageWithDecorations
} from './fractals'
import * as styles from './styles'

const WhyUs = ({ whyUs, colorsVariant, colorVariant }: TWhyUsProps) => {
  const [title] = useTranslations(TITLE)
  const [topic, setTopic] = useState(0)

  const testimonialsDefault = useTranslations(
    TESTIMONIAL_1,
    TESTIMONIAL_2,
    TESTIMONIAL_3,
    TESTIMONIAL_4,
    TESTIMONIAL_5,
    TESTIMONIAL_6,
    TESTIMONIAL_7,
    TESTIMONIAL_8
  )

  const testimonials =
    (whyUs?.whyUs && whyUs?.whyUs?.length !== 0 && whyUs.whyUs) || whyUs?.whyUs

  const { isMobile, isTablet, isLaptop, isDesktop } = useMedia()

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <Section
      id={slugify(title)}
      spacingVariant='delta'
      colorsVariant={colorsVariant || 'delta'}
      styles={{
        overflowX: 'hidden'
      }}>
      <Container
        styles={{
          boxSizing: 'border-box'
        }}>
        <H2 variant='whyUsTitle'>{title}</H2>
        {(isLaptop || isDesktop) && (
          <Div
            styles={{
              ...multiMedia({
                display: 'flex',
                tablet: { display: 'none' },
                phone: { display: 'none' }
              }),
              justifyContent: 'space-between'
            }}>
            <Layout.FlexItem
              styles={{
                width: '60%'
              }}>
              {testimonials?.map((testimonial, idx, array) => (
                <Fragment key={`WhyUs__testimonials__testimonial--${idx + 1}`}>
                  <Div
                    styles={{
                      ...styles.whyUsStyles.testimonial,
                      margin: (idx === 0 && '0 0 24px 0') || '24px 0'
                    }}
                  />
                  <P
                    onClick={() => setTopic(idx)}
                    styles={styles.whyUsStyles.p}
                    variant='whyUsName'>
                    {testimonial.name}
                  </P>
                  {idx === array.length - 1 && (
                    <Div styles={styles.whyUsStyles.line} />
                  )}
                </Fragment>
              ))}
            </Layout.FlexItem>
            <Layout.FlexItem styles={styles.whyUsStyles.animateBlock}>
              <AnimatePresence>
                <motion.div
                  className={`${isLoaded ? '' : 'before-load-opacity-1'}`}
                  key={testimonials?.[topic]?.name}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{
                    transform: 'translateY(-25%)',
                    position: 'absolute',
                    top: '25%',
                    bottom: '25%'
                  }}
                  transition={{
                    duration: 1,
                    when: 'beforeChildren',
                    type: 'spring'
                  }}>
                  <Div styles={styles.whyUsStyles.imageBlock}>
                    <Image
                      colorVariant='beta'
                      src={testimonials?.[topic]?.image?.url || '/'}
                      fill
                      alt={
                        testimonials?.[topic]?.image?.alternativeText ||
                        `whyUs__image`
                      }
                      borderRadiusVariant='alpha'
                      containerProps={{
                        styles: {
                          '& img': {
                            position: 'relative',
                            objectFit: 'cover'
                          }
                        }
                      }}
                    />
                  </Div>
                  <Div
                    styles={styles.whyUsStyles.description}
                    dangerouslySetInnerHTML={{
                      __html: testimonials?.[topic]?.description ?? ''
                    }}
                  />
                </motion.div>
              </AnimatePresence>
            </Layout.FlexItem>
          </Div>
        )}
        {(isMobile || isTablet) && (
          <Div
            styles={styles.whyUsStyles.slider}
            className={`${isLoaded ? '' : 'before-load-display-flex'}`}>
            {testimonials?.map((testimonial, idx) => (
              <Div
                styles={styles.whyUsStyles.slide}
                key={`WhyUs__testimonials__testimonial--${idx + 1}`}>
                <Div styles={styles.whyUsStyles.text}>
                  <P
                    styles={{ ...styles.whyUsStyles.p, color: 'var(--beta)' }}
                    variant='whyUsName'>
                    {testimonial.name}
                  </P>
                  <Div
                    styles={styles.whyUsStyles.description}
                    dangerouslySetInnerHTML={{
                      __html: testimonial?.description ?? ''
                    }}
                  />
                </Div>
                <Div styles={styles.whyUsStyles.imageBlock}>
                  <Image
                    colorVariant='beta'
                    src={testimonial?.image?.url || '/'}
                    fill
                    alt={testimonial?.image?.alternativeText || `whyUs__image`}
                    borderRadiusVariant='alpha'
                    containerProps={{
                      styles: {
                        '& img': {
                          position: 'relative',
                          objectFit: 'cover'
                        }
                      }
                    }}
                  />
                </Div>
              </Div>
            ))}
          </Div>
        )}
      </Container>
    </Section>
  )
}

export default WhyUs
