import { sortBasedOnKey } from 'fractals'
import { fetchCategories } from './fetchCategories'

export const normalizeFacultiesData = ({
  facultiesData
}: {
  facultiesData: Awaited<ReturnType<typeof fetchCategories>>
}) => {
  return { faculties: facultiesData }
}

export default normalizeFacultiesData
