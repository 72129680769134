import Color from 'color'
import { DefaultTheme } from 'styled-components'
import { TThemeColors } from 'fractals'

const ROOT_FONT_SIZE_PX = 16

const BRAKEPOINTS = {
  xs: 0,
  sm: 240,
  md: 480,
  lg: 576,
  xl: 768,
  x2: 1024,
  x3: 1280,
  x4: 1440,
  x5: 1680,
  x6: 1920
} as const

const MEDIA = {
  phone: `@media only screen and (max-width: ${BRAKEPOINTS.md}px)`,
  tablet: `@media only screen and (min-width: ${
    BRAKEPOINTS.md + 1
  }px) and (max-width: ${BRAKEPOINTS.xl}px)`,
  laptop: `@media only screen and (min-width: ${
    BRAKEPOINTS.xl + 1
  }px) and (max-width: ${BRAKEPOINTS.x3}px)`,
  desktop: `@media only screen and (min-width: ${BRAKEPOINTS.x3 + 1}px)`
} as const

export const THEME_COLORS_DEFAULT = {
  alpha: Color('hsla(0, 0%, 100%, 1)'),
  beta: Color('hsla(232, 84%, 58%, 1)'),
  gamma: Color('hsla(14, 71%, 57%, 1)'),
  delta: Color('hsla(40, 88%, 61%, 1)'),
  epsilon: Color('hsla(158, 39%, 48%, 1)'),
  zeta: Color('hsla(4, 96%, 64%, 1)'),
  eta: Color('hsla(254, 71%, 63%, 1)'),
  theta: Color('hsla(68, 60%, 50%, 1)'),
  iota: Color('hsla(68, 61%, 47%, 1)'),
  // kappa: null,
  // lambda: null,
  // mu: null,
  // nu: null,
  // xi: null,
  // omicron: null,
  // pi: null,
  rho: Color('hsla(0, 0%, 31%, 1)'),
  sigma: Color('hsla(0, 0%, 90%, 1)'),
  tau: Color('hsla(210, 4%, 10%, 1)'),
  upsilon: Color('hsla(0, 0%, 55%, 1)'),
  phi: Color('hsla(0, 0%, 77%, 1)'),
  chi: Color('hsla(0, 0%, 95%, 1)'),
  psi: Color('hsla(0, 0%, 42%, 1)'),
  omega: Color('hsla(0, 0%, 0%, 1)')
} as const

const THEME_COLORS_DARK: TThemeColors = {
  alpha: Color('hsla(0, 0%, 100%, 1)'),
  beta: Color('hsla(232, 84%, 58%, 1)'),
  gamma: Color('hsla(14, 71%, 57%, 1)'),
  delta: Color('hsla(40, 88%, 61%, 1)'),
  epsilon: Color('hsla(158, 39%, 48%, 1)'),
  zeta: Color('hsla(4, 96%, 64%, 1)'),
  eta: Color('hsla(254, 71%, 63%, 1)'),
  theta: Color('hsla(68, 60%, 50%, 1)'),
  iota: Color('hsla(68, 61%, 47%, 1)'),
  // kappa: null,
  // lambda: null,
  // mu: null,
  // nu: null,
  // xi: null,
  // omicron: null,
  // pi: null,
  rho: Color('hsla(0, 0%, 31%, 1)'),
  sigma: Color('hsla(0, 0%, 90%, 1)'),
  tau: Color('hsla(210, 4%, 10%, 1)'),
  upsilon: Color('hsla(0, 0%, 55%, 1)'),
  phi: Color('hsla(0, 0%, 77%, 1)'),
  chi: Color('hsla(0, 0%, 95%, 1)'),
  psi: Color('hsla(0, 0%, 42%, 1)'),
  omega: Color('hsla(0, 0%, 0%, 1)')
} as const

// export const THEME: DefaultTheme = {
export const THEME = {
  rootFontSize: ROOT_FONT_SIZE_PX,
  media: MEDIA,
  brakepoints: BRAKEPOINTS,
  colors: {
    light: THEME_COLORS_DEFAULT,
    dark: THEME_COLORS_DARK
  }
} as const

// /////////////////////////////////////////////////////////////////

// const breakPoints = {
//   phone: {
//     px: 480
//   },
//   tablet: {
//     px: 768
//   },
//   laptop: {
//     px: 1200
//   }
// } as const

// // todo: handle @include media sass mixin in a ts way
// const customMediaQuery = <T>(maxWidth: T) =>
//   `@media (max-width: ${maxWidth}px)` as const

// const device = {
//   phone: `(max-width: ${breakPoints.phone.px}px)`,
//   tablet: `(min-width: ${breakPoints.phone.px + 1}px) and (max-width: ${
//     breakPoints.tablet.px
//   }px)`,
//   laptop: `(min-width: ${breakPoints.tablet.px + 1}px) and (max-width: ${
//     breakPoints.laptop.px
//   }px)`,
//   desktop: `(min-width: ${breakPoints.laptop.px + 1}px)`
// } as const

// export const media = (keys: Array<keyof typeof device>) => {
//   const mediaQueries = keys.map(key => device[key])
//   return `screen and ${mediaQueries.join(' and ')}`
// }

// const ui = {
//   breakPoints,
//   media
// } as const

// export default ui
