import { TGetFooterDataProps } from './types'
import {
  fetchCategories,
  normalizeCategoriesData,
  fetchFaculties,
  normalizeFacultiesData
} from './utils'

export const getFooterData = async ({ context }: TGetFooterDataProps) => {
  try {
    const categoriesData = await fetchCategories({ context })

    const categoriesDataNormalized = normalizeCategoriesData({ categoriesData })

    const facultiesData = await fetchFaculties({ context })

    const facultiesDataNormalized = normalizeFacultiesData({ facultiesData })

    return { ...categoriesDataNormalized, ...facultiesDataNormalized }
  } catch (e) {
    console.log('%cgetFooterData.ts line:13 e', 'color: #007acc;', e)
  }
  return null
}

export default getFooterData
