export {
  TITLE_LICENSE,
  TITLE_DIPLOMAS,
  DIPLOMA_BACHELOR,
  DIPLOMA_MASTER,
  DIPLOMA_RETRAINING,
  DIPLOMA_NAME,
  DIPLOMA_SUPPLEMENT,
  LICENCE_ALT,
  CHECK_LICENCE,
  CHECK_LICENCE_URL,
  GRADUATION_DOCUMENTS_INFO
} from './constants'
export type { TDiplomasProps } from './types'
export { getDiplomasData } from './api'
