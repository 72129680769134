import * as React from 'react'
import { StyledJSX } from 'ui'
import { CSSObject } from 'styled-components'
import { TFlexProps } from './types'
import { ROOT_ELEMENT, COLS } from './constants'

// TODO: refactor this mess, it works but it's not readable
// const getElementLength = (gap: TFlexProps['gap'], cols: TFlexProps['cols']) => {
//   return (
//     (cols !== 1 &&
//       `calc((100%${(gap && ` - ${gap} * ${cols - 1}`) || undefined}) / ${cols}
//       )`) ||
//     '100%'
//   )
// }

const Flex = React.forwardRef<HTMLDivElement, TFlexProps>(
  ({ cols = 'auto', proportion, gap, nowrap, ...props }, ref) => {
    // TODO: figure out better types
    const elLength =
      (typeof cols === 'number' &&
        cols !== 1 &&
        `calc(${
          (gap && `(100% - ${gap} * ${cols - 1})`) || '100%'
        } / ${cols})`) ||
      '100%'

    const colsVariants = {
      gap: gap || undefined,
      '& > *': {
        minWidth: (cols !== 'auto' && '0') || undefined,
        flexBasis:
          (!proportion && typeof cols === 'number' && elLength) || undefined,
        ...((props.styles?.['& > *'] as CSSObject) || {})
      }
    } as const

    // TODO: handle empty gap ruining the layout
    const getColsVariants = () => {
      if (proportion && typeof cols === 'number') {
        const proportionParts = proportion?.split(':').map(item => Number(item))
        const proportionPartsSum = Number(
          proportionParts?.reduce((acc, cur) => acc + cur)
        )

        const howMuchEachColumnShouldTake = proportionParts?.map(
          item => (item / proportionPartsSum) * 100
        )

        const colsVariantsWithProportion = proportionParts?.map((item, idx) => {
          const key = `& > *:nth-child(${idx + 1})`
          return {
            [key]: {
              flexBasis: `calc(${
                howMuchEachColumnShouldTake?.[idx]
              }% - (${gap} * ${cols - 1} / ${cols}))`
            }
          }
        })

        return colsVariantsWithProportion
      }
      return []
    }

    const proportionVariants = getColsVariants()

    // TODO: generalize this
    return (
      <StyledJSX.Element
        ref={ref}
        {...props}
        as={ROOT_ELEMENT}
        styles={{
          ...props.styles,
          flexWrap: (!nowrap && 'wrap') || undefined,
          display: 'flex',
          ...colsVariants,
          ...proportionVariants[0],
          ...proportionVariants[1],
          ...proportionVariants[2],
          ...proportionVariants[3],
          ...proportionVariants[4],
          ...proportionVariants[5],
          ...proportionVariants[6]
        }}
      />
    )
  }
)

export default Flex
