import React from 'react'
import { toUnit } from 'fractals'
import { Div, P, Span } from '@/fractals/components'

export const PlusItem = ({
  content,
  advancedDescriptionBefore
}: {
  content: string
  advancedDescriptionBefore: boolean
}) => {
  return (
    <Div
      styles={{
        display: 'flex',
        margin: '16px 0 0 0'
      }}>
      {/* <> */}
      <Span
        styles={{
          width: '8px',
          minWidth: '8px',
          height: '8px',
          border: '1.5px solid #3A52EE',
          borderRadius: '50%',
          marginRight: toUnit(16),
          marginTop: '0.4rem'
        }}
      />
      <P
        variant='sigma'
        styles={{
          fontSize: '18px'
        }}
        dangerouslySetInnerHTML={{
          __html: content
        }}>
        {null}
      </P>
      {/* </> */}
    </Div>
  )
}

export default PlusItem
