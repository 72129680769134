import { multiMedia } from 'fractals'

export const styles = {
  button: {
    display: 'flex',
    justifyContent: 'center',
    ...multiMedia({
      desktop: {
        minWidth: '280px',
        minHeight: '54px',
        margin: '56px auto 0 auto'
      },
      laptop: {
        minWidth: '208px',
        minHeight: '51px',
        margin: '56px auto 0 auto'
      },
      tablet: {
        minWidth: '304px',
        minHeight: '51px',
        margin: '40px auto 0 auto'
      },
      phone: {
        minWidth: '-webkit-fill-available',
        minHeight: '51px',
        margin: '40px auto 0 auto'
      }
    })
  }
} as const
