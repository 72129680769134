import { multiMedia } from 'fractals'

export const selectStyles = {
  select: {
    border: 'none',
    background: 'transparent',
    display: 'flex',
    bottom: 0,
    // width: 'auto',
    height: '17px',
    width: '100%'
  },

  'select > span': {
    position: 'relative'
  },

  'select > div': {
    position: 'relative'
  },

  flag: {
    width: '25.5px'
  },

  flagsScroll: {
    padding: '16px',
    width: '100%',
    marginLeft: '-16px',
    borderRadius: '17px',
    backgroundColor: 'var(--chi)',
    marginTop: '14px',
    zIndex: 100,
    height: '15rem',
    maxHeight: '15rem'
  }
}
