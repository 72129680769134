import styled from 'styled-components'

import { toUnit } from 'fractals'
import { StyledCirclesWrapper } from '@/modules/home-page/widgets/Faculties/fractals/components/atoms'
import { StyledFlexItem, StyledH3, StyledSpan } from '../../atoms'
import { StyledSpanHoverBlack } from '../../atoms/StyledSpanHoverBlack'

type TStyledCardProps = {
  accentColor?: string
}

export const StyledCard = styled.div.attrs(
  ({ accentColor, ...props }: TStyledCardProps) => ({
    accentColor: accentColor || '#fb5449',
    ...props
  })
)`
  /*
  width: ${toUnit(384)};
  min-height: ${toUnit(267)};
  */

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: ${toUnit(40)}; */
  padding: ${toUnit(24)} ${toUnit(16)};
  border-radius: ${toUnit(16)};
  border-width: ${toUnit(1)};
  border-style: solid;
  border-color: ${'var(--phi)'};
  transition-duration: 200ms;
  height: ${toUnit(263)};
  width: ${toUnit(384)};
  box-sizing: border-box;

  ${StyledCirclesWrapper} {
    display: none;
  }

  @media (max-width: 1440px) {
    width: ${toUnit(384)};
    height: ${toUnit(263)};
  }

  @media (max-width: 768px) {
    width: ${toUnit(304)};
    height: ${toUnit(323)};
  }

  @media (max-width: 680px) {
    width: 100%;
    height: ${toUnit(323)};
  }

  @media (max-width: 376px) {
    min-width: 326px;
    width: 100%;
    height: ${toUnit(263)};
    padding: ${toUnit(24)} ${toUnit(16)};
  }

  &:hover {
    background: linear-gradient(
        to right bottom,
        transparent 50%,
        ${({ accentColor }) => accentColor} 50%
      )
      left top;
    background-size: 200% 200%;
    background-position: 100% 100%;

    ${StyledCirclesWrapper} {
      display: flex;
    }

    ${StyledH3}, ${StyledSpan} {
      color: ${'var(--alpha)'};
    }

    ${StyledSpanHoverBlack} {
      color: ${'var(--omega)'};
    }

    ${StyledFlexItem} {
      background-color: ${'var(--alpha)'};
    }

    path {
      stroke: white;
    }
  }
`
