import { useEffect, useState } from 'react'

const usePrefersReducedMotion = (): boolean => {
  const [reducedMotion, setReducedMotion] = useState(false)

  useEffect(() => {
    const reducedMotionMediaQuery = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    )

    if (reducedMotionMediaQuery?.matches) {
      setReducedMotion(true)
    }

    const handleReduceMotion = () => {
      if (reducedMotionMediaQuery?.matches) {
        setReducedMotion(true)
      } else {
        setReducedMotion(false)
      }
    }

    reducedMotionMediaQuery.addEventListener('change', handleReduceMotion)

    return () =>
      reducedMotionMediaQuery.removeEventListener('change', handleReduceMotion)
  }, [])

  return reducedMotion
}

export default usePrefersReducedMotion
