import { useAt } from '@/fractals/hooks'
import { TBannerProps } from './types'

export const getBannerHeader = ({
  data: faculty,
  category,
  useAtResult
}: TBannerProps & {
  useAtResult: ReturnType<typeof useAt>
}) => {
  const { currentCategorySlug, currentFacultySlug } = useAtResult

  if (!currentFacultySlug && currentCategorySlug && category?.name)
    return category.name

  if (currentFacultySlug && faculty?.name) return faculty.name

  return 'Программы дистанционного обучения от онлайн-института MITM'
}

export const getDescription = ({
  data: faculty,
  category,
  defaultData,
  useAtResult
}: TBannerProps & {
  useAtResult: ReturnType<typeof useAt>
}) => {
  const { currentCategorySlug, currentFacultySlug } = useAtResult

  if (!currentCategorySlug && !currentFacultySlug && defaultData)
    return defaultData.allCategoriesDescription

  if (!currentFacultySlug && currentCategorySlug && defaultData && category) {
    if (category.name === 'Бакалавриат' && defaultData.bachelorDescription)
      return defaultData.bachelorDescription

    if (category.name === 'Магистратура' && defaultData.magisterDescription)
      return defaultData.magisterDescription

    if (category.name === 'Курсы' && defaultData.coursesDescription)
      return defaultData.coursesDescription
  }

  if (currentFacultySlug && faculty && category) {
    if (category.name === 'Бакалавриат' && faculty.descriptionBachelor)
      return faculty.descriptionBachelor

    if (category.name === 'Магистратура' && faculty.descriptionMagister)
      return faculty.descriptionMagister

    if (category.name === 'Курсы' && faculty.descriptionCourses)
      return faculty.descriptionCourses

    return faculty.description
  }

  return defaultData?.allCategoriesDescription ?? ''
}

export const getBannerColor = ({
  data: faculty,
  defaultData,
  useAtResult
}: TBannerProps & {
  useAtResult: ReturnType<typeof useAt>
}) => {
  const { currentFacultySlug } = useAtResult

  if (currentFacultySlug && faculty?.color) return faculty.color

  return defaultData?.color ?? '#3A52EE'
}

export const getBannerImage = ({
  data: faculty,
  defaultData,
  useAtResult
}: TBannerProps & {
  useAtResult: ReturnType<typeof useAt>
}) => {
  const { currentFacultySlug } = useAtResult

  if (currentFacultySlug && faculty) return faculty.facultyImage

  return defaultData?.allCategoriesImage
}
