/* eslint-disable no-console */
// hooks/useYandexEcommerce.ts
import { useEffect } from 'react'
import { ENV } from 'fractals'

export interface YandexEcommerce {
  id?: string | null
  name?: string | null
  price?: number | null
  brand?: string | null
  category?: string | null
}

type EventType = 'detail' | 'purchase' | 'add' | 'remove' // Можно добавить другие типы событий

const useYandexEcommerce = () => {
  useEffect(() => {
    console.log(
      'ENV.ymGoalIdENV.ymGoalIdENV.ymGoalIdENV.ymGoalIdENV.ymGoalId',
      ENV
    )

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
    }
  }, [])

  // Общая функция для отправки событий
  const sendEvent = (
    eventType: EventType,
    product: YandexEcommerce,
    goalId?: number
  ) => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          [eventType]: {
            actionField: {
              goal_id: goalId || ENV.ymGoalId // Используем переданный goalId или значение из ENV
            },
            products: [product]
          }
        }
      })
    } else {
      console.warn('dataLayer is not defined')
    }
  }

  // Функция для события 'detail'
  const sendDetailEvent = (product: YandexEcommerce) => {
    sendEvent('detail', product)
  }

  // Функция для события 'purchase'
  const sendPurchaseEvent = (product: YandexEcommerce) => {
    sendEvent('purchase', product)
  }

  // Можно добавить дополнительные функции для других событий при необходимости

  return {
    sendDetailEvent,
    sendPurchaseEvent
    // Другие функции
  }
}

export default useYandexEcommerce
