import { fetchPrograms } from './fetchPrograms'

export const normalizeProgramsData = ({
  programsData
}: {
  programsData: Awaited<ReturnType<typeof fetchPrograms>>
}) => {
  return programsData?.map(item => ({
    name: item.name,
    whyUs: item.whyUs?.map(item2 => item2.name) || null
  }))?.[0]
}

export default normalizeProgramsData
