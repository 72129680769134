import { PATHS, fetcher, parseGeneralSlug } from 'fractals'
import qs from 'qs'
import { TGetHeroDataProps, TResPrograms } from '../types'

export const fetchPrograms = async ({ context }: TGetHeroDataProps) => {
  const { currentProgramSlug } = parseGeneralSlug(
    context?.params?.general?.toString() || null
  )

  if (!currentProgramSlug) return null

  const query = qs.stringify({
    fields: ['name', 'description', 'slug'],
    populate: {
      category: {
        fields: ['name', 'slug', 'label'],
        populate: {
          diplomas: {
            fields: ['label', 'rank']
          }
        }
      },
      faculty: {
        fields: ['name', 'slug']
      },
      image: {
        fields: ['url', 'width', 'height', 'alternativeText']
      },
      cost: {
        fields: [
          'price',
          'discount',
          'studyDurationMonths',
          'studyDurationYears',
          'discountUntil',
          'closestEnrollment',
          'studyHours'
        ]
      },
      diplomas: {
        fields: ['label', 'rank']
      }
    },
    filters: {
      slug: {
        $eq: currentProgramSlug
      }
    },
    locale: context.locale
  })

  const res = await fetcher<TResPrograms>(
    `${PATHS.back.root.index}${PATHS.back.api.programs.index}?${query}`
  )

  return res?.data || null
}

export default fetchPrograms
