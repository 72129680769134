import { multiMedia } from 'fractals'

export const whyUsStyles = {
  slider: {
    flexDirection: 'row',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    gap: '32px',
    marginRight: '-3rem',
    marginLeft: '-3rem',
    ...multiMedia({
      display: 'flex',
      desktop: { display: 'none' },
      laptop: { display: 'none' }
    })
  },
  slide: {
    '&:first-child': {
      marginLeft: '3rem'
    },
    '&:last-child': {
      marginRight: '3rem'
    },
    minWidth: '80%',
    display: 'flex',
    border: '1.5px solid #cbcbcb',
    borderRadius: '16px',
    padding: '24px',
    boxSizing: 'border-box',
    height: 'fit-content',
    ...multiMedia({
      phone: { flexDirection: 'column', gap: '24px' }
    })
  },
  testimonial: {
    width: '100%',
    height: '1px',
    backgroundColor: '#cbcbcb'
  },
  text: {
    ...multiMedia({
      tablet: { marginRight: '24px' }
    })
  },
  p: {
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--beta)'
    },
    transition: 'all .1s ease-in'
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#cbcbcb',
    margin: '24px 0 0 0'
  },
  animateBlock: {
    position: 'relative',
    ...multiMedia({
      desktop: { width: '384px' },
      laptop: { width: '280px' },
      tablet: { width: '218px' },
      phone: { width: '279px' }
    })
  },
  imageBlock: {
    ...multiMedia({
      desktop: { width: '384px', minWidth: '384px', height: '186px' },
      laptop: { width: '280px', minWidth: '280px', height: '186px' },
      tablet: { width: '218px', minWidth: '218px', height: '208px' },
      phone: { width: 'auto', minWidth: '279px', height: '160px' }
    }),
    position: 'relative'
  },
  description: {
    ...multiMedia({
      marginTop: '24px',
      phone: { marginTop: '16px' }
    }),
    '& > *': {
      lineHeight: '120%',
      fontWeight: 400,
      margin: 0,
      ...multiMedia({
        fontSize: '14px',
        desktop: { fontSize: '16px' }
      })
    }
  }
} as const
