import { Div } from 'fractals'
import { CSSObject } from 'styled-components'
import { repeatJSX } from 'ui'

type Props = {
  styles: CSSObject
}

export const Vector = ({ styles }: Partial<Props>) => (
  <Div styles={{ ...styles, display: 'flex', flexDirection: 'column' }}>
    {repeatJSX(
      2,
      <svg
        width='89'
        height='87'
        viewBox='0 0 89 87'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M81.5062 87C77.2247 87 73.1218 85.284 70.1152 82.2358L2.43233 13.6179C-2.55413 8.56255 1.02706 0 8.12787 0H71.4824C75.7639 0 79.8668 1.71599 82.8735 4.76417L150.556 73.3821C155.543 78.4374 151.962 87 144.861 87H81.5062Z'
          fill='#F1AE2B'
        />
      </svg>
    )}
  </Div>
)
