import { FC } from 'react'
import Tippy from '@tippyjs/react/headless'
import { CategoryTooltipProps } from './fractals'
import { StyledSvg } from '../../molecules/CategoryInfo/fractals/styles'

const CategoryTooltip: FC<CategoryTooltipProps> = ({
  color = 'var(--phi)',
  hoverColor = 'var(--beta)',
  content,
  ...props
}) => {
  return (
    <Tippy
      render={attrs => (
        <div
          className='box'
          tabIndex={-1}
          {...attrs}
          style={{
            backgroundColor: 'var(--chi)',
            borderRadius: '16px',
            color: 'var(--omega)',
            padding: '16px',
            boxSizing: 'border-box',
            maxWidth: '344px',
            zIndex: 150
          }}>
          {content}
        </div>
      )}>
      <StyledSvg
        hoverColor={hoverColor}
        {...props}
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_7153_3060)'>
          <path
            d='M7.99967 15.2806C11.6816 15.2806 14.6663 12.2958 14.6663 8.61393C14.6663 4.93203 11.6816 1.94727 7.99967 1.94727C4.31778 1.94727 1.33301 4.93203 1.33301 8.61393C1.33301 12.2958 4.31778 15.2806 7.99967 15.2806Z'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M8 5.94727V8.61393'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M8 11.2793H8.00667'
            stroke={color}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_7153_3060'>
            <rect
              width='16'
              height='16'
              fill='white'
              transform='translate(0 0.613281)'
            />
          </clipPath>
        </defs>
      </StyledSvg>
    </Tippy>
  )
}

export default CategoryTooltip
