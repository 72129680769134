import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Нужна программа для сотрудников компании?',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const DESCRIPTION = {
  [LOCALES.en]: '',
  [LOCALES.ru]:
    'Если нужно организовать обучение своих сотрудников – оставьте заявку. Мы подготовили для Вас индивидуальное предложение',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}

export const BUTTON = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Оставить заявку',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
}
