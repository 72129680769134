import React, { useState } from 'react'
import {
  VKShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from 'react-share'
import { PATHS, multiMedia, toUnit } from 'fractals'
import {
  Div,
  IconVK,
  IconTelegram,
  IconWhatsapp,
  IconLink,
  P,
  IconArrow
} from '@/fractals/components'
import { TSocialProps } from './types'

export const Social = ({
  isLeftSide,
  isBottom,
  journalSlug,
  facultySlug,
  categorySlug,
  tagSlug
}: TSocialProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const urlPage = `${PATHS.front.root.index}/journal--${
    facultySlug || categorySlug || tagSlug
  }--${journalSlug}`

  const handleClipboardLink = () => {
    navigator.clipboard.writeText(urlPage).then(
      () => alert('Ссылка скопирована в буфер обмена.'),
      err => alert('Произошла ошибка... Повторите еще раз.')
    )
  }

  const toggleVisibility = () => {
    setIsVisible(prev => !prev)
  }

  return (
    <Div
      styles={{
        marginLeft: isLeftSide ? '' : 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: toUnit(16),
        ...multiMedia({
          phone: {
            marginBottom: '40px'
          }
        })
      }}>
      <Div
        onClick={toggleVisibility}
        styles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: toUnit(8),
          '&:hover': {
            cursor: 'pointer'
          }
        }}>
        <P
          styles={{
            fontSize: '14px'
          }}
          variant='eta'>
          Поделиться
        </P>
        <IconArrow direction='firefly' />
      </Div>

      {isVisible && (
        <Div
          onClick={toggleVisibility}
          styles={{
            position: 'absolute',
            backgroundColor: 'transparent',
            ...multiMedia({
              phone: {
                ...(!isBottom && {
                  display: 'flex'
                })
              }
            }),
            inset: 0,
            zIndex: 100,
            height: '50rem',
            marginTop: 'auto',
            marginBottom: 'auto'
          }}>
          <Div
            styles={{
              ...multiMedia({
                margin: isBottom ? '14% auto 0 2%' : '24% 0 0 auto',
                phone: {
                  ...(!isBottom && {
                    alignSelf: 'flex-end'
                  }),
                  ...(isBottom && {
                    margin: '52% auto 0 2%'
                  })
                },
                laptop: {
                  ...(isBottom && {
                    margin: '22% auto 0 2%'
                  })
                },
                tablet: {
                  ...(isBottom && {
                    margin: '28% auto 0 2%'
                  })
                }
              }),
              boxShadow: '0px 0px 14px rgba(230, 230, 230, 0.5)',
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
              width: 'max-content',
              height: 'auto',
              padding: toUnit(16),
              display: 'flex',
              flexDirection: 'column',
              gap: toUnit(16),
              '& div > *': {
                display: 'flex',
                flexDirection: 'row',
                gap: toUnit(8)
              },
              '& div': {
                display: 'flex',
                flexDirection: 'row',
                gap: toUnit(8)
              }
            }}>
            <Div
              styles={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <VKShareButton url={`${urlPage}`}>
                <IconVK />
                <P
                  styles={{
                    fontSize: '13px'
                  }}
                  variant='beta'>
                  Vkontakte
                </P>
              </VKShareButton>
            </Div>

            <Div
              styles={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <TelegramShareButton url={`${urlPage}`}>
                <IconTelegram />

                <P
                  styles={{
                    fontSize: '13px'
                  }}
                  variant='beta'>
                  Telegram
                </P>
              </TelegramShareButton>
            </Div>
            <Div
              styles={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <WhatsappShareButton url={`${urlPage}`}>
                <IconWhatsapp />

                <P
                  styles={{
                    fontSize: '13px'
                  }}
                  variant='beta'>{`What's App`}</P>
              </WhatsappShareButton>
            </Div>

            <Div
              onClick={handleClipboardLink}
              styles={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <IconLink />
              <P
                styles={{
                  fontSize: '13px'
                }}
                variant='beta'>
                Скопировать ссылку
              </P>
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  )
}

export default Social
