import { StyledJSX } from 'ui'
import { useTranslations } from 'fractals'
import { TLabelProps } from './types'
import {
  CHILDREN_VARIANT_KEYS,
  YOUR_NAME,
  YOUR_PHONE_NUMBER,
  YOUR_EMAIL,
  YOUR_PROMOCODE
} from './constants'

// TODO: more some of the logic to UI
const Label = ({
  // colorsVariant,
  childrenVariant,
  ...props
}: TLabelProps) => {
  const [yourName, yourPhoneNumber, yourEmail, yourPromocode] = useTranslations(
    YOUR_NAME,
    YOUR_PHONE_NUMBER,
    YOUR_EMAIL,
    YOUR_PROMOCODE
  )

  const childrenVariants = {
    [CHILDREN_VARIANT_KEYS.name]: {
      children: yourName
    },
    [CHILDREN_VARIANT_KEYS.phoneNumber]: {
      children: yourPhoneNumber
    },
    [CHILDREN_VARIANT_KEYS.email]: {
      children: yourEmail
    },
    [CHILDREN_VARIANT_KEYS.promocode]: {
      children: yourPromocode
    }
  } as const

  const colorsVariants = {
    // [COLORS_VARIANT_KEYS.omega]: {
    //   backgroundColor: 'var(--omega)',
    //   color: 'var(--alpha)',
    //   '@media (hover: hover)': {
    //     '&:hover': {
    //       backgroundColor: 'var(--alpha)',
    //       transform
    //     }
    //   }
    // }
  } as const

  const styles = {
    ...props.styles
  } as const

  return (
    <StyledJSX.Label
      {...props}
      {...childrenVariants[childrenVariant]}
      styles={styles}
    />
  )
}

export default Label
