import { toUnit } from 'fractals'

export const subjectStyles = {
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer'
} as const

export const iconTickStyles = {
  marginRight: toUnit(16)
} as const

export const quoteStyles = {
  fontWeight: 500
} as const

export const advancedBackgroundStyles = {
  backgroundColor: '#F7F7F7',
  borderRadius: '0 8px 8px 0'
} as const

export const isSubjectTopicsDiv = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
} as const
