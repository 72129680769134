import { RefObject, useContext, useEffect } from 'react'
// TODO: remove the eslint-disable line and fix the error
// eslint-disable-next-line import/no-cycle
import { ModalShowCtx } from '../contexts'

const useCloseButtons = ({
  modalRef,
  close
}: {
  modalRef: RefObject<HTMLDivElement | null>
  close: () => void
}) => {
  const { modalId } = useContext(ModalShowCtx)
  useEffect(() => {
    if (modalRef.current) {
      const closeButtons =
        modalRef.current.querySelectorAll('[data-modal-close]')

      closeButtons.forEach((button: Element | null /* , key: number */) => {
        if (button) {
          button.setAttribute('aria-controls', modalId)
          button.addEventListener('click', () => {
            close()
          })
        }
      })
    }
  }, [modalRef, close, modalId])
}

export default useCloseButtons
