import React from 'react'
import { TbBrandTelegram, TbPhoneCall } from 'react-icons/tb'
import { Button } from '@/fractals/components'
import { TStack } from '../../types'
import { askModal } from '../../styles'

export const CallButtons = ({
  navigateTo
}: {
  navigateTo: (content: TStack) => void
}) => {
  const two = [
    { here: 'call', is: 'Позвонить', Icon: TbBrandTelegram },
    { here: 'text', is: 'Написать', Icon: TbPhoneCall }
  ]
  return (
    <>
      {two.map(({ here, is, Icon }, idx) => (
        <Button
          key={`AskmodalCallButtons-button__${idx + 1}`}
          styles={{
            ...askModal.defaultButton
          }}
          onClick={() => navigateTo(here as TStack)}>
          <Icon
            style={{
              marginRight: '15px'
            }}
          />
          {is}
        </Button>
      ))}
    </>
  )
}

export default CallButtons
