export type OpportunityCardData = {
  header: string
  points: string[]
  img: any
}

export enum OpportunityCardVariant {
  light = 'light',
  blue = 'blue'
}

export type OpportunityCardProps = {
  data: OpportunityCardData
  variant: OpportunityCardVariant
  customOrder?: boolean
}
