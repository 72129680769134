import React, { useState } from 'react'
import { Input } from 'ui/modules/styled-jsx/widgets'
import { Button, Div, Span } from '@/fractals/components'
import { TStack } from '../../types'
import { askModal } from '../../styles'

export const TextButtons = ({
  currentContent,
  setValue,
  setAlert,
  value,
  alert
}: {
  currentContent: TStack | undefined
  setValue: (data: string) => void
  setAlert: (data: string) => void
  value: string
  alert: string
}) => {
  const [focuse, setFocuse] = useState(false)

  const textOptions = new Set(['call', 'phone', 'text'])

  return (
    <>
      <Div
        styles={{
          ...askModal.inputContainer
        }}>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAlert('')
            const inputValue = e.target.value
            if (inputValue !== undefined && typeof inputValue === 'string') {
              if (currentContent !== 'email') {
                const x = inputValue
                  .replace(/\D/g, '')
                  .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
                if (x) {
                  const newValue = !x[2]
                    ? x[1]
                    : `+${x[1]}(${x[2]})${x[3] ? x[3] : ''}${
                        x[4] ? `-${x[4]}` : ''
                      }${x[5] ? `-${x[5]}` : ''}`
                  e.preventDefault()
                  setValue(newValue ?? '')
                }
              } else setValue(e.target.value)
            }
          }}
          onClick={() => setFocuse(true)}
          onBlur={() => setFocuse(false)}
          value={value}
          styles={{
            ...askModal.input
          }}
        />
        <Span
          styles={{
            ...askModal.inputPlaceholder,
            ...(focuse && askModal.inputPlaceholder.focuse)
          }}>
          {currentContent === 'vk' && 'Введите ID Вконтакте'}
          {currentContent === 'email' && 'Введите электронную почту'}
          {textOptions.has(currentContent ?? '') && 'Введите номер'}
        </Span>
        {alert && (
          <Span styles={askModal.inputPlaceholder.focuse}>{alert}</Span>
        )}
      </Div>
      <Button
        type='submit'
        styles={{
          ...askModal.defaultButton
        }}>
        Отправить
      </Button>
    </>
  )
}

export default TextButtons
