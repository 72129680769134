import React, { useState } from 'react'
import { media, multiMedia, toUnit } from 'fractals'
import Popup from 'reactjs-popup'
import {
  Container,
  Div,
  IconPlus,
  IconUnionFolder,
  P
} from '@/fractals/components'
import { Ellipse } from '../Ellipse'
import { Books } from '../Books'
import { TDownloadFreeMobileProps } from './types'
import { BooksPopup } from '../BooksPopup'
import { LeadForm } from '@/modules/shared/widgets'
import { PopupThanksSpan } from '../PopupThanksSpan'

type CallbackType = (data: boolean | undefined) => void

export const DownloadFreeMobile = ({
  programs,
  pdf,
  category
}: TDownloadFreeMobileProps) => {
  const [isDownloadFree, setIsDownloadFree] = useState(true)

  const [popupThanks, setPopupThanks] = useState<boolean | undefined>(false)

  const handleSuccess: CallbackType = data => {
    setPopupThanks(!data)
  }

  const topic =
    'Получите подборку файлов от Московского Института Технологий и Управления'

  return (
    <Div
      styles={{
        ...multiMedia({
          desktop: { display: 'none' },
          laptop: { display: 'none' },
          tablet: { display: 'block' },
          phone: { display: 'block' }
        }),
        position: 'fixed',
        backgroundColor: 'var(--beta)',
        width: '-webkit-fill-available',
        zIndex: 4,
        bottom: '76px'
      }}>
      <Container>
        <Div>
          {isDownloadFree && (
            <Popup
              modal
              nested
              closeOnDocumentClick
              closeOnEscape
              lockScroll
              trigger={
                <Div
                  styles={{
                    display: 'flex',
                    bottom: 0,
                    top: 'auto',
                    zIndex: 4,
                    width: '-webkit-fill-available',
                    height: '2.5rem',
                    backgroundColor: 'var(--beta)',
                    borderRadius: '8px 8px 0 0',
                    padding: `${toUnit(16)} 0`,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <Ellipse
                    styles={{
                      position: 'absolute',
                      left: '44%',
                      top: 0
                    }}
                    position='center'
                  />
                  <P
                    styles={{
                      position: 'relative',
                      zIndex: 2,
                      maxWidth: '75%',
                      fontSize: '16px'
                    }}
                    variant='pi'>
                    Скачать полезные материалы
                  </P>
                  <IconPlus
                    onClick={prev => setIsDownloadFree(!prev)}
                    styles={{
                      backgroundColor: 'var(--alpha)',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      zIndex: 2,
                      left: 'auto',
                      right: '2rem'
                    }}
                    colorVariant='omega'
                    variant='crossMini'
                  />
                  <Books
                    styles={{
                      position: 'absolute',
                      ...multiMedia({
                        phone: {
                          right: '-1%'
                        },
                        tablet: {
                          right: '20%'
                        }
                      })
                    }}
                    size='sm'
                    position='upRight'
                  />
                </Div>
              }>
              {/* @ts-expect-error TODO: remove this line and fix the error */}
              {(close: MouseEventHandler) => (
                <>
                  <Div
                    onClick={close}
                    styles={{
                      position: 'absolute',
                      top: '1rem',
                      right: '1rem',
                      borderRadius: '50%',
                      backgroundColor: 'var(--alpha)',
                      zIndex: 4,
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}>
                    <IconPlus colorVariant='omega' variant='crossMini' />
                  </Div>
                  <Div
                    styles={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      boxSizing: 'border-box',
                      ...multiMedia({
                        desktop: { maxWidth: '', padding: '23px' },
                        laptop: { maxWidth: '', padding: '23px' },
                        tablet: { maxWidth: '478px', padding: '32px' },
                        phone: { maxWidth: '343px', padding: '23px' }
                      }),
                      minHeight: 'auto',
                      maxHeight: 'max-content',
                      backgroundColor: '#3A52EE',
                      borderRadius: '16px'
                    }}>
                    <Div
                      styles={{
                        visibility: popupThanks ? 'hidden' : 'visible',
                        zIndex: 3,
                        color: 'white',
                        width: '-webkit-fill-available',
                        height: '100%',
                        '& > p:first-child': {
                          ...media('marginBottom', '43px', {
                            laptop: '35px',
                            tablet: '27px',
                            phone: '27px'
                          })
                        }
                      }}>
                      <P
                        styles={{
                          ...media('fontSize', '24px', {
                            phone: '20px'
                          }),
                          width: '80%',
                          fontWeight: 500,
                          lineHeight: '120%'
                        }}
                        variant='eta'>
                        {topic}
                      </P>
                      <Div
                        styles={{
                          display: 'flex',
                          gap: '19px',
                          flexDirection: 'column'
                        }}>
                        {pdf &&
                          pdf?.map(
                            ({ name }, idx) =>
                              idx < 2 &&
                              name && (
                                <Div
                                  styles={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: toUnit(6)
                                  }}
                                  key={`ProgramUnion-Div__${idx + 1}`}>
                                  <IconUnionFolder />
                                  <P
                                    styles={{
                                      fontSize: '14px'
                                    }}
                                    variant='beta'>
                                    {name || ''}
                                  </P>
                                </Div>
                              )
                          )}
                      </Div>
                    </Div>
                    <LeadForm
                      styles={{
                        zIndex: 3,
                        width: '100%',
                        visibility: popupThanks ? 'hidden' : 'visible'
                      }}
                      name
                      phoneNumber
                      email
                      pdfData={pdf?.map(({ name, url }) => ({
                        name,
                        url
                      }))}
                      isJournal
                      isPopupJournal
                      isSandwich
                      onSuccess={handleSuccess}
                      styleVariant='beta'
                      formName=''
                      colorsVariant='gamma'
                      id='DownloadFreeMobile'
                    />
                    <>
                      <Div
                        styles={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                          width: 'fit-content',
                          zIndex: 1,
                          height: '100%',
                          minHeight: 'inherit',
                          top: 0,
                          right: 0
                        }}>
                        <Ellipse
                          position='upRightTablet'
                          styles={{
                            maxWidth: 'fit-content',
                            marginLeft: 'auto',
                            borderRadius: '16px'
                          }}
                        />
                        <Ellipse
                          position='middle'
                          styles={{
                            margin: '-6.5rem 0 auto 0'
                          }}
                        />
                      </Div>
                      <Div
                        styles={{
                          position: 'absolute',
                          zIndex: 2,
                          ...multiMedia({
                            desktop: { bottom: 0, display: 'none' },
                            laptop: { bottom: 0, display: 'none' },
                            tablet: { bottom: 0, display: 'none' },
                            phone: { bottom: toUnit(300), display: 'block' }
                          }),
                          right: 0
                        }}>
                        <BooksPopup position='rightDesktop' />
                      </Div>
                      {popupThanks && (
                        <PopupThanksSpan onForward={handleSuccess} />
                      )}
                    </>
                  </Div>
                </>
              )}
            </Popup>
          )}
        </Div>
      </Container>
    </Div>
  )
}

export default DownloadFreeMobile
