export {
  TITLE,
  TITLE_LEARN_FASTER,
  DESCRIPTION,
  DESCRIPTION_LEARN_FASTER,
  BTN,
  IMAGE_ALT,
  IMAGE_ALT_ALT,
  DYNAMIC_STUDY_DURATION
} from './constants'
export type { TExamFreeEnrollmentProps } from './types'
export { getExamFreeEnrollmentData } from './api'
