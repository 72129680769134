import { useState, FC } from 'react'
import styled from 'styled-components'
import { P } from '../P'

const FadeAndHideText: FC<{
  children: JSX.Element
  maxHeight: number
  bgColor: string
}> = ({ children, maxHeight, bgColor }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
          transition: 'max-height 0.5s',
          position: 'relative'
        }}>
        {children}
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            textAlign: 'center',
            margin: '0',
            padding: '30px 0',
            backgroundImage: isExpanded
              ? 'none'
              : `linear-gradient(to bottom, transparent, ${bgColor})`,
            pointerEvents: 'none'
          }}
        />
      </div>

      {!isExpanded && (
        <P
          variant='omicronWhite'
          styles={{
            cursor: 'pointer',
            textDecoration: 'underline',
            marginTop: '16px'
          }}
          onClick={() => setIsExpanded(true)}>
          Развернуть
        </P>
      )}
    </>
  )
}

const StyledP = styled(P)`
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 50px, black);
  }
`

export default FadeAndHideText
