import { Div, Image, Li, multiMedia, useMedia } from 'fractals'
import { Layout, StyledJSX } from 'ui'
import { OpportunityCardProps, OpportunityCardVariant } from './types'

export const OpportunityCard = ({
  data,
  variant,
  customOrder
}: OpportunityCardProps) => {
  const { header, points, img } = data

  const { isLaptop, isDesktop, isTablet } = useMedia()

  return (
    <Layout.FlexItem
      styles={{
        ...multiMedia({
          desktop: { padding: '32px', maxWidth: '528px' },
          laptop: { padding: '32px', maxWidth: '360px' },
          tablet: { padding: '24px', maxWidth: '576px' },
          phone: { padding: '24px', maxWidth: '311px' }
        }),
        boxSizing: 'border-box',
        borderRadius: '16px',
        backgroundColor:
          variant === OpportunityCardVariant.blue
            ? 'var(--beta)'
            : 'var(--sigma)',
        color:
          variant === OpportunityCardVariant.blue
            ? 'var(--alpha)'
            : 'var(--omega)',
        position: 'relative',
        order: customOrder ? '4' : 'initial'
      }}>
      {isLaptop && (
        <Div
          styles={{
            borderRadius: '100%',
            backgroundColor:
              variant === OpportunityCardVariant.blue
                ? 'var(--sigma)'
                : 'var(--beta)',
            width: '48px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px'
          }}>
          <Image
            colorVariant='transparent'
            {...img}
            accentColor={
              variant === OpportunityCardVariant.blue
                ? 'var(--alpha)'
                : 'var(--beta)'
            }
          />
        </Div>
      )}
      <StyledJSX.H3
        styles={{
          marginTop: 0,
          marginBottom: '16px',
          fontSize: '20px',
          fontWeight: '500',
          lineHeight: '120%'
        }}>
        {header}
      </StyledJSX.H3>
      <ul
        style={{
          margin: 0,
          padding: '0 0 0 1em'
        }}>
        {points.map(point => (
          <Li
            key={point}
            variant='beta'
            styles={{
              ...multiMedia({
                fontSize: '14px',
                desktop: { fontSize: '16px' },
                lineHeight: '19.2px',
                display: 'list-item',
                color:
                  variant === OpportunityCardVariant.blue
                    ? 'var(--alpha)'
                    : 'var(--upsilon)'
              })
            }}>
            {point}
          </Li>
        ))}
      </ul>

      {(isDesktop || isTablet) && (
        <Div
          styles={{
            borderRadius: '100%',
            backgroundColor:
              variant === OpportunityCardVariant.blue
                ? 'var(--sigma)'
                : 'var(--beta)',
            width: '48px',
            height: '48px',
            position: 'absolute',
            top: '16px',
            right: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Image
            colorVariant='transparent'
            {...img}
            accentColor={
              variant === OpportunityCardVariant.blue
                ? 'var(--alpha)'
                : 'var(--beta)'
            }
          />
        </Div>
      )}
    </Layout.FlexItem>
  )
}
export default OpportunityCard
