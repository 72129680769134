import Slider from '@mui/material/Slider'
import { styled } from '@mui/system'

export const CustomSlider = styled(Slider)({
  color: '#3A52EE',
  height: 1,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#3A52EE'
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#C4C4C4'
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: '1.5px'
  },
  '& .MuiSlider-thumb:before': {
    boxShadow: 'none'
  }
})
