import { LOCALES } from 'fractals'

export const FACULTY_DEFAULT_CATEGORY_TITLE = {
  [LOCALES.en]: 'Course',
  [LOCALES.ru]: 'Курс',
  [LOCALES.uz]: 'Kurs',
  [LOCALES.kk]: 'Курс'
} as const

export const FACULTY_DEFAULT_CATEGORY_DESCRIPTION = {
  [LOCALES.en]:
    'First stage of higher education. Training is designed for 4 years. You will receive a state sample diploma and a bachelor’s degree. You can start a career or continue your studies in graduate school',
  [LOCALES.ru]:
    'Первая ступень высшего образования. Обучение рассчитано на 4 года. Вы получите диплом государственного образца и квалификацию бакалавра. Сможете начать карьеру или продолжить обучение в магистратуре',
  [LOCALES.uz]:
    'Oliy ta’limning birinchi darajasi. Ta’lim 4 yil davom etadi. Siz gosudarstven nusxali diplom va bakalavr darajasi olasiz. Karera boshlash yoki magistratura o‘qishini davom ettirish imkoniyatiga ega bo‘lasiz',
  [LOCALES.kk]:
    'Жоғары мектептік білімдің бірінші деңгейі. Білім 4 жыл қалады. Сіз демократиялық нұсқаға диплом және бакалавр дәрежесі аласыз. Сіз карьераны бастауға немесе магистратураны жалғастыруға болады'
} as const

export const FACULTY_DEFAULT_FACULTY_NAME = {
  [LOCALES.en]: 'Faculty',
  [LOCALES.ru]: 'Факультет',
  [LOCALES.uz]: 'Fakultet',
  [LOCALES.kk]: 'Факультет'
} as const

export const COURSE_LABEL = {
  [LOCALES.en]: 'Course',
  [LOCALES.ru]: 'Курс',
  [LOCALES.uz]: 'Kurs',
  [LOCALES.kk]: 'Курс'
} as const

export const SHOW_MORE_BTN = {
  [LOCALES.ru]: 'Все направления',
  [LOCALES.en]: 'All faculties',
  [LOCALES.uz]: 'Barcha yo‘nalishlar',
  [LOCALES.kk]: 'Барлық бағдарламалар'
}
