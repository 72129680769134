import styled from 'styled-components'

import { toUnit } from 'fractals'

type TStyledProps = {
  accentColor?: string
}

export const StyledButtonCources = styled.div.attrs(
  ({ accentColor }: TStyledProps) => ({
    accentColor: accentColor || '#fb5449'
  })
)`
  display: flex;
  justify-content: center;
  align-items: center;
  right: ${toUnit(-45)};
  bottom: ${toUnit(-50)};
  position: absolute;
  background-color: ${({ accentColor }) => accentColor};
  width: ${toUnit(128)};
  height: ${toUnit(128)};
  border-radius: 50%;
  transition-duration: 200ms;
  @media (max-width: 768px) {
    right: ${toUnit(-48)};
    bottom: ${toUnit(-53)};
  }
`
export const StyledSvgButtonCources = styled.svg`
  position: absolute;
  top: ${toUnit(35)};
  left: ${toUnit(50)};
  width: ${toUnit(8)};
  height: ${toUnit(14)};
  fill: none;
  @media (max-width: 768px) {
    top: ${toUnit(32)};
    left: ${toUnit(40)};
  }
`
export const StyledPathButtonCources = styled.path`
  stroke: white;
  stroke-width: 2;
  stroke-linecap: 'round';
  stroke-linejoin: 'round';
`
