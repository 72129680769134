import * as React from 'react'
import {
  HeadItem,
  HeaderItem,
  ListItem,
  ListDescriptionItem,
  StripeItem,
  TextItem,
  PlusItem,
  ImageItem
} from './fractals'

export const EnumContent = ({
  richtext,
  advancedDescriptionBefore
}: {
  richtext: {
    type:
      | 'text'
      | 'list'
      | 'header'
      | 'plus'
      | 'listDescription'
      | 'head'
      | 'stripe'
      | 'image'
    content: string
    keep: boolean
  }[]
  advancedDescriptionBefore: boolean
}) => {
  let counter = 0

  return (
    <>
      {richtext.map(({ type, content, keep }, i) => {
        if (type === 'list') {
          counter += 1
        } else if (!(type === 'listDescription' || keep)) {
          counter = 0
        }
        switch (type) {
          case 'text':
            return (
              <TextItem content={content} key={`EnumContent-Text__${i + 1}`} />
            )
          case 'header':
            return (
              <HeaderItem
                content={content}
                key={`EnumContent-Header__${i + 1}`}
              />
            )
          case 'head':
            return (
              <HeadItem content={content} key={`EnumContent-Head__${i + 1}`} />
            )
          case 'list':
            return (
              <ListItem
                counter={counter}
                content={content}
                key={`EnumContent-List__${i + 1}`}
              />
            )
          case 'listDescription':
            return (
              <ListDescriptionItem
                content={content}
                key={`EnumContent-ListDescription__${i + 1}`}
              />
            )
          case 'stripe':
            return (
              <StripeItem
                content={content}
                key={`EnumContent-Stripe__${i + 1}`}
              />
            )
          case 'plus':
            return (
              <PlusItem
                advancedDescriptionBefore={advancedDescriptionBefore}
                content={content}
                key={`EnumContent-Plus__${i + 1}`}
              />
            )
          case 'image':
            return (
              <ImageItem
                content={content}
                key={`EnumContent-Image__${i + 1}`}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}
