import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import localFont from '@next/font/local'
import { THEME } from './constants'

// TODO: improved structure
const golosTextVF = localFont({
  src: [
    {
      path: './fonts/Golos-Text-VF/golos-text_black.woff2',
      weight: '900',
      style: 'normal'
    },
    {
      path: './fonts/Golos-Text-VF/golos-text_bold.woff2',
      weight: '700',
      style: 'normal'
    },
    {
      path: './fonts/Golos-Text-VF/golos-text_demibold.woff2',
      weight: '600',
      style: 'normal'
    },
    {
      path: './fonts/Golos-Text-VF/golos-text_medium.woff2',
      weight: '500',
      style: 'normal'
    },
    {
      path: './fonts/Golos-Text-VF/golos-text_regular.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: './fonts/Golos-Text-VF/golos-text_vf.woff2',
      weight: '300',
      style: 'normal'
    }
  ]
})

const StyledGlobal = createGlobalStyle`
  ${normalize}

  // TODO: improve types
  :root {
    ${Object.keys(THEME.colors.light).map(
      color =>
        `--${color}: ${THEME.colors.light[
          color as keyof typeof THEME.colors.light
        ]
          .rgb()
          .string()};` as const
    )};
  }

  // * css hack to render box-sizing faster
  // * the inheritance loads faster than the cascade
  *,
  *::before,
  *::after{
    box-sizing: inherit;
    // outline: 2px solid red;
  }

  body {
    font-size: ${THEME.rootFontSize / 16}rem;
    font-family: ${golosTextVF.style.fontFamily}, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    background-color: var(--alpha);
    color: var(--omega);

  }

  /* textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    outline: none;
  } */

  // npm package: nprogress
  html #nprogress .bar{
    background: var(--beta);
  }

  html #nprogress .spinner-icon{
    border-top-color: var(--beta);
    border-left-color: var(--beta);
  }

  // npm package: reactjs-popup
  @keyframes anvil{
  0%{
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1%{
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100%{
    transform: scale(1) translateY(0px);
    opacity: 1;}
  }

@keyframes anvilTooltip{
  0%{
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1%{
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100%{
    transform: scale(1) translateY(2.4rem);
    opacity: 1;
  }
}

@keyframes anvilBg{
  0%{
    background-color: transparent;
  }
  100%{
    background-color: rgba(0,0,0, .5);
  }
}

@keyframes burgerStyledAnvilBg{
  0%{
    background-color: transparent;
  }
  100%{
    background-color: rgba(0,0,0,0);
  }
}

@keyframes styledAnvilBg{
  0%{
    background-color: rgba(255, 255, 255, 0);
  }
  100%{
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes socialAnvilBg{
  0%{
    background-color: transparent;
  }
  100%{
    background-color: rgba(0,0,0,0);
  }
}

@keyframes burgerAnvilBg{
  0%{
    background-color: transparent;
  }
  100%{
    background-color: var(--beta);
  }
}

div.popup-overlay {
  overflow: hidden;
}

div.popup-content{
  position: relative;
  width: 100%;
  width: max-content;
  // height: -webkit-fill-available;
  max-width: 100%;
  border: none;
  background-color: transparent;
  animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

div.ask-popup-content{
  position: fixed !important;
  bottom: 0;
  right: 0;
  // margin-top: 0 !important;
  width: 100%;
  max-width: 100%;
  border: none;
  background-color: transparent;
  animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

div.styled-popup-content{
  position: relative;
  margin-top: 0 !important;
  width: 100%;
  width: max-content;
  max-width: 100%;
  border: none;
  background-color: transparent;
  animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

div.burger-styled-popup-content{
  position: relative;
  margin-top: 0 !important;
  width: 100%;
  width: max-content;
  max-width: 100%;
  border: none;
  background-color: transparent;
  animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

div.social-popup-content {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 100%;
    width: max-content;
    max-width: 100%;
    border: none;
    background-color: transparent;
    animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

div.burger-popup-content {
  margin: 0 !important;
  width: 100%;
}

.ReactModal__Overlay {
  position: absolute !important;
  inset: 0% !important;
  background-color: transparent !important;
}

.ReactModal__Content {
  position: absolute;
  inset: 50% !important;
  border: 0 !important;
  background: 0 !important;
  overflow: initial !important;
  border-radius: 0 !important;
  outline: 0 !important;
  padding: 0 !important;
}

div.left-social-popup-content {
  position: absolute !important;
  bottom: 50% !important;
  left: 23% !important;
  width: 100%;
  width: max-content;
  max-width: 100%;
  border: none;
  background-color: transparent;
  animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@media (max-width: 425px) {
  div.styled-popup-content {
    margin-top: 9rem !important;
  }
  div.burger-styled-popup-content {
    margin-top: 9rem !important;
  }
}

div.popup-overlay{
  /* padding-top: 4.2rem; */
  overflow-y: auto;
  transition: background-color 0.3s ease-in-out;
  animation: anvilBg 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  /* background-color: rgba(0,0,0, .5); */
}

div.styled-popup-overlay{
  /* padding-top: 4.2rem; */
  overflow-y: auto;
  margin-top: 6rem !important;
  transition: background-color 0.3s ease-in-out;
  animation: anvilBg 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  // background-color: rgba(0,0,0,0);
}

div.burger-styled-popup-overlay{
  /* padding-top: 4.2rem; */
  overflow-y: auto;
  margin-top: 6rem !important;
  transition: background-color 0.3s ease-in-out;
  animation: burgerStyledAnvilBg 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  // background-color: rgba(0,0,0,0);
}

div.social-popup-overlay{
  /* padding-top: 4.2rem; */
  overflow-y: auto;
  transition: background-color 0.3s ease-in-out;
  animation: socialAnvilBg 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  /* background-color: rgba(0,0,0, .5); */
}

div.left-social-popup-overlay{
  /* padding-top: 4.2rem; */
  overflow-y: auto;
  transition: background-color 0.3s ease-in-out;
  animation: socialAnvilBg 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  /* background-color: rgba(0,0,0, .5); */
}

div.burger-popup-overlay{
  /* padding-top: 4.2rem; */
  overflow-y: auto;
  transition: background-color 0.3s ease-in-out;
  animation: burgerAnvilBg 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  /* background-color: rgba(0,0,0, .5); */
}

@media (max-width: 425px) {
  div.styled-popup-overlay {
    margin-top: 0 !important;
  }
  div.burger-styled-popup-overlay {
    margin-top: 0 !important;
  }
}

.popup-content[role='tooltip']{
  padding: 0 !important;
  width: initial !important;
  border-radius: 0 !important;
  transform: translateY(2.4rem) !important;
  animation: anvilTooltip 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

// npm package: react-slick

.slick-track {
  display: flex;
}
.similarArticle_div > .slick-list > .slick-track > .slick-slide {
  width: 105% !important;
}

.stories-slide > .slick-list > .slick-track > .slick-slide {
  display: flex !important;
  justify-content: center;
  & > div {
    width: auto !important;
    margin-left: auto;
  }
  & > div > div {
    display: inline-flex !important;
    justify-content: center;
    align-items: end;
  }
}

@media (max-width: 768px) {
  .stories-slide > .slick-list > .slick-track > .slick-slide > div {
    width: 100% !important;
  }
}

.stories-slide > .slick-list {
  overflow: hidden;
}

.slick-list {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slick-list::-webkit-scrollbar {
  display: none;
}

@media(max-width: 480px) {
  .slick-card {
    width: 341px;
    height: 170px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .slick-card {
    width: 304px;
    height: 152px;
  }
}

@media (min-width: 769px) and (max-width: 1280px) {
  .slick-card {
    width: 280px;
    height: 140px;
  }
  .similarArticle_div > .slick-list > .slick-track > .slick-slide {
    width: 280px;
  }
}

@media (min-width: 1281px) {
  .slick-card {
    width: 384px;
    height: 192px;
  }
}

@media (max-width: 480px) {
  .block {
    grid-column: span 6;
    .journalCard__image {
      width: 343px;
      height: 171px;
    }
    .journal-card {
      width: 100%;
    }
  }
}


@media (min-width: 481px) and (max-width: 768px) {
  .block:nth-child(5n + 2),
  .block:nth-child(5n + 3),
  .block:nth-child(5n + 4),
  .block:nth-child(5n + 5) {
    grid-column: span 3;
    .journalCard__image {
      width: 304px;
      height: 152px;
    }
    .journal-card {
      width: 304px;
    }
  }
  .block:nth-child(5n + 1) {
    grid-column: span 6;
    .journalCard__image {
      width: 640px;
      height: 319px;
    }
    .journal-card {
      width: 640px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1280px) {
  .block:nth-child(5n + 2),
  .block:nth-child(5n + 3),
  .block:nth-child(5n + 4),
  .block:nth-child(5n + 5) {
    grid-column: span 3;
    .journalCard__image {
      width: 384px;
      height: 192px;
    }
    .journal-card {
      width: 384px;
    }
  }
  .block:nth-child(5n + 1)
   {
    grid-column: span 6;
    .journalCard__image {
      width: 800px;
      height: 398px;
    }
    .journal-card {
      width: 800px;
    }
  }
}

@media (min-width: 1281px) {
  .block:nth-child(5n + 5),
  .block:nth-child(5n + 6) {
    grid-column: span 3;
    .journalCard__image {
      width: 592px;
      height: 295px;
    }
    .journal-card {
      width: 592px;
      & > div > div > h2 {
        font-size: 36px;
      }
    }
  }
  .block:nth-child(5n + 2),
  .block:nth-child(5n + 3),
  .block:nth-child(5n + 4) {
    grid-column: span 2;
    .journalCard__image {
      width: 384px;
      height: 192px;
    }
    .journal-card {
      width: 384px;
      & > div > div > h2 {
        font-size: 24px;
      }
    }
  }
  .block:first-child {
    grid-column: span 6;
    .journalCard__image {
      width: 592px;
      height: 295px;
    }
    .journal-card {
      width: 1216px;
    }
  }
}

// для таблиц в журнале

.row-headers-background, .column-headers-background {
  display: none;
}

td {
  font-family: '__golosTextVF_099e87','__golosTextVF_Fallback_099e87',sans-serif !important;
  font-size: 16px !important;
  white-space: break-spaces;
  padding: 14px !important;
  font-weight: 400 !important;
}

.table {
  margin: 0;
}

.table table {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .waffle {
    width: 640px;
  }
  .ritz {
    margin: 40px 0 8px 0;
  }
}

@media (min-width: 769px) and (max-width: 1280px) {
  .waffle {
    width: 100%;
  }
  .ritz {
    margin: 56px 0 24px 0;
  }
}

@media (min-width: 1281px) {
  .waffle {
    width: 100%;
  }
  .ritz {
    margin: 56px 0 24px 0;
  }
}

@media (max-width: 1199px) {
  .ritz .waffle .s0, .s1 {
    font-size: 14px;
  }
}

table > tbody > tr:first-child {
  border-radius: 8px 0 0 0;
}

table {
  min-width: auto;
  border-collapse: collapse;
}

table > tbody {
  & > tr:first-child {
    & > td:nth-child(1) {
      border-radius: 8px 0 0 0;
    }
    & > td:last-child {
      border-radius: 0 8px 0 0;
    }
  }
  & > tr:last-child {
    & > td:nth-child(2) {
      border-radius: 0 0 0 8px;
    }
    & > td:last-child {
      border-radius: 0 0 8px 0;
    }
  }
}

// image from strapi model: journal > journalContent > advancedDescription > advancedTable

.image {
    margin: 0;
    padding: 0;
    width: 800px;
    height: 400px;
    overflow: hidden;
    border-radius: 16px;
    box-sizing: border-box;
    position: relative;
    & > img {
      position: absolute;
      max-width: -webkit-fill-available;
    }
}

@media (max-width: 425px) {
  .image {
    width: 343px;
    height: 171px;
  }
}

@media (min-width: 426px) and (max-width: 1023px) {
  .image {
    width: 640px;
    height: 320px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .image {
    width: 592px;
    height: 320px;
  }
}

@media (min-width: 1280px) {
  .image {
    width: 800px;
    height: 400px;
  }
}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.40) 100%);
  z-index: 1;
}

.flags-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

.flags-scroll::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

.flags-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
    border-radius: 4px;
}

.flags-scroll::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.4);
}

.flags-scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(0,0,0,0.2) transparent;
}


// npm react-player

.react-player__preview {
    background-position: left bottom !important;
    align-items: end !important;
    justify-content: left !important;
    // padding: 0 0 24px 24px;
    position: relative;
}

`

export default StyledGlobal
