import UTM_KEYS, { UTM_KEYS_OBJ } from './utm_keys'

export { toUnit } from './toUnit'
export { removeKey } from './removeKey'
export { sortBasedOnKey } from './sortBasedOnKey'
export { parseGeneralSlug } from './parseGeneralSlug'
export { fetcher } from './fetcher'
export { handleLocale } from './handleLocale'
export { handleReferer } from './handleReferer'
export { handleUtms } from './handleUtms'
export { handleCookiesExpiration } from './handleCookiesExpiration'
// export { subscribe, unsubscribe } from './handleResize'
export { media } from './media'
export { multiMedia } from './multiMedia'
export { parseUrlParams } from './parseUrlParams'
export { getCurrentJournal } from './api'
export { withClickOutside } from './withClickOutside'
export {
  getCountryCodeByPhonePrefix,
  getPhonePrefixByCountryCode,
  phonePrefixToCountryCode
} from './getCountryCodeByPhonePrefix'
export { default as hashData } from './hashData'
export { UTM_KEYS_OBJ, UTM_KEYS }
