import { LOCALES } from 'fractals'

export const TITLE = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Что говорят выпускники?',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const NAME_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Иванов Иван',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const POSITION_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Аналитик данных',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const BODY_1 = {
  [LOCALES.en]: '',
  [LOCALES.ru]:
    'Я в восторге! Всегда мечтала о творческой работе в приятной атмосфере благоухания и цветения. И вот я счастливый ученик в Talentsy. Крутой формат обучения, информацию доступно преподносят прекрасные преподаватели. Супербонус - прокачка SMM навыков. Сейчас я на стадии оформления портфолио и верю, что у меня все получится.',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const
