import { CSSProperties } from 'react'

export const wrapper: CSSProperties = {
  position: 'fixed',
  zIndex: 9999,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
}

export const suppressHydrationWarningWrapper: CSSProperties = {
  position: 'fixed',
  left: '-9999px',
  height: 0,
  width: 0,
  visibility: 'hidden',
  opacity: 0
}
