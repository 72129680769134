export const VARIANT_KEYS = {
  default: 'default',
  homeVideo: 'homeVideo',
  programVideo: 'programVideo'
} as const

export const POSITION_KEYS = {
  left: 'left',
  right: 'right'
} as const
