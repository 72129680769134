import { useRouter } from 'next/router'

export const parseUrlParams = ({
  router
}: {
  router: ReturnType<typeof useRouter>
}): Record<string, string> | undefined => {
  // console.log('!!!!!!!!', router)
  const params = router.asPath.split('?')[1]

  if (params)
    return params.split('&').reduce(
      (acc, cur) => ({
        ...acc,
        [cur.split('=')[0] as string]: cur.split('=')[1]
      }),
      {}
    )

  return undefined
}

export default parseUrlParams
