import { LOCALES } from '../../../constants'

export const BORDER_RADIUS_VARIANTS_KEYS = {
  alpha: 'alpha',
  beta: 'beta',
  circle: 'circle'
} as const

export const COLORS_VARIANT_KEYS = {
  omega: 'omega',
  alpha: 'alpha',
  alphaAlt: 'alphaAlt',
  alphaReverse: 'alphaReverse',
  beta: 'beta',
  delta: 'delta',
  betaCost: 'betaCost',
  betaReverse: 'betaReverse',
  omegaReverse: 'omegaReverse',
  omegaReverseGrey: 'omegaReverseGrey',
  omegaReverseFaculty: 'omegaReverseFaculty',
  transparent: 'transparent',
  alphaTransparent: 'alphaTransparent',
  ask: 'ask'
} as const

export const SIZE_VARIANT_KEYS = {
  '3xs': '3xs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  medium: 'medium',
  lg: 'lg',
  default: 'default'
} as const

export const NOT_WORKING_YET_MESSAGE = {
  [LOCALES.ru]: 'К сожалению, эта кнопка ещё не работает. Но скоро будет!',
  [LOCALES.en]:
    'Unfortunately, this button is not working yet. But it will soon!',
  [LOCALES.uz]: 'Ushbu tugma hali ishlamaydi. Lekin tez orada!',
  [LOCALES.kk]:
    'Өкінішке орай, бұл түйме әлі жұмыс істемейді. Бірақ жақында болады!'
}
