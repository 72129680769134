import { LOCALES } from 'fractals'

export const DESCRIPTION = {
  [LOCALES.ru]:
    'Практики, которым мы учим, и методология, на которой строится курс, проверены не только практическим опытом преподавателей, но и строгими научными исследованиями',
  [LOCALES.en]:
    'The practices we teach and the methodology on which the course is based have been proven not only by the practical experience of teachers, but also by rigorous scientific research',
  [LOCALES.uz]:
    'Oʻqitiladigan amallar va kurs asosida asoslangan metodologiya, oʻqituvchilar praktik tajribasi bilan, balki qonunyos tadbirlar bilan ham tasdiqlangan',
  [LOCALES.kk]:
    'Оқытқан амалдар мен курс асосында асосланған методология, оқытушылар практикалық тәжірибесі білімімен, бірақ қонундық әрекеттермен қана тасдықталған'
} as const

export const FORM_NAME = {
  [LOCALES.en]: 'Full plan required',
  [LOCALES.ru]: 'Требуется полный план',
  [LOCALES.uz]: 'Toʻliq reja talab qilinadi',
  [LOCALES.kk]: 'Толық режа талап қилинеді'
} as const

export const DEFAULT_HEADING = {
  [LOCALES.ru]:
    'Оставьте свои контактные данные, чтобы мы прислали **полный учебный план**',
  [LOCALES.en]:
    'Leave your contact details so that we can send you the **full study plan**',
  [LOCALES.uz]:
    'Biz sizga **to’liq o’quv reja**ni yuborish uchun murojaatingizni qoldiring',
  [LOCALES.kk]:
    'Biz sizge **to’liq o’quv reja**ni yuborish uchun murojaatingizni qoldiring'
} as const
