import { TGetBreadcrumbsProps } from './types'
import { fetchBreadcrumbs, normalizeBreadcrumbsData } from './utils'

export const getBreadcrumbs = async ({ context }: TGetBreadcrumbsProps) => {
  const breadcrumbsData = await fetchBreadcrumbs({ context })

  const breadcrumbsDataNormalized = normalizeBreadcrumbsData({
    breadcrumbsData,
    journalSlug: context?.params?.general?.toString() || null
  })

  return breadcrumbsDataNormalized
}

export default getBreadcrumbs
