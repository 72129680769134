import checkIsSravniUtmSource from './checkIsSravniUtmSource'
import checkIsSravniUtmCampaign from './checkIsSravniUtmCampaign'
import checkIsSravniUtmMedium from './checkIsSravniUtmMedium'

const checkIsLeadFromSravni = (params: Record<string, string | undefined>) => {
  const isSravniUtmSource = checkIsSravniUtmSource(params.utm_source)
  // const isSravniUtmCampaign = checkIsSravniUtmCampaign(params.utm_campaign)
  const isSravniUtmMedium = checkIsSravniUtmMedium(params.utm_medium)

  return isSravniUtmSource && isSravniUtmMedium
}

export default checkIsLeadFromSravni
