/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
// TODO: remove the eslint-disable line and fix the error
// eslint-disable-next-line import/no-cycle
import { ModalShowCtx } from '../contexts'

const useAria = () => {
  const { modalId } = useContext(ModalShowCtx)
  const [ariaElementsId, setAriaElementsId] = useState<{
    'aria-describedby'?: string
    'aria-labelledby'?: string
  }>({})

  useEffect(() => {
    const descriptionElem = document.querySelector(
      `#${modalId} [data-modal-description]`
    )
    const labelElem = document.querySelector(`#${modalId} [data-modal-label]`)

    if (descriptionElem) {
      const descriptionId = `${modalId}-description`
      descriptionElem.setAttribute('id', descriptionId)
      setAriaElementsId(prev => ({
        ...prev,
        'aria-describedby': descriptionId
      }))
    }

    if (labelElem) {
      const labelId = `${modalId}-label`
      labelElem.setAttribute('id', labelId)
      setAriaElementsId(prev => ({
        ...prev,
        'aria-labelledby': labelId
      }))
    }
  }, [modalId])

  return ariaElementsId
}

export default useAria
