import { TGetSeoDataProps } from './types'
import { fetchJournalsSeoData, normalizeJournalsSeoData } from './utils'

export const getSeoData = async ({ context }: TGetSeoDataProps) => {
  const seoData = await fetchJournalsSeoData({ context })

  const seoDataNormalized = normalizeJournalsSeoData({
    seoData
  })

  // console.log('!!!!!!!!!!!!', seoDataNormalized)

  return seoDataNormalized
}

export default getSeoData
