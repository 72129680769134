import { PATHS, fetcher } from 'fractals'
import qs from 'qs'
import { TGetFacultiesDataProps, TResFaculties } from '../types'

export const fetchFaculties = async ({ context }: TGetFacultiesDataProps) => {
  const query = qs.stringify({
    fields: [
      'name',
      'slug',
      'rank',
      'color',
      'description',
      'descriptionBachelor',
      'descriptionMagister',
      'descriptionCourses'
    ],
    populate: {
      programs: {
        fields: ['name'],
        populate: {
          category: {
            fields: ['name', 'slug']
          }
        }
      },
      facultyImage: {
        fields: ['url', 'width', 'height', 'alternativeText']
      }
    },
    sort: ['rank:asc'],
    locale: context.locale
  })

  const res = await fetcher<TResFaculties>(
    `${PATHS.back.root.index}${PATHS.back.api.faculties.index}?${query}`
  )

  return res?.data || null
}

export default fetchFaculties
