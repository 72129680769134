import {
  useTranslations,
  Section,
  H2,
  P,
  toUnit,
  Image,
  Container,
  useAt,
  useMedia
} from 'fractals'
import { Layout, slugify } from 'ui'
import {
  TStudyIsProps,
  image1,
  image2,
  image3,
  image4,
  TITLE,
  DESCRIPTION_1,
  DESCRIPTION_2,
  DESCRIPTION_3,
  DESCRIPTION_4,
  DESCRIPTION_5,
  DESCRIPTION_6
} from './fractals'

// TODO: refactor and decompose, add data fetching

const StudyIs = ({ studyIs }: TStudyIsProps) => {
  const [
    title,
    description1,
    description2,
    description3,
    description4,
    description5,
    description6
  ] = useTranslations(
    TITLE,
    DESCRIPTION_1,
    DESCRIPTION_2,
    DESCRIPTION_3,
    DESCRIPTION_4,
    DESCRIPTION_5,
    DESCRIPTION_6
  )

  const { isHigherEducation } = useAt()
  const { isLaptop, isDesktop, isTablet, isMobile } = useMedia()

  const items = [
    {
      content: image1,
      bgColor: null
    },
    {
      content: studyIs?.[0]?.name || description1,
      bgColor: '#DF6945'
    },
    {
      content: image2,
      bgColor: null
    },
    {
      content: studyIs?.[1]?.name || description2,
      bgColor: '#7D5EE4'
    },
    {
      content: isHigherEducation ? studyIs?.[2]?.name || description3 : null,
      bgColor: isHigherEducation ? '#F1AE2B' : null
    },
    {
      content: studyIs?.[3]?.name || description4,
      bgColor: '#FB5449'
    },
    {
      content: image3,
      bgColor: null
    },
    {
      content: image4,
      bgColor: null
    },
    {
      content: studyIs?.[4]?.name || description5,
      bgColor: '#4BAC89'
    },
    {
      content: isHigherEducation ? studyIs?.[5]?.name || description6 : null,
      bgColor: isHigherEducation ? '#ACBF2F' : null
    }
  ] as const

  return (
    <Section
      id={slugify(title)}
      spacingVariant='delta'
      colorsVariant='beta'
      styles={{
        ...(isMobile && { paddingTop: toUnit(64) }),
        '*': { boxSizing: 'border-box' }
      }}>
      <Container
        styles={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <H2
          variant='beta'
          styles={{
            ...(isMobile && { fontSize: toUnit(32), marginBottom: toUnit(40) }),
            textAlign: 'center',
            maxWidth: isTablet || isMobile ? '83%' : '70%'
          }}>
          {title}
        </H2>
        <Layout.Grid
          styles={{
            justifyItems: 'center',
            width: '100%',
            grid:
              (isDesktop &&
                `${toUnit(170)} ${toUnit(185)} ${toUnit(179)}
              / repeat(5, 1fr)`) ||
              `auto-flow 1fr / ${isMobile ? '1fr' : 'repeat(2, 1fr)'}`,
            ...(isDesktop
              ? {
                  position: 'relative',
                  gridTemplateAreas: `
                    'idx_1 idx_1 idx_2 idx_3 idx_3'
                    'idx_4 idx_4 idx_5 idx_5 idx_6'
                    'idx_7 idx_8 idx_8 idx_9 idx_9'
                  `
                }
              : {
                  gap: (isMobile && toUnit(40)) || toUnit(32)
                })
          }}>
          {items
            .filter(item => isDesktop || typeof item.content === 'string')
            .map((item, idx) => (
              <Layout.GridItem
                key={`StudyIs__${item?.content?.toString() || 'item'}--${
                  idx + 1
                }`}
                styles={
                  isDesktop && idx === 0
                    ? {
                        position: 'absolute',
                        top: toUnit(-192),
                        left: 0
                      }
                    : {
                        display: 'flex',
                        gridArea: isDesktop
                          ? `idx_${idx}`
                          : (idx === 4 && ((isMobile && '3') || '2 / 1')) ||
                            (idx === 2 &&
                              ((isMobile &&
                                ((isHigherEducation && '6') || '4')) ||
                                (isHigherEducation && '3 / 1') ||
                                '2 / 2')) ||
                            undefined,
                        ...(isDesktop && {
                          justifySelf:
                            ([1, 2, 3, 6, 9].includes(idx) && 'end') ||
                            (idx === 4 && 'start') ||
                            'center',
                          alignSelf:
                            ([4, 8].includes(idx) && 'end') ||
                            (idx === 3 && 'start') ||
                            'center',
                          marginTop: idx === 6 ? toUnit(-10) : undefined
                        }),
                        ...(typeof item.content === 'string' && {
                          maxWidth: toUnit(
                            ((isDesktop || isLaptop) && 384) ||
                              (isTablet && 300) ||
                              342
                          ),
                          width: '100%',
                          backgroundColor: item.bgColor || undefined,
                          borderRadius: toUnit(16),
                          padding: toUnit(
                            ((isDesktop || isLaptop) && 32) || 24
                          ),
                          minHeight: toUnit(
                            (isDesktop && 136) || (isLaptop && 121) || 105
                          ),
                          height: !isDesktop ? '100%' : undefined
                        })
                      }
                }>
                {typeof item.content === 'string' ? (
                  <P
                    variant='epsilon'
                    styles={{
                      ...(isDesktop && { fontSize: toUnit(20) }),
                      fontWeight: '400',
                      maxHeight: 'max-content'
                    }}>
                    {item.content}
                  </P>
                ) : (
                  item.content && (
                    <Image
                      borderRadiusVariant='alpha'
                      colorVariant='beta'
                      src={item.content}
                      alt={`image${idx + 1}`}
                    />
                  )
                )}
              </Layout.GridItem>
            ))}
        </Layout.Grid>
      </Container>
    </Section>
  )
}

export default StudyIs
