export {
  TITLE,
  TESTIMONIAL_1,
  TESTIMONIAL_2,
  TESTIMONIAL_3,
  TESTIMONIAL_4,
  TESTIMONIAL_5,
  TESTIMONIAL_6,
  TESTIMONIAL_7,
  TESTIMONIAL_8
} from './constants'
export type { TWhyUsProps } from './types'
export { ImageWithDecorations } from './components'
export { getWhyUsData } from './api'
