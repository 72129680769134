import { TToUnitProps } from './types'

// TODO: improve this function on use some kind of lib
export const toUnit = ({
  value,
  unit,
  rootFontSize,
  decimalPoints
}: TToUnitProps) => {
  return `${Number(
    (value / rootFontSize).toFixed(decimalPoints)
  )}${unit}` as const
}

export default toUnit
