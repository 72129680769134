import { Globe } from '../..'
import { TLocaleWithGlobalProps } from '../../../types'

const LocaleWithGlobe = ({ locale, fillGlobe }: TLocaleWithGlobalProps) => (
  <>
    <Globe fill={fillGlobe} styles={{ flexShrink: 0 }} />
    {locale}
  </>
)

export default LocaleWithGlobe
